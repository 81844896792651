import { IMAGES } from "./ImageConstants";

export const SECRETS_PROVIDERS = [
    {
        label : "OpenAI",
        providerValue : "OpenAI",
        value : 4,
        category : "llm",
        icon : IMAGES.PROVIDERS.OPENAI_ICON,
        keyValue : [
            {
                key : "OPENAI_API_KEY",
                value : "",
                type : "text",
            }
        ]
    }
    ,{
        label : "Cohere",
        providerValue : "Cohere",
        value : 18,
        category : "llm",
        icon : IMAGES.PROVIDERS.COHERE_ICON,
        keyValue : [
            {
                key : "COHERE_API_KEY",
                value : "",
                type : "text",
            }
        ]
    }
    ,{
        label : "Azure-OpenAI",
        providerValue : "Azure_OpenAI",
        value : 19,
        category : "llm",
        icon : IMAGES.PROVIDERS.AZURE_OPENAI_ICON,
        info : "You must add configuration with atleast 1 `DEPLOYMENTS` value.",
        keyValue : [
            {
                key : "AZURE_OPENAI_ENDPOINT",
                value : "",
                type : "text",
            },
            {
                key : "AZURE_OPENAI_API_KEY",
                value : "",
                type : "text",
            },
            {
                key : "DEPLOYMENTS",
                partition : {
                    MODEL : 4,
                    AZURE_DEPLOYMENT : 8
                },
                defaultKeys : ["MODEL"],
                value : [
                    {
                        MODEL : "gpt-35-turbo-16k-0613",
                        AZURE_DEPLOYMENT : ""
                    },{
                        MODEL : "gpt-4-0613",
                        AZURE_DEPLOYMENT : ""
                    },{
                        MODEL : "Embedding",
                        AZURE_DEPLOYMENT : ""
                    }
                ],
                isMandatoryCheck : true,
                type : "list",
            }
        ]
    },
    {
        label : "AWS Bedrock",
        providerValue : "AWS_Bedrock",
        value : 26,
        category : "llm",
        icon : IMAGES.PROVIDERS.AWS_BEDROCK_ICON,
        keyValue : [
            {
                key : "AWS_DEFAULT_REGION",
                value : "",
                type : "text",
            },
            {
                key : "AWS_ACCESS_KEY_ID",
                value : "",
                type : "text",
            },
            {
                key : "AWS_SECRET_ACCESS_KEY",
                value : "",
                type : "text",
            }
        ]
    },
    {
        label : "OpenAI Transcribe",
        providerValue : "OpenAI_Transcribe",
        value : 23,
        category : "transcription",
        icon : IMAGES.PROVIDERS.OPENAI_ICON,
        keyValue : [
            {
                key : "API_KEY",
                value : "",
                type : "text",
            }
        ]
    },
    {
        label : "AWS Transcribe",
        providerValue : "AWS_Transcribe",
        value : 20,
        category : "transcription",
        icon : IMAGES.PROVIDERS.AWS_TRANSCRIBER,
        keyValue : [
            {
                key : "AWS_DEFAULT_REGION",
                value : "",
                type : "text",
            },
            {
                key : "AWS_ACCESS_KEY_ID",
                value : "",
                type : "text",
            },
            {
                key : "AWS_SECRET_ACCESS_KEY",
                value : "",
                type : "text",
            },
            {
                key : "BUCKET_NAME",
                value : "",
                type : "text",
            }
        ]
    },
    {
        label : "Azure Transcribe",
        providerValue : "Azure_Transcribe",
        value : 21,
        category : "transcription",
        icon : IMAGES.PROVIDERS.AZURE_TRANSCRIBER,
        keyValue : [
            {
                key : "SUBSCRIPTION_KEY",
                value : "",
                type : "text",
            },
            {
                key : "REGION",
                value : "",
                type : "text",
            },
            {
                key : "STORAGE_CONNECTION_STRING",
                value : "",
                type : "text",
            },
            {
                key : "CONTAINER_NAME",
                value : "",
                type : "text",
            }
        ]
    },
    {
        label : "Google Transcribe",
        providerValue : "Google_Transcribe",
        value : 22,
        category : "transcription",
        icon : IMAGES.PROVIDERS.GOOGLE_TRANSCRIBER,
        keyValue : [
            {
                key : "TYPE",
                value : "",
                type : "text",
            },
            {
                key : "PROJECT_ID",
                value : "",
                type : "text",
            },
            {
                key : "PRIVATE_KEY_ID",
                value : "",
                type : "text",
            },
            {
                key : "PRIVATE_KEY",
                value : "",
                type : "text",
            },
            {
                key : "CLIENT_EMAIL",
                value : "",
                type : "text",
            },
            {
                key : "CLIENT_ID",
                value : "",
                type : "text",
            },
            {
                key : "AUTH_URI",
                value : "",
                type : "text",
            },
            {
                key : "TOKEN_URI",
                value : "",
                type : "text",
            },
            {
                key : "AUTH_PROVIDER_X509_CERT_URL",
                value : "",
                type : "text",
            },
            {
                key : "CLIENT_X509_CERT_URL",
                value : "",
                type : "text",
            },
            {
                key : "BUCKET_NAME",
                value : "",
                type : "text",
            }
        ]
    },
    {
        label : "AWS OCR",
        providerValue : "AWS_OCR",
        value : 24,
        category : "ocr",
        icon : IMAGES.PROVIDERS.AWS_OCR,
        keyValue : [
            {
                key : "AWS_DEFAULT_REGION",
                value : "",
                type : "text",
            },
            {
                key : "AWS_ACCESS_KEY_ID",
                value : "",
                type : "text",
            },
            {
                key : "AWS_SECRET_ACCESS_KEY",
                value : "",
                type : "text",
            },
            {
                key : "BUCKET_NAME",
                value : "",
                type : "text",
            }
        ]
    },
    {
        label : "Azure OCR",
        providerValue : "Azure_OCR",
        value : 25,
        category : "ocr",
        icon : IMAGES.PROVIDERS.AZURE_OCR,
        keyValue : [
            {
                key : "SUBSCRIPTION_KEY",
                value : "",
                type : "text",
            },
            {
                key : "ENDPOINT",
                value : "",
                type : "text",
            }
        ]
    }
]
