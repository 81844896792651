import axios from "axios";
import { Component } from "react";
import TextField from "../../components/ui_elements/form_fields/text_input/TextField";
import { BUTTON_TEXTS, EMPTY_STRING, TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../strings/Strings";
import { UPDATE_ACCOUNT_DETAILS } from "../../urls/Urls";
import { isEmpty, isValidCityString, isValidCountryString, isValidStateString, isValidTextString, isValidZipcode } from "../../utils/UtilityFunctions";
import AccountCreationPopup from "./AccountCreatePopup";
import { ACCOUNT_EDIT_INITIAL_STATE } from "./UserManagement.utils";
import cx from 'classnames';
import { getCognitoUserAccessToken } from "../sign_up/Cognito.utils";
import { toast } from 'react-toastify';
class AccountEditPopup extends Component{
    state = {
        ...ACCOUNT_EDIT_INITIAL_STATE
    }

    componentDidMount = () => {
        let {addressLine1, addressLine2, city, region, country, postalCode, accountID,
            accountName, companyName} = this.props;
        this.setState({
            addressLine1, addressLine2, 
            city, region, country, 
            postalCode, accountID,
            accountName, companyName
        })
    }

    onCloseEditAccount = () => {
        let {onCloseHandler} = this.props;
        this.setState({
            ...ACCOUNT_EDIT_INITIAL_STATE
        }, () => {
            if(!isEmpty(onCloseHandler)){
                onCloseHandler()
            }
        })
    }

    onSubmitHandler = () => {
        let {addressLine1, addressLine2, city, region, country, postalCode, accountID,
            accountName} = this.state;
        let {username, refreshData, onCloseHandler, companyAddress} = this.props;
        let addressLine1Error = isValidTextString(addressLine1,true).error;
        let addressLine2Error = isValidTextString(addressLine2,false).error;
        let cityError = isValidCityString(city,true).error;
        let regionError = isValidStateString(region,true).error;
        let countryError = isValidCountryString(country,true).error;
        let postalCodeError = isValidZipcode(postalCode,true).error;

        if(!addressLine1Error && !addressLine2Error && !cityError
            && !regionError && !countryError && !postalCodeError){
                let url = UPDATE_ACCOUNT_DETAILS
                let newCompanyAddress = [addressLine1, addressLine2, [city, postalCode].join(' '), region, country].join(', ').replace(/, ,/g, ',');
                newCompanyAddress = [newCompanyAddress, '.'].join('');
                let data = {
                    account_id: Number(accountID),
                    addressLine_1: addressLine1,
                    addressLine_2: addressLine2,
                    city: city,
                    state: region,
                    country: country,
                    zipcode: postalCode,
                    user : username,
                    account_name : accountName,
                    old_address : companyAddress,
                    new_address : newCompanyAddress
                }
                this.setState({
                    isSubmitting : true,
                    isSubmitError : false
                }, () => {
                    if(newCompanyAddress !== companyAddress){
                        axios.post(url,data,
                            {
                                headers:{
                                    Authorization: "Bearer "+getCognitoUserAccessToken()
                                }
                            })
                            .then(res => {

                                //toaster
                                toast(TOASTER_MESSAGE.EDIT_ACCOUNT_ADDRESS.SUCCESS,
                                    {
                                        type: TOASTER_TYPE.SUCCESS,
                                        theme: TOASTER_THEME.LIGHT
                                    });

                                refreshData(username)
                                onCloseHandler()
                            })
                            .catch(err => {
                                //toaster
                                toast(TOASTER_MESSAGE.EDIT_ACCOUNT_ADDRESS.ERROR,
                                    {
                                        type: TOASTER_TYPE.ERROR,
                                        theme: TOASTER_THEME.LIGHT
                                    });
                                onCloseHandler()
                            })
                    } else {
                        onCloseHandler()
                    }
                })
            } else {
                this.setState({
                    isSubmitError : true,
                    addressLine1Error, addressLine2Error,
                    cityError, regionError, countryError,
                    postalCodeError
                })
            }

    }

    onChangeAddressLineOne = (addressLine1) =>{
        this.setState({
            addressLine1,
            addressLine1Error : false
        })
    }

    onChangeAddressLineTwo = (addressLine2) =>{
        this.setState({
            addressLine2,
            addressLine2Error : false
        })
    }

    onChangeCity = (city) =>{
        this.setState({
            city,
            cityError : false
        })
    }

    onChangeRegion = (region) =>{
        this.setState({
            region,
            regionError : false
        })
    }

    onChangeCountry = (country) =>{
        this.setState({
            country,
            countryError : false
        })
    }

    onChangePostalCode = (postalCode) =>{
        this.setState({
            postalCode,
            postalCodeError : false
        })
    }

    render(){
        let  { isSubmitError, isSubmitting, accountName, companyName,
            city, cityError, country, countryError, addressLine1, addressLine1Error,
            addressLine2, addressLine2Error, region, regionError, postalCode,
            postalCodeError } = this.state;
        let formComponent = null;

        formComponent = (
            <div className="col-12">
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="accountName">
                        {"Account Name"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={accountName}
                            id={'accountName'}
                            isDisabled={true}
                            isReadOnly={true}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="companyname">
                        {"Company Name"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={companyName}
                            id={'companyname'}
                            isDisabled={true}
                            isReadOnly={true}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="addressline1">
                        {"Address Line 1"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={addressLine1}
                            id={'addressline1'}
                            placeHolder={'Address Line 1'}
                            onChangeHandler={this.onChangeAddressLineOne}
                            validate={isValidTextString}
                            isDisabled={isSubmitting}
                            isReadOnly={isSubmitting}
                            isError={isSubmitError}
                            isFieldError={addressLine1Error}
                            isMandatory={true}
                            isNumber={false}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="addressline2">
                        {"Address Line 2"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={addressLine2}
                            id={'addressline2'}
                            placeHolder={'Address Line 2'}
                            onChangeHandler={this.onChangeAddressLineTwo}
                            validate={isValidTextString}
                            isDisabled={isSubmitting}
                            isReadOnly={isSubmitting}
                            isError={isSubmitError}
                            isFieldError={addressLine2Error}
                            isMandatory={false}
                            isNumber={false}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="city">
                        {"City"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={city}
                            id={'city'}
                            placeHolder={'City'}
                            onChangeHandler={this.onChangeCity}
                            validate={isValidCityString}
                            isDisabled={isSubmitting}
                            isReadOnly={isSubmitting}
                            isError={isSubmitError}
                            isFieldError={cityError}
                            isMandatory={true}
                            isNumber={false}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="region">
                        {"State"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={region}
                            id={'region'}
                            placeHolder={'State'}
                            onChangeHandler={this.onChangeRegion}
                            validate={isValidStateString}
                            isDisabled={isSubmitting}
                            isReadOnly={isSubmitting}
                            isError={isSubmitError}
                            isFieldError={regionError}
                            isMandatory={true}
                            isNumber={false}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="country">
                        {"Country"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={country}
                            id={'country'}
                            placeHolder={'Country'}
                            onChangeHandler={this.onChangeCountry}
                            validate={isValidCountryString}
                            isDisabled={isSubmitting}
                            isReadOnly={isSubmitting}
                            isError={isSubmitError}
                            isFieldError={countryError}
                            isMandatory={true}
                            isNumber={false}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="postalcode">
                        {"Postal Code"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={postalCode}
                            id={'postalcode'}
                            placeHolder={'Postal Code'}
                            onChangeHandler={this.onChangePostalCode}
                            validate={isValidZipcode}
                            isDisabled={isSubmitting}
                            isReadOnly={isSubmitting}
                            isError={isSubmitError}
                            isFieldError={postalCodeError}
                            isMandatory={true}
                            isNumber={false}
                        />
                    </div>
                </div>
            </div>
        )

        return(
            <AccountCreationPopup
                title={"Edit Account Address"} 
                onCloseHandler={this.onCloseEditAccount} 
                onSubmitHandler={this.onSubmitHandler} 
                isSubmitting={isSubmitting} 
                submittingText={EMPTY_STRING}
                submitButtonText={BUTTON_TEXTS.UPDATE}
            >
                {formComponent}
            </AccountCreationPopup>
        )
    }
}

export default AccountEditPopup; 