import { useState } from 'react';
import globalClasses from '../../../../App.module.css';
import { getFileType, getListFromString, isEmpty } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'
import { EMPTY_STRING, SEPARATORS } from '../../../../strings/Strings';

function FileField(props) {
    let {containerClass, value,placeHolder, onChangeHandler, isDisabled, isFieldError,
        isReadOnly, label, id, isError, validate, isMandatory, allowedTypes,
        fileSize, fileType, maxSize, fileName, supportText} = props;

    let labelComponent = null
    let [isTextError,setIsTextError]=useState(false);
    let [errorText,setErrorText] = useState(false);

    maxSize = maxSize * 1024 * 1024
    
    const validateFileField = (value, fileSize, fileType) => {
        let validateResult = validate(value, fileSize, fileType, maxSize, allowedTypes, isMandatory)
        console.log(id, validateResult)
        setIsTextError(validateResult.error)
        setErrorText(validateResult.text)
    }

    const onChangeFile = (files) => {
        if(files.length){
            let file = files[0];
            let fileType = ['.',getListFromString(file.type,SEPARATORS.BACKSLASH)[1]].join(EMPTY_STRING);
            fileType = getFileType(fileType)
            let fileSize = file.size;
            let reader = new FileReader();
            reader.readAsDataURL(file); 
            reader.onloadend = () => {
                let fileValue = reader.result;
                let fileName = file.name;
                onChangeHandler(fileValue,fileSize,fileType,fileName);
                if(isError){
                    validateFileField(fileName,fileSize,fileType)
                }
            }
        }
    }

    if(isFieldError && !isTextError){
        validateFileField(fileName,fileSize,fileType);
    }

    if(!isEmpty(label)){
        labelComponent = (
            <label
                className={cx([globalClasses.FormLabel,isMandatory?globalClasses.FormRequiredInput: ''])} 
                htmlFor={id}>
                {label}
            </label>
        )
    }
    let formFieldContainerClass = isEmpty(containerClass)? "form-group col-12 col-md-6 px-1 px-md-3 my-3" 
                                    : containerClass;
    return(
        <div className={formFieldContainerClass}>
            {labelComponent}
            <input 
                className={cx(["form-control",globalClasses.FormInput,
                isTextError? globalClasses.ErrorFormInput : ''])} 
                type='file'
                name={fileName}
                id={id}
                value={value}
                placeholder={placeHolder}
                disabled={isDisabled}
                readOnly = {isReadOnly}
                onChange={(event) => onChangeFile(event.target.files)}
                accept={allowedTypes.join(',')}
            />
            <div className='d-flex flex-column'>
                <span className={globalClasses.SupportText}>
                    {supportText}
                </span>
                <span className={globalClasses.ErrorText}>
                    {errorText}
                </span>
            </div>
        </div>
    )
}

export default FileField;