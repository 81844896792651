import { useEffect, useState } from 'react';
import globalClasses from '../../../../App.module.css';
import { isEmpty, isValidNumber } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'
import { EMPTY_STRING } from '../../../../strings/Strings';
import { KEY_CODES } from '../../../../utils/Constants';

function VerificationCodeField(props) {
    let {type, autoComplete,containerClass, value, placeHolder, onChangeHandler, isDisabled, isFieldError,
        isReadOnly, label, id, isError, validate, isMandatory, isNumber, maxLength,
        enableVerification, isVerified, errorField, errorFieldText, onBlurHandler, matchText, resendVerificationCode } = props;
    let labelComponent = null
    let [isTextError, setIsTextError] = useState(false);
    let [errorText, setErrorText] = useState(false);
    let [enableResend, setEnableResend] = useState(true);
    let [timerSec, setTimerSec] = useState(60);

    console.log('Submit error', isError);

    useEffect(() => {
        if(!enableResend){
            if(timerSec===0){
                setEnableResend(true)
                setTimerSec(60)
            } else {
                setTimeout(() => {setTimerSec(timerSec - 1)},1000)
            }
        }
    },[enableResend,timerSec])

    const resendCode = () => {
        setEnableResend(false)
        resendVerificationCode()
    }

    const validateTextField = (value) => {
        let validateResult = isEmpty(matchText) ? validate(value, isMandatory) : validate(matchText, value)
        console.log(id, validateResult)
        setIsTextError(validateResult.error)
        setErrorText(validateResult.text)
    }
    const onChangeText = (input) => {
        let trimmedValue = input.replace(/^\s+/g, '');
        trimmedValue = trimmedValue.replace(/\s{2}/g, ' ');
        if (!isNumber || isValidNumber(trimmedValue)) {
            onChangeHandler(trimmedValue)
            if (isError) {
                validateTextField(trimmedValue)
            }
        }
    }
    const onBlurText = (input) => {
        if (!isEmpty(onBlurHandler)) {
            onBlurHandler()
        }
    }
    const onKeyDownText = (event) => {
        if (event.keyCode === KEY_CODES.ENTER_KEY) {
            onBlurText()
        }
    }

    if (isFieldError && !isTextError) {
        validateTextField(value)
    }
    if (enableVerification && isVerified) {
        if (isTextError && !errorField) {
            validateTextField(value)
        }
    }
    if (errorField) {
        if (!isTextError) {
            setIsTextError(errorField);
            setErrorText(errorFieldText);
        }
    }
    if (!isError && isTextError) {
        setIsTextError(false);
        setErrorText(EMPTY_STRING);
    }

    if (!isEmpty(label)) {
        labelComponent = (
            <label
                className={cx([globalClasses.FormLabel, isMandatory ? globalClasses.FormRequiredInput : ''])}
                htmlFor={id}>
                {label}
            </label>
        )
    }
    let formFieldContainerClass = isEmpty(containerClass) ? "form-group col-12 col-md-6 px-1 px-md-3 my-3"
        : containerClass;
    return (
        <div className={formFieldContainerClass}>
            {labelComponent}
            <input
                type={type}
                className={cx(["form-control", globalClasses.FormInput,
                    isTextError ? globalClasses.ErrorFormInput : ''])}
                id={id}
                value={value}
                placeholder={placeHolder}
                disabled={isDisabled}
                readOnly={isReadOnly}
                onChange={(event) => onChangeText(event.target.value)}
                onBlur={(event) => onBlurText(event.target.value)}
                onKeyDown={(event) => onKeyDownText(event)}
                maxLength={maxLength}
                autoComplete={autoComplete}
            />
            {errorText && <span className={globalClasses.ErrorText}>
                {errorText}
            </span>}
            {
                <p className={cx(["col-12 text-end", globalClasses.Font14DarkerGrey])}>
                    {
                        enableResend ?
                        <>
                            {/* Haven't received a verification code? */}
                            <span className={cx(['ps-1',globalClasses.Font14Primary,globalClasses.PointerCursor])} onClick={resendCode}>
                                Resend Code
                            </span>
                        </>
                        :
                        <>Resend verification code in {timerSec} second(s).</>
                    }
                </p>
            }
        </div>
    )
}

export default VerificationCodeField;
{/* <p className={cx(["col-12 col-lg-8", classes.SigninText])}>
                    Enter the verification code that we have sent you via email and enter your new password.
                </p>
                <div className='col-12 col-lg-8'>
                    <TextField
                        containerClass='col-12'
                        value={verificationCode}
                        // label={FORM_LABEL_TEXTS.FIRST_NAME}
                        id={'verificationCode'}
                        placeHolder={"Verification Code"}
                        onChangeHandler={onChangeVerificationCode}
                        validate={isValidNumberString}
                        isDisabled={isLoading}
                        isReadOnly={isLoading}
                        isError={isSubmitError}
                        isFieldError={isVerificationError}
                        isMandatory={true}
                        isNumber={true}
                    />
                </div>
                {
                    enableResend ?
                    <p className={cx(["col-12 col-lg-8 text-end", classes.SigninText])}>
                        Haven't received a verification code? <span onClick={sendCode}>Resend</span>
                    </p>
                    :
                    <p className={cx(["col-12 col-lg-8 text-end", classes.SigninText])}>
                        You can resend the verification code in few minutes.
                    </p>
                } */}