import { FEEDBACK_STRING } from "../../strings/Strings";
import { EMPTY_STRING, USER_ROLE } from "../../utils/Constants";

export const INITIAL_STATE = {
    textContent: EMPTY_STRING, 
    optionType: FEEDBACK_STRING.FEED_TYPE[1], 
    modelName: FEEDBACK_STRING.FEED_TYPE[1],
    optionList: [],
    isErrorTextContent: false,
    errorText: EMPTY_STRING,
    isModelNamesOptionVisible: false,
    user: EMPTY_STRING,
    isErrorUser: false,
    errorTextUser: EMPTY_STRING,
    isFeedbackSubmitting: false,
    isFeedbackSubmitSuccessFull: false,
    accountName: 'null',
    userRole: USER_ROLE.ANONYMOUS.LABEL,
    isSubmitError:false
}

export const INITIAL_RATING_STATE = {
    starRate : 0,                               // to store the rating given by user
    textContent : EMPTY_STRING,                 // to store the comments given by the user
    isErrorTextContent : false,                 // to set error state of the text component
    isSubmitError : false,                      // to set the form submission error
    isSubmitting : false,                       // to set the form submitting event
    isChanged : false,                          // to check the star rating value is changed
    isSubmitSuccess : false,                    // to check the success submission
    isSubmissionError : false,                  // to check the error submission
    user : EMPTY_STRING                         // to store the user value
}

export const INITIAL_MODEL_FEEDBACK_STATE = {
    suggestedTopic : [],              // to store topic information
    isErrorSuggestionTopic : false,             // to set error state of the dropdown component
    experience : 0,                             // to store the experience/rating given by user
    suggestionText : EMPTY_STRING,              // to store the comments given by the user
    isErrorSuggestionText : false,              // to set error state of the text component
    isSubmitError : false,                      // to set the form submission error
    isSubmitting : false,                       // to set the form submitting event
    canSaveRequestReponse : false,              // to check the star rating value is changed
    isSubmitSuccess : false,                    // to check the success submission
    isSubmissionError : false,                  // to check the error submission
    ownSuggestedTopic : EMPTY_STRING,           // to store the own string for suggestion
    isErrorOwnSuggestedTopic : false            // to set error state of own topic
}


export const setDefaultState = () => {
    return {
        textContent: EMPTY_STRING, 
        optionType: FEEDBACK_STRING.FEED_TYPE[1],
        modelName: FEEDBACK_STRING.FEED_TYPE[1],
        isErrorTextContent: false,
        errorText: EMPTY_STRING,
        isModelNamesOptionVisible: false,
        isErrorUser: false,
        errorTextUser: EMPTY_STRING,
        isFeedbackSubmitSuccessFull: false,
        user: EMPTY_STRING
    }
}