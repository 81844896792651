import Autocomplete from "react-autocomplete";
import { HiOutlineSearch } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { isEmpty, isEmptyList, isEmptyObject } from "../../utils/UtilityFunctions";

import classes from './SearchInput.module.css';

import cx from 'classnames';
import SearchDropDown from "./SearchDropDown";

function SearchInput(props) {
    let {searchList, searchValue, onSelectValue, onChangeValue, onClearSearch, searchPlaceHolder,
        disableAutoSearch, searchDropList, onChangeSearchDrop, currentSearch, onSearchKeyPress, focusSearch }=props;

    let searchInputComponent = null;
    let searchInputDropComponent = null;
    let searchContainerClass = cx(["input-group"]);
    let searchInputClass = cx([classes.SearchInput, "form-control"]);
    let searchIconClass = cx([classes.SearchIcon,"input-group-text"]);

    const onAutoCompleteKeyPressInputHandler = (e) => {
        if (e.charCode === 13) {
            onSelectValue(e.target.value,e.target.value)
        }
    }

    const onKeyDownHandler = (e) => {
        if(!isEmpty(onSearchKeyPress)){
            onSearchKeyPress(e)
        }
    }

    if(!isEmptyList(searchDropList) && searchDropList.length>1 && !isEmptyObject(currentSearch)){
        searchInputDropComponent = (
            <SearchDropDown
                selectedOption={currentSearch} 
                dropOptionList={searchDropList} 
                onSelectDropOption={onChangeSearchDrop}
            />
        )
    }

    if(disableAutoSearch){
        searchInputComponent = (
            <input
                className={searchInputClass}
                value={searchValue}
                onChange={onChangeValue}
                placeholder={searchPlaceHolder}
                onKeyDown={onKeyDownHandler}
                autoFocus={focusSearch || false}
            />
        )
    } else {
        searchInputComponent = (
            <Autocomplete
                menuStyle={{
                    // borderRadius: '3px',
                    cursor: "pointer",
                    fontSize: '14px',
                    position: 'absolute',
                    color: '#5F6368',
                    overflowY: 'auto',
                    maxHeight: '250px',
                    zIndex: 1,
                    background: 'white',
                    border: '1px solid lightgrey',
                    top: '42px',
                    left: '0px',
                }}
                wrapperStyle={{
                    flexGrow: '1',
                    position: 'relative'
                }}
                items={searchList}
                shouldItemRender={(item, value) => item.toLowerCase().indexOf(value.toLowerCase()) > -1}
                getItemValue={item => item}
                renderItem={(item, highlighted) =>
                    <div
                        key={item + highlighted}
                        style={{ backgroundColor: highlighted ? '#eee' : 'transparent', paddingLeft: '8px', paddingTop: '5px', paddingBottom: '5px' }}
                    >
                        {item}
                    </div>
                }
                value={searchValue}
                inputProps={{
                    className: searchInputClass,
                    placeholder: searchPlaceHolder,
                    onKeyPress: (e) => { onAutoCompleteKeyPressInputHandler(e) }
                }}
                onChange={onChangeValue}
                onSelect={onSelectValue}
            />
        )
    }

    return(
        <div className={searchContainerClass}>
            {searchInputDropComponent}
            {searchInputComponent}
            <span className={searchIconClass} id={'searchuser'}>
                {isEmpty(searchValue)? 
                    <HiOutlineSearch /> : 
                    <MdClose style={{cursor : 'pointer'}} onClick={onClearSearch}/>
                }
            </span>
        </div>
    )
}

export default SearchInput;