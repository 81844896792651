import { useEffect, useRef, useState } from 'react';
import globalClasses from '../../../../App.module.css';
import { isEmpty } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'
import { EMPTY_STRING } from '../../../../strings/Strings';

function TextAreaField(props) {
    let { containerClass, value, placeHolder, onChangeHandler, isDisabled, isFieldError,
        isReadOnly, label, id, isError, validate, isMandatory, rowLength,
        maxCharacter, minCharacter, customInputClass, hideErrorTextOnEmpty } = props;
    let labelComponent = null
    let [isTextError, setIsTextError] = useState(false);
    let [errorText, setErrorText] = useState(false);

    const validateTextAreaField = (value) => {
        let validateResult = validate(value, maxCharacter, minCharacter, isMandatory)
        console.log(id, validateResult)
        setIsTextError(validateResult.error)
        setErrorText(validateResult.text)
    }

    const onChangeText = (input) => {
        let trimmedValue = input.replace(/^\s+/g, '');
        trimmedValue = trimmedValue.replace(/\s{2}/g, ' ');
        onChangeHandler(trimmedValue)
        if (isError) {
            validateTextAreaField(trimmedValue)
        }
    }

    if (isFieldError && !isTextError) {
        validateTextAreaField(value)
    }

    if (!isEmpty(label)) {
        labelComponent = (
            <label
                className={cx([globalClasses.FormLabel, isMandatory ? globalClasses.FormRequiredInput : ''])}
                htmlFor={id}>
                {label}
            </label>
        )
    }
    let formFieldContainerClass = isEmpty(containerClass) ? "form-group col-12 col-md-6 px-1 px-md-3 my-3"
        : containerClass;
    let formInputClass = isEmpty(customInputClass) ? globalClasses.FormInput : customInputClass
    return (
        <div className={formFieldContainerClass}>
            {labelComponent}
            <textarea
                className={cx(["form-control", globalClasses.FormTextArea, formInputClass,
                    isTextError ? globalClasses.ErrorFormInput : ''])}
                id={id}
                placeholder={placeHolder}
                disabled={isDisabled}
                readOnly={isReadOnly}
                onChange={(event) => onChangeText(event.target.value)}
                rows={rowLength}
                value={value}
            >
            </textarea>
            {
                (!hideErrorTextOnEmpty || !isEmpty(errorText)) &&
                <span className={globalClasses.ErrorText}>
                    {errorText}
                </span>
            }
        </div>
    )
}

export default TextAreaField;