import React, { useEffect } from 'react';
import pluginConfig from './CookieConsentConfig';
import 'vanilla-cookieconsent';

const CookieConsentComponent = () => {
  useEffect(() => {
    /**
     * useEffect is executed twice (React 18+),
     * make sure the plugin is initialized and executed once
     */
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run(pluginConfig);

      if(document.getElementById('s-all-bn')){
        document.getElementById("s-all-bn").style.visibility = "hidden";
      }
    }
  }, []);

  return null;
};

export default CookieConsentComponent;
