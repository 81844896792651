import React from 'react';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, BarController, CategoryScale, LineElement, BarElement, Legend, Tooltip, PointElement, LinearScale, Title } from 'chart.js';
import { isEmpty } from '../../utils/UtilityFunctions';

ChartJS.register(CategoryScale, LineController, BarController, LineElement, BarElement, PointElement, LinearScale, Title, Legend, Tooltip);

function HybridChart(props) {
    let {title,labels, datasets, xAxis, yAxis, screenWidth, legendPositionRight} = props;

    let height = 150;
    let showXaxisValues = true;
    if(screenWidth < 576){
      height = 320
      showXaxisValues = false;
    } else if(screenWidth <= 768 ){
      height = 180
    } else {
      height = 110
    }


    let chartData = {
      labels,
      datasets
    }

    let chartOptions = {
        responsive: true,
        plugins: {
          title: {
            display: !isEmpty(title),
            text: title,
            color: "#221e5b",
            font: {
              size: '18px'
            },
            padding: {top: 0, left: 0, right: 0, bottom: 20}
          },
          legend: {
              display : true,
              position : legendPositionRight? 'right' : 'top',
              labels : {
                usePointStyle : true,
                boxHeight : 8,
                boxWidth : 8
              },
          },
          tooltip : {
            displayColors : false
          }
        },
        scales: {
            x: {
              display: true,
              ticks: {
                font : {
                    size : showXaxisValues? 10 : 1
                }
              },
              title: {
                display: !isEmpty(xAxis),
                text: xAxis,
                color: "#202124",
                font: {
                  family : 'Roboto-Medium',
                  size: 12
                },
              }
            },
            y: {
              display: true,
              min : 0,
              title: {
                display: !isEmpty(yAxis),
                text: yAxis,
                color: "#202124",
                font: {
                    family : 'Roboto-Medium',
                    size: 12
                },
              }
            }
        }
      }
    
    return(
        <>
          <Chart 
            height={height}
            data={chartData} 
            options={chartOptions}
          />
        </>
    )
}

export default HybridChart;