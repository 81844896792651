import { useState } from 'react';
import globalClasses from '../../../../App.module.css';
import { isEmpty } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'

function TextAreaInput(props) {
    let {containerClass, value,placeHolder, onChangeHandler, onBlurHandler, isDisabled, 
        isReadOnly, label, id, isError, errorText, validate, rowLength, isMandatory} = props;
    let labelComponent = null
    let [isAlreadyError,setIsAlreadyError] = useState(false)
    if(isError && !isAlreadyError){
        setIsAlreadyError(true)
    }
    if(!isEmpty(label)){
        labelComponent = (
            <label
                className={cx([globalClasses.FormLabel,isMandatory?globalClasses.FormRequiredInput:''])} 
                htmlFor={id}>
                {label}
            </label>
        )
    }
    let formFieldContainerClass = isEmpty(containerClass)? "form-group col-12 col-md-6 px-1 px-md-3 my-3" 
                                    : containerClass;

    const onBlurText = (value) => {
        let isErrorValue = isMandatory? !(!isEmpty(value) && validate(value)) : !(isEmpty(value) || validate(value))
        onBlurHandler(value,isErrorValue);
    }
    const onChangeText = (value) => {
        onChangeHandler(value)
        if(isAlreadyError){
            onBlurText(value)
        }
    }
    return(
        <div className={formFieldContainerClass}>
            {labelComponent}
            <textarea
                className={cx(["form-control", globalClasses.FormTextArea,globalClasses.FormInput,
                isError? globalClasses.ErrorFormInput : ''])} 
                id={id}
                placeholder={placeHolder}
                disabled={isDisabled}
                readOnly = {isReadOnly}
                onChange={(event) => onChangeText(event.target.value)}
                onBlur={(event) => onBlurText(event.target.value)}
                rows={rowLength}
                value={value}
            >
            </textarea>
            <span className={globalClasses.ErrorText}>
                {errorText}
            </span>
        </div>
    )
}

export default TextAreaInput;