import SortingTable from "../../components/sorting_table/SortingTable";
import CopyButton from "../../components/ui_elements/copy_button/CopyButton";
import { IMAGES } from "../../utils/ImageConstants";
import { copyToClipboard, getHTMLFormatedData, getReplacedContent, isEmptyList } from "../../utils/UtilityFunctions";
import classes from './SelfHostingsInfo.module.css';

export const SELF_HOSTING_CONTENT_TYPES = {
    HEADING : 1,
    SUB_HEADING : 2,
    PARA : 3,
    ORDERED : 4,
    UNORDERED : 5,
    CODE : 6,
    TABLE : 7,
    CODE_INFO : 8,
    NOTE : 9
}

export const SELF_HOSTING_CONTENT_VIEWS = {
    MANAGE : 1,
    CORE_MODEL : 2,
    AUGMENTED_MODEL : 3
}

export const getContentData = (currentInfoData,metaValues,viewValue=SELF_HOSTING_CONTENT_VIEWS.MANAGE) => {
    return currentInfoData.map((data,index) => {
        let type = data.type
        let content = data.content
        let contentViews = data?.views || []
        if(!isEmptyList(contentViews) && !contentViews.includes(viewValue)){
            return null;
        }
        switch(type){
            case SELF_HOSTING_CONTENT_TYPES.SUB_HEADING : return(
                <h3 key={index} className={classes.HostingInfoSubHeading}>{getHTMLFormatedData(content.value,content.meta)}</h3>
            )
            case SELF_HOSTING_CONTENT_TYPES.PARA : return(
                <p key={index} className={classes.HostingInfoParagraph}>{getHTMLFormatedData(content.value,content.meta)}</p>
            )
            case SELF_HOSTING_CONTENT_TYPES.CODE :
                let contentValue = getReplacedContent(content.value,content?.replace_meta,metaValues)
                return(
                <p className={classes.HostingInfoCode}>
                    <code key={index} className={classes.CodeBlock}>
                        {contentValue}
                    </code>
                    <CopyButton
                        className={classes.CopyCode}
                        imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                        copyText={"Copy"}
                        copiedText={'Copied'}
                        onClickCopy={() => {
                            copyToClipboard(contentValue)
                        }}
                    />
                </p>
            )
            case SELF_HOSTING_CONTENT_TYPES.CODE_INFO : return(
                <p key={index} className={classes.HostingInfoCodeInfo}>
                    {content.value}
                </p>
            )
            case SELF_HOSTING_CONTENT_TYPES.NOTE : return(
                <p key={index} className={classes.HostingInfoNote}>
                    <b>NOTE : </b>{content.value}
                </p>
            )
            case SELF_HOSTING_CONTENT_TYPES.TABLE : return(
                <div>
                    <SortingTable
                        headingList={data.contentHeadingList}
                        dataList={metaValues[data.contentKey]}
                        itemsToShow={(metaValues[data.contentKey]).length}
                        tableHeadClass = {classes.TableHeadClassBlue}
                        hoverClass={classes.TableHeadHoverClassBlue} 
                        sortedClass={classes.TableHeadSortedClassBlue}
                        pageButtonClass={classes.PaginationButton}
                        pageButtonActiveClass={classes.PaginationActiveButton}
                    />
                </div>
            )
            case SELF_HOSTING_CONTENT_TYPES.UNORDERED : return(
                <ul key={index} className={classes.HostingInfoList}>
                    {
                        content.map((listItem,index) => {
                            
                            let listContentViews = listItem?.views || []
                            if(!isEmptyList(listContentViews) && !listContentViews.includes(viewValue)){
                                return null;
                            }
                            
                            return(
                                <>
                                    <li key={index}>{getHTMLFormatedData(listItem.value,listItem.meta)}</li>
                                    {
                                        listItem.hasOwnProperty("inner_contents") &&
                                        <div>
                                            {getContentData(listItem.inner_contents,metaValues,viewValue)}
                                        </div>
                                    }
                                </>
                            )
                        })
                    }
                </ul>
            )
            case SELF_HOSTING_CONTENT_TYPES.ORDERED : return(
                <ol key={index} className={classes.HostingInfoList}>
                    {
                        content.map((listItem,index) => {
                            let listContentViews = listItem?.views || []
                            if(!isEmptyList(listContentViews) && !listContentViews.includes(viewValue)){
                                return null;
                            }

                            return(
                                <>
                                    <li key={index}>{getHTMLFormatedData(listItem.value,listItem.meta)}</li>
                                    {
                                        listItem.hasOwnProperty("inner_contents") &&
                                        getContentData(listItem.inner_contents,metaValues,viewValue)
                                    }
                                </>
                            )
                        })
                    }
                </ol>
            )
            default : return null
        }
    })
}