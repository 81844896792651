import cx from 'classnames';
import classes from './Popup.module.css';

function Popup(props) {
    let {className}=props;
    let backgroundStyle = cx([classes.BackGround,className]);
    return(
        <div className={backgroundStyle}>
            {props.children}
        </div>
    )
}

export default Popup;