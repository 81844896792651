import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import './App.module.css';
import UserContextProvider from './hoc/UserContextProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CookieConsentComponent from '../src/components/cookie_consent_banner/CookieConsentComponent';
import '../src/cookieconsent.css'
import * as Sentry from "@sentry/react";

export const history = createBrowserHistory();

if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
  // production 
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
  
    integrations: [Sentry.browserTracingIntegration()],
  
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^\//,
      /^https:\/\/vivas\.ai\/api/,
    ],
  });
}

function App({ msalInstance }) {
  // Enable this for web push
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "utils/client.js";
  //   script.type = "application/javascript";
  //   script.async = true;
  
  //   document.body.appendChild(script);
  // })
  
//   useEffect(() => {

  //     // var tawk = new TawkTo('62a8895ab0d10b6f3e774b51', '1g5h5h2te')

//     var tawk = new TawkTo('62a9f841b0d10b6f3e778527', '1g5jv3h37')
//     tawk.onStatusChange((status) => {
//       console.log('TawkTo')
//       console.log(status)
//     })
//   }, [])
  return (
    <Router history={history}>
      <UserContextProvider />
      <ToastContainer/>
      <CookieConsentComponent />
    </Router>
  );
}
export default App;
