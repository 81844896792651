import axios from "axios";
import { Component } from "react";
import TextField from "../../components/ui_elements/form_fields/text_input/TextField";
import TextAreaField from "../../components/ui_elements/form_fields/teaxt_area_input/TextAreaField";
import { BUTTON_TEXTS, EMPTY_STRING,TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../strings/Strings";
import { UNSUBSCRIBE_ACCOUNT } from "../../urls/Urls";
import { isEmpty, isValidMessageString, isEmptyObject } from "../../utils/UtilityFunctions";
import AccountCreationPopup from "./AccountCreatePopup";
import { ACCOUNT_UNSUBSCRIBE_INITIAL_STATE } from "./UserManagement.utils";
import cx from 'classnames';
import { getCognitoUserAccessToken } from "../sign_up/Cognito.utils";
import { EVENT } from '../../../src/utils/Constants';
import { AuditLog } from '../../utils/AuditUtils'
import { toast } from 'react-toastify';
class UnsubscribeAccountPopup extends Component{
    state = {
        ...ACCOUNT_UNSUBSCRIBE_INITIAL_STATE
    }

    componentDidMount = () => {
        let {accountUUID,accountID,reason} = this.props;
        console.log("ACCID ", this.props)

        this.setState({
            accountUUID,accountID,reason
        })
    }

    onCloseUnsubscribeAccount = () => {
        let {onCloseHandler} = this.props;
        this.setState({
            ...ACCOUNT_UNSUBSCRIBE_INITIAL_STATE
        }, () => {
            if(!isEmpty(onCloseHandler)){
                onCloseHandler()
            }
        })
    }

    onSubmitHandler = () => {
        let { accountUUID, accountID, reason } = this.state;
        let { username, refreshData, onCloseHandler, userDetails } = this.props;
        let reasonError = isValidMessageString(reason, 500, 3, true).error;
        console.log("ACCID", accountUUID, accountID)

        if (!reasonError) {
            let url = UNSUBSCRIBE_ACCOUNT
            let data = {
                account_id: Number(accountID),
                reason: reason
            }

            //#region - audit log
            let startDateTime = new Date()

            let accountDetails = {}  
            if (accountUUID) {
                accountDetails = {accountUUID: accountUUID}
            }

            // audit log - common properties
            let eventDetails = EVENT.MANAGE_CATEGORY.UNSUBSCRIPTION
            eventDetails = {
                ...eventDetails,
                eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
                eventStartDateTime: startDateTime
            }

            let eventAttributes = {}
            let user = {}
            if (!isEmptyObject(userDetails)) {
                user = {
                    awsId: userDetails?.awsId || null,
                    email: userDetails?.email
                }
            }

            let actionableData = {}
            if (data) {
                actionableData = {
                    accountId: data?.account_id,
                    reason: data?.reason
                }
            }

            eventAttributes = {...user, ...actionableData}
            //#endregion

            this.setState({
                isSubmitting: true,
                isSubmitError: false
            }, () => {
                if (!reasonError) {
                    axios.post(url, data,
                        {
                            headers: {
                                Authorization: "Bearer " + getCognitoUserAccessToken()
                            }
                        })
                        .then(res => {
                            //audit log - success scenario
                            if(res){
                                eventDetails = {
                                    ...eventDetails,
                                    eventStatusCode: res?.status || '200',
                                    eventStatus: 'Success',
                                    eventMessage: res?.data?.message || res?.statusText || 'Unsubscription success'
                                }
                            }
                            
                            //toaster
                            toast(TOASTER_MESSAGE.UNSUBSCRIPTION.SUCCESS,
                                {
                                    type: TOASTER_TYPE.SUCCESS,
                                    theme: TOASTER_THEME.LIGHT
                                });

                            refreshData(username)
                            onCloseHandler()
                        })
                        .catch(err => {
                            //audit log - error scenario
                            if (err) {
                                eventDetails = {
                                    ...eventDetails,
                                    eventStatus: 'Failure',
                                    eventStatusCode: err?.response?.status || null,
                                    eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Unsubscription error',
                                }
                            }

                            //toaster
                            toast(TOASTER_MESSAGE.UNSUBSCRIPTION.ERROR,
                                {
                                    type: TOASTER_TYPE.SUCCESS,
                                    theme: TOASTER_THEME.LIGHT
                                });
                                
                            onCloseHandler()
                        })
                        .finally(() => {
                            //audit log
                            AuditLog(eventDetails, eventAttributes, accountDetails)
                        })
                } else {
                    onCloseHandler()
                }
            })
        } else {
            this.setState({
                isSubmitError: true,
                reasonError
            })
        }

    }

    onChangeReason = (reason) =>{
        this.setState({
            reason,
            reasonError : false
        })
    }

    

    render(){
        let  { isSubmitError, isSubmitting, accountUUID,reason, reasonError} = this.state;
        let formComponent = null;

        formComponent = (
            <div className="col-12">
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="accountUUID">
                        {"Account ID"}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={accountUUID}
                            id={'accountUUID'}
                            isDisabled={true}
                            isReadOnly={true}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="reason">
                        {"Reason"}
                    </label>
                    <div className="col-12 col-lg-8">
                        {/* <TextField */}
                        <TextAreaField
                            containerClass='col-12'
                            value={reason}
                            id={'reason'}
                            placeHolder={'Tell us why you are leaving'}
                            onChangeHandler={this.onChangeReason}
                            validate={isValidMessageString }
                            isDisabled={isSubmitting}
                            isReadOnly={isSubmitting}
                            isError={isSubmitError}
                            isFieldError={reasonError}
                            isMandatory={true}
                            isNumber={false}
                            rowLength={3}
                            maxCharacter={500}
                            minCharacter={3}
                        />
                    </div>
                </div>
            </div>
        )

        return(
            <AccountCreationPopup
                title={"We're sorry to see you go!"} 
                onCloseHandler={this.onCloseUnsubscribeAccount} 
                onSubmitHandler={this.onSubmitHandler} 
                isSubmitting={isSubmitting} 
                submittingText={EMPTY_STRING}
                submitButtonText={BUTTON_TEXTS.UPDATE}
            >
                {formComponent}
            </AccountCreationPopup>
        )
    }
}

export default UnsubscribeAccountPopup; 