import React, { Component } from "react";
import axios from 'axios';
import { INITIAL_STATE, handleAsyncTasksError } from "../../containers/async_tasks/AsyncTasks.utils";
import { EMPTY_STRING } from "../../utils/Constants";
import Loader from "../../components/loader/Loader";
import NoData from "../../components/no_data/NoData";
import ErrorPage from "../../components/error_page/ErrorPage";
import { isEmptyList } from "../../utils/UtilityFunctions";
import REFRESH_ICON from '../../assets/images/user-manage/refresh-icon.png'
import classes from '../../components/output_types/table/TableTypeOutput.module.css';
class UnReadNotification extends Component {

    state = {
        ...INITIAL_STATE
    }

    componentDidMount = () => {
        console.log("did mount")
        this.getUnReadTasks()
    }

    getUnReadTasks = () => {
        let { userDetails } = this.props

        // axios.get('/api/gettask', {
        //     headers: {
        //         clientId: userDetails.clientId,
        //         clientSecret: userDetails.clientSecret
        //     }
        // }).then((response) => {
        //     this.setState({
        //      data : response.data
        //     })

        //     console.log(response.data)
        // }).catch((error) => {
        //     console.log("error", error)
        //     this.setState({
        //         ...handleAsyncTasksError()
        //     })
        // })
    }

    render() {
        let { taskList, headingList, isLoading, isErrorInAPI, showTaskDetail, taskId } = this.state
        let taskListComponent = null
        if (isLoading) {
            taskListComponent = <Loader />
        } else {
            if (isErrorInAPI) {
                taskListComponent = <ErrorPage />
            } else {
                if (isEmptyList(taskList)) {
                    taskListComponent = <NoData mainText={'No tasks available'} subText={''} />
                } else {
                    taskListComponent = <>
                        <span >
                            <p className={classes.RefreshLink} onClick={() => this.onClickRefreshTask()}> <img src={REFRESH_ICON} className={classes.RefreshIcon} alt="Refresh" title="Refresh Tasks" />Refresh tasks</p>
                        </span>
                    </>
                }
            }
        }
        return (
            <>
                <div className="container-fluid px-5 mt-5">
                    {taskListComponent}
                </div>
            </>
        )
    }
}
export default UnReadNotification;