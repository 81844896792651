import cx from 'classnames';
import classes from './SuccessScreen.module.css';
import globalClasses from '../../App.module.css';

import Button from '../ui_elements/button/ButtonNew';
import DoneIcon from '../../assets/images/approve-ico.png';
import { isEmpty } from '../../utils/UtilityFunctions';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';

function SuccessScreen(props) {
    let {className, onClickHandler, mainText, subText, buttonText } = props
    let thankyouStyle = cx([classes.Heading, globalClasses.Font36BoldDarkerGrey])
    let successTextStyle = cx([classes.SubText, globalClasses.Font15DarkerGrey])
    let thanksNoteContainerStyle = cx([className,classes.ThanksNoteContainer])
    return (
        <div className={thanksNoteContainerStyle}>
            <img className={classes.DoneImage} src={DoneIcon} alt={'Done'} />
            <span className={thankyouStyle}>
                {mainText}
            </span>
            <span className={successTextStyle}>
                {subText}
            </span>
            {
                !isEmpty(buttonText) &&
                <Button
                    label={buttonText}
                    className={"px-3"}
                    onClickHandler={onClickHandler}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                />
            }
        </div>
    )
}

export default SuccessScreen;