import { Component } from "react";
import cx from 'classnames';
import axios from "axios";
import { VscCircleFilled } from "react-icons/vsc"

// utils
import { ADD_ACCOUNT_WITH_USER, GET_USER_PROFILE, TRY_FOR_FREE_CREATE_USERS, UPDATE_ACCOUNT } from "../../../urls/Urls";
import { handleErrorVerification, USERS } from "../UserManagement.utils";
import { getDollarFromNumber, getWesternNumber, isEmpty, isValidCompanyNameString, isValidJobTitleString, isValidNameString, isValidTextString, isValidWorkEmailString } from "../../../utils/UtilityFunctions";
import { EMPTY_STRING, ERROR_TEXTS, TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../../strings/Strings";
import { BUTTON_ICON_POSITION, ERROR_CODES } from "../../../utils/Constants";
import { USER_MNGMENT_STRINGS } from "../../../strings/UserManagement";
import { ACCOUNT_CREATION_INITIAL_STATE, ACCOUNT_TYPE, getAccountDetails, getInitialAdminDetails, getTenureType, getTenureTypeName, handleSuccessVerfication } from "./AccountManagement.utils";

// classes
import globalClasses from '../../../App.module.css';

// subcomponents
import AccountCreationPopup from "../AccountCreatePopup";
import Button from "../../../components/ui_elements/button/ButtonNew";
import TextField from "../../../components/ui_elements/form_fields/text_input/TextField";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField";

import LoaderGIF from '../../../assets/images/loader/loader-gif.gif';
import { getCognitoUserAccessToken } from "../../sign_up/Cognito.utils";
import { toast } from 'react-toastify';
import { BUTTON_TYPES } from "../../../utils/ButtonConstants";
class AccountCreationFormPopup extends Component {
    state = {
        ...ACCOUNT_CREATION_INITIAL_STATE
    }

    // onclose account creation popup
    onClosePopup = () => {
        let { isSubmitting } = this.state;
        if (!isSubmitting) {
            let { onCloseHandler } = this.props;
            this.setState({
                ...ACCOUNT_CREATION_INITIAL_STATE
            }, () => {
                onCloseHandler()
            })
        }
    }

    // set error data for the component
    setError = (errorCode,submitState) => {
        // this.props.setErrorData();
        if (errorCode === ERROR_CODES.NOT_FOUND) {
            if(submitState){
                this.setState({
                    isSubmitting: false,
                    userError: true,
                    userEmailErrorText: ERROR_TEXTS.NOT_SUBSCRIBED,
                    adminDetailsError : true,
                    enableAdminDetailPage : true
                })
            } else {
                this.setState({
                    isSubmitting: false,
                    userError: true,
                    userEmailErrorText: ERROR_TEXTS.NOT_REGISTERED,
                    adminDetailsError : true,
                    enableAdminDetailPage : true
                })
            }
        } else if (errorCode === ERROR_CODES.CONFLICT) {
            this.setState({
                isSubmitting: false,
                userError: true,
                userEmailErrorText: ERROR_TEXTS.USER_ALREADY_ADDED,
                adminDetailsError : true,
                enableAdminDetailPage : true
            })
        } else {
            this.props.onCloseHandler()
        }
    }

    // onsubmit account details add/edit
    onAccountDetailsSubmission = (res) => {
        let { onSubmitHandler } = this.props
        this.setState({
            ...ACCOUNT_CREATION_INITIAL_STATE
        }, () => {
            onSubmitHandler()
        })
    }

    // on component mount
    componentDidMount = () => {
        let { editableData } = this.props;
        let accountEditableData = Object.assign({}, editableData)
        this.setState({
            editableData: accountEditableData
        })
    }

    // on change username 
    onChangeUserName = (username) => {
        this.setState({
            userName: username,
            userNameError: false,
        })
    }

    validateUserEmail = (email,isMandatory) => {
        let { isEmailVerified } = this.state;
        let validateResult = isValidWorkEmailString(email, isMandatory);
        let error=validateResult.error;
        let text=validateResult.text;
        if(!error){
            if(!isEmailVerified){
                error = true;
                text = ERROR_TEXTS.NEED_VERIFICATION
            }
        }
        return {error,text}
    }

    // on change usermail 
    onChangeUserMail = (usermail) => {
        this.setState({
            userEmail: usermail,
            userEmailError : false
        })
    }

    // on change tierlevel
    onChangeUserTierLevel = (tierlevel) => {
        this.setState({
            tierLevel: tierlevel
        })
    }

    // on change account name 
    onChangeAccountName = (accountname) => {
        let { enableEditMode } = this.props;
        if (enableEditMode) {
            let { editableData } = this.state;
            editableData['accountName'] = accountname;
            this.setState({
                editableData,
                accountNameError : false
            })
            // }, () => {
            //     if (this.props.editableData.accountName.toLowerCase() !== editableData.accountName.toLowerCase())
            //         this.onBlurAccountName(accountname)
            // })
        } else {
            this.setState({
                accountName: accountname,
                accountNameError : false
            })
        }
    }

    validateAccountName = (accountName,isMandatory) => {
        let { enableEditMode, editableData } = this.props;
        let validateResult = isValidTextString(accountName,isMandatory);
        let error = validateResult.error;
        let text = validateResult.text;
        if(!error){
            if(enableEditMode && editableData.accountName.toLowerCase() === accountName.toLowerCase()){
                error = false;
                text = EMPTY_STRING;
            } else {
                let accountnameIndex = this.props.accountNameList.findIndex(item => item.toLowerCase() === accountName.toLowerCase())
                if (accountnameIndex > -1) {
                    error = true;
                    text = ERROR_TEXTS.ACCOUNT_ALREADY_ADDED
                }
            }
        }
        return {error,text}
    }

    // on change company name 
    onChangeCompanyName = (companyname) => {
        let { enableEditMode } = this.props;
        let companyNameError = false
        if (enableEditMode) {
            let { editableData } = this.state;
            editableData['companyName'] = companyname;
            this.setState({
                editableData,
                companyNameError
            })
        } else {
            this.setState({
                companyName: companyname,
                companyNameError,
            })
        }
    }

    // on change subscription type
    onChangeSubscriptionType = (subscriptiontype) => {
        let { enableEditMode } = this.props;
        let accountType, accountTypeCode;
        [accountType, accountTypeCode ] = getAccountDetails(subscriptiontype)
        if (enableEditMode) {
            let { editableData } = this.state;
            editableData['accountType'] = accountType;
            editableData['subscriptionType'] = String(accountTypeCode);
            this.setState({
                editableData
            })
        } else {
            this.setState({
                accountType, accountTypeCode
            })
        }
    }

    // on change subscription tenure type
    onChangeSubscriptionTenureType = (subscriptiontype) => {
        let { enableEditMode } = this.props;
        let tenureType = getTenureType(subscriptiontype)
        let tenureName = getTenureTypeName(tenureType)
        console.log('Tenure',tenureType,tenureName);
        if (enableEditMode) {
            let { editableData } = this.state;
            editableData['tenureType'] = tenureType;
            editableData['tenureName'] = getTenureTypeName(tenureType)
            this.setState({
                editableData
            })
        } else {
            this.setState({
                tenureType,
                tenureName
            })
        }
    }

    // onclick edit submission
    onClickEditDone = () => {
        let { editableData } = this.state;
        let accountNameError = this.validateAccountName(editableData.accountName,true).error;
        let companyNameError = isValidCompanyNameString(editableData.companyName,true).error;
        if (!accountNameError && !companyNameError) {
            let url = UPDATE_ACCOUNT + `?accountNameChange=${this.props.editableData.accountName.toLowerCase() !== editableData.accountName.toLowerCase()}`
            let data = {
                account_id: Number(editableData.accountID),
                account_name: editableData.accountName,
                company_name: editableData.companyName,
                subscription_type: editableData.accountType + editableData.tenureName,
                minimum_balance: 0,
                isActive: 1,
                user: this.props.username
            }
           
            let accountName = editableData?.accountName || ''
            this.setState({
                isSubmitting: true,
                accountDetailsError : false
            }, () => {
                axios.post(url, data,
                    {
                        headers:{
                            Authorization: "Bearer "+getCognitoUserAccessToken()
                        }
                    })
                    .then(res => {
                        console.log(res.data);
                        //toaster
                        toast(accountName? TOASTER_MESSAGE.UPDATE_ACCOUNT.SUCCESS_TEXT1 +'\''+ accountName +'\''+ TOASTER_MESSAGE.UPDATE_ACCOUNT.SUCCESS_TEXT2
                            : TOASTER_MESSAGE.UPDATE_ACCOUNT.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });
                        
                        this.setState({
                            isSubmitting: false
                        }, () => {
                            this.onAccountDetailsSubmission(res)
                        })
                    })
                    .catch((err) => {
                        //toaster
                        toast(accountName? TOASTER_MESSAGE.UPDATE_ACCOUNT.ERROR_TEXT1 +'\''+ accountName +'\''+ TOASTER_MESSAGE.UPDATE_ACCOUNT.ERROR_TEXT2
                            : TOASTER_MESSAGE.UPDATE_ACCOUNT.ERROR,
                            {
                                type: TOASTER_TYPE.ERROR,
                                theme: TOASTER_THEME.LIGHT
                            });
                        this.setError();
                    })
            })
        } else {
            this.setState({
                accountNameError,
                companyNameError,
                accountDetailsError : true
            })
        }
    }

    // create new user 
    inviteNewUser = (inviteData, data) => {
        let url = TRY_FOR_FREE_CREATE_USERS
        axios.post(url, inviteData,
            {
                headers:{
                    Authorization: "Bearer "+getCognitoUserAccessToken()
                }
            })
            .then(res => {
                setTimeout(() => this.createNewAccount(data), 12000)
            })
            .catch(err => {
                this.setError(err.response.status, false)
            })
    }
    createNewAccount = (data) => {
        let url = ADD_ACCOUNT_WITH_USER
        let accountName = data?.account_name || ''
        axios.post(url, data,
            {
                headers:{
                    Authorization: "Bearer "+getCognitoUserAccessToken()
                }
            })
            .then(res => {
                //toaster
                toast(accountName ? TOASTER_MESSAGE.CREATE_ACCOUNT.SUCCESS_TEXT1 + '\'' + accountName + '\'' + TOASTER_MESSAGE.CREATE_ACCOUNT.SUCCESS_TEXT2
                    : TOASTER_MESSAGE.CREATE_ACCOUNT.SUCCESS,
                    {
                        type: TOASTER_TYPE.SUCCESS,
                        theme: TOASTER_THEME.LIGHT
                    });

                this.setState({
                    isSubmitting: false,
                }, () => {
                    this.onAccountDetailsSubmission(res);
                })
            })
            .catch(err => {
                //toaster
                toast(accountName ? TOASTER_MESSAGE.CREATE_ACCOUNT.ERROR_TEXT1 + '\'' + accountName + '\'' + TOASTER_MESSAGE.CREATE_ACCOUNT.ERROR_TEXT2
                    : TOASTER_MESSAGE.CREATE_ACCOUNT.ERROR,
                    {
                        type: TOASTER_TYPE.ERROR,
                        theme: TOASTER_THEME.LIGHT
                    });

                this.setError(err.response.status, true)
            })
    }

    // on submit account + user details
    onSubmitAccountData = () => {
        let { userEmail, accountName, companyName, accountType, tierLevel, 
            firstName, lastName, organisationName, jobTitle, isRegisteredUser, tenureName } = this.state;
        
        // let userEmailError = this.validateUserEmail(userEmail,true).error;
        let accountNameError = this.validateAccountName(accountName,true).error;
        let companyNameError = isValidCompanyNameString(companyName,true).error;

        if (!accountNameError && !companyNameError) {
            let inviteData = {
                first_name : firstName, last_name : lastName, job_title : jobTitle, 
                organisation_name : organisationName,
                communication_email: userEmail,
                accept_market_email : String(true)
            }
            let subscriptionType = [accountType,(Number(tenureName) > 1) ? String(tenureName) : ''].join('');
            let data = {
                account_name: accountName,
                subscription_type: subscriptionType,
                company_name: companyName,
                communication_email: userEmail,
                user_type: USERS.ADMIN_USER.TYPE,
                user_role: USERS.ADMIN_USER.ROLE,
                display_name: firstName + " " + lastName,
                user_tier_level: tierLevel,
                echo_test: 0,
                user: this.props.username
            }
            this.setState({
                isSubmitting: true,
                accountDetailsError: false
            }, () => {
                if (isRegisteredUser) {
                    this.createNewAccount(data)
                } else {
                    this.inviteNewUser(inviteData, data)
                }
            })
        } else {
            this.setState({
                accountNameError,
                companyNameError,
                accountDetailsError : true
            })
        }
    }

    // on click save and next button
    onClickNext = () => {
        let { userEmail, firstName, lastName, organisationName, jobTitle, userError } = this.state;

        let userEmailError = this.validateUserEmail(userEmail,true).error;
        if(!userEmailError && !userError){
            let isFirstNameError = isValidNameString(firstName,true).error;
            let isLstNameError = isValidNameString(lastName,true).error;
            let isJobTitleError = isValidJobTitleString(jobTitle,true).error;
            let isOrganisationNameError = isValidCompanyNameString(organisationName,true).error;
            if (!isFirstNameError && !isLstNameError
                && !isJobTitleError && !isOrganisationNameError) {
                    this.setState({
                        adminDetailsError : false,
                        enableAdminDetailPage: false
                    })
                } else {
                    this.setState({
                        isFirstNameError,
                        isLstNameError, isJobTitleError,
                        isOrganisationNameError,
                        adminDetailsError: true
                    })
                }
        } else {
            this.setState({
                userEmailError,
                adminDetailsError : true
            })
        }
    }

    // on click back button
    onClickBack = () => {
        this.setState({
            enableAdminDetailPage: true
        })
    }

    // on click verify email
    onClickVerify = () => {
        let { userEmail } = this.state;
        let userEmailError = isValidWorkEmailString(userEmail,true).error;
        if (!userEmailError) {
            this.setState({
                isVerifying: true,
                userEmailError
            }, () => {
                axios.post(GET_USER_PROFILE, { user_id: userEmail },
                    {
                        headers:{
                            Authorization: "Bearer "+getCognitoUserAccessToken()
                        }
                    })
                    .then((res) => {
                        this.setState({
                            ...handleSuccessVerfication(res)
                        }, () => {
                            if(this.state.adminDetailsError){
                                this.setState({
                                    userError : true,
                                    userEmailErrorText : ERROR_TEXTS.NOT_SUBSCRIBED
                                })
                            }
                        })
                    }).catch(err => {
                        // console.log(err)
                        if (err.response.status === ERROR_CODES.NOT_FOUND) {
                            this.setState({
                                isEmailVerified: true,
                                isRegisteredUser: false,
                                userEmailError: false,
                                adminDetailsError : true,
                            }, () => this.setState({
                                userError : true,
                                userEmailErrorText : ERROR_TEXTS.NOT_REGISTERED,
                                adminDetailsError : true
                            }))
                        } else {
                            this.setState({
                                ...handleErrorVerification()
                            })
                        }
                    })
            })
        } else {
            this.setState({
                userEmailError,
                adminDetailsError : true
            })
        }
    }

    onChangeOrganisationName = (orgname) => {
        this.setState({
            organisationName: orgname,
            isOrganisationNameError : false
        })
    }

    onChangeFirstName = (name) => {
        this.setState({
            firstName: name,
            isFirstNameError : false
        })
    }

    onChangeLastName = (name) => {
        this.setState({
            lastName: name,
            isLstNameError : false
        })
    }

    onChangeJobTitle = (jobtitle) => {
        this.setState({
            jobTitle: jobtitle,
            isJobTitleError : false
        })
    }

    // on click change email
    onClickChangeEmail = () => {
        this.setState({
            ...getInitialAdminDetails()
        })
    }

    render() {
        let { enableEditMode } = this.props;
        let { accountName, accountType, 
             tierLevel, userEmail, companyName, enableAdminDetailPage,
            isSubmitting, userEmailError, accountNameError, companyNameError,
            editableData, userEmailErrorText, isEmailVerified,
            isVerifying, isFirstNameError, isJobTitleError, isLstNameError, isOrganisationNameError,
            isRegisteredUser, firstName, lastName, organisationName, jobTitle, tenureType,
            accountDetailsError, adminDetailsError, userError, packageCredits, topupCredits } = this.state;

        let accountCreationFormComponent = null;
        let accountDetailForm = null;
        let userDetailForm = null;
        let subHeadingComponent = null;
        let verifyButtonComponent = null

        if (!enableEditMode) {
            subHeadingComponent = (
                <div className="d-flex flex-column flex-md-row">
                    <span className="d-inline-flex align-items-center">
                        <VscCircleFilled color='var(--color-primary)' />
                        <span
                            className={enableAdminDetailPage ? globalClasses.Font18BoldDarkerBlue : globalClasses.Font18BoldOrange}
                        >
                            Admin Details
                        </span>
                    </span>
                    <span
                        className="d-none d-md-block my-auto mx-3"
                        style={{
                            borderTop: enableAdminDetailPage ?
                                '2px solid #707070' : '2px solid var(--color-primary)',
                            minWidth: '50px'
                        }}
                    ></span>
                    <span
                        className="d-block d-md-none ms-2"
                        style={{
                            borderLeft: enableAdminDetailPage ?
                                '2px solid #707070' : '2px solid var(--color-primary)',
                            minHeight: '30px'
                        }}
                    ></span>
                    <span className="d-inline-flex align-items-center">
                        <VscCircleFilled color={enableAdminDetailPage ? '#707070' : 'var(--color-primary)'} />
                        <span
                            className={enableAdminDetailPage ? globalClasses.Font18BoldGrey44 : globalClasses.Font18BoldDarkerBlue }
                        >
                            Account Details
                        </span>
                    </span>
                </div>
            )
        }
        if (!isEmailVerified) {
            if (!isVerifying) {
                verifyButtonComponent = null
            } else {
                verifyButtonComponent = (
                    <span className='d-flex align-items-center justify-content-end ms-auto mb-2'>
                        <img className={globalClasses.LoaderExtraSmallSize} src={LoaderGIF} alt={'Verify'} />
                        <span className={globalClasses.Font16LoadText}>{'Verifying'}</span>
                    </span>
                )
            }
        } else {
            verifyButtonComponent = (
                <Button
                    label={'Change Email ID'}
                    containerClass={cx(['my-2 ms-auto'])}
                    className={"py-1"}
                    onClickHandler={this.onClickChangeEmail}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                    disabled={isSubmitting}
                />
            )
        }

        userDetailForm = (
            <>
                <div className="d-flex flex-wrap">
                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="usermail">
                        {USER_MNGMENT_STRINGS.USER_PANE.USER_MAIL_LABEL}
                    </label>
                    <div className="col-12 col-lg-8">
                        <TextField
                            containerClass='col-12'
                            value={userEmail}
                            id={'email'}
                            placeHolder={'Email ID'}
                            onChangeHandler={this.onChangeUserMail}
                            onBlurHandler={this.onClickVerify}
                            validate={this.validateUserEmail}
                            isDisabled={isSubmitting || isEmailVerified} 
                            isReadOnly={isSubmitting || isEmailVerified}
                            isError={adminDetailsError}
                            isFieldError={userEmailError}
                            isMandatory={true} 
                            isNumber={false}
                            enableVerification={true}
                            isVerified={isEmailVerified}
                            errorField={userError}
                            errorFieldText={userEmailErrorText}
                        />
                    </div>
                    {verifyButtonComponent}
                </div>
                {
                    isEmailVerified && isRegisteredUser &&
                    <>
                        {/* <div className="d-flex flex-wrap">
                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="userame">
                                {USER_MNGMENT_STRINGS.USER_PANE.USER_NAME_LABEL}
                            </label>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    containerClass='col-12'
                                    value={userName}
                                    id={'username'}
                                    placeHolder={'Display name'}
                                    onChangeHandler={this.onChangeUserName}
                                    validate={isValidTextString}
                                    isDisabled={isSubmitting || !isEmailVerified} 
                                    isReadOnly={isSubmitting || !isEmailVerified}
                                    isError={adminDetailsError && isEmailVerified}
                                    isFieldError={userNameError}
                                    isMandatory={true} 
                                    isNumber={false}
                                />
                            </div>
                        </div> */}
                        <div className="d-flex flex-wrap">
                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="firstname">
                                {"First Name"}
                            </label>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    containerClass='col-12'
                                    value={firstName}
                                    id={'firstname'}
                                    placeHolder={'First name'}
                                    onChangeHandler={this.onChangeFirstName}
                                    validate={isValidNameString}
                                    isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified} 
                                    isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                    isError={adminDetailsError && isEmailVerified}
                                    isFieldError={isFirstNameError}
                                    isMandatory={true} 
                                    isNumber={false}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap">
                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="lastname">
                                {"Last Name"}
                            </label>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    containerClass='col-12'
                                    value={lastName}
                                    id={'lastname'}
                                    placeHolder={'Last name'}
                                    onChangeHandler={this.onChangeLastName}
                                    validate={isValidNameString}
                                    isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified} 
                                    isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                    isError={adminDetailsError && isEmailVerified}
                                    isFieldError={isLstNameError}
                                    isMandatory={true} 
                                    isNumber={false}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap">
                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="jobtitle">
                                {"Job Title"}
                            </label>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    containerClass='col-12'
                                    value={jobTitle}
                                    id={'jobtitle'}
                                    placeHolder={'Job title'}
                                    onChangeHandler={this.onChangeJobTitle}
                                    validate={isValidJobTitleString}
                                    isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified} 
                                    isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                    isError={adminDetailsError && isEmailVerified}
                                    isFieldError={isJobTitleError}
                                    isMandatory={true} 
                                    isNumber={false}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap">
                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="organisationname">
                                {"Organisation Name"}
                            </label>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    containerClass='col-12'
                                    value={organisationName}
                                    id={'organisationname'}
                                    placeHolder={'Organisation name'}
                                    onChangeHandler={this.onChangeOrganisationName}
                                    validate={isValidCompanyNameString}
                                    isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified} 
                                    isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                    isError={adminDetailsError && isEmailVerified}
                                    isFieldError={isOrganisationNameError}
                                    isMandatory={true} 
                                    isNumber={false}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap">
                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="tierlevel">
                                {USER_MNGMENT_STRINGS.TIER_LEVEL_LABEL}
                            </label>
                            <div className="col-12 col-lg-8">
                                <SelectField
                                menuPlacement="top"
                                    containerClass={'col-12'} 
                                    value={tierLevel} 
                                    onChangeHandler={this.onChangeUserTierLevel} 
                                    isDisabled={isSubmitting}
                                    isReadOnly={isSubmitting} 
                                    id={"tierlevel"}
                                    isSearchable={false}
                                    optionsList={['1','2','3','4','5','6']}
                                    isMandatory={true}
                                />
                            </div>
                        </div>
                    </>
                }
            </>
        )

        if (!enableEditMode || !isEmpty(editableData)) {
            accountDetailForm = (
                <>
                    <div className="d-flex flex-wrap">
                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="accountname">
                            {'Account Name'}
                        </label>
                        <div className="col-12 col-lg-8">
                            <TextField
                                containerClass='col-12'
                                value={enableEditMode ? editableData.accountName : accountName}
                                id={'accountname'}
                                placeHolder={'Account Name'}
                                onChangeHandler={this.onChangeAccountName}
                                validate={this.validateAccountName}
                                isDisabled={isSubmitting} 
                                isReadOnly={isSubmitting}
                                isError={accountDetailsError}
                                isFieldError={accountNameError}
                                isMandatory={true} 
                                isNumber={false}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap">
                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="companyname">
                            {'Company Name'}
                        </label>
                        <div className="col-12 col-lg-8">
                            <TextField
                                containerClass='col-12'
                                value={enableEditMode ? editableData.companyName : companyName}
                                id={'companyname'}
                                placeHolder={'Company Name'}
                                onChangeHandler={this.onChangeCompanyName}
                                validate={isValidCompanyNameString}
                                isDisabled={isSubmitting} 
                                isReadOnly={isSubmitting}
                                isError={accountDetailsError}
                                isFieldError={companyNameError}
                                isMandatory={true} 
                                isNumber={false}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap">
                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="accounttype">
                            {'Subscription Type'}
                        </label>
                        <div className="col-12 col-lg-8">
                            <SelectField
                                containerClass={'col-12'} 
                                value={enableEditMode ? editableData.accountType : accountType} 
                                onChangeHandler={this.onChangeSubscriptionType} 
                                // isDisabled={isSubmitting || enableEditMode}
                                // isReadOnly={isSubmitting || enableEditMode}
                                isDisabled={true}
                                isReadOnly={true}
                                id={"accounttype"}
                                optionsList={[ACCOUNT_TYPE.CUSTOM,ACCOUNT_TYPE.BASIC,ACCOUNT_TYPE.BRONZE,ACCOUNT_TYPE.TRIAL]} 
                                isMandatory={true}
                            />
                        </div>
                    </div>
                    {
                        (accountType !== ACCOUNT_TYPE.TRIAL) &&
                        <div className="d-flex flex-wrap">
                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="tenuretype">
                                {'Subscription Tenure'}
                            </label>
                            <div className="col-12 col-lg-8">
                                <SelectField
                                    containerClass={'col-12'} 
                                    value={enableEditMode ? editableData.tenureType : tenureType} 
                                    onChangeHandler={this.onChangeSubscriptionTenureType} 
                                    // isDisabled={isSubmitting || enableEditMode}
                                    // isReadOnly={isSubmitting || enableEditMode}
                                    isDisabled={true}
                                    isReadOnly={true}
                                    id={"tenuretype"}
                                    isMandatory={true}
                                    optionsList={[{
                                        value : 3,
                                        label : '1 month'
                                    },{
                                        value : 0,
                                        label : '3 months'
                                    },{
                                        value : 1,
                                        label : '6 months'
                                    },{
                                        value : 2,
                                        label : '12 months'
                                    }]}
                                />
                            </div>
                        </div>
                    }
                    {
                        !enableEditMode &&
                        <>
                            <div className="d-flex flex-wrap mt-2">
                                <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="tierlevel">
                                    {'Package Default Credits (USD)'}
                                </label>
                                <label className={cx(["col-12 col-lg-8 p-2"])}>
                                    {getDollarFromNumber(Number(packageCredits).toFixed(3))}
                                </label>
                            </div>
                            <div className="d-flex flex-wrap mt-2">
                                <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="tierlevel">
                                    {'Top-up Amount'}
                                </label>
                                <label className={cx(["col-12 col-lg-8 p-2"])}>
                                    {getDollarFromNumber(Number(topupCredits).toFixed(3))}
                                </label>
                            </div>
                        </>
                    }
                </>
            )
        }

        accountCreationFormComponent = enableAdminDetailPage && !enableEditMode ? userDetailForm : accountDetailForm

        return (
            <AccountCreationPopup
                title={enableEditMode ? 'Edit Account' : 'Add New Account'}
                onCloseHandler={this.onClosePopup}
                onSubmitHandler={enableEditMode ? this.onClickEditDone : enableAdminDetailPage ? this.onClickNext : this.onSubmitAccountData}
                isSubmitting={isSubmitting}
                submittingText={'Submitting'}
                subHeadingComponent={subHeadingComponent}
                submitButtonText={!enableAdminDetailPage || enableEditMode ? EMPTY_STRING : 'Save & Next'}
                submitButonWithIcon={enableAdminDetailPage && !enableEditMode}
                submitButonIconPosition={BUTTON_ICON_POSITION.RIGHT}
                enableBack={!enableAdminDetailPage}
                disableSubmitButton={!isEmailVerified && !enableEditMode}
                onClickBack={this.onClickBack}
            >
                {accountCreationFormComponent}
            </AccountCreationPopup>
        )
    }
}

export default AccountCreationFormPopup;