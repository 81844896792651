import cx from 'classnames';
import LOGO from '../../assets/images/logos/vivasai-logo-light.png'
import classes from './TryForFree.module.css';
import TextField from "../../components/ui_elements/form_fields/text_input/TextField";
import Button from "../../components/ui_elements/button/ButtonNew";
import { useState } from 'react';
import { BUTTON_TEXTS, EMPTY_STRING, FORM_LABEL_TEXTS, FORM_PLACEHOLDER_TEXTS } from '../../strings/Strings';
import { isEmpty, isValidConfirmPassword, isValidNumberString, isValidPassword, 
    isValidWorkEmailString, getUTCTime } from '../../utils/UtilityFunctions';
import Loader from '../../components/loader/Loader';
import globalClasses from '../../App.module.css'
import { getCognitoUser, getErrorMessage } from './Cognito.utils';
import { ROUTE_CONSTANTS } from '../../routes/RouteConstants';
import SuccessScreen from '../../components/success_screen/SuccessScreen';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { AuditLog } from "../../../src/utils/AuditUtils";
import { EVENT, PAGE_TITLE } from "../../utils/Constants";
import VerificationCodeField from '../../components/ui_elements/form_fields/verify_code_field/VerificationCodeField';
import ConfirmPasswordField from '../../components/ui_elements/form_fields/password_input/ConfirmPasswordField';
import axios from 'axios';
import { VALIDATE_USER_CONFIRMATION } from '../../urls/Urls';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';
import ComponentHelmet from '../../components/component_helmet/ComponentHelmet';

function ResetPassword(props) {

    let formContainer = null;
    let formComponent = null;

    let [isLoading, setIsLoading] = useState(false)
    let [isSubmitError, setIsSubmitError] = useState(false)
    let [formErroMsg, setFormErrorMessage] = useState(EMPTY_STRING)

    let [step, setStep] = useState(1)

    let [emailValue, setEmailValue] = useState(EMPTY_STRING)
    let [isEmailValueError, setEmailValueError] = useState(EMPTY_STRING)

    let [verificationCode, setVerificationCode] = useState(EMPTY_STRING)
    let [isVerificationError, setVerificationError] = useState(EMPTY_STRING)

    let [resetValue, setResetValue] = useState(EMPTY_STRING)
    let [resetValueError, setResetValueError] = useState(EMPTY_STRING)

    let [confirmValue, setConfirmValue] = useState(EMPTY_STRING)
    let [confirmValueError, setConfirmValueError] = useState(EMPTY_STRING)

    // let [enableResend, setEnableResend] = useState(false)

    const gotoLogin = () => {
        window.location.replace(ROUTE_CONSTANTS.LOGIN)
    }

    const onChangeEmail = (email) => {
        setEmailValue(email)
        setEmailValueError(false)
    }

    const onChangeVerificationCode = (verificationCode) => {
        setVerificationCode(verificationCode)
        setVerificationError(false)
    }

    const onChangeNewPassword = (newPassword) => {
        setResetValue(newPassword)
        setResetValueError(false)
        if (isSubmitError) {
            let isConfirmPasswordError = isValidConfirmPassword(newPassword, confirmValue).error;
            setConfirmValueError(isConfirmPasswordError)
        }
    }

    const onChangeConfirmPassword = (confirmPassword) => {
        setConfirmValue(confirmPassword)
        setConfirmValueError(false)
    }

    const sendCode = () => {
        let cognitoUser = getCognitoUser(emailValue)

        axios.post(VALIDATE_USER_CONFIRMATION, {
            "communication_email": emailValue
        })
            .then(res => {
                if (res?.data && res?.data?.verified == 'true') {
                    cognitoUser.forgotPassword({
                        onSuccess: (result) => {
                            setIsLoading(false)
                            setStep(2)
                            setFormErrorMessage(EMPTY_STRING)
                        },
                        onFailure: (err) => {
                            setIsLoading(false)
                            setFormErrorMessage(getErrorMessage(err))
                        }
                    });
                }else{
                    setIsLoading(false)
                    setFormErrorMessage("User not found in Vivas.AI account")
                }
            })
            .catch(err => {
                console.log("error", err)
                setIsLoading(false)
                setFormErrorMessage(getErrorMessage(err))

            })



    }

    const onClickSendCode = () => {
        let isEmailError = isValidWorkEmailString(emailValue, true).error;
        if (!isEmailError) {
            setIsLoading(true)
            setIsSubmitError(false)
            setEmailValueError(false)
            sendCode()
        } else {
            setIsSubmitError(true)
            setEmailValueError(isEmailError)
        }
    }

    const onSubmitReset = () => {
        let isVerificationError = isValidNumberString(verificationCode, true).error;
        let isPasswordError = isValidPassword(resetValue, true).error;
        let isConfirmPasswordError = isValidConfirmPassword(resetValue, confirmValue).error;
        setFormErrorMessage(EMPTY_STRING)

        if (!isVerificationError && !isPasswordError && !isConfirmPasswordError) {
            setIsLoading(true)
            setIsSubmitError(false)
            setVerificationError(false)
            setResetValueError(false)
            setConfirmValueError(false)

            let cognitoUser = getCognitoUser(emailValue)
            
            //audit log
            let startDateTime = new Date()

            //audit log - common properties
            let eventDetails = EVENT.USER_MANAGEMENT_CATEGORY.FORGOT_PASSWORD
            eventDetails = {
                ...eventDetails,
                eventCategory: EVENT.USER_MANAGEMENT_CATEGORY.CATEGORY_NAME,
                eventStartDateTime: startDateTime
            }
            let eventAttributes = {
                email: emailValue ? emailValue : null
            }

            cognitoUser.confirmPassword(verificationCode, resetValue, {
                onFailure: (err) => {
                    //audit log - error scenario
                    eventDetails = {
                        ...eventDetails,
                        eventStatusCode: err?.response?.status || null,
                        eventStatus: 'Failure',
                        eventMessage: err?.message || err?.response?.statusText || 'Reset password error',
                    }
                    AuditLog(eventDetails, eventAttributes)

                    setIsLoading(false)
                    setFormErrorMessage(getErrorMessage(err))
                },
                onSuccess: (result) => {
                     //audit log - success scenario
                     eventDetails = {
                        ...eventDetails,
                        eventStatusCode: result?.status || '200',
                        eventStatus: 'Success',
                        eventMessage: result?.statusText || 'Reset password success'
                    }
                    AuditLog(eventDetails, eventAttributes)

                    setIsLoading(false)
                    setStep(3)
                    setFormErrorMessage(EMPTY_STRING)
                    setTimeout(gotoLogin, 3000)
                },
            });
        } else {
            setIsSubmitError(true)
            setVerificationError(isVerificationError)
            setResetValueError(isPasswordError)
            setConfirmValueError(isConfirmPasswordError)
        }
    }

    if (step === 1) {
        formComponent = (
            <>
                {/* <p className={cx(["col-12", classes.SigninText])}>
                    Enter your email address and we'll send you the verification code.
                </p> */}
                <h3 className={cx([globalClasses.FontGoogleSans, globalClasses.FontBold, "text-start mb-4 mt-3"])}>
                    Forgot Password
                </h3>
                <div className='col-12'>
                    <TextField
                        containerClass='col-12'
                        value={emailValue}
                        label={FORM_LABEL_TEXTS.EMAIL}
                        id={'email'}
                        placeHolder={FORM_PLACEHOLDER_TEXTS.LOGIN_EMAIL}
                        onChangeHandler={onChangeEmail}
                        validate={isValidWorkEmailString}
                        isDisabled={isLoading}
                        isReadOnly={isLoading}
                        isError={isSubmitError}
                        isFieldError={isEmailValueError}
                        isMandatory={true}
                        isNumber={false}
                    />
                </div>
                {!isEmpty(formErroMsg) && <div className={cx(['align-self-center col-12', globalClasses.Error])}> {formErroMsg}</div>}
                <div className='col-12 mt-4'>
                    <Button
                        label={BUTTON_TEXTS.SEND_CODE}
                        className={cx(["col-12"])}
                        actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                        borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                        onClickHandler={onClickSendCode}
                    />
                </div>
            </>
        )
    } else if (step === 2) {
        formComponent = (
            <>
                {/* <VerifyEmail email={emailValue} noVerification={true} getVerificationCode={getVerificationCode} route={props.history} /> */}
                <h3 className={cx([globalClasses.FontGoogleSans, globalClasses.FontBold, "text-start mb-4 mt-3"])}>
                    Reset Password
                </h3>
                {/* <p className={cx(["col-12", classes.SigninText])}>
                    Enter the verification code that we have sent you via email and enter your new password.
                </p> */}
                <div className='col-12'>
                    <VerificationCodeField
                        containerClass='col-12'
                        value={verificationCode}
                        // label={FORM_LABEL_TEXTS.FIRST_NAME}
                        label={FORM_LABEL_TEXTS.VERFICATION_CODE}
                        id={'verificationCode'}
                        placeHolder={FORM_PLACEHOLDER_TEXTS.VERFICATION_CODE}
                        onChangeHandler={onChangeVerificationCode}
                        validate={isValidNumberString}
                        isDisabled={isLoading}
                        isReadOnly={isLoading}
                        isError={isSubmitError}
                        isFieldError={isVerificationError}
                        isMandatory={true}
                        isNumber={true}
                        resendVerificationCode={sendCode}
                    />
                </div>
                <div className='col-12 mt-2'>
                    <ConfirmPasswordField
                        containerClass={"col-12"}
                        password={resetValue}
                        confirmPassword={confirmValue}
                        passwordLabel={FORM_LABEL_TEXTS.NEW_PASSWORD}
                        confirmLabel={FORM_LABEL_TEXTS.CONFIRM_PASSWORD}
                        isDisabled={isLoading}
                        isReadOnly={isLoading}
                        isSubmitError={isSubmitError}
                        passwordPlaceholder={FORM_PLACEHOLDER_TEXTS.NEW_PASSWORD}
                        passwordInfo={FORM_LABEL_TEXTS.VALID_PASSWORD_TEXT}
                        confirmPasswordPlaceholder={FORM_PLACEHOLDER_TEXTS.CONFIRM_PASSWORD}
                        onChangePassword={onChangeNewPassword}
                        isErrorPassword={resetValueError}
                        onChangeConfirmPassword={onChangeConfirmPassword}
                        isErrorConfirmPasword={confirmValueError}
                    />
                </div>
                {!isEmpty(formErroMsg) && <div className={cx(['align-self-center col-12', globalClasses.Error])}> {formErroMsg}</div>}
                <div className='col-12 mt-4'>
                    <Button
                        label={BUTTON_TEXTS.CONFIRM_PASS}
                        className={cx(["col-12"])}
                        actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                        borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                        onClickHandler={onSubmitReset}
                    />
                </div>
            </>
        )
    }

    if (step === 3) {
        formContainer = (
            <div className={cx(['col-12 align-items-center', classes.AuthModal])}>
                <SuccessScreen
                    className={"col-12 px-0 py-3"}
                    mainText={"Password Changed Successfully"}
                    subText={"You will be redirected to the login page. Please login with your new password."}
                />
            </div>
        )
    } else {
        formContainer = (
            <div className={'col-12'}>
                <span className={cx([globalClasses.Font12Grey, globalClasses.PointerCursor])} onClick={gotoLogin}>
                    <HiOutlineChevronLeft size={12} style={{ margin: '0px 3px 3px 0px' }} />Cancel
                </span>
                {/* <div className="text-center mb-3">
                    <img className={classes.AuthLogo} src={LOGO} alt={"Vivas.AI"} title="Vivas.AI" />
                </div> */}
                {formComponent}

            </div>
        )
    }



    return (
        <div className={classes.TryForFreeBack}>
            <ComponentHelmet
                title={[PAGE_TITLE.RESET_PASSWORD.TITLE]}
                description={PAGE_TITLE.RESET_PASSWORD.DESCRIPTION}
                path={process.env.REACT_APP_DOMAIN_URL + window.location.pathname}
            />
            {isLoading && <Loader containerClass={classes.Loading} />}
            {formContainer}
        </div>
    )
}

export default ResetPassword;