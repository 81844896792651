import cx from 'classnames'
import classes from './ModalCard.module.css';

import closeIcon from '../../../assets/images/cancel.png'
import { isEmpty } from '../../../utils/UtilityFunctions';
import {  useEffect, useRef } from 'react';

function ModalCard(props) {
    let { className, titleContainerClass, onClose, isForm, isPopupFixed, parentRef, onlyClose,
        enableScroll, title, isLoading, isDraggable, titleClass, enableCloseOutsideClick } = props;
    
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let modalRef = useRef();
    let modalHeaderRef = useRef();
    let iconComponent = null, titleComponent = null;
    let containerStyle = cx([className, !isForm? classes.ModalContainer: classes.FormModalContainer])
    const closeModal = () => {
        if(!isLoading){
            if(!onlyClose){
                document.body.style.overflow = 'auto';
            }
            if(!isEmpty(onClose)){
                onClose()
            }
        }
    }
    const OnClickOutsideHandler = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && !isLoading) {
                    if(isEmpty(parentRef) || (parentRef?.current && !parentRef.current.contains(event.target))){
                        closeModal()
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref,isLoading]);
    }

    const elementDrag = (event) => {
        event.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - event.clientX;
        pos2 = pos4 - event.clientY;
        pos3 = event.clientX;
        pos4 = event.clientY;
        // set the element's new position:
        try{
            modalRef.current.style.top = (modalRef.current.offsetTop - pos2) > 0 ? (modalRef.current.offsetTop - pos2) + "px" : "0px";
            modalRef.current.style.left = (modalRef.current.offsetLeft - pos1) > 0 ? (modalRef.current.offsetLeft - pos1) + "px" : "0px";
        } catch(err){
            console.log(err.message)
        }
    }

    const closeDragElement = () => {
        document.removeEventListener("mouseup",closeDragElement);
        document.removeEventListener("mousemove",elementDrag);
    }

    const dragMouseDown = (event) => {
        console.log("DragModal dragMouseDown",event)
        event.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = event.clientX;
        pos4 = event.clientY;
        document.addEventListener("mouseup",closeDragElement);
        document.addEventListener("mousemove",elementDrag);
    }

    useEffect(() => {
        if(isDraggable){
            try{
                if (modalHeaderRef.current) {
                    console.log("DragModal modelHeaderRef",modalHeaderRef.current)
                    modalHeaderRef.current.addEventListener('mousedown',dragMouseDown);
                }
            } catch(err){
                console.log(err.message)
            }
        }
        return () => {
            try{
                if (modalHeaderRef.current) {
                    modalHeaderRef.current.removeEventListener('mousedown',dragMouseDown);
                }
            } catch(err){
                console.log(err.message)
            }
        };
    }, [isDraggable]);

    if((isPopupFixed && !isDraggable) || enableCloseOutsideClick){
        OnClickOutsideHandler(modalRef)
    }
    if (isPopupFixed) {
        if(!enableScroll)
            document.body.style.overflow = 'hidden';

        iconComponent = <span className='ms-auto'>
            <img
                src={closeIcon}
                alt='Close'
                onClick={closeModal}
                className={classes.CloseButton}
            />
        </span>
    }
    if(!isEmpty(title)){
        titleComponent = (
            <span ref={modalHeaderRef} 
                className={cx([classes.ModalTitle, titleClass, isDraggable && classes.ModalDraggableTitle])}
            >
                {title}
            </span>
        )
    }
    return (
        <div className={containerStyle} ref={modalRef}>
            <div className={cx(['col-12 d-flex align-items-center',titleContainerClass])}>
                {titleComponent}
                {iconComponent}
            </div>
            <div className={isDraggable && classes.ModalDraggableContentContainer}>
                {props.children}
            </div>
        </div>
    )
}

export default ModalCard;