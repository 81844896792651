// modules
import { Component } from "react";
import cx from 'classnames';
import axios from "axios";

//utils
import { BUTTON_ICON_POSITION, CONST_VALUE, ERROR_CODES, EXPIRY_DAY, SORT_TABLE_VALUES, STRING_VALUE, USER_MANAGEMENT, USER_ROLE, EVENT, THEME } from "../../../utils/Constants";
import { copyToClipboard, isEmpty, isEmptyList, isEmptyObject } from "../../../utils/UtilityFunctions";
import { ACTIONS, APIKEYS_PANE_INITIAL_STATE, getInitialServiceAccountCreation } from "../UserManagement.utils";
import { USER_MNGMENT_STRINGS } from "../../../strings/UserManagement";
import { BUTTON_TEXTS, EMPTY_STRING, SEPARATORS, TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../../strings/Strings";
import { AuditLog } from "../../../utils/AuditUtils";

// classes
import classes from '../UserManagement.module.css';
import globalClasses from '../../../App.module.css'

// component
import ConfirmPopup from "../../../components/confirm_popup/ConfirmPopup";
import Button from "../../../components/ui_elements/button/ButtonNew";
import NoData from "../../../components/no_data/NoData";
import Loader from "../../../components/loader/Loader";
import AccountCreationPopup from "../AccountCreatePopup";

// images
import ADD_ICON from "../../../assets/images/user-manage/add-icon.png"
import EDIT_ICON from '../../../assets/images/user-manage/edit-icon.png'
import COPY_ICON from '../../../assets/images/user-manage/copy-icon.png'
import TRASH_ICON from '../../../assets/images/user-manage/trash-icon.png'
import TICK_ICON from '../../../assets/images/user-manage/tick-icon.png'

// urls
import { GENERATE_AUTH_TOKEN, SERVICE_ACCOUNT_CREATE_EDIT, SERVICE_ACCOUNT_DELETE, SERVICE_ACCOUNT_RENEW_SECRET } from "../../../urls/Urls";
import CopyButton from "../../../components/ui_elements/copy_button/CopyButton";
import CheckBoxInput from "../../../components/ui_elements/form_fields/checkbox_input/CheckBoxInput";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField";
import { getCognitoUserAccessToken } from "../../sign_up/Cognito.utils";
import SortingTable from "../../../components/sorting_table/SortingTable";
import { toast } from 'react-toastify';
import { MdSettings } from "react-icons/md";
import { BUTTON_TYPES } from "../../../utils/ButtonConstants";
import { IMAGES } from "../../../utils/ImageConstants";
class ApiKeysPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...APIKEYS_PANE_INITIAL_STATE
        }
    }

    // set loader to the pane and refresh the data
    updatePane = (key, data) => {
        // console.log("APICreation axios call refresh")
        this.props.refreshData(key, data);
    }

    // set error data for the component
    setError = (errorCode) => {
        // this.props.setErrorData();
        if (errorCode === ERROR_CODES.CONFLICT) {
            this.setState({
                isSubmitting: false,
                isApiKeysPaneLoading: false,
                isNewAccountNameError: true,
                newAccountNameErrorText: 'This service account is already added'
            })
        } else {
            this.setState({
                ...getInitialServiceAccountCreation()
            })
        }
    }

    // to cancel the edit
    cancelEdit = () => {
        if (this.state.accountEditable) {
            this.setState({
                actionableData: null,
                accountEditable: false,
            })
        }
    }

    // on click add service account
    onCreateServiceAccountClicked = () => {
        this.cancelEdit()
        this.setState({
            showAddAccountPopup: true
        })
    }

    // on submit the account creation
    onClickAccountCreation = () => {
        // console.log("APICreation call")
        let { newAccountName, newAccountTierLevel, isEchoTestAccount } = this.state;
        let url = SERVICE_ACCOUNT_CREATE_EDIT
        let data = {
            service_account_name: newAccountName,
            service_tier_level: 6,//Number(newAccountTierLevel),
            echo_test: CONST_VALUE.FALSE,
            user: this.props.username,
            account_id: this.props.accountID
        }

        //#region - audit log
        let startDateTime = new Date()
                
        // audit log - common properties
        let eventDetails = EVENT.MANAGE_CATEGORY.GENERATE_API_KEY
        eventDetails = {
            ...eventDetails,
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }

        let additionalDetails = {} //actionable data
        if(data){
            additionalDetails = {
                serviceAccountName: data?.service_account_name,
                serviceTierLevel: data?.service_tier_level,
                echoTest: data?.echo_test
            }
        }

        //#endregion
        if (!isEmpty(newAccountName)) {

            this.setState({
                isSubmitting: true
            }, () => {
                // console.log("APICreation axios call")
                axios.post(url, data,
                    {
                        headers: {
                            Authorization: "Bearer " + getCognitoUserAccessToken()
                        }
                    })
                    .then(res => {
                        // console.log("APICreation axios call success")
                        //audit log - success scenario
                        eventDetails = {
                            ...eventDetails,
                            eventStatusCode: res?.status || '200',
                            eventStatus: 'Success',
                            eventMessage: res?.data?.message || res?.statusText || 'Generate API Key success'
                        }
                        
                        //toaster
                        toast(TOASTER_MESSAGE.GENERATE_API_KEY.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });

                        this.setState({
                            isSubmitting: false,
                            isApiKeysPaneLoading: true
                        }, () => {
                            // console.log("APICreation axios call updation")
                            this.updatePane(ACTIONS.INSERT, res.data)
                            this.onCloseAddAccountPopup();
                        })
                    })
                    .catch(err => {
                        // console.log("APICreation axios call error")
                        this.setError(err.response.status)

                        //audit log - error scenario
                        if (err) {
                            eventDetails = {
                                ...eventDetails,
                                eventStatus: 'Failure',
                                eventStatusCode: err?.response?.status || null,
                                eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Generate API Key error',
                            }
                        }

                        //toaster
                        toast(TOASTER_MESSAGE.GENERATE_API_KEY.ERROR,
                            {
                                type: TOASTER_TYPE.ERROR,
                                theme: TOASTER_THEME.LIGHT
                            });
                    })
                    .finally(() => {
                        //audit log
                        this.insertToAuditLog(eventDetails, additionalDetails)
                    })
            })
        } else {
            this.setState({
                isNewAccountNameError: true,
                newAccountNameErrorText: 'This field should not be empty.'
            })
        }
    }

    // on close/cancel the add service account popup
    onCloseAddAccountPopup = () => {
        let { isSubmitting } = this.state;
        if (!isSubmitting) {
            if (document.body.style.overflow === 'hidden') {
                document.body.style.overflow = 'auto'
            }
            this.setState({
                ...getInitialServiceAccountCreation()
            })
        }
    }

    // on click edit icon
    onClickEditAccount = (data) => {
        let actionableData = Object.assign({}, data)
        this.setState({
            accountEditable: true,
            actionableData
        })
    }

    // on click done icon after edit
    onClickEditDone = (item) => {
        let { actionableData } = this.state;

        if(Number(actionableData.tier) !== Number(item.tier) || actionableData.echoTest !== item.echoTest){
            
            let url = SERVICE_ACCOUNT_CREATE_EDIT
            let data = {
                service_account_name: actionableData.userName,
                service_tier_level: Number(actionableData.tier),
                echo_test: actionableData.echoTest === STRING_VALUE.TRUE ? CONST_VALUE.TRUE : CONST_VALUE.FALSE,
                user: this.props.username,
                account_id: this.props.accountID,
                client_id: actionableData.clientID
            }

            //#region - audit log
            let startDateTime = new Date()

            // audit log - common properties
            let eventDetails = EVENT.MANAGE_CATEGORY.EDIT_ACCOUNT
            eventDetails = {
                ...eventDetails,
                eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
                eventStartDateTime: startDateTime
            }

            let additionalDetails = {}
            if (data) {
                additionalDetails = {
                    serviceAccountName: data?.service_account_name,
                    serviceTierLevel: data?.service_tier_level,
                    echoTest: data?.echo_test,
                    clientId: data?.client_id
                }
            }
            //#endregion

            this.setState({
                accountEditable: false
            }, () => {
                this.setState({
                    isApiKeysPaneLoading: true
                }, () => {
                    axios.post(url, data,
                        {
                            headers: {
                                Authorization: "Bearer " + getCognitoUserAccessToken()
                            }
                        })
                        .then(res => {
                            //audit log - success scenario
                            eventDetails = {
                                ...eventDetails,
                                eventStatusCode: res?.status || '200',
                                eventStatus: 'Success',
                                eventMessage: res?.data?.message || res?.statusText || 'Edit account success'
                            }

                            //toaster
                            toast(TOASTER_MESSAGE.EDIT_API_KEY.SUCCESS,
                                {
                                    type: TOASTER_TYPE.SUCCESS,
                                    theme: TOASTER_THEME.LIGHT
                                });

                            this.updatePane(ACTIONS.UPDATE, actionableData);
                        })
                        .then(() => {
                            this.setState({
                                isApiKeysPaneLoading: false
                            })
                        })
                        .catch(err => {
                            //audit log - error scenario
                            if (err) {
                                eventDetails = {
                                    ...eventDetails,
                                    eventStatus: 'Failure',
                                    eventStatusCode: err?.response?.status || null,
                                    eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Edit account error',
                                }
                            }
                            this.setError(err.response.status)

                            //toaster
                            toast(TOASTER_MESSAGE.EDIT_API_KEY.ERROR,
                                {
                                    type: TOASTER_TYPE.ERROR,
                                    theme: TOASTER_THEME.LIGHT
                                });
                        })
                        .finally(() => {
                            //audit log
                            this.insertToAuditLog(eventDetails, additionalDetails)
                        })
                })
            })
        } else {
            this.setState({
                accountEditable: false,
                actionableData : null
            })
        }
    }

    // on change tier in edit mode
    onChangeEditableTierLevel = (tierLevel) => {
        let { actionableData } = this.state;
        actionableData['tier'] = tierLevel
        this.setState({
            actionableData
        })
    }

    // on change echo test in edit mode
    onChangeEditableEchotest = (echoTest) => {
        let { actionableData } = this.state;
        actionableData['echoTest'] = echoTest !== STRING_VALUE.TRUE ? STRING_VALUE.TRUE : STRING_VALUE.FALSE
        this.setState({
            actionableData
        })
    }

    // on click refresh icon
    onClickRefreshSecret = (detail) => {
        let url = SERVICE_ACCOUNT_RENEW_SECRET
        let data = {
            client_id: detail.clientID,
            user: this.props.username
        }
        this.setState({
            isApiKeysPaneLoading: true
        }, () => {
            axios.post(url, data, {
                headers: {
                    Authorization: "Bearer " + getCognitoUserAccessToken()
                }
            })
                .then(res => {
                    this.cancelEdit();
                    this.updatePane(ACTIONS.RENEW, res.data)
                })
                .then(() => {
                    this.setState({
                        isApiKeysPaneLoading: false
                    })
                })
                .catch(err => {
                    this.setError(err.response.status)
                })
        })
    }

    // on click delete icon
    onClickDeleteAccount = (detail) => {
        this.cancelEdit()
        this.setState({
            showDeletePopup: true,
            actionableData: detail
        })
    }

    // on click delete button in popup
    onConfirmDelete = () => {
        let { actionableData } = this.state
        let url = SERVICE_ACCOUNT_DELETE
        let data = {
            client_id: actionableData.clientID,
            user: this.props.username
        }

        //#region - audit log
        let startDateTime = new Date()
                
        // audit log - common properties
        let eventDetails = EVENT.MANAGE_CATEGORY.DELETE_ACCOUNT
        eventDetails = {
            ...eventDetails,
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }

        let additionalDetails = {}
        if (data) {
            additionalDetails = {
                clientId: data?.client_id
            }
        }
        
        //#endregion

        this.setState({
            isDeleting: true,
        }, () => {
            axios.post(url, data,
                {
                    headers: {
                        Authorization: "Bearer " + getCognitoUserAccessToken()
                    }
                })
                .then(res => {
                    //audit log - success scenario
                    eventDetails = {
                        ...eventDetails,
                        eventStatusCode: res?.status || '200',
                        eventStatus: 'Success',
                        eventMessage: res?.data?.message || res?.statusText || 'Delete account success'
                    }

                    //toaster
                    toast(TOASTER_MESSAGE.DELETE_API_KEY.SUCCESS,
                        {
                            type: TOASTER_TYPE.SUCCESS,
                            theme: TOASTER_THEME.LIGHT
                        });

                    this.updatePane(ACTIONS.DELETE, actionableData)
                    this.onCloseDeletePopup();
                })
                .catch(err => {
                    //audit log - error scenario
                    if (err) {
                        eventDetails = {
                            ...eventDetails,
                            eventStatus: 'Failure',
                            eventStatusCode: err?.response?.status || null,
                            eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Delete account error',
                        }
                    }
                    this.setError(err.response.status)

                    //toaster
                    toast(TOASTER_MESSAGE.DELETE_API_KEY.ERROR,
                        {
                            type: TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });
                })
                .finally(() => {
                    //audit log
                    this.insertToAuditLog(eventDetails, additionalDetails)
                })
        })
    }

    // on cancel/close delete popup
    onCloseDeletePopup = () => {
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            showDeletePopup: false,
            isDeleting: false,
            isApiKeysPaneLoading: false,
        })
    }

    // on change name in add new service account popup
    onChangeNewAccountName = (accountName) => {
        let newAccountNameErrorText = '';
        let isNewAccountNameError = false
        let trimmedAccountName = accountName.replace(/^\s+/g, '');
        trimmedAccountName = trimmedAccountName.replace(/\s{2}/g, ' ');
        this.setState({
            newAccountName: trimmedAccountName,
            isNewAccountNameError,
            newAccountNameErrorText
        })
    }

    // on change tierlevel in add new service account popup
    onChangeNewAccountTierLevel = (tierLevel) => {
        this.setState({
            newAccountTierLevel: tierLevel
        })
    }

    // on change echo test check in add new service account popup
    onChangeEchoTestCheckbox = () => {
        let { isEchoTestAccount } = this.state;
        this.setState({
            isEchoTestAccount: !isEchoTestAccount
        })
    }

    // copy client secret
    onClickCopyIcon = (details) => {
        copyToClipboard(details)
    }

    // generate token
    onClickGenerate = (apiKeyDetails) => {
        this.setState({
            isApiKeysPaneLoading: true
        }, () => {
            let url = GENERATE_AUTH_TOKEN
            let data = {
                client_id: apiKeyDetails.clientID
            }
            axios.post(url, data,
                {
                    headers: {
                        Authorization: "Bearer " + getCognitoUserAccessToken()
                    }
                })
                .then((res) => {
                    this.setState({
                        isTokenGenerate: true,
                        tokenCredentials: {
                            clientID: apiKeyDetails.clientID,
                            clientSecret: apiKeyDetails.clientSecret
                        },
                        generatedToken: res.data.token,
                        isApiKeysPaneLoading: false
                    })
                })
                .catch(this.getInitialTokenStates)
        })
    }

    // disable token
    getInitialTokenStates = () => {
        if (document.body.style.overflow !== 'auto') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            isTokenGenerate: false,
            tokenCredentials: null,
            generatedToken: EMPTY_STRING,
            isApiKeysPaneLoading: false
        })
    }

    // get time data component
    getTableTimeData = (time) => {
        let dateTimeString = time
        if (dateTimeString.indexOf(SEPARATORS.COMMA) !== -1) {
            let [dateString, timeString] = dateTimeString.split(SEPARATORS.COMMA)
            return (
                <div className="d-flex flex-column">
                    <span>{dateString}</span>
                    <span className={globalClasses.Font14BlueGrey}>{timeString}</span>
                </div>
            )
        }
        return dateTimeString
    }

    //audit log
    insertToAuditLog = (eventDetails, additionalDetails) => {
        console.log('Props: ', this.props)
        let { userDetails, accountUUID } = this.props
        let accountDetails = {}                  
        let eventAttributes = {}
        let user = {}
        if (accountUUID) {
            accountDetails = {accountUUID: accountUUID}
        }

        if (!isEmptyObject(userDetails)) {
            user = {
                awsId: userDetails?.awsId,
                email: userDetails?.email,
            }
        }

        eventAttributes = {...user, ...additionalDetails}

        AuditLog(eventDetails, eventAttributes, accountDetails)
    }

    render() {
        let { apiKeys, userRole, accountIsActive, userDetails, accountUUID } = this.props;
        let { showAddAccountPopup, showDeletePopup, newAccountName, newAccountNameErrorText, newAccountTierLevel,
            isEchoTestAccount, isNewAccountNameError, actionableData, accountEditable,
            isSubmitting, isDeleting, isApiKeysPaneLoading, isTokenGenerate, generatedToken,
            tokenCredentials } = this.state;

        let actionPopupComponent = null, deleteAccountPopupComponent = null;
        let apiKeysComponent = null;
        let loaderComponent = null;
        let addServiceAccountBtnComponent = null;



        if (isApiKeysPaneLoading) {
            loaderComponent = (
                <Loader
                    containerClass={classes.Loader}
                />
            )
        }
        if (showAddAccountPopup) {
            actionPopupComponent = (
                <AccountCreationPopup
                    title={USER_MNGMENT_STRINGS.ADD_NEW_SERVICE_ACCOUNT}
                    onCloseHandler={this.onCloseAddAccountPopup}
                    onSubmitHandler={this.onClickAccountCreation}
                    isSubmitting={isSubmitting}
                    submittingText={USER_MNGMENT_STRINGS.SUBMITTING}
                >
                    <div className="my-3 d-flex flex-wrap align-items-md-center">
                        <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="accountname">
                            {USER_MNGMENT_STRINGS.SERVICE_ACCOUNT_NAME_LABEL}
                        </label>
                        <div className="d-flex flex-column col-12 col-lg-8">
                            <input
                                className={cx(["col-12 p-2", globalClasses.FormInput,
                                    isNewAccountNameError ? globalClasses.ErrorFormInput : ''])}
                                value={newAccountName}
                                id={'accountname'}
                                readOnly={isSubmitting}
                                placeholder={'API Keys Name'}
                                onChange={(event) => this.onChangeNewAccountName(event.target.value)}
                            />
                            <span className={globalClasses.ErrorText}>
                                {newAccountNameErrorText}
                            </span>
                        </div>
                    </div>
                    {/* <div className="my-3 d-flex flex-wrap align-items-md-center">
                        <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="tierlevel">
                            {USER_MNGMENT_STRINGS.TIER_LEVEL_LABEL}
                        </label>
                        <div className={"col-12 col-lg-8"}>
                            <SelectField
                                containerClass={"col-12"}
                                value={newAccountTierLevel}
                                onChangeHandler={this.onChangeNewAccountTierLevel}
                                isDisabled={isSubmitting}
                                isReadOnly={isSubmitting}
                                isSearchable={false}
                                id={"tierlevel"}
                                optionsList={['1', '2', '3', '4', '5', '6']}
                                disableErrorText={true}
                            />
                        </div>
                        <div className={'form-check mt-3'}>
                            <CheckBoxInput
                                containerClass={"col-12"}
                                onChangeHandler={this.onChangeEchoTestCheckbox}
                                isDisabled={isSubmitting}
                                id={"echotestaccount"}
                                label={USER_MNGMENT_STRINGS.ECHO_TEST_LABEL}
                                isChecked={isEchoTestAccount}
                            />
                        </div>
                    </div> */}
                </AccountCreationPopup>
            )
        }
        if (isTokenGenerate) {
            let popupLineClass = "my-3 d-flex flex-wrap align-items-md-start";
            let popupLabelClass = cx(['col-12 col-lg-4 mb-2 mb-lg-0', globalClasses.Font16MediumDarkerBlur])
            let popupContentClass = cx(["col-12 col-lg-8"])
            actionPopupComponent = (
                <AccountCreationPopup
                    title={USER_MNGMENT_STRINGS.ACCESS_TOKEN}
                    onSubmitHandler={this.getInitialTokenStates}
                    onCloseHandler={this.getInitialTokenStates}
                    isSubmitting={isSubmitting}
                    submittingText={USER_MNGMENT_STRINGS.SUBMITTING}
                    disableSecondaryButton={true}
                    submitButtonText={BUTTON_TEXTS.OK}
                >
                    <div className={popupLineClass}>
                        <label className={popupLabelClass} htmlFor="accountname">
                            {USER_MNGMENT_STRINGS.CLIENT_ID}
                        </label>
                        <div className={popupContentClass}>
                            <span>
                                {tokenCredentials.clientID.substring(0, 6) + (tokenCredentials.clientID.substring(6, 14).replace(/\S/g, '*'))}
                            </span>
                            <CopyButton
                                className={cx(['ms-2', classes.Icon])}
                                imageSrc={COPY_ICON}
                                copyText={USER_MNGMENT_STRINGS.TOOLTIP_COPY_ID}
                                copiedText={'Copied'}
                                onClickCopy={() => this.onClickCopyIcon(tokenCredentials.clientID)}
                            />
                        </div>
                    </div>
                    <div className={popupLineClass}>
                        <label className={popupLabelClass} htmlFor="tierlevel">
                            {USER_MNGMENT_STRINGS.CLIENT_SECRET}
                        </label>
                        <div className={popupContentClass}>
                            <span>
                                {tokenCredentials.clientSecret.substring(0, 6) + (tokenCredentials.clientSecret.substring(6, 14).replace(/\S/g, '*'))}
                            </span>
                            <CopyButton
                                className={cx(['ms-2', classes.Icon])}
                                imageSrc={COPY_ICON}
                                copyText={USER_MNGMENT_STRINGS.TOOLTIP_COPY_SECRET}
                                copiedText={'Copied'}
                                onClickCopy={() => this.onClickCopyIcon(tokenCredentials.clientSecret)}
                            />
                        </div>
                    </div>
                    <div className={popupLineClass}>
                        <label className={popupLabelClass} htmlFor="tierlevel">
                            {USER_MNGMENT_STRINGS.ACCESS_TOKEN}
                        </label>
                        <div className={cx([popupContentClass, classes.PopupBearerContent])}>
                            {generatedToken.substring(0, 200) + "..."}
                            <CopyButton
                                className={cx(['ms-2', classes.Icon])}
                                imageSrc={COPY_ICON}
                                copyText={USER_MNGMENT_STRINGS.TOOLTIP_COPY_TOKEN}
                                copiedText={'Copied'}
                                onClickCopy={() => this.onClickCopyIcon(generatedToken)}
                            />
                        </div>
                    </div>
                </AccountCreationPopup>
            )
        }

        if (userRole !== USER_ROLE.SUBSCRIBED.LABEL && (accountIsActive === USER_MANAGEMENT.USER_STATUS.ACTIVE)) {
            addServiceAccountBtnComponent = <Button
                label={"Generate API Key"}
                icon={<MdSettings className={"me-2"} size={20} />}
                onClickHandler={this.onCreateServiceAccountClicked}
                containerClass={"ms-auto col-12 col-sm-6 col-md-4 col-lg-2"}
                actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                borderType={BUTTON_TYPES.BORDER.NO_BORDER}
            />
        }

        if (isEmptyList(apiKeys)) {
            apiKeysComponent = (
                <NoData
                    mainText={'No API Keys'}
                    subText={"API keys are not yet created for this account"}
                />
            )
        } else {
            if (showDeletePopup) {
                deleteAccountPopupComponent = (
                    <ConfirmPopup
                        popupClass={globalClasses.PopupBack}
                        isSubmitting={isDeleting}
                        submittingText={USER_MNGMENT_STRINGS.DELETING}
                        modalClass={'col-11 col-md-8 col-lg-6 ps-3 ps-md-4'}
                        title={USER_MNGMENT_STRINGS.DELETE_POPUP_TITLE}
                        subtext={USER_MNGMENT_STRINGS.DELETE_POPUP_SUBTEXT}
                        primaryButtonText={BUTTON_TEXTS.DELETE}
                        secondaryButtonText={BUTTON_TEXTS.CANCEL}
                        onClickPrimaryButton={this.onConfirmDelete}
                        onClickSecondaryButton={this.onCloseDeletePopup}
                        isPopupFixed={true}
                        enableScroll={false}
                        onCloseModalCard={this.onCloseDeletePopup}
                        theme={THEME.LIGHT_BLUE_SHADES}
                    />
                )
            }
            apiKeysComponent = (
                <>
                    {deleteAccountPopupComponent}
                    <SortingTable
                        enableExports={true}
                        exportTableName={[accountUUID,"API Keys"].join(SEPARATORS.UNDERSCORE)}
                        exportKeysList={
                            [
                                {
                                    key : "userName",
                                    label : "API Key"
                                },
                                {
                                    key : "clientID",
                                    label : "Client ID"
                                },
                                {
                                    key : "clientSecret",
                                    label : "Client Secret",
                                }
                                // ,
                                // {
                                //     key : "tier",
                                //     label : "Tier"
                                // },
                                // {
                                //     key : "echoTest",
                                //     label : "Usage Denied"
                                // }
                            ]
                        }
                        enableSearch={true}
                        searchKeysList={
                            [
                                {
                                    key : "userName",
                                    label : "API Key",
                                    value : "API Key",
                                    placeholder : "Search by API Key"
                                }
                                // ,
                                // {
                                //     key : "tier",
                                //     label : "Tier",
                                //     value : "Tier",
                                //     placeholder : "Search by Tier",
                                //     isNumber : true
                                // }
                            ]
                        }
                        headingList={
                            [
                                {
                                    key : "userName",
                                    label : "API Key",
                                    isDate : false
                                },
                                {
                                    key : "clientID",
                                    label : "Client ID",
                                    isDate : false,
                                    renderItem: (item) => {
                                        return(
                                            <span className="d-inline-flex align-items-center">
                                                {item.clientID.substring(0, 6) + (item.clientID.substring(6, 14).replace(/\S/g, '*'))}
                                                <CopyButton
                                                    className={cx(["ms-2",classes.Icon])}
                                                    imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                                                    copyText={USER_MNGMENT_STRINGS.TOOLTIP_COPY_ID}
                                                    copiedText={'Copied'}
                                                    onClickCopy={() => this.onClickCopyIcon(item.clientID)}
                                                />
                                            </span>
                                        )
                                    }
                                },
                                {
                                    key : "clientSecret",
                                    label : "Client Secret",
                                    isDate : false,
                                    renderItem: (item) => {
                                        return(
                                            <span className="d-inline-flex align-items-center">
                                                {item.clientSecret.substring(0, 6) + (item.clientSecret.substring(6, 14).replace(/\S/g, '*'))}
                                                <CopyButton
                                                    className={cx(["ms-2",classes.Icon])}
                                                    imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                                                    copyText={USER_MNGMENT_STRINGS.TOOLTIP_COPY_ID}
                                                    copiedText={'Copied'}
                                                    onClickCopy={() => this.onClickCopyIcon(item.clientSecret)}
                                                />
                                            </span>
                                        )
                                    }
                                },
                                // {
                                //     key : "tier",
                                //     label : "Tier",
                                //     isDate : false,
                                //     renderItem : (item) => {
                                //         if(accountEditable && item['clientID'] === actionableData['clientID']){
                                //             return (
                                //                 <SelectField
                                //                     containerClass={"col-12"}
                                //                     value={actionableData['tier']}
                                //                     onChangeHandler={this.onChangeEditableTierLevel}
                                //                     isDisabled={isSubmitting}
                                //                     isReadOnly={isSubmitting}
                                //                     isSearchable={false}
                                //                     menuRootElement={document.body}
                                //                     id={"tierlevel"}
                                //                     optionsList={['1', '2', '3', '4', '5', '6']}
                                //                     disableErrorText={true}
                                //                 />
                                //             )
                                //         } else {
                                //             return  item.tier
                                //         }
                                //     }
                                // },
                                // {
                                //     key : "echoTest",
                                //     label : "Usage Denied",
                                //     isDate : false,
                                //     renderItem : (item) => {
                                //         if(accountEditable && item['clientID'] === actionableData['clientID']){
                                //             return (
                                //                 <CheckBoxInput
                                //                     containerClass={"col-12"}
                                //                     onChangeHandler={() => this.onChangeEditableEchotest(actionableData['echoTest'])}
                                //                     isDisabled={false}
                                //                     id={"echo test"}
                                //                     isChecked={actionableData['echoTest'] === STRING_VALUE.TRUE}
                                //                 />
                                //             )
                                //         } else {
                                //             return  item.echoTest
                                //         }
                                //     }
                                // },
                                {
                                    key : "generateToken",
                                    label : "Token",
                                    isDate : false,
                                    unsortable : true,
                                    renderItem : (item) => {
                                        return (
                                            <span onClick={() => this.onClickGenerate(item)} className={classes.ActionButtons}>
                                                {"Get Token"}
                                            </span>
                                        )
                                    }
                                },
                                {
                                    key : "action",
                                    label : "Actions",
                                    isDate : false,
                                    unsortable : true,
                                    hide : (userRole === USER_ROLE.SUBSCRIBED.LABEL),
                                    renderItem : (item) => {
                                        return (
                                            <div className="d-flex align-items-center">
                                                {/* {
                                                    (accountIsActive === USER_MANAGEMENT.USER_STATUS.ACTIVE) &&
                                                    <>
                                                        {
                                                            accountEditable && item['clientID'] === actionableData['clientID'] ?
                                                                <img
                                                                    className={cx(['mx-2', classes.ActionIcon])}
                                                                    src={TICK_ICON} alt={'Done'}
                                                                    title={USER_MNGMENT_STRINGS.TOOLTIP_EDIT_DONE}
                                                                    onClick={() => this.onClickEditDone(item)}
                                                                />
                                                                :
                                                                <img
                                                                    className={cx(['mx-2', classes.ActionIcon])}
                                                                    src={EDIT_ICON} alt={'Edit'}
                                                                    title={USER_MNGMENT_STRINGS.TOOLTIP_EDIT_ACCOUNT}
                                                                    onClick={() => this.onClickEditAccount(item)}
                                                                />
                                                        }
                                                    </>
                                                } */}
                                                <img
                                                    className={cx(['mx-2', classes.ActionIconBlue])}
                                                    src={IMAGES.ACTIONS.DELETE_ICON_BLUE} alt={'Delete'}
                                                    title={USER_MNGMENT_STRINGS.TOOLTIP_DELETE_ACCOUNT}
                                                    onClick={() => this.onClickDeleteAccount(item)}
                                                />
                                            </div>
                                        )
                                    }
                                }
                            ]
                        }
                        containerClass={"mt-3"}
                        dataList={apiKeys}
                        itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.API_KEYS}
                        noteText={SORT_TABLE_VALUES.TABLE_NOTES.API_KEYS}
                    />
                </>
            )
        }
        return (
            <>
                {loaderComponent}
                {addServiceAccountBtnComponent}
                {actionPopupComponent}
                {apiKeysComponent}
            </>
        )
    }
}

export default ApiKeysPane;