import React, { Component } from "react";

import NetworkIssue from '../../components/network_issue/NetworkIssue';
import NetWorkIssueImage from '../../assets/images/network.png';


import MarketingLayout from "../../components/marketing_layout/MarketingLayout";

// import LOGO from '../../assets/images/logos/vivas-ai-logo-beta.png'
import LOGO from '../../assets/images/logos/vivasai-logo-light.png'

class AuthenticationLayout extends Component {

    constructor(props) {
        super(props)
        this.marketingCarousel = React.createRef()

    }

    state = {
        isDisconnected: false,
        shouldBlockNavigation: false,
    }

    componentDidMount = () => {
        let image = new Image();
        image.src = NetWorkIssueImage
        window[Image] = image;
        this.handleNetworkConnection();
        window.addEventListener('online', this.handleNetworkConnection);
        window.addEventListener('offline', this.handleNetworkConnection);

    }

    componentWillUnmount = () => {
        window.removeEventListener('online', this.handleNetworkConnection);
        window.removeEventListener('offline', this.handleNetworkConnection);
    }

    handleNetworkConnection = () => {
        let online = navigator.onLine;
        if (online) {
            console.log('online');
            this.setState({
                isDisconnected: false
            })
        } else {
            console.log('offline');
            this.setState({
                isDisconnected: true
            })
        }
    }

    render() {
        let { isDisconnected } = this.state;
        let { accountDetail, role, isSubscribedUser, userDetails, isAuthenticated } = this.props;

        let bodyContent = isDisconnected ?
            <NetworkIssue /> :
            <>
                <div className="container-fluid d-flex px-0 position-absolute h-100 w-100" >

                    <div className="col-5 col-lg-4 d-none d-md-block">
                        <MarketingLayout />
                    </div>
                    <div className="col-12 col-md-7 col-lg-8" style={{overflow:'auto'}}>
                        <div className="container row justify-content-center h-100 mx-auto">
                            <div className="text-end pt-4 pb-0 pe-4">
                                <img style={{ width: 'auto', height: "50px" }} src={LOGO} alt={"Vivas.AI"} title="Vivas.AI" />
                            </div>
                            <div className="col-12 col-md-10 col-lg-6">
                                {this.props.children}
                            </div>
                        </div>

                    </div>
                </div>
            </>
        return (
            <>
                {bodyContent}

            </>
        )
    }
}

export default AuthenticationLayout;