export const PRICING = Object.freeze({
   SIGN_IP:"Login",
   SUBSCRIBE:"Subscribe",
   CANCEL:"Cancel",
   TITLE:"Pricing Plans",
   CHOOSE_PLAN_MESSAGE:"Choose a plan that works best for you and your team",
   TITLE_SUBTEXT: "Vivas.AI offers competitive rates and pricing plans to help you find one that fits your need and budget.",
   GLOBAL_FEATURES : 'Available across all the plans:',
   GLOBAL_CONNECTORS : 'Connectors Available:',
   FAQ:"Frequently Asked Questions",
   FAQ_SUBTEXT:"Vivas.AI offers competitive rates and pricing plans to help you find one that fits your need and budget.",
   QUESTIONS:"Questions? Let's chat.",
   HELP_TEXT:"Our experts are ready to help you.",
   THANK_YOU: "Thank you!",

   REQUEST_TEXT: "Your request for ",
   PLAN_ACTIVATION_REQUEST: "Your subscription for ",
   PLAN_PLACED: " plan has been placed.",
   SUBSCRIPTION_REQ_ID : "Your subscription request id is ",
   MESSAGE: "Vivas.AI team will reach out to you soon.",
   ACCOUNT_CREATION_TEXT: "Your ",
   ACCOUNT_CREATION_PLAN_PLACED: " plan subscription has been activated.",
   ACCOUNT_CREATION_CONFIRM_MESSAGE: "Your account with Vivas.AI is now set up. Enjoy your experience!",
   ALREADY_SUBSCRIBED: {
      TITLE : "You are already subscribed!",
      SUB_TEXT : "You will receive an email of your account activation soon. Stay tuned.",
      POPUP_TITLE : "",
      TEXT_BEFORE_PLAN : 'Your subscription request for the ',
      TEXT_AFTER_PLAN : " plan is already in the queue. As soon as your account is activated, you will receive an email notification."
   },
   SIGNUP_REQUIRED: {
      TITLE : "Login / Register",
      SUB_TEXT : "To continue with the plan you have to login / register."
   },
   CALCULATOR:{
      HEAD : 'Monthly Pricing Estimator',
      ESTIMATING : 'Estimating',
      REMOVE : 'Remove',
      CLEAR : 'Clear',
      BACK_TO_PLANS : 'Back to plans',
      ADD_NEW : 'Add New',
      SHOW_CALCULATION : 'Show Calculations',
      HIDE_CALCULATION : 'Hide Calculations'
   },
   PLANS : {
      PLAN_1 : 'Trial',
      PLAN_2 : 'Bronze',
      PLAN_3 : 'Silver',
      PLAN_4 : 'Gold',
   },
   GROUP_TYPES : {
      DOMAIN : "domain",
      TASK : "task"
   },
   TOP_UP_TEXT : "Additional Top-up"
})