import ResponsiveButton from "../ui_elements/button/ButtonNew";
import ModalCard from "../ui_elements/modal_card/ModalCard";
import Popup from "../ui_elements/popup/Popup";

import cx from 'classnames';
import globalClasses from '../../App.module.css';
import classes from './ConfirmPopup.module.css';

import React from 'react';
import Loader from "../loader/Loader";
import { THEME } from "../../utils/Constants";
import { BUTTON_TYPES } from "../../utils/ButtonConstants";

function ConfirmPopup(props) {

    let {popupClass, modalClass, titleClass, title, subtextClass, subtext, primaryButtonText, 
        secondaryButtonText, onClickPrimaryButton, onClickSecondaryButton, isPopupFixed, theme,
        enableScroll, onCloseModalCard, submittingText, isSubmitting, hideSecondaryButton} = props

    let blueThemed = theme === THEME.LIGHT_BLUE_SHADES
    
    let modalStyle = cx([modalClass])
    let titleStyle = cx([globalClasses.Font24BoldDarkerGrey, titleClass])
    let subtextStyle = cx(["mb-5 pb-3 mt-3",classes.SubText, globalClasses.Font16Grey, subtextClass])

    let buttongroupComponent = null;
    //close modal popup on esc key press
    const handleKeyUp = (e) => {
        const keys = {
        27 : isPopupFixed && onCloseModalCard,
        };
        if (keys[e.keyCode]) { keys[e.keyCode](); }
      }

    React.useEffect(() => {
        window.addEventListener('keyup', handleKeyUp, false);
    })
    if(isSubmitting){
        buttongroupComponent = (
            <Loader
                containerClass={globalClasses.FullPageLoader}
            />
        )
    } else {
        buttongroupComponent = (
            <div className={cx("row flex-row justify-content-end")}>
                {
                    !hideSecondaryButton && 
                    <ResponsiveButton
                        id={"Confirmation-Secondary-Button"}
                        containerClass={"col-6 col-md-4"}
                        className={"col-12"}
                        label = {secondaryButtonText}
                        onClickHandler = {onClickSecondaryButton}
                        actionType={BUTTON_TYPES.ACTIONS.SECONDARY} 
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                    />
                }
                <ResponsiveButton
                    id={"Confirmation-Primary-Button"}
                    containerClass={"col-6 col-md-4"}
                    className={"col-12"}
                    label = {primaryButtonText}
                    onClickHandler = {onClickPrimaryButton}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                />
            </div>
        )
    }

    return(
        <Popup className={popupClass}>
            <ModalCard isPopupFixed={isPopupFixed} enableScroll={enableScroll} className={modalStyle} onClose={onCloseModalCard}>
                <h6 className={titleStyle}>
                    {title}
                </h6>
                <p className={subtextStyle}>
                    {subtext}
                </p>
                {buttongroupComponent}
            </ModalCard>
        </Popup>
    )
}

export default ConfirmPopup;