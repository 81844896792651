import { ROUTE_CONSTANTS } from "../../routes/RouteConstants";
import axios from "axios";
import { isEmpty } from "../../utils/UtilityFunctions";
import { DEFAULT_COUNTRY_CODE } from "../../utils/Constants";
/**
 * @type {UserConfig}
 */

const getCountryInfo = () => {
  console.log('pincode: ', localStorage.getItem("pincode"))
  if (isEmpty(localStorage.getItem("pincode"))) {
    axios
      .get(process.env.REACT_APP_GET_LOCATION_API)
      .then((response) => {
        console.log(response)
        let data = response.data;
        localStorage.setItem('countryCode', data.country);
        localStorage.setItem('city', data.city);
        localStorage.setItem('state', data.region);
        localStorage.setItem('pincode', data.postal);
        console.log('countryCode: ', localStorage.getItem('countryCode'))
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

const pluginConfig = {
  current_lang: 'en',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  test: 'ciao',
  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  delay: 3000,                               // default: 0 //milliseconds
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
    console.log('onFirstAction: ', cookie)
  },

  onAccept: function (cookie) {
    console.log('onAccept: ', cookie)
    
    // Set to the same value as the web property used on the site
    var gaProperty = 'G-BV91QWTH8T';

    // Disable tracking if the opt-out cookie exists.
    var disableStr = 'ga-disable-' + gaProperty;

    // if (cookie.categories.includes('tracking')) {
    if (document.cookie.indexOf('tracking') > -1) {
      //turn on google analytics
      console.log('cc_cookie, tracking accepted')
      window[disableStr] = false;
      window.dataLayer = window.dataLayer || [];
      function gtag() 
        { 
          window.dataLayer.push(arguments); 
        }
      gtag('js', new Date());
      gtag('config', 'G-BV91QWTH8T');

      //set login region details
      localStorage.setItem('pincode', '');
      // Api function call
      getCountryInfo()

    }
    else {
      console.log('cc_cookie, tracking rejected')

      //turn off google analytics
      window[disableStr] = true;

      //setting default values
      localStorage.setItem('countryCode', DEFAULT_COUNTRY_CODE);
      localStorage.setItem('city', '');
      localStorage.setItem('state', '');
      localStorage.setItem('pincode', '');
      console.log('Default countryCode: ', localStorage.getItem('countryCode'))
    }
  },

  onChange: function (cookie, changed_preferences) {
    console.log('onChange: ', cookie)
  },

  gui_options: {
    consent_modal: {
      layout: 'cloud',               // box/cloud/bar
      position: 'bottom center',     // bottom/middle/top + left/right/center
      transition: 'slide',           // zoom/slide
      swap_buttons: false            // enable to invert buttons
    },
    settings_modal: {
      layout: 'box',                 // box/bar
      // position: 'left',           // left/right
      transition: 'slide'            // zoom/slide
    }
  },

  languages: {
    en: {
      consent_modal: {
        title: 'Your Privacy',
        description:
          'We use cookies to offer a secure and reliable service on our website, improve the user experience, and provide personalized content. You agree to our cookie policy when you click "I Agree". You may customize your settings. We activate only essential functionalities if you do not consent. <button type="button" data-cc="c-settings" class="cc-link">Change My Preferences</button>',
        primary_btn: {
          text: 'I Agree',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        // secondary_btn: {
        //   text: 'Reject all',
        //   role: 'accept_necessary', // 'settings' or 'accept_necessary'
        // },
      },
      settings_modal: {
        title: 'Cookie Settings',
        save_settings_btn: 'I Agree',
        accept_all_btn: 'Accept all',
        // reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'Your privacy 📢',
            description:
              'A cookie is a small text file stored on your device by the websites that you visit. Cookies are used in order to make the website work more efficiently, as well as to provide information to the owners of the website. Cookies allow the users to navigate between pages efficiently, remember their preferences, and improve the user experience. '
              + '<br/><br/> How the users consent may impact their experience of certain features as you navigate the website. Whenever you use this website, information may be collected through the use of cookies and disabling them may prevent you from using parts of our website. ',
          },
          {
            title: 'Strictly necessary cookies',
            description:
              '"Strictly Necessary" cookies are essential to the functioning of the website and cannot be blocked. These enable the user to navigate through the website and use its features.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Functionality cookies',
            description:
              '"Functionality" cookies remember any settings you may have selected, providing the users with a personalized experience on our website. Users can change the way the site behaves or looks: remembering your language preferences or login details, for example.',
            toggle: {
              value: 'functionality', // your cookie category
              enabled: true,
              readonly: false,
            },
          },
          {
            title: 'Tracking and performance cookies',
            description:
              '"Tracking and performance cookies" enhance the performance of a website by allowing us to gather analytics and key information such as webpages visited, and the time spent on the website. '
              + '<br/><br/>In using performance cookies we do not collect personally identifiable information to ensure anonymity.',
            toggle: {
              value: 'tracking',
              enabled: true,
              readonly: false,
            },
          },
          {
            title: 'Targeting and advertising cookies',
            description:
              '"Targeting and advertising" cookies track user activity and sessions to make advertising messages more relevant to you. '
              + '<br/><br/>These cookies combine information collected from our website to deliver content relevant to your interests. '
              + '<br/><br/>If you disable the cookies your device will still receive adverts, though they may not be targeted marketing-related activities.',
            toggle: {
              value: 'targeting',
              enabled: true,
              readonly: false,
            },
          },
          {
            title: 'More information',
            description:
              'For any queries in relation to our policy on cookies and your choices, please contact us. '
              + '<br/><br/>To find out more, please visit our <a href=' + `${ROUTE_CONSTANTS.PRIVACY_POLICY}` + ' class="cc-link" target="_blank" rel="noopener noreferrer">Cookies Policy</a>.',
          },
        ],
      },
    },
  },
};


export default pluginConfig;
