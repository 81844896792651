import { isEmpty } from "underscore"
import { EMPTY_LIST, EMPTY_STRING } from "../../utils/Constants"
import { downloadCsvFile, getDownloadFileNameWithTime, isEmptyList } from "../../utils/UtilityFunctions"
import { SEPARATORS } from "../../strings/Strings"

export const SORT_TABLE_DATA_STATES = {
    tableHeadList: EMPTY_LIST, // to store the table heading list
    tableDataList: EMPTY_LIST, // to store the table data list
    tableFilteredDataList: EMPTY_LIST // to store the table filtered data list
}

export const SORT_TABLE_SORTING_STATES = {
    tableSortableDataList: EMPTY_LIST, // to store the table sorted data list
    currentSortKey: EMPTY_STRING, // to store the current sorted key
}

export const SORT_TABLE_PAGINATION_STATES = {
    pageIndex: 0, // to store the table pagination index
    totalPage: 1, // to store the total pages available for the table
    lastPaginationIndex: 0, // to store the table pagination's last idex
    inputPage: 1 // to store the page number input change value
}

export const SORT_TABLE_SEARCH_STATES = {
    currentSearchKeyObject : {}, // to store the searchable key
    searchValue : EMPTY_STRING // to store the value of search value
}

export const SORT_TABLE_STATES = {
    ...SORT_TABLE_DATA_STATES,
    ...SORT_TABLE_PAGINATION_STATES,
    ...SORT_TABLE_SEARCH_STATES,
    ...SORT_TABLE_SORTING_STATES
}

export const formAndDownloadCSVData = (datKeysList, dataList, exportTableName, addExportRows=[]) => {
    if(!isEmptyList(datKeysList) && !isEmptyList(dataList)){
        let fileName = getDownloadFileNameWithTime(exportTableName)
        let headList = datKeysList.filter(dataKey => !dataKey?.hide)
        headList = headList.map(dataKey => dataKey.label)
        let dataRowsList = dataList.map(data => {
            let rowData = [];
            datKeysList.map(dataKey => {
                if(!dataKey?.hide){
                    let value = (dataKey?.getValue)? dataKey.getValue(data) : data[dataKey.key]
                    if((typeof value === "string") && (value.includes(SEPARATORS.COMMA))){
                        value = "\""+value+"\""
                    }
                    rowData.push(value)
                }
            })
            return rowData
        })
        let addRows = isEmptyList(addExportRows)? [] : Object.assign([],addExportRows)
        let exportDataList = [
            headList,
            ...dataRowsList,
            ...addRows
        ].map(ele => ele.join(','))
            .join('\n')
        
        downloadCsvFile(fileName,exportDataList)
    }
}