import { EMPTY_STRING, SEPARATORS } from '../../strings/Strings'
import { MONTHS, PIE_CHART_COLOR_LIST, STRING_VALUE, SUBSCRIPTION_PRODUCTS, USER_MANAGEMENT } from '../../utils/Constants'
import { SECRETS_PROVIDERS } from '../../utils/SecretContants'
import { getDateFromInput, getStartOfWeek, getStartOfMonth, isEmpty, isEmptyList, getStartOfLastWeek, getEndOfLastWeek, getStartOfPreviousMonth, getEndOfPreviousMonth, downloadCsvFile, getDownloadFileNameWithTime, getDollarFromNumber, isEmptyObject, getAccountSubscriptions } from '../../utils/UtilityFunctions'

// tab ids 
export const TAB_IDS = {
    USERS: "users",
    APIKEYS: "api-keys",
    LLMKEYS: "configurators",
    SELF_HOSTING: "self-hosting",
    REPORTS: "reports",
    MYPLAN: "subscrption",
    SUPPORT: "support",
    TRANSACTIONS: "transactions",
    MODEL_ADMINISTRATION: "manage-models"
}

export const CONFIGURATOR_BUTTONS = [
    {
        id:"llm",
        label:"LLM"
    },
    {
        id:"ocr",
        label:"OCR"
    },
    {
        id:"transcription",
        label:"Speech-to-Text"
    }
]

// CRUD OPS
export const ACTIONS = {
    INSERT: 'insert',
    UPDATE: 'update',
    RENEW: 'renew',
    DELETE: 'delete',
}

// API KEYS UTILS
export const APIKEYS_PANE_INITIAL_STATE = {
    showAddAccountPopup: false,
    showDeletePopup: false,
    newAccountName: EMPTY_STRING,
    isNewAccountNameError: false,
    newAccountNameErrorText: EMPTY_STRING,
    isEchoTestAccount: false,
    newAccountTierLevel: '1',
    actionableData: null,
    accountEditable: false,
    isSubmitting: false,
    isDeleting: false,
    isApiKeysPaneLoading: false,
    isTokenGenerate : false,
    tokenCredentials : null,
    generatedToken : EMPTY_STRING
}

// USERS UTILS

// user type details for
export const USERS = {
    ADMIN: 'Admin',
    USER: 'User',
    ADMIN_USER: {
        ROLE: 'subscribed_admin',
        TYPE: 1
    },
    NORMAL_USER: {
        ROLE: 'subscribed',
        TYPE: 2
    }
}

// users pane initial state
export const USERS_PANE_INITIAL_STATE = {
    isSubmitError: false,
    userError: false,
    showAddUserPopup: false,
    showDeletePopup: false,
    newUserName: EMPTY_STRING,
    isNewUserNameError: false,
    newUserNameErrorText: EMPTY_STRING,
    newUserEmail: EMPTY_STRING,
    isNewUserEmailError: false,
    newUserEmailErrorText: EMPTY_STRING,
    isEchoTestAccount: false,
    newUserType: USERS.ADMIN,
    newUserCode: USERS.ADMIN_USER.TYPE,
    newUserRole: USERS.ADMIN_USER.ROLE,
    newUserTierLevel: '1',
    actionableData: null,
    accountEditable: false,
    isSubmitting: false,
    isDeleting: false,
    isUsersPaneLoading: false,
    showUserDetailPopup: false,
    userEmailErrors: 0,
    errorDelete: false,
    userTypeErrorText: EMPTY_STRING,
    userTypeError: false,
    actualActionableData: null,
    isEmailVerified: false,
    isRegisteredUser: false,
    isVerifying: false,
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    organisationName: EMPTY_STRING,
    jobTitle: EMPTY_STRING,
    isFirstNameError: false,
    isLstNameError: false,
    isOrganisationNameError: false,
    isJobTitleError: false
}

// REPORTS UTILS
export const DAYS_FILTERS = ['Current Week', 'Lask Week', 'Current Month', 'Last Month']

export const REPORTS_INITIAL_STATE = {
    selectedDaysFilter: DAYS_FILTERS[0],
    isReportsPaneLoading: false,
    isReportChartLoading: false,
    usersCount: 0,
    serviceAccounCount: 0,
    totalConsumedCredits: 0,
    totalTransactions: 0,
    tierByCreditData: {},
    tierByTransactionData: {},
    userByCreditTransactionData: {},
    taskByCreditTransactionData: {},
    modelByCreditTransactionData: {},
    dailyTrendData: {},
    screenWidth: 0,
    userByCreditTransactionLoading: false,
    taskByCreditTransactionLoading: false,
    modelByCreditTransactionLoading: false,
    dailyTrendLoading: false,
    initialModelNames: [],
    initialTiers: [],
    initialTaskNames: [],
    initialUserName: [],
    searchedModelName: 'All',
    searchedTaskName: 'All',
    searchedUserName: 'All',
    searchedTier: 'All',
    searchedTrendModelName: 'All',
    searchedTrendUserName: 'All',
    searchedTrendTaskName: 'All',
    searchedModelLimit: 'Top',
    isReportsPaneError: false,
    isDetailedViewReport: false,
    detailedReportData: [],
    isDetailedViewReportError: false,
    isDetailedViewReportLoading: false,
    selectedProduct : 1
}

// PLAN PANE INITIAL STATE
export const PLAN_INITIAL_STATE = {
    multipleAmount: 5,
    showAddCredit: false,
    isProfServiceReqEnabled: false,
    isSubmittingCreditReq: false,
    isSubmittingServiceReq: false,
    isCreditReqSuccess: false,
    isServiceReqSuccess: false,
    topupUnit: 0,
    showConfirmCreditPopup: false,
    errorCredit: false,
    errorSubmitCredit: false,
    creditErrorText: EMPTY_STRING,
    purposeText: EMPTY_STRING,
    errorPurpose: false,
    purposeErrorText: EMPTY_STRING,
    showRequestPopup: false,
    selectedPlan: {},
    planType: EMPTY_STRING,
    tenureType: EMPTY_STRING,
    tenureTypeID: 1,
    baseCharge: EMPTY_STRING,
    minCredits: EMPTY_STRING,
    freeCredits: EMPTY_STRING,
    totalAmount: EMPTY_STRING,
    isSubmitting: false,
    isSubmissionSuccess: false,
    isPlanRenewed: true,
    requestType: EMPTY_STRING,
    isSubmitError: false,
    isPlanError : false
}

// Initial state for support pane
export const INITIAL_SUPPORT_PANE_STATE = {
    email: EMPTY_STRING,
    emailError: false,
    name: EMPTY_STRING,
    nameError: false,
    categories: [],
    showModelNames: false,
    modelNames: [],
    model: EMPTY_STRING,
    category: EMPTY_STRING,
    comments: EMPTY_STRING,
    commentsError: false,
    snapshotFile: EMPTY_STRING,
    snapshotFileSize: 0,
    snapshotFileType: EMPTY_STRING,
    snapshotFileName: EMPTY_STRING,
    snapshotFileError: false,
    isSubmitError: false,
    isSubmitting: false,
    isSubmissionSuccess: false,
    isErrorSupportData: false,
    isSuccessSupportData: false,
    isLoadingSupportData: true,
    supportTicketData: [],
    showSupportTicketForm: false
}

//initial state for transactions pane
export const INITIAL_TRANSACTION_PANE_STATE = {
    transactionsData: [],
    isTransactionsAPIError: false,
    isTransactionsAPILoading: true
}

//initial state for configurators pane
export const INITIAL_CONFIGURATORS_PANE_STATE = {
    configuratorsData: [],
    isConfiguratorsError: false,
    isConfiguratorsLoading: true,
    configuratorFormData:{},
    deleteConfigurator: EMPTY_STRING,
    showCreateEditPopup : false,
    isConfiguratorEditable : false,
    showConfirmDeleteSecretPopup : false,
    isSubmitting : false,
    isDeleting : false,
    isKeyValid : false,
    keyValidated : false,
    availableConfigurators : [],
    isSecretNameError : false,
    isSecretValuesError : {},
    isConfiguratorFormError : false,
    selectedType : "llm",
    filteredConfiguratorData : []
}

export const SELF_HOSTING_FORM_STATES = {
    isSubmitError : false,
    isSubmitting : false,
    formSubmissionData : {},
    formSubmissionsError : {}
}

export const INITIAL_SELF_HOSTING_STATE = {
    selectedLicenseMenu : {},
    selectedProductLicense : EMPTY_STRING,
    isSelfHostLoading : false,
    licenseeData : {},
    isErrorLicenseeData : false,
    gettingLicenseeKey : false,
    showFormComponent : false,
    showConfirmationComponent : false,
    showSuccessPopup : false,
    apiResponseData : {},
    ...SELF_HOSTING_FORM_STATES
}

// USER MANAGEMENT INITIAL STATE
export const INITIAL_STATE = {
    showPaymentSuccessPopup : false,
    showPaymentRequestSuccessPopup : false,
    activeUsersCount: 1,
    companyAddress: EMPTY_STRING,
    addressLine1: EMPTY_STRING,
    addressLine2: EMPTY_STRING,
    city: EMPTY_STRING,
    region: EMPTY_STRING,
    country: EMPTY_STRING,
    postalCode: EMPTY_STRING,
    activeTab: TAB_IDS.MYPLAN,
    isUserManagementLoading: true,
    isUserManagementError: false,
    mainAccountName: EMPTY_STRING,
    apiKeys: [],
    companyName: EMPTY_STRING,
    accountUUID: EMPTY_STRING,
    creditBalance: 0,
    subscriptionType: EMPTY_STRING,
    subscriptions : [],
    users: [],
    filteredUsers: [],
    usersNameList: [],
    searchUser: EMPTY_STRING,
    isSearchUserSelected: false,
    accountID: 0,
    adminCount: 0,
    accountValidUpto: EMPTY_STRING,
    remainingDaysToExpire: 30,
    showAccountEditPopup: false,
    unsubscriptionRequestID: EMPTY_STRING,
    accountIsActive: 0

}

// account edit popup initial state
export const ACCOUNT_EDIT_INITIAL_STATE = {
    accountID: EMPTY_STRING,
    accountName: EMPTY_STRING,
    companyName: EMPTY_STRING,
    addressLine1: EMPTY_STRING,
    addressLine2: EMPTY_STRING,
    city: EMPTY_STRING,
    region: EMPTY_STRING,
    country: EMPTY_STRING,
    postalCode: EMPTY_STRING,
    isSubmitting: false,
    isSubmitError: false,
    addressLine1Error: false,
    addressLine2Error: false,
    cityError: false,
    regionError: false,
    countryError: false,
    postalCodeError: false
}


// account unsubscribe popup initial state
export const ACCOUNT_UNSUBSCRIBE_INITIAL_STATE = {
    accountUUID: EMPTY_STRING,
    accountID: EMPTY_STRING,
    reason: EMPTY_STRING,
    isSubmitting: false,
    isSubmitError: false,
    reasonError: false
}

// setting user management states after the api call
export const getUserAndAPIKeys = ({ data }) => {
    // console.log("data", data)
    let apiKeys = [], users = [], usersNameList = [];
    let mainAccountName = EMPTY_STRING
    let companyName = EMPTY_STRING
    let accountUUID = EMPTY_STRING
    let creditBalance = 0
    let subscriptionType = EMPTY_STRING
    let accountID = 0
    let adminCount = 0
    let accountValidity = EMPTY_STRING
    let companyAddress = '-'
    let addressLine1 = EMPTY_STRING
    let addressLine2 = EMPTY_STRING
    let city = EMPTY_STRING
    let region = EMPTY_STRING
    let country = EMPTY_STRING
    let postalCode = EMPTY_STRING
    let activeUsersCount = 0
    let remainingDaysToExpire = 30
    let unsubscriptionRequestID=EMPTY_STRING
    let subscriptions = []
    if (!data.hasOwnProperty('status') && !isEmptyList(data)) {
        //account details
        if (!isEmptyList(data.address_detail)) {
            let { AddressLine1, AddressLine2, City, State, Country, ZipCode } = data.address_detail[0];
            addressLine1 = AddressLine1
            addressLine2 = AddressLine2
            city = City
            region = State
            country = Country
            postalCode = ZipCode
            companyAddress = [AddressLine1, AddressLine2, [City, ZipCode].join(' '), State, Country].join(', ').replace(/, ,/g, ',');
            companyAddress = [companyAddress, '.'].join('');
        }
        if (!isEmptyList(data.account_detail)) {
            mainAccountName = data.account_detail[0].AccountName;
            companyName = data.account_detail[0].CompanyName;
            accountUUID = data.account_detail[0].AccountUUID;
            creditBalance = Number(data.account_detail[0].CreditBalance);
            subscriptionType = data.account_detail[0].SubscriptionTypeId;   
            subscriptions = Object.assign([],getAccountSubscriptions(data?.subscriptions));
            accountID = data.account_detail[0].AccountId;
            accountValidity = getDateFromInput(data.account_detail[0].ValidUpto, true)
            if (data.account_detail[0]?.UnsubscriptionRequestID)
            {
            unsubscriptionRequestID = data.account_detail[0].UnsubscriptionRequestID
            }

            let currentDate = new Date()
            let [month, day, year] = accountValidity.split('/');
            let validityDate = new Date(year, month - 1, day);
            remainingDaysToExpire = validityDate - currentDate
            remainingDaysToExpire = Math.ceil(remainingDaysToExpire / (1000 * 60 * 60 * 24));
        }
        if (!isEmptyList(data.active_user_count)) {
            activeUsersCount = data.active_user_count[0].ActiveUserCount
        }

        //user details
        data.user.map(user => {
            let userDetail = user
            usersNameList.push(userDetail.DisplayName) // user name list for search
            if (userDetail.UserType === USERS.ADMIN) {
                adminCount += 1;
            }
            users.push({
                userName: userDetail.DisplayName,
                email: userDetail.UserEmail,
                userType: userDetail.UserType,
                userCode: (userDetail.UserType === USERS.ADMIN) ? USERS.ADMIN_USER.TYPE : USERS.NORMAL_USER.TYPE,
                userRole: (userDetail.UserType === USERS.ADMIN) ? USERS.ADMIN_USER.ROLE : USERS.NORMAL_USER.ROLE,
                clientID: userDetail.ClientId,
                tier: userDetail.Tier,
                isActive: userDetail.IsActive,
                validUpto: getDateFromInput(userDetail.ValidUpto, true),
            })

            return null
        })
        // service account details
        data.service_account.map(item => {
            let apiKey = item
            apiKeys.push({
                userName: apiKey.ServiceAccountName,
                clientID: apiKey.ClientId,
                clientSecret: apiKey.ClientSecret,
                tier: apiKey.Tier,
                echoTest: apiKey.IsEchoTestUser ? STRING_VALUE.TRUE : STRING_VALUE.FALSE
            })
            
            return null;
        })

    }
    return {
        activeUsersCount,
        companyAddress,
        apiKeys,
        users,
        isUserManagementLoading: false,
        mainAccountName,
        companyName,
        accountUUID,
        creditBalance,
        subscriptionType,
        ...subscriptions,
        usersNameList,
        accountID,
        adminCount,
        accountValidity,
        remainingDaysToExpire,
        addressLine1,
        addressLine2,
        city,
        region,
        country,
        postalCode,
        unsubscriptionRequestID
    }
}

export const handleErrorApiCreation = () => {
    return {
        isSubmitting: false,
        isApiKeysPaneLoading: false,
        isNewAccountNameError: false,
        newAccountNameErrorText: '',
        showAddAccountPopup: false,
        accountEditable: false,
        actionableData: null
    }
}

// get filter user while search
export const getFilteredUsers = (allUsers, filterUser) => {
    let filteredUsers = Object.assign([], allUsers)
    filteredUsers = filteredUsers.filter((item) => item.userName.toLowerCase().includes(filterUser.toLowerCase()))
    return ({
        filteredUsers
    })
}

// handle email verification of user
export const handleSuccessVerfication = ({ data }) => {
    let firstName = EMPTY_STRING, lastName = EMPTY_STRING, organisationName = EMPTY_STRING,
        jobTitle = EMPTY_STRING, isRegisteredUser = false;
    if (!data.hasOwnProperty('status') && !isEmpty(data.user)) {
        firstName = data.user.firstName;
        lastName = data.user.lastName;
        organisationName = data.user.companyName;
        jobTitle = data.user.jobTitle;
        isRegisteredUser = true
    }
    return {
        firstName, lastName, organisationName,
        jobTitle, isRegisteredUser,
        isVerifying: false,
        isEmailVerified: true,
    }
}

export const handleErrorVerification = () => {
    return {
        isVerifying: false,
        isEmailVerified: false,
    }
}

// handle error data in component
export const handleErrorData = () => {
    return ({
        isUserManagementLoading: false,
        isUserManagementError: true,
    })
}

// setting initial user state (USERS CREATION)
export const getInitialUserCreation = () => {
    return ({
        isSubmitError: false,
        userError: false,
        showAddUserPopup: false,
        newUserName: EMPTY_STRING,
        isNewUserNameError: false,
        newUserNameErrorText: EMPTY_STRING,
        newUserEmail: EMPTY_STRING,
        isNewUserEmailError: false,
        newUserEmailErrorText: EMPTY_STRING,
        isEchoTestAccount: false,
        newUserType: USERS.ADMIN,
        newUserCode: USERS.ADMIN_USER.TYPE,
        newUserRole: USERS.ADMIN_USER.ROLE,
        newUserTierLevel: '1',
        isSubmitting: false,
        isUsersPaneLoading: false,
        userTypeError: false,
        userTypeErrorText: EMPTY_STRING,
        userEmailErrors: 0,
        firstName: EMPTY_STRING,
        lastName: EMPTY_STRING,
        organisationName: EMPTY_STRING,
        jobTitle: EMPTY_STRING,
        isEmailVerified: false,
        isVerifying: false,
        isFirstNameError: false,
        isLstNameError: false,
        isOrganisationNameError: false,
        isJobTitleError: false
    })
}

// setting initial service account state (API KEYS ACCOUNT CREATION)
export const getInitialServiceAccountCreation = () => {
    return ({
        showAddAccountPopup: false,
        newAccountName: EMPTY_STRING,
        newAccountTierLevel: '1',
        newAccountNameErrorText: EMPTY_STRING,
        isNewAccountNameError: false,
        isEchoTestAccount: false,
        isSubmitting: false,
        isApiKeysPaneLoading: false
    })
}

// getting initial state for editable account (APIKEYS, USERS)
export const getInitialEditableAccount = () => {
    return ({
        actionableData: null,
        accountEditable: false,
    })
}

// getting initial filter state (REPORTS)
export const getInitialReportFilters = () => {
    return ({
        selectedDaysFilter: DAYS_FILTERS[1]
    })
}

// chart load states (REPORTS)
export const loadCharts = () => {
    return ({
        isReportChartLoading: true,
    })
}

// get detailed report data
export const getDetailedReportData = (data) => {
    let reportData = []
    if (!isEmptyList(data.detail_report)) {
        data.detail_report.map(item => {
            let reportItem = item
            console.log("REPORTITEM",reportItem)
            let leafGroup = "Page(s)"
            if(item?.LeafGroup === SUBSCRIPTION_PRODUCTS.LEAF_IDP.ID){
                leafGroup = SUBSCRIPTION_PRODUCTS.LEAF_IDP.NAME
            }
            if(item?.LeafGroup === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.ID){
                leafGroup = SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.NAME
            }
            if(item?.LeafGroup === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.ID){
                leafGroup = SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.NAME
            }
            reportData.push({
                accountId: reportItem.AccountID,
                fileId: isEmpty(reportItem.FileId)? '-': reportItem.FileId,
                // clientID: reportItem.ClientId,
                transactionId: reportItem.TransactionId,
                tier: reportItem.ModelTier,
                modelName: reportItem.ModelName,
                transactionDate: getDateFromInput(reportItem.createdOn),
                userName: reportItem.UserEmail,
                modelCode: reportItem.modelcode,
                credit: reportItem.Credit,
                inputToken: isEmpty(reportItem.InputTokens) ? 0 : reportItem.InputTokens,
                outputToken: isEmpty(reportItem.OutputTokens) ? 0 : reportItem.OutputTokens,
                leafGroup
            })
            return null
        })
    }

    return {
        detailedReportData: reportData,
        isDetailedViewReportLoading: false,
        isDetailedViewReportError : false
    }
}

//handling error in detailed report data
export const handleDetailedReportDataError = (err) => {
    return {
        isDetailedViewReportError: true,
        isDetailedViewReportLoading: false
    }
}

// get trend by transactions and credit chart detail
export const getDailyTrend = (data) => {
    let dailyTrendByCreditTransactionLabels = [];
    let dailyTrendByCreditValues = [];
    let dailyTrendByTransactionValues = [];
    let dailyTrendByCreditTransactionDataset = null;

    if (!isEmptyList(data)) {
        data.map((item) => {
            let date = new Date(item.date)
            dailyTrendByCreditTransactionLabels.push(MONTHS[date.getMonth()] + SEPARATORS.SPACE + date.getDate());
            dailyTrendByCreditValues.push(item.credit_sum);
            dailyTrendByTransactionValues.push(item.no_of_transactions);
            return null
        })
        dailyTrendByCreditTransactionDataset = [
            {
                type: 'line',
                label: 'No. of transactions',
                data: dailyTrendByTransactionValues,
                backgroundColor: ['#ff7f5e'],
                borderColor: ['#ff7f5e'],
                hoverBackgroundColor: ['#ff7f5e'],
                borderWidth: 2
            },
            {
                type: 'line',
                label: 'Charges (USD)',
                data: dailyTrendByCreditValues,
                backgroundColor: ['#735DF8'],
                borderColor: ['#735DF8'],
                hoverBackgroundColor: ['#735DF8'],
                borderWidth: 2
            }
        ]
    }
    return ({
        dailyTrendByCreditTransactionLabels,
        dailyTrendByCreditTransactionDataset
    })
}

// get models by transactions and credit chart detail
export const getModelsbyCreditTransaction = (data) => {
    let modelByCreditTransactionLabels = [];
    let modelByCreditValues = [];
    let modelByTransactionValues = [];
    let modelByCreditTransactionDataset = null;

    if (!isEmptyList(data)) {
        data.map((item) => {
            modelByCreditTransactionLabels.push(item.model_name);
            modelByCreditValues.push(item.credit_sum);
            modelByTransactionValues.push(item.no_of_transactions);
            return null
        })
        modelByCreditTransactionDataset = [
            {
                type: 'line',
                label: 'No. of transactions',
                data: modelByTransactionValues,
                backgroundColor: ['#ff7f5e'],
                borderColor: ['#ff7f5e'],
                hoverBackgroundColor: ['#ff7f5e'],
                borderWidth: 2
            },
            {
                type: 'bar',
                label: 'Charges (USD)',
                data: modelByCreditValues,
                backgroundColor: ['#735DF8'],
                hoverBackgroundColor: ['#735DF8'],
                borderWidth: 0,
                maxBarThickness: 40
            }
        ]
    }
    return ({
        modelByCreditTransactionLabels,
        modelByCreditTransactionDataset
    })
}

// get tasks by transactions and credit chart detail
export const getTasksbyCreditTransaction = (data) => {
    let taskByCreditTransactionLabels = [];
    let taskByCreditValues = [];
    let taskByTransactionValues = [];
    let taskByCreditTransactionDataset = null;

    if (!isEmptyList(data)) {
        data.map((item) => {
            taskByCreditTransactionLabels.push(item.task);
            taskByCreditValues.push(item.credit_sum);
            taskByTransactionValues.push(item.no_of_transactions);
            return null
        })
        taskByCreditTransactionDataset = [
            {
                type: 'line',
                label: 'No. of transactions',
                data: taskByTransactionValues,
                backgroundColor: ['#ff7f5e'],
                borderColor: ['#ff7f5e'],
                hoverBackgroundColor: ['#ff7f5e'],
                borderWidth: 2
            },
            {
                type: 'bar',
                label: 'Charges (USD)',
                data: taskByCreditValues,
                backgroundColor: ['#735DF8'],
                hoverBackgroundColor: ['#735DF8'],
                borderWidth: 0,
                maxBarThickness: 40
            }
        ]
    }
    return ({
        taskByCreditTransactionLabels,
        taskByCreditTransactionDataset
    })
}

// get user by transactions and credit chart detail
export const getUsersbyCreditTransaction = (data) => {
    let userByCreditTransactionLabels = [];
    let userByCreditValues = [];
    let userByTransactionValues = [];
    let userByCreditTransactionDataset = null;

    if (!isEmptyList(data)) {
        data.map((item) => {
            userByCreditTransactionLabels.push(item.user_email);
            userByCreditValues.push(item.credit_sum);
            userByTransactionValues.push(item.no_of_transactions);
            return null
        })
        userByCreditTransactionDataset = [
            {
                type: 'line',
                label: 'No. of transactions',
                data: userByTransactionValues,
                backgroundColor: ['#ff7f5e'],
                borderColor: ['#ff7f5e'],
                hoverBackgroundColor: ['#ff7f5e'],
                borderWidth: 2
            },
            {
                type: 'bar',
                label: 'Amount deducted',
                data: userByCreditValues,
                backgroundColor: ['#735DF8'],
                hoverBackgroundColor: ['#735DF8'],
                borderWidth: 0,
                maxBarThickness: 40
            }
        ]
    }
    return ({
        userByCreditTransactionLabels,
        userByCreditTransactionDataset
    })
}

// get tier by credits chart detail
export const getTierByCredits = (data) => {
    let tierByCreditLabels = [];
    let tierByCreditValues = [];
    let tierByCreditDataset = null;
    if (!isEmptyList(data)) {
        data.map((item) => {
            tierByCreditLabels.push('Tier ' + item.model_tier);
            tierByCreditValues.push(item.credit_sum);
            return null
        })
        tierByCreditDataset = [
            {
                label: 'credits',
                data: tierByCreditValues,
                backgroundColor: PIE_CHART_COLOR_LIST,
                hoverBackgroundColor: PIE_CHART_COLOR_LIST,
                // backgroundColor: ['#DDDBFF','#B5C5FC','#5C7BE4','#2F4FB9','#221e5b'],
                // hoverBackgroundColor : ['#DDDBFF','#B5C5FC','#5C7BE4','#2F4FB9','#221e5b'],
                borderWidth: 0
            }
        ]
    }
    return ({
        tierByCreditLabels,
        tierByCreditDataset
    })
}

// get tier by transactions chart detail
export const getTierByTransactions = (data) => {
    let tierByTransactionLabels = [];
    let tierByTransactionValues = [];
    let tierByTransactionDataset = null;
    if (!isEmptyList(data)) {
        data.map((item) => {
            tierByTransactionLabels.push('Tier ' + item.model_tier);
            tierByTransactionValues.push(item.no_of_transactions);
            return null
        })
        tierByTransactionDataset = [
            {
                label: 'credits',
                data: tierByTransactionValues,
                backgroundColor: PIE_CHART_COLOR_LIST,
                hoverBackgroundColor: PIE_CHART_COLOR_LIST,
                borderWidth: 0
            }
        ]
    }
    return ({
        tierByTransactionLabels,
        tierByTransactionDataset
    })
}

// getting chart data after the api call (REPORTS)
export const getChartData = (data) => {
    let usersCount = 0;
    let serviceAccounCount = 0;
    let totalConsumedCredits = 0;
    let totalTransactions = 0;
    let tierByCreditData = {};
    let tierByTransactionData = {};
    let userByCreditTransactionData = {};
    let taskByCreditTransactionData = {};
    let modelByCreditTransactionData = {};
    let dailyTrendData = {};
    let initialTaskNames = [];
    let initialTiers = [];
    let initialUserName = [];
    let initialModelNames = [];
    if (!isEmpty(data)) {
        if (!isEmpty(data.total_user)) {
            usersCount = data.total_user
        }
        if (!isEmpty(data.total_service_account)) {
            serviceAccounCount = data.total_service_account
        }
        if (!isEmpty(data.total_credits)) {
            totalConsumedCredits = !isNaN(data.total_credits) ? getDollarFromNumber(Number(data.total_credits).toFixed(3)) : '0'
        }
        if (!isEmpty(data.total_transaction)) {
            totalTransactions = data.total_transaction
        }
        if (!isEmptyList(data.credits_by_tier)) {
            tierByCreditData = {
                ...getTierByCredits(data.credits_by_tier)
            }
            initialTiers = [
                ...tierByCreditData.tierByCreditLabels
            ]
        }
        if (!isEmptyList(data.transaction_by_tier)) {
            tierByTransactionData = {
                ...getTierByTransactions(data.transaction_by_tier)
            }
        }
        if (!isEmptyList(data.credits_transaction_by_user)) {
            userByCreditTransactionData = {
                ...getUsersbyCreditTransaction(data.credits_transaction_by_user)
            }
            initialUserName = [
                ...userByCreditTransactionData.userByCreditTransactionLabels
            ]
        }
        if (!isEmptyList(data.credits_transaction_by_task)) {
            taskByCreditTransactionData = {
                ...getTasksbyCreditTransaction(data.credits_transaction_by_task)
            }
            initialTaskNames = [
                ...taskByCreditTransactionData.taskByCreditTransactionLabels
            ]
        }
        if (!isEmptyList(data.credits_transaction_by_model)) {
            modelByCreditTransactionData = {
                ...getModelsbyCreditTransaction(data.credits_transaction_by_model)
            }
            initialModelNames = [
                ...modelByCreditTransactionData.modelByCreditTransactionLabels
            ]
        }
        if (!isEmptyList(data.daily_trend)) {
            dailyTrendData = {
                ...getDailyTrend(data.daily_trend)
            }
        }
    }

    return ({
        isReportChartLoading: false,
        isReportsPaneLoading: false,
        usersCount,
        serviceAccounCount,
        totalConsumedCredits,
        totalTransactions,
        tierByCreditData,
        tierByTransactionData,
        userByCreditTransactionData,
        taskByCreditTransactionData,
        modelByCreditTransactionData,
        dailyTrendData,
        initialModelNames: initialModelNames.sort(),
        initialTaskNames: initialTaskNames.sort(),
        initialTiers: initialTiers.sort(),
        initialUserName: initialUserName.sort(),
        searchedModelName: 'All',
        searchedTaskName: 'All',
        searchedUserName: 'All',
        searchedTier: 'All',
        searchedTrendModelName: 'All',
        searchedTrendUserName: 'All',
        searchedTrendTaskName: 'All',
        searchedModelLimit: 'Top',
    })

}

// export the detailed report as csv
export const downloadTableAsCSV = (data, accountName) => {
    let dataList = Object.assign([], data);
    let exportDataList = [
        [
            'Transaction ID', 'User Name', 'Model Code', 'Model Name', 'Model Tier', 'Credit Value', 'Transaction Date', 'Transaction Time'
        ],
        ...dataList.map((item, index) => [
            item.transactionId, item.userName, item.modelCode,
            item.modelName, item.tier, item.credit, item.transactionDate
        ])
    ].map(ele => ele.join(','))
        .join('\n')

    downloadCsvFile(getDownloadFileNameWithTime(accountName), exportDataList)
}

// get start and end date for the report
export const getFromAndToDate = (filter) => {
    let currentDate = getDateFromInput(new Date(), true);
    let currentWeekStartDate = getDateFromInput(getStartOfWeek(new Date()), true)

    switch (DAYS_FILTERS.indexOf(filter)) {
        case 0:
            return [
                currentWeekStartDate,
                currentDate
            ]
        case 1:
            let lastWeekStartDate = getDateFromInput(getStartOfLastWeek(new Date()), true)
            let lastWeekEndDate = getDateFromInput(getEndOfLastWeek(new Date()), true)
            return [
                lastWeekStartDate,
                lastWeekEndDate
            ]
        case 2:
            let currentMonthStartDate = getDateFromInput(getStartOfMonth(new Date()), true)
            return [
                currentMonthStartDate,
                currentDate
            ]
        case 3:
            let lastMonthStartDate = getDateFromInput(getStartOfPreviousMonth(new Date()), true)
            let lastMonthEndDate = getDateFromInput(getEndOfPreviousMonth(new Date()), true)
            return [
                lastMonthStartDate,
                lastMonthEndDate
            ]
        default:
            return [
                currentWeekStartDate,
                currentDate
            ]
    }
}

// error in getting chart data
export const getErrorChartData = (err) => {
    return {
        isReportChartLoading: false,
        isReportsPaneLoading: false,
    }
}

// CRUD OPERATIONS (USERS)
export const insertUserData = (actualData, nameList, insertData, count) => {
    let users = actualData;
    let usersNameList = nameList;
    let adminCount = count;
    let data = {
        clientID: insertData.client_id,
        echoTest: insertData.echo_test,
        userName: insertData.service_account_name,
        email: insertData.user_email,
        tier: insertData.service_tier_level,
        userType: (insertData.user_type === 1) ? USERS.ADMIN : USERS.USER,
        userCode: (insertData.user_type === 1) ? USERS.ADMIN_USER.TYPE : USERS.NORMAL_USER.TYPE,
        userRole: (insertData.user_type === 1) ? USERS.ADMIN_USER.ROLE : USERS.NORMAL_USER.ROLE
    }
    if (insertData.user_type === 1) {
        adminCount += 1
    }
    users.unshift(data);
    usersNameList.unshift(insertData.service_account_name)
    return ({
        users,
        usersNameList,
        adminCount
    })
}

export const updateUserData = (actualData, nameList, updateData, count) => {
    let users = actualData;
    let data = updateData;
    let adminCount = count;
    let updateDataIndex = users.findIndex((item) => item.clientID === data.clientID);
    if (updateDataIndex > -1) {
        if (actualData[updateDataIndex].userType !== USERS.ADMIN
            && updateData.userType === USERS.ADMIN) {
            adminCount += 1
        } else if (actualData[updateDataIndex].userType === USERS.ADMIN
            && updateData.userType !== USERS.ADMIN) {
            adminCount -= 1
        }
        users.splice(updateDataIndex, 1, data);
    }
    return ({
        users,
        adminCount
    })
}

export const renewUserKeyData = (actualData, nameList, renewData) => {
    let users = [...actualData];
    let data = {
        clientID: renewData.client_id,
        clientSecret: renewData.client_secret
    }
    let renewDataIndex = users.findIndex((item) => item.clientID === data.clientID);
    if (renewDataIndex > -1) {
        users[renewDataIndex].clientID = data.clientID;
        users[renewDataIndex].clientSecret = data.clientSecret;
    }
    return ({
        users
    })
}

export const deleteUserData = (actualData, nameList, deleteData, count) => {
    let users = actualData;
    let data = deleteData;
    let usersNameList = nameList;
    let deleteDataIndex = users.findIndex((item) => item.clientID === data.clientID);
    let adminCount = count;
    if (deleteDataIndex > -1) {
        if (users[deleteDataIndex].userType === USERS.ADMIN) {
            adminCount -= 1;
        }
        users.splice(deleteDataIndex, 1);
        usersNameList.splice(deleteDataIndex, 1);
    }
    return ({
        users,
        usersNameList,
        adminCount
    })
}

// CRUD OPERATIONS (API KEYS)
export const insertApikeyData = (actualData, insertData) => {
    let apiKeys = actualData;
    let data = {
        userName: insertData.service_account_name,
        clientID: insertData.client_id,
        clientSecret: insertData.client_secret,
        tier: insertData.service_tier_level,
        echoTest: insertData.echo_test ? STRING_VALUE.TRUE : STRING_VALUE.FALSE
    }
    apiKeys.unshift(data);
    return ({
        apiKeys
    })
}

export const updateApikeyData = (actualData, updateData) => {
    let apiKeys = actualData;
    let data = updateData;
    let updateDataIndex = apiKeys.findIndex((item) => item.clientID === data.clientID);
    if (updateDataIndex > -1) {
        apiKeys.splice(updateDataIndex, 1, data);
    }
    return ({
        apiKeys
    })
}

export const renewApikeyData = (actualData, renewData) => {
    let apiKeys = [...actualData];
    let data = {
        clientID: renewData.client_id,
        clientSecret: renewData.client_secret
    }
    let renewDataIndex = apiKeys.findIndex((item) => item.clientID === data.clientID);
    if (renewDataIndex > -1) {
        apiKeys[renewDataIndex].clientID = data.clientID;
        apiKeys[renewDataIndex].clientSecret = data.clientSecret;
    }
    return ({
        apiKeys
    })
}

export const deleteApikeyData = (actualData, deleteData) => {
    let apiKeys = actualData;
    let data = deleteData;
    let deleteDataIndex = apiKeys.findIndex((item) => item.clientID === data.clientID);
    if (deleteDataIndex > -1) {
        apiKeys.splice(deleteDataIndex, 1);
    }
    return ({
        apiKeys
    })
}

// Plan Utility Functions
export const handleSuccessCreditReq = () => {
    return {
        isCreditReqSuccess: true,
        isSubmittingCreditReq: false
    }
}
export const handleErrorCreditReq = () => {
    return {
        isCreditReqSuccess: false,
        isSubmittingCreditReq: false
    }
}
export const handleSuccessServiceReq = () => {
    return {
        isServiceReqSuccess: true,
        isSubmittingServiceReq: false
    }
}
export const handleErrorServiceReq = () => {
    return {
        isServiceReqSuccess: false,
        isSubmittingServiceReq: false
    }
}

// support data utility fuctions
export const handleSuccessSupportData = ({ data }) => {
    console.log('Support Data', data);
    let supportTicketData = [];
    if (!isEmptyList(data)) {
        data.map((item, index) => {
            let supportTicket = {
                supportId: item.SupportId,
                accountName: item.AccountName,
                category: item.Category,
                query: item.Query,
                approverComment: item.ApproverComment,
                attachmentUrl: item.AttachmentUrl,
                status: item.Status,
                createdBy: item.CreatedBy,
                createdOn: getDateFromInput(item.CreatedOn, true)
            }
            supportTicketData.push(supportTicket)
        })
    }
    return ({
        isErrorSupportData: false,
        isSuccessSupportData: true,
        isLoadingSupportData: false,
        supportTicketData
    })
}

export const handleErrorSupportData = () => {
    return ({
        isErrorSupportData: true,
        isSuccessSupportData: false,
        isLoadingSupportData: false
    })
}

export const getTransactionData = ({ data }) => {
    console.log('Support Data', data);
    let transactionsData = [];
    if (!isEmptyList(data)) {
        data.map((item, index) => {
            let transaction = {
                AccountUUID:item.AccountUUID,
                transactionId:item.TransactionId,
                paymentId: item.PaymentId,
                // category: item.CategoryValue,
                transactionType: item.CategoryValue,
                amount: item.Amount,
                credit: item.Credit,
                status: item.PaymentStatus,
                receiptUrl: item.ReceiptUrl,
                requestBy: item.CreatedBy,
                createdOn: getDateFromInput(item.CreatedOn, true),
            }
            transactionsData.push(transaction)
            return null
        })
    }
    return ({
        isTransactionsAPIError: false,
        isTransactionsAPILoading: false,
        transactionsData
    })
}

export const handleTransactionsAPIError = () => {
    return ({
        isTransactionsAPIError: true,
        isTransactionsAPILoading: false
    })
}

export const getConfiguratorsDataHandler = ({ data }) => {
    let configuratorsData = [];
    let availableConfigurators = JSON.parse(JSON.stringify(SECRETS_PROVIDERS));
    if (!isEmptyList(data)) {
        
        data.map((item, index) => {
            let configurator = {
                secretName:item.secret_name,
                secretType: Number(item.secret_type),
                useKey : item?.use_key==="1" ? true : false,
                createdOn: getDateFromInput(item.created_on),
                createdBy: item.created_by,
                modifiedBy: item.modified_by,
                modifiedOn: getDateFromInput(item.modified_on)
            }
            let secretTypeIndex = availableConfigurators.findIndex(secrets => Number(secrets.value)===Number(item.secret_type));
            if(secretTypeIndex>=0){
                availableConfigurators.splice(secretTypeIndex,1)
            }
            configuratorsData.push(configurator)
            return null
        })
    }
    
    return ({
        isConfiguratorsError: false,
        isConfiguratorsLoading: false,
        configuratorsData, availableConfigurators
    })
}

export const handleConfiguratorsError = () => {
    return ({
        isConfiguratorsError: true,
        isConfiguratorsLoading: false
    })
}

export const getLicenseeData = (data) => {
    let licenseeData = {};
    let selectedProductLicense = EMPTY_STRING;
    console.log("CHECK LICENSEE",data)
    if(!isEmptyObject(data)){
        let authenticateLicenseeData = Object.assign([],data?.authenticateLicense)
        let producLicenseeData = Object.assign([],data?.productLicenses)
        licenseeData["authentication"] = []
        licenseeData["productLicenses"] = []
        if(!isEmptyList(authenticateLicenseeData)){
            authenticateLicenseeData.forEach(licenseeItem => {
                let singleLicenseeData = {};
                singleLicenseeData["licenseeID"] = licenseeItem?.SortId;
                singleLicenseeData["expiryDate"] = getDateFromInput(licenseeItem?.ExpiresAt, true);
                singleLicenseeData["productID"] = licenseeItem?.ProductId;
                let currentDate = new Date()
                let [month, day, year] = singleLicenseeData["expiryDate"].split('/');
                let validityDate = new Date(year, month - 1, day);
                let remainingDays = validityDate - currentDate
                singleLicenseeData["remainingDays"] = Math.ceil(remainingDays / (1000 * 60 * 60 * 24));
                licenseeData["authentication"].push(singleLicenseeData)
            });
            selectedProductLicense = licenseeData["authentication"][0]?.productID
        }
        if(!isEmptyList(producLicenseeData)){
            producLicenseeData.forEach(licenseeItem => {
                let singleLicenseeData = {};
                singleLicenseeData["licenseeID"] = licenseeItem?.SortId;
                singleLicenseeData["aliasName"] = licenseeItem?.AliasName;
                singleLicenseeData["balance"] = JSON.parse(licenseeItem?.Details)?.page_count;
                singleLicenseeData["licenseStatus"] = licenseeItem?.LicenseStatus;
                singleLicenseeData["expiryDate"] = getDateFromInput(licenseeItem?.ExpiresAt, true);
                singleLicenseeData["productID"] = licenseeItem?.ProductId;
                let currentDate = new Date()
                let [month, day, year] = singleLicenseeData["expiryDate"].split('/');
                let validityDate = new Date(year, month - 1, day);
                let remainingDays = validityDate - currentDate
                singleLicenseeData["remainingDays"] = Math.ceil(remainingDays / (1000 * 60 * 60 * 24));
                licenseeData["productLicenses"].push(singleLicenseeData)
            });
        }
    }
    console.log("CHECK LICENSEE",licenseeData)
    return {
        selectedProductLicense,
        licenseeData,
        isSelfHostLoading : false,
        isErrorLicenseeData : false
    };
}

export const getErrorLicenseeData = () => {
    return {
        licenseeData : {},
        isSelfHostLoading : false,
        isErrorLicenseeData : true
    };
}