import { EMPTY_STRING } from "../../strings/Strings";
import { SELECT_OPTIONS_LIST } from "../../utils/Constants";
import { isEmpty } from "../../utils/UtilityFunctions";

export const INITIAL_STATE = {
    isSubmitError : false,
    phoneData : {},
    phoneNormal : EMPTY_STRING,
    nameText: EMPTY_STRING,
    lastNameText:EMPTY_STRING,
    companyNameText:EMPTY_STRING,
    jobTitleText: EMPTY_STRING,
    emailText: EMPTY_STRING,
    phoneText: EMPTY_STRING,
    howDidYouKnowText: SELECT_OPTIONS_LIST.HOW_DID_YOU_KNOW[0],
    message: EMPTY_STRING,
    isErrorNameText: false,
    isErrorLastNameText:false,
    isErrorCompanyNameText:false,
    isErrorJobTitleText: false,
    isErrorEmailText: false,
    isErrorPhoneText: false,
    isErrorMessage: false,
    errorNameText: EMPTY_STRING,
    errorLastNameText:EMPTY_STRING,
    errorCompanyNameText:EMPTY_STRING,
    errorJobTitleText: EMPTY_STRING,
    errorEmailText: EMPTY_STRING,
    errorPhoneText: EMPTY_STRING,
    errorMessage: EMPTY_STRING,
    isSubmittingRequest: false,
    isRequestSubmissionSucccess: false,
    isCountryCodeError : false,
    isSlotModalOpen:false

}

export const validateField = (value,errorKey,errorTextKey,errorText,validate) => {
    let result = {};
    result[errorKey] = !validate(value)
    result[errorTextKey] = result[errorKey]? isEmpty(value)? EMPTY_STRING : errorText : EMPTY_STRING
    return result;
}

export const handleSuccessfullSubmission = (res) => {
    return({
        ...INITIAL_STATE,
        // isRequestSubmissionSucccess: true //TODO:REMOVE
    })
}

export const handleErrorSubmission = (err) => {
    return({
        isSubmittingRequest: false
    })
}