import { useState, useRef } from 'react';
import globalClasses from '../../../../App.module.css';
import { isEmpty, isEmptyList } from '../../../../utils/UtilityFunctions';
import Select from 'react-select';
import cx from 'classnames'
import { EMPTY_STRING, SEPARATORS } from '../../../../strings/Strings';

function SelectField(props) {
    const ref = useRef(null);

    let { containerClass, selectClassName, menuPlacement, value, onChangeHandler, isDisabled, isFieldError,
        isReadOnly, label, id, placeholder, isError, isSearchable, validate, optionsList, isMandatory,
        disableErrorText, onBlurHandler, isClearable, labelClass, selectMultiple, menuRootElement,
        isGroupedList, showErrorText, errorTextValue, menuMaxHeight } = props;
    let labelComponent = null;
    let optionComponent = null;
    let showErrorTextValue = showErrorText ? errorTextValue : EMPTY_STRING
    let options = []
    const customStyles = {
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        placeholder:(styles, state) => ({
            ...styles,
            fontSize:"14px"
        }),
        control: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
            border: "1px solid #cccccc",
            boxShadow: "none",
            // "&:hover": {
            //   border: "1px solid #ff8b67",
            //   boxShadow: "0px 0px 6px #ff8b67"
            // }

        }),
        menu: (styles) => ({
            ...styles,
            zIndex: 1,
            marginTop: 10
        }),
        menuList: (styles) => ({
            ...styles,
            maxHeight : menuMaxHeight || 120
        }),
        multiValue: (styles,state) => {
            if(state.data?.isFixed){
                return { ...styles, backgroundColor: "var(--color-medium-back)", borderRadius : "4px" }
            }
            return {
                ...styles,
                backgroundColor : "#f2f2f2",
                borderRadius : "4px"
            }
        },
        multiValueLabel: (styles, state) => {
            if(state.data?.isFixed){
                return { ...styles, paddingRight: 10 }
            }
            return {
                ...styles
            }
        },
        multiValueRemove: (styles, state) => {
            if(state.data?.isFixed){
                return {
                    ...styles,
                    display: 'none'
                }
            }
            return {
                ...styles,
                ':hover' : {
                    backgroundColor : "transparent",
                    color : "var(--error-text)"
                }
            }
        },
        menuPortal: (styles) => ({ ...styles, zIndex: 9999,marginTop:0 }),
        groupHeading: (styles) => ({
            ...styles,
            fontWeight : "bold",
            color: "var(--color-dark-blue)",
        })

    }

    let [isSelectError, setIsSelectError] = useState(false);
    let [errorText, setErrorText] = useState(false);
    const validateSelectField = (value) => {

        if (!isEmpty(validate)) {
            let validateResult = validate(value, isMandatory)
            setIsSelectError(validateResult.error)
            setErrorText(validateResult.text)
        }
    }

    const onChangeGroupSelectOption = (selectedOption) => {
        onChangeHandler(selectedOption)
        if (isError) {
            validateSelectField(selectedOption?.value)
        }
    }

    const onChangeMultiSelectOption = (selectedOptionList,actionMeta) => {
        if (!selectedOptionList) {
            selectedOptionList = {
                label: '',
                value: '',
            };
        }
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue?.isFixed) {
                    return;
                }
                break;
            case 'clear':
                selectedOptionList = options.filter((v) => v?.isFixed);
                break;
        }
        let value = selectedOptionList.map(selected => selected.value)
        onChangeHandler(value)
        if (isError) {
            validateSelectField(value.join(SEPARATORS.COMMA))
        }
    }

    const onChangeOption = (selectedOption) => {
        if (!selectedOption) {
            selectedOption = {
                label: '',
                value: '',
            };
        }
        let value = selectedOption.value
        onChangeHandler(value)
        if (isError) {
            validateSelectField(value)
        }
    }

    if (isFieldError && !isSelectError) {
        if(selectMultiple){
            validateSelectField(value.join(SEPARATORS.COMMA))
        } else {
            validateSelectField(value)
        }
    }

    if (!isEmpty(label)) {
        labelComponent = (
            <label
                className={cx([labelClass,globalClasses.FormLabel, isMandatory ? globalClasses.FormRequiredInput : ""])}
                htmlFor={id}>
                {label}
            </label>
        )
    }
    let formFieldContainerClass = isEmpty(containerClass) ? "position-relative form-group col-12 col-md-6 px-1 px-md-3 my-3"
        : containerClass;

    let selectContainerClass = isEmpty(selectClassName) ? (isSelectError || !isEmpty(showErrorTextValue)) ? globalClasses.ErrorFormInput : '' : selectClassName

    if(isGroupedList){
        options = [ ...optionsList ]
    } else {
        if (!isEmptyList(optionsList)) {
            optionComponent = optionsList.map((item, index) => {
                let itemvalue, itemlabel, icon, isFixed;
                if (typeof item === 'object') {
                    itemvalue = item.value
                    itemlabel = item.label
                    icon = item.icon ? item.icon : ""
                    isFixed = item?.isFixed ? item.isFixed : false
                } else {
                    itemvalue = item
                    itemlabel = item
                }
                if (item?.hide) {
                    return null
                }
                options.push({
                    value: itemvalue,
                    label: itemlabel,
                    icon: icon,
                    isFixed : isFixed
                })
            })
        }
    }
    let selectedValue = isGroupedList? value : options.filter(option => selectMultiple ? value.includes(option.value) : option.value == value)
    
    return (
        <div ref={ref} className={formFieldContainerClass}>
            {labelComponent}
            <Select options={options} className={cx([globalClasses.PointerCursor,
                selectContainerClass])}
                id={id}
                isClearable={isClearable}
                placeholder={placeholder}
                value={selectedValue}
                isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
                onChange={isGroupedList? onChangeGroupSelectOption : selectMultiple ? onChangeMultiSelectOption : onChangeOption}
                onBlur={isClearable ?  onBlurHandler : () => { }}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                readOnly={isReadOnly}
                maxMenuHeight={220}
                menuPortalTarget={menuRootElement}
                closeMenuOnScroll={true}
                isMulti = {selectMultiple || false}
                menuPlacement={menuPlacement || 'auto'}
                getOptionLabel={e => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {!isEmpty(e.icon) ? <img src={e.icon} alt={e.label} style={{width:"auto",height:"18px",objectFit:"unset"}}/> : ""}
                        <span className={globalClasses.SelectLabel} style={{ marginLeft: 5 }}>{e.label}</span>
                    </div>
                )}
                styles={customStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: '4px',
                    colors: {
                        ...theme.colors,
                        primary25: 'lightgrey',
                    },
                })}
            />

            {
                !disableErrorText &&
                <span className={globalClasses.ErrorText}>
                    {errorText}
                </span>
            }
            {
                !isEmpty(showErrorTextValue) && 
                <span className={globalClasses.ErrorText}>
                    {errorTextValue}
                </span>
            }
        </div>
    )
}

export default SelectField;