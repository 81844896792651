// modules
import { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames';
import axios from '../../../axios/Axios'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCircleFill } from "react-icons/bs";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField"

// utils
import {
    INITIAL_STATE, TAB_IDS, getAllAccounts, handleAccountsAPIError, getFilteredAccounts,
    getProfitLossReport, getAccountLevelReport, handleProfitLossAPIError, handleAccountLevelAPIError, getSupportRequests, handleSupportRequestsAPIError, getUserInvitesStatus, handleUserInviteRequestsAPIError, SUPPORT_INITIAL_STATE
} from './AccountManagement.utils';
import { GET_ACCOUNTS, GET_PROFIT_LOSS_REPORT, GET_ACCOUNT_LEVEL_PA_REPORT, GET_SUPPORT_REQUESTS, GET_USER_INVITES_STATUS, MODEL_ADMINISTATION, RAISE_SUPPORT_TICKET } from '../../../urls/Urls';
import { BUTTON_ICON_POSITION, SORT_TABLE_VALUES, USER_MANAGEMENT, USER_ROLE } from '../../../utils/Constants';
import { QUERY_STRINGS, USER_MNGMENT_STRINGS } from '../../../strings/UserManagement';
import { getBadgeDetails, getDateFromInput, getWesternNumber, isEmpty, isEmptyList, isValidMessageString } from '../../../utils/UtilityFunctions';
import { BUTTON_TEXTS, EMPTY_STRING, SEPARATORS } from '../../../strings/Strings';

// classes
// import classes from './AccountManagement.module.css';
import classes from '../UserManagement.module.css'
import globalClasses from '../../../App.module.css';

// components
import Badge from '../../../components/ui_elements/badge/Badge';
import Loader from '../../../components/loader/Loader';
import ErrorPage from '../../../components/error_page/ErrorPage';
import NoData from '../../../components/no_data/NoData';
import Button from '../../../components/ui_elements/button/ButtonNew';
import SearchInput from '../../../components/search_input/SearchInput';

// subcomponents
import AccountCreationFormPopup from './AccountCreationFormPopup';
import UserManagement from '../UserManagement';
import TransactionsPane from "../tab_panes/TransactionsPane"

// images
import EDIT_ICON from '../../../assets/images/user-manage/edit-icon.png';
import ADD_ICON from "../../../assets/images/user-manage/add-icon.png";
import { validateField } from '../../reqest_demo/RequestDemo.utils';
import SupportRequests from './support_requests/SupportRequests';
import UserInvites from './user_invites/UserInvites';
import { ROUTE_CONSTANTS } from '../../../routes/RouteConstants';
import SuccessScreen from '../../../components/success_screen/SuccessScreen';
import AccountCreationPopup from '../AccountCreatePopup';
import TextAreaField from '../../../components/ui_elements/form_fields/teaxt_area_input/TextAreaField';
import ModelAdministration from './model_administration/ModelAdministration';
import { getCognitoUserAccessToken } from '../../sign_up/Cognito.utils';
import SortingTable from '../../../components/sorting_table/SortingTable';
import { IMAGES } from '../../../utils/ImageConstants';
import { getTableTimeData } from '../../../utils/UIUtilityFunctions';
import { BUTTON_TYPES } from '../../../utils/ButtonConstants';
import { MdGroupAdd } from 'react-icons/md';

class AccountManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
            ...SUPPORT_INITIAL_STATE
        }
    }

    getAccounts = () => {
        // Get all accounts 
        axios.get(GET_ACCOUNTS,
            {
                headers: {
                    Authorization: "Bearer " + getCognitoUserAccessToken()
                }
            })
            .then((response) => {
                this.setState({
                    ...getAllAccounts(response.data)
                })
            }).catch((err) => {
                this.setState({
                    ...handleAccountsAPIError(err)
                })
            })
    }

    setError = () => {
        if (document.body.style.overflow !== 'auto') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            ...handleAccountsAPIError('Error')
        })
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paymentStatus = urlParams.get('payment')
        const requestStatus = urlParams.get('request')
        urlParams.delete('payment');
        urlParams.delete('request');
        if (paymentStatus === 'success' || requestStatus === 'success') {
            this.setState({
                showPaymentSuccessPopup: paymentStatus === 'success',
                showPaymentRequestSuccessPopup: requestStatus === 'success',
                isAccountsAPILoading: false
            })
        } else {
            this.getAccounts()
        }
    }

    onTabSelectHandler = (value) => {

        this.setState({
            activeTab: value
        }, () => {
            let { activeTab } = this.state
            if(activeTab == TAB_IDS.ACCOUNTS){
                this.getAccounts()
            }
            if (activeTab == TAB_IDS.SUPPORT) {
                this.getSupportRequests()
            }
            else if (activeTab == TAB_IDS.PROFIT_LOSS_REPORT) {
                console.log("active tab profit loss")
                 //this.getProfitLossReportData()
                this.getAccountLevelReportData()
            } else if (activeTab == TAB_IDS.USER_INVITES) {
                this.getUserInvites()
            }
        })
    }

    getSupportRequests = () => {
        axios.get(GET_SUPPORT_REQUESTS, {
            headers: {
                Authorization: "Bearer " + getCognitoUserAccessToken()
            }
        }
        )
            .then((response) => {
                console.log("respo support", response)
                this.setState({
                    ...getSupportRequests(response.data)
                })
            }).catch((err) => {
                this.setState({
                    ...handleSupportRequestsAPIError(err)
                })
            })
    }

    getUserInvites = () => {
        axios.get(GET_USER_INVITES_STATUS, {
            headers: {
                Authorization: "Bearer " + getCognitoUserAccessToken()
            }
        }
        )
            .then((response) => {
                this.setState({
                    ...getUserInvitesStatus(response)
                })
            }).catch((err) => {
                this.setState({
                    ...handleUserInviteRequestsAPIError(err)
                })
            })
    }

    getProfitLossReportData = () => {
        // Get all accounts 
        axios.post(GET_PROFIT_LOSS_REPORT,
            {
                "start_date": new Date(this.state.profitReportStartDate).toISOString().slice(0, 10),
                "end_date": new Date(this.state.profitReportEndDate).toISOString().slice(0, 10)
            },
            {
                headers: {
                    Authorization: "Bearer " + getCognitoUserAccessToken()
                }
            })
            .then((response) => {
                console.log("respo report", response)
                this.setState({
                    ...getProfitLossReport(response)
                })
            }).catch((err) => {
                this.setState({
                    ...handleProfitLossAPIError(err)
                })
            })
    }

    getAccountLevelReportData = () => {
        console.log("getAccountLevelReportData()")
        // Get account level data
        //axios.post("https://dev.lowcodeailab.com/api/um/getaccountprofitlossreport",
        axios.post(GET_ACCOUNT_LEVEL_PA_REPORT,
            {
                "start_date": new Date(this.state.profitReportStartDate).toISOString().slice(0, 10),
                "end_date": new Date(this.state.profitReportEndDate).toISOString().slice(0, 10)
            },
            {
                headers: {
                    Authorization: "Bearer " + getCognitoUserAccessToken()
                }
            })
            .then((response) => {
                console.log("AccountLevelReportData ", response)
                this.setState({
                    ...getAccountLevelReport(response)
                })
            }).catch((err) => {
                this.setState({
                    ...handleAccountLevelAPIError(err)
                })
            })
    }

    // on click create new account 
    onCreatAccountClicked = () => {
        this.setState({
            showAddAccountPopup: true
        }, () => {
            this.clearSearch()
        })
    }

    // on click cancel add/edit account
    onCloseAccountPopup = () => {
        if (document.body.style.overflow !== 'auto') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            showAddAccountPopup: false,
            actionableData: null,
            isAccountEditable: false
        })
    }

    // on submit account popup
    onSubmitHandler = () => {
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            showAddAccountPopup: false,
            actionableData: null,
            isAccountEditable: false,
            isAccountsAPILoading: true
        }, () => {
            this.getAccounts()
        })
    }

    // on click edit icon
    onClickEditAccount = (data) => {
        let actionableData = Object.assign({}, data)
        this.setState({
            actionableData,
            isAccountEditable: true,
            showAddAccountPopup: true
        }, () => {
            this.clearSearch()
        })
    }

    // on change tier in edit mode
    onChangeEditableTierLevel = (tierLevel) => {
        let { actionableData } = this.state;
        actionableData['tier'] = tierLevel
        this.setState({
            actionableData
        })
    }

    // on click account name
    onClickAccountName = (id) => {
        this.setState({
            showManage: true,
            manageAccountID: id
        })
    }

    // on back manage
    onClickBack = () => {
        this.setState({
            showManage: false,
            manageAccountID: 0
        })
    }

    onChangeSearchAccount = (account) => {
        let { accounts } = this.state;
        this.setState({
            searchedAccount: account
        }, () => {
            if (isEmpty(account)) {
                this.clearSearch();
            } else {
                this.setState({
                    ...getFilteredAccounts(accounts, account)
                })
            }
        })
    }

    clearSearch = () => {
        this.setState({
            searchedAccount: EMPTY_STRING,
            isSearchAccountSelected: false,
            filteredAccounts: []
        })
    }

    onDatePickerChangeHandler = (dates) => {
        const [start, end] = dates;
        const diffTime = Math.abs(start - end);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let noOfMonth = Math.ceil((diffDays + 1) / 30); // inclusive of end date
        this.setState({
            profitReportStartDate: start,
            profitReportEndDate: end,
            noOfMonth
            // isProfitLossAPILoading: true
        })


    }

    onDatePickerSelectHandler = () => {
        // const [start, end] = dates
        // if (this.state.profitReportStartDate != start || this.state.profitReportEndDate != end) {
        if (!isEmpty(this.state.profitReportEndDate)) {
            this.setState({
                isProfitLossAPILoading: true
            }, () => {

                this.getProfitLossReportData()
            })
        }
        // }s

    }

    onChangeProfitDefaultValue = (val) => {
        this.setState({
            profitReportInputCreditValue: val
        })

        //         () => {
        //             if (this.state.isErrorProfitReportInputCreditValue) {
        //                 this.onBlurProfitDefaultValue(val)
        //             }
        //         })
    }

    onChangeAccountLevelDefaultValue = (val) => {
        this.setState({
            accountLevelReportInputCreditValue: val
        })
    }

    isValidCreditNumber = () => {
        return Number(this.state.profitReportInputCreditValue >= 0)
    }
    onCalculateProfitReportClicked = () => {

        // console.log("tets teste", validateField(this.state.profitReportInputCreditValue, 'isErrorProfitReportInputCreditValue', 'isErrorProfitValueText', 'Invalid Number', isValidNumber))
        this.setState({
            ...validateField(this.state.profitReportInputCreditValue, 'isErrorProfitReportInputCreditValue', 'isErrorProfitValueText', 'Invalid Number', this.isValidCreditNumber),
            isErrorInDatePicker: isEmpty(this.state.profitReportEndDate)
        }, () => {
            if (!this.state.isErrorInDatePicker && !this.state.isErrorProfitReportInputCreditValue) {
                this.setState({
                    isProfitLossAPILoading: true,
                    thresholdValue: Number(this.state.profitReportInputCreditValue)
                }, () => {
                    this.getProfitLossReportData()
                })
            }
        }
        )
    }

    onCalculateAccountLevelReportClicked = () => {
        // console.log("tets teste", validateField(this.state.profitReportInputCreditValue, 'isErrorProfitReportInputCreditValue', 'isErrorProfitValueText', 'Invalid Number', isValidNumber))
        this.setState({
            ...validateField(this.state.accountLevelReportInputCreditValue, 'isErrorAccountLevelReportInputCreditValue', 'isErrorAccountLevelValueText', 'Invalid Number', this.isValidCreditNumber),
            isErrorInDatePicker: isEmpty(this.state.profitReportEndDate)
        }, () => {
            if (!this.state.isErrorInDatePicker && !this.state.isErrorAccountLevelReportInputCreditValue) {
                this.setState({
                    isAccountLevelAPILoading: true,
                    thresholdValue: Number(this.state.accountLevelReportInputCreditValue)
                }, () => {
                    this.getAccountLevelReportData()
                })
            }
        }
        )
    }

    // onBlurProfitDefaultValue = (val) => {
    //     this.setState({
    //         ...validateField(val, 'isErrorProfitReportInputCreditValue', 'isErrorProfitValueText', 'Invalid Number', isValidNumber),
    //         isProfitLossAPILoading: true

    //     }, () => {
    //         this.getProfitLossReportData(val)
    //     })
    // }

    onClickSupportEditComment = (item) => {
        console.log('Item', item)
        let supportEditableData = Object.assign({}, item);
        this.setState({
            isSupportEditable: true,
            supportEditableData
        })
    }

    onChangeSupportComment = (value) => {
        let { supportEditableData } = this.state;
        supportEditableData['approverComment'] = value
        this.setState({
            supportEditableData,
            editableCommentError: false
        })
    }

    onClickSupportEditCancel = () => {
        if (document.body.style.overflow !== 'auto') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            isSupportEditable: false,
            supportEditableData: {},
            isSubmitError: false,
            isSupportUpdating: false
        })
    }

    onClickSupportEditDone = () => {
        let { userDetails } = this.props
        let { supportEditableData } = this.state;
        let editableCommentError = isValidMessageString(supportEditableData.approverComment, 5000, 10, true).error;
        if (!editableCommentError) {
            let url = RAISE_SUPPORT_TICKET
                + QUERY_STRINGS.SUPPORT_TICKET_ROLE + USER_ROLE.SUPER_ADMIN.ID;
            this.setState({
                isSupportUpdating: true
            }, () => {
                let supportId = (supportEditableData.supportId).replace('spt_', '');
                let user = userDetails.email
                let comment = supportEditableData.approverComment
                let supportUpdateData = {
                    "support_id": supportId,
                    "communication_email": user,
                    "approver_comments": comment
                }
                axios.post(url, supportUpdateData,
                    {
                        headers: {
                            Authorization: "Bearer " + getCognitoUserAccessToken()
                        }
                    })
                    .then((res) => {
                        this.setState({
                            isSupportRequestsAPILoading: true
                        }, () => {
                            this.onClickSupportEditCancel()
                            this.getSupportRequests()
                        })
                    })
                    .catch((err) => {
                        console.log('Error', err.message)
                        this.setState({
                            isSupportEditable: true,
                            isSubmitError: false,
                            isSupportRequestsAPILoading: false,
                            isSupportUpdating: false
                        })
                    })
            })
        } else {
            this.setState({
                editableCommentError,
                isSubmitError: true,
                isSupportEditable: true,
                isSupportUpdating: false
            })
        }
    }

    onAutoCompleteChangeInputHandler = (value) => {
        console.log('onAutoCompleteChangeInputHandler')
        if(value === 1)
            this.getAccountLevelReportData()
        else if(value === 2)
            this.getProfitLossReportData()

        this.setState({
            searchValue: value,
            isSearchItemSelected: false,
            lastEvalKey: null
        }, () => {
      
        })
    }

    onShowOrHideAccountSubscriptions = (accountUUID) => {
        let { showAccountUUID } = this.state;
        let newShowAccountUUID = showAccountUUID
        if(showAccountUUID === accountUUID){
            newShowAccountUUID = EMPTY_STRING
        } else {
            newShowAccountUUID = accountUUID
        }
        this.setState({
            showAccountUUID : newShowAccountUUID
        })
    }

    render() {
        let { accounts, isAccountsAPILoading, isAccountsAPIError, activeTab, isAccountEditable,
            actionableData, showAddAccountPopup, manageAccountID, showManage, accountNameList,
            filteredAccounts, searchedAccount, showPaymentSuccessPopup, showPaymentRequestSuccessPopup,
            isProfitLossAPILoading, isProfitLossAPIError, profitLossData,
            profitReportInputCreditValue, profitReportStartDate, profitReportEndDate,
            isErrorProfitReportInputCreditValue, isErrorProfitValueText,
            isErrorInDatePicker, noOfMonth, thresholdValue, isSupportRequestsAPIError, isSupportRequestsAPILoading,
            supportEditableData, supportRequestsData, userInvitesData, isUserInvitesAPIError,
            isSubmitError, editableCommentError, isUserInvitesAPILoading, isSupportUpdating,
            isSupportEditable, accountLevelData, isAccountLevelAPILoading, isAccountLevelAPIError, 
            isErrorAccountLevelReportInputCreditValue, accountLevelReportInputCreditValue, isErrorAccountLevelValueText } = this.state
        let { userDetails } = this.props
        let accountNameStyle = cx([globalClasses.Font24, classes.Title])

        let accountsContainer = null, buttonAndFilterComponent = null, filterComponent = null, transactionsContainerComponent = null;
        let accountCreationEditPopupComponent = null;
        let reportTypeContainer = null
        let accountData = !isEmpty(searchedAccount) ? filteredAccounts : accounts

        let profitLossDataContainer = null
        let accountLevelDataContainer = null //account level report

        if (isAccountsAPILoading) {
            accountsContainer = <Loader />
        } else {
            if (isAccountsAPIError) {
                accountsContainer = <ErrorPage />
            } else if (showPaymentSuccessPopup || showPaymentRequestSuccessPopup) {
                return (
                    <SuccessScreen
                        className={''}
                        onClickHandler={() => {
                            window.location.replace(ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT)
                        }}
                        mainText={showPaymentSuccessPopup ? "Payment Successful" : "Thank you"}
                        subText={showPaymentSuccessPopup ? "You can dowload the receipt of this payment in the transaction section." : "Your request has been placed, Vivas.AI team will reach out to you soon."}
                        buttonText={BUTTON_TEXTS.DONE}
                    />
                )
            } else {
                if (showAddAccountPopup) {
                    console.log(showAddAccountPopup)
                    accountCreationEditPopupComponent = (
                        <AccountCreationFormPopup
                            enableEditMode={isAccountEditable}
                            editableData={actionableData}
                            onCloseHandler={this.onCloseAccountPopup}
                            username={userDetails.email}
                            onSubmitHandler={this.onSubmitHandler}
                            setError={this.setError}
                            accountNameList={accountNameList}
                        />
                    )
                }
                if (isEmptyList(accountData)) {
                    accountsContainer = <>
                        <NoData mainText={'No Accounts Available'} subText={''} />
                    </>
                } else {
                    accountsContainer = (
                        <SortingTable
                            enableExports={true}
                            exportTableName={["Superadmin","Accounts"].join(SEPARATORS.UNDERSCORE)}
                            exportKeysList={[
                                {
                                    key: 'accountUUID',
                                    label: 'Account ID',
                                    getValue: (item) => item.accountUUID
                                },
                                {
                                    key: 'accountName',
                                    label: 'Account Name'
                                },
                                {
                                    key: 'companyName',
                                    label: 'Company Name'
                                },
                                {
                                    key: 'activeUsers',
                                    label: 'Active User'
                                }
                            ]}
                            headingList={
                                [
                                    {
                                        key: 'accountUUID',
                                        label: 'Account ID',
                                        isDate: false,
                                        renderItem: (item) => {
                                            return (
                                                <p
                                                    style={{ cursor: 'pointer', color: '#2159C9', width: 'fit-content' }}
                                                    onClick={() => this.props.history.push(ROUTE_CONSTANTS.USER_MANAGEMENT+"/"+item.accountUUID)}
                                                    className='mb-0'>
                                                    {item.accountUUID}
                                                </p>
                                            )
                                        }
                                    },
                                    {
                                        key: 'accountName',
                                        label: 'Account Name',
                                        isDate: false
                                    },
                                    {
                                        key: 'companyName',
                                        label: 'Company Name',
                                        isDate: false
                                    },
                                    {
                                        key: 'isActive',
                                        label: 'Status',
                                        isDate: false,
                                        renderItem: (item) => {
                                            return (
                                                Number(item.isActive) == USER_MANAGEMENT.USER_STATUS.INACTIVE ?
                                                    <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-expired)" />&nbsp;Expired</span>
                                                    : 
                                                    Number(item.isActive) == USER_MANAGEMENT.USER_STATUS.UNSUBSCRIBED ?
                                                    <span ><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="Red" />&nbsp;Closed</span>
                                                    :
                                                    <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-active)" />&nbsp;Active</span>
                                            )
                                        }
                                    },
                                    {
                                        key: 'activeUsers',
                                        label: 'Active User',
                                        isDate: false,
                                    },
                                    {
                                        key: 'accountSubscriptions',
                                        label: EMPTY_STRING,
                                        isDate: false,
                                        unsortable : true,
                                        showSubItem : (item) => item.accountUUID===this.state.showAccountUUID,
                                        renderItem: (item) => {
                                            let isSubscriptionsAvailable = !isEmptyList(item.accountSubscriptions)
                                            if(!isSubscriptionsAvailable){
                                                return "-"
                                            }
                                            return(
                                                <span className={classes.SubscriptionButton} onClick={() => this.onShowOrHideAccountSubscriptions(item.accountUUID)}>
                                                    {(item.accountUUID===this.state.showAccountUUID) ? "Hide Subscription" : "View Subscription"}
                                                </span>
                                            )
                                        },
                                        renderSubItem: (item) => {
                                            console.log("accountSubscriptions",item)
                                            let isSubscriptionsAvailable = !isEmptyList(item.accountSubscriptions)
                                            if(item.accountUUID===this.state.showAccountUUID && isSubscriptionsAvailable){
                                                return (
                                                    <SortingTable
                                                        headingList = {
                                                            [
                                                                {
                                                                    key : "productName",
                                                                    label : "Product",
                                                                    isDate : false
                                                                },
                                                                {
                                                                    key: 'planName',
                                                                    label: "Plan",
                                                                    isDate : false
                                                                },
                                                                {
                                                                    label: 'Status',
                                                                    key: "isPlanActive",
                                                                    isDate : false,
                                                                    renderItem: (item) => {
                                                                        return (
                                                                            Number(item.isPlanActive) == USER_MANAGEMENT.USER_STATUS.INACTIVE ?
                                                                                <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-expired)" />&nbsp;Expired</span>
                                                                                : 
                                                                                Number(item.isPlanActive) == USER_MANAGEMENT.USER_STATUS.UNSUBSCRIBED ?
                                                                                <span ><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="Red" />&nbsp;Closed</span>
                                                                                :
                                                                                <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-active)" />&nbsp;Active</span>
                                                                        )
                                                                    }
                                                                }, {
                                                                    key: 'balance',
                                                                    label: "Balance",
                                                                    isDate : true
                                                                }, {
                                                                    key: 'balanceUnits',
                                                                    label: "Balance Unit",
                                                                    isDate : false
                                                                }, {
                                                                    key: 'validityDate',
                                                                    label: "Valid Up To",
                                                                    isDate : true,
                                                                    onlyDate : true
                                                                }
                                                            ]
                                                        }
                                                        dataList = {item.accountSubscriptions}
                                                        itemsToShow = {SORT_TABLE_VALUES.ITEM_COUNTS.ACCOUNTS}
                                                        isHalfScreen = {false}
                                                        indexed = {false}
                                                    />
                                                )
                                            }
                                            return null;
                                        }
                                    },
                                    {
                                        key: 'actions',
                                        label: EMPTY_STRING,
                                        isDate: false,
                                        unsortable : true,
                                        renderItem: (item) => {
                                            return(
                                                <div className="d-flex align-items-center" style={{width:"100px"}}>
                                                    <img
                                                        className={cx(['mx-2', classes.ActionIconBlue])}
                                                        src={IMAGES.ACTIONS.EDIT_ICON_BLUE} alt={'Edit'}
                                                        title={USER_MNGMENT_STRINGS.TOOLTIP_EDIT_ACCOUNT}
                                                        onClick={() => this.onClickEditAccount(item)}
                                                    />
                                                </div>
                                            )
                                        }
                                    }
                                ]
                            }
                            enableSearch = {true}
                            searchKeysList = {
                                [
                                    {
                                        key : "accountName",
                                        label : "Account Name",
                                        value : "Account Name",
                                        placeholder : "Search by Account Name"
                                    },
                                    {
                                        key : "accountUUID",
                                        label : "Account ID",
                                        value : "Account ID",
                                        placeholder : "Search by Account ID"
                                    },
                                    {
                                        key : "companyName",
                                        label : "Company Name",
                                        value : "Company Name",
                                        placeholder : "Search by Company Name"
                                    }
                                ]
                            }
                            dataList={accountData}
                            itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.ACCOUNTS}
                            noteText = {SORT_TABLE_VALUES.TABLE_NOTES.DATE_FORMAT1}
                            containerClass = {"mt-3"}
                        />
                    )
                }

                buttonAndFilterComponent = (
                    <div className="d-flex flex-wrap col-12">
                        {filterComponent}
                        <Button
                            label={"Add Account"}
                            containerClass={cx(['ms-auto mt-3 mt-md-0'])}
                            icon={<MdGroupAdd className={"me-2"} size={20} />}
                            onClickHandler={this.onCreatAccountClicked}
                            actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                            radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                            borderType={BUTTON_TYPES.BORDER.BORDERED}
                        />
                    </div>
                )
            }
        }

        if (showManage) {
            return (
                <UserManagement onClickBack={this.onClickBack} manageAccountID={manageAccountID} {...this.props} />
            )
        }

        //Report dropdown
        reportTypeContainer =
            <>
                <div className='row'>
                    <div className="col-md-4 px-1 px-md-3 mb-3">
                        
                        <SelectField
                            containerClass={"w-100"}
                            disableErrorText={true}
                            placeholder={"Select Report"}
                            optionsList={
                                [
                                    { value: 1, label: 'Account Level P&L Report' },
                                    { value: 2, label: 'Model Level P&L Report' }
                                ]
                            }
                            isClearable={true}
                            // onBlurHandler={(value) => this.onAutoCompleteBlurInputHandler(value)}
                            onChangeHandler={(value) => this.onAutoCompleteChangeInputHandler(value)}
                            value={this.state.searchValue}
                        />
                    </div>
                </div>
            </>

        //Profit Loss Report
        if (isProfitLossAPILoading) {
            profitLossDataContainer = <Loader />
        } else {
            let grandTotal = profitLossData.profit_summary[0].GrandTotal
            let overallCostPrice = profitLossData.profit_summary[0].CostPrice
            let overallSellingPrice = profitLossData.profit_summary[0].SellingPrice
            let profitPercent = grandTotal - (thresholdValue * noOfMonth)
            let profitLossText = (profitPercent > 0) ? "Overall Profit" : "Overall Loss"
            let profitLossContentContainer = null

            if (isProfitLossAPIError) {
                profitLossDataContainer = <ErrorPage />
            }
            else {

                if (isEmptyList(profitLossData.profit_table)) {
                    profitLossContentContainer = <><NoData mainText={'No Data Available'} subText={''} />
                    </>
                }
                else {
                    profitLossContentContainer = (
                        <SortingTable
                            enableExports={true}
                            exportTableName={["Superadmin","PL Report Models"].join(SEPARATORS.UNDERSCORE)}
                            exportKeysList={
                                [
                                    {
                                        key : "ModelName",
                                        label : "Model Name"
                                    },
                                    {
                                        key : "ModelCode",
                                        label : "Model Code"
                                    },
                                    {
                                        key : "NoRecords",
                                        label : "Records"
                                    },
                                    {
                                        key : "NumberOfTransactions",
                                        label : "Transactions"
                                    },
                                    {
                                        key : "TotalCredits",
                                        label : "Total Credits"
                                    },
                                    {
                                        key : "OverallCP",
                                        label : "Overall Cost Price (USD)",
                                        getValue : (item) => (item.OverallCP).toFixed(4)
                                    },
                                    {
                                        key : "OverallSP",
                                        label : "Overall Selling Price (USD)",
                                        getValue : (item) => (item.OverallSP).toFixed(4)
                                    },
                                    {
                                        key : "OverallProfitPercentage",
                                        label : "Overall Profit (%)",
                                        getValue : (item) => (item.OverallProfitPercentage).toFixed(2)
                                    }
                                ]
                            }
                            addExportRows={
                                [
                                    [EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,"Total",(overallCostPrice).toFixed(4),(overallSellingPrice).toFixed(4),EMPTY_STRING],
                                    [EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,"Model Profit (USD)",EMPTY_STRING,grandTotal,EMPTY_STRING],
                                    [EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,"Fixed Monthly Cost (USD)",EMPTY_STRING,Number(profitReportInputCreditValue).toFixed(4),EMPTY_STRING],
                                    [EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,EMPTY_STRING,profitLossText + " (USD)",EMPTY_STRING,(Math.abs(profitPercent)).toFixed(4),EMPTY_STRING],
                                ]
                            }
                            headingList={
                                [
                                    {
                                        key : "ModelName",
                                        label : "Model Name",
                                        isDate : false
                                    },
                                    {
                                        key : "ModelCode",
                                        label : "Model Code",
                                        isDate : false
                                    },
                                    {
                                        key : "NoRecords",
                                        label : "Records",
                                        isDate : false,
                                        renderItem : (item) => getWesternNumber(item.NoRecords)
                                    },
                                    {
                                        key : "NumberOfTransactions",
                                        label : "Transactions",
                                        isDate : false,
                                        renderItem : (item) => getWesternNumber(item.NumberOfTransactions)
                                    },
                                    {
                                        key : "TotalCredits",
                                        label : "Total Credits",
                                        isDate : false,
                                        renderItem : (item) => getWesternNumber(item.TotalCredits)
                                    },
                                    {
                                        key : "OverallCP",
                                        label : "Overall Cost Price (USD)",
                                        isDate : false,
                                        alignment : SORT_TABLE_VALUES.ALIGNMENTS.RIGHT,
                                        renderItem : (item) => getWesternNumber((item.OverallCP).toFixed(4))
                                    },
                                    {
                                        key : "OverallSP",
                                        label : "Overall Selling Price (USD)",
                                        isDate : false,
                                        alignment : SORT_TABLE_VALUES.ALIGNMENTS.RIGHT,
                                        renderItem : (item) => getWesternNumber((item.OverallSP).toFixed(4))
                                    },
                                    {
                                        key : "OverallProfitPercentage",
                                        label : "Overall Profit (%)",
                                        isDate : false,
                                        alignment : SORT_TABLE_VALUES.ALIGNMENTS.RIGHT,
                                        renderItem : (item) => (item.OverallProfitPercentage).toFixed(2)
                                    }
                                ]
                            }
                            containerClass={"mt-3"}
                            dataList={profitLossData?.profit_table}
                            itemsToShow={profitLossData?.profit_table.length}
                            indexed = {false}
                            additionalRows = {
                                <>
                                    <tr>
                                        <td colSpan={5} className='text-end'><b>Total</b></td>
                                        <td className='text-end'>{getWesternNumber((overallCostPrice).toFixed(4))}</td>
                                        <td className='text-end'>{getWesternNumber((overallSellingPrice).toFixed(4))}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className='text-end'>
                                            <div>
                                                <b>Model Profit (USD)</b>
                                                <div className={globalClasses.Font12Grey}><i>Overall Selling Price - Overall Cost Price</i></div>
                                            </div>
                                        </td>
                                        <td colSpan={2} className='text-end' >{getWesternNumber((grandTotal))}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className='text-end'>
                                            <div>
                                                <b>Fixed Monthly Cost (USD)</b>
                                            </div>
                                        </td>
                                        <td colSpan={2} className='text-end' >{getWesternNumber(Number(profitReportInputCreditValue).toFixed(4))}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className='text-end'>
                                            <div>
                                                <p className='mb-1'><b>{profitLossText + " (USD)"}</b></p>
                                                <div className={globalClasses.Font12Grey}>
                                                    <i>Model Profit - (Fixed Monthly Cost * Number of Months)</i>
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={2} className='text-end'>{getWesternNumber((Math.abs(profitPercent)).toFixed(4))}</td>
                                        <td></td>
                                    </tr>
                                </>
                            }
                        />
                    )
                }
                    profitLossDataContainer =

                        <>
                            <div className='row'>
                                <div className='col-12 col-md-4 px-1 px-md-3 mb-3'>
                                    <div className="form-group col-12">

                                        <label
                                            className={cx([globalClasses.FormLabel])}
                                            htmlFor="profitReportInputCreditValue">
                                            {'Fixed monthly cost (USD)'}
                                        </label>
                                        <input
                                            className={cx(["form-control", globalClasses.FormInput,
                                                isErrorProfitReportInputCreditValue ? globalClasses.ErrorFormInput : ''
                                            ])}
                                            type='number'
                                            min='1'
                                            id="profitReportInputCreditValue"
                                            value={Number(profitReportInputCreditValue)}
                                            // disabled
                                            // placeholder={FORM_LABEL_TEXTS.NAME}
                                            onChange={(event) => this.onChangeProfitDefaultValue(event.target.value)}
                                        // onBlur={(event) => this.onBlurProfitDefaultValue(event.target.value)}
                                        />
                                        <span className={globalClasses.ErrorText}>{isErrorProfitValueText}</span>

                                    </div>
                                </div>
                                <div className='col-12 col-md-4 px-1 px-md-3 mb-3'>
                                    <div className="form-group col-12">
                                    <label
                                        className={cx([globalClasses.FormLabel])}
                                    // htmlFor="profitReportInputCreditValue"
                                    >
                                        {'Select Date Range'}
                                    </label><br/>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={profitReportStartDate}
                                        onChange={this.onDatePickerChangeHandler}
                                        // onCalendarClose={this.onDatePickerSelectHandler}
                                        startDate={profitReportStartDate}
                                        endDate={profitReportEndDate}
                                        selectsRange
                                        className={cx(['form-control', isErrorInDatePicker ?
                                            globalClasses.ErrorFormInput : ""])}
                                        maxDate={new Date()}
                                    // inline
                                    />
                                    {isErrorInDatePicker && <span className={globalClasses.ErrorText}>{'Choose start and end date'}</span>}

                                    </div>
                                </div>

                                <div className='col-12 col-md-4 col-lg-2 px-1 px-md-3 mt-4'>
                                    <Button
                                        label={"Calculate"}
                                        containerClass={cx(['ms-auto mt-3 mt-md-0'])}
                                        onClickHandler={this.onCalculateProfitReportClicked}
                                        actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                                    />
                                </div>
                            </div>
                            {profitLossContentContainer}
                        </>


                }

            }

        //Account Level P&A Report Start
        if (isAccountLevelAPILoading) {
            accountLevelDataContainer = <Loader />
        } else {
            let grandTotal = accountLevelData.profit_summary[0].GrandTotal
            // let overallCostPrice = accountLevelData.profit_summary[0].CostPrice
            // let overallSellingPrice = accountLevelData.profit_summary[0].SellingPrice
            let profitPercent = grandTotal - (thresholdValue * noOfMonth)
            let profitLossText = (profitPercent > 0) ? "Overall profit" : "Overall loss"
            let accountLevelContentContainer = null

            if (isAccountLevelAPIError) {
                accountLevelDataContainer = <ErrorPage />
            }
            else {

                if (isEmptyList(accountLevelData.profit_table)) {
                    accountLevelContentContainer = <><NoData mainText={'No Data Available'} subText={''} />
                        
                    </>
                }
                else {
                    accountLevelContentContainer = (
                        <SortingTable
                            enableExports={true}
                            exportTableName={["Superadmin","PL Report Accounts"].join(SEPARATORS.UNDERSCORE)}
                            exportKeysList={
                                [
                                    {
                                        key : "AccountUUID",
                                        label : "Account ID"
                                    },
                                    {
                                        key : "AccountName",
                                        label : "Account Name"
                                    },
                                    {
                                        key : "isActive",
                                        label : "Status",
                                        getValue : (item) => {
                                            if(Number(item.isActive) == USER_MANAGEMENT.USER_STATUS.INACTIVE){
                                                return "Expired"
                                            } else if(Number(item.isActive) == USER_MANAGEMENT.USER_STATUS.UNSUBSCRIBED){
                                                return "Closed"
                                            } else {
                                                return "Active"
                                            }
                                        }
                                    },
                                    {
                                        key : "TransactionAmount",
                                        label : "Transaction Amount (USD)",
                                        getValue : (item) => (item.TransactionAmount).toFixed(2)
                                    }
                                ]
                            }
                            addExportRows={
                                [
                                    [EMPTY_STRING,EMPTY_STRING,"Total",(grandTotal).toFixed(2)],
                                    [EMPTY_STRING,EMPTY_STRING,"Fixed Monthly Cost (USD)",Number(accountLevelReportInputCreditValue).toFixed(2)],
                                    [EMPTY_STRING,EMPTY_STRING,profitLossText + " (USD)",(Math.abs(profitPercent)).toFixed(2)],
                                ]
                            }
                            headingList={
                                [
                                    {
                                        key : "AccountUUID",
                                        label : "Account ID",
                                        isDate : false
                                    },
                                    {
                                        key : "AccountName",
                                        label : "Account Name",
                                        isDate : false
                                    },
                                    {
                                        key : "isActive",
                                        label : "Status",
                                        isDate : false,
                                        renderItem : (item) => {
                                            if(Number(item.isActive) == USER_MANAGEMENT.USER_STATUS.INACTIVE){
                                                return <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-expired)" />&nbsp;Expired</span>
                                            } else {
                                                return <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-active)" />&nbsp;Active</span>
                                            }
                                        }
                                    },
                                    {
                                        key : "TransactionAmount",
                                        label : "Transaction Amount (USD)",
                                        isDate : false,
                                        alignment : SORT_TABLE_VALUES.ALIGNMENTS.RIGHT,
                                        renderItem : (item) => getWesternNumber((item.TransactionAmount).toFixed(2))
                                    }
                                ]
                            }
                            containerClass={"mt-3"}
                            dataList={accountLevelData?.profit_table}
                            itemsToShow={accountLevelData?.profit_table.length}
                            indexed = {false}
                            additionalRows={
                                <>
                                    <tr>
                                        <td colSpan={3} className='text-end'>
                                            <b>Total</b>
                                        </td>
                                        <td className='text-end'>{getWesternNumber((grandTotal).toFixed(2))}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className='text-end'>
                                            <div><b>Fixed Monthly Cost (USD)</b></div>
                                        </td>
                                        <td className='text-end' >{getWesternNumber(Number(accountLevelReportInputCreditValue).toFixed(2))}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className='text-end'>
                                            <div>
                                                <p className='mb-1'><b>{profitLossText + " (USD)"}</b></p>
                                                <div className={globalClasses.Font12Grey}><i>Total - (Fixed Monthly Cost * Number of Months)</i></div>
                                            </div>
                                        </td>
                                        <td className='text-end'>{getWesternNumber((Math.abs(profitPercent)).toFixed(2))}</td>
                                    </tr>
                                </>
                            }
                        />
                    )
                }

                    accountLevelDataContainer =

                        <>
                        <div className='row'>
                                <div className='col-12 col-md-4 px-1 px-md-3 mb-3'>
                                    <div className="form-group col-12">

                                        <label
                                            className={cx([globalClasses.FormLabel])}
                                            htmlFor="accountLevelReportInputCreditValue">
                                            {'Fixed monthly cost (USD)'}
                                        </label>
                                        <input
                                            className={cx(["form-control", globalClasses.FormInput,
                                            isErrorAccountLevelReportInputCreditValue ? globalClasses.ErrorFormInput : ''
                                            ])}
                                            type='number'
                                            min='1'
                                            id="accountLevelReportInputCreditValue"
                                            value={Number(accountLevelReportInputCreditValue)}
                                            onChange={(event) => this.onChangeAccountLevelDefaultValue(event.target.value)}
                                        />
                                        <span className={globalClasses.ErrorText}>{isErrorProfitValueText}</span> 

                                    </div>
                                </div>
                                <div className='col-12 col-md-4 px-1 px-md-3 mb-3'>
                                    <div className="form-group col-12">
                                    <label
                                        className={cx([globalClasses.FormLabel])}
                                    // htmlFor="profitReportInputCreditValue"
                                    >
                                        {'Select Date Range'}
                                    </label><br/>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={profitReportStartDate}
                                        onChange={this.onDatePickerChangeHandler}
                                        // onCalendarClose={this.onDatePickerSelectHandler}
                                        startDate={profitReportStartDate}
                                        endDate={profitReportEndDate}
                                        selectsRange
                                        className={cx(['form-control', isErrorInDatePicker ?
                                            globalClasses.ErrorFormInput : ""])}
                                        maxDate={new Date()}
                                    // inline
                                    />
                                    {isErrorInDatePicker && <span className={globalClasses.ErrorText}>{'Choose start and end date'}</span>}

                                    </div>
                                </div>

                                <div className='col-12 col-md-4 col-lg-2 px-1 px-md-3 mt-4'>
                                    <Button
                                        label={"Calculate"}
                                        containerClass={cx(['ms-auto mt-3 mt-md-0'])}
                                        onClickHandler={this.onCalculateAccountLevelReportClicked} 
                                        actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                                    />
                                </div>
                            </div>
                            {accountLevelContentContainer}
                        </>


                }

            }
        //Account Level P&A Report End

        transactionsContainerComponent = (
            <TransactionsPane
                accountID={0}
                tableName={"Superadmin"}
                isActiveTab={this.state.activeTab === TAB_IDS.TRANSACTION}
                isNotAccountBasedTransaction={false}
            />
        )

        return (
            <>
                <div className='container-fluid px-0'>
                    <div className={classes.Banner}>
                        <h5 className={accountNameStyle}>Super Admin</h5>
                    </div>
                    <Tabs
                        activeKey={activeTab}
                        transition={false}
                        id="tabs"
                        className={classes.Tabs}
                        onSelect={this.onTabSelectHandler}
                    >
                        <Tab tabClassName={activeTab === TAB_IDS.ACCOUNTS ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.ACCOUNTS} title={USER_MNGMENT_STRINGS.ACCOUNT_SECTION.TAB_1}>
                            <div className="container-fluid px-md-5 my-5">
                                {/* {buttonAndFilterComponent} */}
                                {accountsContainer}
                                {accountCreationEditPopupComponent}
                            </div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.MODEL_ADMINISTRATION ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.MODEL_ADMINISTRATION} title={USER_MNGMENT_STRINGS.ACCOUNT_SECTION.TAB_5}>
                            <div className={"container-fluid px-0 mt-5"}>
                                <ModelAdministration
                                    apiURL={MODEL_ADMINISTATION}
                                    userRole={this.props.role}
                                    userDetails={this.props.userDetails}
                                    isActive={activeTab === TAB_IDS.MODEL_ADMINISTRATION}
                                    accountUUID ={this.props.accountDetail?.accountUUID}
                                    tableName={"Superadmin"}
                                />
                            </div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.SUPPORT ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.SUPPORT} title={USER_MNGMENT_STRINGS.ACCOUNT_SECTION.TAB_2}>
                            <div className="container-fluid px-md-5 mt-5">
                                {isSupportEditable &&
                                    <AccountCreationPopup
                                        title={"Update Comment"}
                                        onCloseHandler={this.onClickSupportEditCancel}
                                        onSubmitHandler={this.onClickSupportEditDone}
                                        isSubmitting={isSupportUpdating}
                                        submitButtonText={"Update"}
                                    >
                                        <div className="d-flex flex-wrap">
                                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="firstname">
                                                {"Support ID"}
                                            </label>
                                            <div className="col-12 col-lg-8 p-2">
                                                {supportEditableData.supportId}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="firstname">
                                                {"Account ID"}
                                            </label>
                                            <div className="col-12 col-lg-8 p-2">
                                                {supportEditableData.AccountUUID}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="firstname">
                                                {"Category"}
                                            </label>
                                            <div className="col-12 col-lg-8 p-2">
                                                {supportEditableData.category}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="lastname">
                                                {"Comment"}
                                            </label>
                                            <div className="col-12 col-lg-8 mt-2">
                                                <TextAreaField
                                                    containerClass={'col-12'}
                                                    value={supportEditableData.approverComment}
                                                    placeHolder={'Type your comments here...'}
                                                    onChangeHandler={this.onChangeSupportComment}
                                                    isDisabled={isSupportUpdating}
                                                    isFieldError={editableCommentError}
                                                    isReadOnly={isSupportUpdating}
                                                    id={'CommentText'}
                                                    isError={isSubmitError}
                                                    validate={isValidMessageString}
                                                    isMandatory={true}
                                                    rowLength={3}
                                                    maxCharacter={5000}
                                                    minCharacter={10}
                                                />
                                            </div>
                                        </div>
                                    </AccountCreationPopup>
                                }
                                <SupportRequests
                                    supportRequestsData={supportRequestsData}
                                    isSupportRequestsAPIError={isSupportRequestsAPIError}
                                    isSupportRequestsAPILoading={isSupportRequestsAPILoading}
                                    onClickEditComment={this.onClickSupportEditComment}
                                    onClickDone={this.onClickSupportEditDone}
                                    onClickCancel={this.onClickSupportEditCancel}
                                    onChangeComment={this.onChangeSupportComment}
                                    editabeSupportData={supportEditableData}
                                    editableCommentError={editableCommentError}
                                    isSubmitError={isSubmitError}
                                    tableName={"Superadmin"}
                                />
                            </div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.USER_INVITES ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.USER_INVITES} title={USER_MNGMENT_STRINGS.ACCOUNT_SECTION.TAB_3}>
                            <div className="container-fluid px-md-5 mt-5">

                                <UserInvites
                                    userInvitesData={userInvitesData}
                                    isUserInvitesAPIError={isUserInvitesAPIError}
                                    isUserInvitesAPILoading={isUserInvitesAPILoading}
                                />
                            </div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.PROFIT_LOSS_REPORT ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.PROFIT_LOSS_REPORT} title={USER_MNGMENT_STRINGS.ACCOUNT_SECTION.TAB_4}>
                            <div className="container-fluid px-md-5 mt-5">
                                {reportTypeContainer}
                                {this.state.searchValue === 1 ?  accountLevelDataContainer : ""}
                                {this.state.searchValue === 2 ? profitLossDataContainer : ""} 
                            </div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.TRANSACTION ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.TRANSACTION} title={USER_MNGMENT_STRINGS.ACCOUNT_SECTION.TAB_6}>
                            <div className="container-fluid px-md-5 mt-5">

                                {transactionsContainerComponent}

                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </>
        )
    }
}
export default AccountManagement;