import React from 'react'
import IdleTimer from 'react-idle-timer';
import {IdleTimeOutModal} from './SessionTimeOutModal'
import UserPool from '../../containers/sign_up/UserPool';
// import './App.css'

class SessionTimeOut extends React.Component {
    constructor(props){
        super(props)
        this.interval = null;
        this.state = {
            timeout:1000 * 60 * process.env.REACT_APP_SESSION_TIME_OUT_IN_MINUTES,
            // timeout:1000 * 20 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            setIsLoading: false,
            remainingTime: 60
            // remainingTime: 20
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    _onAction(e) {
      this.setState({isTimedOut: false})
    }
   
    _onActive(e) {
      console.log('user is active', e)
      this.setState({isTimedOut: false})
      console.log('time remaining', this.idleTimer.getRemainingTime())
    }
   
    _onIdle(e) {
      console.log('user is idle', e)
      const isTimedOut = this.state.isTimedOut
      if (isTimedOut) {
        //   this.props.history.push('/')
        console.log(isTimedOut)
      } else {
        this.setState({showModal: true})
        this.idleTimer.reset();
        this.setState({isTimedOut: true})
        
        this.interval = setInterval(() => { 
          if(this.state.remainingTime > 0){
            this.setState({remainingTime: this.state.remainingTime-1 })
          }
          else{
            clearInterval(this.interval);
            this._onSignout();
          }
        }, 1000)
      }
      console.log('last active', this.idleTimer.getLastActiveTime())
    }

    _onRefresh = () => {
      clearInterval(this.interval);
      this.setState({
        remainingTime: 60, showModal: false, isTimedOut: false
      })
    }

    _onSignout = () => {
      let user = UserPool.getCurrentUser()
      if(user){
          user.signOut()
          window.location.reload()
      }

    }

    // _onClose(e) {
      // console.log('user is closing modal', e)
      // const isTimedOut = this.state.isTimedOut
      // if (isTimedOut) {
      //   //   this.props.history.push('/')
      //   console.log(isTimedOut)
      // } else {
      //   this.setState({showModal: true})
      //   this.idleTimer.reset();
      //   this.setState({isTimedOut: true})
      // }
    // }

    render(){
      return(
        <>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
            <IdleTimeOutModal showModal={this.state.showModal} handleRefresh={this._onRefresh} handleLogout={this._onSignout}
            remainingTime={this.state.remainingTime}/>
        </>
      )
   }

 }

 export default SessionTimeOut