import classes from './FeedbackButton.module.css'

import FeedbackIcon from '../../../assets/images/feedback.png'

function FeedbackButton(props) {
    let { onClickHandler } = props;
    return (
        <div className={classes.Container}>
            <div className={classes.Wrapper} onClick={onClickHandler}>
                <span className={classes.FloatText}>Feedback</span>
                <div className={classes.Icon}>
                    <img
                        height="100%" src={FeedbackIcon} alt="feedback icon" width='24' />
                </div>
            </div>
        </div>
    )
}

export default FeedbackButton;