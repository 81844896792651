import { Dropdown } from "react-bootstrap";
import classes from "./NavBar.module.css";
import { FaAngleDown } from "react-icons/fa";
import { useState } from "react";

function DropNavItem(props) {
    let { label, dropItems } = props;
    let [showMenuList,setShowMenuList] = useState(false)
    let dropdownMenuComponent = (
        <>
            <div className="d-flex flex-column d-lg-none">
                <div className={classes.NavDropDown} onClick={() => setShowMenuList(!showMenuList)}>
                    <label>{label}</label> <FaAngleDown size={14} style={{marginBottom:"3px"}}/>
                </div>
                {
                    showMenuList &&
                    <div>
                        {
                            dropItems.map((item,index) => {
                                return (
                                    <a href={item.routePath} target={"_blank"} rel="noopener noreferrer" style={{textDecoration : "none"}}>
                                        <div key={index} className={classes.NavDropDownMenuItem}>
                                            <div className="row align-items-start">
                                                <div className="col-2">
                                                    <img src={item.icon} alt={item.label} width={"24px"} height={"24px"} />
                                                </div>
                                                <div className="col-10 d-inline-flex flex-column">
                                                    <h3>{item.label}</h3>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    
                                )
                            })
                        }
                    </div>
                }
            </div>
            <Dropdown className="d-none d-lg-block">
                <Dropdown.Toggle className={classes.NavDropDown} variant="success" id="dropdown-basic">
                {label}
                </Dropdown.Toggle>
        
                <Dropdown.Menu className={classes.NavDropDownMenu}>
                    {
                        dropItems.map((item,index) => {
                            return (
                                <Dropdown.Item key={index} className={classes.NavDropDownMenuItem} href={item.routePath}>
                                    <div className="row align-items-start">
                                        <div className="col-2">
                                            <img src={item.icon} alt={item.label} width={"24px"} height={"24px"} />
                                        </div>
                                        <div className="col-10 d-inline-flex flex-column">
                                            <h3>{item.label}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
    return dropdownMenuComponent;
  }

  export default DropNavItem;