export const REQUEST_TYPE = Object.freeze({
    GET: 'get',
    POST: 'post'
})

export const REQUEST_RESPONSE_OBJECT_KEYS = Object.freeze({
    PARAMS: 'params',
    FORMAT: 'format',
    RESPONSE: 'response',
    STATUS: 'status'
})

export const CONFIG_CONSTANTS = Object.freeze({
    TIMEOUT: 60000,
    APPLICATION_TYPE: {
        JSON: 'json'
    }
}) 

export const ACCESS_TOKEN_PREFIX = "Bearer "
