import globalClasses from '../../App.module.css';
import cx from 'classnames';
import classes from './NetworkIssue.module.css';

import { CONNECTION_ISSUE } from "../../strings/Strings";

function NetworkIssue() {
    return(
        <div 
            className={cx(["d-flex flex-column align-items-center",classes.Container])}
        >
            <img className={classes.Image} src={window[Image].src} alt={'NetworkIssueImage.png'}/>
            <span className={cx(globalClasses.Font36BoldGrey44,classes.HeadingText)}>
                {CONNECTION_ISSUE.NETWORKCONNECTIVITYISSUES}
            </span>
            <span className={cx([globalClasses.Font18Grey44,classes.SubText])}>
                {CONNECTION_ISSUE.NETWORKCONNECTIVITYISSUESTEXT}
            </span>
        </div>
    )
}
export default NetworkIssue;