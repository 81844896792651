import APPROVE_ICON from '../../assets/images/user-manage/approve-icon.png';

import cx from 'classnames';
import classes from './InlineForm.module.css';
import globalClasses from '../../App.module.css';

import { BUTTON_TEXTS } from "../../strings/Strings";
import Button from "../ui_elements/button/ButtonNew";
import { CREDIT_PER_USD, INPUT_TYPES, MIN_TOPUP_AMOUNT, SELECT_OPTIONS_LIST } from "../../utils/Constants";
import TextAreaInput from "../ui_elements/form_fields/teaxt_area_input/TextAreaInput";
import { getWesternNumber, isEmpty } from "../../utils/UtilityFunctions";
import SelectField from "../ui_elements/form_fields/select_input/SelectField";
import Loader from '../loader/Loader';
import TopupField from '../ui_elements/form_fields/topup_input/TopupField';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';

function InlineForm(props) {
    let {containerClass, value, placeHolder, onChangeHandler, onBlurHandler,
        isError, errorText, validate, noteText, onClickSubmit, onClickCancel, isSubmitting, isSuccess,
        inputType, addtionalComponent, label, id, isFieldError, isSubmitError, minValue,
        maxValue, onDecreaseHandler, onIncreaseHandler, primaryButtonText, primaryButtonClass } = props;

    let actionButtonComponent = null;
    let inputComponent = null;
    if(isSubmitting){
        actionButtonComponent = (
            <Loader
                containerClass = {globalClasses.FullPageLoader}
            />
        )
    } else {
        actionButtonComponent = (
            <div className="row justify-content-end">
                <Button
                    containerClass="col-4 col-md-4 col-lg-3" 
                    label={BUTTON_TEXTS.CANCEL} 
                    onClickHandler={onClickCancel} 
                    actionType={BUTTON_TYPES.ACTIONS.SECONDARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                />
                <Button 
                    containerClass={cx("col-4 col-md-4 col-lg-3")} 
                    label={isEmpty(primaryButtonText)? BUTTON_TEXTS.SUBMIT : primaryButtonText} 
                    onClickHandler={onClickSubmit} 
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                />
            </div>
        )
    }
    if(isSuccess){
        return(
            <div className={cx(['col-12 my-2 d-inline-flex align-items-center',classes.AdditionText])}>
                <img className={classes.SuccessIcon} src={APPROVE_ICON} alt={'Success'}/>
                <span>{'Thank you, your request has been submitted successfully.'}</span>
            </div>
        )
    }
    if(inputType === INPUT_TYPES.TEXT_AREA){
        inputComponent = (
            <TextAreaInput
                rowLength = {3}
                containerClass={'col-12'} 
                value= {value}
                placeHolder={placeHolder} 
                onChangeHandler={onChangeHandler} 
                onBlurHandler={onBlurHandler}
                isReadOnly={isSubmitting} 
                isError={isError} 
                errorText={errorText} 
                validate={validate}
            />
        )
    } else if(inputType === INPUT_TYPES.TOPUP) {
        inputComponent = (
            <TopupField
                containerClass= {'col-12'} 
                value={value}
                placeHolder={placeHolder} 
                onChangeHandler={onChangeHandler} 
                isDisabled={isSubmitting} 
                isFieldError={isFieldError}
                isReadOnly={isSubmitting} 
                label={label} 
                id={id} 
                isError={isSubmitError} 
                validate={validate} 
                isMandatory={false} 
                isNumber={true}
                minValue={minValue} 
                maxValue={maxValue}
                onDecreaseHandler={onDecreaseHandler}
                onIncreaseHandler={onIncreaseHandler}
            />
        )
    } else {
        inputComponent = (
            <div className="row">
                <SelectField
                    containerClass={'col-4'}
                    value={value} 
                    onChangeHandler={onChangeHandler} 
                    isDisabled = {isSubmitting}
                    isReadOnly={isSubmitting} 
                    id={'topup'}
                    optionsList={SELECT_OPTIONS_LIST.SUBSCRIPTION_TOP_UP_UNITS}
                />
                <span className={cx(["col-8 mt-2",globalClasses.Font16Grey,globalClasses.FontRobotoMedium])}>= {getWesternNumber(value*MIN_TOPUP_AMOUNT*CREDIT_PER_USD)} credits</span>
            </div>
        )
    }
    return(
        <div className={containerClass}>
            <span className={classes.NoteText}>
                {noteText}
            </span>
            <div className={cx(["row"])}>
                <div className="col-12">
                    {inputComponent}
                </div>
                <div className='col-12'>
                    {addtionalComponent}
                </div>
                <div className="col-12 mt-3">
                    {actionButtonComponent}
                </div>
            </div>
        </div>
        
    )
}

export default InlineForm;