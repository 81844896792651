// modules
import axios from "axios";
import { Component } from "react";
import Button from "../../../components/ui_elements/button/ButtonNew";
import FileField from "../../../components/ui_elements/form_fields/file_input/FileField";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField";
import TextAreaField from "../../../components/ui_elements/form_fields/teaxt_area_input/TextAreaField";
import TextField from "../../../components/ui_elements/form_fields/text_input/TextField";
import { BUTTON_TEXTS, EMPTY_STRING, SEPARATORS, TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../../strings/Strings";
import { GET_MODELS, GET_SUPPORT_REQUESTS, RAISE_SUPPORT_TICKET } from "../../../urls/Urls";
import { SELECT_OPTIONS_LIST, USER_ROLE } from "../../../utils/Constants";
import { isEmptyList, isValidFile, isValidMessageString, isValidNameString } from "../../../utils/UtilityFunctions";
import { handleErrorSupportData, handleSuccessSupportData, INITIAL_SUPPORT_PANE_STATE } from "../UserManagement.utils";
import cx from 'classnames';
import globalClasses from '../../../App.module.css';
import { isEmpty } from "underscore";
import SuccessScreen from "../../../components/success_screen/SuccessScreen";
import Loader from "../../../components/loader/Loader";
import { QUERY_STRINGS } from "../../../strings/UserManagement";
import SupportRequests from "../account_management/support_requests/SupportRequests";
import { getCognitoUserAccessToken } from "../../sign_up/Cognito.utils";
import { toast } from 'react-toastify';
import { BUTTON_TYPES } from "../../../utils/ButtonConstants";
class SupportPane extends Component{
    state = {
        ...INITIAL_SUPPORT_PANE_STATE
    }

    getModelNames = () => {
        axios.get(GET_MODELS+'?role=1')
            .then(res => {
                let modelNames = [];
                modelNames = [...new Set(res.data.modelNames.map(item => item.Model_Name))]
                this.setState({
                    modelNames
                })
            })
            .catch(err => console.log(err.message))
    }

    getUserData = () => {
        let { userDetails } = this.props;
        let email = userDetails.email;
        let name = userDetails.displayName;
        let categories = SELECT_OPTIONS_LIST.AVAILABLE_CATEGORIES;
        let category = categories[0];
        return {email,name,categories,category}
    }

    getSupportTicketsData = () => {
        let {accountID} = this.props;
        this.setState({
            isLoadingSupportData : true
        }, () => {
            let url = GET_SUPPORT_REQUESTS
            + QUERY_STRINGS.SUPPORT_TICKETS_FOR_ACCCOUNTS + accountID ;
            axios.get(url, {
                headers:{
                    Authorization: "Bearer "+getCognitoUserAccessToken()
                }
            })
                .then(res => {
                    console.log("respo support", res)
                    this.setState({
                        ...handleSuccessSupportData(res.data)
                    })
                })
                .catch(err => {
                    console.log("error support", err.message)
                    this.setState({
                        ...handleErrorSupportData()
                    })
                })
        })
    }

    componentDidMount = () => {
        let { isActiveTab } = this.props;
        this.setState({
            ...this.getUserData()
        }, () => {
            this.getModelNames()
            if(isActiveTab){
                this.getSupportTicketsData();
            }
        })
    }

    componentDidUpdate = (prevProps,prevState) => {
        if(prevProps.isActiveTab !== this.props.isActiveTab){
            let { isActiveTab } = this.props; 
            if(isActiveTab){
                this.getSupportTicketsData();
            }
        }
    }

    onChangeName = (name) => {
        this.setState({
            name,
            nmeError : false
        })
    }

    onChangeCategory = (category) => {
        let showModelNames = false
        let model = EMPTY_STRING
        if(category === 'Model Specific'){
            let {modelNames} = this.state;
            showModelNames = true
            model = modelNames[0]
        }
        this.setState({
            category,
            showModelNames,
            model
        })
    }

    onChangeModel = (model) => {
        this.setState({
            model
        })
    }

    onChangeComments = (comments) => {
        this.setState({
            comments,
            commentsError : false
        })
    }

    onChangeFile = (snapshotFile,snapshotFileSize,snapshotFileType,snapshotFileName) => {
        this.setState({
            snapshotFile,snapshotFileSize,snapshotFileType,snapshotFileName,
            snapshotFileError : false
        })
    }

    onSubmitHandler = () => {
        let { email, name, category, model, comments, snapshotFile, snapshotFileName, snapshotFileSize,
            snapshotFileType } = this.state;
        let {accountID} = this.props;
        let nameError = isValidNameString(name,true).error;
        let commentsError = isValidMessageString(comments,1500,50,true).error;
        let snapshotFileError = isValidFile(snapshotFileName,snapshotFileSize,snapshotFileType,5*1024*1024,['.png','.jpeg','.jpg','.pdf'],false).error;
        if(!commentsError && !snapshotFileError){
            let url = RAISE_SUPPORT_TICKET 
            + QUERY_STRINGS.SUPPORT_TICKET_ROLE + USER_ROLE.SUBSCRIBED_ADMIN.ID ;
            let data = {
                communication_email : email,
                category,
                attachment_url : isEmpty(snapshotFile)? "" : snapshotFile.split(SEPARATORS.COMMA)[1],
                query : isEmpty(model) ? comments : [model,comments].join(';'),
                account_id : accountID,
                attachment_filename : snapshotFileName.replace(/\s/g,'-')
            }
            this.setState({
                isSubmitting : true,
                isSubmitError : false
            }, () => {
                console.log(data);
                axios.post(url,data,
                    {
                        headers:{
                            Authorization: "Bearer "+getCognitoUserAccessToken()
                        }
                    })
                    .then((res) => {
                        console.log('Support ticket',res.data);
                        this.setState({
                            // isSubmissionSuccess : true, //TODO:REMOVE
                            isSubmitting : false
                        })

                        //toaster
                        toast(TOASTER_MESSAGE.SUPPORT.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });
                        
                        this.onClickDoneHandler()
                    })
                    .catch((err) => {
                        this.setState({
                            isSubmissionSuccess : false,
                            isSubmitting : false
                        })

                        //toaster
                        toast(TOASTER_MESSAGE.SUPPORT.ERROR,
                            {
                                type: TOASTER_TYPE.ERROR,
                                theme: TOASTER_THEME.LIGHT
                            });
                    })
            })
        } else {
            this.setState({
                nameError, commentsError, snapshotFileError,
                isSubmitError : true
            })
        }
    }

    onClickDoneHandler = () => {
        this.setState({
            ...INITIAL_SUPPORT_PANE_STATE,
            ...this.getSupportTicketsData()
        })
    }

    onClickSupportButtonHandler = () => {
        let {showSupportTicketForm} = this.state;
        this.setState({
            showSupportTicketForm : !showSupportTicketForm
        }, () => {
            if(this.state.showSupportTicketForm){
                this.setState({
                    ...this.getUserData()
                })
            }
        })
    }

    render(){
       let { email, category, comments, commentsError,
        snapshotFileError, isSubmitting, isSubmitError, isSubmissionSuccess,
        categories, snapshotFileName, snapshotFileSize, snapshotFileType, 
        modelNames, model, showModelNames, isErrorSupportData, isLoadingSupportData,
        supportTicketData, showSupportTicketForm } = this.state;
        let { accountUUID } = this.props;

        let buttonGroupComponent = null;
        let raiseAndViewSupportTicketButton = null;
        let supportComponent = null;
        if(showSupportTicketForm){
            if(isSubmissionSuccess){
                supportComponent = (
                    <SuccessScreen
                        onClickHandler={this.onClickDoneHandler} 
                        mainText={"Thank you"} 
                        subText={"Your request has been raised, you will hear from us within 2 business days."} 
                        buttonText={BUTTON_TEXTS.DONE}
                    />
                )
            } else {
                if(isSubmitting){
                    buttonGroupComponent = (
                        <Loader
                            containerClass={globalClasses.FullPageLoader}
                        />
                    )
                } else {
                    buttonGroupComponent = (
                        <div className="col-12 mt-4 text-center">
                            <Button
                                containerClass={"mx-auto col-12 col-sm-6 col-md-4"}
                                label={BUTTON_TEXTS.SUBMIT}
                                onClickHandler={this.onSubmitHandler}
                                actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                                borderType={BUTTON_TYPES.BORDER.BORDERED}
                            />
                        </div>
                    )
                }
        
                supportComponent =  (
                    <div>
                        <div className="card mx-auto p-3 p-md-4 p-lg-5 mt-3 mb-5 col-11 col-md-10 col-lg-8">
                            <TextField
                                containerClass='col-12'
                                value={email}
                                isDisabled={true}
                                isReadOnly={true} 
                                label={'Email ID'} 
                                id={'mail'}
                                isMandatory={true}
                            />
                            <SelectField
                                containerClass='col-12'
                                value={category}
                                isDisabled={isSubmitting}
                                isReadOnly={isSubmitting}
                                id={'category'}
                                optionsList={categories}
                                isMandatory = {true}
                                label={'How can we help you?'}
                                onChangeHandler={this.onChangeCategory}
                            />
                            {
                                showModelNames && 
                                !isEmptyList(modelNames) && 
                                <SelectField
                                    containerClass='col-12'
                                    value={model}
                                    isDisabled={isSubmitting}
                                    isReadOnly={isSubmitting}
                                    id={'models'}
                                    optionsList={modelNames}
                                    isMandatory = {true}
                                    label={'Model Name'}
                                    onChangeHandler={this.onChangeModel}
                                />
                            }
                            <TextAreaField
                                containerClass='col-12'
                                value={comments}
                                isDisabled={isSubmitting}
                                isReadOnly={isSubmitting}
                                id={'comments'}
                                isError={isSubmitError}
                                isFieldError={commentsError}
                                isMandatory = {true}
                                placeHolder={'Give more information on the issue'}
                                label={'Comments'}
                                onChangeHandler={this.onChangeComments}
                                validate={isValidMessageString}
                                rowLength={3}
                                minCharacter={50}
                                maxCharacter={1500}
                            />
                            <FileField
                                containerClass={'col-12'}
                                placeHolder={'Upload Screenshot'}
                                onChangeHandler={this.onChangeFile} 
                                isDisabled={isSubmitting} 
                                isFieldError={snapshotFileError}
                                isReadOnly={isSubmitting} 
                                label={'Supporting files (if any)'}
                                id={'snapshot'} 
                                isError={isSubmitError} 
                                validate={isValidFile} 
                                isMandatory={false} 
                                allowedTypes={['.png','.jpeg','.jpg','.pdf']}
                                fileSize={snapshotFileSize} 
                                fileType={snapshotFileType} 
                                maxSize={5}
                                fileName={snapshotFileName}
                                supportText={'PDF, PNG, JPG and JPEG files within 5 MB are accepted'}
                            />
                            {buttonGroupComponent}
                        </div>
                    </div>
                )   
            }
        } else {
            supportComponent = (
                <SupportRequests
                    supportRequestsData={supportTicketData}
                    isSupportRequestsAPIError={isErrorSupportData}
                    isSupportRequestsAPILoading={isLoadingSupportData}
                    isAccountTypeRequest={true}
                    tableName={accountUUID}
                />
            )
        }

        if(this.props.userRole !== USER_ROLE.SUBSCRIBED.LABEL && this.props.userRole !== USER_ROLE.SUPER_ADMIN.LABEL){
            raiseAndViewSupportTicketButton = (
                <Button
                    containerClass={"ms-auto col-12 col-sm-6 col-md-4 col-lg-2"}
                    label={showSupportTicketForm? "View Requests" : "Raise Support Ticket"}
                    onClickHandler={this.onClickSupportButtonHandler}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                />
            )
        }

        return (
            <div className="col-12">
                { raiseAndViewSupportTicketButton }
                <div className="mt-3">
                    { supportComponent }
                </div>
            </div>
        )
    }
}

export default SupportPane;