import globalClasses from '../../../../App.module.css';
import { THEME } from '../../../../utils/Constants';
import { isEmpty } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'

function CheckBoxInput(props) {
    let {containerClass, onChangeHandler, isDisabled,
        isReadOnly, label, id, isChecked, inputClassStyle,labelStyle} = props;

    let formFieldContainerClass = isEmpty(containerClass)? "form-group col-12 col-md-6 px-1 px-md-3 my-3" 
                                    : containerClass;

    let labelClass = cx(['form-check-label ms-2',labelStyle || globalClasses.Font16DarkGrey,
    isDisabled? globalClasses.DisblePointer : globalClasses.PointerCursor])
    
    return(
        <div className={formFieldContainerClass}>
            <input
                className={cx(['form-check-input', globalClasses.FormInputCheckBoxBlue, inputClassStyle])}
                type='checkbox'
                onChange={onChangeHandler}
                disabled={isDisabled}
                checked={isChecked}
                readOnly={isReadOnly}
                id={id}
            />
            {
                !isEmpty(label) &&
                <label className={labelClass} htmlFor={id}>
                    {label}
                </label>
            }
        </div>
    )
}

export default CheckBoxInput;