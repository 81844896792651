export const BUTTON_TYPES = {
    ACTIONS : {
        PRIMARY : 1,
        SECONDARY : 2,
        TERTIARY : 3,
        LINK : 4,
        REGULAR : 5,
        ERROR : 6
    },
    RADIUS : {
        PILL : 1,
        ROUND_RECTANGLE : 2,
    },
    BORDER : {
        BORDERED : 1,
        NO_BORDER : 2
    }
}