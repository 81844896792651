import { Component } from "react";

import globalClasses from '../../App.module.css'
import NavBar from "../../components/navbar/NavBar";
import NetworkIssue from '../../components/network_issue/NetworkIssue';
import NetWorkIssueImage from '../../assets/images/network.png';

import FeedbackButton from "../../components/ui_elements/feedback_button/FeedbackButton";
import Popup from "../../components/ui_elements/popup/Popup";
import ModalCard from "../../components/ui_elements/modal_card/ModalCard";
import FeedbackScreen from "../../components/feedback/FeedbackScreen";
import Footer from "../../components/footer/Footer";

class NoFooterLayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            isDisconnected: false,
            showFeedbackScreen: false,
            shouldBlockNavigation: false,
            value: ''
        }
    }

    handleWindowClose() {
        alert("Alerted Browser Close");
    }
    componentDidMount = () => {
        let image = new Image();
        image.src = NetWorkIssueImage
        window[Image] = image;
        this.handleNetworkConnection();
        window.addEventListener('online', this.handleNetworkConnection);
        window.addEventListener('offline', this.handleNetworkConnection);
    }

    componentWillUnmount = () => {
        window.removeEventListener('online', this.handleNetworkConnection);
        window.removeEventListener('offline', this.handleNetworkConnection);
    }

    handleNetworkConnection = () => {
        let online = navigator.onLine;
        if (online) {
            console.log('online');
            this.setState({
                isDisconnected: false
            })
        } else {
            console.log('offline');
            this.setState({
                isDisconnected: true
            })
        }
    }
    showAndHideFeedbackScreen = () => {
        this.setState({
            showFeedbackScreen: !this.state.showFeedbackScreen
        })
    }
    render() {
        let { isDisconnected, showFeedbackScreen } = this.state;
        let { accountDetail, userDetails, role} = this.props;

        let feedbackButtonComponent = null;
        let feedbackComponent = null;

        // if(!isSubscribedUser){
            feedbackButtonComponent = (
                <FeedbackButton
                    onClickHandler={this.showAndHideFeedbackScreen}
                />
            )
            if (showFeedbackScreen) {
                feedbackComponent = (
                    <Popup className={globalClasses.PopupBack}>
                        <ModalCard isForm={true} isPopupFixed={true} onClose={this.showAndHideFeedbackScreen}>
                            <FeedbackScreen
                                isEmailMandatory={false}
                                closeFeedbackScreen={this.showAndHideFeedbackScreen}
                                isAuthenticated={this.props.isAuthenticated}
                                userDetails={userDetails}
                                accountDetail={accountDetail}
                                userRole={role}
                            />
                        </ModalCard>
                    </Popup>
                )
            }
        // }

        let bodyContent = isDisconnected ?
            <NetworkIssue /> :
            <>
                {feedbackButtonComponent}
                {feedbackComponent}
                {this.props.children}
            </>
        return (
            <>
                <NavBar history={this.props.history} userDetails={userDetails} {...this.props}/>
                <div className={globalClasses.BodyContent}>{bodyContent}</div>
                <Footer />
            </>
        )
    }
}

export default NoFooterLayout;