import React, { Component } from "react";
import {RxExternalLink} from 'react-icons/rx'

import cx from 'classnames'
import classes from './SignUp.module.css';
import globalClasses from '../../App.module.css';
import DUMMY_USER from '../../assets/images/empty-profile.png';
import UserPool from "./UserPool";
import { ROUTE_CONSTANTS } from "../../routes/RouteConstants";
import { AuditLog } from "../../../src/utils/AuditUtils";
import { isEmptyObject, getUTCTime } from "../../../src/utils/UtilityFunctions"
import { EMPTY_STRING, EVENT, LEAF_PRODUCTS, USER_ROLE } from "../../utils/Constants";
import ModalCard from "../../components/ui_elements/modal_card/ModalCard";
import { IMAGES } from "../../utils/ImageConstants";
import { getLabelText } from "../../utils/UtilityFunctions";
import InfoToolTip from "../../components/ui_elements/info_tool_tip/InfoToolTip";

class ProfileContent extends Component {

    onLogoutClicked = () => {
        let {userDetails, accountDetail} = this.props
        let user = UserPool.getCurrentUser()

        //#region audit log
        let startDateTime = new Date()

        //audit log - common properties
        let eventDetails = EVENT.AUTHENTICATION_CATEGORY.LOGOUT
        eventDetails = {...eventDetails, 
            eventCategory: EVENT.AUTHENTICATION_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime,
            eventStatusCode: '200',
            eventStatus: 'Success',
            eventMessage: 'Logout'
        }
       
        let accountDetails = (!isEmptyObject(accountDetail)) ? accountDetail : {}
        let userDetail = (!isEmptyObject(userDetails)) ? userDetails : {}
        let eventAttributes = {}

        if (userDetails) {
            eventAttributes = {
                awsId: userDetail?.awsId,
                email: userDetail?.email,
            }
        }

        AuditLog(eventDetails, eventAttributes, accountDetails)

        //#endregion

        if(user){
            user.signOut()
            let cuurentUrl = window.location.href.split('?')[0];
            window.location.href = cuurentUrl
        }
    }

    gotoCognitiveSearchApp = () => {
        window.open(process.env.REACT_APP_COGNITIVE_SEARCH_LINK,"_blank")
    }

    onClickMyProfileHandler = () => {
        this.props.history.push(ROUTE_CONSTANTS.MY_PROFILE)
    }

    onClickMyFavouritesHandler = () => {
        const {userDetails, isSubscribed, productID} = this.props;
        if(productID===LEAF_PRODUCTS.LEAF_IDP){
            let baseRoute = ROUTE_CONSTANTS.LEAF_IDP.FAVORITEMODEL
            window.location.replace(baseRoute)
        } else {
            let baseRoute = isSubscribed ? ROUTE_CONSTANTS.DEV_STUDIO : ROUTE_CONSTANTS.MODEL_PLAYGROUND
            window.location.replace(baseRoute +'?group=favorites')
        }
    }

    render() {
        const {userDetails, isSubscribed, productID} = this.props

        const name = userDetails?.firstName+" "+ userDetails?.lastName
        const mail = userDetails?.email
        const role = isSubscribed? userDetails?.role : EMPTY_STRING

        let profilePicture = DUMMY_USER

        if(userDetails?.picture && !userDetails.picture.startsWith('user')){
            profilePicture = userDetails?.picture
        }

        let { showDrop, onClickHandler, className, refer } = this.props;
        let toolTipComponent = (
            <span style={{whiteSpace:"pre",fontSize:"12px"}}>{name+"\n"+mail}</span>
        )

        return (
            <>
                <div ref={refer} className={cx(["position-relative", className])}>
                    <InfoToolTip textPlacement={"bottom"} text={toolTipComponent} hideDelay={10}>
                        <img
                            style={{ height: '32px', width: '32px', borderRadius: '50%', marginRight: '5px', cursor:'pointer' }}
                            id='preview'
                            src={profilePicture}
                            alt='profile-pic'
                            onClick={onClickHandler}
                        />
                    </InfoToolTip>
                    {
                        showDrop &&
                        <ModalCard className={cx(["d-flex flex-column",classes.ProfileDopDown])}>
                            <span className="d-flex align-items-center mb-3">
                                <img
                                    className="d-none d-md-block"
                                    style={{ height: '54px', width: '54px', borderRadius: '50%', marginRight: '10px'}}
                                    id='preview'
                                    src={profilePicture}
                                    alt='profile-pic'
                                />
                                <span className={'d-flex align-items-center px-2 px-md-0'}>
                                    <span className="d-inline-flex flex-column">
                                        <span
                                            style={{ fontFamily: 'Roboto-Medium', maxWidth:"200px"}}>
                                            {name}
                                        </span>
                                        <span
                                            style={{ maxWidth:"200px" , fontSize: '14px', textOverflow: 'ellipsis', overflow:'hidden' }}>
                                            {mail}
                                        </span>
                                        <span style={{ fontSize: '10px', fontFamily: "Roboto-Medium", color: "var(--color-blue-grey)" }}>{isSubscribed && "ROLE : "+getLabelText(role).toUpperCase()}</span>
                                        {/* <span style={{ fontSize: '10px', fontFamily: "Roboto-Medium", color: "var(--color-blue-grey)" }}>{isSubscribed && "ACCOUNT ID : "+getLabelText(role).toUpperCase()}</span> */}
                                    </span>
                                </span>
                            </span>
                            <div className={cx(['my-2',globalClasses.HorizontalLine])}/>
                            <a
                                className={cx(['py-2',classes.ProfileValues])}
                                href={ROUTE_CONSTANTS.MY_PROFILE}
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={IMAGES.PROFILE_DROP.PROFILE_ICON_DB}
                                    alt='my-profile'
                                    width={"18px"}
                                    height={"18px"}
                                />{'My Profile'}
                            </a>
                            {/* {
                                (productID!==LEAF_PRODUCTS.LEAF_IDP) &&
                                <span
                                    className={cx(['py-2',classes.ProfileValues])}
                                    onClick={() => this.onClickMyFavouritesHandler()}
                                >
                                    <img
                                        src={IMAGES.PROFILE_DROP.FAVORITE_ICON_DB}
                                        alt='my-favorites'
                                        width={"18px"}
                                        height={"18px"}
                                    />{'My Favorites'}
                                </span>
                            } */}
                            {
                                (productID===LEAF_PRODUCTS.LEAF_IDP) &&
                                <a
                                    className={cx(['py-2',classes.ProfileValues])}
                                    href={role===USER_ROLE.SUPER_ADMIN.LABEL? ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT : ROUTE_CONSTANTS.USER_MANAGEMENT}
                                    rel="noopener noreferrer"
                                    target={"_blank"}
                                >
                                    <img
                                        src={IMAGES.PROFILE_DROP.MANAGE_ICON_DB}
                                        alt='manage'
                                        width={"18px"}
                                        height={"18px"}
                                    />{'Manage'}
                                </a>
                            }
                            
                            {
                                (productID===LEAF_PRODUCTS.LEAF_IDP) &&
                                <a
                                    className={cx(['py-2',classes.ProfileValues])}
                                    href={process.env.REACT_APP_API_DOCUMENTATION_URL}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src={IMAGES.PROFILE_DROP.DOCUMENTATION_ICON_DB}
                                        alt='documentation'
                                        width={"18px"}
                                        height={"18px"}
                                    />{'Documentation'}
                                </a>
                            }
                            <span
                                className={cx(['py-2',classes.ProfileValues])}
                                onClick={() => this.onLogoutClicked()}
                            >
                                <img
                                    src={IMAGES.PROFILE_DROP.LOGOUT_ICON_DB}
                                    alt='log-out-user'
                                    width={"18px"}
                                    height={"18px"}
                                />{'Logout'}
                            </span>
                        </ModalCard>
                    }
                </div>
            </>
        )
    }
}
export default ProfileContent;