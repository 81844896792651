import { Component } from 'react';
import cx from 'classnames';
import { getLabelText, isEmpty, isEmptyList, isEmptyObject, isValidSecret, isValidTextString } from '../../../utils/UtilityFunctions';
import Loader from '../../../components/loader/Loader';
import ErrorPage from '../../../components/error_page/ErrorPage';
import NoData from '../../../components/no_data/NoData';
import { CONFIGURATOR_BUTTONS, getConfiguratorsDataHandler, handleConfiguratorsError, INITIAL_CONFIGURATORS_PANE_STATE, TAB_IDS } from '../UserManagement.utils';
import { LLM_SECRETS } from '../../../urls/Urls';
import axios from '../../../axios/Axios'
import SortingTable from '../../../components/sorting_table/SortingTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCognitoUserAccessToken } from '../../sign_up/Cognito.utils';
import { BUTTON_ICON_POSITION, EMPTY_STRING, EVENT, EXPIRY_DAY, SORT_TABLE_VALUES, TEXTAREA_LENGTH, THEME, USER_MANAGEMENT, USER_ROLE } from '../../../utils/Constants';
import { BUTTON_TEXTS, HTTP_METHODS, SEPARATORS, TOASTER_THEME, TOASTER_TYPE } from '../../../strings/Strings';
import { getTableTimeData } from '../../../utils/UIUtilityFunctions';
import { USER_MNGMENT_STRINGS } from '../../../strings/UserManagement';

// classes
import classes from '../UserManagement.module.css';
import globalClasses from '../../../App.module.css'

// images
import AccountCreationPopup from '../AccountCreatePopup';
import SelectField from '../../../components/ui_elements/form_fields/select_input/SelectField';
import TextField from '../../../components/ui_elements/form_fields/text_input/TextField';
import ConfirmPopup from '../../../components/confirm_popup/ConfirmPopup';
import Card from '../../../components/ui_elements/card/Card';
import CheckBoxInput from '../../../components/ui_elements/form_fields/checkbox_input/CheckBoxInput';
import { AuditLog } from '../../../utils/AuditUtils';
import { SECRETS_PROVIDERS } from '../../../utils/SecretContants';
import ResponsiveButton from '../../../components/ui_elements/button/ButtonNew';
import { BUTTON_TYPES } from '../../../utils/ButtonConstants';
import ConfiguratorCard from '../../../components/configurator_card/ConfiguratorCard';

class ConfiguratorsPane extends Component {

    state = {
        ...INITIAL_CONFIGURATORS_PANE_STATE
    }

    setSelectedConfiguratorType(index) {
        let selectedType = index
        this.setState({
            selectedType
        })
    }

    componentDidMount() {
        let { isActiveTab } = this.props;
        if (isActiveTab) {
            this.getConfiguratorsData()
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.isActiveTab !== this.props.isActiveTab) {
            let { isActiveTab } = this.props;
            if (isActiveTab) {
                this.getConfiguratorsData();
            }
        }
    }


    getConfiguratorsData = () => {
        let { userRole, accountUUID } = this.props;

        if (document.body.style.overflow !== "auto") {
            document.body.style.overflow = "auto"
        }

        let url = LLM_SECRETS
        if (userRole === USER_ROLE.SUPER_ADMIN.LABEL) {
            url = url + "?account_id=" + accountUUID
        }

        axios.get(url, {
            headers: {
                Authorization: "Bearer " + getCognitoUserAccessToken()
            }
        }
        )
            .then((response) => {
                this.setState({
                    ...getConfiguratorsDataHandler(response)
                })
            }).catch((err) => {
                this.setState({
                    ...handleConfiguratorsError(err)
                })
            })
    }

    onClickAddSceretHandler = (item) => {
        const { availableConfigurators } = this.state;
        if (document.body.style.overflow !== "hidden") {
            document.body.style.overflow = "hidden"
        }
        let secretValue = JSON.parse(JSON.stringify(this.getSecretValueData(item.value, "keyValue")))
        this.setState({
            validateKey: true,
            configuratorFormData: {
                secretName: EMPTY_STRING,
                secretType: item.value,
                secretValue,
                useKey: true
            },
            deleteConfigurator: EMPTY_STRING,
            showCreateEditPopup: true,
            isConfiguratorEditable: false,
            showConfirmDeleteSecretPopup: false
        })
    }

    getSecretValueData = (secretType, key = EMPTY_STRING) => {
        let secretProviders = JSON.parse(JSON.stringify(SECRETS_PROVIDERS))
        let secretValueIndex = secretProviders.findIndex(secretProvider => secretProvider.value === secretType)
        if (secretValueIndex >= 0) {
            if (isEmpty(key)) {
                return secretProviders[secretValueIndex]
            } else {
                return secretProviders[secretValueIndex][key]
            }
        }
        return EMPTY_STRING
    }

    onClickEditSceretHandler = (item) => {
        if (document.body.style.overflow !== "hidden") {
            document.body.style.overflow = "hidden"
        }
        let secretValue = JSON.parse(JSON.stringify(this.getSecretValueData(item.secretType, "keyValue")))
        this.setState({
            configuratorFormData: {
                secretName: item.secretName,
                secretType: item.secretType,
                secretValue,
                useKey: item.useKey
            },
            validateKey: true,
            deleteConfigurator: EMPTY_STRING,
            showCreateEditPopup: true,
            isConfiguratorEditable: true,
            showConfirmDeleteSecretPopup: false
        })
    }

    onClickDeleteSceretHandler = (item) => {
        if (document.body.style.overflow !== "hidden") {
            document.body.style.overflow = "hidden"
        }
        this.setState({
            configuratorFormData: {},
            deleteConfigurator: item.secretName,
            showCreateEditPopup: false,
            isConfiguratorEditable: false,
            showConfirmDeleteSecretPopup: true
        })
    }

    //audit log
    insertToAuditLog = (eventDetails, additionalDetails) => {
        let { userDetails, accountUUID } = this.props
        let accountDetails = {}
        let eventAttributes = {}
        let user = {}
        if (accountUUID) {
            accountDetails = { accountUUID: accountUUID }
        }

        if (!isEmptyObject(userDetails)) {
            user = {
                awsId: userDetails?.awsId,
                email: userDetails?.email,
            }
        }

        eventAttributes = { ...user, ...additionalDetails }

        AuditLog(eventDetails, eventAttributes, accountDetails)
    }

    getAuditDetails = (eventType, eventData) => {
        //#region - audit log
        let startDateTime = new Date()

        // audit log - common properties
        let eventDetails = {
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }

        if (eventType === "add") {
            eventDetails = {
                ...eventDetails,
                ...EVENT.MANAGE_CATEGORY.ADD_CONFIGURATION
            }
        } else if (eventType === "update") {
            eventDetails = {
                ...eventDetails,
                ...EVENT.MANAGE_CATEGORY.UPDATE_CONFIGURATION
            }
        } else if (eventType === "delete") {
            eventDetails = {
                ...eventDetails,
                ...EVENT.MANAGE_CATEGORY.DELETE_CONFIGURATION
            }
        } else {
            eventDetails = {
                ...eventDetails,
                ...EVENT.MANAGE_CATEGORY.VALIDATE_CONFIGURATION
            }
        }

        let additionalDetails = {}
        if (eventData) {
            additionalDetails = {
                secretName: eventData?.secret_name,
                secretType: eventData?.secret_type,
                useKey: eventData?.use_key
            }
        }

        return { eventDetails, additionalDetails }

    }

    onConfirmDeleteHandler = () => {
        const { deleteConfigurator, configuratorsData } = this.state;
        this.setState({
            isDeleting: true
        }, () => {
            let url = LLM_SECRETS + "?secret_name=" + deleteConfigurator
            let auditDetails = this.getAuditDetails("delete", { "secret_name": deleteConfigurator })
            axios.delete(url, {
                headers: {
                    Authorization: "Bearer " + getCognitoUserAccessToken()
                }
            })
                .then((response) => {
                    let responseMessage = response.data.message
                    auditDetails["eventDetails"] = {
                        ...auditDetails["eventDetails"],
                        eventStatusCode: 200,
                        eventStatus: "Success",
                        eventMessage: responseMessage
                    }
                    this.setState({
                        ...INITIAL_CONFIGURATORS_PANE_STATE,
                        configuratorsData
                    }, this.getConfiguratorsData)
                })
                .catch((err) => {
                    let responseMessage = err?.response?.data?.message || "Error deleting the configuration."
                    auditDetails["eventDetails"] = {
                        ...auditDetails["eventDetails"],
                        eventStatusCode: err?.response?.data?.status_code || err?.response?.status,
                        eventStatus: err?.response?.statusText,
                        eventMessage: responseMessage
                    }
                    this.setState({
                        isDeleting: false
                    })
                })
                .finally(() => {
                    toast(auditDetails["eventDetails"]?.eventMessage,
                        {
                            type: auditDetails["eventDetails"]?.eventStatusCode === 200 ? TOASTER_TYPE.SUCCESS : TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });
                    this.insertToAuditLog(auditDetails["eventDetails"], auditDetails["additionalDetails"])
                })
        })
    }

    onClickCancelHandler = () => {
        let { configuratorsData, availableConfigurators, selectedType } = this.state;

        if (document.body.style.overflow !== "auto") {
            document.body.style.overflow = "auto"
        }

        this.setState({
            ...INITIAL_CONFIGURATORS_PANE_STATE, availableConfigurators,
            configuratorsData, isConfiguratorsLoading: false, selectedType
        })
    }

    isMandatoryCheck = (value, index) => {
        const { configuratorFormData } = this.state;
        if (configuratorFormData.secretType === 19) {
            let valueEnteredList = value.filter((valueItem => !isEmpty(valueItem.AZURE_DEPLOYMENT)));
            if (isEmptyList(valueEnteredList)) {
                return true
            } else {
                let valueItem = Object.assign({}, value[index]);
                let valueEnterenedIndex = valueEnteredList.findIndex(valueEnteredItem => valueEnteredItem.MODEL === valueItem.MODEL);
                return ((valueEnterenedIndex >= 0) ? true : false)
            }
        }
        return true;
    }

    getSecretsValueErrors = () => {
        const { configuratorFormData } = this.state;
        let isSecretValuesError = {};
        configuratorFormData.secretValue.map((secrets, index) => {
            if (secrets.type === "text") {
                let secretValueError = isValidTextString(secrets["value"], true).error
                if (secretValueError) {
                    isSecretValuesError[secrets["key"]] = secretValueError
                }
            }
            if (secrets.type === "list") {
                let errorFields = []
                let errorFieldCounts = 0;
                secrets["value"].map((secretsListItem, listItemIndex) => {
                    let errorValues = {}
                    Object.keys(secretsListItem).map((secretsListItemKey, listKeyIdex) => {
                        let isMandatory = secrets?.isMandatoryCheck ? this.isMandatoryCheck(secrets["value"], listItemIndex) : true
                        // let isMandatory = true
                        let secretValueError = isValidTextString(secretsListItem[secretsListItemKey], isMandatory).error
                        if (secretValueError) {
                            errorFieldCounts++
                            errorValues[secretsListItemKey] = secretValueError
                        }
                        return listKeyIdex
                    })
                    errorFields.push(errorValues)
                    return listItemIndex
                })
                if (errorFieldCounts > 0) {
                    isSecretValuesError[secrets["key"]] = Object.assign([], errorFields)
                }
            }
            return index
        })
        return isSecretValuesError
    }

    getActualSecretValues = () => {
        const { configuratorFormData } = this.state;
        let actualSecretValues = {};
        configuratorFormData.secretValue.map((secretValue, index) => {
            if (secretValue.type === "text") {
                actualSecretValues[secretValue.key] = secretValue.value
            }
            if (secretValue.type === "list") {
                let newSecretValue = JSON.parse(JSON.stringify(secretValue.value))
                if (configuratorFormData.secretType === 19) {
                    newSecretValue.map((values, vIndex) => {
                        values["MODEL"] = values["MODEL"].toLowerCase();
                        return vIndex;
                    })
                    newSecretValue = newSecretValue.filter((values, vIndex, valueList) => this.isMandatoryCheck(valueList, vIndex))
                }
                actualSecretValues[secretValue.key] = JSON.parse(JSON.stringify(newSecretValue))
            }
            return index
        })
        return actualSecretValues
    }

    onConfirmCreateUpdateHandler = () => {
        const { configuratorsData, configuratorFormData, isConfiguratorEditable, keyValidated, isKeyValid } = this.state;
        let validate = !(keyValidated && isKeyValid)
        let isSecretValuesError = this.getSecretsValueErrors()
        let isSecretNameError = this.isValidUniqueSecretName(configuratorFormData.secretName, true).error

        if (!isSecretNameError && isEmptyObject(isSecretValuesError)) {

            this.setState({
                isSubmitting: true
            }, () => {
                let url = LLM_SECRETS
                let method = (isConfiguratorEditable && !validate) ? HTTP_METHODS.PUT : HTTP_METHODS.POST
                let requestData = {}
                let responseMessage = EMPTY_STRING
                requestData["secret_name"] = configuratorFormData["secretName"]
                requestData["secret_type"] = this.getSecretValueData(configuratorFormData["secretType"], "providerValue")
                requestData["secret_value"] = this.getActualSecretValues()
                requestData["use_key"] = configuratorFormData["useKey"] ? 1 : 0
                if (validate) {
                    requestData["test"] = 1
                }
                let auditDetails = this.getAuditDetails(validate ? "test" : isConfiguratorEditable ? "update" : "add", requestData)
                axios({
                    url, method,
                    data: requestData,
                    headers: {
                        Authorization: "Bearer " + getCognitoUserAccessToken()
                    }
                })
                    .then((response) => {
                        responseMessage = response.data.message
                        auditDetails["eventDetails"] = {
                            ...auditDetails["eventDetails"],
                            eventStatusCode: 200,
                            eventStatus: "Success",
                            eventMessage: responseMessage
                        }
                        if (validate) {
                            this.setState({
                                keyValidated: true,
                                isKeyValid: true,
                                isSubmitting: false
                            })
                        } else {
                            this.setState({
                                ...INITIAL_CONFIGURATORS_PANE_STATE,
                                configuratorsData, keyValidated: false,
                                isConfiguratorFormError: false
                            }, this.getConfiguratorsData)
                        }

                    })
                    .catch((err) => {
                        let responseMessage = err?.response?.data?.message
                        auditDetails["eventDetails"] = {
                            ...auditDetails["eventDetails"],
                            eventStatusCode: err?.response?.data?.status_code || err?.response?.status,
                            eventStatus: err?.response?.statusText,
                            eventMessage: responseMessage
                        }
                        if (validate) {
                            this.setState({
                                keyValidated: true,
                                isKeyValid: false,
                                isSubmitting: false
                            })
                        } else {

                            this.setState({
                                isSubmitting: false
                            })
                        }
                    })
                    .finally(() => {
                        toast(auditDetails["eventDetails"]?.eventMessage,
                            {
                                type: auditDetails["eventDetails"]?.eventStatusCode === 200 ? TOASTER_TYPE.SUCCESS : TOASTER_TYPE.ERROR,
                                theme: TOASTER_THEME.LIGHT
                            });
                        this.insertToAuditLog(auditDetails["eventDetails"], auditDetails["additionalDetails"])
                    })

            })
        } else {
            this.setState({
                isConfiguratorFormError: true,
                isSecretNameError, isSecretValuesError
            })
        }

    }

    onChangeSecretName = (secretName) => {
        let { configuratorFormData } = this.state;
        let newConfiguratorFormData = JSON.parse(JSON.stringify(configuratorFormData));
        newConfiguratorFormData["secretName"] = secretName
        this.setState({
            configuratorFormData: newConfiguratorFormData,
            isSecretNameError: false
        })
    }

    onChangeSecretType = (secretType) => {
        let { configuratorFormData } = this.state;
        let newConfiguratorFormData = JSON.parse(JSON.stringify(configuratorFormData));
        newConfiguratorFormData["secretType"] = secretType
        newConfiguratorFormData["secretValue"] = this.getSecretValueData(secretType, "keyValue")
        this.setState({
            configuratorFormData: newConfiguratorFormData,
            isKeyValid: false, keyValidated: false, isSecretValuesError: {}
        })
    }

    onChangeSecretValue = (secretValue, secretValueKey, secretValueKeyIndex, secretValueSubKey = EMPTY_STRING, secretValueSubIndex = null) => {
        let { configuratorFormData, isSecretValuesError } = this.state;
        let newConfiguratorFormData = JSON.parse(JSON.stringify(configuratorFormData));
        let newSecretValuesError = JSON.parse(JSON.stringify(isSecretValuesError));
        let newSecretValue = JSON.parse(JSON.stringify(newConfiguratorFormData?.secretValue));

        if (!isEmpty(secretValueSubIndex) || secretValueSubIndex === 0) {
            newSecretValuesError[secretValueKey] = []
            newSecretValue[secretValueKeyIndex]["value"][secretValueSubIndex][secretValueSubKey] = secretValue
        } else if (!isEmpty(secretValueSubKey)) {
            newSecretValuesError[secretValueKey] = {}
            newSecretValue[secretValueKeyIndex]["value"][secretValueSubKey] = secretValue
        } else {
            newSecretValuesError[secretValueKey] = false
            newSecretValue[secretValueKeyIndex]["value"] = secretValue
        }
        newConfiguratorFormData = {
            ...newConfiguratorFormData,
            secretValue: [
                ...newSecretValue
            ]
        }
        this.setState({
            configuratorFormData: newConfiguratorFormData,
            isSecretValuesError: newSecretValuesError,
            isKeyValid: false, keyValidated: false
        })
    }

    onChangeUseKey = () => {
        let { configuratorFormData } = this.state;
        let newConfiguratorFormData = JSON.parse(JSON.stringify(configuratorFormData));
        newConfiguratorFormData["useKey"] = !configuratorFormData.useKey
        this.setState({
            configuratorFormData: newConfiguratorFormData
        })
    }

    isValidUniqueSecretName = (value, isMandatory) => {
        let { configuratorsData, isConfiguratorEditable } = this.state;
        let validation = isValidSecret(value, TEXTAREA_LENGTH.SECRET_NAME.MINIMUM, TEXTAREA_LENGTH.SECRET_NAME.MAXIMUM, isMandatory)
        let error = validation.error;
        let text = validation.text;
        if (!error && !isConfiguratorEditable) {
            if (configuratorsData.findIndex(configs => configs.secretName === value) >= 0) {
                error = true;
                text = "Name already exists."
            }
        }
        return { error, text }
    }

    render() {

        let { configuratorsData, isConfiguratorsError, isConfiguratorsLoading, showCreateEditPopup, showConfirmDeleteSecretPopup,
            isConfiguratorEditable, isDeleting, isSubmitting, configuratorFormData, keyValidated, isKeyValid, availableConfigurators,
            isSecretNameError, isSecretValuesError, isConfiguratorFormError } = this.state
        let { accountIsActive, accountUUID, userRole, accountDetail } = this.props;

        let enableActions = (userRole === USER_ROLE.SUPER_ADMIN.LABEL) || ((accountDetail?.accountUUID === accountUUID) && (userRole !== USER_ROLE.SUBSCRIBED.LABEL))

        let configuratorsDataContainer = null;
        let infoComponent = null;

        if (showCreateEditPopup) {
            let secretProviderIndex = SECRETS_PROVIDERS.findIndex(secrets => secrets.value === configuratorFormData.secretType);
            if (secretProviderIndex >= 0 && SECRETS_PROVIDERS[secretProviderIndex].hasOwnProperty("info")) {
                infoComponent = (
                    <span className={globalClasses.Font12Grey}><b>NOTE : </b>{SECRETS_PROVIDERS[secretProviderIndex]["info"]}</span>
                )
            }

        }

        if (isConfiguratorsLoading) {
            configuratorsDataContainer = <Loader />
        }
        else {

            if (isConfiguratorsError) {
                configuratorsDataContainer = <ErrorPage />
            }
            else {
                let configuratorsDataComponent = null;
                let configuratorTypeButtonGroupComponent = null;
                let configuratorPopupComponent = null;
                let infoContainerComponent = null;
                // if (isEmptyList(availableConfigurators) && enableActions) {
                //     infoContainerComponent = (
                //         <Card className={globalClasses.Info}>
                //             Adding additional keys to this account is not an option as the quota for providers has been fully utilized.
                //         </Card>
                //     )
                // }
                infoContainerComponent = (
                    <div className="col-12 mb-3">
                        <Card className={cx(["col-12", globalClasses.Info])}>
                            {"Your keys will be stored securely, ensuring strict confidentiality and preventing unauthorized access."}
                            <a rel={"noopener noreferrer"} className={globalClasses.AnchorElement} target={"_blank"} href={process.env.REACT_APP_CONFIGURATOR_SECURITY_DOCUMENTATION_URL}>{"Click here to know more."}</a>
                            <p>{"To enable connectivity with Vivas.AI for your below provided services, you may need to add our IP address to your firewall's whitelist. The IP address is "}<b>{process.env.REACT_APP_VIVASAI_SERVICE_IP}</b></p>
                            <span style={{ display: "block", marginTop: "10px" }}>Don't have LLM keys? Reach out to us at <a href={"mailto:" + "ask@vivas.ai"} className={globalClasses.AnchorElement}>{"ask@vivas.ai"}</a> and our team will ensure you get the support you need.</span>
                        </Card>
                    </div>
                )
                if (showConfirmDeleteSecretPopup) {
                    configuratorPopupComponent = (
                        <ConfirmPopup
                            popupClass={globalClasses.PopupBack}
                            isSubmitting={isDeleting}
                            submittingText={USER_MNGMENT_STRINGS.DELETING}
                            modalClass={'col-11 col-md-8 col-lg-6 ps-3 ps-md-4'}
                            title={"Remove Configuration"}
                            subtext={"Do you wish to remove this configuration from your account? You can always add a new one later. In the meantime, Leaf Group models will be accessed using Vivas.AI's key."}
                            primaryButtonText={BUTTON_TEXTS.DELETE}
                            secondaryButtonText={BUTTON_TEXTS.CANCEL}
                            onClickPrimaryButton={this.onConfirmDeleteHandler}
                            onClickSecondaryButton={this.onClickCancelHandler}
                            isPopupFixed={true}
                            enableScroll={false}
                            onCloseModalCard={this.onClickCancelHandler}
                            theme={THEME.LIGHT_BLUE_SHADES}
                        />
                    )
                }
                if (showCreateEditPopup) {
                    configuratorPopupComponent = (
                        <AccountCreationPopup
                            title={isConfiguratorEditable ? "Update Configuration" : "Add New Configuration"}
                            onCloseHandler={this.onClickCancelHandler}
                            onSubmitHandler={this.onConfirmCreateUpdateHandler}
                            isSubmitting={isSubmitting}
                            submitButtonText={keyValidated && isKeyValid ? isConfiguratorEditable ? "Update" : "Add" : "Validate"}
                            submitButonWithIcon={false}
                            disableSubmitButton={false}
                            subHeadingComponent={!(keyValidated && isKeyValid) && <span>{"To guarantee that key possess the necessary permissions, a validation process will be conducted."}</span>}
                        >
                            <div className="d-flex flex-wrap">
                                <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="secretname">
                                    {'Name'}
                                </label>
                                <div className="col-12 col-lg-8">
                                    <TextField
                                        containerClass='col-12'
                                        value={configuratorFormData.secretName}
                                        id={'secretname'}
                                        placeHolder={'Type your configurator name here...'}
                                        onChangeHandler={this.onChangeSecretName}
                                        validate={this.isValidUniqueSecretName}
                                        isDisabled={isConfiguratorEditable || isSubmitting}
                                        isReadOnly={isConfiguratorEditable || isSubmitting}
                                        isError={isConfiguratorFormError}
                                        isFieldError={isSecretNameError}
                                        isMandatory={true}
                                        isNumber={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-wrap">
                                <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="provider">
                                    {'Provider'}
                                </label>
                                <div className="col-12 col-lg-8">
                                    <SelectField
                                        containerClass={'col-12'}
                                        value={configuratorFormData.secretType}
                                        onChangeHandler={this.onChangeSecretType}
                                        isDisabled={true}
                                        isReadOnly={true}
                                        id={"provider"}
                                        isMandatory={true}
                                        optionsList={isConfiguratorEditable ? SECRETS_PROVIDERS : availableConfigurators}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-wrap">
                                <label className={cx(['col-12 mt-2 mb-2'])}>
                                    {'Value(s)'}
                                    <br />
                                    {infoComponent}
                                </label>
                                <div className="col-12">
                                    {
                                        configuratorFormData.secretValue.map((configitem, index) => {
                                            return (
                                                <div key={index} className='row'>
                                                    <div className="col-4">
                                                        <TextField
                                                            containerClass='col-12'
                                                            value={configitem.key}
                                                            id={configitem.key}
                                                            isDisabled={true}
                                                            isReadOnly={true}
                                                            title={configitem.key}
                                                        />
                                                    </div>
                                                    {
                                                        (configitem.type === "text") &&
                                                        <div className="col-8">
                                                            <TextField
                                                                containerClass='col-12'
                                                                value={configitem.value}
                                                                id={'secretvalue'}
                                                                placeHolder={'Type your value here...'}
                                                                onChangeHandler={(value) => this.onChangeSecretValue(value, configitem.key, index)}
                                                                validate={isValidTextString}
                                                                isDisabled={isSubmitting}
                                                                isReadOnly={isSubmitting}
                                                                isError={isConfiguratorFormError}
                                                                isFieldError={isSecretValuesError?.[configitem.key] || false}
                                                                isMandatory={true}
                                                                isNumber={false}
                                                                autoComplete={"off"}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        (configitem.type === "list") &&
                                                        <div className="col-8">
                                                            {
                                                                configitem.value.map((conigitemvalues, ciKey) => {
                                                                    return (
                                                                        <div key={ciKey} className='row flex-row'>
                                                                            {
                                                                                Object.keys(conigitemvalues).map((conigitemvaluekey, civKey) => {
                                                                                    let className = 'col-' + String(configitem?.partition?.[conigitemvaluekey] || 12)
                                                                                    let isDefaultValue = configitem?.defaultKeys ? configitem?.defaultKeys.includes(conigitemvaluekey) : false

                                                                                    return (
                                                                                        <div key={civKey} className={className}>
                                                                                            {
                                                                                                isDefaultValue ?
                                                                                                    <TextField
                                                                                                        containerClass='col-12'
                                                                                                        value={conigitemvalues[conigitemvaluekey]}
                                                                                                        id={configitem.key}
                                                                                                        isDisabled={true}
                                                                                                        isReadOnly={true}
                                                                                                        title={conigitemvalues[conigitemvaluekey]}
                                                                                                    />
                                                                                                    :
                                                                                                    <TextField
                                                                                                        containerClass='col-12'
                                                                                                        value={conigitemvalues[conigitemvaluekey]}
                                                                                                        id={'secretvalue'}
                                                                                                        placeHolder={getLabelText(conigitemvaluekey)}
                                                                                                        onChangeHandler={(value) => this.onChangeSecretValue(value, configitem.key, index, conigitemvaluekey, ciKey)}
                                                                                                        validate={isValidTextString}
                                                                                                        isDisabled={isSubmitting}
                                                                                                        isReadOnly={isSubmitting}
                                                                                                        isError={isConfiguratorFormError}
                                                                                                        isFieldError={isSecretValuesError?.[configitem.key]?.[ciKey]?.[conigitemvaluekey] || false}
                                                                                                        // isMandatory={true} 
                                                                                                        isMandatory={configitem?.isMandatoryCheck ? this.isMandatoryCheck(configitem["value"], ciKey) : true}
                                                                                                        isNumber={false}
                                                                                                        autoComplete={"off"}
                                                                                                    />
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })

                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className={'d-flex flex-wrap mt-2'}>
                                <CheckBoxInput
                                    containerClass={"col-12"}
                                    onChangeHandler={this.onChangeUseKey}
                                    isDisabled={isSubmitting}
                                    id={"enablekey"}
                                    label={"Utilize this key for calling Leaf Group models."}
                                    isChecked={configuratorFormData.useKey}
                                />
                            </div>
                        </AccountCreationPopup>
                    )
                }


                configuratorTypeButtonGroupComponent = (
                    <div className="row flex-wrap">
                        {
                            CONFIGURATOR_BUTTONS.map((item, index) => (
                                <ResponsiveButton
                                    key={index}
                                    id={item.id}
                                    containerClass={"col-4 col-md-3 col-xl-2"}
                                    className={cx("col-12")}
                                    label={item.label}
                                    onClickHandler={() => this.setSelectedConfiguratorType(item.id)}
                                    actionType={this.state.selectedType === item.id ? BUTTON_TYPES.ACTIONS.PRIMARY : BUTTON_TYPES.ACTIONS.SECONDARY}
                                    radiusType={BUTTON_TYPES.RADIUS.PILL}
                                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                                />
                            ))
                        }
                    </div>
                );

                let filteredConfiguratorData = []
                filteredConfiguratorData = SECRETS_PROVIDERS.filter((provider) => provider.category.toLowerCase() === this.state.selectedType);



                if (isEmptyList(filteredConfiguratorData)) {
                    configuratorsDataComponent = <NoData mainText={'No Configurations Available'} subText={''} />
                } else {


                    filteredConfiguratorData = filteredConfiguratorData.map(filteredItem => {
                        const match = configuratorsData.find(configuratorItem =>
                            configuratorItem.secretType === filteredItem.value
                        );
                        return {
                            ...filteredItem,
                            keyPresent: match ? true : false,
                            secretName: match ? match.secretName : null,
                            secretType: match ? match.secretType : null,
                            useKey: match ? match.useKey : null
                        };
                    });

                    configuratorsDataComponent = (
                        <div className="row flex-wrap my-4">
                            {filteredConfiguratorData.map((filteredItem, index) => (
                                <ConfiguratorCard
                                    configurationContent={filteredItem}
                                    addConfigurationButton={() => this.onClickAddSceretHandler(filteredItem)}
                                    updateConfigurationButton={() => this.onClickEditSceretHandler(filteredItem)}
                                    deleteConfigurationButton={() => this.onClickDeleteSceretHandler(filteredItem)}
                                    canAddOrEdit={enableActions && (accountIsActive === USER_MANAGEMENT.USER_STATUS.ACTIVE)}
                                />
                            ))}
                        </div>





                    )
                }

                configuratorsDataContainer = (
                    <div className='col-12'>
                        {configuratorPopupComponent}
                        {infoContainerComponent}
                        {configuratorTypeButtonGroupComponent}
                        {configuratorsDataComponent}
                    </div>
                )
            }
        }
        return (
            <>
                {configuratorsDataContainer}
            </>
        )
    }
}


export default ConfiguratorsPane;
