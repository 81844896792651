import { useEffect, useState } from "react";

import COPY_DONE from '../../../assets/images/approve-ico.png'

function CopyButton(props) {
    let {imageSrc,className,onClickCopy,copyText,copiedText,containerClass} = props;
    let [copyButtonText,setCopyButtonText] = useState(copyText);
    let [copyIcon,setCopyIcon] = useState(imageSrc);

    useEffect(() => {
        if(copyButtonText === copiedText){
            setTimeout(() => {
                setCopyButtonText(copyText)
                setCopyIcon(imageSrc)
            },2000);
        }
    }, [copyButtonText, copiedText, imageSrc, copyText]);

    const onClickCopyButton = () => {
        if(copyButtonText !== copiedText){
            onClickCopy();
            setCopyButtonText(copiedText)
            setCopyIcon(COPY_DONE)
        }
    }

    return(
        <span className={containerClass} title={copyButtonText} onClick={onClickCopyButton}>
            <img
                className={className}
                src={copyIcon}
                alt={'Copy'}
            />
        </span>
    )
}

export default CopyButton;