import Carousel,  { consts } from 'react-elastic-carousel';
import ReactDOM from 'react-dom'

import classes from './CustomCarousel.module.css'
import cx from 'classnames';

import { isEmpty } from '../../../utils/UtilityFunctions';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useRef } from 'react';

function CustomCarousel(props) {

    let { className, pageDotClass, pageDotActiveClass,carouselBreakpoints, itemsToScroll, itemsToShow, enableArrows,
        enablePagination, enableAutoPlay, transitionDuration, focusOnSelect, reference,
        itemsPosition, itemsCount, enableSwipe, autoPlaySpeed, infiniteCarousel }=props;
    let itemPosition = consts.CENTER;
    let carouselRef = useRef();

    if(itemsPosition === 1){
        itemPosition = consts.START;
    }


    const renderCustomArrows = ({ type, onClick, isEdge }) => {
        let opacity = !isEdge? 1 : 0
        let pointerEvents = !isEdge? 'auto' : 'none'
        const pointer = type === consts.PREV ? 
        <MdKeyboardArrowLeft size={16} /> 
        : <MdKeyboardArrowRight size={16} />
        return (
            <span style={{pointerEvents,opacity,cursor:'pointer'}} className={classes.CarouselButtons} onClick={onClick}>
                {pointer}
            </span>
        )
      }

    const renderCustomPagination = ({ pages, activePage, onClick }) => {
        return (
            <div className={classes.Pagging}>
                {pages.map(page => {
                    const isActivePage = activePage === page
                    let activeClass = isActivePage ? pageDotActiveClass|| classes.PageActiveDots : null;
                    return (
                        <div
                            className={cx([pageDotClass || classes.PageDots, activeClass])}
                            onClick={() => onClick(page)}
                        >

                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <Carousel
            className={className}
            ref={ref => carouselRef = ref}
            breakPoints={carouselBreakpoints}
            itemsToShow={itemsToShow}
            showArrows={enableArrows}
            enableAutoPlay={enableAutoPlay}
            itemsToScroll={itemsToScroll}
            transitionMs={transitionDuration}
            focusOnSelect={focusOnSelect}
            autoPlaySpeed={autoPlaySpeed || 8000}
            pagination={enablePagination}
            renderArrow={renderCustomArrows}
            itemPosition={itemPosition}
            showEmptySlots={true}
            enableSwipe={enableSwipe}
            enableMouseSwipe={enableSwipe}
            onChange={(currentItem,pageIndex) => {
                // if(infiniteCarousel){
                //     console.log(pageIndex)
                //     let domNode = ReactDOM.findDOMNode(reference.current);
                //     if(!isEmpty(domNode)){
                //         const firstChild = domNode.firstElementChild;
                //         const clone = firstChild.cloneNode(true);
                //         domNode.appendChild(clone);
                //     }
                // }
                if(itemsCount > 0 && enableAutoPlay && carouselRef?.state && carouselRef.state?.pages && (currentItem.index === carouselRef.state?.pages.length-1)){
                    if(!isEmpty(carouselRef)){
                        setTimeout(() => {
                            if(!isEmpty(carouselRef?.goTo)){
                                carouselRef.goTo(0)
                            }
                        },(autoPlaySpeed || 8000))
                    }
                }
            }}
            renderPagination={renderCustomPagination}
        >
            {props.children}
        </Carousel>
    )
}

export default CustomCarousel;