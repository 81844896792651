import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "../ui_elements/button/ButtonNew";
import cx from 'classnames';
import globalClasses from '../../App.module.css';
import { SESSION_TIMEOUT_CONTENT } from '../../strings/SessionTimeOut';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';
export const IdleTimeOutModal = ({showModal, handleRefresh, handleLogout, remainingTime}) => {

    return (
        <Modal show={showModal}>
        <Modal.Header>
        <Modal.Title>{SESSION_TIMEOUT_CONTENT.HEADER}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {remainingTime > 0 ? SESSION_TIMEOUT_CONTENT.BODY_CONTENT_BEFORE_TIMEOUT_FIRST + `${remainingTime}` + SESSION_TIMEOUT_CONTENT.BODY_CONTENT_BEFORE_TIMEOUT_SECOND :
            SESSION_TIMEOUT_CONTENT.BODY_CONTENT_AFTER_TIMEOUT}
        </Modal.Body>
        
        
        { 
        remainingTime > 0 &&
            <Modal.Footer>
                <div className='row col-12 justify-content-end'>
                    <Button 
                        onClickHandler={handleLogout} 
                        label= {'Logout'} 
                        containerClass={cx(["col-6 col-md-4"])} 
                        className={"col-12"}
                        actionType={remainingTime > 0 ? BUTTON_TYPES.ACTIONS.SECONDARY : BUTTON_TYPES.ACTIONS.PRIMARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                    />
                    <Button 
                        onClickHandler={handleRefresh} 
                        label= {'Stay Logged In'} 
                        containerClass={cx(["col-6 col-md-4"])} 
                        className={"col-12"}
                        actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                    />
                </div>
            </Modal.Footer>
            
        } 
    </Modal>
    )
}