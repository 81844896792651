import { SELF_HOSTING_CONTENT_TYPES, SELF_HOSTING_CONTENT_VIEWS } from "./SelfHostingsInfo.utils";

export const SELF_HOSTING_INFOS = [
    {
        key : "prerequisites",
        label : "Prerequisites",
        contents : [
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                content : {
                    value : "The following prerequisites are required to run the container",
                    meta : []
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                content : {
                    value : "Check the Official Docker Installation Guide to run Docker-like container engine.",
                    meta : [
                        {
                            "type": "link",
                            "start": 10,
                            "text": "Official Docker Installation Guide",
                            "source": "https://docs.docker.com/engine/install/"
                        }
                    ]
                }   
            }
        ]
    },
    {
        key : "downloadContainerImage",
        label : "Download the Container Image",
        contents : [
            {
                type : SELF_HOSTING_CONTENT_TYPES.ORDERED,
                content : [
                    {
                        value : "You can obtain the model/augmented container image from the `registry.vivas.ai`.",
                        meta : []
                    },
                    {
                        value : "Before retrieving the image from the container registry, it's necessary to authenticate by providing account ID and licensee ID. Below is an example demonstrating how to use Docker to log into the container image registry.",
                        meta : [],
                        inner_contents : [
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                                content : {
                                    value : "docker login -u <Account ID> --password <Licensee ID> registry.vivas.ai",
                                    replace_meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                                content : {
                                    value : "Below is an example demonstrating how to use Docker to log into the container image registry for your account.",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                                views : [SELF_HOSTING_CONTENT_VIEWS.MANAGE],
                                content : {
                                    value : "docker login -u <Account ID> --password <Licensee ID> registry.vivas.ai",
                                    replace_meta : [
                                        {
                                            value : "<Account ID>",
                                            replacerKey : "accountUUID"
                                        },
                                        {
                                            value : "<Licensee ID>",
                                            replacerKey : "licenseeID"
                                        }
                                    ]
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                                views : [SELF_HOSTING_CONTENT_VIEWS.CORE_MODEL,SELF_HOSTING_CONTENT_VIEWS.AUGMENTED_MODEL],
                                content : {
                                    value : "docker login -u <Account ID> --password <Licensee ID> registry.vivas.ai",
                                    replace_meta : [
                                        {
                                            value : "<Account ID>",
                                            replacerKey : "accountUUID"
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        value : "After successfully logging in, you can use the following command to pull the core/augmented model image:",
                        meta : [],
                        views : [SELF_HOSTING_CONTENT_VIEWS.MANAGE],
                        inner_contents : [
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                                content : {
                                    value : "The following Docker command is used to pull the core model image:",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                                content : {
                                    value : "docker pull registry.vivas.ai/<model_code>:<version_number>",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE_INFO,
                                content : {
                                    value : "<model_code> : A unique identifier, specific to the model you wish to retrieve from the registry, can be located within the details of each model card in the respective model card section.",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE_INFO,
                                content : {
                                    value : "<version_number> : If a specific model version is provided, that version will be pulled. If no version is specified, the latest version will be pulled using the 'latest' image tag.",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                                content : {
                                    value : "The following Docker command is used to pull the augmented model image:",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                                content : {
                                    value : "docker pull registry.vivas.ai/<skill_id>",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE_INFO,
                                content : {
                                    value : "<skill_id> : An individual unique ID associated with each augmented model can be located within the respective augmented model's model card section.",
                                    meta : []
                                }
                            }
                        ]
                    },
                    {
                        value : "After successfully logging in, you can use the following command to pull the core model image:",
                        meta : [],
                        views : [SELF_HOSTING_CONTENT_VIEWS.CORE_MODEL],
                        inner_contents : [
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                                content : {
                                    value : "The following Docker command is used to pull the core model image:",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                                content : {
                                    value : "docker pull registry.vivas.ai/<model_code>:latest",
                                    replace_meta : [
                                        {
                                            value : "<model_code>",
                                            replacerKey : "modelCode",
                                            toLowerCase : true
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        value : "After successfully logging in, you can use the following command to pull the augmented model image:",
                        meta : [],
                        views : [SELF_HOSTING_CONTENT_VIEWS.AUGMENTED_MODEL],
                        inner_contents : [
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                                content : {
                                    value : "The following Docker command is used to pull the augmented model image:",
                                    meta : []
                                }
                            },
                            {
                                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                                content : {
                                    value : "docker pull registry.vivas.ai/<skill_id>",
                                    replace_meta : [
                                        {
                                            value : "<skill_id>",
                                            replacerKey : "skillID"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        key : "containerEnvironmentVariables",
        label : "Container Environment Variables",
        downloadViews : [SELF_HOSTING_CONTENT_VIEWS.CORE_MODEL,SELF_HOSTING_CONTENT_VIEWS.AUGMENTED_MODEL],
        downloadEnvData : {
            envKey : "variable_name",
            envValueKey : "default_value"
        },
        downloadContentKey : "environmentVariables",
        contents : [
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                views : [SELF_HOSTING_CONTENT_VIEWS.MANAGE],
                content : {
                    value : "The environment details for the respective model or augmented models can be found within their corresponding self-hosting sections.",
                    meta : []
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.TABLE,
                views : [SELF_HOSTING_CONTENT_VIEWS.CORE_MODEL,SELF_HOSTING_CONTENT_VIEWS.AUGMENTED_MODEL],
                contentKey : "environmentVariables",
                contentHeadingList : [
                    {
                        key : "variable_name",
                        label : "Variable Name",
                        isDate : false
                    },
                    {
                        key : "description",
                        label : "Description",
                        isDate : false
                    },
                    {
                        key : "default_value",
                        label : "Default Value",
                        isDate : false
                    },
                    {
                        key : "is_required",
                        label : "Required",
                        isDate : false
                    }
                ]
            }
        ]
    },
    {
        key : "runningContainer",
        label : "Running the Container",
        contents : [
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                content : {
                    value : "To facilitate the container's execution, it is essential to provide specific credentials and configuration parameters. These include the licensee ID, license key, account ID, and the model container environment variable. In the provided example, these variables are conveniently stored within a file.",
                    meta : []
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                views : [SELF_HOSTING_CONTENT_VIEWS.MANAGE,SELF_HOSTING_CONTENT_VIEWS.CORE_MODEL],
                content : {
                    value : "To run the core model container, execute the following command:",
                    meta : []
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                views : [SELF_HOSTING_CONTENT_VIEWS.MANAGE],
                content : {
                    value : "docker run --rm --env-file <env-file-name> -p 8000:8000 -t registry.vivas.ai/<model_code>",
                    meta : []
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                views : [SELF_HOSTING_CONTENT_VIEWS.CORE_MODEL],
                content : {
                    value : "docker run --rm --env-file <env-file-name> -p 8000:8000 -t registry.vivas.ai/<model_code>",
                    replace_meta : [
                        {
                            value : "<model_code>",
                            replacerKey : "modelCode",
                            toLowerCase : true
                        }
                    ]
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                views : [SELF_HOSTING_CONTENT_VIEWS.MANAGE,SELF_HOSTING_CONTENT_VIEWS.AUGMENTED_MODEL],
                content : {
                    value : "To run the augmented model container, execute the following command:",
                    meta : []
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                views : [SELF_HOSTING_CONTENT_VIEWS.MANAGE],
                content : {
                    value : "docker run --rm --env-file <env-file-name> -p 8000:8000 -t registry.vivas.ai/<skill_id>",
                    meta : []
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.CODE,
                views : [SELF_HOSTING_CONTENT_VIEWS.AUGMENTED_MODEL],
                content : {
                    value : "docker run --rm --env-file <env-file-name> -p 8000:8000 -t registry.vivas.ai/<skill_id>",
                    replace_meta : [
                        {
                            value : "<skill_id>",
                            replacerKey : "skillID"
                        }
                    ]
                }
            },
            {
                type : SELF_HOSTING_CONTENT_TYPES.CODE_INFO,
                content : {
                    value : "<env-file-name> : The file which contains the environment variables and is expected to be located within the same directory.",
                    meta : []
                }
            }
        ]
    },
    {
        key : "apiReference",
        label : "API Reference",
        contents : [
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                content : {
                    value : "The specific container API details can be accessed within the Vivas.AI documentation. By default, the endpoint for model prediction is set to /model.",
                    meta : [
                        {
                            "type": "link",
                            "start": 62,
                            "text": "Vivas.AI documentation",
                            "source": "https://vivasai.readme.io"
                        }
                    ]
                }
            }
        ]
    },
    {
        key : "authAndContainerDIagnosticReport",
        label : "Authentication and Container Diagnostic Report",
        contents : [
            {
                type : SELF_HOSTING_CONTENT_TYPES.PARA,
                content : {
                    value : "The container only communicates with Vivas.AI's servers for authentication purposes only.\nThese communications do not include any customer data. An authentication request occurs during the first API call after the container image is initiated and at the end of the subscription period.",
                    meta : []
                }
            }
        ]
    }
]