import cx from 'classnames';
import classes from './NoData.module.css';
import globalClasses from '../../App.module.css';

import { EMPTY_STRING, NODATA_FOUND } from "../../strings/Strings";
import NoResultImage from '../../assets/images/no_result_found.png';
import { isEmpty } from '../../utils/UtilityFunctions';
import ResponsiveButton from '../ui_elements/button/ButtonNew';
import { THEME } from '../../utils/Constants';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';

function NoData(props) {
    let { mainText, subText, image, buttonLabel, onClickHandler, theme, customNoDataImageClass, noImage } = props;
    let isBlueTheme = theme === THEME.LIGHT_BLUE_SHADES;
    mainText = (mainText || mainText === EMPTY_STRING) ? mainText : NODATA_FOUND.NORESULT;
    subText = (subText || subText === EMPTY_STRING) ? subText : NODATA_FOUND.NORESULTTEXT;
    let buttonComponent = null;
    if(!isEmpty(buttonLabel)){
        buttonComponent = (
            <ResponsiveButton
                id={"No-Data-Navigate-Button"} 
                label={buttonLabel} 
                containerClass={"mt-3"}
                className={"px-4"}
                actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                borderType={BUTTON_TYPES.BORDER.BORDERED} 
                onClickHandler={onClickHandler}
            />
        )
    }
    return (
        <div
            className={cx(["d-flex flex-column align-items-center", classes.Container])}
        >
            {!noImage && <img className={cx(isEmpty(image) ? classes.Image : classes.OwnImage, customNoDataImageClass)} src={isEmpty(image) ? NoResultImage : image} alt={'NoResultImage.png'} />}
            <span className={cx(globalClasses.Font36BoldGrey44, classes.HeadingText)}>
                {mainText}
            </span>
            <span className={cx([globalClasses.Font18Grey44, classes.SubText])}>
                {subText}
            </span>
            {
                buttonComponent
            }
        </div>
    )
}
export default NoData;