import classes from "./Button.module.css";
import { BUTTON_TYPES } from "../../../utils/ButtonConstants"

export const getActionClass = (action) => {
    switch(action){
        case BUTTON_TYPES.ACTIONS.PRIMARY : return classes.Primary;
        case BUTTON_TYPES.ACTIONS.SECONDARY : return classes.Secondary;
        case BUTTON_TYPES.ACTIONS.TERTIARY : return classes.Tertiary;
        case BUTTON_TYPES.ACTIONS.LINK : return classes.Link;
        case BUTTON_TYPES.ACTIONS.ERROR : return classes.Error;
        default: return classes.Regular
    }
}

export const getRadiusClass = (radius) => {
    switch(radius){
        case BUTTON_TYPES.RADIUS.PILL : return classes.Pill;
        case BUTTON_TYPES.RADIUS.ROUND_RECTANGLE : return classes.RoundedRectagle;
        default: return classes.Pill
    }
}

export const getBorderClass = (border) => {
    switch(border){
        case BUTTON_TYPES.BORDER.BORDERED : return classes.Bordered;
        case BUTTON_TYPES.BORDER.NO_BORDER : return classes.NoBorder;
        default: return classes.NoBorder
    }
}