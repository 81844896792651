export const NAVBAR_LABELS = Object.freeze({
    HOME : "Home",
    MODELS : "Playground",
    MODEL_PLAYGROUND:"Playground",
    TASK:"Tasks",
    PRICING:"Pricing",
    SUBSCRIBE:"Subscribe",
    CONTACT_US : "Contact us",
    SIGN_IN:"Login",
    SIGN_OUT:"Logout",
    SIGN_UP: "Register",
    MANAGE:"Manage",
    HELP: 'Help',
    DOCUMENTATION:" Documentation",
    DEV_STUDIO : "Home",
    USE_CASE:"Use Cases",
    PARTNERS: "Partners",
    SERVICES: "Services",
    INDUSTRY:"Industries",
    CORE_MODEL : "Core Models",
    AUGMENTED_MODEL : "Augmented Models",
    ENTERPRISE_MODEL : "Enterprise Models",
    BUILD_OWN_MODEL : "Build Your Own Model"
})