// modules
import { Component } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames';
import axios from '../../axios/Axios'

// utils
import {
    ACTIONS, deleteApikeyData, deleteUserData, getFilteredUsers, getUserAndAPIKeys, handleErrorData, INITIAL_STATE,
    insertApikeyData, insertUserData, renewApikeyData, renewUserKeyData, TAB_IDS, updateApikeyData, updateUserData
} from "./UserManagement.utils";
import { USER_MNGMENT_STRINGS } from "../../strings/UserManagement";
import { GET_USERS_SERVICE_ACCOUNT, MODEL_MAPPING_ADMINISTARTION } from "../../urls/Urls"
import { getBadgeDetails, getDollarFromNumber, getPlanDetails, getSubscriptionIDs, getWesternNumber, isEmpty, isEmptyList, isEmptyObject } from "../../utils/UtilityFunctions";
import { EMPTY_STRING, PAGE_TITLE, SELF_HOSTING_SUBSCRIPTION_IDS, SUBSCRIPTION_TYPE, USER_MANAGEMENT, USER_ROLE } from "../../utils/Constants";

// css files
import classes from './UserManagement.module.css';
import globalClasses from '../../App.module.css'

// components
import Loader from "../../components/loader/Loader";
import ErrorPage from "../../components/error_page/ErrorPage";
import NoData from "../../components/no_data/NoData";
import Badge from "../../components/ui_elements/badge/Badge";

// subcomponents
import ReportsPane from "./tab_panes/ReportsPane";
import ApiKeysPane from "./tab_panes/ApiKeysPane";
import UsersPane from "./tab_panes/UsersPane";
import SupportPane from "./tab_panes/SupportPane";
import ModelAdministration from "./account_management/model_administration/ModelAdministration";

// images
import REFRESH_ICON from '../../assets/images/user-manage/refresh-icon.png';
import EDIT_ICON from '../../assets/images/user-manage/edit-icon.png';
import { FaChevronLeft } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import PlanPane from "./tab_panes/PlanPane";
import AccountEditPopup from "./AccountEditPopup";
import UnsubscribeAccountPopup from "./UnsubscribeAccountPopup";
import TransactionsPane from "./tab_panes/TransactionsPane";
import SuccessScreen from "../../components/success_screen/SuccessScreen";
import { ROUTE_CONSTANTS } from "../../routes/RouteConstants";
import { BUTTON_TEXTS } from "../../strings/Strings";
import { getCognitoUserAccessToken } from "../sign_up/Cognito.utils";
import ConfiguratorsPane from "./tab_panes/ConfiguratorsPane";
import SelfHostingPane from "./tab_panes/SelfHostingPane";
import { IMAGES } from "../../utils/ImageConstants";
import ComponentHelmet from "../../components/component_helmet/ComponentHelmet";

class UserManagement extends Component {
    state = {
        ...INITIAL_STATE,
    }

    // get the basic details, users and service accounts 
    getUserAndServiceAccounts = (accountID) => {
        // console.log("APICreation axios call userapi refresh")
        let url = GET_USERS_SERVICE_ACCOUNT
        this.setState({
            isUserManagementLoading: true
        }, () => {
            axios.post(url,
                {
                    account_id: accountID
                },
                {
                    headers: {
                        Authorization: "Bearer " + getCognitoUserAccessToken()
                    }
                }
            )
                .then(res => {
                    this.setState({
                        ...getUserAndAPIKeys(res)
                    })
                })
                .catch(err => {
                    this.setState({
                        ...handleErrorData()
                    })
                })
        })
    }

    // CRUD operations for users list
    updateUsersData = (key, data) => {
        let { users, usersNameList, adminCount } = this.state;
        if (key === ACTIONS.INSERT) {
            this.setState({
                ...insertUserData(users, usersNameList, data, adminCount)
            })
        } else if (key === ACTIONS.UPDATE) {
            this.setState({
                ...updateUserData(users, usersNameList, data, adminCount)
            })
        } else if (key === ACTIONS.RENEW) {
            this.setState({
                ...renewUserKeyData(users, usersNameList, data)
            })
        } else {
            this.setState({
                ...deleteUserData(users, usersNameList, data, adminCount)
            })
        }
    }

    // CRUD operations for service accounts data
    updateServiceAccountData = (key, data) => {
        let { apiKeys } = this.state;
        if (key === ACTIONS.INSERT) {
            this.setState({
                ...insertApikeyData(apiKeys, data)
            })
        } else if (key === ACTIONS.UPDATE) {
            this.setState({
                ...updateApikeyData(apiKeys, data)
            })
        } else if (key === ACTIONS.RENEW) {
            this.setState({
                ...renewApikeyData(apiKeys, data)
            })
        } else {
            this.setState({
                ...deleteApikeyData(apiKeys, data)
            })
        }
    }

    // getting details on component mount
    componentDidMount = () => {
        let { params } = this.props.match;
        let { userDetails, accountDetail } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paymentStatus = urlParams.get('payment')
        const requestStatus = urlParams.get('request')
        urlParams.delete('payment');
        urlParams.delete('request');
        if (document.body.style.overflow !== "auto") {
            document.body.style.overflow = "auto"
        }
        if (paymentStatus === 'success' || requestStatus === 'success') {
            this.setState({
                showPaymentSuccessPopup: paymentStatus === 'success',
                showPaymentRequestSuccessPopup: requestStatus === 'success',
                isUserManagementLoading: false
            })
        } else {
            if (userDetails?.role === USER_ROLE.SUPER_ADMIN.LABEL && isEmpty(params?.accountID)) {
                this.props.history.push(ROUTE_CONSTANTS.USER_MANAGEMENT + "/" + accountDetail.accountUUID + (!isEmpty(params?.selectedTab) ? ("/" + params?.selectedTab) : EMPTY_STRING))
            } else {
                let activeTab = TAB_IDS.MYPLAN
                if (isEmpty(params?.selectedTab) || Object.values(TAB_IDS).indexOf(params?.selectedTab) >= 0) {
                    activeTab = params?.selectedTab || activeTab
                    this.setState({
                        activeTab
                    }, () => {
                        this.getUserAndServiceAccounts(params?.accountID);
                    })
                } else {
                    this.props.history.push(ROUTE_CONSTANTS.USER_MANAGEMENT)
                }
            }
        }
    }

    // set serach user empty and update the data
    emptySearchUser = () => {
        this.setState({
            searchUser: EMPTY_STRING,
            isSearchUserSelected: false,
            filteredUsers: []
        })
    }

    // on select tab chanimg active tab and setting initial data (if search user is enabled)
    onTabSelectHandler = (value) => {
        this.setState({
            activeTab: value
        }, () => {
            const { userDetails, accountDetail } = this.props;
            const { activeTab } = this.state;
            if (!isEmpty(this.state.searchUser)) {
                this.emptySearchUser()
            }
            if (userDetails?.role === USER_ROLE.SUPER_ADMIN.LABEL) {
                this.props.history.push(ROUTE_CONSTANTS.USER_MANAGEMENT + "/" + accountDetail.accountUUID + "/" + activeTab)
            } else {
                this.props.history.push(ROUTE_CONSTANTS.USER_MANAGEMENT + "/" + activeTab)
            }
        })
    }

    // to set error for the component
    setErrorData = () => {
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            ...handleErrorData()
        })
    }

    // on change user search (users tab)
    onChangeSearchUser = (user) => {
        let { users } = this.state;
        this.setState({
            searchUser: user
        }, () => {
            if (isEmpty(user)) {
                this.emptySearchUser();
            } else {
                this.setState({
                    ...getFilteredUsers(users, user)
                })
            }
        })
    }

    // on select user and get data for searcheduser
    onSelectUser = (user, item) => {
        let { users } = this.state;
        this.setState({
            searchUser: user,
            isSearchUserSelected: true
        }, () => {
            this.setState({
                ...getFilteredUsers(users, user)
            })
        })
    }

    // on edit account details
    onClickEditAccount = () => {
        this.setState({
            showAccountEditPopup: true
        })
    }

    onCloseEditAccount = () => {
        if (document.body.style.overflow !== "auto") {
            document.body.style.overflow = "auto"
        }
        this.setState({
            showAccountEditPopup: false,
        })
    }

    onClickUnsubscribeAccount = () => {
        this.setState({
            showUnsubscribeAccountPopup: true
        })
    }

    onCloseUnsubscribeAccount = () => {
        if (document.body.style.overflow !== "auto") {
            document.body.style.overflow = "auto"
        }
        this.setState({
            showUnsubscribeAccountPopup: false,
        })
    }

    render() {

        let userRole = this.props.role
        let userDetails = this.props.userDetails
        let accountDetail = this.props.accountDetail
        // state variables
        let { activeTab, apiKeys, users, isUserManagementLoading, companyName, accountUUID, mainAccountName,
            isUserManagementError, subscriptionType, searchUser, usersNameList,
            filteredUsers, accountID, adminCount, companyAddress, activeUsersCount,
            remainingDaysToExpire, addressLine1, addressLine2, city, region, country, postalCode, showAccountEditPopup,
            showUnsubscribeAccountPopup, subscriptions,
            showPaymentSuccessPopup, showPaymentRequestSuccessPopup, unsubscriptionRequestID, accountIsActive } = this.state

        let withSelfhosting = false;
        let subscriptionIDs = getSubscriptionIDs(subscriptions)
        if (!isEmptyList(subscriptionIDs)) {
            subscriptionIDs.map((subId, index) => {
                withSelfhosting = SELF_HOSTING_SUBSCRIPTION_IDS.includes(subId) || withSelfhosting;
                return index;
            })
        }

        let accountNameStyle = cx(["mb-0", globalClasses.FontGoogleSans, globalClasses.FontBold, globalClasses.Font24DarkerBlue, classes.Title])

        let apiKeyContainerComponent = null, reportContainerComponent = null, usersContainer = null, supportContainer = null,
            planContainerComponent = null, transactionsContainerComponent = null,
            accountEditPopupComponent = null, accountExpiryDays = null,
            unsubscribeAccountPopupComponent = null, selfHostingComponent = null,
            modelAdministrationComponent = null, llmAPIKeysContainerComponent = null;
        let loaderComponent = null;

        if (isUserManagementError) {
            return (
                <ErrorPage />
            )
        } else {
            if (isUserManagementLoading) {
                loaderComponent = (
                    <Loader containerClass={classes.Loader} />
                )
            } else if (showPaymentSuccessPopup || showPaymentRequestSuccessPopup) {
                return (
                    <SuccessScreen
                        className={''}
                        onClickHandler={() => {
                            window.location.replace(ROUTE_CONSTANTS.USER_MANAGEMENT)
                        }}
                        mainText={showPaymentSuccessPopup ? "Payment Successful" : "Thank you"}
                        subText={showPaymentSuccessPopup ? "You can dowload the receipt of this payment in the transaction section." : "Your request has been placed, Vivas.AI team will reach out to you soon."}
                        buttonText={BUTTON_TEXTS.DONE}
                    />
                )
            } else if (isEmpty(accountID)) {
                return (
                    <NoData
                        mainText={'No data found'}
                        subText={''}
                    />
                )
            } else {
                // accountExpiryDays = remainingDaysToExpire < 0 ? <span>Account has been expired</span> : <span>Account expires in <b>{remainingDaysToExpire}</b> days</span>

                accountExpiryDays = (accountIsActive === USER_MANAGEMENT.USER_STATUS.INACTIVE) ? <span>Account <b>Expired</b></span> : (accountIsActive === USER_MANAGEMENT.USER_STATUS.UNSUBSCRIBED) ? <span><b>Unsubscribed</b></span> : EMPTY_STRING

                if (showAccountEditPopup) {
                    accountEditPopupComponent = (
                        <AccountEditPopup
                            accountID={accountID}
                            accountName={mainAccountName}
                            companyName={companyName}
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            city={city}
                            region={region}
                            country={country}
                            postalCode={postalCode}
                            username={userDetails.email}
                            onCloseHandler={this.onCloseEditAccount}
                            refreshData={() => this.getUserAndServiceAccounts(accountUUID)}
                            companyAddress={companyAddress}
                        />
                    )
                }


                if (showUnsubscribeAccountPopup) {
                    unsubscribeAccountPopupComponent = (
                        <UnsubscribeAccountPopup
                            accountUUID={accountUUID}
                            accountID={accountID}
                            reason={""}
                            username={userDetails.email}
                            userDetails={userDetails}
                            onCloseHandler={this.onCloseUnsubscribeAccount}
                            refreshData={() => this.getUserAndServiceAccounts(accountUUID)}

                        />
                    )
                }

                if (withSelfhosting) {
                    selfHostingComponent = (
                        <SelfHostingPane
                            accountUUID={accountUUID}
                            remainingDays={remainingDaysToExpire}
                            accountIsActive={accountIsActive}
                            userRole={userRole}
                            userDetails={userDetails}
                            accountDetail={accountDetail}
                            subscriptionType={subscriptionType}
                            subscriptions={subscriptions}
                            isActiveTab={this.state.activeTab === TAB_IDS.SELF_HOSTING}
                            refreshData={() => this.getUserAndServiceAccounts(accountUUID)}
                        />
                    )
                }

                // users tab component
                usersContainer = (
                    <UsersPane
                        adminCount={adminCount}
                        remainingDays={remainingDaysToExpire}
                        activeUsersCount={activeUsersCount}
                        accountID={accountID}
                        accountUUID={accountUUID}
                        userRole={userRole}
                        subscriptionType={subscriptionType}
                        users={isEmpty(searchUser) ? users : filteredUsers}
                        usersNameList={usersNameList}
                        setErrorData={this.setErrorData}
                        refreshData={() => this.getUserAndServiceAccounts(accountUUID)}
                        username={userDetails.email}
                        searchedUser={searchUser}
                        onChangeSearchUser={(e) => this.onChangeSearchUser(e.target.value)}
                        onSelectUser={(val, item) => this.onSelectUser(val, item)}
                        clearSearch={this.emptySearchUser}
                        userDetails={userDetails}
                        accountIsActive={accountIsActive}
                    />
                )
                // api keys tab
                apiKeyContainerComponent = (
                    <ApiKeysPane
                        accountID={accountID}
                        accountUUID={accountUUID}
                        userRole={userRole}
                        remainingDays={remainingDaysToExpire}
                        apiKeys={apiKeys}
                        setErrorData={this.setErrorData}
                        refreshData={() => this.getUserAndServiceAccounts(accountUUID)}
                        username={userDetails.email}
                        userDetails={userDetails}
                        accountIsActive={accountIsActive}
                    />
                )
                // llm api keys tab
                llmAPIKeysContainerComponent = (
                    <ConfiguratorsPane
                        accountID={accountID}
                        accountUUID={accountUUID}
                        remainingDays={remainingDaysToExpire}
                        accountIsActive={accountIsActive}
                        userRole={userRole}
                        username={userDetails.email}
                        userDetails={userDetails}
                        accountDetail={accountDetail}
                        isActiveTab={this.state.activeTab === TAB_IDS.LLMKEYS}
                    />
                )
                // report tab
                reportContainerComponent = (
                    <ReportsPane
                        accountID={accountID}
                        accountUUID={accountUUID}
                        setErrorData={this.setErrorData}
                        username={userDetails.email}
                        accountName={mainAccountName}
                        isActiveTab={this.state.activeTab === TAB_IDS.REPORTS}
                    />
                    // <SupportPane />
                )
                // plan tab
                planContainerComponent = (
                    <PlanPane
                        accountID={accountID}
                        // remainingDays={-10}
                        unsubscriptionID={unsubscriptionRequestID}
                        accountActiveStatus={accountIsActive}
                        remainingDays={remainingDaysToExpire}
                        accountIsActive={accountIsActive}
                        setErrorData={this.setErrorData}
                        usersCount={users.length}
                        apiKeysCount={apiKeys.length}
                        userRole={userRole}
                        username={userDetails.email}
                        accountName={mainAccountName}
                        companyName={companyName}
                        accountUUID={accountUUID}
                        subscriptionType={subscriptionType}
                        subscriptions={subscriptions}
                        history={this.props.history}
                        userDetails={userDetails}
                        gotoAPIKeys={() => this.onTabSelectHandler(TAB_IDS.APIKEYS)}
                        gotoUsers={() => this.onTabSelectHandler(TAB_IDS.USERS)}
                        gotoSupport={() => this.onTabSelectHandler(TAB_IDS.SUPPORT)}
                    />
                )
                // suport tab
                supportContainer = (
                    <SupportPane
                        history={this.props.history}
                        userDetails={userDetails}
                        accountID={accountID}
                        accountUUID={accountUUID}
                        userRole={userRole}
                        isActiveTab={this.state.activeTab === TAB_IDS.SUPPORT}
                    />
                )

                transactionsContainerComponent = (
                    <TransactionsPane
                        accountID={accountID}
                        tableName={accountUUID}
                        isActiveTab={this.state.activeTab === TAB_IDS.TRANSACTIONS}
                        isNotAccountBasedTransaction={true}
                    />
                )

                // if(!isTrialPlan){
                //     modelAdministrationComponent = (
                //         <ModelAdministration
                //             apiURL={MODEL_MAPPING_ADMINISTARTION}
                //             userRole={this.props.role}
                //             userDetails={this.props.userDetails}
                //             isActive={this.state.activeTab === TAB_IDS.MODEL_ADMINISTRATION}
                //             accountId={accountID}
                //             accountUUID={accountUUID}
                //             tableName={accountUUID}
                //             isAccountLevel={true}
                //         />
                //     )
                // }

            }
        }

        return (
            <div>
                <ComponentHelmet
                    title={[PAGE_TITLE.MANAGE.TITLE]}
                    description={PAGE_TITLE.MANAGE.DESCRIPTION}
                    path={process.env.REACT_APP_DOMAIN_URL + window.location.pathname}
                />
                <div className={cx(["container-fluid px-0 position-relative", classes.UserManagementContainer])}>
                    {loaderComponent}
                    {accountEditPopupComponent}
                    {unsubscribeAccountPopupComponent}
                    {/* Banner Content */}
                    <div className={classes.Banner}>
                        {
                            userRole === USER_ROLE.SUPER_ADMIN.LABEL &&
                            <div className="my-3">
                                <span onClick={() => { this.props.history.push(ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT) }} className={classes.ViewAllLink}> <FaChevronLeft size={12} style={{ margin: '0 8px 2px 0', opacity: 1 }} />
                                    {USER_MNGMENT_STRINGS.VIEW_ALL}
                                </span>
                            </div>
                        }
                        <div className="row">
                            <div className="col-lg-8 col-12">
                                <div className="d-inline-flex align-items-center">
                                    <h3 className={accountNameStyle}>
                                        {mainAccountName}
                                    </h3>
                                    <button className={cx('mx-2', classes.IconContainer)}
                                        onClick={() => this.getUserAndServiceAccounts(accountUUID)}>
                                        <img
                                            src={IMAGES.ACTIONS.REFRESH_ICON_BLUE} alt={'Refresh'}
                                            title={USER_MNGMENT_STRINGS.USER_PANE.TOOLTIP_REFRESH}
                                        />
                                    </button>
                                </div>
                                <div className="col-12 d-flex flex-row flex-wrap">
                                    <span className={cx(['me-4 mt-3', globalClasses.Font16])}>
                                        Account ID:  <span><b>{accountUUID}</b></span>
                                    </span>
                                    <span className={cx(['me-4 mt-3', globalClasses.Font16])}>
                                        Company Name:  <span><b>{companyName}</b></span>
                                    </span>
                                </div>
                                <div className={cx(['me-4 mt-3', globalClasses.Font16])}>
                                    Company Address:  <span><b>{companyAddress}</b></span>
                                    {
                                        userRole !== USER_ROLE.SUBSCRIBED.LABEL &&
                                        <button className={cx('mx-2', classes.IconContainer)} onClick={this.onClickEditAccount}>
                                            <img
                                                src={IMAGES.ACTIONS.EDIT_ICON_BLUE} alt={'Edit'}
                                                title={"Edit Address"}
                                            />
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className={cx(['col-12 mt-3 mt-lg-0 text-lg-end', globalClasses.Font16])}>
                                    {accountExpiryDays}
                                </div>

                                {/* {
                                    userRole !== USER_ROLE.SUBSCRIBED.LABEL && isEmpty(unsubscriptionRequestID) && accountIsActive!=3 &&
                                    <div className="col-12 mt-3 text-lg-end">
                                        <span onClick={this.onClickUnsubscribeAccount} className={classes.ActionButtons}>
                                            {"Unsubscribe"}
                                        </span>
                                    </div>
                                } */}

                            </div>
                        </div>
                    </div>

                    {/* Tabs */}
                    <Tabs
                        activeKey={activeTab}
                        transition={false}
                        id="tabs"
                        defaultActiveKey={TAB_IDS.MYPLAN}
                        className={classes.Tabs}
                        onSelect={this.onTabSelectHandler}
                    >
                        <Tab tabClassName={activeTab === TAB_IDS.MYPLAN ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.MYPLAN} title={USER_MNGMENT_STRINGS.TAB_5}>
                            <div className="container-fluid px-3 px-md-5 my-5">{planContainerComponent}</div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.USERS ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.USERS} title={USER_MNGMENT_STRINGS.TAB_1}>
                            <div className="container-fluid px-3 px-md-5 my-5 position-relative">
                                {usersContainer}
                            </div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.APIKEYS ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.APIKEYS} title={USER_MNGMENT_STRINGS.TAB_2}>
                            <div className="container-fluid px-3 px-md-5 my-5">
                                {apiKeyContainerComponent}
                            </div>
                        </Tab>
                        <Tab tabClassName={activeTab === TAB_IDS.LLMKEYS ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.LLMKEYS} title={USER_MNGMENT_STRINGS.TAB_8}>
                            <div className="container-fluid px-3 px-md-5 my-5">
                                {llmAPIKeysContainerComponent}
                            </div>
                        </Tab>
                        {
                            withSelfhosting &&
                            <Tab tabClassName={activeTab === TAB_IDS.SELF_HOSTING ? classes.TabActive : classes.Tab}
                                eventKey={TAB_IDS.SELF_HOSTING} title={USER_MNGMENT_STRINGS.TAB_9}>
                                <div className="container-fluid px-3 px-md-5 my-5">
                                    {selfHostingComponent}
                                </div>
                            </Tab>
                        }
                        {
                            userRole !== USER_ROLE.SUBSCRIBED.LABEL &&
                            <Tab tabClassName={activeTab === TAB_IDS.REPORTS ? classes.TabActive : classes.Tab}
                                eventKey={TAB_IDS.REPORTS} title={USER_MNGMENT_STRINGS.TAB_3}>
                                <div className="container-fluid px-3 px-md-5 my-5">
                                    {reportContainerComponent}
                                </div>
                            </Tab>
                        }
                        <Tab tabClassName={activeTab === TAB_IDS.SUPPORT ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.SUPPORT} title={USER_MNGMENT_STRINGS.TAB_4}>
                            <div className="container-fluid px-3 px-md-5 my-5">{supportContainer}</div>
                        </Tab>
                        {/* <Tab tabClassName={activeTab === TAB_IDS.TRANSACTIONS ? classes.TabActive : classes.Tab}
                            eventKey={TAB_IDS.TRANSACTIONS} title={USER_MNGMENT_STRINGS.TAB_6}>
                            <div className="container-fluid px-3 px-md-5 my-5">{transactionsContainerComponent}</div>
                        </Tab> */}
                        {/* {
                            !isTrialPlan && userRole !== USER_ROLE.SUBSCRIBED.LABEL &&
                            <Tab tabClassName={activeTab === TAB_IDS.MODEL_ADMINISTRATION ? classes.TabActive : classes.Tab}
                                eventKey={TAB_IDS.MODEL_ADMINISTRATION} title={USER_MNGMENT_STRINGS.TAB_7}>
                                <div className={"container-fluid px-0 my-5"}>
                                    {modelAdministrationComponent}
                                </div>
                            </Tab>
                        } */}

                    </Tabs>
                </div>
            </div>
        )
    }
}

export default UserManagement;
