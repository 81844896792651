// modules
import cx from 'classnames';

// utils
import { USER_MNGMENT_STRINGS } from '../../strings/UserManagement';

// classes
import classes from './UserManagement.module.css';
import globalClasses from '../../App.module.css';

// components
import ModalCard from "../../components/ui_elements/modal_card/ModalCard";
import Popup from "../../components/ui_elements/popup/Popup";

// images
import LoaderGIF from '../../assets/images/loader/loader-gif.gif';
import REFRESH_ICON from '../../assets/images/user-manage/refresh-icon.png'
import { USER_ROLE } from '../../utils/Constants';

function UserDetailsViewPopup(props) {
    let { onCloseHandler, onRenewClientSecret, userDetail, isLoading } = props;
    return (
        <div>
            <Popup className={globalClasses.PopupBack}>
                <ModalCard
                    className={'col-11 col-md-8 col-lg-6 px-0'}
                    titleContainerClass={'px-3'}
                    isPopupFixed={true}
                    onClose={onCloseHandler}
                >
                    <div className='px-4'>
                        <h3 className={cx(['mb-3', classes.ModalHead])}>
                            {'User Details'}
                        </h3>
                        <table className={cx(['table table-borderless', classes.ModalTable])}>
                            <tr>
                                <td className={classes.ModalHeadData}>
                                    {'Email ID'}
                                </td>
                                <td className={classes.ModalData}>
                                    {userDetail.email}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.ModalHeadData}>
                                    {'User Type'}
                                </td>
                                <td className={classes.ModalData}>
                                    {userDetail.userType}
                                </td>
                            </tr>
                            {/* <tr>
                                <td className={classes.ModalHeadData}>
                                    {'Client Secret Expiration'}
                                </td>
                                <td className={classes.ModalData}>
                                    {userDetail.expirationDatetime}
                                    {(props.userRole !== USER_ROLE.SUBSCRIBED.LABEL) &&
                                        <img
                                            className={cx([classes.ModalIcon])}
                                            src={!isLoading ? REFRESH_ICON : LoaderGIF} alt={'Refresh'}
                                            title={USER_MNGMENT_STRINGS.USER_PANE.TOOLTIP_REFRESH}
                                            onClick={onRenewClientSecret}
                                        />}
                                </td>
                            </tr> */}
                            <tr>
                                <td className={classes.ModalHeadData}>
                                    {'Tier ID'}
                                </td>
                                <td className={classes.ModalData}>
                                    {userDetail.tier}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.ModalHeadData}>
                                    {'User Valid Upto'}
                                </td>
                                <td className={classes.ModalData}>
                                    {userDetail.validUpto}
                                </td>
                            </tr>
                        </table>
                    </div>
                </ModalCard>
            </Popup>
        </div>
    )
}

export default UserDetailsViewPopup;