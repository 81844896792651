import { Component } from "react";

import globalClasses from '../../App.module.css'
import NavBar from "../../components/navbar/NavBar";
import NetworkIssue from '../../components/network_issue/NetworkIssue';
import NetWorkIssueImage from '../../assets/images/network.png';

import FeedbackButton from "../../components/ui_elements/feedback_button/FeedbackButton";
import Popup from "../../components/ui_elements/popup/Popup";
import ModalCard from "../../components/ui_elements/modal_card/ModalCard";
import FeedbackScreen from "../../components/feedback/FeedbackScreen";
import HomeFooter from "../../components/home_footer/HomeFooter";



class HomeLayout extends Component {
    state = {
        isDisconnected: false,
        showFeedbackScreen: false,
        shouldBlockNavigation: false,
        setVisible : false,
        value:''
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
    }

    toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 350){
            this.setState({
                setVisible: true
            })
        }        
        else if (scrolled <= 350){
            this.setState({
                setVisible: false
            })
        }
    };

    handleWindowClose(){
        alert("Alerted Browser Close");
    }
    componentDidMount = () => {
        let image = new Image();
        image.src = NetWorkIssueImage
        window[Image] = image;
        this.handleNetworkConnection();
        window.addEventListener('online', this.handleNetworkConnection);
        window.addEventListener('offline', this.handleNetworkConnection);
        window.addEventListener('scroll', this.toggleVisible);
        // console.log("pasfsf", this.props, this.props.location.pathname == '/')
        // if (this.state.shouldBlockNavigation) {
        //     window.onbeforeunload = () => true
        //   } else {
        //     window.onbeforeunload = undefined
        //   }
        // window.addEventListener('pagehide',(e)=>{
        //     console.log("asf",e)
        //     alert("ags")
        //     return 'f'
        // })
        // (e) => {
            
            // alert("hi")
            // e.preventDefault();
            
            // this.setState({
            //     shouldBlockNavigation:true
            // })
            // const e = window.event;
            // Cancel the event
            // e.preventDefault();
            // if (e) {
            //    e.returnValue = ''; // Legacy method for cross browser support
            // }
            // return ''; // Legacy method for cross browser support
       
        // })
    }

    // componentDidUpdate = () => {
    //     if (this.state.shouldBlockNavigation) {
    //       window.onbeforeunload = () => true
    //     } else {
    //       window.onbeforeunload = undefined
    //     }
    //   }
    componentWillUnmount = () => {
        window.removeEventListener('online', this.handleNetworkConnection);
        window.removeEventListener('offline', this.handleNetworkConnection);
        window.removeEventListener('scroll', this.toggleVisible);
    }

    handleNetworkConnection = () => {
        let online = navigator.onLine;
        if (online) {
            console.log('online');
            this.setState({
                isDisconnected: false
            })
        } else {
            console.log('offline');
            this.setState({
                isDisconnected: true
            })
        }
    }
    showAndHideFeedbackScreen = () => {
        this.setState({
            showFeedbackScreen: !this.state.showFeedbackScreen
        })
    }
    render() {
        console.log('issuee home layout', this.props)

        let { isDisconnected, showFeedbackScreen, setVisible } = this.state;
        let {accountDetail, role, isSubscribedUser,userDetails,isAuthenticated} = this.props;

        let feedbackButtonComponent = null;
        let feedbackComponent = null;

        feedbackButtonComponent = (
            <FeedbackButton
                onClickHandler={this.showAndHideFeedbackScreen}
            />
        )
        if (showFeedbackScreen) {
            feedbackComponent = (
                <Popup className={globalClasses.PopupBack}>
                    <ModalCard isPopupFixed={true} onClose={this.showAndHideFeedbackScreen}>
                        <FeedbackScreen
                            isEmailMandatory={false}
                            closeFeedbackScreen={this.showAndHideFeedbackScreen}
                            isAuthenticated={isAuthenticated}
                            userDetails = {userDetails}
                            accountDetail={accountDetail}
                            userRole={role}
                        />
                    </ModalCard>
                </Popup>
            )
        }

        let bodyContent = isDisconnected ?
            <NetworkIssue /> :
            <>
                {feedbackButtonComponent}
                {feedbackComponent}
                {this.props.children}
            </>
        return (
            <>

                <NavBar history={this.props.history} userDetails={userDetails}  {...this.props} />
                {/* <Prompt
                    when={shouldBlockNavigation}
                    message='You have unsaved changes, are you sure you want to leave?'
                /> */}
                {bodyContent}
                {setVisible && <button onClick={this.scrollToTop} className={globalClasses.ScrollToTop}>▲</button>}
                <HomeFooter openFeedback = {this.showAndHideFeedbackScreen} showEula={isSubscribedUser}/>
            </>
        )
    }
}

export default HomeLayout;