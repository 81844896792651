import cx from 'classnames';
import globalClasses from '../../../../App.module.css';
import classes from '../../UserManagement.module.css';
import DOWNLOAD_ICON from '../../../../assets/images/download-orange.png';
import { isEmptyList, downloadFile, getDateFromInput, isEmptyObject } from '../../../../utils/UtilityFunctions';
import Loader from '../../../../components/loader/Loader';
import ErrorPage from '../../../../components/error_page/ErrorPage';
import NoData from '../../../../components/no_data/NoData';
import { USER_MNGMENT_STRINGS } from '../../../../strings/UserManagement';
import SortingTable from '../../../../components/sorting_table/SortingTable';
import { IMAGES } from '../../../../utils/ImageConstants';
import { SORT_TABLE_VALUES, SUPPORT_STATUS_IDS } from '../../../../utils/Constants';
import axios from "axios";
import { FILE_DOWNLOAD_FROM_S3 } from "../../../../urls/Urls";
import { getCognitoUserAccessToken } from '../../../sign_up/Cognito.utils';
import ReadMoreText from '../../../../components/ui_elements/read_more_text/ReadMoreText';
import { SEPARATORS } from '../../../../strings/Strings';
import { useState } from 'react';
import Popup from '../../../../components/ui_elements/popup/Popup';
import ModalCard from '../../../../components/ui_elements/modal_card/ModalCard';

function SupportRequests(props) {

    let { supportRequestsData, isSupportRequestsAPIError, isSupportRequestsAPILoading,
        isAccountTypeRequest, onClickEditComment, tableName } = props;
    let [ supportDetails, setSupprtDetails ] = useState(null)

    let supportRequestsContainer = null
    let supportRequestViewPopup = null;

    const downloadAttachment = (item) => {
        let downloadUrl = FILE_DOWNLOAD_FROM_S3
        let downloadReqBody = {
            objectID: item.attachmentUrl,
            bucketType: 'Private'
        }

        axios.post(
            downloadUrl,
            downloadReqBody,
            {headers:{"Authorization" : "Bearer "+getCognitoUserAccessToken()}}
        ).then((res) => {
            let responseURL = res.data.URL;
            downloadFile(responseURL)
        }).catch(err => {
            console.log('error: ', err)
        })
    }

    if (isSupportRequestsAPILoading) {
        supportRequestsContainer = <Loader />
    }
    else {
        if (isSupportRequestsAPIError) {
            supportRequestsContainer = <ErrorPage />
        }
        else if (isEmptyList(supportRequestsData)) {
            supportRequestsContainer = <NoData mainText={'No support requests available'} subText={''} />
        } else {
            if(!isEmptyObject(supportDetails)){
                supportRequestViewPopup = (
                    <Popup className={globalClasses.PopupBack}>
                        <ModalCard>

                        </ModalCard>
                    </Popup>
                )
            }
            supportRequestsContainer = (
                <SortingTable
                    headingList={[{
                        key: 'supportId',
                        label: 'Request ID',
                        isDate: false,
                        renderItem: (item) => {
                            return (
                                <label className={classes.SupportRequestID}>
                                    {item.supportId}
                                </label>
                            )
                        }
                    }, {
                        key: 'AccountUUID',
                        label: 'Account ID',
                        isDate: false,
                        hide: isAccountTypeRequest
                    }, {
                        key: 'createdBy',
                        label: 'Email ID',
                        isDate: false
                    }, {
                        key: 'category',
                        label: 'Category',
                        isDate: false
                    }, {
                        key: 'query',
                        label: 'Query',
                        isDate: false,
                        renderItem: (item) => {
                            return (
                                <ReadMoreText
                                    query={item.query}
                                />
                            )
                        }
                    }, {
                        key: 'approverComment',
                        label: 'Comments',
                        isDate: false
                    }, {
                        key: 'status',
                        label: 'Status',
                        isDate: false,
                        renderItem: (item) => {
                            if (!isNaN(item.status)) {
                                return SUPPORT_STATUS_IDS[String(item.status)]
                            }
                            return item.status
                        }
                    }, {
                        key: 'createdOn',
                        label: 'Raised On',
                        isDate: true,
                        onlyDate: true
                    }, {
                        key: 'actions',
                        label: 'Action',
                        isDate: false,
                        hide: isAccountTypeRequest,
                        unsortable: true,
                        renderItem: (item) => {
                            return (
                                <div className='d-flex text-center'>
                                    <img
                                        className={cx(['mx-2', classes.ActionIcon])}
                                        src={IMAGES.ACTIONS.EDIT_ICON} alt={'Edit'}
                                        title={USER_MNGMENT_STRINGS.ACCOUNTS.SUPPORT.EDIT_COMMENT}
                                        onClick={() => onClickEditComment(item)}
                                    />
                                </div>
                            )
                        }
                    }, {
                        key: 'attachmentUrl',
                        label: 'Attachment',
                        isDate: false,
                        unsortable: true,
                        renderItem: (item) => {
                            return (
                                item.attachmentUrl && <div className="d-flex text-center">
                                    <span onClick={() => downloadAttachment(item)}>
                                        <img
                                            className={cx(['mx-2', classes.ActionIcon])}
                                            src={DOWNLOAD_ICON} alt={USER_MNGMENT_STRINGS.TOOLTIP_DOWNLOAD}
                                            title={USER_MNGMENT_STRINGS.TOOLTIP_DOWNLOAD}
                                        />
                                    </span>
                                </div>
                            )
                        }
                    }]}
                    enableExports={true}
                    exportTableName={[tableName,"Support Tickets"].join(SEPARATORS.UNDERSCORE)}
                    exportKeysList={
                        [
                            {
                                key : "supportId",
                                label : "Request ID"
                            },
                            {
                                key: 'AccountUUID',
                                label: 'Account ID',
                                getValue: (item) => String(item.AccountUUID),
                                hide: isAccountTypeRequest
                            },
                            {
                                key: 'createdBy',
                                label: 'Email ID'
                            },
                            {
                                key: 'category',
                                label: 'Category'
                            },
                            {
                                key: 'query',
                                label: 'Query'
                            }, 
                            {
                                key: 'approverComment',
                                label: 'Comments'
                            },
                            {
                                key: 'status',
                                label: 'Status',
                                getValue: (item) => {
                                    if (!isNaN(item.status)) {
                                        return SUPPORT_STATUS_IDS[String(item.status)]
                                    }
                                    return item.status
                                }
                            },
                            {
                                key: 'createdOn',
                                label: 'Raised On',
                                getValue : (item) => getDateFromInput(item.createdOn,true)
                            }
                        ]
                    }
                    enableSearch = {true}
                    searchKeysList = {
                        [
                            {
                                key : "supportId",
                                label : "Request ID",
                                value : "Request ID",
                                placeholder : "Search by Request ID"
                            },
                            {
                                key : "AccountUUID",
                                label : "Account ID",
                                value : "Account ID",
                                placeholder : "Search by Account ID",
                                hide: isAccountTypeRequest
                            },
                            {
                                key : "createdBy",
                                label : "Email ID",
                                value : "Email ID",
                                placeholder : "Search by Email ID"
                            },
                            {
                                key : "category",
                                label : "Category",
                                value : "Category",
                                placeholder : "Search by Category"
                            }
                        ]
                    }
                    dataList={supportRequestsData}
                    itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.SUPPORT_TICKET}
                    noteText = {SORT_TABLE_VALUES.TABLE_NOTES.DATE_FORMAT1}
                />
            )
        }
    }


    return (
        <>{supportRequestsContainer}</>
    )
}


export default SupportRequests;