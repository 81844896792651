import { useState } from "react";

import cx from 'classnames';
import globalClasses from '../../App.module.css';

import UPDOWN_ICON from '../../assets/images/sort-all.png';
import DOWN_ICON from '../../assets/images/sort-down.png';
import UP_ICON from '../../assets/images/sort-up.png';

function SortableHead(props) {
    let {dataStyle, data, onSortHandler, sortKey,
        dataSortedStyle, iconStyle, isSortable, currentSortKey}=props;
    let [isSorted, setIsSorted] = useState(false)
    let [isSortAcsent, setSortOrder] = useState(false);
    let sortComponent = null;
    const onClickHandler = () => {
        if(isSortable){
            setIsSorted(true);
            setSortOrder(!isSortAcsent);
            onSortHandler(sortKey, isSortAcsent);
        }
    }
    if(currentSortKey !== sortKey){
        if(isSorted){
            setIsSorted(false);
            setSortOrder(false);
        }
    }
    if(isSortable){
        if(isSorted){
            if(!isSortAcsent){
                sortComponent = (
                    <img
                        src={DOWN_ICON}
                        className={iconStyle}
                        alt="Down icon"
                    />
                )
            } else {
                sortComponent = (
                    <img
                        src={UP_ICON}
                        className={iconStyle}
                        alt="Up icon"
                    />
                )
            }
        } else {
            sortComponent = (
                <img
                    src={UPDOWN_ICON}
                    className={iconStyle}
                    alt="up down arrow"
                />
            )
        }
    }
    if(isSorted) {
        dataStyle = cx([dataStyle, dataSortedStyle])
    }
    return (
        <th className={dataStyle} onClick={onClickHandler}>
            <div className={cx(["d-inline-flex",isSortable && globalClasses.PointerCursor])}>
                <span>{data}</span>
                <span className >
                    {sortComponent}
                </span>
            </div>
        </th>
    )
}

export default SortableHead;