import Card from "../card/Card";
import classes from './ProviderCard.module.css';
import cx from 'classnames';
import ToggleSwitch from "../toggle_switch/ToggleSwitch";
import {MdKeyboardArrowRight} from 'react-icons/md';
import globalClasses from '../../../App.module.css';

function ProviderCard(props) {
    let { providerDetails, onClickHandler, onClickToggle, toggleStyle, toggleCheckInput, hideAction } = props;

    let cardClass = classes.Card;
    let cardImageCotainerClass = classes.CardImageContainer;
    let cardTitleClass = cx(["mb-2 flex-grow-1",classes.CardTitle]);
    let cardDescClass = cx(["my-1",classes.CardDesc]);
    let activeClass = cx(["ms-1",classes.CardActive]);
    let inactiveClass = cx(["ms-1",classes.CardInActive])

    const onClickProviderCard = () => {
        onClickHandler(providerDetails.id)
    }
    const onChangeToggleSwitch = () => {
        onClickToggle();
    }

    return(
        <div className={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"}>
            <Card className={cardClass} >
                <div className={cardImageCotainerClass}>
                    <img className={classes.CardImage} src={providerDetails.imageSrc} alt={providerDetails.name} width="100" height="50"/>
                </div>
                <div className={"p-3"}>
                    <div className="d-flex">
                        <span title={providerDetails.name} className={cardTitleClass}>{providerDetails.name}</span>
                        {
                            !hideAction && <ToggleSwitch
                                isToggleOn={providerDetails.isActive}
                                onClickHandler={onChangeToggleSwitch}
                                toggleSwitchStyle = {toggleStyle}
                                checkinputClass = {toggleCheckInput}
                            />
                        }
                    </div>
                    <span className={cardDescClass}>{providerDetails.description}</span>
                    <div className="d-flex justify-content-between my-3">
                        <span className={classes.CardAvailable}>Available : {providerDetails.availableCount}</span>
                        <span>
                            <span title={"Active"} className={activeClass}>{providerDetails.activeCount}</span>
                            <span title={"Inactive"} className={inactiveClass}>{providerDetails.inActiveCount}</span>
                        </span>
                    </div>
                    <div className={classes.CardForward}>
                        <span onClick={onClickProviderCard} className={globalClasses.PointerCursor}>
                            View Models <MdKeyboardArrowRight size={16} style={{marginBottom:'3px'}}/>
                        </span>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default ProviderCard;