import { Component } from "react";
import axios from '../../../../axios/Axios'
import ErrorPage from "../../../../components/error_page/ErrorPage";
import Loader from "../../../../components/loader/Loader";
import { SORT_TABLE_VALUES, USER_ROLE, EVENT } from "../../../../utils/Constants";
import { isEmptyList, isEmptyObject } from "../../../../utils/UtilityFunctions";
import { INITIAL_STATE, REQUEST_DATA, setError, setProviderModelsInfo, setProvierInfo, updateProviderDetails, updateProviderModelDetails } from "./ModelAdministration.utils";
import classes from './ModelAdministration.module.css';
import cx from 'classnames';
import NoData from "../../../../components/no_data/NoData";
import ProviderCard from "../../../../components/ui_elements/provider_card/ProviderCard";
import { BUTTON_TEXTS, EMPTY_STRING, HTTP_METHODS, SEPARATORS, TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../../../strings/Strings";
import SortingTable from "../../../../components/sorting_table/SortingTable";
import globalClasses from '../../../../App.module.css';
import { AuditLog } from '../../../../utils/AuditUtils'

import { BsFillCircleFill } from "react-icons/bs";
import { MdKeyboardArrowLeft } from 'react-icons/md';
import ToggleSwitch from "../../../../components/ui_elements/toggle_switch/ToggleSwitch";
import ConfirmPopup from "../../../../components/confirm_popup/ConfirmPopup";
import { getCognitoUserAccessToken } from "../../../sign_up/Cognito.utils";
import { toast } from 'react-toastify';
class ModelAdministration extends Component {
    state = {
        ...INITIAL_STATE
    }

    getProviderDetails = (updated) => {
        let { providerList } = this.state;
        let { userDetails, apiURL, accountId, isAccountLevel } = this.props;

        if (isEmptyList(providerList) || updated) {
            this.setState({
                isLoading: true
            }, () => {
                let getURL = apiURL;
                let getData = {
                    ...REQUEST_DATA,
                    type: HTTP_METHODS.GET,
                    is_count_required: 'true',
                    user: userDetails.email
                }
                if (isAccountLevel) {
                    getData = {
                        ...getData,
                        account_id: accountId
                    }
                }
                axios.post(getURL, getData, {
                    headers: {
                        Authorization: "Bearer " + getCognitoUserAccessToken()
                    }
                })
                    .then((res) => {
                        this.setState({
                            ...setProvierInfo(res.data)
                        })
                    })
                    .catch((err) => {
                        this.setState({
                            ...setError()
                        })
                    })
            })
        }
    }

    getPutProviderModelDetails = (provider, method) => {
        let { userDetails, apiURL, accountId, isAccountLevel } = this.props;
        let { providerList, isProviderSelected, providerModelList } = this.state;

        let isActive = (method === HTTP_METHODS.PUT) ? !provider.isActive : EMPTY_STRING
        let getPutURL = apiURL;
        let getData = {
            ...REQUEST_DATA,
            provider_id: provider.id,
            type: method,
            is_active: isActive,
            user: userDetails.email
        }
        if (isAccountLevel) {
            getData = {
                ...getData,
                account_id: accountId
            }
        }

        //#region - audit log
        let startDateTime = new Date()
                
        // audit log - common properties
        let eventDetails = EVENT.MANAGE_CATEGORY.MANAGE_MODEL
        eventDetails = {
            ...eventDetails,
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }

        let additionalDetails = {} // actionable data
        if(getData){
            additionalDetails = {
                providerId: getData?.provider_id,
                httpMethod: getData?.type,
                isActive: getData?.is_active,
                accountId: getData?.account_id || null
            }
        }
        //#endregion
        let providerName = provider?.name || ''
        
        axios.post(getPutURL, getData, {
            headers:{
                Authorization: "Bearer "+getCognitoUserAccessToken()
            }
        })
            .then((res) => {
                if (method === HTTP_METHODS.GET) {
                    this.setState({
                        ...setProviderModelsInfo(res.data),
                        selectedProvider: provider
                    })
                } else {
                    //audit log - success scenario
                    if (res) {
                        eventDetails = {
                            ...eventDetails,
                            eventStatusCode: res?.status || '200',
                            eventStatus: 'Success',
                            eventMessage: res?.data?.message || res?.statusText || 'Enable/Disable model by provider id'
                        }
                    }

                    this.setState({
                        ...updateProviderDetails(providerList, provider, isProviderSelected, providerModelList)
                    })

                    //toaster
                    if (isActive) {
                        //toaster
                        toast(providerName ? TOASTER_MESSAGE.ACTIVATE_MODEL_PROVIDER.SUCCESS_TEXT1 +'\'' + providerName + '\'' +TOASTER_MESSAGE.ACTIVATE_MODEL_PROVIDER.SUCCESS_TEXT2
                            : TOASTER_MESSAGE.ACTIVATE_MODEL_PROVIDER.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });
                    }
                    else {
                        //toaster
                        toast(providerName ? TOASTER_MESSAGE.IN_ACTIVATE_MODEL_PROVIDER.SUCCESS_TEXT1 +'\'' + providerName +'\'' + TOASTER_MESSAGE.IN_ACTIVATE_MODEL_PROVIDER.SUCCESS_TEXT2
                            : TOASTER_MESSAGE.IN_ACTIVATE_MODEL_PROVIDER.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });
                    }
                }
            })
            .catch((err) => {
                //audit log - error scenario
                if (err) {
                    if (method !== HTTP_METHODS.GET) {
                        eventDetails = {
                            ...eventDetails,
                            eventStatus: 'Failure',
                            eventStatusCode: err?.response?.status || null,
                            eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Enable/Disable model by provider id',
                        }
                    }
                }
                this.setState({
                    ...setError()
                })

                //toaster
                if (isActive) {
                    //toaster
                    toast(providerName ? TOASTER_MESSAGE.ACTIVATE_MODEL_PROVIDER.ERROR_TEXT1 + '\'' + providerName + '\'' + TOASTER_MESSAGE.ACTIVATE_MODEL_PROVIDER.ERROR_TEXT2
                        : TOASTER_MESSAGE.ACTIVATE_MODEL_PROVIDER.ERROR,
                        {
                            type: TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });
                }
                else {
                    //toaster
                    toast(providerName ? TOASTER_MESSAGE.IN_ACTIVATE_MODEL_PROVIDER.ERROR_TEXT1 + '\'' + providerName + '\'' + TOASTER_MESSAGE.IN_ACTIVATE_MODEL_PROVIDER.ERROR_TEXT2
                        : TOASTER_MESSAGE.IN_ACTIVATE_MODEL_PROVIDER.ERROR,
                        {
                            type: TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });
                }
            })
            .finally(() => {
                //audit log
                if (method !== HTTP_METHODS.GET) {
                    this.insertToAuditLog(eventDetails, additionalDetails)
                }
                this.onCloseConfirmPopup()
            })
    }

    putModelDetails = (model) => {
        console.log(model)
        let { userDetails, apiURL, accountId, isAccountLevel } = this.props;
        let { providerList, providerModelList, selectedProvider } = this.state;

        let isActive = !model.isActive
        let getPutURL = apiURL;
        let putData = {
            ...REQUEST_DATA,
            model_id: model.id,
            type: HTTP_METHODS.PUT,
            is_active: isActive,
            user: userDetails.email
        }
        if (isAccountLevel) {
            putData = {
                ...putData,
                account_id: accountId,
                model_code: model.modelCode
            }
        }

        //#region - audit log
        let startDateTime = new Date()
                
        // audit log - common properties
        let eventDetails = EVENT.MANAGE_CATEGORY.MANAGE_MODEL
        eventDetails = {
            ...eventDetails,
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }

        let additionalDetails = {} //actionable data
        if(putData){
            additionalDetails = {
                modelId: model?.id,
                httpMethod: putData?.type,
                isActive: putData?.is_active,
                accountId: putData?.account_id || null,
                modelCode: model?.modelCode || null
            }
        }
        //#endregion

        let modelName = model?.modelName || ''

        axios.post(getPutURL, putData, {
            headers:{
                Authorization: "Bearer "+getCognitoUserAccessToken()
            }
        })
            .then((res) => {
                console.log(res.data)
                //audit log - success scenario
                if (res) {
                    eventDetails = {
                        ...eventDetails,
                        eventStatusCode: res?.status || '200',
                        eventStatus: 'Success',
                        eventMessage: res?.data?.message || res?.statusText || 'Enable/Disable model by model id'
                    }
                    //toaster
                    if (isActive) {
                        //toaster
                        toast(modelName ? TOASTER_MESSAGE.ACTIVATE_MODEL.SUCCESS_TEXT1 +'\''+ modelName +'\''+ TOASTER_MESSAGE.ACTIVATE_MODEL.SUCCESS_TEXT2 
                            : TOASTER_MESSAGE.ACTIVATE_MODEL.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });
                    }
                    else {
                        //toaster
                        toast(modelName ? TOASTER_MESSAGE.IN_ACTIVATE_MODEL.SUCCESS_TEXT1 +'\''+ modelName +'\''+ TOASTER_MESSAGE.IN_ACTIVATE_MODEL.SUCCESS_TEXT2
                            : TOASTER_MESSAGE.IN_ACTIVATE_MODEL.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });
                    }
                }
                this.setState({
                    ...updateProviderModelDetails(providerList, model, selectedProvider, providerModelList)
                })
            })
            .catch((err) => {
                //audit log - error scenario
                if (err) {
                    eventDetails = {
                        ...eventDetails,
                        eventStatus: 'Failure',
                        eventStatusCode: err?.response?.status || null,
                        eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Enable/Disable model by model id',
                    }
                }

                //toaster
                if (isActive) {
                    //toaster
                    toast(modelName ? TOASTER_MESSAGE.ACTIVATE_MODEL.ERROR_TEXT1 +'\''+ modelName +'\''+ TOASTER_MESSAGE.ACTIVATE_MODEL.ERROR_TEXT1
                        : TOASTER_MESSAGE.ACTIVATE_MODEL.ERROR,
                        {
                            type: TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });
                }
                else {
                    //toaster
                    toast(modelName ? TOASTER_MESSAGE.IN_ACTIVATE_MODEL.ERROR_TEXT1 +'\''+ modelName +'\''+ TOASTER_MESSAGE.IN_ACTIVATE_MODEL.ERROR_TEXT2
                        : TOASTER_MESSAGE.IN_ACTIVATE_MODEL.ERROR,
                        {
                            type: TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });
                }

                this.setState({
                    ...setError()
                })
            })
            .finally(() => {
                //audit log
                this.insertToAuditLog(eventDetails, additionalDetails)
                this.onCloseConfirmPopup()
            })
    }

    componentDidMount = () => {
        this.getProviderDetails()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.isActive !== prevProps.isActive) {
            this.getProviderDetails()
        }
    }

    onClickProvider = () => {
        let { tempReqData } = this.state;
        let item = tempReqData.item;
        let method = tempReqData.method;
        this.setState({
            isLoading: true,
            showPopupId: 0
        }, () => {
            this.getPutProviderModelDetails(item, method)
        })
    }

    onClickProviderModel = () => {
        let { tempReqData } = this.state;
        let item = tempReqData.item;
        this.setState({
            isLoading: true,
            showPopupId: 0
        }, () => {
            this.putModelDetails(item)
        })
    }

    onClickViewProviderModels = (item, method) => {
        let tempReqData = {};
        tempReqData["item"] = item;
        tempReqData["method"] = method;
        this.setState({
            tempReqData
        }, () => {
            this.onClickProvider()
        })
    }

    onClickProviderToggle = (item, method) => {
        let tempReqData = {};
        tempReqData["item"] = item;
        tempReqData["method"] = method;
        this.setState({
            tempReqData,
            showPopupId: 1
        })
    }

    onClickProviderModelToggle = (item) => {
        let tempReqData = {};
        tempReqData["item"] = item;
        this.setState({
            tempReqData,
            showPopupId: 2
        })
    }

    onCloseConfirmPopup = () => {
        if (document.body.style.overflow !== "auto") {
            document.body.style.overflow = "auto"
        }
        this.setState({
            tempReqData: {},
            showPopupId: 0
        })
    }

    onClickBack = () => {
        this.setState({
            selectedProvider: {},
            providerModelList: [],
            isProviderSelected: false
        })
    }

    //audit log
    insertToAuditLog = (eventDetails, additionalDetails) => {
        console.log('Props: ', this.props)
        let { userDetails, accountUUID } = this.props
        let accountDetails = {}                  
        let eventAttributes = {}
        let user = {}
        if (accountUUID) {
            accountDetails = {accountUUID: accountUUID}
        }

        if (!isEmptyObject(userDetails)) {
            user = {
                awsId: userDetails?.awsId,
                email: userDetails?.email,
            }
        }
        eventAttributes = {...user, ...additionalDetails}

        AuditLog(eventDetails, eventAttributes, accountDetails)
    }

    render() {
        let { providerList, isErrorData, isLoading, selectedProvider,
            providerModelList, isProviderSelected, showPopupId, tempReqData } = this.state;
        let { isAccountLevel, userRole, tableName } = this.props;

        let providerComponent = null;
        let loaderComponent = null;
        let popupComponent = null;
        let backButtonComponnet = null;
        let hideAction = isAccountLevel && userRole === USER_ROLE.SUPER_ADMIN.LABEL;

        if (isErrorData) {
            return (
                <ErrorPage />
            )
        } else {
            if (isLoading) {
                loaderComponent = (
                    <Loader containerClass={classes.Loader} />
                )
            }
            if (showPopupId) {
                let subText = EMPTY_STRING;
                let actionlevelQuest = isAccountLevel ? " for your account?" : " for the application?"
                if (showPopupId === 1) {
                    let status = tempReqData.item.isActive ? "disable" : "enable";
                    subText = "Do you want to " + status + " " + " the " + tempReqData.item.name + " provider" + actionlevelQuest
                } else {
                    let status = tempReqData.item.isActive ? "disable" : "enable";
                    subText = "Do you want to " + status + " " + " the " + tempReqData.item.modelName + " model" + actionlevelQuest
                }
                popupComponent = (
                    <ConfirmPopup
                        popupClass={globalClasses.PopupBack}
                        isPopupFixed={true}
                        modalClass={cx(['col-11 col-md-9 col-lg-8 col-xl-6 p-4'])}
                        title={"Are you sure?"}
                        subtext={subText}
                        primaryButtonText={BUTTON_TEXTS.CONFIRM}
                        onClickPrimaryButton={showPopupId === 1 ? this.onClickProvider : this.onClickProviderModel}
                        secondaryButtonText={BUTTON_TEXTS.CANCEL}
                        onClickSecondaryButton={this.onCloseConfirmPopup}
                        onCloseModalCard={this.onCloseConfirmPopup}
                    />
                )
            }
            if (!isEmptyList(providerList)) {
                if (isProviderSelected) {
                    backButtonComponnet = (
                        <div>
                            <span onClick={this.onClickBack} className={globalClasses.PointerCursor}>
                                <MdKeyboardArrowLeft size={16} style={{ marginBottom: '3px' }} /> Back to all providers
                            </span>
                        </div>
                    )
                    providerComponent = (
                        <div className="d-flex flex-column">
                            <div className="row my-4">
                                <div className={cx(["d-none d-md-inline-flex col-3 col-lg-2", classes.BannerImageContainer])}>
                                    <img src={selectedProvider.imageSrc} alt={selectedProvider.name} width="100" height="50" />
                                </div>
                                <div className="col-12 col-md-9 col-lg-10">
                                    <div className="d-inline-flex align-items-center">
                                        <h2 className={classes.BannerTitle}>{selectedProvider.name}</h2>
                                        {
                                            !hideAction &&
                                            <ToggleSwitch
                                                isToggleOn={selectedProvider.isActive}
                                                onClickHandler={() => this.onClickProviderToggle(selectedProvider, HTTP_METHODS.PUT)}
                                                toggleSwitchStyle={classes.ToggleStyle}
                                                checkinputClass={classes.ToggleCheckInput}
                                            />
                                        }

                                    </div>
                                    <p className={classes.BannerDesc}>{selectedProvider.description}</p>
                                    <span className={classes.BannerText}>
                                        Available Model(s) : {selectedProvider.availableCount}
                                        &nbsp;|&nbsp;Active Model(s) : {selectedProvider.activeCount}
                                        &nbsp;|&nbsp;Inactive Model(s) : {selectedProvider.inActiveCount}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <SortingTable
                                
                                    enableExports={true}
                                    exportTableName={[tableName,"Manage Models",selectedProvider.name].join(SEPARATORS.UNDERSCORE)}
                                    exportKeysList={
                                        [
                                            {
                                                key: 'modelName',
                                                label: 'Model Name'
                                            }, {
                                                key: 'modelCode',
                                                label: 'Model Code'
                                            }, {
                                                key: 'category',
                                                label: 'Category'
                                            }, {
                                                key: 'modelDomain',
                                                label: 'Domain Group'
                                            }, {
                                                key: 'modelGroup',
                                                label: 'Model Group'
                                            }, {
                                                key: 'tier',
                                                label: 'Tier'
                                            }, {
                                                key: 'credits',
                                                label: 'Credits'
                                            }, {
                                                key: 'isActive',
                                                label: 'Status',
                                                getValue: (item) => {
                                                    if (!item.isActiveForAllAccounts) {
                                                        return "Disabled by SuperAdmin"
                                                    } else if (item.isActive) {
                                                        return "Active";
                                                    } else {
                                                        return "Inactive";
                                                    }
                                                }
                                            }  
                                        ]
                                    }
                                    headingList={[{
                                        key: 'modelName',
                                        label: 'Model Name',
                                        isDate: false
                                    }, {
                                        key: 'modelCode',
                                        label: 'Model Code',
                                        isDate: false
                                    }, {
                                        key: 'category',
                                        label: 'Category',
                                        isDate: false
                                    }, {
                                        key: 'modelDomain',
                                        label: 'Domain Group',
                                        isDate: false
                                    }, {
                                        key: 'modelGroup',
                                        label: 'Model Group',
                                        isDate: false
                                    }, {
                                        key: 'tier',
                                        label: 'Tier',
                                        isDate: false
                                    }, {
                                        key: 'credits',
                                        label: 'Credits',
                                        isDate: false
                                    }, {
                                        key: 'isActive',
                                        label: 'Status',
                                        isDate: false,
                                        renderItem: (item) => {
                                            if (!item.isActiveForAllAccounts) {
                                                return <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-blue-grey)" />&nbsp;Disabled by SuperAdmin</span>
                                            } else if (item.isActive) {
                                                return <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-active)" />&nbsp;Active</span>;
                                            } else {
                                                return <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-expired)" />&nbsp;Inactive</span>;
                                            }
                                        }
                                    }, {
                                        key: 'isActive',
                                        label: 'Is Active?',
                                        isDate: false,
                                        hide: hideAction,
                                        renderItem: (item) => {
                                            if (!item.isActiveForAllAccounts) {
                                                return EMPTY_STRING;
                                            }
                                            let icon = (
                                                <ToggleSwitch
                                                    isToggleOn={item.isActive}
                                                    onClickHandler={() => this.onClickProviderModelToggle(item)}
                                                    toggleSwitchStyle={classes.ToggleStyle}
                                                    checkinputClass={classes.ToggleCheckInput}
                                                />
                                            )
                                            return <span className="mx-auto">{icon}</span>
                                        }
                                    }]}
                                    enableSearch = {true}
                                    searchKeysList = {
                                        [
                                            {
                                                key : "modelName",
                                                label : "Model Name",
                                                value : "Model Name",
                                                placeholder : "Search by Model Name"
                                            },
                                            {
                                                key : "modelCode",
                                                label : "Model Code",
                                                value : "Model Code",
                                                placeholder : "Search by Model Code"
                                            },
                                            {
                                                key : "category",
                                                label : "Category",
                                                value : "Category",
                                                placeholder : "Search by Category"
                                            },
                                            {
                                                key : "modelDomain",
                                                label : "Domain Group",
                                                value : "Domain Group",
                                                placeholder : "Search by Domain Group"
                                            },
                                            {
                                                key : "modelGroup",
                                                label : "Model Group",
                                                value : "Model Group",
                                                placeholder : "Search by Model Group"
                                            },
                                            {
                                                key : "tier",
                                                label : "Tier",
                                                value : "Tier",
                                                placeholder : "Search by Tier"
                                            }
                                        ]
                                    }
                                    dataList={providerModelList}
                                    itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.MODEL_MANAGE}
                                />
                            </div>
                        </div>
                    )
                } else {
                    providerComponent = (
                        providerList.map((item, index) => {
                            return (
                                <ProviderCard
                                    key={index}
                                    providerDetails={item}
                                    onClickHandler={() => this.onClickViewProviderModels(item, HTTP_METHODS.GET)}
                                    onClickToggle={() => this.onClickProviderToggle(item, HTTP_METHODS.PUT)}
                                    toggleStyle={classes.ToggleStyle}
                                    hideAction={hideAction}
                                    toggleCheckInput={classes.ToggleCheckInput}
                                />
                            )
                        })
                    )
                }
            } else {
                if (!isLoading) {
                    return (
                        <NoData
                            mainText={"No Model Data Found"}
                            subText={"We can't find any data, refresh the page or try agin later."}
                        />
                    )
                }
            }
        }

        return (
            <div className={cx(["container-fluid px-5 position-relative", classes.Container])}>
                {loaderComponent}
                {popupComponent}
                {backButtonComponnet}
                <div className="row">
                    {providerComponent}
                </div>
            </div>
        )
    }
}

export default ModelAdministration;