// modules
import cx from "classnames";

// classes
import classes from "./Button.module.css";
import { getActionClass, getBorderClass, getRadiusClass } from "./Button.utils";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import ReactButton from 'react-bootstrap/Button';
import { useState } from "react";

function Button (props) {

    // initialization
    let { id, containerClass, className, icon, backIcon, label, actionType, radiusType, 
        borderType, disabled, onClickHandler, withDropOptions, optionObjectList } = props;
    
    let [buttonKey,setButtonKey] = useState(optionObjectList?.[0])

    // class initialization
    let actionClass = getActionClass(actionType);
    let radiusClass = getRadiusClass(radiusType);
    let borderClass = getBorderClass(borderType);
    
    let buttonClass = cx("col-12 p-2",classes.ButtonNew, actionClass, radiusClass, borderClass, className);
   
    let buttonContainerClass = cx("btn-group",containerClass);

    const onClickDropButtonHandler = (buttonkeyData) => {
        setButtonKey(buttonkeyData)
        onClickHandler(buttonkeyData.key)
    }

    if(withDropOptions){
        let groupButtonContainerClass=cx("col-12 d-flex")
        let groupButtonClass = cx("flex-grow-1 p-2",classes.ButtonNew, actionClass, radiusClass, borderClass, className, classes.GroupButton);
        let groupDropButtonClass = cx("flex-grow-0 py-2 px-3",classes.ButtonNew, classes.GroupDropButton, actionClass, radiusClass, borderClass, className);
        let dropItemClass = classes.DropDownItem;
        let dropItemTitleClass = classes.DropDownTitleItem;
        let dropItemDescriptionClass = classes.DropDownDescriptionItem;
        let dropOptions = optionObjectList.filter(options => !options?.hide)
        return (
            <div className={buttonContainerClass} role="group">
                <Dropdown as={ButtonGroup} className={groupButtonContainerClass}>
                    <ReactButton className={groupButtonClass} onClick={() => onClickDropButtonHandler(buttonKey)} disabled={disabled}>{buttonKey.name}</ReactButton>
                    <Dropdown.Toggle id={id+"-drop"} className={groupDropButtonClass} disabled={disabled}/>
                    <Dropdown.Menu>
                        {
                            dropOptions.map((option, index) => {
                                return (
                                    <>
                                        <Dropdown.Item className={dropItemClass} key={option.key} onClick={() => onClickDropButtonHandler(option)}>
                                            <label className={dropItemTitleClass}>{option.name}</label>
                                            <label className={dropItemDescriptionClass}>{option.description}</label>
                                        </Dropdown.Item>
                                        {index<dropOptions.length-1 && <Dropdown.Divider/>}
                                    </>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    } else {
        return (
            <div className={containerClass}>
                <button id={id} className={buttonClass}
                    onClick={onClickHandler}
                    disabled={disabled}
                >
                    {icon}{label}{backIcon}
                </button>
            </div>
        )
    }
}

export default Button;