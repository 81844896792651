import { useState } from "react";
import cx from 'classnames';
import classes from './TryForFree.module.css';
import TextField from "../../components/ui_elements/form_fields/text_input/TextField";
import Button from "../../components/ui_elements/button/ButtonNew";

import globalClasses from '../../App.module.css'
import {
    isEmpty, isEmptyObject, isValidCompanyNameString, isValidConfirmPassword, isValidNumber,
    isValidNumberString, isValidPassword, isValidTextString, isValidWorkEmailString, onSignupClicked,
    getUTCTime, getTimeDiff
} from "../../utils/UtilityFunctions";
import { EMPTY_STRING, PAGE_TITLE } from "../../utils/Constants";
import Loader from "../../components/loader/Loader";
import { ROUTE_CONSTANTS } from "../../routes/RouteConstants";
import { FORM_LABEL_TEXTS, FORM_PLACEHOLDER_TEXTS } from "../../strings/Strings";

import VerifyEmail from "./VerifyEmail";
import { authenticateUser, resendConfirmationCode } from "./Cognito.utils";
import PasswordField from "../../components/ui_elements/form_fields/password_input/PasswordField";
import ConfirmPasswordField from "../../components/ui_elements/form_fields/password_input/ConfirmPasswordField";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { BUTTON_TYPES } from "../../utils/ButtonConstants";
import ComponentHelmet from "../../components/component_helmet/ComponentHelmet";

function Login(props) {

    const [email, setEmail] = useState(EMPTY_STRING)
    const [isEmailError, setEmailError] = useState(false)

    const [totp, setTOTP] = useState(EMPTY_STRING)
    const [isTOTPError, setTOTPError] = useState(false)
    const [isMFAEnabled, setMFAEnabled] = useState(false)
    const [isTOTPErrorMsg, setTOTPErrorMsg] = useState(EMPTY_STRING)

    const [password, setPassword] = useState(EMPTY_STRING)
    const [isPasswordError, setPasswordError] = useState(false)

    const [newPassword, setNewPassword] = useState(EMPTY_STRING)
    const [isNewPasswordError, setNewPasswordError] = useState(false)

    const [confirmPassword, setConfirmPassword] = useState(EMPTY_STRING)
    const [isConfirmPasswordError, setConfirmPasswordError] = useState(false)


    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)

    const [isLoginError, setIsLoginError] = useState(false)
    const [isLoginErrorMsg, setIsLoginErrorMsg] = useState(false)

    const [isEmailVerified, setEmailVerified] = useState(true)

    const [isForceNewPassword, setIsForceNewPassword] = useState(false)
    // const [isForceNewPasswordError, setIsForceNewPasswordError] = useState(false)
    const [isForceNewPasswordErrorMsg, setIsForceNewPasswordErrorMsg] = useState(false)


    const [userAttributes, setUserAttributes] = useState()
    const [user, setUser] = useState()


    const onChangeEmail = (email) => {
        setEmail(email)
        setEmailError(false)
    }

    const onChangeTOTP = (totp) => {
        console.log("totp", totp)
        setTOTP(totp)
        setTOTPError(false)
    }

    const onChangePassword = (password) => {
        setPassword(password)
        setPasswordError(false)
    }

    const onChangeNewPassword = (password) => {
        setNewPassword(password)
        setNewPasswordError(false)
    }

    const onChangeConfirmPassword = (confirmPassword) => {

        setConfirmPassword(confirmPassword)
        setConfirmPasswordError(false)
        if (isSubmitError) {
            let isConfirmPasswordError = isValidConfirmPassword(newPassword, confirmPassword).error;
            setConfirmPasswordError(isConfirmPasswordError)
        }
    }

    const onLoginFailureHandler = (err) => {
        console.log("logon err", err, err.code)
        let errMsg = err.message
        if (err.code === 'UserNotConfirmedException') {
            errMsg = "Verify your email and try login"
            setEmailVerified(false)
            resendConfirmationCode(email, () => { })
        }
        setIsLoading(false)
        setIsLoginError(true)
        setIsLoginErrorMsg(errMsg)
    }

    const onForceNewPasswordChangeHandler = (user, userAttributes) => {
        setIsForceNewPassword(true)
        setIsLoading(false)
        setUserAttributes(userAttributes)
        setUser(user)
    }

    const onClickConfirmNewPasswordChangeHandler = () => {
        let isNewPasswrdErr = isValidPassword(newPassword, true).error
        let isCnfrmPasswrdErr = isValidConfirmPassword(newPassword, confirmPassword).error
        console.log("passssss", isNewPasswrdErr, isCnfrmPasswrdErr)
        if (!isNewPasswrdErr && !isCnfrmPasswrdErr) {
            setIsLoading(true)
            setIsSubmitError(false)
            setNewPasswordError(false)
            setConfirmPasswordError(false)
            delete userAttributes.email_verified
            delete userAttributes.email


            user.completeNewPasswordChallenge(newPassword, userAttributes, {
                onSuccess: (data) => {

                    window.location = props.history.location.state ? props.history.location.state.from : ROUTE_CONSTANTS.STUDIO_HOME

                },
                onFailure: (err) => {
                    setIsForceNewPasswordErrorMsg(err.message)
                },
            })

        } else {
            setIsSubmitError(true)
            setNewPasswordError(isNewPasswrdErr)
            setConfirmPasswordError(isCnfrmPasswrdErr)
            // setEmailVerified(false)
        }
    }
    const generateTOTPCodeHandler = (user) => {
        setIsLoading(false)
        setUser(user)
        setMFAEnabled(true)
    }
    const handleKeypress = (e) => {
        // console.log(e.keyCode)
        if (e.keyCode === 13) {
            console.log('Enter Button Clicked!')
            onLoginClicked();
        }
    }

    const onLoginClicked = () => {
        let isMailError = isValidWorkEmailString(email, true).error;
        let isPsswrdErr = isValidTextString(password, true).error;

        console.log("valid", isValidWorkEmailString(email, true))
        if (!isMailError && !isPsswrdErr) {
            setIsLoading(true)
            setIsLoginError(false)
            setIsLoginErrorMsg(EMPTY_STRING)
            authenticateUser(email, password, props.history,
                (err) => onLoginFailureHandler(err),
                (user, userAttributes) => onForceNewPasswordChangeHandler(user, userAttributes),
                (user) => generateTOTPCodeHandler(user)
            )

        }
        else {
            // setIsLoginError(true)
            setIsSubmitError(true)
            setEmailError(isMailError)
            setPasswordError(isPsswrdErr)
        }

    }

    const onClickSubmitTOTP = () => {

        let isValidTOTP = isValidNumberString(totp, true).error
        if (!isValidTOTP) {
            setIsLoading(true)
            user.sendMFACode(totp,
                {
                    onSuccess: (data) => {
                        console.log("successssss")
                        window.location.replace(props.history.location.state ? props.history.location.state.from : ROUTE_CONSTANTS.STUDIO_HOME)

                    },
                    onFailure: (err) => {
                        console.error("error", err, err.code)
                        setIsLoading(false)
                        setTOTPErrorMsg(err.message)
                    },

                }, 'SOFTWARE_TOKEN_MFA');

        } else {
            setIsSubmitError(true)
            setTOTPError(isValidTOTP)
        }

    }

    const gotoLogin = () => {

        setMFAEnabled(false)
        setPassword(EMPTY_STRING)
    }

    let formComponent = null
    if (isForceNewPassword) {
        formComponent =
            <div className='col-12 mt-2'>
                <h3 className={cx([globalClasses.FontGoogleSans, globalClasses.FontBold, "text-start mb-4"])}>
                    Set Password
                </h3>
                <ConfirmPasswordField
                    passwordLabel={FORM_LABEL_TEXTS.NEW_PASSWORD}
                    confirmLabel={FORM_LABEL_TEXTS.CONFIRM_PASSWORD}
                    containerClass={"col-12"}
                    password={newPassword}
                    confirmPassword={confirmPassword}
                    isDisabled={isLoading}
                    isReadOnly={isLoading}
                    isSubmitError={isSubmitError}
                    passwordPlaceholder={FORM_PLACEHOLDER_TEXTS.NEW_PASSWORD}
                    passwordInfo={FORM_LABEL_TEXTS.VALID_PASSWORD_TEXT}
                    confirmPasswordPlaceholder={FORM_PLACEHOLDER_TEXTS.CONFIRM_PASSWORD}
                    onChangePassword={onChangeNewPassword}
                    isErrorPassword={isNewPasswordError}
                    onChangeConfirmPassword={onChangeConfirmPassword}
                    isErrorConfirmPasword={isConfirmPasswordError}
                />
                {!isEmpty(isForceNewPasswordErrorMsg) && <p className={cx(['align-self-center', globalClasses.Error])}> {isForceNewPasswordErrorMsg}</p>}

                <Button
                    label={"Submit"}
                    className={cx(['mt-3 w-100', globalClasses.PrimaryButton, classes.AuthFormButton])}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                    onClickHandler={(e) => {
                        e.preventDefault()
                        onClickConfirmNewPasswordChangeHandler()
                    }}
                />
            </div>
    }
    else if (isMFAEnabled) {
        formComponent = <>
            <span className={cx([globalClasses.Font12Grey, globalClasses.PointerCursor])} onClick={gotoLogin}>
                <HiOutlineChevronLeft size={12} style={{ margin: '0px 3px 3px 0px' }} />Cancel
            </span>
            <h3 className={cx([globalClasses.FontGoogleSans, globalClasses.FontBold, "text-start mb-4 mt-3"])}>
                Multi Factor Authentication
            </h3>
            <div className='col-12'>
                <TextField
                    containerClass='col-12'
                    value={totp}
                    label={"TOTP"}
                    id={'totp'}
                    placeHolder={"Enter TOTP"}
                    onChangeHandler={onChangeTOTP}
                    validate={isValidNumberString}
                    isDisabled={isLoading}
                    isReadOnly={isLoading}
                    isError={isSubmitError}
                    isFieldError={isTOTPError}
                    isMandatory={true}
                    isNumber={true}
                />
            </div>
            {!isEmpty(isTOTPErrorMsg) && <div className={cx(['align-self-center col-12', globalClasses.Error])}> {isTOTPErrorMsg}</div>}
            <span className={globalClasses.SupportText}>Having trouble? Contact your administrator.
            </span>
            <div className='col-12 mt-4'>
                <Button
                    label={"Verify"}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                    className={cx(["col-12"])}
                    onClickHandler={onClickSubmitTOTP}
                />
            </div>

        </>
    }
    else if (isEmailVerified) {
        formComponent = <>

            <h3 className={cx([globalClasses.FontGoogleSans, globalClasses.FontBold, "text-start mb-4"])}>
                Login
            </h3>
            {/* <form> */}
            {/* <div className="col-9"> */}
            <TextField
                containerClass='col-12 mb-1'
                value={email}
                label={FORM_LABEL_TEXTS.EMAIL}
                id={'email'}
                placeHolder={FORM_PLACEHOLDER_TEXTS.LOGIN_EMAIL}
                onChangeHandler={(e) => onChangeEmail(e)}
                validate={isValidWorkEmailString}
                isDisabled={isLoading}
                isReadOnly={isLoading}
                isError={isSubmitError}
                isFieldError={isEmailError}
                isMandatory={true}
                isNumber={false}
            />

            {/* </div> */}

            {/* <div className="col-9"> */}
            <PasswordField
                containerClass='col-12 mb-1'
                value={password}
                type="password"
                label={FORM_LABEL_TEXTS.PASSWORD}
                id={'password'}
                placeHolder={FORM_PLACEHOLDER_TEXTS.LOGIN_PASSWORD}
                infoText={FORM_LABEL_TEXTS.VALID_PASSWORD_TEXT}
                onChangeHandler={(e) => onChangePassword(e)}
                validate={isValidTextString}
                isDisabled={isLoading}
                isReadOnly={isLoading}
                isError={isSubmitError}
                isFieldError={isPasswordError}
                isMandatory={true}
                isNumber={false}
                onKeyUpHandler={(e) => handleKeypress(e)}
            />
            {isLoginError && <p className={cx([globalClasses.Error, 'mt-0'])}> {isLoginErrorMsg}</p>}

            <p className={cx(['mb-0', classes.SigninText])}>
                <span
                    onClick={() => { props.history.push(ROUTE_CONSTANTS.RESET_PASSWORD) }}
                >
                    Forgot Password?
                </span>
            </p>

            {/* </div> */}


            <div className=" mt-sm-4 ">
                <Button
                    label={"Login"}
                    className={cx(['col-12'])}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                    onClickHandler={() => onLoginClicked()}
                />

                <div className={cx(["mt-3 text-center", classes.SigninText])}>
                    Don't have an account?&nbsp;
                    <span
                        onClick={() => { props.history.push(ROUTE_CONSTANTS.TRY_FOR_FREE) }}
                    >
                        Register
                    </span>
                    &nbsp;here
                </div>
            </div>
        </>

    }
    else {
        formComponent = <VerifyEmail email={email} password={password} route={props.history} />
    }

    return (
        <div className={classes.TryForFreeBack}>
            <ComponentHelmet
                title={[PAGE_TITLE.LOGIN.TITLE]}
                description={PAGE_TITLE.LOGIN.DESCRIPTION}
                path={process.env.REACT_APP_DOMAIN_URL + window.location.pathname}
            />
            {isLoading && <Loader containerClass={classes.Loading} />}
            {formComponent}
        </div>

    )
}
export default Login;
