import { getDateFromInput, isEmptyList } from '../../../../utils/UtilityFunctions';
import Loader from '../../../../components/loader/Loader';
import ErrorPage from '../../../../components/error_page/ErrorPage';
import NoData from '../../../../components/no_data/NoData';
import SortingTable from '../../../../components/sorting_table/SortingTable';
import { SORT_TABLE_VALUES } from '../../../../utils/Constants';
import { SEPARATORS } from '../../../../strings/Strings';


function UserInvites(props) {

    let { userInvitesData, isUserInvitesAPIError, isUserInvitesAPILoading } = props

    let userInvitesContainer = null

    if (isUserInvitesAPILoading) {
        userInvitesContainer = <Loader />
    }
    else {
        if (isUserInvitesAPIError) {
            userInvitesContainer = <ErrorPage />
        }
        else if (isEmptyList(userInvitesData)) {
            userInvitesContainer = <NoData mainText={'No User Invites available'} subText={''} />
        } else {
            userInvitesContainer = (
                <SortingTable
                    enableExports={true}
                    exportTableName={["Superadmin","Pending Invitees"].join(SEPARATORS.UNDERSCORE)}
                    exportKeysList={
                        [
                            {
                                key: 'id',
                                label: 'User ID'
                            }, {
                                key: 'displayName',
                                label: 'Display Name'
                            }, {
                                key: 'email',
                                label: 'Email ID'
                            }, {
                                key: 'companyName',
                                label: 'Company Name'
                            },
                            {
                                key: 'createdOn',
                                label: 'Invited On',
                                getValue: (item) => getDateFromInput(item.createdOn)
                            }
                        ]
                    }
                    headingList={[{
                        key: 'id',
                        label: 'User ID',
                        isDate: false
                    }, {
                        key: 'displayName',
                        label: 'Display Name',
                        isDate: false
                    }, {
                        key: 'email',
                        label: 'Email ID',
                        isDate: false
                    }, {
                        key: 'companyName',
                        label: 'Company Name',
                        isDate: false
                    }, {
                        key: 'userStatus',
                        label: 'Status',
                        isDate: false,
                        hide: true
                    }, {
                        key: 'createdOn',
                        label: 'Invited On',
                        isDate: true
                    }]}
                    enableSearch = {true}
                    searchKeysList = {
                        [
                            {
                                key : "id",
                                label : "User ID",
                                value : "User ID",
                                placeholder : "Search by User ID"
                            },
                            {
                                key : "displayName",
                                label : "Display Name",
                                value : "Display Name",
                                placeholder : "Search by Display Name"
                            },
                            {
                                key : "email",
                                label : "Email ID",
                                value : "Email ID",
                                placeholder : "Search by Email ID"
                            },
                            {
                                key : "companyName",
                                label : "Company Name",
                                value : "Company Name",
                                placeholder : "Search by Company Name"
                            }
                        ]
                    }
                    dataList={userInvitesData}
                    itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.PENDING_INVITEES}
                    noteText = {SORT_TABLE_VALUES.TABLE_NOTES.DATE_FORMAT2}
                />
            )
        }
    }


    return (
        <>{userInvitesContainer}</>
    )
}


export default UserInvites;