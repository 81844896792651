import { Component } from "react";

import cx from 'classnames';
import globalClasses from '../../../App.module.css';
import classes from '../UserManagement.module.css';
import { IoMdAddCircle } from "react-icons/io";
import { BiLink } from "react-icons/bi";
import { getDollarFromNumber, getPlanDetails, getWesternNumber, isEmpty, isValidTextString, isValidTopup,
getUTCTime, isEmptyObject, 
isEmptyList} from "../../../utils/UtilityFunctions";
import { handleErrorCreditReq, handleErrorServiceReq, handleSuccessCreditReq, handleSuccessServiceReq } from "../UserManagement.utils";
import InlineForm from "../../../components/inline_form/InlineForm";
import { CREDIT_PER_USD, INPUT_TYPES, MIN_TOPUP_AMOUNT, MIN_TOPUP_UNIT, SUBSCRIBE_REQUEST_TYPE, 
    TEXTAREA_LENGTH, VALIDATION_REGEX, REQUEST_TYPE, USER_ROLE, USER_MANAGEMENT, ENABLE_RENEW_BEFORE, 
    EXPIRY_DAY, TOPUP_UNIT_AMOUNT, MAX_CREDITS_REQUIRED, MAX_ADDITION_MANAGE_TOPUP, SUBSCRIPTION_TYPE, 
    STRIPE_THRESHOLD_AMOUNT, EVENT, MIN_TOPUP_UNIT_MANAGE } from "../../../utils/Constants";
import { BUTTON_TEXTS, EMPTY_STRING, ERROR_TEXTS, SEPARATORS, PAYMENT_STATUS } from "../../../strings/Strings";
import axios from "axios";
import { CREATE_CHANGE_SUBSCRIPTION, CREATE_TRANSACTION, POST_PROF_SERVICE, POST_RENEWAL, POST_TOPUP, RENEW_SUBSCRIPTION_PACKAGE, STRIPE_CREATE_ORDER, UPDATE_CHANGE_SUBSCRIPTION, UPDATE_TRANSACTION } from "../../../urls/Urls";
import { PLAN_INITIAL_STATE } from "../UserManagement.utils";
import Card from "../../../components/ui_elements/card/Card";
import Button from "../../../components/ui_elements/button/ButtonNew";
import { ROUTE_CONSTANTS } from "../../../routes/RouteConstants";
import AccountCreationPopup from "../AccountCreatePopup";
import { USER_MNGMENT_STRINGS } from "../../../strings/UserManagement";
import TextField from "../../../components/ui_elements/form_fields/text_input/TextField";
import SuccessScreen from "../../../components/success_screen/SuccessScreen";
import { PRICING } from "../../../strings/Pricing";
import { PRICING_PLANS } from "../../pricing/data";
import CheckBoxInput from "../../../components/ui_elements/form_fields/checkbox_input/CheckBoxInput";
import Popup from "../../../components/ui_elements/popup/Popup";
import ModalCard from "../../../components/ui_elements/modal_card/ModalCard";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField";
import ConfirmPopup from "../../../components/confirm_popup/ConfirmPopup";
import { getCognitoUserAccessToken } from "../../sign_up/Cognito.utils";

import { AuditLog } from  "../../../../src/utils/AuditUtils"
import { BsFillCircleFill } from "react-icons/bs";
import { BUTTON_TYPES } from "../../../utils/ButtonConstants";
class PlanPane extends Component{
    constructor(props){
        super(props);
        this.state= {
            ...PLAN_INITIAL_STATE
        }
    }

    validateCredit = (value) => {
        let topupUnit = value;
        if (isEmpty(value) || (Number(topupUnit) < MIN_TOPUP_UNIT_MANAGE))
            return false;
        return true
    }

    onChangeTopupHandler = (value) => {
        let topupUnit = value;
        if(topupUnit > MAX_ADDITION_MANAGE_TOPUP){
            topupUnit = MAX_ADDITION_MANAGE_TOPUP
        }
        this.setState({
            topupUnit,
            errorCredit : false
        })
    }

    onDecreaseHandler = () => {
        let {topupUnit} = this.state;
        this.setState({
            topupUnit : (Number(topupUnit) - 5) < MIN_TOPUP_UNIT_MANAGE ? MIN_TOPUP_UNIT_MANAGE : (Number(topupUnit) - 5),
            errorCredit : false
        })
    }

    onIncreaseHandler = () => {
        let {topupUnit} = this.state;
        this.setState({
            topupUnit : (Number(topupUnit) + 5) > MAX_ADDITION_MANAGE_TOPUP ? MAX_ADDITION_MANAGE_TOPUP : (Number(topupUnit) + 5),
            errorCredit : false
        })
    }

    onBlurTopupHandler = (value,isError) => {
        let creditErrorText = EMPTY_STRING;
        let errorCredit = false;
        if (isError) {
            creditErrorText = "Minimum top-up unit is "+MIN_TOPUP_UNIT_MANAGE+" unit";
            errorCredit = true;
        } else {
            creditErrorText = EMPTY_STRING;
            errorCredit = false;
        }
        this.setState({
            errorCredit,
            creditErrorText
        })
    }

    validatePurpose = (value) => {
        if(isEmpty(value) || value.length < TEXTAREA_LENGTH.MINIMUM
            || value.length > TEXTAREA_LENGTH.MAXIMUM){
                return false;
            }
        return true;
    }

    onChangePurpose = (value) => {
        this.setState({
            purposeText : value
        })
    }

    onBlurPurpose = (value, isError) => {
        let purposeErrorText = EMPTY_STRING;
        let errorPurpose = isError;
        if(isError){
            if(isEmpty(value)){
                purposeErrorText = ERROR_TEXTS.SERVICE_REQUEST_EMPTY_FIELD
            } else if(value.length < TEXTAREA_LENGTH.MINIMUM){
                purposeErrorText = ERROR_TEXTS.MINIMUM_CHAR_IN_TEXTAREA
            } else {
                purposeErrorText = ERROR_TEXTS.EXCEEDED_CHAR_IN_TEXTAREA
            }
        }
        this.setState({
            purposeErrorText,
            errorPurpose
        })
    }

    onClickCancelCreditReq = () => {
        this.setState({
            showAddCredit : false,
            topupUnit : 0,
            errorCredit : false,
            creditErrorText : EMPTY_STRING,
            isCreditReqSuccess : false,
            errorSubmitCredit: false
        })
    }

    onClickCancelServiceReq = () => {
        this.setState({
            isProfServiceReqEnabled : false,
            purposeText : EMPTY_STRING,
            errorPurpose : false,
            purposeErrorText : EMPTY_STRING,
            isServiceReqSuccess : false
        })
    }

    // checkCredit = () => {
    //     let { creditBalance } = this.props;
    //     if(Number(creditBalance) <= MAX_CREDITS_REQUIRED){
    //         this.showCreditRequestHandler();
    //     } else {
    //         this.setState({
    //             showConfirmCreditPopup : true
    //         })
    //     }
    // }

    closeConfirmCreditPopup = () => {
        if(document.body.style.overflow !== 'auto'){
            document.body.style.overflow = 'auto'
        }
        this.setState({
            showConfirmCreditPopup : false
        })
    }

    showCreditRequestHandler = () => {
        if(document.body.style.overflow !== 'auto'){
            document.body.style.overflow = 'auto'
        }
        this.setState({
            showAddCredit : true,
            showConfirmCreditPopup : false
        }, () => {
            if(this.state.isProfServiceReqEnabled){
                this.onClickCancelServiceReq()
            }
        })
    }

    onChangeReqOptionHandler = () => {
        this.setState({
            isProfServiceReqEnabled : !this.state.isProfServiceReqEnabled,
            purposeText : EMPTY_STRING,
            errorPurpose : false,
            purposeErrorText : EMPTY_STRING
        }, () => {
            if(this.state.showAddCredit){
                this.onClickCancelCreditReq()
            }
        })
    }

    onSubmitCreditRequest = () => {
        let { accountID, username, accountUUID } = this.props;
        let { topupUnit } = this.state;
        let errorCredit = isValidTopup(topupUnit, 0, MAX_ADDITION_MANAGE_TOPUP, false).error
        if (!errorCredit) {
            this.setState({
                isSubmittingCreditReq: true,
                requestType: REQUEST_TYPE.TOPUP,
                errorSubmitCredit: false
            }, () => {
                let amount = MIN_TOPUP_AMOUNT + (TOPUP_UNIT_AMOUNT * Number(topupUnit))
                let credit = amount * CREDIT_PER_USD
                let data = {
                    account_uuid : accountUUID,
                    account_id: accountID,
                    amount,
                    credit,
                    transaction_type: USER_MANAGEMENT.TRANSACTION_TYPE.ADD_CREDITS,
                    user: username,
                }
                let products = [];

                let product =
                {
                    Product: "Top-up",
                    Price: String(parseInt(Number(amount * 100).toFixed(2)))
                }
                products.push(product);

                let requestData = {
                    CancelURL: window.location.href,
                    SuccessURL: window.location.href,
                    SuccessText: 'Top-up has been successfull, refresh the page and continue with your updated balance.',
                    Data: data,
                    DataBaseEntryAPI: CREATE_TRANSACTION,
                    DataBaseUpdateAPI: UPDATE_TRANSACTION
                }

                //#region - audit log
                let startDateTime = new Date()

                // audit log - common properties
                let eventDetails = EVENT.PAYMENT_TRANSACTION_CATEGORY.ADD_CREDITS
                eventDetails = {
                    ...eventDetails,
                    eventCategory: EVENT.PAYMENT_TRANSACTION_CATEGORY.CATEGORY_NAME,
                    eventStartDateTime: startDateTime
                }

                let additionalDetails = {...data}

                //#endregion

                axios.post(STRIPE_CREATE_ORDER, { requestData, products },
                    {
                        headers: {
                            Authorization: "Bearer " + getCognitoUserAccessToken()
                        }
                    })
                    .then(res => {
                        console.log('FRONT END')
                        console.log(JSON.stringify(res.data));

                        //audit log - success scenario
                        if (res) {
                            eventDetails = {
                                ...eventDetails,
                                eventStatusCode: res?.status || '200',
                                eventStatus: 'Success',
                                eventMessage: res?.data?.message || res?.statusText || 'Add additional credits success'
                            }
                        }

                        // if (amount > STRIPE_THRESHOLD_AMOUNT) {
                        //     window.location.replace(window.location.href + "?request=success")
                        // } else {
                        //     window.open(res.data.url, '_self');
                        // }
                        window.location.replace(window.location.href + "?request=success")

                    })
                    .catch(err => {
                        console.log(JSON.stringify(err));
                        console.log(err);
                        console.log(err.data);
                        this.setState({
                            ...handleErrorCreditReq(err)
                        })
                        if (err) {
                            //audit log - error scenario
                            eventDetails = {
                                ...eventDetails,
                                eventStatusCode: err?.response?.status || null,
                                eventStatus: 'Failure',
                                eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Add additional credits error',
                            }
                        }
                    }).finally(() => {
                        //call audit log method
                        this.insertToAuditLog(eventDetails, additionalDetails)
                    })
            })
        } else {
            this.setState({
                errorCredit,
                errorSubmitCredit: true
            })
        }
    }

    saveToDatabase = (api, data) => {
        if(api){
                axios.post(api, data)
                .then(response => {
                    if(this.state.requestType === REQUEST_TYPE.TOPUP){
                        this.setState({
                            ...handleSuccessCreditReq()
                        }, () => {
                            setTimeout(this.onClickCancelCreditReq,5000)
                        })
                    }
                    else
                    {
                        this.setState({
                            isSubmitting: false,
                            isSubmissionSuccess: true
                        })
                    }        
                })
                .catch(err => {
                    if(this.state.requestType === REQUEST_TYPE.TOPUP){
                        this.setState({
                            ...handleErrorCreditReq()
                        }, () => {
                            this.onClickCancelCreditReq()
                        })
                    } else {
                        this.setState({
                            isSubmitting : false,
                            isSubmissionSuccess : false
                        });
                    }
                });
        }
    }

    //add-on or professional service request
    onSubmitServiceRequest = () => {
        let { username, accountUUID } = this.props;
        let { purposeText } = this.state;
        if(this.validatePurpose(purposeText)){
            this.setState({
                isSubmittingServiceReq : true
            }, () => {
                let data = {
                    accountId : accountUUID,
                    message : purposeText,
                    requestedBy : username,
                }

                //#region - audit log
                let startDateTime = new Date()

                // audit log - common properties
                let eventDetails = EVENT.MANAGE_CATEGORY.ADD_ON_REQUEST
                eventDetails = {
                    ...eventDetails,
                    eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
                    eventStartDateTime: startDateTime
                }

                let additionalDetails = {...data}
                //#endregion

                axios.post(POST_PROF_SERVICE,data)
                .then(res => {
                    this.setState({
                        ...handleSuccessServiceReq()
                    }, () => {
                        setTimeout(this.onClickCancelServiceReq,5000)
                    })
                    //audit log - success scenario
                    if (res) {
                        eventDetails = {
                            ...eventDetails,
                            eventStatusCode: res?.status || '200',
                            eventStatus: 'Success',
                            eventMessage: res?.data?.message || res?.statusText || 'Add-on or professional service request success'
                        }
                    }
                })
                .catch(err => {
                    //audit log - error scenario
                    if (err) {
                        eventDetails = {
                            ...eventDetails,
                            eventStatusCode: err?.response?.status || null,
                            eventStatus: 'Failure',
                            eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Add-on or professional service error',
                        }
                    }
                    this.setState({
                        ...handleErrorServiceReq()
                    }, () => {
                        this.onClickCancelServiceReq()
                    })
                }).finally(() => {
                    this.insertToAuditLog (eventDetails, additionalDetails)
                })
            })
        } else {
            this.onBlurPurpose(purposeText,true);
        }
    }

    onChnageSubscriptionType = (planName,tenureTypeID) => {
        if(isEmpty(planName)){
            this.setState({
                showRequestPopup : true,
                selectedPlan: {},
                planType: EMPTY_STRING,
                tenureTypeID: 0,
                baseCharge: "USD 0.00",
                minCredits: 0,
                isSubmitting: false,
                isSubmissionSuccess: false,
                isPlanError : false
            })
        } else {
            PRICING_PLANS.map((plan) => {
                if (plan.type === planName) {
                    this.setState({
                        showRequestPopup : true,
                        selectedPlan: plan,
                        planType: plan.type,
                        tenureTypeID,
                        baseCharge: this.getBaseCharge(plan,tenureTypeID),
                        minCredits: plan.credits,
                        isSubmitting: false,
                        isSubmissionSuccess: false,
                        isPlanError : false
                    })
                }
                return null
            })
        }
    }

    onClickRenewPlan = () => {
        let { subscriptionType } = this.props;
        let planDetails = getPlanDetails(subscriptionType);
        this.setState({
            isPlanRenewed : true
        })
        this.onChnageSubscriptionType(planDetails.planName,planDetails.planTenure.VALUE)
    }

    onClickChangePlan = () => {
        this.setState({
            isPlanRenewed : false
        })
        this.onChnageSubscriptionType(EMPTY_STRING,0)
    }

    onChangePlanType = (type) => {
        this.onChnageSubscriptionType(type,1)
    }

    onChangeTenureType = (type) => {
        let {selectedPlan} = this.state;
        let tenureTypeID = Number(type)
        let baseCharge = this.getBaseCharge(selectedPlan,tenureTypeID)
        this.setState({
            baseCharge,
            tenureTypeID
        })
    }

    getBaseCharge = (selectedPlan,tenureTypeID) => {
        switch(tenureTypeID){
            case 1: return selectedPlan.month
            case 3: return selectedPlan.quarter
            case 6: return selectedPlan.half
            case 12: return selectedPlan.annual
            default: return selectedPlan.month
        }
    }

    onChangeRenewOption = () => {
        let { isPlanRenewed } = this.state;
        if(!isPlanRenewed){
            this.onClickRenewPlan()
        } else  {
            this.onClickChangePlan()
        }
    }

    onCancelChangeRenewPlan = () => {
        let { isSubmitting } = this.state;
        if(!isSubmitting){
            if (document.body.style.overflow === 'hidden') {
                document.body.style.overflow = 'auto'
            }
            this.setState({
                showRequestPopup : false,
                isPlanRenewed : true
            })
        }
    }

    onSubscribeConfirmationClickHandler = () => {
        if (document.body.style.overflow === 'hidden'){
            document.body.style.overflow = 'auto';
        }
        this.setState({
            isSubmitting: true
        }, () => {
            let { accountID, username, accountUUID } = this.props
            let { isPlanRenewed, planType, baseCharge, tenureTypeID } = this.state;
            let isPlanError = isValidTextString(planType,true).error
            if(!isPlanError){
                let requestType = isPlanRenewed ? SUBSCRIBE_REQUEST_TYPE.RENEW : SUBSCRIBE_REQUEST_TYPE.CHANGE;
                let amount = planType === PRICING.PLANS.PLAN_1 ? "" : (Number(baseCharge.split(SEPARATORS.SPACE)[1]) * tenureTypeID).toFixed(2)
                let subscriptionTypeName = [planType,(tenureTypeID > 1)? tenureTypeID : ''].join('');
                let renewalData = {
                    account_uuid : accountUUID,
                    account_id: accountID,
                    subscription_type: subscriptionTypeName,
                    user: username,
                    amount
                }
                let requestData = {}
                let products = [];
                let eventDetails = {}

                if(requestType === SUBSCRIBE_REQUEST_TYPE.CHANGE){
                    renewalData = {
                        ...renewalData,
                        transaction_type : USER_MANAGEMENT.TRANSACTION_TYPE.CHANGE_PLAN
                    }
                    let product = 
                    {
                        Product: "Change : " + planType,
                        Price: String(parseInt(Number(Number(amount)*100).toFixed(2)))
                    }
                    products.push(product);
                    requestData = {
                        CancelURL: window.location.href,
                        SuccessURL: window.location.href,
                        SuccessText: 'Subscription plan change has been successfull.',
                        Data: renewalData,
                        DataBaseEntryAPI: CREATE_CHANGE_SUBSCRIPTION,
                        DataBaseUpdateAPI: UPDATE_CHANGE_SUBSCRIPTION
                    }

                    eventDetails = EVENT.PAYMENT_TRANSACTION_CATEGORY.CHANGE_PLAN
                }
                else if(requestType === SUBSCRIBE_REQUEST_TYPE.RENEW){
                    renewalData = {
                        ...renewalData,
                        transaction_type : USER_MANAGEMENT.TRANSACTION_TYPE.RENEW_ACCOUNT
                    }
                    let product = 
                    {
                        Product: "Renew : " + planType,
                        Price: String(parseInt(Number(Number(amount)*100).toFixed(2)))
                    }
                    products.push(product);
                    requestData = {
                        CancelURL: window.location.href,
                        SuccessURL: window.location.href,
                        SuccessText: 'Renew has been successfull.',
                        Data: renewalData,
                        DataBaseEntryAPI: CREATE_TRANSACTION,
                        DataBaseUpdateAPI: RENEW_SUBSCRIPTION_PACKAGE
                    }
                    eventDetails = EVENT.PAYMENT_TRANSACTION_CATEGORY.RENEW_PLAN
                }

                //#region - audit log
                let startDateTime = new Date()
                
                // audit log - common properties
                eventDetails = {
                    ...eventDetails,
                    eventCategory: EVENT.PAYMENT_TRANSACTION_CATEGORY.CATEGORY_NAME,
                    eventStartDateTime: startDateTime
                }

                let additionalDetails = {
                    ...renewalData
                }
                //#endregion

                axios.post(STRIPE_CREATE_ORDER, {requestData, products},
                    {
                        headers:{
                            Authorization: "Bearer "+getCognitoUserAccessToken()
                        }
                    })
                    .then(res => {
                        console.log('FRONT END')
                        console.log(JSON.stringify(res.data));
                        //audit log - success scenario
                        if (res) {
                            eventDetails = {
                                ...eventDetails,
                                eventStatusCode: res?.status || '200',
                                eventStatus: 'Success',
                                eventMessage: res?.data?.message || res?.statusText || 'Subscription change or renew plan success'
                            }
                        }

                        // if(Number(amount) > STRIPE_THRESHOLD_AMOUNT){
                        //     window.location.replace(window.location.href+"?request=success")
                        // } else {
                        //     window.open(res.data.url, '_self'); 
                        // }
                        window.location.replace(window.location.href+"?request=success")
                        
                    })
                    .catch(err => {
                        console.log(JSON.stringify(err));
                        console.log(err);
                        console.log(err.data);
                        this.setState({
                            isSubmitting : false,
                            isSubmissionSuccess : false
                        });
                        if(err){
                            eventDetails = {...eventDetails,
                                eventStatus: 'Failure',
                                eventStatusCode: err?.response?.status || null,
                                eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Subscription change or renew error',
                            }
                        }
                    }).finally(() => {
                        //call audit log method
                        this.insertToAuditLog(eventDetails, additionalDetails)
                    })
            } else {
                this.setState({
                    isPlanError,
                    isSubmitError: true,
                    isSubmitting: false
                })
            }
        });
    }

    insertToAuditLog = (eventDetails, additionalDetails = {}) => {
        console.log('Props: ', this.props)
        let { userDetails, accountUUID } = this.props
        let accountDetails = {}                  
        let eventAttributes = {}
        let user = {}

        if (accountUUID) {
            accountDetails = {accountUUID: accountUUID}
        }

        if (!isEmptyObject(userDetails)) {
            user = {
                awsId: userDetails?.awsId,
                email: userDetails?.email,
            }
        }

        eventAttributes = {...user, ...additionalDetails}

        AuditLog(eventDetails, eventAttributes, accountDetails)
    }
    
    render(){
        let {subscriptionType, accountName, companyName, accountUUID, usersCount, gotoSupport, subscriptions,
            apiKeysCount, userRole, gotoUsers, gotoAPIKeys, accountIsActive, userDetails,unsubscriptionID,accountActiveStatus } = this.props;
        let {showAddCredit, isCreditReqSuccess, isProfServiceReqEnabled, isServiceReqSuccess,
            isSubmittingCreditReq, isSubmittingServiceReq, topupUnit, creditErrorText, errorCredit,
            purposeErrorText, purposeText, errorPurpose, showRequestPopup, planType, errorSubmitCredit, 
            isSubmissionSuccess, isSubmitting, baseCharge, minCredits, tenureTypeID, isPlanRenewed, showConfirmCreditPopup,
            isPlanError, isSubmitError } = this.state;
        let labelStyle = cx(['col-12 col-md-5 mb-3 mb-md-0',globalClasses.Font18MediumDarkerBlue])
        let addButtonStyle = cx(['mt-4 p-2',classes.AddCreditButton])
        let planDetails = getPlanDetails(subscriptionType)
        let planName = planDetails.planName;
        let planTenure = planDetails.planTenure;

        let creditInlineFormComponent = null;
        let serviceReqInlineFormComponnet = null;
        let addCreditButton = null;
        let requestButton = null;
        let topupCreditComponent = null;
        let professionServiceAdonComponent = null;
        let changeSubscriptionComponent = null;
        let requestComponent = null;

        if(showRequestPopup){
            if(isSubmissionSuccess){
                requestComponent = (
                    <Popup className={globalClasses.PopupBack}>
                        <ModalCard
                            className={'col-11 col-md-8 col-lg-6 p-3'}
                            isPopupFixed={true} 
                            isForm={true}
                            onClose={this.onCancelChangeRenewPlan}
                        >
                            <SuccessScreen
                                className={classes.SuccessScreenPopup}
                                onClickHandler={this.onCancelChangeRenewPlan}
                                mainText={"Thank you"} 
                                subText={"Your subscription request has been submitted successfully, we will reach out to you soon."}
                                buttonText={BUTTON_TEXTS.DONE}
                            /> 
                        </ModalCard>
                    </Popup>
                )
            } else {
                let innerComponent = null;
                let selectPlanDiscount = 0;
                if(tenureTypeID === 3){
                    selectPlanDiscount = 5;
                } else if(tenureTypeID === 6) {
                    selectPlanDiscount = 8;
                } else if(tenureTypeID === 12) {
                    selectPlanDiscount = 12;
                }
                innerComponent = (
                    <>
                        <div className="my-3 d-flex flex-wrap align-items-md-center">
                            <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-3'])} htmlFor="accountname">
                                {"Account Name"}
                            </label>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    containerClass={"col-12"}
                                    value={accountName}
                                    placeHolder={'Account name'}
                                    isDisabled={true}
                                    // isReadOnly={isSubmitting}
                                    id={'accountname'}
                                />
                            </div>
                        </div>
                        {
                            planName !== PRICING.PLANS.PLAN_1 &&
                            <div className="my-3 d-flex align-items-md-center">
                                <CheckBoxInput
                                    containerClass={"d-flex col-12 col-lg-8"}
                                    isChecked={isPlanRenewed}
                                    isDisabled={isSubmitting}
                                    isReadOnly={true}
                                    id={'renewplan'}
                                    onChangeHandler={this.onChangeRenewOption}
                                    label={'Renew current subscription plan'}
                                />
                            </div>
                        }
                        <div className="my-3 d-flex flex-wrap align-items-md-center">
                            <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="plantype">
                                {"Plan Type"}
                            </label>
                            <SelectField
                                containerClass={"d-flex flex-column col-12 col-lg-8"}
                                value={planType}
                                placeHolder={'Plan type'}
                                isDisabled={isSubmitting || isPlanRenewed}
                                isReadOnly={isSubmitting || isPlanRenewed}
                                id={'plantype'}
                                isError={isSubmitError}
                                isFieldError={isPlanError}
                                validate={isValidTextString}
                                isMandatory={true}
                                optionsList={[
                                    {value:EMPTY_STRING,label:"-- Select a plan --"},
                                    {value:PRICING.PLANS.PLAN_4,label:PRICING.PLANS.PLAN_4,hide:((planName===PRICING.PLANS.PLAN_4 && !isPlanRenewed))},
                                    {value:PRICING.PLANS.PLAN_3,label:PRICING.PLANS.PLAN_3,hide:(planName===PRICING.PLANS.PLAN_3 && !isPlanRenewed)},
                                    {value:PRICING.PLANS.PLAN_2,label:PRICING.PLANS.PLAN_2,hide:(planName===PRICING.PLANS.PLAN_2 && !isPlanRenewed)}
                                ]}
                                onChangeHandler={this.onChangePlanType}
                                // disableErrorText={true}
                            />
                        </div>
                        {
                            !isEmpty(planType) &&
                            <>
                                    <div className="my-4 d-flex flex-wrap align-items-md-center">
                                    <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="tenuretype">
                                        {"Subscription Tenure"}
                                    </label>
                                    <div className="col-12 col-lg-8">
                                        <SelectField
                                            containerClass={"col-12"}
                                            value={tenureTypeID}
                                            placeHolder={'Tenure type'}
                                            isDisabled={isSubmitting}
                                            isReadOnly={isSubmitting}
                                            id={'tenuretype'}
                                            optionsList={[
                                                {value:1,label:'1 month'},
                                                // {value:3,label:'3 months'},
                                                {value:6,label:'6 months'},
                                                {value:12,label:'12 months'}
                                            ]}
                                            onChangeHandler={this.onChangeTenureType}
                                            disableErrorText={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3 mb-4 d-flex flex-wrap align-items-md-center">
                                    <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0 d-inline-flex flex-lg-column'])} htmlFor="plantype">
                                        <span>{"Base Charge"}</span>
                                        <span className={cx(['ms-2 ms-lg-0 align-self-end align-self-lg-start',globalClasses.Font14Grey])}>
                                            {selectPlanDiscount ? `(includes ${selectPlanDiscount}% discount)` :""}
                                        </span>
                                    </label>
                                    <span>
                                        {baseCharge} {planType !== PRICING.PLANS.PLAN_1? 'per month' : ''}
                                    </span>
                                </div>
                                {/* <div className="mt-3 mb-4 d-flex flex-wrap align-items-md-center">
                                    <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="plantype">
                                        {"Amount Addition"}
                                    </label>
                                    <span>
                                        {getDollarFromNumber((Number(minCredits)).toFixed(2))}
                                    </span>
                                </div> */}
                            </>
                        }
                        <div className="my-3 d-flex flex-wrap align-items-md-center">
                            <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="plantype">
                                {"Total"}
                            </label>
                            <span>
                                {getDollarFromNumber((Number(baseCharge.split(SEPARATORS.SPACE)[1]) * tenureTypeID).toFixed(2))}
                            </span>
                        </div>
                    </>
                )
                requestComponent = (
                    <AccountCreationPopup
                        title={planName === PRICING.PLANS.PLAN_1? 
                            USER_MNGMENT_STRINGS.PLAN_CHANGE_MODAL_TITLE
                            : USER_MNGMENT_STRINGS.PLAN_RENEW_CHANGE_MODAL_TITLE }
                        onCloseHandler={this.onCancelChangeRenewPlan}
                        onSubmitHandler={this.onSubscribeConfirmationClickHandler}
                        isSubmitting={isSubmitting}
                        submittingText={USER_MNGMENT_STRINGS.SUBMITTING}
                        submitButtonText={(Number(baseCharge.split(SEPARATORS.SPACE)[1]) * tenureTypeID)>5000?BUTTON_TEXTS.REQUEST_PAYMENT:BUTTON_TEXTS.REQUEST_PAYMENT}
                    >
                        {innerComponent}
                    </AccountCreationPopup>
                )
            }
        }

        if(!SUBSCRIPTION_TYPE.TRIAL.includes(subscriptionType) && userRole !== USER_ROLE.SUBSCRIBED.LABEL 
            && (accountIsActive === USER_MANAGEMENT.USER_STATUS.ACTIVE)){
            let confirmPopupComponent = null;
            // if(showAddCredit){
            //     creditInlineFormComponent = (
            //         <InlineForm
            //             containerClass={cx(['col-12 my-2',classes.InlineFormContainer])}
            //             // noteText = {'Note : 1 unit = USD 100.00 = 100,000 credits'}
            //             inputType={INPUT_TYPES.TOPUP}
            //             placeHolder = {""}
            //             onChangeHandler={this.onChangeTopupHandler}
            //             minValue={0}
            //             maxValue={MAX_ADDITION_MANAGE_TOPUP}
            //             validate={isValidTopup}
            //             isMandatory={false}
            //             onBlurHandler={this.onBlurTopupHandler}
            //             onIncreaseHandler={this.onIncreaseHandler}
            //             onDecreaseHandler={this.onDecreaseHandler}
            //             value={topupUnit}
            //             errorText={creditErrorText}
            //             isFieldError={errorCredit}
            //             isSubmitError={errorSubmitCredit}
            //             onClickCancel={this.onClickCancelCreditReq}
            //             onClickSubmit={this.onSubmitCreditRequest}
            //             primaryButtonText={BUTTON_TEXTS.REQUEST_PAYMENT}
            //             primaryButtonClass={"col-7 col-md-5"}
            //             isSubmitting={isSubmittingCreditReq}
            //             isSuccess={isCreditReqSuccess}
            //             addtionalComponent={
            //                 <div className="col-12">
            //                     <hr className={globalClasses.HorizontalLine} />
            //                     <div className="d-flex flex-column flex-md-row">
            //                         <div className={cx(["mb-3 mb-md-0",globalClasses.Font14Grey])}>
            //                             {/* <span>USD 1.00 = 1,000 credits</span><br/> */}
            //                             <span>Maximum topup amount is {getDollarFromNumber((MAX_ADDITION_MANAGE_TOPUP+MIN_TOPUP_AMOUNT).toFixed(2))}</span>
            //                         </div>
            //                         <div className="flex-grow-1 text-end">
            //                             <span className={cx([globalClasses.FontRobotoMedium,globalClasses.Font14DarkerGrey])}>Grand Total</span><br/>
            //                             <span className={globalClasses.Font24Bold}>{getDollarFromNumber(((topupUnit*TOPUP_UNIT_AMOUNT) + MIN_TOPUP_AMOUNT).toFixed(2))}</span><br/>
            //                             {/* <span className={globalClasses.Font14Grey}>( {getWesternNumber(((topupUnit*TOPUP_UNIT_AMOUNT) + MIN_TOPUP_AMOUNT) * CREDIT_PER_USD)} credits )</span> */}
            //                         </div>
            //                     </div>
            //                 </div>
            //             }
            //         />
            //     )
            // } else {
            //     addCreditButton = (
            //         <span className={addButtonStyle} onClick={this.checkCredit}>
            //             <IoMdAddCircle className="me-2" size={20}/> Add Top-up
            //         </span>
            //     )
            // }

            if(isProfServiceReqEnabled){
                serviceReqInlineFormComponnet = (
                    <InlineForm
                        containerClass={cx(['col-12 my-2',classes.InlineFormContainer])}
                        inputType={INPUT_TYPES.TEXT_AREA}
                        placeHolder = {'Could you please provide the use case for the service?'}
                        onChangeHandler={this.onChangePurpose}
                        validate={this.validatePurpose}
                        onBlurHandler={this.onBlurPurpose}
                        value={purposeText}
                        errorText={purposeErrorText}
                        isError={errorPurpose}
                        onClickCancel={this.onClickCancelServiceReq}
                        onClickSubmit={this.onSubmitServiceRequest}
                        isSubmitting={isSubmittingServiceReq}
                        isSuccess={isServiceReqSuccess}
                    />
                )
            } else {
                requestButton = (
                    <Button
                        containerClass={"col-4 mt-4"}
                        label = {"Request"}
                        onClickHandler = {this.onChangeReqOptionHandler}
                        actionType={BUTTON_TYPES.ACTIONS.SECONDARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                    />
                )
            }

            // if(showConfirmCreditPopup) {
            //     confirmPopupComponent = (
            //         <ConfirmPopup
            //             popupClass={globalClasses.PopupBack}
            //             modalClass={"col-11 col-md-9 col-lg-8 col-xl-6 p-3 p-md-4"}
            //             title={"Are you sure?"}
            //             subtext={<>You have <b>{getDollarFromNumber(Number(creditBalance).toFixed(3))}</b> in your account. Do you want to keep going?</>} 
            //             primaryButtonText={"Yes"}
            //             secondaryButtonText={"No"} 
            //             onClickPrimaryButton={this.showCreditRequestHandler}
            //             onClickSecondaryButton={this.closeConfirmCreditPopup} 
            //             isPopupFixed={true}
            //             enableScroll={false} 
            //             onCloseModalCard={this.closeConfirmCreditPopup}
            //             hideSecondaryButton={false}
            //         />
            //     )
            // }
            // topupCreditComponent = (
            //     <div className="my-3">
            //         {confirmPopupComponent}
            //         <div className="d-flex flex-column">
            //             {addCreditButton}
            //         </div>
            //         <div className="col-12 col-sm-8 col-md-12 col-lg-12 col-xl-12">
            //             {creditInlineFormComponent}
            //         </div>
            //     </div>
            // )
            professionServiceAdonComponent = (
                <div className="my-3">
                    <div className="d-flex flex-column">
                        <label className={cx(['form-check-label',globalClasses.Font18MediumDarkerBlue])} htmlFor='showRequestNeed'>
                            {'Do you need our professional service?'}
                        </label>
                        {requestButton}
                    </div>
                    <div className="col-12 col-sm-8 col-md-12 col-lg-12 col-xl-10">
                        {serviceReqInlineFormComponnet}
                    </div>
                </div>
            )
        }

        // if(userRole !== USER_ROLE.SUBSCRIBED.LABEL && remainingDays < planTenure.ENABLE_RENEW_BEFORE){
        //     changeSubscriptionComponent = (
        //         <Card className={cx(['px-4 py-4 mb-3',globalClasses.OrangeContainer])}>
        //             <div className="d-flex flex-wrap align-items-center">
        //                 <span className={cx(['flex-grow-1 col-12',globalClasses.Font24Bold])}>
        //                     {planName === PRICING.PLANS.PLAN_1? 
        //                         USER_MNGMENT_STRINGS.PLAN_CHANGE_TITLE
        //                         : USER_MNGMENT_STRINGS.PLAN_RENEW_CHANGE_TITLE }
        //                 </span>
        //                 <div className="row mt-4 col-12 col-sm-10 col-md-11 col-lg-10">
        //                     <div className="col-12 col-sm-6">
        //                         <Button
        //                             className="col-12"
        //                             label = {BUTTON_TEXTS.VIEW_PLAN}
        //                             onClickHandler = {() => this.props.history.push(ROUTE_CONSTANTS.PRICING)}
        //                         />
        //                     </div>
        //                     <div className="col-12 col-sm-6 mt-3 mt-sm-0">
        //                         {planName === PRICING.PLANS.PLAN_1? 
        //                             <Button
        //                                 className={cx(['col-12',globalClasses.PrimaryButton])}
        //                                 label = {BUTTON_TEXTS.PLAN_CHANGE}
        //                                 onClickHandler = {this.onClickChangePlan}
        //                             />
        //                             : 
        //                             <Button
        //                                 className={cx(['col-12',globalClasses.PrimaryButton])}
        //                                 label = {BUTTON_TEXTS.PLAN_CHANGE_RENEW}
        //                                 onClickHandler = {this.onClickRenewPlan}
        //                             />
        //                         }
        //                     </div>
        //                 </div>
        //                 {/* <span className={cx(['mt-3',globalClasses.Font14Grey])}>
        //                 Please open a <label className={cx([globalClasses.PointerCursor,globalClasses.Font14Orange])} onClick={gotoSupport}>support</label> ticket, if you want to change the plan.
        //                 </span> */}
        //             </div>
        //         </Card>
        //     )
        // }

        return(
            <div className="row flex-wrap">
                {
                    !isEmpty(unsubscriptionID) && accountActiveStatus!=3 &&
                    <div className="col-12 mb-3">
                        <Card className={cx(["col-12", globalClasses.Info])}>
                            {"Your request for unsubscription has been placed. The account will be deactivated by Vivas.AI once your current plan expires."}
                        </Card>
                    </div>
               }
               {
                    isEmpty(unsubscriptionID) && accountActiveStatus!=3 &&
                    <div className="col-12 mb-3">
                        <Card className={cx(["col-12", globalClasses.Info])}>
                            {"Ready to unlock more features? Consider upgrading your plan to access premium functionalities and enhance your experience with us. Visit our "}<a className={globalClasses.AnchorElement} href={ROUTE_CONSTANTS.PRICING} target="_blank" rel={"noopener noreferrer"}>{"pricing page"}</a>{" to explore our plans in detail."}
                        </Card>
                    </div>
               }
               {
                    !isEmptyList(subscriptions) &&
                    subscriptions.map((sub,index) => {
                        return (
                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Card className={cx(["col-12 p-4"])}>
                                    <h4 className={cx(globalClasses.FontGoogleSans,classes.ProductName)}>{sub?.productName}</h4>
                                    <div className="d-flex align-items-center flex-wrap my-3">
                                        <span className={labelStyle}>
                                            {'Plan'}
                                        </span>
                                        <span>
                                            {sub?.planName}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap my-3">
                                        <span className={labelStyle}>
                                            {'Status'}
                                        </span>
                                        <span>
                                            {
                                                Number(sub.isPlanActive) == USER_MANAGEMENT.USER_STATUS.INACTIVE ?
                                                    <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-expired)" />&nbsp;Expired</span>
                                                    : 
                                                    Number(sub.isPlanActive) == USER_MANAGEMENT.USER_STATUS.UNSUBSCRIBED ?
                                                    <span ><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="Red" />&nbsp;Closed</span>
                                                    :
                                                    <span><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-active)" />&nbsp;Active</span>
                                            }
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap my-3">
                                        <span className={labelStyle}>
                                            {'Balance'}
                                        </span>
                                        <span>
                                            {sub?.balance}&nbsp;{!isEmpty(sub?.balanceUnits) ? (sub?.balanceUnits === "USD") ? sub?.balanceUnits : sub?.balanceUnits+"(s)" : EMPTY_STRING}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap my-3">
                                        <span className={labelStyle}>
                                            {'Valid Up To'}
                                        </span>
                                        <span>
                                            {sub?.validityDate}
                                        </span>
                                    </div>
                                </Card>
                            </div>
                        )
                    })
               }
                {/* <div className="col-12 col-lg-6">
                    <Card className={cx(["col-12 p-4"])}>
                        <span className={globalClasses.Font24MediumBlue}>Basic Details</span>
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'Plan'}
                            </span>
                            <span>
                                {planName}
                            </span>
                        </div>
                        {
                            !SUBSCRIPTION_TYPE.TRIAL.includes(subscriptionType) &&
                            <div className="d-flex align-items-center flex-wrap my-3">
                                <span className={labelStyle}>
                                    {'Tenure'}
                                </span>
                                <span>
                                    {planTenure.LABEL}
                                </span>
                            </div>
                        }
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'Account Name'}
                            </span>
                            <span>
                                {accountName}
                            </span>
                        </div>
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'Account ID'}
                            </span>
                            <span>
                                {accountUUID}
                            </span>
                        </div>
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'Company Name'}
                            </span>
                            <span>
                                {companyName}
                            </span>
                        </div>
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'Users'}
                            </span>
                            <span>
                                {usersCount} <BiLink fontWeight={600} className={classes.TabLink} onClick={gotoUsers} title="View Users"/>
                            </span>
                        </div>
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'API Keys'}
                            </span>
                            <span>
                                {apiKeysCount} <BiLink className={classes.TabLink} onClick={gotoAPIKeys} title="View API Keys"/>
                            </span>
                        </div>
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'Valid upto'}
                            </span>
                            <span>
                                {accountValidity}
                            </span>
                        </div>
                        <div className="d-flex align-items-center flex-wrap my-3">
                            <span className={labelStyle}>
                                {'Balance'}
                            </span>
                            <span>
                                {creditBalance}&nbsp;Page(s)
                            </span>
                        </div>
                    </Card>
                </div> */}
                <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                    {changeSubscriptionComponent}
                    {requestComponent}
                    {
                        ((subscriptions.findIndex((subs) => !subs?.isFreePlan) >= 0)
                        && userRole !== USER_ROLE.SUBSCRIBED.LABEL) 
                        && (accountIsActive === USER_MANAGEMENT.USER_STATUS.ACTIVE)  &&
                        <>
                            {/* <Card className='p-4 mb-3'>
                                <span className={globalClasses.Font24MediumBlue}>Need to Top-up?</span> 
                                {topupCreditComponent}
                            </Card> */}
                            <Card className='p-4 mb-3'>
                                <span className={globalClasses.Font24MediumBlue}>Add-ons</span> 
                                {professionServiceAdonComponent}
                            </Card>
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default PlanPane;
