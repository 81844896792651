import classes from './AudioPlayer.module.css'
import cx from 'classnames'
import {
    BiPlayCircle,
    BiPauseCircle,
    BiVolumeFull,
    BiVolumeMute
} from 'react-icons/bi'
import { Component } from 'react';
import { isEmpty, getTimeCodeFromNum, getAudioFileContent } from '../../../utils/UtilityFunctions';
import { BsFillPauseCircleFill, BsFillPlayCircleFill } from 'react-icons/bs';
import { createRef } from 'react';
import { THEME } from '../../../utils/Constants';
import Loader from '../../loader/Loader';
import { HiDownload } from 'react-icons/hi';

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.customTimeOut = 0
        this.audioPlayerRef = createRef();
        this.state = {
            isAudioPlaying: false,
            volumeValue: 70,
            audioSrc: this.props.isURL ? this.props.audioSrc : getAudioFileContent(this.props.audioSrc.charAt(0)) + this.props.audioSrc,
            audio: null,
            duration: 0,
            currentTime: 0,
            rangeValue: 0,
            disableActionButton : false
        }
    }

    setToDefault = () => {
        // this.state.audio.currentTime = 0;
        let { audio } = this.state
        if(audio){
            audio.currentTime = 0
            audio.pause()
        }
        clearInterval(this.customTimeOut)
        // this.state.audio.pause();
        this.setState({
            isAudioPlaying: false,
            currentTime: 0,
            rangeValue: 0,
        })
    }

    playAudio = () => {
        if (!this.state.isAudioPlaying) {
            this.setState({
                isAudioPlaying: true,
            },
                () => {
                    this.state.audio.play()
                    this.customTimeOut = setInterval(this.getCurrentTime, 1000);
                }
            )
        }
    }

    pauseAudio = () => {
        if (this.state.isAudioPlaying) {
            this.setState({
                isAudioPlaying: false,
            },
                () => {
                    this.state.audio.pause()
                    clearInterval(this.customTimeOut)
                }
            )
        }
    }

    getCurrentTime = () => {
        let { duration } = this.props;
        let audioDuration = isEmpty(duration)? this.state.audio.duration : duration
        this.setState({
            currentTime: this.state.audio.currentTime,
            rangeValue: (this.state.audio.currentTime / audioDuration)
        })
    }

    muteAudio = () => {
        this.setState({
            volumeValue: 0
        },
            () => {
                let { audio } = this.state
                audio.volume = 0
            }

        )
    }

    unmuteAudio = () => {
        this.setState({
            volumeValue: 0.70
        },
            () => {
                let { audio } = this.state
                audio.volume = 0.70
                // this.state.audio.volume = 0.70
            }
        )
    }

    getAudioDuration = () => {
        this.setState({
            disableActionButton : true,
            duration : 0
        }, () => {
            let isURL = this.props.isURL;
            let audioDuration = this.props.duration
            let audio = new Audio(isURL? this.props.audioSrc : getAudioFileContent(this.props.audioSrc.charAt(0)) + this.props.audioSrc);
            audio.addEventListener('ended',this.setToDefault)
            audio.addEventListener('loadeddata', () => {
                this.setState({
                    audio,
                    duration: isEmpty(audioDuration) ? audio.duration : audioDuration,
                    currentTime: 0,
                    disableActionButton : false
                })
            })  
        })
    }

    setCurrentTime = (event) => {
        let { duration } = this.props;
        let audioDuration = isEmpty(duration)? this.state.audio.duration : duration
        let currentTime = ((event.target.value / 100) * audioDuration);
        this.setState({
            currentTime,
            rangeValue: event.target.value / 100,
        })
        let { audio } = this.state

        audio.currentTime = currentTime;
    }

    handleClickOutside = (event) => {
        let { isAudioPlaying } = this.state;
        if(isAudioPlaying){
            try{
                if(this.audioPlayerRef.current && !this.audioPlayerRef.current.contains(event.target)){
                    this.pauseAudio();
                }
            } catch(err) {
                console.log(err.code+" : "+err.message);
            }
        }
    }

    componentDidMount = () => {
        this.getAudioDuration();
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.audioSrc !== prevProps.audioSrc){
            this.getAudioDuration();
            this.setToDefault();
        }
    }

    componentWillUnmount = () => {
        let { audio } = this.state;
        this.setToDefault();
        if(audio){
            audio.removeEventListener('ended',this.setToDefault)
        }
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    render() {
        const { isAudioPlaying, currentTime, duration, rangeValue, volumeValue, disableActionButton, audioSrc } = this.state;
        const { className, previewMaximized, audioFileName, theme } = this.props;
        
        // if (rangeValue >= 1) {
        //     this.setToDefault();
        // }
        if(previewMaximized){
            let containerClass = classes.MaximizedLight;
            let pauseIconClass = classes.PreviewActionIcons
            let fileNameComponent = null;
            if(theme === THEME.DARK){
                containerClass = classes.MaximizedDark
                pauseIconClass = cx([classes.PreviewActionIcons,classes.PreviewActionIconsAnimation])
                fileNameComponent = (
                    <div className={cx(['col-12 text-center',classes.FileName])}>{audioFileName}</div>
                )
            }
            if(disableActionButton){
                return (
                    <Loader />
                )
            }
            return (
                <div className={cx(['d-flex flex-column',className,classes.AudioPlayerContainer,containerClass])}>
                    <div className='flex-grow-1 d-flex justify-content-center align-items-center'>
                        {
                            !isAudioPlaying && 
                            <button className={classes.PreviewActionButton} onClick={this.playAudio}>
                                <BsFillPlayCircleFill className={classes.PreviewActionIcons}/>
                            </button>
                        }
                        {
                            isAudioPlaying && 
                            <button className={classes.PreviewActionButton} onClick={this.pauseAudio}>
                                <BsFillPauseCircleFill className={pauseIconClass}/>
                            </button>
                        }
                       
                    </div>
                    {fileNameComponent}
                    <div className='col-12 d-flex px-3 py-5'>
                        <span>{getTimeCodeFromNum(currentTime)}</span>
                        <input className='flex-grow-1' type='range' value={rangeValue * 100} min={0} max={100} onChange={this.setCurrentTime} />
                        <span>{getTimeCodeFromNum(duration)}</span>
                    </div>
                </div>
            )
        }
        return (
            <div ref={this.audioPlayerRef}>
                {/* <audio controls
                    controlsList="nodownload"
                >
                    <source src={audioSrc} type="audio/wav"/>
                </audio> */}
                <div className={cx(['px-2',className,classes.AudioPlayerContainer])}>
                    {
                        !disableActionButton && 
                        <span>
                            {!isAudioPlaying && <BiPlayCircle className={classes.ControllerIcons} size={20} onClick={this.playAudio} />}
                            {isAudioPlaying && <BiPauseCircle className={classes.ControllerIcons} size={20} onClick={this.pauseAudio} />}
                        </span>
                    }
                    <div className='d-flex flex-grow-1 px-1'>
                        <span>{getTimeCodeFromNum(currentTime)}</span>
                        <input className='flex-grow-1' type='range' value={rangeValue * 100} min={0} max={100} onChange={this.setCurrentTime} />
                        <span>{getTimeCodeFromNum(duration)}</span>
                    </div>
                    <span>
                        {volumeValue > 0 && <BiVolumeFull className={classes.ControllerIcons} size={18} onClick={this.muteAudio} />}
                        {volumeValue === 0 && <BiVolumeMute className={classes.ControllerIcons} size={18} onClick={this.unmuteAudio} />}
                    </span>
                    <span>
                        <a href={audioSrc} download>
                            <HiDownload
                                className={classes.ControllerIcons}
                                size={18}
                            />
                        </a>
                    </span>
                </div>
            </div>
        )
    }
}

export default AudioPlayer;