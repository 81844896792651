import { EMPTY_STRING } from "../../../strings/Strings"
import { SUBSCPTION_TENURE_TYPE, SUBSCRIPTION_TYPE_IDS, USER_MANAGEMENT } from "../../../utils/Constants"
import { getAccountSubscriptions, getDateFromInput, getPlanDetails, isEmpty, isEmptyList, isEmptyObject } from "../../../utils/UtilityFunctions"

export const TAB_IDS = {
    ACCOUNTS: "accountsSection",
    SUPPORT: "supportSection",
    PROFIT_LOSS_REPORT: "profitLossSection",
    USER_INVITES:"userInvites",
    MODEL_ADMINISTRATION: "modeladministration",
    TRANSACTION: "transaction"
}
export const INITIAL_STATE = {
    showPaymentSuccessPopup : false,
    showPaymentRequestSuccessPopup : false,
    activeTab: TAB_IDS.ACCOUNTS,
    accounts: [],
    isAccountsAPILoading: true,
    isAccountsAPIError: false,
    isProfitLossAPILoading: true,
    isProfitLossAPIError: false,
    profitLossData: {},
    profitReportInputCreditValue: 1200,
    accountLevelReportInputCreditValue: 1200,
    noOfMonth: 1,
    showAddAccountPopup: false,
    isAccountEditable: false,
    actionableData: null,
    accountNameList: [],
    searchedAccount: EMPTY_STRING,
    isSearchAccountSelected: false,
    filteredAccounts: [],
    profitReportStartDate: new Date().setDate(new Date().getDate() - 30),
    profitReportEndDate: new Date(),
    thresholdValue: 1200,
    isErrorProfitReportInputCreditValue: false,
    isErrorInDatePicker: false,
    isErrorProfitValueText: EMPTY_STRING,
    isUserInvitesAPILoading: true,
    isUserInvitesAPIError: false,
    userInvitesData:[],
    searchValue : 1,
    accountLevelData: {},
    isAccountLevelAPILoading : true,
    isAccountLevelAPIError : false


}

export const SUPPORT_INITIAL_STATE = {
    supportRequestsData: [],
    isSupportRequestsAPILoading: true,
    isSupportRequestsAPIError: false,
    supportEditableData: {},
    isSupportEditable : false,
    editableCommentError: false,
    isSubmitError: false,
    isSupportUpdating : false
}

export const ACCOUNT_TYPE = {
    TRIAL: 'Trial',
    BRONZE: 'Bronze',
    CUSTOM: 'Gold',
    BASIC: 'Silver',
    TRIAL_TYPE: {
        CODE: 1
    },
    BRONZE_TYPE: {
        CODE: 2
    },
    CUSTOM_TYPE: {
        CODE: 4
    },
    BASIC_TYPE: {
        CODE: 3
    }
}

export const ACCOUNT_CREATION_INITIAL_STATE = {
    packageCredits : 0,
    topupCredits : 0,
    adminDetailsError: false,
    accountDetailsError: false,
    accountName: EMPTY_STRING,
    companyName: EMPTY_STRING,
    accountType: ACCOUNT_TYPE.CUSTOM,
    accountTypeCode: ACCOUNT_TYPE.CUSTOM_TYPE.CODE,
    tenureType: 3,
    tenureName: '1',
    tierLevel: '1',
    isEchoTestAccount: false,
    userName: EMPTY_STRING,
    userEmail: EMPTY_STRING,
    enableAdminDetailPage: true,
    isSubmitting: false,
    accountNameError: false,
    accountNameErrorText: EMPTY_STRING,
    companyNameError: false,
    userEmailError: false,
    userEmailErrors: 0,
    userEmailErrorText: EMPTY_STRING,
    userNameError: false,
    userNameErrorText: EMPTY_STRING,
    editableData: null,
    showManage: false,
    manageAccountID: 0,
    isEmailVerified: false,
    isVerifying: false,
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    organisationName: EMPTY_STRING,
    jobTitle: EMPTY_STRING,
    isFirstNameError: false,
    isLstNameError: false,
    isOrganisationNameError: false,
    isJobTitleError: false,
    isRegisteredUser: false,
    userError: false
}

// get account type details
export const getAccountDetails = (type) => {
    switch (type) {
        case ACCOUNT_TYPE.TRIAL:
            return [
                ACCOUNT_TYPE.TRIAL,
                ACCOUNT_TYPE.TRIAL_TYPE.CODE
            ];
        case ACCOUNT_TYPE.BRONZE:
            return [
                ACCOUNT_TYPE.BRONZE,
                ACCOUNT_TYPE.BRONZE_TYPE.CODE
            ];
        case ACCOUNT_TYPE.BASIC:
            return [
                ACCOUNT_TYPE.BASIC,
                ACCOUNT_TYPE.BASIC_TYPE.CODE
            ];
        case ACCOUNT_TYPE.CUSTOM:
        default:
            return [
                ACCOUNT_TYPE.CUSTOM,
                ACCOUNT_TYPE.CUSTOM_TYPE.CODE
            ]
    }
}

export const getAccountTypeName = (typeID) => {
    console.log('Type ID', Number(typeID))
    switch (Number(typeID)) {
        case 1:
        case 3:
            return ACCOUNT_TYPE.TRIAL
        case 2:
        case 4:
            return ACCOUNT_TYPE.BRONZE
        case 5:
        case 22:
            return ACCOUNT_TYPE.BASIC
        case 6:
        default:
            return ACCOUNT_TYPE.CUSTOM
    }
}

export const getTenureType = (type) => {
    type = Number(type)
    if (SUBSCPTION_TENURE_TYPE.THREE.includes(type)) {
        return 0
    } else if (SUBSCPTION_TENURE_TYPE.SIX.includes(type)) {
        return 1
    } else if (SUBSCPTION_TENURE_TYPE.TWELEVE.includes(type)) {
        return 2
    } else {
        return 3
    }
}

export const getTenureTypeName = (id) => {
    switch (id) {
        case 0: return '3';
        case 1: return '6';
        case 2: return '12';
        case 3: return '1';
        default: return '1'
    }
}

// handle email verification of admi user
export const handleSuccessVerfication = ({ data }) => {
    let firstName = EMPTY_STRING, lastName = EMPTY_STRING, organisationName = EMPTY_STRING,
        jobTitle = EMPTY_STRING, isRegisteredUser = false;
    let accountType = ACCOUNT_TYPE.CUSTOM
    let accountTypeCode = ACCOUNT_TYPE.CUSTOM_TYPE.CODE
    let tenureType = 3
    let tenureName = '1'
    let adminDetailsError = false
    let userError = false
    let userEmailErrorText = EMPTY_STRING
    let packageCredits = 0
    let topupCredits = 0
    if (!data.hasOwnProperty('status') && !isEmpty(data.user)) {
        firstName = data.user.firstName;
        lastName = data.user.lastName;
        organisationName = data.user.companyName;
        jobTitle = data.user.jobTitle;
        isRegisteredUser = true
        if(!isEmptyList(data.subscriptionRequest) && !isEmptyObject(data.subscriptionRequest[0])){
            let selectedPlan = data.subscriptionRequest[0];
            let planName = getPlanDetails(SUBSCRIPTION_TYPE_IDS[selectedPlan.subscriptionType]).planName
            let [accountTypeSubscribed, accountTypeCodeSubscribed ] = getAccountDetails(planName)
            accountType = accountTypeSubscribed
            accountTypeCode = accountTypeCodeSubscribed
            packageCredits = selectedPlan.subscriptionCredit
            topupCredits = selectedPlan.additionalCredit
            tenureType = getTenureType(SUBSCRIPTION_TYPE_IDS[selectedPlan.subscriptionType])
            tenureName = getTenureTypeName(tenureType)
        }
        else {
            isRegisteredUser = false
            adminDetailsError = true
        }
    }
    return {
        firstName, lastName, organisationName,
        jobTitle, isRegisteredUser,
        isVerifying: false,
        isEmailVerified: true,
        accountType,
        accountTypeCode,
        tenureName,
        tenureType,
        userError,
        userEmailErrorText,
        adminDetailsError,
        packageCredits,
        topupCredits
    }
}

export const getAllAccounts = ({ data }) => {
    let accounts = []
    let accountNameList = []
    if (!isEmpty(data)) {
        data.map(item => {
            let accountSubscriptions = {};
            let accountIsActive = 0;
            if(!isEmptyList(item?.subscription)){
                accountSubscriptions = Object.assign({},getAccountSubscriptions(item?.subscription))
            }
            let account = {
                accountName: item.accountname,
                accountUUID: item.AccountUUID,
                companyName: item.CompanyName,
                isActive: accountSubscriptions?.accountIsActive,
                accountID: item.accountid,
                // isExpired,
                activeUsers: item.active_users,
                actions: true,
                accountSubscriptions : accountSubscriptions?.subscriptions
            }
            accountNameList.push(item.accountname)
            accounts.push(account)
            return null
        })
    }
    accountNameList = [...new Set(accountNameList.map((item) => item))]
    return ({
        isAccountsAPILoading: false,
        isAccountLevelAPIError: false,
        accounts,
        accountNameList
    })
}

export const handleAccountsAPIError = (error) => {

    return ({
        isAccountsAPILoading: false,
        isAccountsAPIError: true
    })
}

export const getProfitLossReport = ({ data }) => {
    
    return {
        isProfitLossAPILoading: false,
        profitLossData: data

    }
}

export const getAccountLevelReport = ({ data }) => {
    
    return {
        isAccountLevelAPILoading: false,
        accountLevelData: data

    }
}

export const getSupportRequests = ({ data }) => {

    let supportRequests = []

    if (!isEmptyList(data)) {
        data.map((item, index) => {
            let supportRequest = {
                supportId:item.SupportId,
                AccountUUID: item.AccountUUID,
                category: item.Category,
                query: item.Query,
                approverComment: item.ApproverComment,
                attachmentUrl: item.AttachmentUrl,
                status: item.Status,
                createdBy: item.CreatedBy,
                createdOn: getDateFromInput(item.CreatedOn,true),
                actions: true
            }
            supportRequests.push(supportRequest)
            return null;
        })
    }
    return {
        isSupportRequestsAPILoading: false,
        supportRequestsData: supportRequests
    }
}

export const handleSupportRequestsAPIError = (err) => {
    return {
        isUserInvitesAPILoading: false,
        isUserInvitesAPIError: true
    }
}

export const getUserInvitesStatus = ({ data }) => {

    let userInvites = []

    if (!isEmptyList(data)) {
        data.map((item, index) => {
            let userInvite = {
                id:item.awsId,
                displayName:item.displayName,
                email:item.email,
                companyName:item.companyName,
                userStatus : item.userStatus,
                createdOn:getDateFromInput(item.createdDateTime,false)    
            }
            userInvites.push(userInvite)
            return null;
        })
    }
    return {
        isUserInvitesAPILoading: false,
        userInvitesData: userInvites
    }
}

export const handleUserInviteRequestsAPIError = (err) => {
    return {
        isSupportRequestsAPILoading: false,
        isSupportRequestsAPIError: true
    }
}

export const handleProfitLossAPIError = (err) => {
    return {
        isProfitLossAPILoading: false,
        isProfitLossAPIError: true
    }
}

export const handleAccountLevelAPIError = (err) => {
    return {
        isAccountLevelAPILoading: false,
        isAccountLevelAPIError: true
    }
}

// get filter accounts while search
export const getFilteredAccounts = (allAccounts, filterAccount) => {
    let filteredAccounts = Object.assign([], allAccounts)
    filteredAccounts = filteredAccounts.filter((item) => item.accountName.toLowerCase().includes(filterAccount.toLowerCase()))
    return ({
        filteredAccounts
    })
}

// get initial admin details
export const getInitialAdminDetails = () => {
    return {
        adminDetailsError: false,
        accountDetailsError: false,
        isEmailVerified: false,
        isVerifying: false,
        firstName: EMPTY_STRING,
        lastName: EMPTY_STRING,
        organisationName: EMPTY_STRING,
        jobTitle: EMPTY_STRING,
        isFirstNameError: false,
        isLstNameError: false,
        isOrganisationNameError: false,
        isJobTitleError: false,
        isRegisteredUser: false,
        userEmailError: false,
        userEmailErrors: 0,
        userEmailErrorText: EMPTY_STRING,
        userNameError: false,
        userNameErrorText: EMPTY_STRING,
        tierLevel: '1',
        userName: EMPTY_STRING,
        userEmail: EMPTY_STRING,
        userError: false,

    }
}