import { FILE_TYPES, SEPARATORS } from "../strings/Strings";
import { DEFAULT_COUNTRY_CODE, EMPTY_STRING, ERROR_CODES, INFO_MAIL, MIN_TOPUP_AMOUNT, FULL_MONTHS, MODEL_CODES, SUBSCPTION_TENURE_TYPE, SUBSCRIPTION_TENURES, SUBSCRIPTION_TYPE, SUBSCRIPTION_TYPE_IDS, TOPUP_UNIT_AMOUNT, VALIDATION_REGEX, BASE64_CONTENTS, STRING_VALUE, PAGE_TITLE, SUBSCPTION_PRODUCT_TYPE, SUBSCRIPTION_PRODUCTS, USER_MANAGEMENT } from "./Constants";
import { COUNTRY_LIST } from "./CountryList";
import { IMAGES } from "./ImageConstants";
import { MODEL_INPUT_JSON_CHANGE_BEFORE_SUBMIT, MODEL_INPUT_JSON_CHANGE_TYPE } from "./ModelConstants";

export const isEmpty = (value) => {
  switch (value) {
    case "":
    case 0:
    case null:
    case false:
    case undefined:
      return true;
    default:
      return false;
  }
};


export const isEmptyList = (list) => {
  if (isEmpty(list)) return true;
  if (list.length === 0) return true;
  return false;
};

export const isEmptyObject = (obj) => {
  if (isEmpty(obj)) return true;
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

// set page title
export function seoSetTitleAndDescription(title=EMPTY_STRING, metaDescription=EMPTY_STRING) {
  // title = !isEmpty(title) ? (PAGE_TITLE.DEFAULT.TITLE + " | " + title) : PAGE_TITLE.DEFAULT.TITLE;
  title = !isEmpty(title) ? (title) : PAGE_TITLE.DEFAULT.TITLE;

  metaDescription = !isEmpty(metaDescription) ? metaDescription : PAGE_TITLE.DEFAULT.DESCRIPTION;

  document.title = title;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
}

// file related
export const getFileSrc = (type, src) => {
  console.log("FILE_TYPE",type,src)
  let fileSource = !isEmpty(src)? src.replace(/\s/g,"%20") : src;
  switch (type.toLowerCase()) {
    case FILE_TYPES.TIFF:
      return IMAGES.FILES.TIFF_ICON
    case FILE_TYPES.PDF:
      return IMAGES.FILES.PDF_ICON
    case FILE_TYPES.ZIP:
      return IMAGES.FILES.ZIP_ICON
    case FILE_TYPES.TEXT:
      return IMAGES.FILES.TXT_ICON
    case FILE_TYPES.JSON:
      return IMAGES.FILES.JSON_ICON
    case FILE_TYPES.MP3:
    case FILE_TYPES.WAV:
      return IMAGES.FILES.AUDIO_ICON
    case FILE_TYPES.MP4:
      return IMAGES.FILES.VIDEO_ICON
    case FILE_TYPES.DOCX:
      return IMAGES.FILES.DOCX_ICON
    case FILE_TYPES.PPTX:
      return IMAGES.FILES.PPTX_ICON
    case FILE_TYPES.XLSX:
      return IMAGES.FILES.XLSX_ICON
    case FILE_TYPES.XLS:
    case FILE_TYPES.XLS_MIME:
      return IMAGES.FILES.XLS_ICON
    case FILE_TYPES.CSV:
      return IMAGES.FILES.CSVF_ICON
    default:
      return fileSource;
  }
}

export const getFileType = (fileType) => {
  switch (fileType) {
    case FILE_TYPES.X_ZIP:
      return FILE_TYPES.ZIP;
    case FILE_TYPES.X_ICON:
      return FILE_TYPES.ICO;
    case FILE_TYPES.PLAIN:
      return FILE_TYPES.TEXT;
    case FILE_TYPES.MPEG:
      return FILE_TYPES.MP3;
    case FILE_TYPES.DOCX_MIME:
      return FILE_TYPES.DOCX;
    case FILE_TYPES.PPTX_MIME:
      return FILE_TYPES.PPTX;
    case FILE_TYPES.XLSX_MIME:
      return FILE_TYPES.XLSX;
    case FILE_TYPES.XLS_MIME:
      return FILE_TYPES.XLS;
    default:
      return fileType;
  }
}

export const getFileTypeFromBase64String = (base64String) => {
  let initialChar = base64String? base64String.charAt(0) : EMPTY_STRING;
  switch (initialChar) {
    case 'i': return FILE_TYPES.PNG;
    case '/': return FILE_TYPES.JPG;
    case 'U': return FILE_TYPES.WEBP;
    case 'J': return FILE_TYPES.PDF;
    case 'Q': return FILE_TYPES.BMP;
    default: return FILE_TYPES.PNG;
  }
}

export const getFileContent = (initialChar) => {
  switch (initialChar) {
    case 'i': return BASE64_CONTENTS.PNG;
    case '/': return BASE64_CONTENTS.JPEG;
    case 'U': return BASE64_CONTENTS.WEBP;
    case 'J': return BASE64_CONTENTS.PDF;
    case 'Q': return BASE64_CONTENTS.BMP;
    default: return BASE64_CONTENTS.PNG;
  }
}

export const getAudioFileContent = (initialChar) => {
  switch(initialChar) {
    case '/': return BASE64_CONTENTS.MP3;
    case 'T': return BASE64_CONTENTS.OGG;
    default: return BASE64_CONTENTS.WAV;
  }
}

export const removePublishStorage = () => {
  window.sessionStorage.removeItem('publish_req_data');
  window.sessionStorage.removeItem('publish_base_model');
  window.sessionStorage.removeItem('update_publish');
  window.sessionStorage.removeItem('skillid');
}

export const removeLastOccurence = (fullString, stringToReplace, replaceString) => {
  let lastOccurenceIndex = fullString.lastIndexOf(stringToReplace);
  if(lastOccurenceIndex > 0){
    let lengthOfStringToReplace = stringToReplace.length;
    return fullString.substring(0,lastOccurenceIndex) + replaceString + fullString.substring(lastOccurenceIndex+lengthOfStringToReplace)
  }
  return fullString;
}

// new validationFunction
export const isValidTopup = (topup, min, max, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(topup) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(topup)) {
    if (Number(topup) < min) {
      error = true;
      text = "Minimum topup amount is " + getDollarFromNumber((MIN_TOPUP_AMOUNT + TOPUP_UNIT_AMOUNT * Number(min)).toFixed(2))
    } else if (Number(topup) > max) {
      error = true;
      text = "Maximum topup amount is " + getDollarFromNumber((MIN_TOPUP_AMOUNT + TOPUP_UNIT_AMOUNT * Number(max)).toFixed(2))
    }
  }
  return { error, text };
}
export const isValidTextString = (string, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(string) && isEmpty(string.trim()) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  }
  return { error, text }
}

export const isValidHypothesisTextString = (string, max, min, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(string.trim()) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(string)) {
    if (string.length > max) {
      error = true;
      text = `You have reached your maximum limit (${max}) of characters allowed.`
    } else if (string.length < min) {
      error = true;
      text = `This field should have atleast ${min} characters.`
    } else if (!string.includes('{}')) {
      error = true;
      text = "Hypothesis should contain {}"
    }
  }
  return { error, text }
}

export const isValidNumberString = (number, isMandatory, fieldName) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(number) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(number)) {
    if (!number.match(VALIDATION_REGEX.NUMBER_PATTERN)) {
      error = true;
      text = "Invalid " + fieldName + '.'
    }
  }
  return { error, text }
}

export const isValidWorkExperience = (number, isMandatory) => {
  return isValidNumberString(number, isMandatory, 'work experience');
}

export const isValidSecret = (value, min, max, isMandatory) => {

  let error = false, text = EMPTY_STRING;
  value = isEmpty(value)? value : value.trim();
  if (isEmpty(value) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(value)) {
    if (!value.match(VALIDATION_REGEX.SECRETS)){
      error = true;
      text = `Only uppercase and underscore (_) are allowed.`
    } else if (value.length > max) {
      error = true;
      text = `You have reached your maximum limit (${max}) of characters allowed.`
    } else if (value.length < min) {
      error = true;
      text = `This field should have atleast ${min} characters.`
    } 
  }
  console.log("CONFIGURATORS validSecret",value, min, max, isMandatory,{error,text})
  return {error,text}
}

export const isValidNameString = (name, isMandatory, fieldName) => {
  let error = false, text = EMPTY_STRING;
  let field = isEmpty(fieldName) ? 'Name' : fieldName
  if (isEmpty(name) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(name)) {
    if (name.match(VALIDATION_REGEX.NUMBER_PATTERN)) {
      error = true;
      text = field + " must contains alphabets."
    } else if (!name.match(VALIDATION_REGEX.NAME_PATTERN)) {
      error = true;
      text = "Invalid " + field.toLowerCase() + '.';
    }
  }
  return { error, text }
}

export const isValidPassword = (password, isMandatory, fieldName) => {
  let error = false, text = EMPTY_STRING;
  let field = isEmpty(fieldName) ? 'Password' : fieldName
  if (isEmpty(password) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(password)) {
    if (!password.match(VALIDATION_REGEX.PASSWORD)) {
      error = true;
      text = field + " must contain atleast a uppercase, lowercase, special character and number."

    }
    //  else if (password.match(VALIDATION_REGEX.PASSWORD)) {
    //   error = true;
    //   text = "Invalid " + field.toLowerCase() + '.';
    // }
  }
  return { error, text }
}

export const isValidConfirmPassword = (actualPassword, confirmPassword) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(confirmPassword)) {
    error = true;
    text = 'This field should not be empty.'
  } else if(!isEmpty(confirmPassword)){
      if(actualPassword !== confirmPassword){
        error = true;
        text = 'Passwords must be same.'
      }
  }
  return { error, text }
}

export const isValidString = (name, isMandatory, fieldName) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(name) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(name)) {
    if (name.match(VALIDATION_REGEX.NUMBER_PATTERN)) {
      error = true;
      text = fieldName + " should not be a number."
    } else if (!name.match(VALIDATION_REGEX.ORG_NAME)) {
      error = true;
      text = "Invalid " + fieldName.toLowerCase() + '.'
    }
  }
  return { error, text }
}

export const isValidCompanyNameString = (name, isMandatory) => {
  return isValidString(name, isMandatory, 'Company name');
}

export const isValidJobTitleString = (name, isMandatory) => {
  let validateResult = isValidString(name, isMandatory, 'Job title');
  let error = validateResult.error;
  let text = validateResult.text;
  if (!error && !isEmpty(name)) {
    if (name.match(/\d/g)) {
      error = true;
      text = 'Invalid job title.'
    }
  }
  return { error, text };
}

export const isValidCountryString = (name, isMandatory) => {
  return isValidNameString(name, isMandatory, 'Country');
}

export const isValidStateString = (name, isMandatory) => {
  return isValidNameString(name, isMandatory, 'State');
}

export const isValidCityString = (name, isMandatory) => {
  return isValidNameString(name, isMandatory, 'City');
}

export const isValidEmailString = (mail, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(mail) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(mail)) {
    if (!mail.match(VALIDATION_REGEX.MAIL_PATTERN)) {
      error = true;
      text = "Invalid email id."
    }
  }
  return { error, text }
}

export const isValidWorkEmailString = (mail, isMandatory) => {
  let validateMail = isValidEmailString(mail, isMandatory);
  let error = validateMail.error, text = validateMail.text;
  if (!validateMail.error) {
    if (isEmpty(mail) && isMandatory) {
      error = true;
      text = 'This field should not be empty.'
    } else if (!isEmpty(mail)) {
      if (!mail.match(VALIDATION_REGEX.WORKMAIL_PATTERN)) {
        error = true;
        text = "Invalid work email id."
      }
    }
  }
  return { error, text }
}

export const isValidWebsiteString = (website, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(website) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(website)) {
    if (!website.match(VALIDATION_REGEX.WEBSITE)) {
      error = true;
      text = "Invalid website."
    }
  }
  return { error, text }
}

export const isValidMessageString = (message, max, min, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  message = isEmpty(message)? message : message.trim();
  if (isEmpty(message) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(message)) {
    if (message.length > max) {
      error = true;
      text = `You have reached your maximum limit (${max}) of characters allowed.`
    } else if (message.length < min) {
      error = true;
      text = `This field should have atleast ${min} characters.`
    }
  }
  return { error, text }
}


export const isValidModelNameString = (modelName, max, min, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  modelName = isEmpty(modelName)? modelName : modelName.trim();
  if (isEmpty(modelName) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(modelName)) {
    if (modelName.length > max) {
      error = true;
      text = `You have reached your maximum limit (${max}) of characters allowed.`
    } else if (modelName.length < min) {
      error = true;
      text = `This field should have atleast ${min} characters.`
    }
  }
  return { error, text }
}

export const isValidZipcode = (zipcode, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(zipcode) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(zipcode)) {
    if (!zipcode.match(VALIDATION_REGEX.ZIP_CODE) || zipcode.match(/^[a-zA-Z]+$/)) {
      error = true;
      text = "Invalid postal code."
    }
  }
  return { error, text }
}

export const isValidMobileNumber = (mobileNormal, data, mobile, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if (isEmpty(mobile) && isMandatory) {
    error = true;
    text = EMPTY_STRING;
  } else if (!isEmptyObject(data) && mobileNormal.indexOf(data.dialCode) !== 0) {
    error = true;
    text = "Invalid country code."
  } else if (!mobile.match(VALIDATION_REGEX.MOBILE)) {
    error = true;
    text = "Invalid mobile number."
  }
  return { error, text }
}

export const isValidInfoType = (list, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  if ((isEmpty(list) || isEmptyList(list)) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  }
  return { error, text }
}

export const isValidFile = (file, fileSize, fileType, maxSize, allowedTypes, isMandatory) => {
  let error = false, text = EMPTY_STRING;
  let validTypes = allowedTypes.join(', ').toUpperCase().replace(/\./g, '');
  let validSize = maxSize / 1024 / 1024;
  if (isEmpty(file) && isMandatory) {
    error = true;
    text = 'This field should not be empty.'
  } else if (!isEmpty(file)) {
    if (fileSize > maxSize) {
      error = true;
      text = `File size must not exceed ${validSize} MB.`
    } else if (!allowedTypes.includes(fileType)) {
      error = true;
      text = `Please upload valid file (${validTypes}).`
    }
  }
  return { error, text }
}

export const isValidAudioFile = (file, fileDuration, fileSize, fileType, maxTime, maxSize, allowedTypes, isMandatory) => {
  let fileValidation = isValidFile(file, fileSize, fileType, maxSize, allowedTypes, isMandatory);
  let error = fileValidation.error;
  let text = fileValidation.text;
  if (!error && isMandatory && !isEmpty(maxTime)) {
    if (fileDuration > maxTime) {
      error = true
      text = "File duration must not exceed " + maxTime + " seconds."
    }
  }
  return { error, text }
}

export const isValidWholeNumber = (number) => {
  if (number.match(VALIDATION_REGEX.NUMBER))
    return true
  return false
}

export const isValidNumber = (number) => {
  if (number.match(VALIDATION_REGEX.NUMBER_PATTERN))
    return true
  return false
}

// get refrreddata by passing through the object
export const getReferredData = (initialData, keyItemsString) => {
  let data;
  if(Array.isArray(initialData)){
    data = Object.assign([],initialData);
  } else  {
    data = Object.assign({},initialData);
  }
  if((!isEmptyObject(data) || !isEmptyList(data)) && !isEmpty(keyItemsString)){
      let keyItems = keyItemsString.split(SEPARATORS.COMMA);
      keyItems.map((key,index) => {
          if(!isEmpty(data[key])){
            data = data[key]
          } else {
            data = EMPTY_STRING
          }
          return index
      })   
  }
  return data;
}

// get country list
export const getCountrySelectOptionList = () => {
  let countrySelectOptionList = [];
  COUNTRY_LIST.map((country) => {
    countrySelectOptionList.push({
      value: country.code,
      label: country.name
    })
    return null;
  })
  return countrySelectOptionList;
}

export const getCountryFromCode = (code) => {
  let countryList = Object.assign([], COUNTRY_LIST);
  let countryCode = code;
  if (isEmpty(countryCode)) {
    countryCode = DEFAULT_COUNTRY_CODE
  }
  let countryIndex = countryList.findIndex(country => country.code === countryCode.toUpperCase())
  return countryList[countryIndex].name;
}

export const getCountryCodeFromCountry = (countryName) => {
  let countryList = Object.assign([], COUNTRY_LIST);
  let countryIndex = countryList.findIndex(country => country.name === countryName)
  return countryList[countryIndex].code;
}

export const getProductType = (type) => {
  type = Number(type)
  if (SUBSCPTION_PRODUCT_TYPE.LEAF_CONVERSE.includes(type)) {
    return {
      ...SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE
    }
  } else if (SUBSCPTION_PRODUCT_TYPE.LEAF_ESSENTIALS.includes(type)) {
    return {
      ...SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS
    }
  } else if (SUBSCPTION_PRODUCT_TYPE.LEAF_IDP.includes(type)) {
    return {
      ...SUBSCRIPTION_PRODUCTS.LEAF_IDP
    }
  } else {
    return {
      ...SUBSCRIPTION_PRODUCTS.LEAF_IDP
    }
  }
}

export const getTenureType = (type) => {
  type = Number(type)
  if (SUBSCPTION_TENURE_TYPE.THREE.includes(type)) {
    return {
      ...SUBSCRIPTION_TENURES.THREE_MONTH
    }
  } else if (SUBSCPTION_TENURE_TYPE.SIX.includes(type)) {
    return {
      ...SUBSCRIPTION_TENURES.SIX_MONTH
    }
  } else if (SUBSCPTION_TENURE_TYPE.TWELEVE.includes(type)) {
    return {
      ...SUBSCRIPTION_TENURES.TWELVE_MONTH
    }
  } else if (SUBSCPTION_TENURE_TYPE.ONE.includes(type)) {
    return {
      ...SUBSCRIPTION_TENURES.ONE_MONTH
    }
  } else {
    return {
      ...SUBSCRIPTION_TENURES.FREE
    }
  }
}

export const getPlanDetails = (subscptionTypeId) => {
  let planName = EMPTY_STRING
  let planTenure = getTenureType(subscptionTypeId)
  let productDetails = getProductType(subscptionTypeId)
  let isFreePlan = true;
  if(productDetails.ID === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.ID){
    if(SUBSCRIPTION_TYPE.LEAF_CONVERSE.CUSTOM.includes(subscptionTypeId)){
      planName = SUBSCRIPTION_TYPE.LEAF_CONVERSE.CUSTOM_DETAILS.LABEL
      isFreePlan = SUBSCRIPTION_TYPE.LEAF_CONVERSE.CUSTOM_DETAILS.IS_FREE_PLAN
    } else {
      planName = SUBSCRIPTION_TYPE.LEAF_CONVERSE.TRIAL_DETAILS.LABEL
      isFreePlan = SUBSCRIPTION_TYPE.LEAF_CONVERSE.TRIAL_DETAILS.IS_FREE_PLAN
    }
  } else if(productDetails.ID === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.ID){
    if(SUBSCRIPTION_TYPE.LEAF_ESSENTIALS.CUSTOM.includes(subscptionTypeId)){
      planName = SUBSCRIPTION_TYPE.LEAF_ESSENTIALS.CUSTOM_DETAILS.LABEL
      isFreePlan = SUBSCRIPTION_TYPE.LEAF_ESSENTIALS.CUSTOM_DETAILS.IS_FREE_PLAN
    } else {
      planName = SUBSCRIPTION_TYPE.LEAF_ESSENTIALS.TRIAL_DETAILS.LABEL
      isFreePlan = SUBSCRIPTION_TYPE.LEAF_ESSENTIALS.TRIAL_DETAILS.IS_FREE_PLAN
    }
  } else {
    if(SUBSCRIPTION_TYPE.LEAF_IDP.STANDARD.includes(subscptionTypeId)){
      planName = SUBSCRIPTION_TYPE.LEAF_IDP.STANDARD_DETAILS.LABEL
      isFreePlan = SUBSCRIPTION_TYPE.LEAF_IDP.STANDARD_DETAILS.IS_FREE_PLAN
    } else if(SUBSCRIPTION_TYPE.LEAF_IDP.ENTERPRISE.includes(subscptionTypeId)){
      planName = SUBSCRIPTION_TYPE.LEAF_IDP.ENTERPRISE_DETAILS.LABEL
      isFreePlan = SUBSCRIPTION_TYPE.LEAF_IDP.ENTERPRISE_DETAILS.IS_FREE_PLAN
    } else {
      planName = SUBSCRIPTION_TYPE.LEAF_IDP.LITE_DETAILS.LABEL
      isFreePlan = SUBSCRIPTION_TYPE.LEAF_IDP.LITE_DETAILS.IS_FREE_PLAN
    }
  }

  return {
    planName,
    planTenure,
    productDetails,
    isFreePlan
  }
}

export const getAccountSubscriptions = (data) => {
  let subscriptions = [];
  let accountIsActive = 0;
  if(!isEmptyList(data)){
    data.map((sub,index) => {
      let subscriptionDetails = getPlanDetails(Number(sub?.subscription_id));
      let productName = subscriptionDetails?.productDetails?.NAME
      let balanceUnits = subscriptionDetails?.productDetails?.UNITS
      let planName = subscriptionDetails?.planName;
      let isFreePlan = subscriptionDetails?.isFreePlan
      if(Number(sub?.is_active) === USER_MANAGEMENT.USER_STATUS.ACTIVE){
          accountIsActive = USER_MANAGEMENT.USER_STATUS.ACTIVE
      } else if((Number(sub?.is_active) === USER_MANAGEMENT.USER_STATUS.INACTIVE) && accountIsActive !== USER_MANAGEMENT.USER_STATUS.ACTIVE){
          accountIsActive = USER_MANAGEMENT.USER_STATUS.INACTIVE
      } else if((Number(sub?.is_active) === USER_MANAGEMENT.USER_STATUS.UNSUBSCRIBED) && accountIsActive !== USER_MANAGEMENT.USER_STATUS.ACTIVE && accountIsActive !== USER_MANAGEMENT.USER_STATUS.INACTIVE){
          accountIsActive = USER_MANAGEMENT.USER_STATUS.UNSUBSCRIBED
      }
      subscriptions.push({
          productName,
          planName,
          isPlanActive : Number(sub?.is_active),
          balance : sub?.balance,
          balanceUnits,
          validityDate : getDateFromInput(sub?.valid_upto,true),
          subscription_id : sub?.subscription_id,
          isFreePlan
      })
      return index;
    })
  }
  return {
    subscriptions, accountIsActive
  };
}

export const getSubscriptionIDs = (subscriptions) => {
  if(isEmptyList(subscriptions)){
    return [];
  }
  return subscriptions.map((sub) => sub?.subscription_id);
}

export const getPlanDetailsFromLabel = (labelKey) => {
  let subscriptionTenureId = SUBSCRIPTION_TYPE_IDS[labelKey];
  return {
    ...getPlanDetails(subscriptionTenureId)
  }
}

// get subscription badge
export const getBadgeDetails = (type) => {
  let subscptionType = Number(type);
  if (SUBSCRIPTION_TYPE.TRIAL.includes(subscptionType)) {
    return [
      SUBSCRIPTION_TYPE.TRIAL_DETAILS.LABEL,
      SUBSCRIPTION_TYPE.TRIAL_DETAILS.STYLE
    ]
  } else if (SUBSCRIPTION_TYPE.BRONZE.includes(subscptionType)) {
    return [
      SUBSCRIPTION_TYPE.BRONZE_DETAILS.LABEL,
      SUBSCRIPTION_TYPE.BRONZE_DETAILS.STYLE
    ]
  } else if (SUBSCRIPTION_TYPE.BASIC.includes(subscptionType)) {
    return [
      SUBSCRIPTION_TYPE.BASIC_DETAILS.LABEL,
      SUBSCRIPTION_TYPE.BASIC_DETAILS.STYLE
    ]
  } else {
    return [
      SUBSCRIPTION_TYPE.CUSTOM_DETAILS.LABEL,
      SUBSCRIPTION_TYPE.CUSTOM_DETAILS.STYLE
    ]
  }
}

export const getListFromString = (string, separator) => {
  let trimmedList = [];
  if (!isEmpty(string)) {
    let list = string.split(separator);
    list.map(item => trimmedList.push(item.trim()))
  }
  return trimmedList;
}

export const filterListWithKeyAndValue = (list, key, value) => {
  let resultList = [];
  if (!isEmptyList(list)) {
    resultList = list.filter(item => item[key] === value)
  }
  return resultList;
}

export const getFileExtension = (url) => {
  console.log("AUGMENTEDSAMPLES url",url)
  let extension = url;
  
  if(!isEmpty(extension)){
    if (extension.includes("?")) {
      extension = extension.substring(0, extension.indexOf('?'))
    }
    extension = extension.substring(extension.lastIndexOf('.'))
    extension = extension.toLowerCase();
  }
  
  return extension;
}

export const getFileNameFromURL = (url) => {
  let fileName = url;
  
  if(!isEmpty(fileName)){
    if (fileName.includes("?")) {
      fileName = fileName.substring(0, fileName.indexOf('?'))
    }
    fileName = fileName.substring(fileName.lastIndexOf('/')+1)
  }
  
  return fileName;
}

export const getWesternNumber = (number) => {
  let wesNum = number.toString().split(".");
  wesNum[0] = wesNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return wesNum.join('.')
}

export const getDollarFromNumber = (number) => {
  return 'USD ' + getWesternNumber(number)
}

export const getTimeFromDateString = (inputDateString, isOnlyDate) => {
  let dateTime = 0
  if(inputDateString.includes('/')){
    let [date, sessiontime] = inputDateString.split(', ');
    let [month, day, year] = date.split('/');
    let [time, session] = !isOnlyDate ? sessiontime.split(' ') : [0, EMPTY_STRING];
    let [hour, minute, second] = !isOnlyDate ? time.split(':') : [0, 0, 0];
    hour = session.toLowerCase() === 'pm' && hour !== '12' ? (Number(hour) + 12).toString() : hour;
    month = (Number(month) - 1).toString()
    dateTime = new Date(year, month, day, hour, minute, second).getTime();
  }
  return dateTime;
}

export const getDownloadFileNameWithTime = (name) => {
  let filename = name.replace(/\s/g, SEPARATORS.UNDERSCORE)
  let dateTimeString = getDateFromInput(new Date())
  let [date, sessiontime] = dateTimeString.split(', ');
  date = date.replace(/\//g, EMPTY_STRING);
  sessiontime = sessiontime.replace(/:/g, EMPTY_STRING).split(SEPARATORS.SPACE)[0];
  return filename + SEPARATORS.UNDERSCORE + date + sessiontime
}

export const getDatePublishFormat = (input, agoFormat=false) => {
  if(!isEmpty(input)){
    let dateObject = new Date(input);
    let day = dateObject.getDate();
    let month = FULL_MONTHS[dateObject.getMonth()];
    let year = dateObject.getFullYear();
    if(agoFormat){
      let dateDifference = Math.abs(new Date() - dateObject)
      let dateDifferenceInSecs = Math.floor(dateDifference / (1000 * 60))
      let dateDifferenceInDays = Math.floor(dateDifference / (1000 * 60 * 60 * 24))
      if(dateDifferenceInDays === 0){
        if(dateDifferenceInSecs >= 1 && dateDifferenceInSecs < 60){
          return ((dateDifferenceInSecs === 1)? "A second" : (dateDifferenceInSecs + " seconds")) + " ago";
        } else if(dateDifferenceInSecs >= 60 && dateDifferenceInDays < 3600 ){
          return ((Math.floor(dateDifferenceInSecs/60) === 1)? "A minute" : (Math.floor(dateDifferenceInSecs/60) + " minutes")) + " ago";
        } else if(dateDifferenceInSecs >= 3600 && dateDifferenceInSecs < 36000 ){
          return ((Math.floor(dateDifferenceInSecs/3600) === 1)? "An hour" : (Math.floor(dateDifferenceInSecs/3600) + " hours")) + " ago";
        } else {
          return "Today";
        }
      } else if(dateDifferenceInDays >= 1 && dateDifferenceInDays < 10){
        return ((dateDifferenceInDays === 1)? "A day" : (dateDifferenceInDays + " days")) + " ago";
      } else if(dateDifferenceInDays >= 10 && dateDifferenceInDays < 35 ){
        return ((Math.floor(dateDifferenceInDays/7) === 1)? "A week" : (Math.floor(dateDifferenceInDays/7) + " weeks")) + " ago";
      } else if(dateDifferenceInDays >= 35 && dateDifferenceInDays < 365 ){
        return ((Math.floor(dateDifferenceInDays/30) === 1)? "A month" : (Math.floor(dateDifferenceInDays/30) + " months")) + " ago";
      }
    }

    return [[month, day].join(' '), year].join(", ");
  }
  return EMPTY_STRING
}

export const getUTCTime = () => {
  let date = new Date();
  let year = date.getUTCFullYear();
  let month = date.getUTCMonth();
  let day = date.getUTCDate();
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  let seconds = date.getUTCSeconds();
  let milliseconds = date.getUTCMilliseconds();
  return new Date(year, month, day, hours, minutes, seconds, milliseconds);
}

export const getDateFromInput = (input, getOnlyDate) => {
  if (!isEmpty(input)) {
    if (getOnlyDate) {
      return new Date(input)
        .toLocaleDateString('en-US', {
          day: "2-digit",
          month: "2-digit",
          year: 'numeric',
        }).toString().toUpperCase();
    }
    return new Date(input)
      .toLocaleString('en-US', {
        day: "2-digit",
        month: "2-digit",
        year: 'numeric',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).toString().toUpperCase();
  }
  return '-'

}

// get model output error images
export const getModelErrorData = (code, message) => {
  let image = IMAGES.MODEL_RESPONSE.NO_DATA
  let mainText = "No Formatted Data Found"
  let subText = EMPTY_STRING
  if (code == "200" || code == "201" || code == "202") {
    mainText = "No Result Found"
    subText = "You can check the raw data for detailed information."
  } else if(code == ERROR_CODES.BAD_REQUEST){
      mainText = message
  } else if (code == ERROR_CODES.UNAUTHORIZED) {
    if (message.toLowerCase().includes('permission')) {
      image = IMAGES.MODEL_RESPONSE.NO_ACCESS
      mainText = "Access Denied!"
      subText = "You don't have access to this model."
    } else if (message.toLowerCase().includes('disabled')) {
      image = IMAGES.MODEL_RESPONSE.NO_ACCESS
      mainText = "Model Disabled!"
      subText = message.toLowerCase().includes('admin') ?
        "Model disabled by your account admin, please contact the admin."
        : <span>{"Model disabled by Vivas.AI, please reach out to us at "}<a href={"mailto:" + INFO_MAIL}>{INFO_MAIL}</a></span>
    } else if (message.toLowerCase().includes('credits')) {
      image = IMAGES.MODEL_RESPONSE.NO_ENOUGH_CREDITS
      mainText = "Credits Reached Minimum!"
      subText = "Top-up the credits and try again."
    } else if(message.toLowerCase().includes('active subscription')
    && message.toLowerCase().includes('account')) {
      image = IMAGES.MODEL_RESPONSE.ACCOUNT_UNSUBSCRIBED
      mainText = "Unsubscribed!"
      subText = "You don't have an active subscription to try this model."
    }else if (message.toLowerCase().includes('expired')
      && message.toLowerCase().includes('account')) {
      image = IMAGES.MODEL_RESPONSE.ACCOUNT_EXPIRED
      mainText = "Account Got Expired!"
      subText = "Contact the admin and renew the subscription."
    } else if (message.toLowerCase().includes('expired')
      && message.toLowerCase().includes('user')) {
      image = IMAGES.MODEL_RESPONSE.USER_EXPIRED
      mainText = "User Validy Expired!"
      subText = "Contact the admin and renew the user."
    } else {
      mainText = message
    }
  } else if (code == ERROR_CODES.PRECONDITION_FAILED || code == ERROR_CODES.UNPROCESSABLE_ENTITY) {
    if (message === MODEL_CODES.OBJECT_DETECTOR) {
      image = IMAGES.MODEL_RESPONSE.NO_OBJECT
      mainText = "No Objects Found"
    } else if (message === MODEL_CODES.COMPARE_FACES || message === MODEL_CODES.FACE_ANALYZER) {
      image = IMAGES.MODEL_RESPONSE.NO_FACE
      mainText = "No Faces Found "
    } else if (message.toLowerCase().indexOf('weblink') > -1) {
      image = IMAGES.MODEL_RESPONSE.NO_WEBLINK
      mainText = "No Weblinks Found"
    } else if (message.toLowerCase().indexOf('landmark') > -1) {
      image = IMAGES.MODEL_RESPONSE.NO_LANDMARK
      mainText = "No Landmarks Found"
    } else if (message.toLowerCase().indexOf('place') > -1) {
      image = IMAGES.MODEL_RESPONSE.NO_PLACE
      mainText = "No Places Found"
    } else if (message.toLowerCase().indexOf('logo') > -1 || message === MODEL_CODES.LOGO_DETECTOR) {
      image = IMAGES.MODEL_RESPONSE.NO_LOGO
      mainText = "No Logos Found"
    }
    else if (message === MODEL_CODES.BARCODE_DETECTION) {
      image = IMAGES.MODEL_RESPONSE.NO_BARCODE
      mainText = "No Code found"
    }
    else if (message === MODEL_CODES.IMAGE_IDENTIFIER) {
      image = IMAGES.MODEL_RESPONSE.NO_IMAGEIDENTIFIER
      mainText = "No Personal Data/Information Found"
    }
    else if(message === "items"){
      mainText = "No Result Found"
      subText = "You can check the raw data for detailed information."
    }
    else if(message === "records"){
      mainText = "No Records Found"
    }
    else {
      mainText = message
    }
    subText = "Try again with the different data."
  } else if (code == ERROR_CODES.SERVER_ERROR) {
    image = IMAGES.MODEL_RESPONSE.ERROR
    mainText = "OOPS! Somthing went wrong"
    subText = "You may also refresh the page or try again later."
  } else if (code == ERROR_CODES.SERVICE_UNAVAILABLE ) {
    mainText = "Service Unavailable"
    subText = message
  }
  return { image, mainText, subText }
}

// signup function

export const onSignupClicked = (instance, isAuthenticated, policyName) => {


  let isLoading = true

  if (!isAuthenticated) {
    instance.loginRedirect(policyName).then(() => {
      isLoading = false
    })
      .catch(e => {
        isLoading = false
      })
  }
  return isLoading
}

export const getFileSize = (bytes, decimalPoint) => {
  if (bytes == 0) return '0 Bytes';
  // let k = 1024 // in case of binary
  let k = 1000 // in case of decimal
  let dm = decimalPoint || 2
  // let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  // let i = Math.floor(Math.log(bytes) / Math.log(k));
  // return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  return parseFloat(bytes / k).toFixed(dm) + " " + "KB"
}

export const getModelData = (model) => {
  return {
    modelName: model.Model_Name,
    modelCode: model.Model_Code,
    modelTask: model.Model_Task,
    modelDomain: model.Model_Domain,
    modelCategory: model.Model_Category,
    modelDesc: model.Model_Desc,
    modelImg: model.ModelImage,
    modelGifImg: model.ModelGif,
    genAIGroup : model?.GenAIGroup,
    id: model._id,
    isSampleSection: model.SampleFlag,
    likeCount: model.likeCount,
    isModelEnabled: model.isModelEnabled,
    tier: model.Tier,
    validationAccuracy: model.Validation_Accuracy,
    credits: model.Credits,
    rating: parseFloat(model.Rating),
    apiInfo: model.API_Info,
    userRating: parseFloat(model.UserRating),
    isFavourited: model.isFavourited,
    modelOnboardedDate: model.Model_Onboarded_Date,
    isGenAIModel: (model.IsGenAIModel && model.IsGenAIModel.toLowerCase() === STRING_VALUE.TRUE.toLowerCase())? true : false,
    isCoreModel : (model?.GenAIGroup && model.GenAIGroup.toLowerCase() === "core models") ? true : false,
    isAugmentedModel : (model?.SkillId) ? true : false,
    skillID : model?.SkillId,
    hasEditAccess : model?.Access,
    privateModel : model?.PrivateModel,
    modelVisibilityLevel : model?.ModelVisibilityLevel,
    leafGroups : model?.LeafGroup || EMPTY_STRING
  }
}

export const getModelsFromList = (data) => {
  let modelList = [];
  if (!isEmptyList(data)) {

    data.map(item => {
      let model = item
      modelList.push(
        getModelData(model)
      )

      return null;
    })
  }
  return modelList;
}

export const copyToClipboard = (text) => {
  console.log(text)
  var copyText = document.createElement('input')
  copyText.setAttribute('type', 'text');
  copyText.setAttribute('value', text)

  copyText.select();
  copyText.setSelectionRange(0, 99999);

  navigator.clipboard.writeText(copyText.value);

}

export const copyFormatToClipboard = (formattedDataComponent) => {

  let range = document.createRange();
  let sel = window.getSelection();
  sel.removeAllRanges();

  try {
    range.selectNodeContents(formattedDataComponent);
    sel.addRange(range);
  } catch (e) {
    range.selectNode(formattedDataComponent);
    sel.addRange(range);
  }
  document.execCommand('copy');

  // navigator.clipboard.writeText(range.startContainer.innerText);
  sel.removeAllRanges();

}

export const downloadEnvFile = async (fileName, resultData) => {
  let text = EMPTY_STRING;
  Object.keys(resultData).forEach((key) => {
      text += `${key}=${resultData[key]}\n`
  })
  const blob = new Blob([text], { type: 'text/plain' });
  const href = URL.createObjectURL(blob);
  let anchorElem = document.createElement('a')
  anchorElem.href = href;
  anchorElem.download = fileName + ".env";
  document.body.appendChild(anchorElem);
  anchorElem.click();
  document.body.removeChild(anchorElem)
}

export const downloadJsonFile = async (fileName, resultData) => {

  const blob = new Blob([resultData], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  let anchorElem = document.createElement('a')
  anchorElem.href = href;
  anchorElem.download = fileName + ".json";
  document.body.appendChild(anchorElem);
  anchorElem.click();
  document.body.removeChild(anchorElem)
}

export const downloadCsvFile = async (fileName, resultData) => {

  const blob = new Blob([resultData], { type: 'application/csv' });
  const href = URL.createObjectURL(blob);
  let anchorElem = document.createElement('a')
  anchorElem.href = href;
  anchorElem.download = fileName + ".csv";
  document.body.appendChild(anchorElem);
  anchorElem.click();
  document.body.removeChild(anchorElem)
}

export const getBase64FileFromUrl = async (fileUrl) => {
  var res = await fetch(fileUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}

export const isSubArray = (actualArray,subArray) => {
  let array = Object.assign([],actualArray);
  let sub = Object.assign([],subArray)
  if(!isEmptyList(array) && !isEmptyList(sub)){
      let notExistsArray = sub.filter(item => !array.includes(item))
      return isEmptyList(notExistsArray)
  }
  return false;
}

export const getLabelText = (text,separator="_") => {
  if (!isEmpty(text) && isNaN(text)) {
    if (text.match(/(https?:\/\/[^\s]+)/g)) {
      return text
    }
    let data = text.split(separator);
    data = data.map(word => (isEmpty(word) || isEmpty(word.trim())) ? EMPTY_STRING : word.charAt(0).toUpperCase() + word.slice(1))
    data = (data.join(SEPARATORS.SPACE).replace(/\s+/g,SEPARATORS.SPACE)).trim();
    return data;
  }
  return text;
}

export const getKeyText = (text) => {
  if (!isEmpty(text) && isNaN(text)) {
    let data = text.toLowerCase().trim();
    data = data.replace(/\s+/g,SEPARATORS.UNDERSCORE);
    data = data.replace(/[\W]+/g,SEPARATORS.UNDERSCORE);
    return data;
  }
  return text;
}

const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) +
      nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const createImageFromInitials = (size, name, color) => {
  if (name == null) return;
  name = getInitials(name)

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = canvas.height = size

  context.fillStyle = "#ffffff"
  context.fillRect(0, 0, size, size)

  context.fillStyle = `${color}90`
  context.fillRect(0, 0, size, size)

  context.fillStyle = 'White';
  context.textBaseline = 'middle'
  context.textAlign = 'center'
  context.font = `${size / 2}px GoogleSans`
  context.fillText(name, (size / 2), (size / 2))

  return canvas.toDataURL()
};

export const getReplacedContent = (value, meta, metaValues ) => {
  let replacedText = value;
  if(!isEmptyList(meta)){
    for(let index=0;index<meta.length;index++){
      if(metaValues.hasOwnProperty(meta[index]["replacerKey"])){
        let replaceValue = metaValues[meta[index]["replacerKey"]]
        if(meta[index]?.toLowerCase){
          replaceValue = replaceValue.toLowerCase()
        }
        replacedText = replacedText.replace(meta[index]["value"],replaceValue)
      }
    }
  }
  return replacedText
}

export const getHTMLFormatedData = (value, meta) => {
  let actualText = value;

  if (!isEmpty(value)) {
    if (!isEmptyList(meta)) {
      let formattedText = EMPTY_STRING;
      let formattableMetaData = Object.assign([], meta)
      let lastIndex = formattableMetaData.length - 1;
      let firstStart = formattableMetaData[0]['start'];
      let lastEnd = formattableMetaData[lastIndex]["text"].length + formattableMetaData[lastIndex]["start"]

      // sorting meta data based on te start
      formattableMetaData.sort(function (a, b) {
        var sortData1 = a['start'];
        var sortData2 = b['start'];
        if (sortData1 < sortData2) {
          return -1;
        }
        if (sortData1 > sortData2) {
          return 1;
        }
        return 0;
      });

      formattedText = (
        <span>
          {actualText.slice(0, firstStart)}
          {
            formattableMetaData.map((item, index) => {
              let start = item.start;
              let end = item.text.length + start;

              return (
                <span>
                  {
                    item.type === "bold" ?
                      <b>{item.text}</b>
                      : item.type === "link" ?
                        <a href={item.source} target={"_blank"} rel="noopener noreferrer">{item.text}</a>
                        : item.type === "image" ?
                          <img src={item.source} width={'auto'} height={item.height} alt={item.text} title={item.text} />
                          : <span>{item.text}</span>
                  }
                  {
                    (index !== lastIndex) &&
                    <span>
                      {actualText.slice(end, formattableMetaData[index + 1]['start'])}
                    </span>
                  }
                </span>
              )
            })
          }
          {actualText.slice(lastEnd)}
        </span>
      )

      return formattedText
    }
  }
  return <span>{actualText}</span>;
}

export const downloadBase64File = (url, filename, type) => {
  var element = document.createElement("a");
  element.href = url;
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const downloadFile = (url) => {
  var element = document.createElement("a");
  element.href = url;
  element.download = url.split('/').pop();
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// date utility functions
export const getStartOfWeek = (date) => {
  let diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -7 : 0);
  return new Date(date.setDate(diff));
}
export const getStartOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}
export const getEndofMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}
export const getStartOfPreviousMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}
export const getEndOfPreviousMonth = (date) => {
  let lastmonthStartDate = getStartOfPreviousMonth(date)
  return getEndofMonth(lastmonthStartDate)
}
export const getStartOfLastWeek = (date) => {
  let currentWeekStartDate = getStartOfWeek(date)
  let diff = currentWeekStartDate.getDate() - 7;
  return new Date(currentWeekStartDate.setDate(diff))
}
export const getEndOfLastWeek = (date) => {
  let lastWeekStartDate = getStartOfLastWeek(date)
  let diff = lastWeekStartDate.getDate() + 6;
  return new Date(lastWeekStartDate.setDate(diff))
}

// Time Functions
// time formatting
export const getTimeCodeFromNum = (num) => {
  let seconds = parseInt(num);
  let minutes = parseInt(seconds / 60);
  seconds -= minutes * 60;
  const hours = parseInt(minutes / 60);
  minutes -= hours * 60;

  // if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
  return `${String(hours).padStart(2, 0)}:${String(minutes).padStart(2, 0)}:${String(
    seconds % 60
  ).padStart(2, 0)}`;
}

// get number from formatted string
export const getNumFromTimeCode = (timeCode) => {
  let currentTime = 0;
  let time = timeCode.split(':');
  for (let ind = 0; ind < time.length; ind++) {
    currentTime += Number(time[ind]) * (Math.pow(60, time.length - (ind + 1)));
  }
  return currentTime;
}

export const isCookieAvailable = (cookie_name, key) => {
  const name = cookie_name + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
  })

  if (res) {
      // eval('var obj='+res);
      var obj = JSON.parse(res);
      if(obj.categories.includes(key)){
        return true;
      }
  }

  return false;

}

export const getTimeDiff = (startTime, endTime) => {

  let timeTaken = "-"
  if(!isEmpty(endTime)){
      let timeDiff = endTime-startTime
      timeTaken = (timeDiff/1000).toFixed(3)+" s"
  }

  return timeTaken
}

export const sortList = (listValue,ascend=true) => {
  let returnListValue = Object.assign([],listValue);
  returnListValue.sort((a,b) => {
    if(!isNaN(a) && !isNaN(b)){
      if(Number(a)>Number(b)){
        return ascend ? 1 : -1
      } else if (Number(b)>Number(a)){
        return ascend? -1 : 1
      } else {
        return 0
      }
    } else {
      if(a>b){
        return ascend ? 1 : -1
      } else if (b>a){
        return ascend? -1 : 1
      } else {
        return 0
      }
    }
  })

  return returnListValue;
}

// to remove key or change datatype of key
export const getActualInputJSON = (data,modelCode,forSample=false,metaInfo=[]) => {
  let actualInputJSON = Object.assign({},data)
  if(!isEmpty(MODEL_INPUT_JSON_CHANGE_BEFORE_SUBMIT[modelCode])){
    let metaData = !isEmptyList(metaInfo) ? metaInfo : MODEL_INPUT_JSON_CHANGE_BEFORE_SUBMIT[modelCode]
    for(let keyIndex=0;keyIndex<metaData.length;keyIndex++){
        switch(metaData[keyIndex]["changeType"]){
          case MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL : 
            if(!actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              let applyValue = isEmpty(metaData[keyIndex]?.["refervalue"]) ? metaData[keyIndex]["value"] : getReferredData(actualInputJSON,metaData[keyIndex]?.["refervalue"])
              if(!isEmptyList(metaData[keyIndex]?.["checkInput"])){
                metaData[keyIndex]?.["checkInput"].map((inputCheckItem,index) => {
                    let checkInputValue = actualInputJSON[inputCheckItem["key"]]
                    if(inputCheckItem?.checkEmpty && ((inputCheckItem.type === "object" && isEmptyObject(checkInputValue))
                       || (inputCheckItem.type === "list" && isEmptyList(checkInputValue)) || (isEmpty(checkInputValue)))){
                        applyValue = inputCheckItem.emptyValue
                    } else if(!isEmpty(inputCheckItem?.nonEmptyValue)){
                      applyValue = inputCheckItem.nonEmptyValue
                    }
                })
              }
              if(isEmpty(applyValue)){
                applyValue = metaData[keyIndex]["value"]
              }
              if(typeof applyValue === "Object" && Array.isArray(applyValue)){
                actualInputJSON[metaData[keyIndex]["key"]] = Object.assign([],applyValue)
              } else if(typeof applyValue === "Object"){
                actualInputJSON[metaData[keyIndex]["key"]] = Object.assign({},applyValue)
              } else {
                actualInputJSON[metaData[keyIndex]["key"]] = applyValue
              }
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT :
            let newFormedObject = {};
            for(let index=0;index<metaData[keyIndex]["keysList"].length;index++){
              if(!isEmpty(actualInputJSON[metaData[keyIndex]["keysList"][index]])){
                newFormedObject[metaData[keyIndex]["keysList"][index]] = actualInputJSON[metaData[keyIndex]["keysList"][index]]
              }
            }
            actualInputJSON = Object.assign({},newFormedObject);
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.LIST_TO_STRING : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = (actualInputJSON[metaData[keyIndex]["key"]].map(lItem => metaData[keyIndex]?.["is_label"] ? getLabelText(lItem) : lItem)).join(",")
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.VALUE_TO_STRING :
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = String(actualInputJSON[metaData[keyIndex]["key"]])
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_VALUE : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = Number(actualInputJSON[metaData[keyIndex]["key"]])
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.BOOLEAN_TO_STRING :
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = actualInputJSON[metaData[keyIndex]["key"]] ? "true" : "false"
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_BOOLEAN : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = (actualInputJSON[metaData[keyIndex]["key"]].toLowerCase()==="true") ? true : false
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = getLabelText(actualInputJSON[metaData[keyIndex]["key"]])
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL :
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = getStructuredName(actualInputJSON[metaData[keyIndex]["key"]])
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]]  = getKeyText(actualInputJSON[metaData[keyIndex]["key"]])
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_LIST : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              let stringValue = actualInputJSON[metaData[keyIndex]["key"]];
              if(!isEmpty(stringValue) && metaData[keyIndex]["is_key"]){
                stringValue = stringValue.toLowerCase().replace(/,+\s+/g,SEPARATORS.COMMA).replace(/,+/g,SEPARATORS.COMMA).replace(/,$/,EMPTY_STRING).replace(/\s+/g,SEPARATORS.UNDERSCORE)
              } else {
                stringValue = stringValue.replace(/,+\s+/g,SEPARATORS.COMMA).replace(/,+/g,SEPARATORS.COMMA).replace(/,$/,EMPTY_STRING)
              }
              let stringToList = Object.assign([],[...new Set(stringValue.split(metaData[keyIndex]["separator"]))])
              if(!isEmptyList(stringToList) && !isEmptyList(metaData[keyIndex]["filterIndexes"])){
                stringToList = stringToList.filter((item,index) => metaData[keyIndex]["filterIndexes"].includes(index))
              }
              actualInputJSON[metaData[keyIndex]["key"]]  = Object.assign([],stringToList)
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY :
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              if(isEmpty(metaData[keyIndex]?.["referKey"]) || (metaData[keyIndex]["referValue"].includes(actualInputJSON[metaData[keyIndex]["referKey"]]))){
                  let copyValue = !isEmpty(metaData[keyIndex]["dataRef"]) ? getReferredData(actualInputJSON,metaData[keyIndex]["dataRef"]) : actualInputJSON[metaData[keyIndex]["key"]]
                  if(typeof copyValue === "Object" && Array.isArray(copyValue)){
                    actualInputJSON[metaData[keyIndex]["copyKey"]]  = Object.assign([],copyValue)
                  } else if(typeof copyValue === "Object" ){
                    actualInputJSON[metaData[keyIndex]["copyKey"]]  = Object.assign({},copyValue)
                  } else {
                    actualInputJSON[metaData[keyIndex]["copyKey"]]  = copyValue
                  }
              }
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"]) && typeof actualInputJSON[metaData[keyIndex]["key"]]==="string"){
              actualInputJSON[metaData[keyIndex]["key"]]  = actualInputJSON[metaData[keyIndex]["key"]].toLowerCase()
            }
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"]) && Array.isArray(actualInputJSON[metaData[keyIndex]["key"]])){
              actualInputJSON[metaData[keyIndex]["key"]]  = Object.assign([],actualInputJSON[metaData[keyIndex]["key"]].map(aitem=>aitem.toLowerCase()))
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_VALUE : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"]) && typeof actualInputJSON[metaData[keyIndex]["key"]]!=="object"){
              if(actualInputJSON[metaData[keyIndex]["key"]] === metaData[keyIndex]["value"]){
                actualInputJSON[metaData[keyIndex]["addKey"]] = metaData[keyIndex]["addValue"]
              }
            }
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"]) && Array.isArray(actualInputJSON[metaData[keyIndex]["key"]])){
              if(JSON.stringify(actualInputJSON[metaData[keyIndex]["key"]]) === JSON.stringify(metaData[keyIndex]["value"])){
                actualInputJSON[metaData[keyIndex]["addKey"]]  = metaData[keyIndex]["addValue"]
              }
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.SEPARATE_MAPPED_LIST_VALUES:
            let theMappedListKey = metaData[keyIndex]["key"]
            if(actualInputJSON.hasOwnProperty(theMappedListKey)){
              let theMappedValueKey = metaData[keyIndex]["mappedValueKey"]
              let theMappedKey = metaData[keyIndex]["mappedKey"]
              let theMappedKeyList = metaData[keyIndex]["mappedKeyList"]
              let separatedList = [];
              actualInputJSON[theMappedListKey].map((mappedObject,moIndex) => {
                  mappedObject[theMappedValueKey].map((mappedListObject,mlIndex) => {
                      let separatedListObject = {
                        ...mappedListObject
                      }
                      separatedListObject[theMappedKeyList] = mappedObject[theMappedKey]
                      separatedList.push(separatedListObject)
                      return mlIndex;
                  })
                  return moIndex;
              })
              actualInputJSON[theMappedListKey] = Object.assign([],separatedList)
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.MAP_LISTS_VALUES:
            let theMainListKey = metaData[keyIndex]["mainListKey"]
            let theMappingListKey = metaData[keyIndex]["mappingListKey"]
            if(actualInputJSON.hasOwnProperty(theMainListKey) && actualInputJSON.hasOwnProperty(theMappingListKey)){
                let theMappedkey = metaData[keyIndex]["mappedKey"]
                let theMappingKeyMain = metaData[keyIndex]["mappingKeyMain"]
                let theMappingKeyMapList = metaData[keyIndex]["mappingKeyMapList"]
                let newMappedListKey = metaData[keyIndex]["key"]
                let newMappedListObject = {}
                let newMappedList = []                
                actualInputJSON[theMainListKey].map((mainValues,mainIndex) => {
                    newMappedListObject = { ...mainValues }
                    actualInputJSON[theMappingListKey].map((mapValues,mapIndex) => {
                        if(mapValues?.[theMappingKeyMapList] === mainValues?.[theMappingKeyMain]){
                            let pushValues = Object.assign({},mapValues)
                            delete pushValues[theMappingKeyMapList]
                            if(newMappedListObject.hasOwnProperty(theMappedkey)){
                              newMappedListObject[theMappedkey].push(pushValues)
                            } else {
                              newMappedListObject[theMappedkey] = Object.assign([],[pushValues])
                            }
                        }
                        return mapIndex;
                    })
                    if(!newMappedListObject.hasOwnProperty(theMappedkey) && metaData[keyIndex].hasOwnProperty("emptyMapping")){
                        let pushValues = {}
                        Object.keys(metaData[keyIndex]["emptyMapping"]).map((emptyMapKeys,emkIndex) => {
                            pushValues[emptyMapKeys] = mainValues[metaData[keyIndex]["emptyMapping"][emptyMapKeys]]
                            return emkIndex;
                        })
                        newMappedListObject[theMappedkey] = Object.assign([],[pushValues])
                    }
                    newMappedList.push(newMappedListObject)
                    return mainIndex;
                })
                actualInputJSON[newMappedListKey] = Object.assign([],newMappedList)
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.MERGE_LIST : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"]) && actualInputJSON.hasOwnProperty(metaData[keyIndex]["baseListKey"]) && actualInputJSON.hasOwnProperty(metaData[keyIndex]["mergeListKey"])){
              let mergedList = [];
              let baseList = Object.assign([],actualInputJSON[metaData[keyIndex]["baseListKey"]]);
              let listToBeMerged = Object.assign([],actualInputJSON[metaData[keyIndex]["mergeListKey"]]);
              baseList.map((baseItem,index) => {
                let mergeKey = metaData[keyIndex]["commonKey"];
                let mergeIndex = listToBeMerged.findIndex(mergeItem => mergeItem[mergeKey]===baseItem[mergeKey]);
                if(mergeIndex>=0){
                  mergedList.push({
                    ...baseItem,
                    ...listToBeMerged[mergeIndex]
                  })
                }
                return index
              })
              delete actualInputJSON[metaData[keyIndex]["baseListKey"]]
              delete actualInputJSON[metaData[keyIndex]["mergeListKey"]]
              actualInputJSON[metaData[keyIndex]["key"]] = Object.assign([],mergedList)
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_PAIR : 
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
                let keyValuePairData = {};
                actualInputJSON[metaData[keyIndex]["key"]].map((item,index) => {
                  keyValuePairData[item[metaData[keyIndex]['keyValuePairKey']]] = item[metaData[keyIndex]['keyValuePairValueKey']]
                });
                actualInputJSON[metaData[keyIndex]["key"]] = keyValuePairData;
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_TO_OBJECT : 
            let keyValuePairObjectData = [];
            if(isEmptyObject(actualInputJSON[metaData[keyIndex]["key"]])){
                console.log("AUGMENTEDSAMPLES empty",actualInputJSON)
                let objectValue = {};
                objectValue[metaData[keyIndex]['keyValuePairKey']] = metaData[keyIndex]['keyValuePairKeyEmpty'];
                objectValue[metaData[keyIndex]['keyValuePairValueKey']] = metaData[keyIndex]['keyValuePairValueEmpty'];
                keyValuePairObjectData.push(objectValue)
            } else { 
              console.log("AUGMENTEDSAMPLES non-empty",actualInputJSON)
              Object.keys(actualInputJSON[metaData[keyIndex]["key"]]).map((itemkey) => {
                let objectValue = {};
                objectValue[metaData[keyIndex]['keyValuePairKey']] = itemkey;
                objectValue[metaData[keyIndex]['keyValuePairValueKey']] = actualInputJSON[metaData[keyIndex]["key"]][itemkey]
                keyValuePairObjectData.push(objectValue)
              });
            }
            actualInputJSON[metaData[keyIndex]["key"]] = Object.assign([],keyValuePairObjectData);
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT :
            let formedObject = {};
            for(let index=0;index<metaData[keyIndex]["keysList"].length;index++){
              if(!isEmpty(actualInputJSON[metaData[keyIndex]["keysList"][index]])){
                formedObject[metaData[keyIndex]["keysList"][index]] = actualInputJSON[metaData[keyIndex]["keysList"][index]]
              }
            }
            actualInputJSON[metaData[keyIndex]["key"]] = formedObject;
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT_LIST :
            let formedObjectList = [];
            for(let index=0;index<metaData[keyIndex]["keysList"].length;index++){
              if(!isEmptyObject(actualInputJSON[metaData[keyIndex]["keysList"][index]])){
                formedObjectList.push(actualInputJSON[metaData[keyIndex]["keysList"][index]])
              }
            }
            actualInputJSON[metaData[keyIndex]["key"]] = Object.assign([],formedObjectList);
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT :
            if(!isEmpty(actualInputJSON[metaData[keyIndex]["key"]]) && (!metaData[keyIndex].hasOwnProperty("forSample") || metaData[keyIndex]["forSample"]===forSample)){
              let formedFileObject = {};
              let fileURLValue = actualInputJSON[metaData[keyIndex]["key"]]
              // formedFileObject[metaData[keyIndex]["fileNameKey"]]=getFileNameFromURL(fileURLValue)
              formedFileObject[metaData[keyIndex]["fileNameKey"]]=metaData[keyIndex]["fileName"]+getFileExtension(fileURLValue)
              formedFileObject[metaData[keyIndex]["fileURLKey"]]=fileURLValue
              actualInputJSON[metaData[keyIndex]["key"]] = Object.assign({},formedFileObject);
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.REPLACER :
            let replaceText = metaData[keyIndex]["replaceText"];
            for(let index=0;index<metaData[keyIndex]["replacers"].length;index++){
              if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["replacers"][index]["value_key"]) && typeof actualInputJSON[metaData[keyIndex]["replacers"][index]["value_key"]] === "string"){
                replaceText = replaceText.replace(metaData[keyIndex]["replacers"][index]["text"],actualInputJSON[metaData[keyIndex]["replacers"][index]["value_key"]])
              }
              if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["replacers"][index]["value_key"]) && Array.isArray(actualInputJSON[metaData[keyIndex]["replacers"][index]["value_key"]])){
                let replaceListValue = Object.assign([],actualInputJSON[metaData[keyIndex]["replacers"][index]["value_key"]])
                replaceListValue = metaData[keyIndex]["replacers"][index]?.["sort"] ? sortList(replaceListValue) : replaceListValue
                replaceText = replaceText.replace(metaData[keyIndex]["replacers"][index]["text"],replaceListValue[metaData[keyIndex]["replacers"][index]["replace_value_index"]])
              }
            }
            actualInputJSON[metaData[keyIndex]["key"]] = replaceText;
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING :
              if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
                actualInputJSON[metaData[keyIndex]["key"]] = JSON.stringify(actualInputJSON[metaData[keyIndex]["key"]]);
              }
              break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON :
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
              actualInputJSON[metaData[keyIndex]["key"]] = JSON.parse(actualInputJSON[metaData[keyIndex]["key"]]);
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE : 
            if(metaData[keyIndex].hasOwnProperty("referKey")){
              if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
                if(
                  (typeof actualInputJSON[metaData[keyIndex]["referKey"]]==="object" && (JSON.stringify(actualInputJSON[metaData[keyIndex]["referKey"]]) === JSON.stringify(metaData[keyIndex]["referKeyValue"])))
                  || (actualInputJSON[metaData[keyIndex]["referKey"]] === metaData[keyIndex]["referKeyValue"])
                ){delete actualInputJSON[metaData[keyIndex]["key"]]}
              }
            } else {
              delete actualInputJSON[metaData[keyIndex]["key"]]
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT : 
            if(metaData[keyIndex].hasOwnProperty("referKey")){
              if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"])){
                if(
                  metaData[keyIndex].hasOwnProperty("referKeyValue") &&
                  ((actualInputJSON[metaData[keyIndex]["referKey"]] !== metaData[keyIndex]["referKeyValue"])
                  || (typeof actualInputJSON[metaData[keyIndex]["referKey"]] ==="object" && (JSON.stringify(actualInputJSON[metaData[keyIndex]["referKey"]]) !== JSON.stringify(metaData[keyIndex]["referKeyValue"]))))
                  || (metaData[keyIndex].hasOwnProperty("referKeyList") && !(metaData[keyIndex]["referKeyList"].includes(actualInputJSON[metaData[keyIndex]["referKey"]])))
                ){delete actualInputJSON[metaData[keyIndex]["key"]]}
              }
            }
            break;
          case MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST :
            if(actualInputJSON.hasOwnProperty(metaData[keyIndex]["key"]) && !isEmptyList(actualInputJSON[metaData[keyIndex]["key"]])){
              let tranformObjectList = Object.assign([],actualInputJSON[metaData[keyIndex]["key"]]);
              for(let index=0;index<tranformObjectList.length;index++){
                tranformObjectList[index] = getActualInputJSON(tranformObjectList[index],modelCode,forSample,metaData[keyIndex]["transformList"])
              }
              actualInputJSON[metaData[keyIndex]["key"]] = Object.assign([],tranformObjectList)
            }
            break;
          default :
            actualInputJSON[metaData[keyIndex]["key"]]  = actualInputJSON[metaData[keyIndex]["key"]]
        }
    }
  }
  return actualInputJSON;
}

export const getApproximateCreditValue = (units,creditsData,modelCode,data,type=EMPTY_STRING) => {
  if(modelCode.includes("LLM_")){
    let parsedCreditJSON = JSON.parse(creditsData)
    let provider = data?.provider || "openai"
    let llm = (data?.llm || "chatgpt") + type
    return Number(units) * Number(parsedCreditJSON[[provider.toLowerCase(),llm.toLowerCase()].join(SEPARATORS.UNDERSCORE)]);
  } else {
    return (Number(units) * Number(creditsData))
  }
}

export const getStructuredName = (name) => {
  let structuredName;
  switch(name){
    case "openai" : structuredName="OpenAI"; break;
    case "cohere" : structuredName="Cohere"; break;
    case "azure_openai" : structuredName="Azure_OpenAI"; break;
    case "open-source" : structuredName="Open-Source"; break;
    case "ai21" : structuredName="AI21"; break;
    case "chatgpt" : structuredName="ChatGPT"; break;
    case "chatgptinstruction" : structuredName="ChatGPT (Instructions)"; break;
    case "gpt4" : structuredName="GPT4"; break;
    case "gpt4-v" : structuredName="GPT4-V"; break;
    case "gpt4instruction" : structuredName="GPT4 (Instructions)"; break;
    case "command" : structuredName="Command"; break;
    case "command-nightly" : structuredName="Command-Nightly"; break;
    case "jurassic-mid" : structuredName="Jurassic-MID"; break;
    case "jurassic-ultra" : structuredName="Jurassic-ULTRA"; break;
    case "llama2-70b-chat" : structuredName="Llama2-70b-chat"; break;
    case "mixtral-8x7b-instruct-v0.1" : structuredName="Mixtral-8x7b-instruct-v0.1"; break;
    case "aws_transcribe" : structuredName="AWS_Transcribe"; break;
    case "azure_transcribe" : structuredName="Azure_Transcribe"; break;
    case "google_transcribe" : structuredName="Google_Transcribe"; break;
    case "openai_transcribe" : structuredName="OpenAI_Transcribe"; break;
    case "azure_ocr" : structuredName="Azure_OCR"; break;
    case "aws_ocr" : structuredName="AWS_OCR"; break;
    case "provider" : structuredName="Provider"; break;
    case "tool" : structuredName="Tool"; break;
    default : structuredName = name;
  }
  return structuredName;
}

// to get the strcture data for the labels kind of data (ouput labels, languages)
export const convertLabelsTextToLabelsJSON = (text) => {
  const labelsData = text.split('",').map(label => label.replace(/"/g, ""));;
  let separatedLabels = {};
  let labelsList = [];

  labelsData.forEach(labelData => {
    const [type, labelName] = labelData.includes(':')?labelData.split(':'):["common",labelData];
    const cleanedLabelName = getLabelText(labelName.trim());
    const clanedTypeName = getLabelText(type.trim());
    if (separatedLabels[clanedTypeName]) {
      separatedLabels[clanedTypeName].labels.push(cleanedLabelName);
    } else {
      separatedLabels[clanedTypeName] = { type: clanedTypeName, labels: [cleanedLabelName] };
    }

    if (!labelsList.includes(cleanedLabelName)) {
      labelsList.push(cleanedLabelName);
    }
  });

  const separatedLabelsArray = Object.values(separatedLabels).sort((a, b) => b.labels.length - a.labels.length);;

  const resultJSON = {
    separatedLabels: separatedLabelsArray,
    labelsList: labelsList
  };

  return resultJSON
}

export const converMuliFormatJSONToKeyValue = (json) => {
  let resultObject = {}
  let result = Object.assign([],getMultiFormatJSONList(json));
  if(result.length > 0){
    result.map((resultItem) => {
      let [key,value] = resultItem.split(SEPARATORS.JSON_SEPARATION);
      resultObject[key.trim()] = value.trim()
    })
  }
  console.log("converMuliFormatJSONToKeyValue",resultObject)
  return resultObject;
}

export const getMultiFormatJSONList = (json, parentKey = "") => {
  let result = [];

  for (const key in json) {
    if (typeof json[key] === 'string' || typeof json[key] === 'number' || typeof json[key] === 'boolean') {
      result.push(`${parentKey ? parentKey + '-' + key : key} ${SEPARATORS.JSON_SEPARATION} ${json[key]}`);
    } else if (Array.isArray(json[key])) {
      if (json[key].length > 0 && typeof json[key][0] === 'string') {
        result.push(`${parentKey ? parentKey + '-' + key : key} ${SEPARATORS.JSON_SEPARATION} ${json[key].join(', ')}`);
      } else {
        json[key].forEach((item, index) => {
          result.push(...getMultiFormatJSONList(item, `${parentKey ? parentKey + '-' + key : key}${index + 1}`));
        });
      }
    } else if (typeof json[key] === 'object') {
      result.push(...getMultiFormatJSONList(json[key], parentKey ? parentKey + '-' + key : key));
    }
  }

  return result;
}