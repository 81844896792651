import { EMPTY_STRING } from "../../utils/Constants"

import { getDateFromInput, getFileSize, getLabelText, getTimeFromDateString, isEmpty, isEmptyList, isEmptyObject } from "../../utils/UtilityFunctions"

import classes from '../../components/output_types/table/TableTypeOutput.module.css';
export const FILE_HEADINGS_TEMP = [
    // {
    //     key: 'file_id',
    //     label: "File Id",
    //     class: classes.TableCellLongText
    // },
    {
        key: 'filename',
        label: "File Name",
        class: classes.TableCellLongText
    },
    {
        key: 'file_size',
        label: "File Size",
        class:classes.TableCell

    },
    {
        key: 'status',
        label: "Status",
        class:classes.TableCell

    },
    {
        key: 'status_code',
        label: "Status Code"
    },
    {
        key: 'message',
        label: "Message",
        class: classes.TableCellMediumText

    },
    {
        label: 'Start Time',
        key: "model_start_time",
        class: classes.TableCellDate

    }, {
        key: 'model_end_time',
        label: "End Time",
        class: classes.TableCellDate

    }, {
        key: 'units',
        label: "Units"
    },
    {
        key: 'credits_consumed',
        label: "Credits Consumed"
    }, 
    // {
    //     key: 'credit_deduction_status',
    //     label: "Credit Deduction Status"
    // },
    {
        key: 'download_url',
        label: "Result"
    },

]


export const FILE_HEADINGS = [
    // {
    //     key: 'fileId',
    //     label: "File Id",
    //     class: classes.TableCellLongText

    // },
    {
        key: 'fileName',
        label: "File Name",
        class: classes.TableCellLongText


    },
    {
        key: 'fileSize',
        label: "File Size",
        class:classes.TableCell
    },
    {
        key: 'fileStatus',
        label: "Status"
    },
    {
        key: 'statusCode',
        label: "Status Code"
    },
    {
        key: 'message',
        label: "Message",
        class: classes.TableCellMediumText

    },
    {
        label: 'Start Time',
        key: "modelStartTime",
        isLongText:true,
        class: classes.TableCellDate

    }, {
        key: 'modelEndTime',
        label: "End Time",
        class: classes.TableCellDate
        // isLongText:true

    }, {
        key: 'units',
        label: "Units"
    },
    {
        key: 'creditsConsumed',
        label: "Credits Consumed"
    }, 
    // {
    //     key: 'creditDeductionStatus',
    //     label: "Credit Deduction Status"
    // },
    {
        key: 'downloadUrl',
        label: "Result"
    },

]

export const INITIAL_STATE = {
    taskList: [],
    isLoading: true,
    isErrorInAPI: false,
    headingList: [],
    searchedTask : EMPTY_STRING
}

// get filter task while search
export const getFilteredTasks = (allTasks, searchValue) => {
    console.log('Search Value', searchValue)
    console.log('All Tasks', allTasks)
    let filteredTasks = Object.assign([], allTasks)
    filteredTasks = filteredTasks.filter((item) => item.taskId.toLowerCase().includes(searchValue.toLowerCase()))
    return ({
        filteredTasks
    })
}

const HEADING_LIST = [{
    key: 'task_id',
    label: getLabelText('task_id'),
    isDate: false,
    renderItem: (item) => {
        return (

            <span className="d-flex align-items-center" onClick={() => this.onClickTableRow(item)}>
                {item.task_id}
            </span>

        )
        // unsortable: true,
    }
}, {
    key: 'task_created_on',
    label: getLabelText('task_created_on'),
    isDate: true,
    // unsortable: true,

}, {
    key: 'task_ended_on',
    label: getLabelText('task_ended_on'),
    isDate: true,
    // unsortable: true,

}, {
    key: 'total_credits_consumed',
    label: getLabelText('total_credits_consumed'),
    isDate: false,
    // unsortable: true,

}, {
    key: 'task_status',
    label: getLabelText('task_status'),
    isDate: false,
    // unsortable: true,

}]

export const handleAsyncTasks = ({ data }) => {
    let tasks = []

    if(!isEmptyObject(data.response) && !isEmptyList(data.response.tasks)){
        let taskData = data.response.tasks

        taskData.map((task) => {

            let operations = []

            task.operations.map((item) => {

                let file = {
                    fileId: item.file_id,
                    fileName: item.filename,
                    fileSize: getFileSize(item.file_size),
                    units: item.units,
                    inputTokens: item.input_tokens || 0,
                    outputTokens: item.output_tokens || 0,
                    creditsConsumed: item.credits_consumed || 0,
                    creditDeductionStatus: item.credit_deduction_status,
                    fileStatus: getLabelText(item.status),
                    modelStartTime: getDateFromInput(item.model_start_time),
                    modelEndTime: isEmpty(item.model_end_time) ? "-" : getDateFromInput(item.model_end_time),
                    statusCode: item.status_code,
                    message: item.message,
                    downloadUrl: EMPTY_STRING
                }

                operations.push(file)

                return
            })

            let taskItem = {
                taskId: task.task_id,
                createdOn: getDateFromInput(task.task_created_on),
                endedOn: isEmpty(task.task_ended_on) ? "-" : getDateFromInput(task.task_ended_on),
                totalCreditsConsumed: isEmpty(task.total_credits_consumed) ? 0 : task.total_credits_consumed,
                taskStatus: getLabelText(task.task_status),
                operations: operations,
                modelCode: task.model_code

            }
            tasks.push(taskItem)
            return null
        })

        console.log("tasks", data)
    }
    
    return {
        isLoading: false,
        taskList: tasks,
        // headingList:HEADING_LIST
    }
}

export const handleAsyncTasksError = () => {
    return {
        isLoading: false,
        isErrorInAPI: true
    }
}