import cx from 'classnames'
import classes from './ConfiguratorCard.module.css'
import ResponsiveButton from '../../components/ui_elements/button/ButtonNew';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';
import { BUTTON_TEXTS } from '../../strings/Strings';

function ConfiguratorCard(props) {
let {configurationContent,addConfigurationButton,updateConfigurationButton,deleteConfigurationButton,canAddOrEdit}=props


    return (
        <>
        <div className={cx(['col-12 col-sm-6 col-md-4 col-lg-3 px-3 py-1'])}>
        <div className={cx(classes.ConfiguratorCard,"d-flex flex-column h-100")}>

        {configurationContent.keyPresent &&
        <div className={cx(classes.activeKeyClass,(configurationContent.useKey?classes.inUse:classes.notInUse))}>
            {configurationContent.useKey ? 
            "In Use" : "Not In Use"}
        </div>
        }

            <div className={cx(classes.ConfiguratorCardImgContainer,'text-center')}>
                <img className={cx(['mt-4 mb-3'])} src={configurationContent.icon} alt={configurationContent.label}/>
                <h6>{configurationContent.label}</h6>
                {!(configurationContent.keyPresent) && canAddOrEdit &&
                <ResponsiveButton
                    containerClass={"col-12 mt-2 text-center mt-auto"}
                    className={cx("col-12",classes.ConfiguratorRemoveBR)}
                    label = {BUTTON_TEXTS.ADD_KEY}
                    onClickHandler = {() => addConfigurationButton(configurationContent)}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                    borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                />
                }
                
                {configurationContent.keyPresent && canAddOrEdit &&
                <div className={cx(classes.ButtonsContainer)}>
                <ResponsiveButton
                    containerClass={"col-6 mt-2 text-center mt-auto"}
                    className={cx("col-12",classes.ConfiguratorRemoveBR)}
                    label = {BUTTON_TEXTS.UPDATE_KEY}
                    onClickHandler = {() => updateConfigurationButton(configurationContent)}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                    borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                />
                <ResponsiveButton
                    containerClass={"col-6 mt-2 text-center mt-auto"}
                    className={cx("col-12",classes.ConfiguratorDeleteClass,classes.ConfiguratorRemoveBR)}
                    label = {BUTTON_TEXTS.DELETE}
                    onClickHandler = {() => deleteConfigurationButton(configurationContent)} 
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                    borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                />

                </div>
                }

          </div>
        </div>
        </div>
        </>
    )
}
export default ConfiguratorCard;



