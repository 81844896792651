import { Dropdown } from "react-bootstrap";
import cx from 'classnames';
import classes from './SearchInput.module.css';

function SearchDropDown(props){
    let { selectedOption, dropOptionList, onSelectDropOption } = props;

    let dropButtonClass = cx([classes.DropButton])
    let dropMenuClass = cx([classes.DropMenu])
    let dropItemClass = cx([classes.DropItem])

    return (
        <Dropdown>
            <Dropdown.Toggle id={selectedOption?.key || selectedOption} className={dropButtonClass}>
                {selectedOption?.label || selectedOption}
            </Dropdown.Toggle>
            <Dropdown.Menu className={dropMenuClass}>
                {
                    dropOptionList.map((option, index) => {
                        if(option?.hide){
                            return null
                        }
                        return <Dropdown.Item key={index} className={dropItemClass} onClick={() => onSelectDropOption(option)}>{option?.value || option}</Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SearchDropDown;