import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js"
import { ROUTE_CONSTANTS } from "../../routes/RouteConstants"
import UserPool from "./UserPool"
import { EVENT } from "../../../src/utils/Constants"
import { AuditLog } from  "../../../src/utils/AuditUtils"
import { isEmptyObject, getUTCTime } from "../../utils/UtilityFunctions"
import axios from "axios"
import { GET_ACCOUNTS, VALIDATE_USER_SUBSCRIPTION } from "../../urls/Urls"

// const user = new CognitoUser({
//     Username: email,
//     Pool: UserPool
// })
// const authDetails = new AuthenticationDetails({
//     Username: email,
//     Password: password
// })
export const getCognitoUser = (email) => {
    const user = new CognitoUser({
        Username: email.trim(),
        Pool: UserPool
    })

    return user
}

export const authenticateUser = (email, password, redirectUrlObj, onFailureHandler, onNewPasswrdHandler, generateTOTPCodeHandler) => {

    const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password
    })

    let user = getCognitoUser(email)
    
    //audit log
    let startDateTime = new Date()
    
    //audit log - common properties
    let eventAttributes = {}
    let eventDetails = EVENT.AUTHENTICATION_CATEGORY.LOGIN
    eventDetails = {
        ...eventDetails, 
        eventCategory: EVENT.AUTHENTICATION_CATEGORY.CATEGORY_NAME,
        eventStartDateTime: startDateTime
    }

    user.authenticateUser(authDetails, {
        onSuccess: (data) => {
            // console.log("data", data.getAccessToken().getJwtToken())
            
            if (data) {
                console.log('authenticateUser - getCognitoUser: ', data.getIdToken())
                
                //event details
                eventDetails = { ...eventDetails,
                    eventStatusCode: data?.status || '200',
                    eventStatus: 'Success',
                    eventMessage: data?.statusText || 'User authenticated successfully'
                }
                
                //event attributes
                if (!isEmptyObject(data.getIdToken())) {
                    const { payload } = data.getIdToken()
                    if (!isEmptyObject(payload)) {
                        eventAttributes = {
                            awsId: payload['cognito:username'],
                            email: payload?.email
                        }
                    }
                    axios.post(VALIDATE_USER_SUBSCRIPTION,
                        {
                            userEmail : payload?.email
                        },
                        {
                            headers : {
                                Authorization: "Bearer " + getCognitoUserAccessToken()
                            }
                        }
                    ).then(() => {
                        window.location = ROUTE_CONSTANTS.STUDIO_HOME
                    }).catch(() => {
                        window.location = ROUTE_CONSTANTS.PRICING
                    }).finally(() => {
                        AuditLog(eventDetails, eventAttributes, null)
                    })
                } else {
                    window.location = ROUTE_CONSTANTS.PRICING
                }
            } else {
                window.location = ROUTE_CONSTANTS.PRICING
            }
        },
        onFailure: (err) => {
            console.error("cognito login", err)
            
            if (err) {
                eventDetails = {...eventDetails,
                    eventStatusCode: err?.response?.status || null,
                    eventStatus: 'Failure',
                    eventMessage: err?.message || err?.response?.statusText || 'User authentication failed',
                }
            }
           
            eventAttributes = {
                email: email
            }

            //call audit log method
            AuditLog (eventDetails, eventAttributes, null)
            onFailureHandler(err)
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
            onNewPasswrdHandler(user, userAttributes)
        },

        totpRequired: function (secretCode) {
            generateTOTPCodeHandler(user)
        },

       
    })


}


export const resendConfirmationCode = (email, onFailurHandler) => {
    let user = getCognitoUser(email)

    user.resendConfirmationCode((err, data) => {

        if (err) {
            console.log("err", err)
            onFailurHandler(err)
            return
        }
        console.log("verificaiton", data)
    })

}

export const getErrorMessage = (errorObject) => {
    if (errorObject.code === "CodeMismatchException") {
        return "Invalid verification code provided."
    }
    return errorObject.message
}

export const getCognitoUserAccessToken = () => {

    let currentUser = UserPool.getCurrentUser()
    if(currentUser){
        let accessToken = currentUser.getSession((err,session)=>{
            if(err){
                console.log("session error")
                return
            }

            return currentUser.getSignInUserSession().getAccessToken().getJwtToken()
        })
        if(accessToken===undefined){
            currentUser.signOut()
            let cuurentUrl = window.location.href.split('?')[0];
            window.location.href = cuurentUrl
        }
        return accessToken
    }
    return
}