export const ROUTE_CONSTANTS = Object.freeze({
    HOME: "/",
    HOME_PAGE: '/',
    MODELS: "/playground",
    PRICING: "/pricing",
    SUBCRIBE: "/subscribe",
    CONTACT_US: "/contact-us",
    MODEL_DETAIL: "/model-detail",
    LLM_MODEL_DETAIL: "/llm-model-detail",
    MODEL_PLAYGROUND: "/playground",
    DEV_STUDIO: "/dev-studio",
    STUDIO_HOME : "/studio",
    TASK: "/tasks",
    REQ_A_DEMO: "/request-demo",
    HELP: "/help",
    BLOG: "/blogs",
    USECASE: "/use-cases",
    PARTNER: "/partner",
    SERVICES: "/services",
    BECOME_A_PARTNER: "/become-a-partner",
    WHITE_PAPER: "/whitepaper",
    PLAYBOOK: "/playbook",
    CASE_STUDY: "/case-study",
    ABOUT_VIVASAI: "/about-vivasai",
    CAREERS: "/careers",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS: "/terms",
    REFUND_POLICY:"/cancellation-and-refund-policy",
    EULA:"/eula",
    USER_MANAGEMENT: "/manage",
    ACCOUNT_MANAGEMENT: "/accounts",
    MY_SUBSCRIPTIONS : "/subscriptions",
    TRY_FOR_FREE: "/register",
    LOGIN: "/login",
    FAQ : '/faq',
    PAYMENT_CONFIRMATION : '/payment-confirmation',
    HTTP_CODE_DOC:"/http-code",
    RESET_PASSWORD:"/reset-password",
    MY_PROFILE:"/profile",
    DISCOUNT: "/discount",
    PUBLISH_MODEL: "/publish-model",
    INDUSTRY : "/industries",
    PRODUCT_GPT_FLOW_OLD:"/leaf-flow",
    PRODUCT_GPT_FLOW:"/leaf-essentials",
    PRODUCT_DOCQSPHERE:"/leaf-converse",
    PRODUCT_DOCQSPHERE_OLD:"/docqsphere",
    PRODUCT_DEV_COPILOT_OLD:"/dev-copilot-engine",
    PRODUCT_DEV_COPILOT:"/leaf-concierge",
    PRODUCT_LEAF_IDP : "/leaf-idp",
    ANNOUNCEMENTS : "/announcements",
    LEAF_ESSENTIALS : {
        STUDIO : "/leaf-essentials-studio",
        MODEL_DETAILS : "/leaf-essentials-studio/model-details",
        SEED_MODEL_DETAILS : "/leaf-essentials-studio/seed-model-details",
        PUBLISH_MODEL : "/leaf-essentials-studio/publish-model"
    },
    LEAF_CONVERSE : {
        STUDIO : "/leaf-converse-studio",
        MODEL_DETAILS : "/leaf-converse-studio/model-details",
        PUBLISH_MODEL : "/leaf-converse-studio/publish-model"
    },
    LEAF_IDP : {
        STUDIO : "/leaf-idp-studio",
        BROWSE_MODELS : "/leaf-idp-studio/browse-model",
        EXECUTE_MODEL : "/leaf-idp-studio/execute-model",
        BUILDOWNMODEL : "/leaf-idp-studio/build-model",
        MODEL_DETAILS : "/leaf-idp-studio/model-details",
        COREMODEL : "/leaf-idp/core-models",
        AUGMENTEDMODEL : "/leaf-idp/augmented-models",
        ENTERPRISEMODEL : "/leaf-idp/enterprise-models",
        FAVORITEMODEL : "/leaf-idp/favorite-model",
        USER_MANAGE : "/leaf-idp/manage",
        ACCOUNT_MANAGE : "/leaf-idp/accounts",
        PRICING : "/leaf-idp/pricing"
    }
})