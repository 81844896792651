import { EMPTY_STRING, SEPARATORS } from "../strings/Strings"
import { MODEL_CODES } from "./Constants"

export const BOUNDARIES = {
    AUDIO_RECORDING : {
        TP_SR_SST : {
            audio : 60
        },
        PT_SR_SST : {
            audio : 30
        },
        TP_LLM_SEE : {
            file : 300
        },
        TP_LLM_SQA : {
            file : 300
        }
    },
    ARRAY : {
        PT_L_SSS : {
            target_context : {
                max_count : 5
            }
        },
        CT_QA_DQA : {
            question : {
                max_count : 10
            }
        },
        TP_LLM_DQA : {
            question : {
                max_count : 10
            }
        },
        TP_LLM_SQA : {
            question : {
                max_count : 10
            }
        },
        TP_ASYNCLLM_DS : {
            focus : {
                max_characters : 100,
                max_count : 5
            },
            audience : {
                max_characters : 100,
                max_count : 5
            }
        },
        TP_LLM_WS: {
            questions : {
                max_characters : 1000,
                max_count : 15
            }
        },
        TP_LLM_TS : {
            summary_guidelines : {
                max_characters : 200
            },
            tone : {
                max_characters : 100
            },
            language_style : {
                max_characters : 100
            },
            audience : {
                max_characters : 100
            }
        },
        TP_LLM_CDS : {
            summary_guidelines : {
                max_characters : 200
            },
            tone : {
                max_characters : 100
            },
            language_style : {
                max_characters : 100
            },
            audience : {
                max_characters : 100
            }
        }
    },
    SLIDER : {
        PT_S_STS : {
            summary_percentage : [35,55]
        },
        PT_CV_IS : {
            top_k : [1,5]
        },
        TP_LLM_TS : {
            length : [1,3000]
        },
        TP_LLM_CDS : {
            length : [1,5000]
        },
        TP_LLM_TT : {
            max_rephrase : [1,3]
        },
        TP_LLM_DQA : {
            top_k : [2,5]
        }
    }
}

export const DEFAULT_VALUES = {
    DROP_DOWN : {
        TP_LLM_CDE : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "Azure_OCR"
        },
        TP_LLM_TQA : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_GC : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_PII : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_DC : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "Azure_OCR"
        },
        TP_LLM_DQA : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "Azure_OCR"
        },
        TP_LLM_CDS : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "Azure_OCR"
        },
        TP_LLM_TS : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_WS : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_LT : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_IDE : {
            provider : "OpenAI",
            llm : "ChatGPT",
            id_type : "GENERIC_ID"
        },
        TP_LLM_CIDE : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "Azure_OCR"
        },
        TP_LLM_DPII : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "Azure_OCR"
        },
        TP_LLM_SEE : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "OpenAI_Transcribe"
        },
        TP_LLM_GTE : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_SQA : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            tool : "OpenAI_Transcribe"
        },
        TP_LLM_CTSA : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            context_splitter : "sentence"
        },
        TP_LLM_CID : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview"
        },
        TP_LLM_TG : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            default_skill : "1"
        },
        TP_LLM_TT : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            default_skill : "1"
        },
        TP_LLM_CTED : {
            provider : "OpenAI",
            llm : "gpt-4-1106-preview",
            context_splitter : "sentence"
        }
    },
    SLIDER : {
        CT_CV_SL : {
            threshold : "MEDIUM"
        },
        PT_S_STS : {
            summary_percentage : "35"
        },
        PT_CV_IS : {
            top_k : "1"
        },
        TP_ASYNCLLM_DS : {
            summary_size : "LONG"
        },
        TP_LLM_TS : {
            length : ["300","500"]
        },
        TP_LLM_CDS : {
            length : ["1000","1500"]
        },
        TP_LLM_TT : {
            max_rephrase : "1"
        },
        TP_LLM_DQA : {
            top_k : "5",
            chunk_overlap : "1000",
            chunk_size : "1024"
        }
    },
    SHOPPING_CART : {
        TP_LLM_CTSA : {
            output_labels : ["positive","neutral","negative"]
        }
    },
    RADIO_BUTTON : {
        TP_LLM_TS : {
            additional_information : "None"
        },
        TP_LLM_CDS : {
            additional_information : "None"
        },
        TP_LLM_TG : {
            generate_skills : "None"
        },
        TP_LLM_TT : {
            transform_skills : "None"
        }
    },
    TOGGLE : {
        TP_LLM_TT : {
            enable_default_skill : "True"
        },
        TP_LLM_TG : {
            enable_default_skill : "True"
        }
    }
}

export const DRAW_BOUNDING_BOX_MODELS = [
    MODEL_CODES.COMPARE_FACES,
    MODEL_CODES.OBJECT_DETECTOR,
    MODEL_CODES.FACE_ANALYZER,
    MODEL_CODES.PROTECTIVE_EQUIPMENT_DETECTOR,
    MODEL_CODES.BARCODE_DETECTION,
    MODEL_CODES.IMAGE_IDENTIFIER,
    MODEL_CODES.SIGNATURE_LOCALIZATION,
    MODEL_CODES.ID_EXTRACTOR,
    MODEL_CODES.DOCUMENT_ENTITY_EXTRACTOR
]

export const BULK_SYNC_UPLOAD_INPUTS = {
    TP_ASYNCVA_FS : ["image"]
}

export const MODEL_SLIDER_VALUES = {
    TP_CV_CF : ["NONE","LOW","MEDIUM","HIGH"],
    TP_SR_STTS : ['8KHz','16KHz','24KHz','44.1KHz','48KHz'],
    CT_CV_SL : ["LOW","MEDIUM","HIGH"],
    TP_ASYNCLLM_DS : ["SHORT","MEDIUM","LONG"],
    TP_LLM_DQA : {
        chunk_size : ["256","512","1024","2048","4096"],
        chunk_overlap : ["100","200","500","1000"]
    }
}

export const MODEL_PROCESS_TYPES = {
    LEAF_IDP : {
        EXTRACTION : ["TP_LLM_CDE","TP_LLM_CIDE"],
        REDACTION : ["TP_LLM_DPII"],
        CLASSIFICATION : ["TP_LLM_DC"],
        SUMMARIZATION : ["TP_LLM_CDS"]
    }
}

export const INPUT_STEPS = {
    TP_LLM_CDE : {
        "input" : ["file","provider","llm","tool"],
        "custom" : ["entities"]
    },
    TP_LLM_CDE_PUBLISH : {
        "input" : ["file"],
        "custom" : ["provider","llm","tool","entities"]
    },
    TP_LLM_CIDE : {
        "input" : ["file","provider","llm","tool"],
        "custom" : ["id_type","entities"]
    },
    TP_LLM_CIDE_PUBLISH : {
        "input" : ["file"],
        "custom" : ["provider","llm","tool","id_type","entities"]
    },
    TP_LLM_DPII : {
        "input" : ["file","provider","llm","tool"],
        "custom" : ["entities"]
    },
    TP_LLM_DPII_PUBLISH : {
        "input" : ["file"],
        "custom" : ["provider","llm","tool","entities"]
    },
    TP_LLM_CDS : {
        "input" : ["file","provider","llm","tool"],
        "custom" : ["samples","additional_information","summary_guidelines","tone","length","language_style","bullets","audience","other_informations"]
    },
    TP_LLM_CDS_PUBLISH : {
        "input" : ["file"],
        "custom" : ["provider","llm","tool","samples","additional_information","summary_guidelines","tone","length","language_style","bullets","audience","other_informations"]
    },
    TP_LLM_DC : {
        "input" : ["file","provider","llm","tool","fit_to_lim"],
        "custom" : ["classification_type","classifications","secondary_classifications","classification_samples","classification_samples_primary","classification_samples_secondary"]
    },
    TP_LLM_DC_PUBLISH : {
        "input" : ["file"],
        "custom" : ["provider","llm","tool","fit_to_lim","classification_type","classifications","secondary_classifications","classification_samples","classification_samples_primary","classification_samples_secondary"]
    },
    TP_LLM_SEE : {
        "input" : ["file","provider","llm","tool"],
        "custom" : ["entities"]
    },
    TP_LLM_PII : {
        "input" : ["text","provider","llm","tool"],
        "custom" : ["examples","custom_entities"]
    }
}

export const MODEL_LABELS = {
    TP_LLM_GC : [
        {
            key : "classifications",
            label : "Classification",
            info : "The primary class names and their descriptions form the basis of the classification system. Any modifications made to this field will have an impact on the secondary classifications and classification samples.",
            json_keys : [
                "primary_class", "description"
            ],
            empty_data : [{"primary_class" : "", "description":""}],
            json_keys_schema : {
                primary_class :{
                    size : 4,
                    label : "Primary Class",
                    placeholder_label : "Primary class",
                    type : "text",
                    empty : "",
                    required : true
                },
                description : {
                    size : 8,
                    label : "Description",
                    placeholder_label : "Primary class's description",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            validations : {
                primary_class : {
                    max : 200
                },
                description : {
                    max : 3000
                }
            },
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["secondary_classifications","primary_samples","primary_secondary_samples","primary_secondary_splitted_samples"]
                }
            ]
        },
        {
            key : "secondary_classifications",
            label : "Sub-classification",
            info : "The sub-classification contains details about the secondary class names, their descriptions, and the primary class name to which they belong. Any changes made to this field can potentially affect the classification samples.",
            json_keys : [
                "class","primary_class", "description"
            ],
            empty_data : [{"class":"","primary_class" : "", "description":""}],
            json_keys_schema : {
                class :{
                    size : 3,
                    label : "Secondary Class",
                    placeholder_label : "Secondary class",
                    type : "text",
                    empty : "",
                    required : true
                },
                primary_class :{
                    size : 3,
                    label : "Primary Class",
                    placeholder_label : "Primary class",
                    type : "drop",
                    empty : "",
                    required : true,
                    list_ref : {
                        ref_key : "classifications",
                        ref_type : "object",
                        list_ref_key : "primary_class"
                    }
                },
                description : {
                    size : 6,
                    label : "Description",
                    placeholder_label : "Secondary class's description",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            validations : {
                class :{
                    max : 200
                },
                primary_class : {
                    max : 200
                },
                description : {
                    max : 3000
                }
            },
            show : {
                refer_key : "classification_type",
                refer_value : ["1","3","5","7"]
            },
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["primary_samples","primary_secondary_samples","primary_secondary_splitted_samples"]
                }
            ]
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "classification_type",
            label : "Classification Method",
            formatted_list : [{"value":"0","label": "Primary Classification Only"},{"value":"1","label": "Primary and Secondary Classification"},{"value":"2","label": "Primary Classification with Splitter"},{"value":"3","label": "Primary and Secondary Classification with Splitter"},{"value":"4","label": "Primary Classification with Sample(s)"},{"value":"5","label": "Primary and Secondary Classification with Sample(s)"},{"value":"6","label": "Primary Classification with Splitter and Sample(s)"},{"value":"7","label": "Primary and Secondary Classification with Splitter and Sample(s)"}],
        },
        {
            key : "primary_samples",
            label : "Classification Samples",
            show : {
                refer_key : "classification_type",
                refer_value : ["4","6"]
            }
        },
        {
            key : "primary_secondary_samples",
            label : "Classification Samples",
            show : {
                refer_key : "classification_type",
                refer_value : ["5"]
            }
        },
        {
            key : "primary_secondary_splitted_samples",
            label : "Classification Samples",
            show : {
                refer_key : "classification_type",
                refer_value : ["7"]
            }
        },
        {
            key : "splitter",
            label : "Splitter Text",
            singleline_text : true,
            show : {
                refer_key : "classification_type",
                refer_value : ["2","3","6","7"]
            },
            validations : {
                max : 200
            },
        }
    ],
    TP_LLM_DC : [
        {
            key : "classifications",
            label : "Classification",
            info : "The primary class names and their descriptions form the basis of the classification system. Any modifications made to this field will have an impact on the secondary classifications and sample classification information.",
            json_keys : [
                "primary_class", "description"
            ],
            empty_data : [{"primary_class" : "", "description":""}],
            json_keys_schema : {
                primary_class :{
                    size : 4,
                    label : "Primary Class",
                    placeholder_label : "Primary class",
                    type : "text",
                    empty : "",
                    required : true
                },
                description : {
                    size : 8,
                    label : "Description",
                    placeholder_label : "Primary class's description",
                    type : "textarea",
                    empty : "",
                    required : true
                }
            },
            validations : {
                primary_class : {
                    max : 200
                },
                description : {
                    max : 3000
                }
            },
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["secondary_classifications","classification_samples_primary","classification_samples_secondary"]
                }
            ]
        },
        {
            key : "secondary_classifications",
            label : "Sub-classification",
            info : "The sub-classification contains details about the secondary class names, their descriptions, and the primary class name to which they belong. Any changes made to this field can potentially affect the sample classification information.",
            json_keys : [
                "class","primary_class", "description"
            ],
            empty_data : [{"class":"","primary_class" : "", "description":""}],
            json_keys_schema : {
                class :{
                    size : 3,
                    label : "Secondary Class",
                    placeholder_label : "Secondary class",
                    type : "text",
                    empty : "",
                    required : true
                },
                primary_class :{
                    size : 3,
                    label : "Primary Class",
                    placeholder_label : "Primary class",
                    type : "drop",
                    empty : "",
                    required : true,
                    list_ref : {
                        ref_key : "classifications",
                        ref_type : "object",
                        list_ref_key : "primary_class"
                    }
                },
                description : {
                    size : 6,
                    label : "Description",
                    placeholder_label : "Secondary class's description",
                    type : "textarea",
                    empty : "",
                    required : true
                }
            },
            validations : {
                class :{
                    max : 200
                },
                primary_class : {
                    max : 200
                },
                description : {
                    max : 3000
                }
            },
            show : {
                refer_key : "classification_type",
                refer_value : ["1","3"]
            },
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["primary_samples","classification_samples_secondary"]
                }
            ]
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "fit_to_lim",
            label : "Classify the file with limitations applied."
        },
        {
            key : "classification_type",
            label : "Classification Method",
            formatted_list : [{"value":"0","label": "Primary Classification Only"},{"value":"1","label": "Primary and Secondary Classification"},{"value":"2","label": "Primary Classification with Sample(s)"},{"value":"3","label": "Primary and Secondary Classification with Sample(s)"}],
        },
        {
            key : "classification_samples",
            info : "The samples contain files for the data mentioned in the sample classification information. Any changes made to this field can potentially affect the samples classification information. Additionally, the zip file should not include any internal folder structure.",
            max_count : 1,
            label : "Samples",
            show : {
                refer_key : "classification_type",
                refer_value : ["2","3"]
            },
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["primary_samples","classification_samples_secondary"]
                }
            ]
        },
        {
            key : "classification_samples_primary",
            info : "The number of samples classification information must precisely correspond with the number of files within the uploaded ZIP file, and the filenames contained within the ZIP file must precisely match those declared in the samples classification information.",
            label : "Samples Classification Information",
            show : {
                refer_key : "classification_type",
                refer_value : ["2"]
            }
        },
        {
            key : "classification_samples_secondary",
            info : "The number of samples classification information must precisely correspond with the number of files within the uploaded ZIP file, and the filenames contained within the ZIP file must precisely match those declared in the samples classification information.",
            label : "Samples Classification Information",
            show : {
                refer_key : "classification_type",
                refer_value : ["3"]
            }
        }
    ],
    TP_LLM_WS : [
        {
            key : "entities",
            label : "Entities",
            json_keys : [
                "entity_name", "entity_data_type", "description"
            ],
            empty_data : [{"entity_name" : "", "entity_data_type" : "","description":""}],
            max_count : 15,
            json_keys_schema : {
                entity_name :{
                    size : 4,
                    label : "Name",
                    placeholder_label : "Entity name",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_data_type : {
                    size : 3,
                    label : "Type",
                    placeholder_label : "Data type",
                    type : "drop",
                    list : [{"value":"string","label":"String"},{"value":"list_of_string","label":"List of Strings"}],
                    empty : "",
                    required : true
                },
                description : {
                    size : 5,
                    label : "Description",
                    placeholder_label : "Entity description",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            validations : {
                entity_name : {
                    max : 50,
                    unique : true
                },
                description : {
                    max : 1000
                }
            },
            show : {
                refer_key : "with_question",
                refer_value : ["false"]
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "with_question",
            label : "Scrap web with questions"
        },
        {
            key : "questions",
            label : "Questions",
            show : {
                refer_key : "with_question",
                refer_value : ["true"]
            }
        },
        {
            key : "url",
            label : "URL",
            singleline_text : true
        }
    ],
    TP_LLM_CID : [
        {
            key : "intents",
            label : "Intents & Entities",
            json_keys : [
                "intent", "intent_description", "entity_types"
            ],
            empty_data : [{"intent" : "", "intent_description" : "","entity_types":""}],
            json_keys_schema : {
                intent :{
                    size : 3,
                    label : "Type",
                    placeholder_label : "Intent type",
                    type : "text",
                    empty : "",
                    required : true
                },
                intent_description : {
                    size : 4,
                    label : "Description",
                    placeholder_label : "Intent description",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_types : {
                    size : 5,
                    label : "Entities",
                    placeholder_label : "Entities (separated by comma)",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            validations : {
                intent : {
                    max : 50,
                    unique : true
                },
                intent_description : {
                    max : 200
                },
                entity_types : {
                    max : 500
                }
            },
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["examples"]
                }
            ],
            notes : {
                global : "Modification will reset the 'Intent Detection Samples' field."
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "examples",
            label : "Intent Detection Samples",
            optional : true,
            disabled_condition : {
                intents : [{"intent":"","intent_description":"","entity_types" : ""}]
            },
            notes : {
                disabled : "To add samples, intent types and its entities are necessary.",
                global : "To add samples, intent types and its entities are necessary."
            }
        }
    ],
    TP_LLM_GTE : [
        {
            key : "custom_entities",
            label : "Additional Custom Entities",
            condition_label : {
                referKey : "examples",
                referValue : "[]",
                empty : true,
                label : "Custom Entities"
            },
            json_keys : [
                "entity_name", "entity_description"
            ],
            empty_data : [{"entity_name" : "", "entity_description" : ""}],
            json_keys_schema : {
                entity_name :{
                    size : 4,
                    label : "Name",
                    placeholder_label : "Name",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_description : {
                    size : 8,
                    label : "Description",
                    placeholder_label : "Description",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            optional : true,
            validations : {
                entity_name : {
                    max : 100
                },
                entity_description : {
                    max : 250
                }
            }
        },
        {
            key : "text",
            label : "Input Text",
            validations : {
                max : 20000
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "examples",
            label : "Samples",
            optional : true
        }
    ],
    TP_LLM_CTSA : [
        {
            key : "sentiment_aspects",
            label : "Sentiment Aspects",
            empty_data : [{"aspect" : "", "sub_aspect" : ""}],
            json_keys : [
                "aspect", "sub_aspect"
            ],
            json_keys_schema : {
                aspect :{
                    size : 4,
                    label : "Aspect",
                    placeholder_label : "Aspect",
                    type : "text",
                    empty : "",
                    required : true
                },
                sub_aspect : {
                    size : 8,
                    label : "Subaspect(s)",
                    placeholder_label : "Subaspects (separated by comma)",
                    type : "text",
                    empty : "",
                    required : false
                }
            },
            optional : true,
            validations : {
                aspect : {
                    max : 50
                },
                sub_aspect : {
                    max : 100
                }
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "examples",
            label : "Examples",
            optional : true
        },
        {
            key : "delimiter",
            label : "Delimiter",
            singleline_text : true,
            show : {
                refer_key : "context_splitter",
                refer_value : ["custom"]
            }
        },
        {
            key : "output_labels",
            label : "Sentiment Labels",
            default_disabled_list : ['positive','neutral','negative']
        }
    ],
    TP_LLM_CTED : [
        {
            key : "emotion_aspects",
            label : "Emotion Aspects",
            empty_data : [{"aspect" : "", "sub_aspect" : ""}],
            json_keys : [
                "aspect", "sub_aspect"
            ],
            json_keys_schema : {
                aspect :{
                    size : 4,
                    label : "Aspect",
                    placeholder_label : "Aspect",
                    type : "text",
                    empty : "",
                    required : true
                },
                sub_aspect : {
                    size : 8,
                    label : "Subaspect(s)",
                    placeholder_label : "Subaspects (separated by comma)",
                    type : "text",
                    empty : "",
                    required : false
                }
            },
            optional : true,
            validations : {
                aspect : {
                    max : 50
                },
                sub_aspect : {
                    max : 100
                }
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "examples",
            label : "Examples",
            optional : true
        },
        {
            key : "facets",
            label : "Get emotion with facets"
        },
        {
            key : "delimiter",
            label : "Delimiter",
            optional : false,
            singleline_text : true,
            show : {
                refer_key : "context_splitter",
                refer_value : ["custom"]
            }
        },
        {
            key : "output_labels",
            label : "Emotion Labels"
        }
    ],
    TP_LLM_LT : [
        {
            key : "text",
            label : "Text",
            validations : {
                max : 8000
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "samples",
            label : "Translated Samples",
            max_count : 4,
            optional: true,
            notes : {
                global : "You can add maximum of 4 'Translated Samples'."
            }
        }
    ],
    TP_LLM_TQA : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        }
    ],
    TP_LLM_TS : [
        {
            key : "provider",
            label : "Provider",
            call_function : [
                {
                    call_code : "TP_LLM_TSI",
                    function_code : "estimate",
                    type : "instruction",
                    check_type : "list",
                    check_input : "sample_summaries"
                }
            ]
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true,
            call_function : [
                {
                    call_code : "TP_LLM_TSI",
                    function_code : "estimate",
                    type : "instruction",
                    check_type : "list",
                    check_input : "sample_summaries"
                }
            ]
        },
        {
            key : "sample_summaries",
            label : "Sample Summaries",
            optional : true,
            call_function : [
                {
                    call_code : "TP_LLM_TSI",
                    function_code : "estimate",
                    type : "instruction"
                }
            ]
        },
        {
            key : "additional_information",
            label : "Summary Instructions",
            list_order : [{value:"None"},{value:"Custom Instructions",ownvalue:"Custom Instructions"},{value:"Auto Generated AI Instructions",ownvalue:"Auto Generated AI Instructions (Customizable)"}],
            export_function : {
                type : "Instructions",
                export_when : ["Custom Instructions","Auto Generated AI Instructions (Customizable)"],
                export_code : "EXP_TP_LLM_TSI",
                name : "SummaryInstructions"
            },
            pop : [
                {
                    type : "confirmation",
                    pop_when : ["Auto Generated AI Instructions (Customizable)"],
                    message_type : "accessible",
                    getcredits_for : "Sample Summaries"
                }
            ],
            call_function : [
                {
                    call_code : "TP_LLM_TSI",
                    function_code : "generate_instruction",
                    call_when : ["Auto Generated AI Instructions (Customizable)"],
                    input_mapping : [
                        {
                            key : "audience",
                            input_key : "audience",
                            type : "list"
                        },
                        {
                            key : "summary_guidelines",
                            input_key : "summary_guidelines",
                            type : "list"
                        },
                        {
                            key : "tone",
                            input_key : "tone",
                            type : "list"
                        },
                        {
                            key : "language_style",
                            input_key : "language_style",
                            type : "list"
                        },
                        {
                            key : "bullets",
                            input_key : "bullets",
                            type : "toggle"
                        },
                        {
                            key : "other_informations",
                            input_key : "other_informations",
                            type : "text"
                        },
                        {
                            key : "length",
                            input_key : "length",
                            type : "minmaxlist",
                            separator : " ",
                            valueIndex : [0,2]
                        }
                    ]
                }
            ],
            disable_values : ["Auto Generated AI Instructions (Customizable)"],
            disabled_condition : {
                sample_summaries : "[]"
            },
            notes : {
                disabled : "To generate AI instructions, sample summaries are necessary, and that will require additional credits.",
                global : "Using 'Auto Generated AI Instructions (Customizable)', additional credit will be automatically consumed for every generation."
            }
        },
        {
            key : "summary_guidelines",
            label : "Summary Guideline",
            info : "The Summary Guideline outlines user-specified instructions for the summary's format and focus, directing the model on what to include and how to structure the content.",
            single_item : true,
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "tone",
            label : "Tone",
            info : "Tone dictates the emotional character of the summary, allowing users to match the summary's voice with their communicative intent.",
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "length",
            label : "Summary Length",
            info : "Summary Length specifies the desired word count, ensuring the summary's conciseness or detail level meets user expectations.",
            dual_thumb : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "audience",
            label : "Audience",
            info : "Audience identifies the target readers, enabling the model to customize the summary's language and complexity to suit the intended demographic.",
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "language_style",
            label : "Language Style",
            info : "Language Style indicates the preferred writing style, such as technical or casual, guiding the model to produce a summary that aligns with the user's stylistic choices.",
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "other_informations",
            label : "Other Information",
            info :"Other Information encompasses any extra user requirements or unique considerations for the summary, ensuring the model addresses all specific user needs.",
            validations : {
                max : 1000
            },
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "bullets",
            label : "Summary with bullet points",
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        }
    ],
    TP_LLM_CDS : [
        {
            key : "provider",
            label : "Provider",
            call_function : [
                {
                    call_code : "TP_LLM_CDSI",
                    function_code : "estimate",
                    type : "instruction",
                    check_input : "sample_files"
                }
            ]
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true,
            call_function : [
                {
                    call_code : "TP_LLM_CDSI",
                    function_code : "estimate",
                    type : "instruction",
                    check_input : "sample_files"
                }
            ]
        },
        {
            key : "samples",
            label : "Summary Samples",
            max_count : 1,
            info : "Only accepts a single ZIP archive containing the input/output sample files, without any nested folders.\nThe ZIP can have a maximum of 10 file pairs for GPT4 or 3 file pairs for ChatGPT, with each input named 'sampleN_input' and output named 'sampleN_output' with a numbering (N) starting from '1' (Example : sample1_input, sample1_output).\nAllowed file types are JPEG, JPG, PNG, TIFF, PDF (max 7 pages), BMP and TXT.",
            optional : true,
            // call_function : [
            //     {
            //         call_code : "TP_LLM_CDSI",
            //         function_code : "estimate",
            //         type : "instruction"
            //     }
            // ]
        },
        {
            key : "additional_information",
            label : "Summary Instructions",
            list_order : [{value:"None"},{value:"Custom Instructions",ownvalue:"Custom Instructions"},{value:"Auto Generated AI Instructions",ownvalue:"Auto Generated AI Instructions (Customizable)"}],
            export_function : {
                type : "Instructions",
                export_when : ["Custom Instructions","Auto Generated AI Instructions (Customizable)"],
                export_code : "EXP_TP_LLM_CDSI",
                name : "SummaryInstructions"
            },
            pop : [
                {
                    type : "confirmation",
                    pop_when : ["Auto Generated AI Instructions (Customizable)"],
                    message_type : "accessible",
                    getcredits_for : "Summary Samples"
                }
            ],
            call_function : [
                {
                    call_code : "TP_LLM_CDSI",
                    function_code : "generate_instruction",
                    call_when : ["Auto Generated AI Instructions (Customizable)"],
                    input_mapping : [
                        {
                            key : "audience",
                            input_key : "audience",
                            type : "list"
                        },
                        {
                            key : "summary_guidelines",
                            input_key : "summary_guidelines",
                            type : "list"
                        },
                        {
                            key : "tone",
                            input_key : "tone",
                            type : "list"
                        },
                        {
                            key : "language_style",
                            input_key : "language_style",
                            type : "list"
                        },
                        {
                            key : "bullets",
                            input_key : "bullets",
                            type : "toggle"
                        },
                        {
                            key : "other_informations",
                            input_key : "other_informations",
                            type : "text"
                        },
                        {
                            key : "length",
                            input_key : "length",
                            type : "minmaxlist",
                            separator : " ",
                            valueIndex : [0,2]
                        }
                    ]
                }
            ],
            disable_values : ["Auto Generated AI Instructions (Customizable)"],
            disabled_condition : {
                samples : "[]"
            },
            notes : {
                disabled : "To generate AI instructions, 'Summary Samples' is necessary, and that will require additional credits.",
                global : "Using 'Auto Generated AI Instructions (Customizable)', additional credit will be automatically consumed for every generation."
            }
        },
        {
            key : "summary_guidelines",
            label : "Summary Guideline",
            info : "The Summary Guideline outlines user-specified instructions for the summary's format and focus, directing the model on what to include and how to structure the content.",
            single_item : true,
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "tone",
            label : "Tone",
            info : "Tone dictates the emotional character of the summary, allowing users to match the summary's voice with their communicative intent.",
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "length",
            label : "Summary Length",
            info : "Summary Length specifies the desired word count, ensuring the summary's conciseness or detail level meets user expectations.",
            dual_thumb : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "audience",
            label : "Audience",
            info : "Audience identifies the target readers, enabling the model to customize the summary's language and complexity to suit the intended demographic.",
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "language_style",
            label : "Language Style",
            info : "Language Style indicates the preferred writing style, such as technical or casual, guiding the model to produce a summary that aligns with the user's stylistic choices.",
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "other_informations",
            label : "Other Information",
            info :"Other Information encompasses any extra user requirements or unique considerations for the summary, ensuring the model addresses all specific user needs.",
            validations : {
                max : 1000
            },
            optional : true,
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        },
        {
            key : "bullets",
            label : "Summary with bullet points",
            show : {
                refer_key : "additional_information",
                refer_value : ["custom instructions","auto generated ai instructions (customizable)"]
            }
        }
    ],
    TP_LLM_IC : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true,
        },
        {
            key : "image_examples",
            label : "Image Examples",
            max_count : 1,
            optional : true,
        }
    ],
    TP_LLM_OD : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "image_examples",
            label : "Image Examples",
            max_count : 1,
            optional : true,
        }
    ],
    TP_LLM_VQA : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "provider",
            label : "Provider"
        },
        {
            key : "file",
            label : "File"
        },
        {
            key : "question",
            label : "Questions"
        }
    ],
    TP_LLM_TT : [
        {
           key : "text",
           label : "Text",
           validations : {
               max: 6000
           }
        },
        {
            key : "provider",
            label : "Provider",
            call_function : [
                {
                    call_code : "TP_LLM_TTI",
                    function_code : "estimate",
                    type : "instruction",
                    check_type : "list",
                    check_input : "samples"
                }
            ]
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true,
            call_function : [
                {
                    call_code : "TP_LLM_TTI",
                    function_code : "estimate",
                    type : "instruction",
                    check_type : "list",
                    check_input : "samples"
                }
            ]
        },
        {
            key : "enable_default_skill",
            label : "Transform with default skills"
        },
        {
            key : "default_samples",
            label : "Transformed Samples",
            optional_condition : {
                enable_default_skill : "true",
                transform_skills : "custom skills"
            },
            show : [
                {
                    refer_key : "enable_default_skill",
                    refer_value : ["false"]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["1","2","4"]
                }
            ],
            call_function : [
                {
                    call_code : "TP_LLM_TTI",
                    function_code : "estimate",
                    type : "instruction"
                }
            ]
        },
        {
            key : "rephrase_samples",
            label : "Rephrased Samples",
            optional : true,
            show : {
                refer_key : "default_skill",
                refer_value : ["3"]
            }
        },
        {
            key : "grammar_corrector_samples",
            label : "Grammar Corrected Samples",
            optional : true,
            show : {
                refer_key : "default_skill",
                refer_value : ["5"]
            }
        },
        {
            key : "transform_skills",
            label : "Skill Instructions",
            show : {
                refer_key : "enable_default_skill",
                refer_value : ["false"]
            },
            list_order : [{value:"None"},{value:"Custom Skills",ownvalue:"Custom Skills"},{value:"Auto Generated AI Skills",ownvalue:"Auto Generated AI Skills (Customizable)"}],
            export_function : {
                type : "Skill Instructions",
                export_when : ["Custom Skills","Auto Generated AI Skills (Customizable)"],
                export_code : "EXP_TP_LLM_TTI",
                name : "SkillInstructions"
            },
            pop : [
                {
                    type : "confirmation",
                    pop_when : ["Auto Generated AI Skills (Customizable)"],
                    message_type : "accessible",
                    getcredits_for : "Samples"
                }
            ],
            call_function : [
                {
                    call_code : "TP_LLM_TTI",
                    function_code : "generate_instruction",
                    call_when : ["Auto Generated AI Skills (Customizable)"],
                    input_mapping : [
                        {
                            key : "transformation_type",
                            input_key : "transformation_type",
                            type : "text"
                        },
                        {
                            key : "transformation_instruction",
                            input_key : "transformation_instruction",
                            type : "text"
                        },
                        {
                            key : "do",
                            input_key : "do",
                            type : "text"
                        },
                        {
                            key : "dont",
                            input_key : "dont",
                            type : "text"
                        }
                    ]
                }
            ],
            disable_values : ["Auto Generated AI Skills (Customizable)"],
            disabled_condition : {
                default_samples : "[]"
            },
            notes : {
                disabled : "To generate AI skills, samples are necessary, and that will require additional credits.",
                global : "Using 'Auto Generated AI Skills (Customizable)', additional credit will be automatically consumed for every generation."
            }
        },
        {
            key : "default_skill",
            label : "Transformation Type",
            formatted_list : [{value:"1",label: "Tone Adapter"},{value:"2",label: "Expander"},{value:"3",label: "Rephraser"},{value:"4",label: "Simplifier"},{value:"5",label: "Grammar Corrector"}],
            show : {
                refer_key : "enable_default_skill",
                refer_value : ["true"]
            }
        },
        {
            key : "tone_type",
            label : "Tone Type",
            show : {
                refer_key : "default_skill",
                refer_value : ["1"]
            }
        },
        {
            key : "max_rephrase",
            label : "Rephrase(s)",
            show : {
                refer_key : "default_skill",
                refer_value : ["3"]
            }
        },
        {
            key : "transformation_type",
            label : "Transformation Type",
            singleline_text : true,
            validations : {
                max: 1000
            },
            show : {
                refer_key : "transform_skills",
                refer_value : ["custom skills","auto generated ai skills (customizable)"]
            }
        },
        {
            key : "transformation_instruction",
            label : "Transformation Instructions",
            validations : {
                max: 1000
            },
            show : {
                refer_key : "transform_skills",
                refer_value : ["custom skills","auto generated ai skills (customizable)"]
            }
        },
        {
            key : "do",
            label : "Do's",
            placeholder_label : "do's",
            validations : {
                max: 1000
            },
            show : {
                refer_key : "transform_skills",
                refer_value : ["custom skills","auto generated ai skills (customizable)"]
            }
        },
        {
            key : "dont",
            label : "Don'ts",
            placeholder_label : "don'ts",
            validations : {
                max: 1000
            },
            show : {
                refer_key : "transform_skills",
                refer_value : ["custom skills","auto generated ai skills (customizable)"]
            }
        }
    ],
    TP_LLM_TG : [
        {
            key : "generation_parameters",
            label : "Generation Parameters",
            empty_data : [{"parameter" : "", "parameter_value" : ""}],
            max_count : 15,
            default_condition_when : {
                refer_key : "enable_default_skill",
                refer_value : ["true"]
            },
            default_conditions : [
                {
                    refer_key : "default_skill",
                    refer_value : ["1"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Email Type", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["2"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Blog Type", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["3"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Contract Type", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["4"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Post Description", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["5"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Product Type", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["6"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Position", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["7"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Speech Topic", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["8"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Project Name", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["9"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Proposal Type", "parameter_value" : ""}]
                },
                {
                    refer_key : "default_skill",
                    refer_value : ["10"],
                    disabled : ["parameter"],
                    default_data : [{"parameter" : "Product Description", "parameter_value" : ""}]
                }
            ],
            json_keys : [
                "parameter", "parameter_value"
            ],
            json_keys_schema : {
                parameter :{
                    size : 4,
                    label : "Paramater",
                    placeholder_label : "Parameter",
                    type : "text",
                    empty : "",
                    required : true
                },
                parameter_value : {
                    size : 8,
                    label : "Value",
                    placeholder_label : "Parameter's value",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            validations : {
                parameter : {
                    max : 500,
                    unique : true
                },
                parameter_value : {
                    max : 2000
                }
            }
        },
        {
            key : "provider",
            label : "Provider",
            call_function : [
                {
                    call_code : "TP_LLM_TGI",
                    function_code : "estimate",
                    type : "instruction",
                    check_type : "list",
                    check_input : "samples"
                }
            ]
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true,
            call_function : [
                {
                    call_code : "TP_LLM_TGI",
                    function_code : "estimate",
                    type : "instruction",
                    check_type : "list",
                    check_input : "samples"
                }
            ]
        },
        {
            key : "enable_default_skill",
            label : "Generate with default skills",
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["generation_parameters","samples"]
                }
            ]
        },
        {
            key : "samples",
            label : "Generated Samples",
            optional_condition : {
                enable_default_skill : "true",
                generate_skills : "custom skills"
            },
            call_function : [
                {
                    call_code : "TP_LLM_TGI",
                    function_code : "estimate",
                    type : "instruction"
                }
            ]
        },
        {
            key : "generate_skills",
            label : "Skill Instructions",
            max_count : 15,
            show : {
                refer_key : "enable_default_skill",
                refer_value : ["false"]
            },
            list_order : [{value:"None"},{value:"Custom Skills",ownvalue:"Custom Skills"},{value:"Auto Generated AI Skills",ownvalue:"Auto Generated AI Skills (Customizable)"}],
            export_function : {
                type : "Skill Instructions",
                export_when : ["Custom Skills","Auto Generated AI Skills (Customizable)"],
                export_code : "EXP_TP_LLM_TGI",
                name : "SkillInstructions"
            },
            pop : [
                {
                    type : "confirmation",
                    pop_when : ["Auto Generated AI Skills (Customizable)"],
                    message_type : "accessible",
                    getcredits_for : "Samples"
                }
            ],
            call_function : [
                {
                    call_code : "TP_LLM_TGI",
                    function_code : "generate_instruction",
                    call_when : ["Auto Generated AI Skills (Customizable)"],
                    input_mapping : [
                        {
                            key : "generation_type",
                            input_key : "generation_type",
                            type : "text"
                        },
                        {
                            key : "generation_instructions",
                            input_key : "generation_instructions",
                            type : "text"
                        },
                        {
                            key : "do",
                            input_key : "do",
                            type : "text"
                        },
                        {
                            key : "dont",
                            input_key : "dont",
                            type : "text"
                        }
                    ]
                }
            ],
            disable_values : ["Auto Generated AI Skills (Customizable)"],
            disabled_condition : {
                samples : "[]"
            },
            notes : {
                disabled : "To generate AI skills, samples are necessary, and that will require additional credits.",
                global : "Using 'Auto Generated AI Skills (Customizable)', additional credit will be automatically consumed for every generation."
            }
        },
        {
            key : "default_skill",
            label : "Generation Type",
            formatted_list : [{value:"1",label: "Email Generation"},{value:"2",label: "Blog Post Generation"},{value:"3",label: "Contract or Legal Document Generation"},{value:"4",label: "Social Media Post Generation"},{value:"5",label: "Product Description Generation"},{value:"6",label: "Resume Generation"},{value:"7",label: "Speech Generation"},{value:"8",label: "Project Proposal Generation"},{value:"9",label: "Business Letter Generation"},{value:"10",label: "Sales Pitch Generation"}],
            show : {
                refer_key : "enable_default_skill",
                refer_value : ["true"]
            },
            call_function : [
                {
                    function_code : "remove_keys",
                    keys_list : ["generation_parameters","samples"]
                }
            ]
        },
        {
            key : "generation_type",
            label : "Generation Type",
            singleline_text : true,
            validations : {
                max: 2000
            },
            show : {
                refer_key : "generate_skills",
                refer_value : ["custom skills","auto generated ai skills","auto generated ai skills (customizable)"]
            }
        },
        {
            key : "generation_instructions",
            label : "Generation Instruction",
            validations : {
                max: 2000
            },
            show : {
                refer_key : "generate_skills",
                refer_value : ["custom skills","auto generated ai skills","auto generated ai skills (customizable)"]
            }
        },
        {
            key : "do",
            label : "Do's",
            placeholder_label : "do's",
            validations : {
                max: 2000
            },
            show : {
                refer_key : "generate_skills",
                refer_value : ["custom skills","auto generated ai skills","auto generated ai skills (customizable)"]
            }
        },
        {
            key : "dont",
            label : "Don'ts",
            placeholder_label : "don'ts",
            validations : {
                max: 2000
            },
            show : {
                refer_key : "generate_skills",
                refer_value : ["custom skills","auto generated ai skills","auto generated ai skills (customizable)"]
            }
        }
    ],
    CT_DOC_DR : [
        {
            key : "edit",
            label : "Edit",
        },
        {
            key : "text",
            label : "Text",
            optional_condition : {
                edit : "false"
            },
            show : {
                refer_key : "edit",
                refer_value : "true"
            }
        },
        {
            key : "choices",
            label : "Choices",
            show : {
                refer_key : "edit",
                refer_value : "true"
            }
        }
    ],
    TP_LLM_IDE : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "id_type",
            label : "ID Type"
        }
    ],
    TP_LLM_PII : [
        {
            key : "custom_entities",
            label : "Custom Entities",
            json_keys : [
                "entity_name", "entity_description"
            ],
            empty_data : [{"entity_name" : "", "entity_description" : ""}],
            json_keys_schema : {
                entity_name :{
                    size : 4,
                    label : "Name",
                    placeholder_label : "Name",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_description : {
                    size : 8,
                    label : "Description",
                    placeholder_label : "Description",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            validations : {
                entity_name : {
                    max : 100
                },
                entity_description : {
                    max : 250
                }
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "examples",
            label : "Redacted Samples",
            optional : true
        }
    ],
    /*TP_LLM_CIDE : [
        {
            key : "entities",
            label : "Custom Entities",
            json_keys : [
                "entity_type", "entity_description"
            ],
            empty_data : [{"entity_type" : "", "entity_description" : ""}],
            json_keys_schema : {
                entity_type :{
                    size : 4,
                    label : "Entity Name",
                    placeholder_label : "Entity name",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_description : {
                    size : 8,
                    label : "Entity Description",
                    placeholder_label : "Entity description",
                    type : "textarea",
                    empty : "",
                    required : true
                }
            },
            validations : {
                entity_type : {
                    max : 100,
                    unique : true
                },
                entity_description : {
                    max : 800
                }
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "id_type",
            label : "ID Type",
            singleline_text : true
        }
    ],*/
    TP_LLM_SEE : [
        {
            key : "entities",
            label : "Custom Entities",
            json_keys : [
                "entity_name", "entity_description"
            ],
            empty_data : [{"entity_name" : "", "entity_description" : ""}],
            json_keys_schema : {
                entity_name :{
                    size : 4,
                    label : "Entity Name",
                    placeholder_label : "Entity name",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_description : {
                    size : 8,
                    label : "Description",
                    placeholder_label : "Description",
                    type : "text",
                    empty : "",
                    required : true
                }
            },
            validations : {
                entity_name : {
                    max : 100
                },
                entity_description : {
                    max : 250
                }
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "tool",
            label : "Transcription Tool"
        }
    ],
    TP_LLM_DQA : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "with_file_input",
            label : "Ask question(s) from file"
        },
        {
            key : "file",
            label : "File",
            show : {
                refer_key : "with_file_input",
                refer_value : ["true"]
            }
        },
        {
            key : "tool",
            label : "Tool",
            show : {
                refer_key : "with_file_input",
                refer_value : ["true"]
            }
        },
        {
            key : "text",
            label : "Text",
            show : {
                refer_key : "with_file_input",
                refer_value : ["false"]
            }
        },
        {
            key : "chunk_size",
            label : "Chunk Size",
            heading : "Optimizers",
            is_subheading : true,
            show : {
                refer_key : "search_type",
                refer_value : ["rag"]
            }
        },
        {
            key : "chunk_overlap",
            label : "Chunk Overlap",
            is_subheading : true,
            show : {
                refer_key : "search_type",
                refer_value : ["rag"]
            }
        },
        {
            key : "top_k",
            label : "Top K",
            is_subheading : true,
            show : {
                refer_key : "search_type",
                refer_value : ["rag"]
            }
        }
    ],
    TP_LLM_SQA : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "question",
            label : "Questions"
        },
        {
            key : "tool",
            label : "Transcription Tool"
        }
    ],
    TP_ASYNCLLM_DS : [
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "focus",
            label : "Focus",
            optional : true
        },
        {
            key : "audience",
            label : "Audience",
            optional : true
        }
    ],
    TP_LLM_CDE : [
        {
            key : "entities",
            label : "Custom Entities",
            info : "The AI generated can be wrong it is advised to review the response, also the response can take longer than expected.",
            json_keys : [
                "entity_name", "extract_type", "entity_description", "column_names"
            ],
            empty_data : [{"entity_name" : "", "extract_type" : "string", "entity_description" : "", "column_names":""}],
            json_keys_schema : {
                entity_name :{
                    size : 4,
                    label : "Entity Name",
                    placeholder_label : "Entity name",
                    type : "text",
                    empty : "",
                    required : true
                },
                extract_type :{
                    size : 3,
                    label : "Extraction Type",
                    placeholder_label : "Extraction type",
                    type : "drop",
                    list : [{"value":"string","label":"Single Value"},{"value":"multiple","label":"Multiple Values"},{"value":"line_item","label":"Line Items"}],
                    empty : "string",
                    required : true
                },
                entity_description : {
                    size : 5,
                    label : "Entity Description",
                    placeholder_label : "Entity description",
                    type : "textarea",
                    empty : "",
                    required : true
                },
                column_names : {
                    size : 7,
                    label : "Column Name",
                    placeholder_label : "Column names (comma separated)",
                    type : "text",
                    empty : "",
                    required_condition : {
                        extract_type : "line_item"
                    },
                    hide_conditions : {
                        extract_type : ["","string","multiple"]
                    }
                }
            },
            validations : {
                entity_name : {
                    max : 100,
                    unique : true
                },
                entity_description : {
                    max : 800
                },
                column_names : {
                    max : 1000
                }
            },
            generate_function : {
                type : "Auto-fill Custom Entities",
                generate_code : "GEN_TP_LLM_CDE",
                file_key: "file"
            },
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        }
    ],

    TP_LLM_CIDE : [
        {
            key : "entities",
            label : "Custom Entities",
            info : "The AI generated can be wrong it is advised to review the response, also the response can take longer than expected.",
            json_keys : [
                "entity_type","entity_description"
            ],
            empty_data : [{"entity_type" : "", "entity_description" : ""}],
            json_keys_schema : {
                entity_type :{
                    size : 4,
                    label : "Entity Name",
                    placeholder_label : "Entity name",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_description : {
                    size : 8,
                    label : "Entity Description",
                    placeholder_label : "Entity description",
                    type : "textarea",
                    empty : "",
                    required : true
                }
            },
            validations : {
                entity_type : {
                    max : 100,
                    unique : true
                },
                entity_description : {
                    max : 800
                }
            },
            generate_function : {
                type : "Auto-fill Custom Entities",
                generate_code : "GEN_TP_LLM_CIDE",
                file_key: "file"
            },
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        },
        {
            key : "id_type",
            label : "ID Type",
            singleline_text : true
        }
    ],


    TP_LLM_DPII : [
        {
            key : "entities",
            label : "Custom Entities",
            json_keys : [
                "entity_name", "entity_description"
            ],
            empty_data : [{"entity_name" : "", "entity_description" : ""}],
            json_keys_schema : {
                entity_name :{
                    size : 5,
                    label : "Entity Name",
                    placeholder_label : "Entity name",
                    type : "text",
                    empty : "",
                    required : true
                },
                entity_description : {
                    size : 7,
                    label : "Entity Description",
                    placeholder_label : "Entity description",
                    type : "textarea",
                    empty : "",
                    required : true
                }
            },
            validations : {
                entity_name : {
                    max : 100,
                    unique : true
                },
                entity_description : {
                    max : 800
                }
            }
        },
        {
            key : "llm",
            label : "LLM",
            display_exact : true
        }
    ]
}

export const MODEL_KEYS_CONFIGS_CHECKLIST = {
    TP_LLM_SEE : ["provider","tool"],
    TP_LLM_SQA : ["provider","tool"],
    TP_LLM_CDE : ["provider","tool"],
    TP_LLM_DPII : ["provider","tool"],
    TP_LLM_CDS : ["provider","tool"],
    TP_LLM_DC : ["provider","tool"],
    TP_LLM_CIDE : ["provider","tool"],
    TP_LLM_DQA : ["provider","tool"]
}

export const MODEL_INPUT_JSON_CHANGE_TYPE = {
    TO_LOWERCASE : 1,
    LIST_TO_STRING : 2,
    REMOVE : 3,
    KEY_VALUE_PAIR : 4,
    ADD_FORMED_OBJECT : 5,
    JSON_TO_STRING : 6,
    STRING_TO_JSON : 7,
    REPLACER : 8,
    REMOVE_LIST : 9,
    FORM_NEW_OBJECT : 10,
    TO_LABEL : 11,
    TRANSFORM_DATA_FROM_OBJECT_LIST : 12,
    REMOVE_NOT : 13,
    COPY_KEY : 14,
    TO_KEY : 15,
    STRING_TO_LIST : 16,
    ADD_KEY_IF_NOT_AVAIL : 17,
    KEY_VALUE_TO_OBJECT : 18,
    ADD_FORMED_OBJECT_LIST : 19,
    MERGE_LIST : 20,
    STRING_TO_VALUE : 21,
    MAP_LISTS_VALUES : 22,
    SEPARATE_MAPPED_LIST_VALUES : 23,
    VALUE_TO_STRING : 24,
    FORM_FILE_OBJECT : 25,
    BOOLEAN_TO_STRING : 26,
    STRING_TO_BOOLEAN : 27,
    TO_STRUCTURED_LABEL : 28,
    ADD_KEY_VALUE : 29
}

export const MODEL_INPUT_JSON_CHANGE_BEFORE_SUBMIT = {
    FEWSHOT_TP_LLM_CTSA : [
        {
            key : "context_level_sentiment",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "context_level_sentiment",
            referKeyValue : [{"context" : "", "sentiment" : "","aspect" : "", "sub_aspect" : ""}]
        },
        {
            key : "context_level_sentiment",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "context",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "context",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "sentiment",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "sentiment",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "aspect",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "sub_aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "sub_aspect",
                    referKeyValue : EMPTY_STRING
                }
            ]
        }
    ],
    FEWSHOT_TP_LLM_CTED : [
        {
            key : "emotions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "emotions",
            referKeyValue : [{"context" : "", "emotion" : "","aspect" : "", "sub_aspect" : ""}]
        },
        {
            key : "emotions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "context",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "context",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "emotion",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "emotion",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "aspect",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "sub_aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "sub_aspect",
                    referKeyValue : EMPTY_STRING
                }
            ]
        }
    ],
    TP_LLM_GTE_PUBLISH : [
        {
            key : "custom_entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"entity_name":"","entity_description":""}]
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        }
    ],
    TP_LLM_GTE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "examples",
            referKeyValue : []
        },
        {
            key : "custom_entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "custom_entities",
            referKeyValue : [{"entity_name":"","entity_description":""}]
        }
    ],
    FEWSHOT_TP_LLM_TT : [
        {
            key : "grammatically_corrected_content",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "grammatically_corrected_content",
            referKeyValue : ""
        },
        {
            key : "grammatically_corrected_content",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "content_need_modification",
            referKeyValue : "False"
        }
    ],
    EXP_TP_LLM_TTI : [
        {
            key : "transformation_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "transformation_type",
            referKeyValue : ""
        },
        {
            key : "transformation_instruction",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "transformation_instruction",
            referKeyValue : ""
        },
        {
            key : "do",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "do",
            referKeyValue : ""
        },
        {
            key : "dont",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "dont",
            referKeyValue : ""
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["transformation_type","transformation_instruction","do","dont"]
        }
    ],
    EXP_TP_LLM_TGI : [
        {
            key : "generation_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "generation_type",
            referKeyValue : ""
        },
        {
            key : "generation_instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "generation_instructions",
            referKeyValue : ""
        },
        {
            key : "do",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "do",
            referKeyValue : ""
        },
        {
            key : "dont",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "dont",
            referKeyValue : ""
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["generation_type","generation_instructions","do","dont"]
        }
    ],
    TP_LLM_TGI : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
                    transformList : [
                        {
                            key : "parameter",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY,
                        }
                    ]
                },
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_PAIR,
                    keyValuePairKey : "parameter",
                    keyValuePairValueKey : "parameter_value"
                },
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
                }
            ]
        },
        {
            key : "request",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["provider","llm","samples"]
        }
    ],
    TP_LLM_TTI : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "request",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["provider","llm","samples"]
        }
    ],
    TP_LLM_TT_PUBLISH : [
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL
        },
        {
            key : "rephrase_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : {}
        },
        {
            key : "transform_skills",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            checkInput : [
                {
                    key : "instructions",
                    type : "object",
                    checkEmpty : true,
                    emptyValue : "None",
                    nonEmptyValue : "Custom Skills" 
                }
            ],
            value : "None"
        },
        {
            key : "enable_default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            checkInput : [
                {
                    key : "default_skill",
                    checkEmpty : true,
                    emptyValue : "false",
                    nonEmptyValue : "true" 
                }
            ],
            value : "false"
        },
        {
            key : "default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "1"
        },
        {
            key : "tone_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : EMPTY_STRING
        },
        {
            key : "max_rephrase",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "1"
        },
        {
            key : "transformation_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,transformation_type",
            value : EMPTY_STRING
        },
        {
            key : "transformation_instruction",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,transformation_instruction",
            value : EMPTY_STRING
        },
        {
            key : "do",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,do",
            value : EMPTY_STRING
        },
        {
            key : "dont",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,dont",
            value : EMPTY_STRING
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "default_skill",
            referValue : ["1","2","4"],
            copyKey : "default_samples"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "enable_default_skill",
            referValue : ["false","False"],
            copyKey : "default_samples"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "default_skill",
            referValue : ["3"],
            copyKey : "rephrase_samples"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "default_skill",
            referValue : ["5"],
            copyKey : "grammar_corrector_samples"
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "transformed_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "tranformed_content,grammar_explanation",
                    copyKey : "grammar_explanation"
                },
                {
                    key : "transformed_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "tranformed_content,spelling_error",
                    copyKey : "spelling_error"
                },
                {
                    key : "transformed_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "tranformed_content,content_need_modification",
                    copyKey : "content_need_modification"
                },
                {
                    key : "transformed_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "tranformed_content,grammatically_corrected_content",
                    copyKey : "grammatically_corrected_content"
                },
                {
                    key : "transformed_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                }
            ]
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "rephrase_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_TT : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "rephrase_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["transformation_type","transformation_instruction","do","dont"]
        },
        {
            key : "rephrase_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "default_skill",
            referKeyValue : "3"
        },
        {
            key : "rephrase_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "false"
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "default_skill",
            referKeyValue : "5"
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "false"
        },
        {
            key : "tone_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "default_skill",
            referKeyValue : "1"
        },
        {
            key : "tone_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "false"
        },
        {
            key : "max_rephrase",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "default_skill",
            referKeyValue : "3"
        },
        {
            key : "max_rephrase",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "false"
        },
        {
            key : "default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "false"
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "default_skill",
            referKeyValue : "3"
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "default_skill",
            referKeyValue : "5"
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "rephrase_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "transformed_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["grammar_explanation","spelling_error","content_need_modification","grammatically_corrected_content"]
                },
                {
                    key : "grammar_explanation",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                },
                {
                    key : "content_need_modification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                },
                {
                    key : "grammatically_corrected_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                },
                {
                    key : "spelling_error",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                }
            ]
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "transformation_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "transformation_instruction",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "do",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "dont",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "enable_default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE,
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "true"
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "transform_skills",
            referKeyValue : "None"
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "instructions",
            referKeyValue : {}
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "samples",
            referKeyValue : []
        },
        {
            key : "rephrase_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "grammar_corrector_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "default_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "enable_default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "transform_skills",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_TG_PUBLISH : [
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : {}
        },
        {
            key : "generate_skills",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            checkInput : [
                {
                    key : "instructions",
                    type : "object",
                    checkEmpty : true,
                    emptyValue : "None",
                    nonEmptyValue : "Custom Skills" 
                }
            ],
            value : "None"
        },
        {
            key : "enable_default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            checkInput : [
                {
                    key : "default_skill",
                    checkEmpty : true,
                    emptyValue : "false",
                    nonEmptyValue : "true" 
                }
            ],
            value : "false"
        },
        {
            key : "default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "1"
        },
        {
            key : "generation_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,generation_type",
            value : EMPTY_STRING
        },
        {
            key : "generation_instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,generation_instructions",
            value : EMPTY_STRING
        },
        {
            key : "do",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,do",
            value : EMPTY_STRING
        },
        {
            key : "dont",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,dont",
            value : EMPTY_STRING
        },
        // {
        //     key : "generation_parameters",
        //     changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON,
        //     keyValuePairKey : "parameter",
        //     keyValuePairValueKey : "parameter_value"
        // },
        // {
        //     key : "generation_parameters",
        //     changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_TO_OBJECT,
        //     keyValuePairKey : "parameter",
        //     keyValuePairValueKey : "parameter_value",
        //     keyValuePairKeyEmpty : "",
        //     keyValuePairValueEmpty : ""
        // },
        // {
        //     key : "generation_parameters",
        //     changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
        //     transformList : [
        //         {
        //             key : "parameter",
        //             changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL,
        //         }
        //     ]
        // },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON,
                },
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_TO_OBJECT,
                    keyValuePairKey : "parameter",
                    keyValuePairValueKey : "parameter_value",
                    keyValuePairKeyEmpty : "",
                    keyValuePairValueEmpty : ""
                },
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
                    transformList : [
                        {
                            key : "parameter",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL,
                        }
                    ]
                }
            ]
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        }
    ],
    TP_LLM_TG : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "generation_parameters",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "parameter",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY,
                }
            ]
        },
        {
            key : "generation_parameters",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_PAIR,
            keyValuePairKey : "parameter",
            keyValuePairValueKey : "parameter_value"
        },
        {
            key : "generation_parameters",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
            keyValuePairKey : "parameter",
            keyValuePairValueKey : "parameter_value"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["generation_type","generation_instructions","do","dont"]
        },
        {
            key : "default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "false"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
                    transformList : [
                        {
                            key : "parameter",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY,
                        }
                    ]
                },
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_PAIR,
                    keyValuePairKey : "parameter",
                    keyValuePairValueKey : "parameter_value"
                },
                {
                    key : "generation_information",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
                }
            ]
        },
        {
            key : "generation_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "generation_instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "do",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "dont",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "enable_default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE,
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "enable_default_skill",
            referKeyValue : "true"
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "generate_skills",
            referKeyValue : "None"
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "instructions",
            referKeyValue : {}
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "samples",
            referKeyValue : []
        },
        {
            key : "enable_default_skill",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "generate_skills",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_CTSA_PUBLISH : [
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "sentiment_aspects",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"aspect":"","sub_aspect":""}]
        },
        {
            key : "delimiter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : EMPTY_STRING
        },
        {
            key : "context_splitter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "None"
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        }
    ],
    TP_LLM_CTSA : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "output_labels",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "examples",
            referKeyValue : []
        },
        {
            key : "sentiment_aspects",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "sentiment_aspects",
            referKeyValue : [{"aspect":"","sub_aspect":""}]
        },
        {
            key : "delimiter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "context_splitter",
            referKeyValue : "custom"
        },
        {
            key : "context_splitter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "context_splitter",
            referKeyValue : "none"
        },
        {
            key : "sentiment_aspects",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "aspect",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "sub_aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "sub_aspect",
                    referKeyValue : EMPTY_STRING
                }
            ]
        }
    ],
    TP_LLM_LT_PUBLISH : [
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "original_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "original_content,content_text",
                    copyKey : "original_content_text"
                },
                {
                    key : "original_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "original_content,language",
                    copyKey : "original_content_language"
                },
                {
                    key : "original_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    keysList : ["language","content_text"]
                }
            ]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        }
    ],
    TP_LLM_LT : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "original_content_text",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    copyKey : "content_text"
                },
                {
                    key : "original_content_language",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    copyKey : "language"
                },
                {
                    key : "original_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["language","content_text"]
                },
                {
                    key : "original_content_text",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "original_content_language",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "content_text",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "language",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "samples",
            referKeyValue : []
        }
    ],
    TP_LLM_CID_PUBLISH : [
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "intent",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
                },
                {
                    key : "entities",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
                    transformList : [
                        {
                            key : "entity_type",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
                        }
                    ]
                }
            ]
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_TO_OBJECT,
            keyValuePairKey : "intent",
            keyValuePairValueKey : "entity_types",
            keyValuePairKeyEmpty : "",
            keyValuePairValueEmpty : ""
        },
        {
            key : "intents",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.MERGE_LIST,
            baseListKey : "intents",
            mergeListKey : "entities",
            commonKey : "intent"
        },
        {
            key : "intents",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "intent",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
                },
                {
                    key : "entity_types",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.LIST_TO_STRING,
                    is_label : true
                }
            ]
        }
    ],
    TP_LLM_CID : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "intents",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "intent",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY
                },
                {
                    key : "entity_types",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_LIST,
                    is_key : true,
                    separator : SEPARATORS.COMMA
                }
            ]
        },
        {
            key : "intents",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "entities"
        },
        {
            key : "intents",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "entity_types",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_PAIR,
            keyValuePairKey : "intent",
            keyValuePairValueKey : "entity_types"
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "intent",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY
                },
                {
                    key : "entities",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
                    transformList : [
                        {
                            key : "entity_type",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY
                        }
                    ]
                }
            ]
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "examples",
            referKeyValue : []
        }
    ],
    TP_LLM_CTED_PUBLISH : [
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "emotion_aspects",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"aspect":"","sub_aspect":""}]
        },
        {
            key : "delimiter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : EMPTY_STRING
        },
        {
            key : "context_splitter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "None"
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        }
    ],
    TP_LLM_CTED : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "output_labels",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "examples",
            referKeyValue : []
        },
        {
            key : "emotion_aspects",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "emotion_aspects",
            referKeyValue : [{"aspect":"","sub_aspect":""}]
        },
        {
            key : "delimiter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "context_splitter",
            referKeyValue : "custom"
        },
        {
            key : "context_splitter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "context_splitter",
            referKeyValue : "none"
        },
        {
            key : "emotion_aspects",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "aspect",
                    referKeyValue : EMPTY_STRING
                },
                {
                    key : "sub_aspect",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                    referKey : "sub_aspect",
                    referKeyValue : EMPTY_STRING
                }
            ]
        }
    ],
    EXP_TP_LLM_TSI : [
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REPLACER,
            replaceText : "{{min_length}} - {{max_length}} characters",
            replacers : [
                {
                    text : "{{min_length}}",
                    value_key : "length",
                    replace_value_index : 0,
                    sort: true
                },
                {
                    text : "{{max_length}}",
                    value_key : "length",
                    replace_value_index : 1,
                    sort: true
                }
            ]
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "summary_guidelines",
            referKeyValue : [""]
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "tone",
            referKeyValue : [""]
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "language_style",
            referKeyValue : [""]
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "audience",
            referKeyValue : [""]
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "other_informations",
            referKeyValue : EMPTY_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["summary_guidelines","tone","length","language_style","bullets","audience","other_informations"]
        }
    ],
    TP_LLM_TSI : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "sample_summaries",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "request",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["provider","llm","sample_summaries"]
        }
    ],
    TP_LLM_TS_PUBLISH : [
        {
            key : "sample_summaries",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : {}
        },
        {
            key : "additional_information",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            checkInput : [
                {
                    key : "instructions",
                    type : "object",
                    checkEmpty : true,
                    emptyValue : "None",
                    nonEmptyValue : "Custom Instructions" 
                }
            ],
            value : "None"
        },
        {
            key : "sample_summaries",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,length",
            value : "300 - 500 characters"
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_LIST,
            separator : SEPARATORS.SPACE,
            filterIndexes : [0,2]
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,length",
            value : "300 - 500 characters"
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,bullets",
            value : "False"
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,summary_guidelines",
            value : [""]
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,tone",
            value : [""]
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,language_style",
            value : [""]
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,audience",
            value : [""]
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,other_informations",
            value : EMPTY_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_TS : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REPLACER,
            replaceText : "{{min_length}} - {{max_length}} characters",
            replacers : [
                {
                    text : "{{min_length}}",
                    value_key : "length",
                    replace_value_index : 0,
                    sort: true
                },
                {
                    text : "{{max_length}}",
                    value_key : "length",
                    replace_value_index : 1,
                    sort: true
                }
            ]
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "length",
            referKeyValue : "{{min_length}} - {{max_length}} characters"
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "summary_guidelines",
            referKeyValue : [""]
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "tone",
            referKeyValue : [""]
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "language_style",
            referKeyValue : [""]
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "audience",
            referKeyValue : [""]
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "other_informations",
            referKeyValue : EMPTY_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["summary_guidelines","tone","length","language_style","bullets","audience","other_informations"]
        },
        {
            key : "sample_summaries",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "additional_information",
            referKeyValue : "None"
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "instructions",
            referKeyValue : {}
        },
        {
            key : "sample_summaries",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "sample_summaries",
            referKeyValue : []
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "additional_information",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
    ],
    EXP_TP_LLM_CDSI : [
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REPLACER,
            replaceText : "{{min_length}} - {{max_length}} characters",
            replacers : [
                {
                    text : "{{min_length}}",
                    value_key : "length",
                    replace_value_index : 0,
                    sort: true
                },
                {
                    text : "{{max_length}}",
                    value_key : "length",
                    replace_value_index : 1,
                    sort: true
                }
            ]
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "summary_guidelines",
            referKeyValue : [""]
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "tone",
            referKeyValue : [""]
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "language_style",
            referKeyValue : [""]
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "audience",
            referKeyValue : [""]
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "other_informations",
            referKeyValue : EMPTY_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["summary_guidelines","tone","length","language_style","bullets","audience","other_informations"]
        }
    ],
    TP_LLM_CDSI : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            dataRef : "samples,0,sample_files",
            copyKey : "sample_files"
        },
        {
            key : "request",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_NEW_OBJECT,
            keysList : ["provider","llm","tool","sample_files"]
        }
    ],
    TP_LLM_CDS_PUBLISH : [
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["sample_files"]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT_LIST,
            keysList : ["sample_temp_files"]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "filename",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : "Summarization-Examples"
                }
            ]
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "sample_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : {}
        },
        {
            key : "additional_information",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            checkInput : [
                {
                    key : "instructions",
                    type : "object",
                    checkEmpty : true,
                    emptyValue : "None",
                    nonEmptyValue : "Custom Instructions" 
                }
            ],
            value : "None"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,length",
            value : "300 - 500 characters"
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_LIST,
            separator : SEPARATORS.SPACE,
            filterIndexes : [0,2]
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,length",
            value : "300 - 500 characters"
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,bullets",
            value : "False"
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,summary_guidelines",
            value : [""]
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,tone",
            value : [""]
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,language_style",
            value : [""]
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,audience",
            value : [""]
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "instructions,other_informations",
            value : EMPTY_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_CDS : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-Input-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REPLACER,
            replaceText : "{{min_length}} - {{max_length}} characters",
            replacers : [
                {
                    text : "{{min_length}}",
                    value_key : "length",
                    replace_value_index : 0,
                    sort: true
                },
                {
                    text : "{{max_length}}",
                    value_key : "length",
                    replace_value_index : 1,
                    sort: true
                }
            ]
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "length",
            referKeyValue : "{{min_length}} - {{max_length}} characters"
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "summary_guidelines",
            referKeyValue : [""]
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "tone",
            referKeyValue : [""]
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "language_style",
            referKeyValue : [""]
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "audience",
            referKeyValue : [""]
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "other_informations",
            referKeyValue : EMPTY_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["summary_guidelines","tone","length","language_style","bullets","audience","other_informations"]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "additional_information",
            referKeyValue : "None"
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "instructions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "instructions",
            referKeyValue : {}
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "samples",
            referKeyValue : []
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            dataRef : "samples,0,sample_files",
            copyKey : "sample_files"
        },
        {
            key : "sample_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Summarization-Examples",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "summary_guidelines",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "tone",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "length",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "language_style",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "bullets",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "other_informations",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "additional_information",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
    ],
    TP_LLM_CDS_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_IC_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_IC_PUBLISH : [
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["examples"]
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT_LIST,
            keysList : ["sample_temp_files"]
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "filename",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : "Classification-Examples"
                }
            ]
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        }
    ],
    TP_LLM_IC : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-Image-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "image_examples",
            referKeyValue : []
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            dataRef : "image_examples,0,examples",
            copyKey : "examples"
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileURLKey: "url",
            fileName : "Classification-Examples",
            forSample : true
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_OD_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_OD_PUBLISH : [
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["examples"]
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT_LIST,
            keysList : ["sample_temp_files"]
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "filename",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : "Objet-Detection-Examples"
                }
            ]
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        }
    ],
    TP_LLM_OD : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-Image-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "image_examples",
            referKeyValue : []
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            dataRef : "image_examples,0,examples",
            copyKey : "examples"
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Objet-Detection-Examples",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
    ],
    TP_LLM_VQA_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_VQA_PUBLISH : [
        {
            key : "question",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        }
    ],
    TP_LLM_VQA : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-Image-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        }],
    TP_LLM_IDE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        }
    ],
    TP_LLM_GSA : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        }
    ],
    TP_LLM_GED : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        }
    ],
    TP_LLM_DQA : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "search_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "text",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "with_file_input",
            referKeyValue : "true"
        },
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "with_file_input",
            referKeyValue : "false"
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "with_file_input",
            referKeyValue : "false"
        },
        {
            key : "with_file_input",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "chunk_size",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "search_type",
            referKeyValue : "context"
        },
        {
            key : "chunk_overlap",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "search_type",
            referKeyValue : "context"
        },
        {
            key : "top_k",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "search_type",
            referKeyValue : "context"
        },
        {
            key : "chunk_size",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_VALUE
        },
        {
            key : "chunk_overlap",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_VALUE
        },
        {
            key : "top_k",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_VALUE
        }
    ],
    TP_LLM_SQA : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        }
    ],
    TP_LLM_TQA : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        }
    ],
    TP_LLM_PII_PUBLISH : [
        {
            key : "custom_entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"entity_name":"","entity_description":""}]
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "custom_entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL,
                }
            ]
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        }
    ],
    TP_LLM_PII_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_PII : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "custom_entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY,
                }
            ]
        },
        {
            key : "custom_entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "custom_entities",
            referKeyValue : [{"entity_name":"","entity_description":""}]
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "examples",
            referKeyValue : []
        }
    ],
    CT_DOC_DR : [
        {
            key : "choices",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "edit",
            referKeyValue : "false"
        },
        {
            key : "text",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "edit",
            referKeyValue : "false"
        },
        {
            key : "edit",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_SEE_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_CIDE_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_SEE_PUBLISH : [
        {
            key : "custom_entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"entity_name":"","entity_description":""}],
        }
    ],
    TP_LLM_SEE : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-Audio",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        }
    ],
    TP_LLM_CIDE_PUBLISH : [
        {
            key : "taxonomy",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "{\"id_type\":\"\",\"entities\":{}}",
        },
        {
            key : "taxonomy",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "id_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "taxonomy,id_type",
            value : ""
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            refervalue : "taxonomy,entities",
            value : {}
        },
        {
            key : "taxonomy",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_TO_OBJECT,
            keyValuePairKey : "entity_type",
            keyValuePairValueKey : "entity_description",
            keyValuePairKeyEmpty : "",
            keyValuePairValueEmpty : ""
        }
    ],
    TP_LLM_CIDE : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_PAIR,
            keyValuePairKey : "entity_type",
            keyValuePairValueKey : "entity_description"
        },
        {
            key : "taxonomy",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["id_type","entities"],
        },
        {
            key : "taxonomy",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "taxonomy",
            referKeyValue : {}
        },
        {
            key : "taxonomy",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        },
        {
            key : "id_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_CTED_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_CTSA_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_GTE_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_TG_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "generation_parameters",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "generation_parameters",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.KEY_VALUE_TO_OBJECT,
            keyValuePairKey : "parameter",
            keyValuePairValueKey : "parameter_value",
            keyValuePairKeyEmpty : "",
            keyValuePairValueEmpty : ""
        },
        {
            key : "generation_parameters",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "parameter",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL,
                }
            ]
        },
    ],
    TP_LLM_CID_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_WS_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_GC_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_LT_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_TS_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_TT_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_CDE_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_DPII_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    GEN_TP_LLM_CDE : [
        {
            key : "data",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "[{\"entity_name\":\"\",\"is_line_item\":false,\"is_multiple\":false,\"entity_description\":\"\",\"column_names\":[]}]",
        },
        {
            key : "data",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "entities"
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [ 
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
                },
                {
                    key : "extract_type",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : "string"
                },
                {
                    key : "is_multiple",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : false,
                },
                {
                    key : "is_line_item",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : false,
                },
                {
                    key : "column_names",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : [],
                },
                {
                    key : "is_line_item",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_VALUE,
                    value : true,
                    addKey : "extract_type",
                    addValue : "line_item"
                },
                {
                    key : "is_multiple",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_VALUE,
                    value : true,
                    addKey : "extract_type",
                    addValue : "multiple"
                },
                {
                    key : "is_line_item",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                },
                {
                    key : "is_multiple",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "column_names",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.LIST_TO_STRING,
                    is_label : true
                }
            ]
        },
        {
            key : "data",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],


    GEN_TP_LLM_CIDE : [
        {
            key : "data",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "[{\"entity_name\":\"\",\"entity_description\":\"\"}]",
        },
        {
            key : "data",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            dataRef : "data,entity_information",
            copyKey : "entities"
        },
        {
            key : "data",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            dataRef : "data,document_type",
            copyKey : "id_type"
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [ 
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    copyKey : "entity_type"
                },
                {
                    key : "entity_type",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
                },
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
                
            ]
        },
        {
            key : "data",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],

    TP_LLM_CDE_PUBLISH : [
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "[{\"entity_name\":\"\",\"is_line_item\":false,\"is_multiple\":false,\"entity_description\":\"\",\"column_names\":[]}]",
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [ 
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
                },
                {
                    key : "extract_type",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : "string"
                },
                {
                    key : "is_multiple",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : false,
                },
                {
                    key : "is_line_item",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : false,
                },
                {
                    key : "column_names",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : [],
                },
                {
                    key : "is_line_item",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_VALUE,
                    value : true,
                    addKey : "extract_type",
                    addValue : "line_item"
                },
                {
                    key : "is_multiple",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_VALUE,
                    value : true,
                    addKey : "extract_type",
                    addValue : "multiple"
                },
                {
                    key : "is_line_item",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
                },
                {
                    key : "is_multiple",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "column_names",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.LIST_TO_STRING,
                    is_label : true
                }
            ]
        }
    ],
    TP_LLM_DPII_PUBLISH : [
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "[{\"entity_name\":\"\",\"entity_description\":\"\"}]",
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        // {
        //     key : "entities",
        //     changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
        //     transformList : [ 
        //         {
        //             key : "entity_name",
        //             changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL
        //         }
        //     ]
        // }
    ],
    TP_LLM_WS_PUBLISH : [
        {
            key : "with_question",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            checkInput : [
                {
                    key : "questions",
                    type : "list",
                    checkEmpty : true,
                    emptyValue : "false",
                    nonEmptyValue : "true" 
                }
            ],
            value : "false"
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"entity_name":"","entity_data_type":"","description":""}],
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LABEL,
                }
            ]
        },
        {
            key : "questions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [""],
        }
    ],
    TP_LLM_WS : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "with_question",
            referKeyValue : "true"
        },
        {
            key : "questions",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "with_question",
            referKeyValue : "false"
        },
        {
            key : "with_question",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY,
                }
            ]
        }
    ],
    TP_LLM_CDE : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "entity_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY
                },
                {
                    key : "column_names",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
                    referKey : "extract_type",
                    referKeyValue : "line_item"
                },
                {
                    key : "column_names",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_LIST,
                    is_key : true,
                    separator : SEPARATORS.COMMA
                },
                {
                    key : "extract_type",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_VALUE,
                    value : "line_item",
                    addKey : "is_line_item",
                    addValue : true
                },
                {
                    key : "extract_type",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_VALUE,
                    value : "multiple",
                    addKey : "is_multiple",
                    addValue : true
                },
                {
                    key : "extract_type",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        }
    ],
    TP_LLM_DPII : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        // {
        //     key : "entities",
        //     changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
        //     transformList : [
        //         {
        //             key : "entity_name",
        //             changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_KEY
        //         }
        //     ]
        // },
        {
            key : "entities",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        }
    ],
    TP_LLM_GC_PUBLISH : [
        {
            key : "splitter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : EMPTY_STRING
        },
        {
            key : "classification_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : 0,
        },
        {
            key : "classification_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.VALUE_TO_STRING
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"primary_class" : "", "description":"","secondary_classes":[{"class":"","description":""}]}]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "classification_type",
            referValue : ["4","6"],
            copyKey : "primary_samples"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "classification_type",
            referValue : ["5"],
            copyKey : "primary_secondary_samples"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "classification_type",
            referValue : ["7"],
            copyKey : "primary_secondary_splitted_samples"
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "classification,class",
                    copyKey : "class"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "classification,class",
                    copyKey : "class"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "classification,secondary_class",
                    copyKey : "secondary_class"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "splitted_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
                    transformList : [
                        {
                            key : "classification",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                            dataRef : "classification,class",
                            copyKey : "class"
                        },
                        {
                            key : "classification",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                        }
                    ]
                },
                {
                    key : "full_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "full_content,original_text",
                    copyKey : "original_text"
                },
                {
                    key : "full_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "full_content,classification",
                    copyKey : "classification"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "classification,class",
                    copyKey : "class"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "full_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "classification_type",
            referValue : ["1","3","5","7"],
            copyKey : "secondary_classifications"
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "secondary_classes",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.SEPARATE_MAPPED_LIST_VALUES,
            mappedKey : "primary_class",
            mappedKeyList : "primary_class",
            mappedValueKey : "secondary_classes",
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        }
    ],
    TP_LLM_GC : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["1","3","7"]
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.MAP_LISTS_VALUES,
            mainListKey : "classifications",
            mappingListKey : "secondary_classifications",
            mappedKey : "secondary_classes",
            mappingKeyMain : "primary_class",
            mappingKeyMapList : "primary_class",
            emptyMapping : {
                class : "primary_class",
                description : "description"
            }
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "splitter",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["2","3","6","7"]
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["4","6"]
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["5"]
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["7"]
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["class"],
                },
                {
                    key : "class",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["class","secondary_class"],
                },
                {
                    key : "class",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "secondary_class",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["class"],
                },
                {
                    key : "class",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "full_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["original_text","classification"],
                },
                {
                    key : "original_text",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "splitted_content",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
                    transformList : [
                        {
                            key : "classification",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                            keysList : ["class"],
                        },
                        {
                            key : "class",
                            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                        }
                    ]
                }
            ]
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "primary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "primary_secondary_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "primary_secondary_splitted_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["4","5","6","7"]
        },
        {
            key : "classification_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_VALUE
        }
    ],
    TP_LLM_DC_SAMPLE : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_STRUCTURED_LABEL
        }
    ],
    TP_LLM_DC_PUBLISH : [
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : []
        },
        {
            key : "classification_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "0",
        },
        {
            key : "classification_type",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.VALUE_TO_STRING
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : [{"primary_class" : "", "description":"","secondary_classes":[{"class":"","description":""}]}]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "[]"
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "[]"
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
            value : "[]"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
            keysList : ["sample_files"]
        },
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT_LIST,
            keysList : ["sample_temp_files"]
        },
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "file_name",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_KEY_IF_NOT_AVAIL,
                    value : "Classification-Samples-File"
                }
            ]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "classification_type",
            referValue : ["2"],
            copyKey : "classification_samples_primary"
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "classification_type",
            referValue : ["3"],
            copyKey : "classification_samples_secondary"
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "classification,class",
                    copyKey : "class"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "classification,class",
                    copyKey : "class"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
                    dataRef : "classification,secondary_class",
                    copyKey : "secondary_class"
                },
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            referKey : "classification_type",
            referValue : ["1","3"],
            copyKey : "secondary_classifications"
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "secondary_classes",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.SEPARATE_MAPPED_LIST_VALUES,
            mappedKey : "primary_class",
            mappedKeyList : "primary_class",
            mappedValueKey : "secondary_classes",
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "sample_temp_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "sample_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
        },
        {
            key : "image_examples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        }
    ],
    TP_LLM_DC : [
        {
            key : "file",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Sample-Input-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "tool",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "fit_to_lim",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "fit_to_lim",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "fit_to_lim",
            referKeyValue : "false"
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["1","3"]
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.MAP_LISTS_VALUES,
            mainListKey : "classifications",
            mappingListKey : "secondary_classifications",
            mappedKey : "secondary_classes",
            mappingKeyMain : "primary_class",
            mappingKeyMapList : "primary_class",
            emptyMapping : {
                class : "primary_class",
                description : "description"
            }
        },
        {
            key : "secondary_classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["2","3"]
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["2"]
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["3"]
        },
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.STRING_TO_JSON
        },
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            dataRef : "classification_samples,0,sample_files",
            copyKey : "sample_files"
        },
        {
            key : "sample_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.FORM_FILE_OBJECT,
            fileNameKey : "filename",
            fileName : "Classification-Samples-File",
            fileURLKey: "url",
            forSample : true
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["class"],
                },
                {
                    key : "class",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TRANSFORM_DATA_FROM_OBJECT_LIST,
            transformList : [
                {
                    key : "classification",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.ADD_FORMED_OBJECT,
                    keysList : ["class","secondary_class"],
                },
                {
                    key : "class",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                },
                {
                    key : "secondary_class",
                    changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
                }
            ]
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.COPY_KEY,
            copyKey : "samples"
        },
        {
            key : "classification_samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "classification_samples_primary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "classification_samples_secondary",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE
        },
        {
            key : "sample_files",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["2","3"]
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE_NOT,
            referKey : "classification_type",
            referKeyList : ["2","3"]
        },
        {
            key : "classifications",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        },
        {
            key : "samples",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING
        }
    ],
    TP_ASYNCLLM_DS : [
        {
            key : "provider",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "llm",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "summary_size",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.TO_LOWERCASE
        },
        {
            key : "focus",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        },
        {
            key : "focus",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "focus",
            referKeyValue : "[\"\"]"
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.JSON_TO_STRING,
        },
        {
            key : "audience",
            changeType : MODEL_INPUT_JSON_CHANGE_TYPE.REMOVE,
            referKey : "audience",
            referKeyValue : "[\"\"]"
        }
    ]
}

export const MODEL_REFERRER_KEY = {
    TP_LLM_GC : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_DC : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_WS : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_GTE : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_TT : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_TG : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_CDS : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_IC : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_OD : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_VQA : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_CTSA : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_CTED : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },

    TP_LLM_TS : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_SR_STTS : {
        actualKey : "voice",
        key : "language",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_IDE : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_GSA : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_PII : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_SEE : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_SQA : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    }, 
    TP_LLM_CIDE : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_DPII : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_CDE : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_GED : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_DQA : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    }, 
    TP_LLM_TQA : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_ASYNCLLM_DS : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_CID : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    },
    TP_LLM_LT : {
        actualKey : "llm",
        key : "provider",
        type : "stringmatch",
        display : "separator",
        separator : ":",
        valueIndex : 1
    }
}

export const TEXT_OPTIONS = {
    TP_SR_STTS : [
        {
            optionName : "Emphasis",
            optionList : [{label:"Strong",value: "strong"},{label:"Moderate",value: "moderate"},{label:"Reduced",value:"reduced"}],
            getInsertValue : (value) => { return {"startTagText":"<emphasis level='"+value+"'>","endTagText":"</emphasis>"} }
        },
        {
            optionName : "Pauses",
            optionList : [{label:"0.5s",value:"500ms"},{label:"1s",value:"1s"},{label:"2s",value:"2s"},{label:"3s",value:"3s"},{label:"4s",value:"4s"},{label:"5s",value:"5s"},{label:"6s",value:"6s"}],
            getInsertValue : (value) => { return {"startTagText":"<break time='"+value+"'/>","endTagText":""} }
        },
        {
            optionName : "Speed",
            optionList : [{label:"x-Slow",value:"x-slow"},{label:"Slow",value:"slow"},{label:"Medium",value:"medium"},{label:"Fast",value:"fast"},{label:"x-Fast",value:"x-fast"}],
            getInsertValue : (value) => { return {"startTagText":"<prosody rate='"+value+"'>","endTagText":"</prosody>"} }
        },
        {
            optionName : "Pitch",
            optionList : [{label:"x-Low",value:"x-low"},{label:"Low",value:"low"},{label:"Medium",value:"medium"},{label:"Default",value:"default"},{label:"High",value:"high"},{label:"x-High",value:"x-high"}],
            getInsertValue : (value) => { return {"startTagText":"<prosody pitch='"+value+"'>","endTagText":"</prosody>"} }
        },
        {
            optionName : "Volume",
            optionList : [{label:"x-Soft",value:"x-soft"},{label:"Soft",value:"soft"},{label:"Medium",value:"medium"},{label:"Loud",value:"loud"},{label:"x-Loud",value:"x-loud"}],
            getInsertValue : (value) => { return {"startTagText":"<prosody volume='"+value+"'>","endTagText":"</prosody>"} }
        },
        {
            optionName : "Say as",
            optionList : [{label:"Date",value:"date"},{label:"Time",value:"time"},{label:"Address",value:"address"},{label:"Telephone",value:"telephone"},{label:"Spell-Out",value:"spell-out"},{label:"Expletive",value:"expletive"},{label:"Cardinal",value:"cardinal"},{label:"Ordinal",value:"ordinal"},{label:"Characters",value:"characters"},{label:"Unit",value:"unit"},{label:"Digits",value:"digits"},{label:"Fraction",value:"fraction"},{label:"Currency",value:"currency"}],
            getInsertValue : (value) => { return {"startTagText":"<say-as interpret-as='"+value+"'>","endTagText":"</say-as>"} }
        }
    ]
}
