import globalClasses from '../../App.module.css';

import { BUTTON_TEXTS, NOPAGE_FOUND } from "../../strings/Strings";
import cx from 'classnames';
import classes from './PageNotFound.module.css';

import Button from '../ui_elements/button/ButtonNew';
import { ROUTE_CONSTANTS } from '../../routes/RouteConstants';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';
import { IMAGES } from '../../utils/ImageConstants';

function PageNotFound(props) {
    let { isHideBtn } = props;

    let btnComponent = null;

    if(!isHideBtn){
        btnComponent =  (
            <a className={"col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-4"} 
                href={ROUTE_CONSTANTS.HOME} rel="noopener noreferrer" >
                <Button 
                    id={"To-Home-From-Unknown-Page"} 
                    containerClass={"col-12"} 
                    label={BUTTON_TEXTS.BACK_TO_HOME} 
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                />
            </a>
        )
    }

    return(
        <div 
            className={cx(["container"])}
        >
            <div className="d-flex flex-column justify-content-center align-items-center">
                <img className={classes.PageNotFoundImage} src={IMAGES.PAGES.PAGE_NOT_FOUND} alt={'Page Not Found'}/>
                <h4 className={cx(globalClasses.Font36BoldGrey44,classes.HeadingText)}>
                    {NOPAGE_FOUND.NOPAGE}
                </h4>
                <p className={cx([globalClasses.Font18Grey44,classes.SubText])}>
                    {NOPAGE_FOUND.NOPAGETEXT}
                </p>
                {btnComponent} 
            </div>    
        </div>
    )
}

export default PageNotFound;