import APPIAN_LOGO from '../../assets/images/appian1.png'
import UIPATH_LOGO from '../../assets/images/uipath.png'
import BLUEPRISM_LOGO from '../../assets/images/blueprism-logo.png'
import OUTSYSTEMS_LOGO from '../../assets/images/outsystems-logo.png'
import POWERAUTOMATE_LOGO from '../../assets/images/powerautomate-logo.png'
import API_LOGO from '../../assets/images/learnmore-api.png'
import { PRICING } from '../../strings/Pricing'
import { PRICING as IDP_PRICING } from '../../strings/IDPPricing'
import { INFO_MAIL } from '../../utils/Constants'
import globalClasses from '../../App.module.css';

export const AVAILABLE_CONNECTORS = [
    {
        name : 'API',
        url : API_LOGO,
        width : '62px',
    },
    {
        name: 'Appian',
        url: APPIAN_LOGO,
        width : '72px',
    },
    {
        name: 'UiPath',
        url: UIPATH_LOGO,
        width : '76px',
    },
    {
        name: 'Power Automate',
        url: POWERAUTOMATE_LOGO,
        width : '92px',
    },
    {
        name: 'blueprism',
        url: BLUEPRISM_LOGO,
        width : '70px',
    },
    {
        name: 'outsystems',
        url: OUTSYSTEMS_LOGO,
        width : '96px',
    }
]

export const AVAILABLE_FEATURES = [
    {
        name : 'Top - up (Minimum USD 100.00)',
        availability: 1,
        popup_info: 'User can consume models up to available balance'
    },
    {
        name : 'Available models - all',
        availability: 1,
        info: 1
    },
    {
        name: 'Usage Reports',
        availability: 1
    },
    {
        name : 'Customer support',
        availability : 1
    },
    {
        name : 'Multiple API keys & Users',
        availability: 1,
    },
    {
        name : 'API documentation',
        availability: 1,
    },
    {
        name : 'Connectors from the marketplace',
        availability : 1
    },
    {
        name : 'Administration with access controls',
        availability: 1
    },
    {
        name : 'Bring you own LLM',
        availability: 1
    }
]

export const PRICING_PLANS = [
    {
        type: PRICING.PLANS.PLAN_1,
        label: "Free",
        isPopular: 0,
        price: "Free",
        month: "Free - 14 days",
        credits: "20000",
        totalPrice: "Free",
        addOns : "",
        features : [
            {
                name : 'Validity - 14 days',
                availability: 1
            },
            {
                name : '10 calls per day',
                availability: 1
            },
            {
                name : 'No credit card required',
                availability: 1
            }
        ]
    },
    {
        type: PRICING.PLANS.PLAN_2,
        label: "Bronze",
        isPopular: 0,
        price: "USD 199/month",
        month : "USD 199.00",
        quarter : "USD "+String((199 - 199*0.05).toFixed(2)),
        half : "USD "+String((199 - 199*0.08).toFixed(2)),
        annual : "USD "+String((199 - 199*0.12).toFixed(2)),
        credits: "1",
        totalPrice: "USD 199.00",
        features : [
            {
                name : 'Access to all core models',
                availability: 1
            },
            {
                name : 'Access to all classic models',
                availability: 1
            }
        ]
    },
    {
        type: PRICING.PLANS.PLAN_3,
        label: "Silver",
        isPopular: 1,
        price: "USD 499/month",
        month : "USD 499.00",
        quarter : "USD "+String((499 - 499*0.05).toFixed(2)),
        half : "USD "+String((499 - 499*0.08).toFixed(2)),
        annual : "USD "+String((499 - 499*0.12).toFixed(2)),
        credits: "1",
        totalPrice: "USD 499.00",
        addOns : "Professional services & support",
        features : [
            {
                name : 'Everything of Bronze',
                availability: 1
            },
            {
                name : 'Access to augmented models',
                availability: 1
            },
            {
                name : 'Priority email support',
                availability: 1
            },
            {
                name : 'Professional services\n10 hours',
                availability: 1
            }
        ]
    },
    {
        type: PRICING.PLANS.PLAN_4,
        label: "Gold",
        isPopular: 0,
        price: "USD 999/month",
        month : "USD 999.00",
        // comingsoon : true,
        quarter : "USD "+String((999 - 999*0.05).toFixed(2)),
        half : "USD "+String((999 - 999*0.08).toFixed(2)),
        annual : "USD "+String((999 - 999*0.12).toFixed(2)),
        credits: "1",
        totalPrice: "USD 999.00",
        addOns : "Professional services & support",
        features : [
            {
                name : 'Everything of Silver',
                availability: 1
            },
            {
                name : 'Access to create your own Gen AI models',
                availability: 1
            },
            {
                name : 'Dedicated account manager',
                availability: 1
            },
            {
                name : 'On prem hosting',
                availability: 1
            },
            {
                name : 'Professional services\n20 hours',
                availability: 1
            }
        ]
    }
]
   
export const FAQS = [
    {
        question: "How does the Leaf IDP pricing work?",
        answer: <span>{"We calculate the number of pages processed by the model and deduct it from your account's page balance."}</span>,
    },
    {
        question: "What happens if my usage exceeds the page balance?",
        answer: <span>{"For the Lite plan, the page balance is renewed every day until the subscription expires. For Paid plans, the Top-up option is available. You can reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {" to raise additional top-up requests."}</span>,
    },
    {
        question: "Are the limits applied on a per-user basis or on a per-account basis when it comes to page usage?",
        answer: <span>{"The page usage is calculated for each request and page consumption is recorded on account-level."}</span>,
    },
    {
        question: "What happens to my unused page balance?",
        answer: <span>{"Unused page balances do not roll over."}</span>,
    },
    {
        question: "How can I top up my page count?",
        answer: <span>{"Reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {", and our team will ensure you get the support you need."}</span>,
    },
    {
        question: "How can I top up my page balance in the Lite plan?",
        answer: <span>{"For the Lite plan, You can process up to 10 pages every day until the validity of Lite plan. Once the validity expires, You must get a standard or an enterprise plan."}</span>,
    },
    {
        question: "How often do I have to renew a Standard/Enterprise plan?",
        answer: <span>{"Currently, one-month validity is provided for both standard/enterprise plans. If you need a subscription for more than a month, Reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {" for support."}</span>,
    },
    {
        question: "What are the accepted payment methods supported in Vivas.AI?",
        answer: <span>{"Currently, we are handling offline payments. Reach out to us at"} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {"for subscription."}</span>,
    },
    {
        question: "Can you invoice us?",
        answer: <span>{"Yes. We send invoice for Standard and Enterprise plans. Please reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {" for details."}</span>,
    },
    {
        question: "Can we receive a discount if we commit to using Vivas.AI services for a specific period under a contract?",
        answer: <span>{"Reach out to us at"} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {"for any special offers/discounts."}</span>,
    },
    {
        question: "Do you provide support or onboarding assistance for enterprise customers?",
        answer: <span>{"Yes. Basic onboarding assistance is provided with the plan but if you are looking for any additional professional services, please reach out to us at"} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {"."}</span>,
    },
    {
        question: "Are there any additional costs for technical support or customer assistance?",
        answer: <span>{"All kinds of basic support are covered in Standard and Enterprise plans. For any customized support, reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {"."}</span>,
    },
    {
        question: "Are Professional services included in self hosting? Will the subscription cost include Vivas.AI's support for self hosting?",
        answer: <span>{"Enterprise plan offers self-hosting capabilities and basic customer support. For any additional support, reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {"."}</span>,
    },
    {
        question: "Do you offer any Service-Level Agreements (SLAs) for uptime or performance?",
        answer: <span>{"Yes! This can be included in the paid plans upon request. Please reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {" for details."}</span>,
    },
    {
        question: "How long are my documents saved in Vivas.AI cloud?",
        answer: <span>{"Currently, the documents are saved for two days. Then it will be removed permanently."}</span>,
    },
    {
        question: "What if my documents are so sensitive by nature that I do not want it to be processed in Vivas.AI's managed environment?",
        answer: <span>{"You can opt for the Enterprise plan, which offers self-hosting capabilities, where  you can host models in your own environment."}</span>,
    },
    {
        question: "How to add my LLM keys to the configurator?",
        answer: <span>{"To incorporate your LLM keys into the configurator, follow these steps:\n1. Navigate to the"} <b>{"\"Manage\""}</b> {"section.\n2. Select the"} <b>{"\"Configurator\""}</b> {"tab.\n3. Input your LLM keys in the designated area.\nThese keys are utilized to access the model whenever a query is made. Presently, this functionality is accessible for OpenAI, Azure-OpenAI, and Cohere.\n"}<b>{"Note:"}</b> {"Your keys are securely stored to maintain confidentiality and prevent unauthorized usage."}</span>,
    },
    {
        question: "What if I don't have LLM keys?",
        answer: <span>{"Reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {" and our team will ensure you get the support you require."}</span>,
    },
    {
        question: "In addition to LLM, what other services are compatible with our keys for configuration?",
        answer: <span>{"IDP models utilize OCR services from providers such as AWS and Azure. You have the option to include authentication keys for these OCR services. In case you do not possess any keys, please contact us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {",and our team will assist you in obtaining the necessary support."}</span>,
    },
    {
        question: "Can an admin of my account access the configurator key which I provided?",
        answer: <span>{"Admin of the account can only create/update/delete a configurator key. However, no one can view the key for security reasons."}</span>,
    },
    {
        question: "Can I add/invite people to my account?",
        answer: <span>{"Yes, you can add/invite people to your account."}</span>,
    },
    {
        question: "Is there a restriction on the number of users that I can add to my account?",
        answer: <span>{"There is no restriction on adding users in an account."}</span>,
    },
    {
        question: "Can I create multiple API keys in an account?",
        answer: <span>{"Yes, an account admin can create multiple API keys in an account."}</span>,
    },
    {
        question: "What are Core models, Augmented models and Enterprise models?",
        answer: <span>{"1.Core models allow users to define and adjust parameters. It empowers users to fine-tune the model's behavior according to processing requirements, ensuring optimal performance and accuracy.\n2.Augmented models are offered by Vivas.AI by parameterizing the core models. These models are designed to solve a specific use case or task. These models are globally accessible for all account users.\n3.Enterprise models are models that you can create on your own tailored to perform your specific task by adding custom parameters to a core model. The models that you publish will be accessible only by users/API of your account. For example, you can create a passport extraction model by customizing the ID Extractor model. Refer "} <a href={"https://vivasai.readme.io/reference/leaf-idp-studio"} target={"_blank"} rel={"noopener noreferrer"}>{"documentation"}</a> {" for more information."}</span>,
    },
    {
        question: "Can I create enterprise models in the Lite plan?",
        answer: <span>{"Yes. you can create enterprise models in the Lite plan."}</span>,
    },
    {
        question: "Will other users be able to see the draft models I created and saved in my account?",
        answer: <span>{"No. Draft models will be visible only to the user who saved it as draft. Once it is published, it will be visible to everyone in that user's account."}</span>,
    },
    {
        question: "Can I access Augmented/Enterprise models through API?",
        answer: <span>{"Yes. you can use Augmented/Enterprise models through API. It is mandatory to send "} <b>{"'skill_id'"}</b> {" as a request header."}</span>,
    },
    {
        question: "How to access core models through API documentation?",
        answer: <span>{"Generate a Bearer token from the 'Manage' section. Go to the "} <a href={"https://vivasai.readme.io/reference/grant-authentication-token" } target={"_blank"} rel={"noopener noreferrer"}>{"API Documentation"}</a> {" page and enter the Bearer token in the Authorization section. Fill in the request parameters and click 'Try it'. Refer "} <a href={"https://vivasai.readme.io/reference/grant-authentication-token"} target={"_blank"} rel={"noopener noreferrer"}>{"API Documentation"}</a> {" for more information."}</span>,
    },
    {
        question: "How to access Augmented/Enterprise models through API documentation?",
        answer: <span>{"Generate a Bearer token from the 'Manage' section. Go to the "}<a href={"https://vivasai.readme.io/reference/grant-authentication-token"} target={"_blank"} rel={"noopener noreferrer"}>{"API Documentation"}</a> {" page and enter the Bearer token in the Authorization section. Fill in the request parameters and 'skill_id' in the header section and click 'Try it'. Refer "} <a href={"https://vivasai.readme.io/reference/grant-authentication-token"} target={"_blank"} rel={"noopener noreferrer"}>{"API Documentation"}</a> {" for more information."}</span>,
    },
    {
        question: "Does the user have to be trained to use Leaf IDP?",
        answer: <span>{"No special training is required to use Leaf IDP. We help customers in onboarding and provide extensive API Documentation to seamlessly use and  integrate models without any difficulties. However, you can reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {", if you need any additional assistance."}</span>,
    },
    {
        question: "Can Leaf IDP process handwritten or scanned documents?",
        answer: <span>{"Yes. Leaf IDP supports all documents be it handwritten or scanned documents but the accuracy depends on clarity and quality of the document."}</span>,
    },
    {
        question: "Is there a limit on how many entities I can extract?",
        answer: <span>{"There is no limit on the number of entities you can extract. However regarding the limit on the number of pages, refer to the model card section of the corresponding model."}</span>,
    },
    {
        question: "Are there any restrictions on the types of documents Leaf IDP can process and the maximum file size associated with each file?",
        answer: <span>{"The supported file format and file size limitation will differ among each Leaf IDP model. Refer to Model card section."}</span>,
    },
    {
        question: "Can Leaf IDP process bulk documents?",
        answer: <span>{"No. Currently, Leaf IDP does not support processing bulk documents."}</span>,
    },
    {
        question: "What languages does Leaf IDP support for defining model configurations?",
        answer: <span>{"Currently, All model configurations must be provided in the English language. If you are looking for other language support, please feel free to reach out to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a>{"."}</span>,
    },
    {
        question: "How can I verify model extraction accuracy?",
        answer: <span>{"Leaf IDP offers confidence score and bounding box as a part of model response. Though the  bounding box is provided for all LLM vendors, confidence score is provided only for OpenAI at the moment."}</span>,
    },
    {
        question: "Is there a way to monitor my model usage and account activity?",
        answer: <span>{"Navigate to the 'Manage' section and visit the 'Reports' tab to track model usage. You can get page balance information from that section."}</span>,
    },
    {
        question: "Is the human in the loop option available?",
        answer: <span>{"No, the human in the loop option is not available at the moment."}</span>,
    },
    {
        question: "What if I have additional queries?",
        answer: <span>{"Write to us at "} <a href={"mailto:"+INFO_MAIL}>{INFO_MAIL}</a> {" and our team will get in touch with you."}</span>,
    }
    
]


export const IDP_AVAILABLE_FEATURES = [
    // {
    //     name: 'Usage Reports',
    //     availability: 1
    // },
    // {
    //     name : 'Customer support',
    //     availability : 1
    // },
    // {
    //     name : 'Multiple API keys & Users',
    //     availability: 1,
    // },
    // {
    //     name : 'API documentation',
    //     availability: 1,
    // },
    {
        name : 'Connectors from the marketplace',
        availability : 1
    },
    {
        name : 'Administration with access controls',
        availability: 1
    },
    {
        name: "LLM's Supported - OpenAI/Azure Open AI 3.5, 4.0, Mixtral, LLama",
        availability:1
    },
    {
        name : 'Enterprise Security',
        availability: 1
    }
]


export const IDP_PRICING_PLANS = [
    {
        type: IDP_PRICING.PLANS.PLAN_1,
        label: "Lite",
        isPopular: 0,
        price: "Free",
        month: "Free - 14 days",
        credits: "0",
        totalPrice: "Free",
        addOns : "",
        enabled : true,
        features : [
            {
                name : 'Validity - 14 days',
                availability: 1
            },
            {
                name : '10 Pages per day',
                availability: 1
            },
            {
                name : "Deployment - Vivas.AI Mangaged",
                availability: 1
            },
            {
                name : "60+ out-of-the-box Models",
                availability: 1
            },
            {
                name : "Use your own LLM Keys",
                availability: 1
            },
            {
                name : "OCR Keys - Vivas.AI Managed",
                availability: 1
            }
        ]
    },
    {
        type: IDP_PRICING.PLANS.PLAN_2,
        label: "Standard",
        isPopular: 0,
        price: "USD 499/month",
        month : "USD 499.00",
        quarter : "USD "+String((499 - 499*0.05).toFixed(2)),
        half : "USD "+String((499 - 499*0.08).toFixed(2)),
        annual : "USD "+String((499 - 499*0.12).toFixed(2)),
        credits: "1",
        totalPrice: "USD 499.00",
        features : [
            {
                name : '3,000 Pages/month',
                availability: 1
            },
            {
                name : "Deployment - Vivas.AI Mangaged",
                availability: 1
            },
            {
                name : "60+ out-of-the-box Models",
                availability: 1
            },
            {
                name : "Enterprise Model Creation - Unlimited",
                availability: 1
            },
            {
                name : "Enterprise Model Hosting - Vivas.AI Managed",
                availability: 1
            },
            {
                name : "Use your own LLM Keys",
                availability: 1
            },
            {
                name : "OCR Keys - Vivas.AI Managed",
                availability: 1
            }
        ],
        addOns : "TopUp - 2000 Pages for 100 USD"
    },
    {
        type: IDP_PRICING.PLANS.PLAN_3,
        label: "Enterprise",
        isPopular: 1,
        price: "USD 999/month",
        month : "USD 999.00",
        quarter : "USD "+String((999 - 999*0.05).toFixed(2)),
        half : "USD "+String((999 - 999*0.08).toFixed(2)),
        annual : "USD "+String((999 - 999*0.12).toFixed(2)),
        credits: "1",
        totalPrice: "USD 999.00",
        features : [
            {
                name : '10,000 Pages/month',
                availability: 1
            },
            {
                name : "Deployment - Self Hosting",
                availability: 1
            },
            {
                name : "60+ out-of-the-box Models",
                availability: 1
            },
            {
                name : "Enterprise Model Creation - Unlimited",
                availability: 1
            },
            {
                name : "Enterprise Model Hosting - Self Hosted",
                availability: 1
            },
            {
                name : "Use your own LLM Keys",
                availability: 1
            },
            {
                name : "Use your own OCR Keys",
                availability: 1
            }
        ],
        addOns : "TopUp - 3000 Pages for 100 USD"
    }

]
