// normal api folder or azure function url(UAT)
export const GET_ATTRIBUTES = '/api/attributes/'
export const GET_MODELS = '/api/models/'
export const GET_MODEL_DETAILS = '/api/modelDetails/'
export const GET_MODEL_TIER_DETAILS = '/api/getModelTierDetails/'
export const CALL_MODEL = "/api/callmodel"
export const ESTIMATE_MODEL = "/api/estimate"
export const POST_SUBSCRIBE = '/api/createsubscriptionrequest/'
export const POST_RENEWAL = '/api/renewaccount/'
export const POST_SUBMIT_FEEDBACK = '/api/feedback/'
export const UPDATE_MODEL_LIKE = '/api/like/'
export const POST_DEMO_REQUEST = '/api/requestdemo/'
export const POST_PARTNER_REQUEST = '/api/partnerrequest/'
export const POST_CONTACT = '/api/contactus/'
export const POST_EMAIL = '/api/sendmail/'
export const POST_TOPUP = '/api/createtransactionentry/'
export const POST_PROF_SERVICE = '/api/profservice/'
export const POST_WHITEPAPER = '/api/whitepaper/'
export const POST_CAREER = '/api/postcareerform/'
export const GET_BLOGS = "/api/blogs/"
export const GET_USECASE = "/api/usecases/"
export const GET_FAQS = "/api/faqs/"
export const GET_INDUSTRIES = "/api/industries"
export const GET_HTTP_ERROR_CODES = "/api/http-error-codes/"
export const FILE_UPLOAD_S3 = '/api/uploadtos3/'
export const FILE_UPLOAD_TO_S3 = '/api/upload-file-s3/'
export const UPLOAD_FILE_TO_S3 = '/api/upload-file-to-s3/'
export const FILE_DOWNLOAD_FROM_S3 = '/api/download-file-s3/'
export const FILE_UPLOAD_ASYNC = "/api/uploadasync";
export const CREATE_TASK = '/api/createtask'
export const DRAW_BOUNDING_BOX = '/api/draw-bounding-box'
export const POST_MODEL_RATING = '/api/rating'
export const POST_SUBMIT_FAVOURITE_MODEL = '/api/favourite-models/'
export const POST_AUDIT_LOG = '/api/event-audit-logs'
export const GET_RECOMMENDED_MODELS = '/api/recommend-model/'
export const POST_AUTOGENERATE_ENTITIES = '/api/generate-entity/'

// GPT Flow Publish, Edit, Get and Delete Models
export const POST_GPT_FLOW_PUBLISH_MODEL = '/api/publish-model'

// service accounts and users CRUD URL
export const SERVICE_ACCOUNT_CREATE_EDIT = '/api/um/createupdateserviceaccount'
export const SERVICE_ACCOUNT_RENEW_SECRET = '/api/um/renewserviceaccount' // not used
export const SERVICE_ACCOUNT_DELETE = '/api/um/deleteserviceaccount'
export const GET_USERS_SERVICE_ACCOUNT = '/api/um/getuserserviceaccountdetail'
export const USER_CREATE_EDIT = '/api/um/createupdateuser' // not used
export const CREATE_UPDATE_NAMED_USERS = '/api/um/createupdateuser-v1'
export const DELETE_NAMED_USER = '/api/um/deleteuser' // not checked
export const VALIDATE_USER = '/api/um/validateuseremail' // not used
export const GENERATE_AUTH_TOKEN = '/api/um/generateauthtoken'
export const POST_SUBMIT_DISCOUNT = '/api/um/discountrequest'

// payment APIs - not checked
export const CREATE_SUBSCRIPTION_REQUEST = '/api/um/createsubscriptionrequest'
export const UPDATE_SUBSCRIPTION_REQUEST = '/api/um/updatesubscriptionrequest'
export const CREATE_TRANSACTION = '/api/um/createtransactionentry'
export const UPDATE_TRANSACTION = '/api/um/updatetransactionentry'
export const RENEW_SUBSCRIPTION_PACKAGE = '/api/um/renewaccount'
export const CREATE_CHANGE_SUBSCRIPTION = '/api/um/createchangesubscription'
export const UPDATE_CHANGE_SUBSCRIPTION = '/api/um/updatechangesubscription'

// accounts superadmin
export const GET_ACCOUNTS = '/api/um/getaccount' 
export const ADD_ACCOUNT_WITH_USER = '/api/um/createaccountandfirstuser'
export const UPDATE_ACCOUNT = '/api/um/updateaccount'
export const GET_PROFIT_LOSS_REPORT = '/api/um/getprofitlossreport'
export const GET_SUPPORT_REQUESTS = '/api/um/getsupportticket'
export const GET_TRANSACTIONS = '/api/um/getpaymentdetails?account_id='
export const GET_USER_INVITES_STATUS = '/api/um/getinvitationstatus'
export const MODEL_ADMINISTATION = '/api/um/models'
export const GET_ACCOUNT_LEVEL_PA_REPORT = '/api/um/getaccountprofitlossreport'
// report url
export const REPORT_DATA = '/api/um/getusagereport'
export const DETAILED_REPORT_DATA = '/api/um/getdetailreport'

// support url
export const RAISE_SUPPORT_TICKET = '/api/um/createsupportticket'

// model administation
export const MODEL_MAPPING_ADMINISTARTION = '/api/um/account_model_mapping'

// account level address edit
export const UPDATE_ACCOUNT_DETAILS = '/api/um/editaccountaddress'

// account unsubscription
export const UNSUBSCRIBE_ACCOUNT = '/api/um/unsubscribe'

// secrets crud operatrions url path
export const LLM_SECRETS = '/api/um/llm-secret-keys'

// licensee details get url
export const GET_LICENSEE_DETAILS = '/api/product-license'
export const GET_LICENSEE_KEY = '/api/get-licensee-key'

// create user and getuser urls (auth)
export const TRY_FOR_FREE_CREATE_USERS = '/api/um/inviteuser'
export const GET_USER_PROFILE = "/api/um/getuserprofile"
export const VALIDATE_USER_CONFIRMATION = "/api/um/validateuserconfirmation"

// model feedback
export const POST_MODEL_FEEDBACK = '/api/um/userfeedback'

// mid tier ad ons
export const GET_MODEL_SAMPLES = '/model/sample'
export const GET_MODEL_CARD_DETAILS = '/model/detail'
export const GET_MODEL_CACHED_RESULT = '/model/cached'

export const GET_MODEL_DETAILS_AUTH = '/001'

//payment gateway
export const STRIPE_CREATE_ORDER = '/api/create-checkout-session/'

// validate and create account
export const VALIDATE_USER_SUBSCRIPTION = '/api/validate-subscription/'