// modules
import cx from 'classnames';
import { MdKeyboardArrowLeft } from 'react-icons/md';

// utils
import { BUTTON_TEXTS } from "../../strings/Strings";
import { isEmpty } from '../../utils/UtilityFunctions';

// classes
import classes from './UserManagement.module.css';
import globalClasses from '../../App.module.css';

// components
import ModalCard from "../../components/ui_elements/modal_card/ModalCard";
import Popup from "../../components/ui_elements/popup/Popup";
import Button from "../../components/ui_elements/button/ButtonNew";

// images
import LoaderGIF from '../../assets/images/loader/loader-gif.gif';
import Loader from '../../components/loader/Loader';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';

function AccountCreationPopup(props) {
    let {title, onCloseHandler, onSubmitHandler, isSubmitting, submittingText,
        submitButtonText, submitButonWithIcon, submitButonIconPosition,
        submitButtonIcon, enableBack, onClickBack, subHeadingComponent,
        disableSubmitButton, noteText, disableSecondaryButton}= props;
    let buttongroupComponent = null;
    let noteComponent = null;

    if(!isEmpty(noteText)){
        noteComponent = (
            <div className='mx-3 mb-2'>
                <span className={globalClasses.Font14Grey}>
                    {noteText}
                </span>
            </div>

        )
    }
    if(isSubmitting){
        buttongroupComponent = (
            // <span className='d-flex align-items-center mx-3 mx-md-5 justify-content-end'>
            //     <img className={globalClasses.LoaderSmallSize} src={LoaderGIF} alt={'Submit'}/>
            //     <span className={globalClasses.Font16LoadText}>{submittingText}</span>
            // </span>
            <Loader
                containerClass={globalClasses.FullPageLoader}
            />
        )
    } else {
        buttongroupComponent = (
            <div className='d-flex flex-wrap px-3'>
            {
                enableBack &&
                <span 
                    className={cx(['me-auto my-auto col-12 col-md-2 justify-self-start',classes.BackButton])}
                    onClick={onClickBack}
                >
                   <MdKeyboardArrowLeft style={{marginBottom: '2px'}} size={24}/> {'Back'}
                </span>
            }
            <div className="row col-12 ms-auto justify-content-end">
                {
                    !disableSecondaryButton &&
                    <Button
                        containerClass="col-6 col-md-4" 
                        label={BUTTON_TEXTS.CANCEL} 
                        onClickHandler={onCloseHandler} 
                        actionType={BUTTON_TYPES.ACTIONS.SECONDARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                    />
                }
                <Button
                    containerClass={cx(["col-6 col-md-4"])} 
                    label={isEmpty(submitButtonText)?BUTTON_TEXTS.SUBMIT:submitButtonText}
                    onClickHandler={onSubmitHandler} 
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                    disabled={disableSubmitButton}
                />
            </div>
            </div>
        )
    }

    return(
        <div>
            <Popup className={globalClasses.PopupBack}>
                <ModalCard 
                    className={'col-11 col-md-8 col-lg-6 px-0'}
                    titleContainerClass={'px-3'}
                    title={title} 
                    isPopupFixed={true} 
                    onClose={onCloseHandler}
                    isForm={true}
                >
                    <div className='px-3 mt-3'>
                        {subHeadingComponent}
                    </div>
                    <div className={cx(['p-3 my-3',classes.AccountCreation])}>
                        {props.children}
                    </div>
                    {noteComponent}
                    {buttongroupComponent}
                </ModalCard>
            </Popup>
        </div>
    )
}

export default AccountCreationPopup;