
import { withRouter } from "react-router-dom";
import renderRouteBasedComponent from '../routes/Routes'
import { UserContext } from "./UserContext";
import SessionTimeOut from "../components/session_timeout/SessionTimeOut"
import React, { useEffect } from "react";
import { toast } from 'react-toastify';
import { getAccountSubscriptions, isEmpty, isEmptyList } from '../utils/UtilityFunctions';
import UserPool from "../containers/sign_up/UserPool";
import { TASK_STATUS } from '../utils/Constants';
import { getCognitoUserAccessToken } from "../containers/sign_up/Cognito.utils"

var token;
let socket;
if (!isEmpty(UserPool.getCurrentUser())) {
    token = getCognitoUserAccessToken();
    socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + "?token=" + token);
}
// const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + "?token=" + token);
// const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
// console.log('socket')

const Main = () => {

    const isAuthenticated = !isEmpty(UserPool.getCurrentUser())
    const userDetails = React.useContext(UserContext)
    // console.log(userDetails)
    let params;

    useEffect(() => {
        async function openWebSocketConnection() {
            console.log("isAuthenticated", isAuthenticated)
            if (socket && isAuthenticated) {
                console.log(userDetails)
                if (!isEmpty(userDetails)) {
                    if (!isEmpty(userDetails.user)) {
                        params = { action: "saveConnection", data: userDetails.user.email };
                        // console.log('param: ', params.data)

                        console.log('Opening connection..');

                        socket.addEventListener('open', (event) => {
                            console.log(socket.readyState)
                            socket.send(JSON.stringify(params));
                            console.log('Connected on open!')
                        });

                        if (socket.readyState === WebSocket.OPEN) {
                            socket.send(JSON.stringify(params));
                            console.log('Connected on eady state!')

                            socket.addEventListener('message', (event) => {

                                //  toast(JSON.parse(JSON.stringify(event.data.message)), {
                                //     type: 'info',
                                //     theme: 'colored'
                                //  });
                                toast(JSON.parse(event.data).message,
                                    {
                                        type: JSON.parse(event.data).status === TASK_STATUS.COMPLETED ? 'success' : 'error',
                                        theme: 'light'
                                    });
                            });
                        }
                    }
                }
            }
        }

        openWebSocketConnection()

        return () => {
            if (socket) {
                // Cleanup function
                window.addEventListener("beforeunload", function (e) {
                    let user = UserPool.getCurrentUser()
                    const wsdisconnect = () => {
                        socket.addEventListener('close', (event) => {
                            console.log('disconnected', event.data);
                        });
                    };
                    WebSocket.close();
                    if (user) {
                        user.signOut()
                    }
                });
            }
        }
    }, [isAuthenticated])

    return (
        <>
            <UserContext.Consumer>
                {(user) => {
                    if(!isEmptyList(user?.accountDetail) && !isEmptyList(user.accountDetail?.[0]) && !isEmptyList(user?.subscriptions)){
                        let accountSubscriptionDetails = Object.assign({},getAccountSubscriptions(user?.subscriptions))
                        let userAccountDetail = user.accountDetail?.[0]
                        user.accountDetail[0] = Object.assign({},{...userAccountDetail,...accountSubscriptionDetails})
                    }
                    return (
                        // renderRouteBasedComponent(isAuthenticated, user.data , user.account_detail, user)
                        renderRouteBasedComponent(isAuthenticated, user)
                    );
                }}
            </UserContext.Consumer>

            {isAuthenticated && <SessionTimeOut />}

            {/* <SessionTimeOut isAuthenticated={isAuthenticated}/> */}
        </>
    )
}
export default withRouter(Main);