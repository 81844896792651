import { SELF_HOSTING_INFOS } from "./SelfHostingsInfo.data";
import {useState} from 'react'
import { SELF_HOSTING_CONTENT_VIEWS, getContentData } from "./SelfHostingsInfo.utils";
import { downloadEnvFile, isEmpty, isEmptyList, isEmptyObject, isValidTextString } from "../../utils/UtilityFunctions";
import cx from 'classnames';
import { BUTTON_TEXTS, EMPTY_STRING } from "../../strings/Strings";
import classes from './SelfHostingsInfo.module.css';
import { MdEdit, MdOutlineKeyboardArrowRight } from "react-icons/md";
import Button from "../../components/ui_elements/button/ButtonNew";
import { BUTTON_TYPES } from "../../utils/ButtonConstants";
import Popup from "../../components/ui_elements/popup/Popup";
import globalClasses from "../../App.module.css";
import ModalCard from "../../components/ui_elements/modal_card/ModalCard";
import TextField from "../../components/ui_elements/form_fields/text_input/TextField";

function SelfHostingInfo(props){
    let {modelCode, skillID, environmentVariables, accountUUID, licenseeID} = props;
    const [currentInfoData,setCurrentInfoData] = useState(SELF_HOSTING_INFOS[0]);
    const [downloadData,setDownloadData] = useState({});
    let selfHostingContainer= null;
    let viewValue = (isEmpty(modelCode) && isEmpty(skillID)) ? SELF_HOSTING_CONTENT_VIEWS.MANAGE : (isEmpty(skillID)) ? SELF_HOSTING_CONTENT_VIEWS.CORE_MODEL : SELF_HOSTING_CONTENT_VIEWS.AUGMENTED_MODEL
    let downloadEnvComponent = null;
    let downloadModalComponent = null;

    const setEnvVariables = () => {
        let setDownloadFormData = Object.assign([],props[currentInfoData?.downloadContentKey]);
        if(!isEmptyList(setDownloadFormData)){
            let downloadEnvData = {};
            setDownloadFormData.map((envData,index) => {
                let envKey = envData?.[currentInfoData?.downloadEnvData?.envKey];
                let envValue = envData?.[currentInfoData?.downloadEnvData?.envValueKey];
                if(envKey === "LICENSEE_ID"){
                    envValue = licenseeID
                }else if(envKey === "ACCOUNT_ID"){
                    envValue = accountUUID
                }
                downloadEnvData[envKey] = envValue;
                return index;
            })
            setDownloadData(downloadEnvData)
        }
    }

    const onCloseHandler = () => {
        if(document.body.style.overflow !== "auto"){
            document.body.style.overflow = "auto"
        }
        setDownloadData({})
    }

    const onDownloadHandler = () => {
        downloadEnvFile(modelCode+"_ENV",downloadData);
    }

    const onChangeEnvVariableValue = (key,value) => {
        let newEnvData = Object.assign({},JSON.parse(JSON.stringify(downloadData)));
        newEnvData[key] = value;
        setDownloadData(newEnvData)
    }

    if(!isEmptyObject(currentInfoData?.downloadEnvData) && currentInfoData?.downloadViews.includes(viewValue)){
        downloadEnvComponent = (
            <Button
                id={"Env-Download-Button"} 
                containerClass={"ms-auto col-6 col-md-4 col-lg-3 mb-4 mt-3 mt-md-0"}
                label={BUTTON_TEXTS.EDIT_AND_DOWNLOAD} 
                icon={<MdEdit className={"me-2"}/>}
                actionType={BUTTON_TYPES.ACTIONS.SECONDARY} 
                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                borderType={BUTTON_TYPES.BORDER.BORDERED} 
                onClickHandler={setEnvVariables}
            />
        )
    }

    if(!isEmptyObject(downloadData)){
        downloadModalComponent = (
            <Popup className={globalClasses.PopupBack}>
                 <ModalCard 
                    className={'col-12 col-sm-11 col-md-10 col-lg-8 col-xl-7 px-0'}
                    titleContainerClass={'px-3'}
                    title={"Container Environment Variables"} 
                    isPopupFixed={true} 
                    onClose={onCloseHandler}
                    isForm={true}
                >
                    <div className={cx(['col-12 p-3',classes.EnvPopupForm])}>
                        {
                            Object.keys(downloadData).map((dataKey,index) => {
                                return (
                                    <div key={index} className="row flex-wrap">
                                        <div className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0',classes.EnvLabel])}>
                                            <label title={dataKey} htmlFor={dataKey+"-Input"}>
                                                {dataKey.replace(/\_/g," ")}
                                            </label>
                                        </div>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={downloadData?.[dataKey]}
                                                id={dataKey+"-Input"}
                                                placeHolder={"Type your value here..."}
                                                onChangeHandler={(value) => onChangeEnvVariableValue(dataKey,value)}
                                                validate={isValidTextString}
                                                isMandatory={false}
                                                isNumber={false}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="col-12 p-3">    
                        <div className="row flex-wrap justify-content-end">
                            <Button
                                id={"Cancel-Env-Download-Button"} 
                                containerClass={"col-6 col-md-4 col-lg-3"}
                                label={BUTTON_TEXTS.CANCEL} 
                                actionType={BUTTON_TYPES.ACTIONS.SECONDARY} 
                                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                                borderType={BUTTON_TYPES.BORDER.NO_BORDER} 
                                onClickHandler={onCloseHandler}
                            />
                            <Button
                                id={"Download-Button"} 
                                containerClass={"col-6 col-md-4 col-lg-3"}
                                label={BUTTON_TEXTS.DOWNLOAD} 
                                actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                                borderType={BUTTON_TYPES.BORDER.BORDERED} 
                                onClickHandler={onDownloadHandler}
                            />
                        </div>
                    </div>
                </ModalCard>
            </Popup>
        )
    }
    
    selfHostingContainer = (
        <div className="row flex-wrap">
            {downloadModalComponent}
            <div className="col-12 col-md-3">
                <div className={cx("col-12",classes.MenuContainer)}>
                    {
                        SELF_HOSTING_INFOS.map((info) => {
                            return (
                                <label className={cx("col-12",classes.MenuItem ,(info.key===currentInfoData.key) ? classes.MenuActiveItem : EMPTY_STRING)} 
                                    key={info.key} onClick={() => setCurrentInfoData(info)}>
                                    <span>{info.label}</span><MdOutlineKeyboardArrowRight/>
                                </label>
                            )
                        })
                    }
                </div>
            </div>
            <div className="col-12 col-md-9">
                {
                    downloadEnvComponent &&
                    <div className="col-12">
                       {downloadEnvComponent}
                    </div>
                }
                <div className="col-12">
                    {
                        getContentData(currentInfoData.contents,{modelCode,skillID,environmentVariables,accountUUID, licenseeID},viewValue)
                    }
                </div>
            </div>
        </div>
    )

    return selfHostingContainer
}

export default SelfHostingInfo;