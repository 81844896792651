import { useState } from 'react';
import globalClasses from '../../../../App.module.css';
import { getDollarFromNumber, getWesternNumber, isEmpty, isValidNumber, isValidWholeNumber } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'
import classes from './TopupField.module.css';
import { EMPTY_STRING } from '../../../../strings/Strings';
import { CREDIT_PER_USD, KEY_CODES, MIN_TOPUP_AMOUNT, TOPUP_UNIT_AMOUNT, VALIDATION_REGEX } from '../../../../utils/Constants';

function TopupField(props) {
    let {containerClass, value,placeHolder, onChangeHandler, isDisabled, isFieldError,
        isReadOnly, label, id, isError, validate, isMandatory, isNumber, minValue, maxValue,
        onDecreaseHandler, onIncreaseHandler, showTotal } = props;
    let labelComponent = null
    let [isTextError,setIsTextError]=useState(false);
    let [errorText,setErrorText] = useState(false);

    console.log('Submit error',isError);
    
    const validateTopupField = (value) => {
        let validateResult = validate(value, minValue, maxValue, isMandatory)
        setIsTextError(validateResult.error)
        setErrorText(validateResult.text)
    }
    const onChangeText = (input) => {
        let trimmedValue = input.replace(/^\s+/g, '');
        trimmedValue = trimmedValue.replace(/\s{2}/g, ' ');
        trimmedValue = trimmedValue.replace(/^[0]+/,'');
        if(!isNaN(trimmedValue) && isValidWholeNumber(trimmedValue)){
            onChangeHandler(trimmedValue)
            if(isError){
                validateTopupField(trimmedValue)
            }
        }
    }

    if(isFieldError && !isTextError){
        validateTopupField(value)
    }
    if(!isError && isTextError){
        setIsTextError(false);
        setErrorText(EMPTY_STRING);
    }

    if(!isEmpty(label)){
        labelComponent = (
            <label
                className={cx([globalClasses.FormLabel,isMandatory?globalClasses.FormRequiredInput: ''])} 
                htmlFor={id}>
                {label}
            </label>
        )
    }
    let formFieldContainerClass = isEmpty(containerClass)? "form-group col-12 col-md-6 px-1 px-md-3 my-3" 
                                    : containerClass;
    let totalComponent = null;
    if(showTotal){
        totalComponent = (
            <tr>
                <th>
                    Top-up Amount
                </th>
                <td className='text-end' colSpan={2}>
                    <div className='d-flex flex-column'>
                        <span className={globalClasses.Font24Bold}>{getDollarFromNumber((MIN_TOPUP_AMOUNT + TOPUP_UNIT_AMOUNT * Number(value)).toFixed(2))}</span>
                        <span className={globalClasses.Font14Grey}>({getWesternNumber((MIN_TOPUP_AMOUNT + TOPUP_UNIT_AMOUNT * Number(value)) * CREDIT_PER_USD)} credits)</span>
                    </div>
                </td>
            </tr>
        )
    }
    return(
        <div className={formFieldContainerClass}>
            {labelComponent}
            {/* <div className={cx(['my-3',globalClasses.Font14MediumGrey])}>
                <span>
                    * {getDollarFromNumber(1)} = {getWesternNumber(1 * CREDIT_PER_USD)} credits
                </span>
            </div> */}
            <div className='d-flex'>
                
                <table className={cx(['col-12 table table-borderless',classes.TopupTable])}>
                    <tr>
                        <th>
                            Default Top-up Amount
                        </th>
                        {/* <td>

                        </td> */}
                        <td className={cx(['text-end',globalClasses.FixedWidth150,classes.Amount])}>
                            {getDollarFromNumber(MIN_TOPUP_AMOUNT.toFixed(2))}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Add extra top-up amount (USD) :
                        </th>
                        <td className='text-end'>
                            <div className='d-flex flex-column px-0'>
                                <div className='d-flex align-self-end px-0'>
                                    <button onClick={onDecreaseHandler} className={cx([classes.IncreaseDecreaseButton,classes.Left])} disabled={Number(value) <= minValue}> - </button>
                                    <input 
                                        className={cx(["form-control",globalClasses.FormInput,classes.TopupInput])}
                                        type={'text'}
                                        id={id}
                                        value={value}
                                        placeholder={placeHolder}
                                        disabled={isDisabled}
                                        readOnly = {isReadOnly}
                                        step={"1"}
                                        pattern={VALIDATION_REGEX.NUMBER}
                                        onChange={(event) => onChangeText(event.target.value)}
                                        min={minValue}
                                        max={maxValue}
                                    />
                                    <button onClick={onIncreaseHandler} className={cx([classes.IncreaseDecreaseButton,classes.Right])} disabled={Number(value) >= maxValue}> + </button>
                                </div>
                                {/* <span className={cx(['align-self-end',globalClasses.Font12Grey])}>
                                    ( {getDollarFromNumber(TOPUP_UNIT_AMOUNT.toFixed(2))} / addition )
                                </span> */}
                            </div>
                        </td>
                        {/* <td className={'text-end'}>
                            <div className='d-flex flex-column px-0'>
                                <span className={cx([globalClasses.FixedWidth150,classes.Amount])}>
                                    {getDollarFromNumber((TOPUP_UNIT_AMOUNT * value).toFixed(2))}
                                </span>
                                {
                                    !isEmpty(value) &&
                                    <span className={cx(['align-self-end',globalClasses.Font12Grey])}>
                                        ( {value} x {TOPUP_UNIT_AMOUNT} )
                                    </span>
                                }
                            </div>
                        </td> */}
                    </tr>
                    {/* {totalComponent} */}
                </table>
            </div>
            {/* <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row'>
                <span className='d-inline-flex'>{MIN_TOPUP_AMOUNT} + {TOPUP_UNIT_AMOUNT} x </span>
                <div className='d-inline-flex mx-0 mx-md-2 my-2 my-md-0'>
                    <button onClick={onDecreaseHandler} className={cx([classes.IncreaseDecreaseButton,classes.Left])} disabled={Number(value) <= minValue}> - </button>
                    <input 
                        className={cx(["form-control",globalClasses.FormInput,classes.TopupInput])}
                        type={'number'}
                        id={id}
                        value={value}
                        placeholder={placeHolder}
                        disabled={isDisabled}
                        readOnly = {isReadOnly}
                        onChange={(event) => onChangeText(event.target.value)}
                        min={minValue}
                        max={maxValue}
                    />
                    <button onClick={onIncreaseHandler} className={cx([classes.IncreaseDecreaseButton,classes.Right])} disabled={Number(value) >= maxValue}> + </button>
                </div>
                <span className='d-inline-flex'> = {getDollarFromNumber(MIN_TOPUP_AMOUNT + TOPUP_UNIT_AMOUNT * Number(value))} = {getWesternNumber((MIN_TOPUP_AMOUNT + TOPUP_UNIT_AMOUNT * Number(value)) * CREDIT_PER_USD)} credits</span>
            </div> */}
            <div className='d-flex flex-column'>
                <span className={globalClasses.ErrorText}>
                    {errorText}
                </span>
            </div>
        </div>
    )
}

export default TopupField;