import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import classes from './NavBar.module.css';
import globalClasses from '../../App.module.css';
import { useHistory } from "react-router-dom";

import ProfileContent from "../../containers/sign_up/ProfileContent";
// import LOGO from '../../assets/images/logos/vivas-ai-logo-beta.png';
import LOGO from '../../assets/images/logos/vivasai-logo-light.png';
import { ROUTE_CONSTANTS } from '../../routes/RouteConstants';
import { NAVBAR_LABELS } from '../../strings/NavBar';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import Loader from '../loader/Loader';
import Button from '../ui_elements/button/ButtonNew'

import { MdClose, MdMenu } from 'react-icons/md';
import { BUTTON_TEXTS, SUBSCRIPTION_REQUIRED } from '../../strings/Strings';
import { USER_ROLE, TASK_STATUS } from '../../utils/Constants';
import { getDatePublishFormat, isEmpty, isEmptyObject } from '../../utils/UtilityFunctions';
import ConfirmPopup from '../confirm_popup/ConfirmPopup';
import Badge from '../badge_count/Badge'
import axios from '../../axios/Axios'
import UserPool from '../../containers/sign_up/UserPool';
import { getCognitoUserAccessToken } from '../../containers/sign_up/Cognito.utils';
import DropNavItem from './DropNavItem';
import { DROP_NAV_LINKS } from './NavBar.utils';
import { BUTTON_TYPES } from '../../utils/ButtonConstants';

const navBarStyle = cx([classes.NavBar, "navbar navbar-expand sticky-top px-0 px-md-3 py-1"])

function NavBar(props) {
    const isAuthenticated = !isEmpty(UserPool.getCurrentUser())
    const { isSubscribedUser, userDetails, accountDetail} = props
    let accountDetails = { ...accountDetail }

    if (isAuthenticated && isEmptyObject(accountDetails)) {
        if(!isEmptyObject(props.data)){
            accountDetails = (!isEmptyObject(props.data.accountDetail) && props.data.accountDetail.length > 0) ? props.data.accountDetail[0] : {};
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const [showDrop, setShowDrop] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [isNotificationLoading, setIsNotificationLoading] = useState(true);
    let profileRef = useRef();

    let loadingComponent = null;
    let classStyle = null;
    let homepageLink = null;
    let pricingLink = null;
    let industryLink = null;
    let developersLink = null;
    let tasksLink = null;
    let tryForFreeLink = null;
    let playgroundLink = null;
    let showcaseLink = null;
    let menuComponent = null;
    let menuIconComponent = null;
    let userManagementLink = null;
    let helpLink = null;
    let partnersLink=null;
    let servicesLink=null;
    let badgeComponent = null;

    let resourcesDropMenu = null;
    let productsDropMenu = null;
    let aboutsDropMenu = null;
    let servicesDropMenu = null;

    loadingComponent = <Loader containerClass={classes.LoadingContainer} />

    useEffect(() => {
        function handleClickOutside(event) {
            if (profileRef.current && !profileRef.current.contains(event.target)
                && showDrop) {
                setShowDrop(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileRef, showDrop]);

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         axios.get('/api/notification', {
    //             headers:{"Authorization" : "Bearer "+getCognitoUserAccessToken()},
    //             params: {
    //                 loggedinUserId: userDetails?.email
    //             }
    //         }).then((response) => {
    //             setIsNotificationLoading(false);
    //             setTaskList(response.data);
    //         }).catch((error) => {
    //             setIsNotificationLoading(false);
    //         })
    //     }
    //     else{
    //         setIsNotificationLoading(false);
    //     }

    // }, [isAuthenticated,userDetails]);

    const Redirect = (item) => {
        let taskId = JSON.parse(item.NotificationData).taskId;
        axios.put('/api/notification', {
            data: {
                loggedinUserId: userDetails.email,
                notificationId: item.Id
            }
        },{
            headers:{"Authorization" : "Bearer "+getCognitoUserAccessToken()},
        }).then((response) => {
            if (props.history.location.pathname !== ROUTE_CONSTANTS.TASK)
                props.history.push(ROUTE_CONSTANTS.TASK + "?taskid=" + taskId);
            else {
                const currentURL = window.location.href.split('?')[0];
                window.open(currentURL + "?taskid=" + taskId, "_self");
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }

    const updateAllAsRead = () => {
        // to be implemented
    }

    const unreadNotificationContent = () => {

        return <div>
            {
                taskList.length ? taskList.map(item => {
                    if (JSON.parse(item.NotificationData)) {
                        let notificationdata = JSON.parse(item.NotificationData);
                        let taskstate = notificationdata.status === TASK_STATUS.COMPLETED ? 'Task Completed!'
                            : 'Task Failed!'

                        let singleNotification = (
                            <div onClick={() => Redirect(item)} className={classes.SingleNotificationContainer} >
                                <span className='mb-1' style={{ fontSize: "14px", fontFamily:"GoogleSans", fontWeight:"600", color: "var(--color-grey)" }}>{taskstate}</span>
                                <span style={{color:"var(--color-grey44)"}}>The task ID <label style={{fontFamily:"Roboto-Medium"}}>{notificationdata.taskId}</label> created for {notificationdata.modelName} {" (" + notificationdata.modelCode + ")"} has been {notificationdata?.status ? notificationdata.status.toLowerCase() : notificationdata?.status}.</span>
                                <span className='mt-2 align-self-end' style={{color:"var(--color-blue-grey)"}}>{getDatePublishFormat(notificationdata.taskCreatedOn)}</span>
                            </div>
                        )

                        return singleNotification
                    }
                }) :

                    <div className={"d-flex justify-content-center align-items-center"}>
                        <span className={classes.FooterText}>No Notifications Found!</span>
                    </div>
            }
        </div>
    }

    const onTryForFreeClicked = () => {
        setIsLoading(true)
        setTimeout(() => {
            props.history.push(ROUTE_CONSTANTS.TRY_FOR_FREE)
        }, 1000);
    }

    const checkRoleAndOpenpopup = () => {
        if (isAuthenticated && !isSubscribedUser) {
            setShowPopup(true)
            if (showMenu) {
                setShowMenu(false);
            }
        }
    }

    const onClosePopup = () => {
        if (showPopup) {
            if (document.body.style.overflow !== 'auto') {
                document.body.style.overflow = 'auto'
            }
            setShowPopup(false)
        }
    }

    const onClickSubscribe = () => {
        if (props.history.location.pathname !== ROUTE_CONSTANTS.PRICING) {
            if (document.body.style.overflow !== 'auto') {
                document.body.style.overflow = 'auto'
            }
            props.history.push(ROUTE_CONSTANTS.PRICING)
        } else {
            onClosePopup()
        }
    }

    const onClickNavItem = () => {
        if (showMenu) {
            if (!showMenu) {
                if (document.body.style.overflow !== 'hidden') {
                    window.scrollTo(0, 0)
                    document.body.style.overflow = 'hidden'
                }
            } else {
                if (document.body.style.overflow !== 'auto') {
                    document.body.style.overflow = 'auto'
                }
            }
            setShowMenu(!showMenu)
        }
    }

    const onClickDrop = () => {
        setShowDrop(!showDrop);
        if (showMenu) {
            if (!showMenu) {
                if (document.body.style.overflow !== 'hidden') {
                    window.scrollTo(0, 0)
                    document.body.style.overflow = 'hidden'
                }
            } else {
                if (document.body.style.overflow !== 'auto') {
                    document.body.style.overflow = 'auto'
                }
            }
            setShowMenu(!showMenu);
        }
    }
    
    const onClickMenu = () => {
        if (!showMenu) {
            if (document.body.style.overflow !== 'hidden') {
                window.scrollTo(0, 0)
                document.body.style.overflow = 'hidden'
            }
        } else {
            if (document.body.style.overflow !== 'auto') {
                document.body.style.overflow = 'auto'
            }
        }
        setShowMenu(!showMenu);
    }

    homepageLink = (
        <NavLink
            to={ROUTE_CONSTANTS.HOME_PAGE}
            className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
            isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.HOME_PAGE}
            activeClassName={globalClasses.Font16PrimaryBlue}
            rel="noopener noreferrer"
            onClick={onClickNavItem}
        >
            {NAVBAR_LABELS.HOME}
        </NavLink>
    )
    if (!isSubscribedUser) {

        pricingLink = (
            <NavLink
                to={ROUTE_CONSTANTS.PRICING}
                className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
                isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.PRICING}
                activeClassName={globalClasses.Font16PrimaryBlue}
                rel="noopener noreferrer"
                onClick={onClickNavItem}
            >
                {NAVBAR_LABELS.PRICING}
            </NavLink>
        )

        industryLink = (
            <NavLink
                to={ROUTE_CONSTANTS.INDUSTRY}
                className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
                isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.INDUSTRY}
                activeClassName={globalClasses.Font16PrimaryBlue}
                rel="noopener noreferrer"
                onClick={onClickNavItem}
            >
                {NAVBAR_LABELS.INDUSTRY}
            </NavLink>
        )

        // partnersLink = (
        //     <NavLink
        //         to={ROUTE_CONSTANTS.PARTNER}
        //         className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
        //         isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.PARTNER}
        //         activeClassName={globalClasses.Font16PrimaryBlue}
        //         rel="noopener noreferrer"
        //         onClick={onClickNavItem}
        //     >
        //         {NAVBAR_LABELS.PARTNERS}
        //     </NavLink>
        // )

        servicesLink = (
            <NavLink
                to={ROUTE_CONSTANTS.SERVICES}
                className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
                isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.SERVICES}
                activeClassName={globalClasses.Font16PrimaryBlue}
                rel="noopener noreferrer"
                onClick={onClickNavItem}
            >
                {NAVBAR_LABELS.SERVICES}
            </NavLink>
        )

        
        resourcesDropMenu = (
            <div className={"me-0 me-md-4 me-lg-5 py-1 py-md-0"}>
                <DropNavItem
                    label = {DROP_NAV_LINKS.RESOURCES.LABEL}
                    dropItems = {DROP_NAV_LINKS.RESOURCES.ITEMS}
                />
            </div>
        )
        
        productsDropMenu = (
            <div className={"me-0 me-md-4 me-lg-5 py-1 py-md-0"}>
                <DropNavItem
                    label = {DROP_NAV_LINKS.PRODUCT.LABEL}
                    dropItems = {DROP_NAV_LINKS.PRODUCT.ITEMS}
                />
            </div>
        )

        aboutsDropMenu = (
            <div className={"me-0 me-md-4 me-lg-5 py-1 py-md-0"}>
                <DropNavItem
                    label = {DROP_NAV_LINKS.ABOUT.LABEL}
                    dropItems = {DROP_NAV_LINKS.ABOUT.ITEMS}
                />
            </div>
        )

        servicesDropMenu = (
            <div className={"me-0 me-md-4 me-lg-5 py-1 py-md-0"}>
                <DropNavItem
                    label = {DROP_NAV_LINKS.SERVICES.LABEL}
                    dropItems = {DROP_NAV_LINKS.SERVICES.ITEMS}
                />
            </div>
        )
    }

    if (isAuthenticated) {
        helpLink = <NavLink
            to={ROUTE_CONSTANTS.HELP}
            className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
            isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.HELP}
            activeClassName={globalClasses.Font16PrimaryBlue}
            rel="noopener noreferrer"
            onClick={onClickNavItem}
        >
            {NAVBAR_LABELS.HELP}
        </NavLink>

        developersLink =
            <a
                href={process.env.REACT_APP_API_DOCUMENTATION_URL}
                className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
                target="_blank"
                rel="noopener noreferrer"
            >
                {NAVBAR_LABELS.DOCUMENTATION}
            </a>

        if (isSubscribedUser) {
            classStyle = 'ml-auto'

            playgroundLink =
                <NavLink
                    to={ROUTE_CONSTANTS.STUDIO_HOME}
                    className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
                    isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.STUDIO_HOME}
                    activeClassName={globalClasses.Font16PrimaryBlue}
                    rel="noopener noreferrer"
                    onClick={onClickNavItem}
                >
                    {NAVBAR_LABELS.DEV_STUDIO}
                </NavLink>

            userManagementLink = <NavLink
                to={USER_ROLE.SUPER_ADMIN.LABEL === userDetails.role ? ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT : ROUTE_CONSTANTS.USER_MANAGEMENT}
                className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
                isActive={(match, location) => (location.pathname === ROUTE_CONSTANTS.USER_MANAGEMENT || location.pathname === ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT)}
                activeClassName={globalClasses.Font16PrimaryBlue}
                rel="noopener noreferrer"
                onClick={onClickNavItem}
            >
                {NAVBAR_LABELS.MANAGE}
            </NavLink>

            // tasksLink = <NavLink
            //     to={ROUTE_CONSTANTS.TASK}
            //     className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
            //     isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.TASK}
            //     activeClassName={globalClasses.Font16PrimaryBlue}
            //     rel="noopener noreferrer"
            //     onClick={onClickNavItem}
            // >
            //     {NAVBAR_LABELS.TASK}
            // </NavLink>

        } 
        // else {
        //     playgroundLink = (
        //         <NavLink
        //             to={ROUTE_CONSTANTS.MODELS}
        //             className={cx(['me-0 me-md-4 me-lg-5 py-1 py-md-0', classes.NavItem])}
        //             isActive={(match, location) => location.pathname === ROUTE_CONSTANTS.MODELS}
        //             activeClassName={globalClasses.Font16PrimaryBlue}
        //             rel="noopener noreferrer"
        //             onClick={onClickNavItem}
        //         >
        //             {NAVBAR_LABELS.MODELS}
        //         </NavLink>
        //     )
        // }

    }
    else {
        tryForFreeLink = (
            <div className='me-3'>
                <Button
                    className={cx(['py-2 px-4'])}
                    onClickHandler={onTryForFreeClicked}
                    label={BUTTON_TEXTS.TRY_FOR_FREE}
                    actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                    radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                    borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                />
            </div>
        )
    }


    if (!showMenu) {
        menuIconComponent = (
            <MdMenu
                size={32}
                color="var(--color-primary)"
                className={'d-block d-lg-none mt-1 mx-2'}
                onClick={onClickMenu}
            />
        )
    } else {
        menuIconComponent = (
            <MdClose
                size={32}
                color="var(--color-primary)"
                className={'d-block d-lg-none mt-1 mx-2'}
                onClick={onClickMenu}
            />
        )
    }
    menuComponent = (
        <div>
            {menuIconComponent}
            {
                showMenu &&
                <div
                    className={cx(['d-flex d-lg-none', classes.MenuItemsContainer])}
                >
                    {/* <li className={'py-2'}>
                        {homepageLink}
                    </li> */}
                    
                    {
                        productsDropMenu &&
                        <li className={'py-2 col-12'}>
                            {productsDropMenu}
                        </li>
                    }
                    {
                        servicesDropMenu &&
                        <li className={'py-2'}>
                            {servicesDropMenu}
                        </li>
                    }
                    {
                        playgroundLink &&
                        <li className={'py-2'}>
                            {playgroundLink}
                        </li>
                    }
                    {
                        resourcesDropMenu &&
                        <li className={'py-2 col-12'}>
                            {resourcesDropMenu}
                        </li>
                    }
                    {
                        developersLink &&
                        <li className={'py-2 col-12'}>
                            {developersLink}
                        </li>
                    }
                    {/* {
                        tasksLink &&
                        <li className={'py-2 col-12'}>
                            {tasksLink}
                        </li>
                    } */}
                    {
                        pricingLink &&
                        <li className={'py-2'}>
                            {pricingLink}
                        </li>
                    }
                    {/* {
                        partnersLink &&
                        <li className={'py-2'}>
                            {partnersLink}
                        </li>
                    } */}
                    {
                        userManagementLink &&
                        <li className={'py-2'}>
                            {userManagementLink}
                        </li>
                    }
                    {
                        aboutsDropMenu &&
                        <li className={'py-2 col-12 mx-auto'}>
                            {aboutsDropMenu}
                        </li>
                    }
                    {
                        tryForFreeLink &&
                        <li className={'py-2'}>
                            {tryForFreeLink}
                        </li>
                    }
                </div>
            }
        </div>
    )
    console.log("navbar", props)
    return (
        <nav className={navBarStyle}>
            <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <a href={ROUTE_CONSTANTS.HOME} target='_blank' rel={"noopener noreferrer"} className="mb-0">
                            <div className={cx(['mx-4 mx-lg-5', classes.LogoContainer])}>
                                <img width='100%' height='100%' src={LOGO} alt="VivasAI Logo" className={classes.Logo} />
                            </div>
                        </a>
                    </li>

                </ul>

                <ul className="navbar-nav ms-auto">
                    {/* <li className="nav-item my-auto d-md-block d-none" >
                        {homepageLink}
                    </li> */}

                    <li className="nav-item my-auto d-lg-block d-none" >
                        {showcaseLink}
                    </li>
                    <li className="nav-item my-auto d-lg-block d-none" >
                        {productsDropMenu}
                    </li>
                    <li className="nav-item my-auto d-lg-block d-none" >
                        {servicesDropMenu}
                    </li>
                    <li className="nav-item my-auto d-lg-block d-none" >
                        {playgroundLink}
                    </li>
                    <li className="nav-item my-auto d-lg-block d-none">
                        {developersLink}
                    </li>
                    {/* <li className="nav-item my-auto d-lg-block d-none">
                        {tasksLink}
                    </li> */}
                    <li className="nav-item my-auto d-lg-block d-none" >
                        {resourcesDropMenu}
                    </li>
                    <li className="nav-item my-auto d-lg-block d-none" >
                        {pricingLink}
                    </li>
                    {/* <li className="nav-item my-auto d-md-block d-none" >
                        {industryLink}
                    </li> */}
                    {/* <li className="nav-item my-auto d-lg-block d-none" >
                        {partnersLink}
                    </li> */}
                    <li className="nav-item my-auto d-lg-block d-none" >
                        {userManagementLink}
                    </li>
                    <li className="nav-item my-auto d-lg-block d-none" >
                        {aboutsDropMenu}
                    </li>
                    {isAuthenticated ?
                        <>
                            {/* <Badge count={isNotificationLoading ? '...' : taskList.length} bodyContent={unreadNotificationContent()}></Badge> */}
                            <ProfileContent
                                className={classStyle}
                                showDrop={showDrop}
                                accountDetail={accountDetails}
                                refer={profileRef}
                                onClickHandler={onClickDrop}
                                userDetails={userDetails}
                                isSubscribed={isSubscribedUser}
                                history={props.history}
                            />
                            {menuComponent}
                        </>
                        :
                        <>
                            <div className='d-block'
                                onClick={() => {
                                    console.log("props nav", props)
                                    props.history.push({ pathname: ROUTE_CONSTANTS.LOGIN, state: { from: props.history.location.pathname } })
                                }}>
                                <div className='me-3'>
                                    <Button
                                        className={cx(['py-2 px-4'])}
                                        label={BUTTON_TEXTS.SIGN_IN}
                                        actionType={BUTTON_TYPES.ACTIONS.TERTIARY} 
                                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                                        borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                                    />
                                </div>
                            </div>

                            <div className='d-none d-lg-block'>
                                {tryForFreeLink}
                            </div>
                            {menuComponent}
                        </>

                    }
                </ul>
                {isLoading && loadingComponent}
                {showPopup &&
                    (
                        <ConfirmPopup
                            popupClass={globalClasses.PopupBack}
                            modalClass={'col-11 col-md-8 col-lg-6 ps-3 ps-md-4'}
                            title={SUBSCRIPTION_REQUIRED.TITLE}
                            subtext={SUBSCRIPTION_REQUIRED.SUB_TEXT_API}
                            primaryButtonText={BUTTON_TEXTS.SUBSCRIBE}
                            secondaryButtonText={BUTTON_TEXTS.CANCEL}
                            onClickPrimaryButton={onClickSubscribe}
                            onClickSecondaryButton={onClosePopup}
                            isPopupFixed={true}
                            enableScroll={false}
                            onCloseModalCard={onClosePopup}
                        />
                    )
                }
            </div>
        </nav >
    )
}

export default NavBar;