import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PlayGroundLayout from '../hoc/layout/PlayGroundLayout';
// import LeafIdpLayout from '../hoc/layout/LeafIDPLayout';
import HomeLayout from '../hoc/layout/HomeLayout';
import NoFooterLayout from '../hoc/layout/NoFooterLayout';
// import ModelLandingPage from '../containers/home/ModelLandingPage';
// import Pricing from '../containers/pricing/Pricing'
import { ROUTE_CONSTANTS } from './RouteConstants';
import Loader from '../components/loader/Loader';
import PageNotFound from '../components/page_not_found/PageNotFound';
import { history } from '../App';
import { EMPTY_STRING, USER_ROLE, LEAF_PRODUCTS } from "../utils/Constants"
import AccountManagement from '../containers/user_management/account_management/AccountManagement';
import { getAccountSubscriptions, isEmpty, isEmptyList, isEmptyObject } from '../utils/UtilityFunctions';
import Login from '../containers/sign_up/Login';
import ResetPassword from '../containers/sign_up/ResetPassword';
import AuthenticationLayout from '../hoc/layout/AuthenticationLayout';


// 
const TryForFree = React.lazy(() => import('../containers/sign_up/TryForFree'))
const HomePage = React.lazy(() => import('../containers/home_page/HomePage'))
const ModelLandingPage = React.lazy(() => import('../containers/model_landing_page/ModelLandingPage'))
const StudioLandingPage = React.lazy(() => import('../containers/product_home_card/ProductHomeCard'))
const LeafIDPStudioPage = React.lazy(() => import('../containers/leaf-studios/LeafIdpStudio'))
const LeafIDPBrowsePage = React.lazy(() => import('../containers/leaf_idp_model_page/IdpModelLandingPage'))
const Pricing = React.lazy(() => import('../containers/pricing/Pricing'))
const IDPPricing = React.lazy(() => import('../containers/pricing/IDPPricing'))
const Help = React.lazy(() => import('../containers/help/Help'))
const Faq = React.lazy(() => import('../containers/help/Faq'))
const ModelDetails = React.lazy(() => import('../containers/model_details/ModelDetails'))
const LLMModelDetails = React.lazy(() => import('../containers/model_details/ModelDetailsLLM'))
const UserManagement = React.lazy(() => import('../containers/user_management/UserManagement'))
const RequestDemo = React.lazy(() => import('../containers/reqest_demo/RequestDemo'))
const ContactUs = React.lazy(() => import('../containers/contact_us/ContactUs'))
const AboutUs = React.lazy(() => import('../containers/about_us/AboutUs'));
const Blog = React.lazy(() => import('../containers/blogs/Blogs'));
const SingleBlog = React.lazy(() => import('../containers/blogs/SingleBlog'));
const UseCase = React.lazy(() => import('../containers/usecase/UseCase'));
const SingleUseCase = React.lazy(() => import('../containers/usecase/SingleUseCase'));
const Partner = React.lazy(() => import('../containers/partner/Partner'));
const PartnerForm = React.lazy(()=> import('../containers/partner_form/PartnerForm'))
const Services = React.lazy(()=> import('../containers/services/Services'))
const Career = React.lazy(() => import('../containers/careers/Careers'));
const WhitePapers = React.lazy(() => import('../containers/whitepapers/WhitePapers'));
const Announements = React.lazy(() => import('../containers/announcements/Announcements'));
// const SubscriptionDetail = React.lazy(() => import('../containers/subscription_detail/SubscriptionDetail'));
const PrivacyPolicy = React.lazy(() => import('../containers/agreement/PrivacyPolicy'));
const TermsConditions = React.lazy(() => import('../containers/agreement/TermsConditions'))
const CancellationAndRefundPolicy = React.lazy(() => import('../containers/agreement/CancellationAndRefundPolicy'))
const EULAComponent = React.lazy(() => import('../containers/agreement/EULA'));
const PaymentComponent = React.lazy(() => import('../containers/payment_confirmation/PaymentConfirmation'));
const HttpStatusCode = React.lazy(() => import('../containers/http_status_codes/HttpStatusCode'))
const TaskComponent = React.lazy(() => import('../containers/async_tasks/AsyncTasks'))
const MyProfile = React.lazy(() => import('../containers/sign_up/MyProfile'))
const Discount = React.lazy(() => import('../containers/discount/Discount'))
const PublishModel = React.lazy(() => import('../containers/publish_skill_modal/PublishSkillModal'))
const PublishIDPModel = React.lazy(() => import('../containers/publish_skill_idp_model/PublishSkillModalIDP'))
const Industries = React.lazy(() => import('../containers/resources/industries/Industries'))
const SingleIndustry = React.lazy(() => import('../containers/resources/industries/SingleIndustry'))
const ProductGptFlow = React.lazy(() => import('../containers/products/ProductGptFlow'))
const ProductDocQSphere = React.lazy(() => import('../containers/products/ProductDocQSphere'))
const ProductDevCopilot = React.lazy(() => import('../containers/products/ProductDevCopilot'))
const ProductLeafIdp = React.lazy(() => import('../containers/products/LeafIDP/LeafIdp'))


const REDIRECT_ROUTES = Object.freeze([
    {
        old_path : ROUTE_CONSTANTS.PRODUCT_DOCQSPHERE_OLD,
        new_path : ROUTE_CONSTANTS.PRODUCT_DOCQSPHERE
    },
    {
        old_path : ROUTE_CONSTANTS.PRODUCT_GPT_FLOW_OLD,
        new_path : ROUTE_CONSTANTS.PRODUCT_GPT_FLOW
    },
    {
        old_path : ROUTE_CONSTANTS.PRODUCT_DEV_COPILOT_OLD,
        new_path : ROUTE_CONSTANTS.PRODUCT_DEV_COPILOT
    }
])

const REDIRECT_USER_ROUTES = Object.freeze([
    {
        check_path : ROUTE_CONSTANTS.STUDIO_HOME,
        to_path : ROUTE_CONSTANTS.HOME
    },
    // {
    //     check_path: ROUTE_CONSTANTS.LLM_MODEL_DETAIL,
    //     to_path : ROUTE_CONSTANTS.HOME,
    //     check : /^\/llm-model-detail/
    // },
    // {
    //     check_path: ROUTE_CONSTANTS.MODEL_DETAIL,
    //     to_path : ROUTE_CONSTANTS.HOME,
    //     check : /^\/model-detail/
    // },
    // {
    //     check_path : ROUTE_CONSTANTS.DEV_STUDIO,
    //     to_path : ROUTE_CONSTANTS.HOME
    // },
    {
        check_path : ROUTE_CONSTANTS.PUBLISH_MODEL,
        to_path : ROUTE_CONSTANTS.HOME
    },
    {
        check_path : ROUTE_CONSTANTS.USER_MANAGEMENT,
        to_path : ROUTE_CONSTANTS.HOME
    },
    {
        check_path : ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT,
        to_path : ROUTE_CONSTANTS.HOME
    },
    {
        check_path : ROUTE_CONSTANTS.LEAF_IDP.FAVORITEMODEL,
        to_path : ROUTE_CONSTANTS.LEAF_IDP
    },
    {
        check_path : ROUTE_CONSTANTS.LEAF_IDP.STUDIO,
        to_path : ROUTE_CONSTANTS.PRODUCT_LEAF_IDP
    },
    {
        check_path: ROUTE_CONSTANTS.LEAF_IDP.MODEL_DETAILS,
        to_path : ROUTE_CONSTANTS.PRODUCT_LEAF_IDP,
        check : /^\/leaf-idp-studio\/model-details/
    },
    {
        check_path : ROUTE_CONSTANTS.LEAF_ESSENTIALS.STUDIO,
        to_path : ROUTE_CONSTANTS.PRODUCT_GPT_FLOW
    },
    {
        check_path: ROUTE_CONSTANTS.LEAF_ESSENTIALS.MODEL_DETAILS,
        to_path : ROUTE_CONSTANTS.PRODUCT_GPT_FLOW,
        check : /^\/leaf-essentials-studio\/model-details/
    },
    {
        check_path: ROUTE_CONSTANTS.LEAF_ESSENTIALS.SEED_MODEL_DETAILS,
        to_path : ROUTE_CONSTANTS.PRODUCT_GPT_FLOW,
        check : /^\/leaf-essentials-studio\/seed-model-details/
    },
    {
        check_path : ROUTE_CONSTANTS.LEAF_CONVERSE.STUDIO,
        to_path : ROUTE_CONSTANTS.PRODUCT_DOCQSPHERE
    },
    {
        check_path: ROUTE_CONSTANTS.LEAF_CONVERSE.MODEL_DETAILS,
        to_path : ROUTE_CONSTANTS.PRODUCT_DOCQSPHERE,
        check : /^\/leaf-converse-studio\/model-details/
    },
    {
        check_path: ROUTE_CONSTANTS.EULA,
        to_path : ROUTE_CONSTANTS.HOME
    },
    {
        check_path: ROUTE_CONSTANTS.MY_PROFILE,
        to_path : ROUTE_CONSTANTS.HOME
    },
    {
        check_path: ROUTE_CONSTANTS.DISCOUNT,
        to_path : ROUTE_CONSTANTS.HOME
    }
])

const BROKEN_ROUTES = Object.freeze([
    {
        path: "/*",
        exact: true,
        layout: HomeLayout
    }
])

const COMMON_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.HOME_PAGE,
        exact: true,
        component: HomePage,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.PRODUCT_GPT_FLOW,
        exact: true,
        component: ProductGptFlow,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.PRODUCT_DOCQSPHERE,
        exact: true,
        component: ProductDocQSphere,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.PRODUCT_DEV_COPILOT,
        exact: true,
        component: Services,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.PRODUCT_LEAF_IDP,
        exact: true,
        component: ProductLeafIdp,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.PRICING,
        exact: true,
        component: IDPPricing,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.CONTACT_US,
        exact: true,
        component: ContactUs,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.BLOG,
        exact: true,
        component: Blog,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.BLOG+"/:blogID",
        exact: true,
        component: SingleBlog,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.USECASE,
        exact: true,
        component: UseCase,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.USECASE+"/:usecaseID",
        exact: true,
        component: SingleUseCase,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.PARTNER,
        exact: true,
        component: Partner,
        layout: HomeLayout,
    },
    // {
    //     path: ROUTE_CONSTANTS.SERVICES,
    //     exact: true,
    //     component: Services,
    //     layout: HomeLayout,
    // },
    {
        path: ROUTE_CONSTANTS.INDUSTRY,
        exact: true,
        component: Industries,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.INDUSTRY+"/:industryID",
        exact: true,
        component: SingleIndustry,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.BECOME_A_PARTNER,
        exact: true,
        component: PartnerForm,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.WHITE_PAPER,
        exact: true,
        component: WhitePapers,
        layout: HomeLayout,
    },
    // {
    //     path: ROUTE_CONSTANTS.ANNOUNCEMENTS,
    //     exact: true,
    //     component: Announements,
    //     layout: HomeLayout,
    // },
    // {
    //     path: ROUTE_CONSTANTS.PLAYBOOK,
    //     exact: true,
    //     component: PageNotFound,
    //     layout: Layout,
    // }
    // , 
    // {
    //     path: ROUTE_CONSTANTS.CASE_STUDY,
    //     exact: true,
    //     component: PageNotFound,
    //     layout: Layout,
    // },
    {
        path: ROUTE_CONSTANTS.ABOUT_VIVASAI,
        exact: true,
        component: AboutUs,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.CAREERS,
        exact: true,
        component: Career,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.PRIVACY_POLICY,
        exact: true,
        component: PrivacyPolicy,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.TERMS,
        exact: true,
        component: TermsConditions,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.REFUND_POLICY,
        exact: true,
        component: CancellationAndRefundPolicy,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.PAYMENT_CONFIRMATION,
        exact: true,
        component: PaymentComponent,
        layout: HomeLayout,
    }
]);

const UNSUBSCRIBED_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.HELP,
        exact: true,
        component: Help,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.FAQ,
        exact: true,
        component: Faq,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.REQ_A_DEMO,
        exact: true,
        component: RequestDemo,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.SUBCRIBE,
        exact: true,
        component: IDPPricing,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.MY_PROFILE,
        exact: true,
        component: MyProfile,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.DISCOUNT,
        exact: true,
        component: Discount,
        layout: HomeLayout,
    }
])

const SUBSCRIBED_ROUTES = Object.freeze([
    // {
    //     path: ROUTE_CONSTANTS.TASK,
    //     exact: true,
    //     component: TaskComponent,
    //     layout: NoFooterLayout,
    // },
    {
        path: ROUTE_CONSTANTS.STUDIO_HOME,
        exact: true,
        component: StudioLandingPage,
        layout: NoFooterLayout
    },
    // {
    //     path: ROUTE_CONSTANTS.MODEL_DETAIL+"/:modelCode",
    //     exact: false,
    //     component: ModelDetails,
    //     layout: NoFooterLayout,
    // },
    // {
    //     path: ROUTE_CONSTANTS.LLM_MODEL_DETAIL+"/:modelCode/:skillID?",
    //     exact: false,
    //     component: LLMModelDetails,
    //     layout: NoFooterLayout,
    // },
    {
        path: ROUTE_CONSTANTS.FAQ,
        exact: true,
        component: Faq,
        layout: HomeLayout
    },
    {
        path: ROUTE_CONSTANTS.USER_MANAGEMENT+"/:selectedTab?",
        exact: true,
        component: UserManagement,
        layout: NoFooterLayout,
    },
    // {
    //     path: ROUTE_CONSTANTS.DEV_STUDIO,
    //     exact: true,
    //     component: ModelLandingPage,
    //     layout: PlayGroundLayout
    // },
    {
        path: ROUTE_CONSTANTS.EULA,
        exact: true,
        component: EULAComponent,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.MY_PROFILE,
        exact: true,
        component: MyProfile,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.DISCOUNT,
        exact: true,
        component: Discount,
        layout: HomeLayout,
    }
])

const SUBCRIBED_ADMIN_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.LEAF_IDP.BUILDOWNMODEL,
        exact: true,
        component: PublishIDPModel,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_IDP
    },
    {
        path: ROUTE_CONSTANTS.LEAF_CONVERSE.PUBLISH_MODEL,
        exact: true,
        component: PublishModel,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_CONVERSE
    },
    {
        path: ROUTE_CONSTANTS.LEAF_ESSENTIALS.PUBLISH_MODEL,
        exact: true,
        component: PublishModel,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_ESSENTIALS
    },
    ...SUBSCRIBED_ROUTES
])

const LEAF_IDP_STUDIO_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.LEAF_IDP.EXECUTE_MODEL,
        exact: true,
        component: PublishIDPModel,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_IDP
    },
    {
        path: ROUTE_CONSTANTS.LEAF_IDP.MODEL_DETAILS+"/:modelCode/:skillID?",
        exact: true,
        component: LLMModelDetails,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_IDP
    },
    {
        path: ROUTE_CONSTANTS.LEAF_IDP.STUDIO,
        exact: true,
        component: LeafIDPStudioPage,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_IDP
    },
    {
        path: ROUTE_CONSTANTS.LEAF_IDP.BROWSE_MODELS,
        exact: true,
        component: LeafIDPBrowsePage,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_IDP
    }
])

const LEAF_CONVERSE_STUDIO_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.LEAF_CONVERSE.STUDIO,
        exact: true,
        component: ModelLandingPage,
        layout: PlayGroundLayout,
        product : LEAF_PRODUCTS.LEAF_CONVERSE
    },
    {
        path: ROUTE_CONSTANTS.LEAF_CONVERSE.MODEL_DETAILS+"/:modelCode/:skillID?",
        exact: true,
        component: LLMModelDetails,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_CONVERSE
    }
])

const LEAF_ESSENTIALS_STUDIO_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.LEAF_ESSENTIALS.STUDIO,
        exact: true,
        component: ModelLandingPage,
        layout: PlayGroundLayout,
        product : LEAF_PRODUCTS.LEAF_ESSENTIALS
    },
    {
        path: ROUTE_CONSTANTS.LEAF_ESSENTIALS.MODEL_DETAILS+"/:modelCode/:skillID?",
        exact: true,
        component: LLMModelDetails,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.LEAF_ESSENTIALS
    },
    {
        path: ROUTE_CONSTANTS.LEAF_ESSENTIALS.SEED_MODEL_DETAILS+"/:modelCode",
        exact: true,
        component: LLMModelDetails,
        layout: NoFooterLayout,
        product : LEAF_PRODUCTS.SEED_ESSENTIALS
    }
])

const SUPER_ADMIN_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT,
        exact: true,
        component: AccountManagement,
        layout: NoFooterLayout,
    },
    {
        path: ROUTE_CONSTANTS.ACCOUNT_MANAGEMENT+"/:selectedTab?",
        exact: true,
        component: AccountManagement,
        layout: NoFooterLayout,
    },
    {
        path: ROUTE_CONSTANTS.USER_MANAGEMENT+"/:accountID"+"/:selectedTab?",
        exact: true,
        component: UserManagement,
        layout: NoFooterLayout,
    },
    ...SUBCRIBED_ADMIN_ROUTES,
    ...LEAF_IDP_STUDIO_ROUTES,
    ...LEAF_CONVERSE_STUDIO_ROUTES,
    ...LEAF_ESSENTIALS_STUDIO_ROUTES
])

const ANONYMOUS_ROUTES = Object.freeze([
    {
        path: ROUTE_CONSTANTS.REQ_A_DEMO,
        exact: true,
        component: RequestDemo,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.SUBCRIBE,
        exact: true,
        component: IDPPricing,
        layout: HomeLayout,
    },
    {
        path: ROUTE_CONSTANTS.DISCOUNT,
        exact: true,
        component: Discount,
        layout: HomeLayout,
    }
])


const getAuthenticatedRoutes = (userRole) => {
    // let isLicenseAttached = (Number(isLicense) === 1)
    if (userRole === USER_ROLE.SUBSCRIBED.LABEL || userRole === USER_ROLE.SUBSCRIBED_ADMIN.LABEL) {
        let subscribedRoutes = SUBSCRIBED_ROUTES
        if(userRole === USER_ROLE.SUBSCRIBED_ADMIN.LABEL){
            subscribedRoutes = SUBCRIBED_ADMIN_ROUTES
        }
        return (
            subscribedRoutes
        )

    }
    else if (userRole === USER_ROLE.SUPER_ADMIN.LABEL) {
        let superAdminRoutes = SUPER_ADMIN_ROUTES
        return (
            superAdminRoutes
        )
    }
    else {
        return (
            UNSUBSCRIBED_ROUTES
        )
    }
}

const getProductAccessRoutes = (subscriptions) => {
    let productAccessRoutes = [];
    if(!isEmptyList(subscriptions)){
        if(subscriptions.findIndex((sub) => sub?.productName === LEAF_PRODUCTS.LEAF_CONVERSE) >= 0){
            productAccessRoutes = [ ...productAccessRoutes, ...LEAF_CONVERSE_STUDIO_ROUTES ]
        }
        if(subscriptions.findIndex((sub) => sub?.productName === LEAF_PRODUCTS.LEAF_ESSENTIALS) >= 0){
            productAccessRoutes = [ ...productAccessRoutes, ...LEAF_ESSENTIALS_STUDIO_ROUTES ]
        }
        if(subscriptions.findIndex((sub) => sub?.productName === LEAF_PRODUCTS.LEAF_IDP) >= 0){
            productAccessRoutes = [ ...productAccessRoutes, ...LEAF_IDP_STUDIO_ROUTES ]
        }
    } else {
        productAccessRoutes = [ ...productAccessRoutes, ...LEAF_IDP_STUDIO_ROUTES ]
    }

    return productAccessRoutes
}



const renderRouteBasedComponent = (isAuthenticated, data) => {
    let userProfileDetail = data.user
    let userAccountDetail = isEmptyList(data.accountDetail) ? {} : data.accountDetail[0]
    let userRole = isEmpty(userProfileDetail) ? EMPTY_STRING : userProfileDetail.role
    
    let isInvite = false;

    let isSubscribedUser = userRole === USER_ROLE.SUBSCRIBED.LABEL
    let isSubscribedAdmin = userRole === USER_ROLE.SUBSCRIBED_ADMIN.LABEL
    let isSuperAdmin = userRole === USER_ROLE.SUPER_ADMIN.LABEL
    let roleBasedRoutes = null;
    let prodctBasedRoutes = null;

    if (isAuthenticated) {
        roleBasedRoutes = getAuthenticatedRoutes(userRole)
        prodctBasedRoutes = getProductAccessRoutes(userAccountDetail?.subscriptions)
        console.log("ROUTES", roleBasedRoutes, prodctBasedRoutes)
    } else {
        let params = (new URL(document.location)).searchParams;
        isInvite = params.get("is-invite");
        roleBasedRoutes = ANONYMOUS_ROUTES
    }
    return (
        <>
            <Switch>

                {!isAuthenticated &&
                    <Route
                        path={ROUTE_CONSTANTS.TRY_FOR_FREE}
                        exact={true}
                        render={(props) => (
                            <Suspense fallback={<Loader />}>
                                <AuthenticationLayout>
                                    <TryForFree
                                        // isAuthenticated={isAuthenticated}
                                        // isSubscribedUser={isSubscribedUser}
                                        // isSubscribedAdmin={isSubscribedAdmin}
                                        {...props}
                                    />
                                </AuthenticationLayout>
                            </Suspense>
                        )}
                    />
                }
                {
                    !isAuthenticated &&
                    <Route
                        path={ROUTE_CONSTANTS.LOGIN}
                        exact={true}
                        render={(props) => (
                            <AuthenticationLayout>
                                <Login
                                    {...props}
                                />
                            </AuthenticationLayout>
                        )}
                    />
                }
                {
                    !isAuthenticated &&
                    <Route
                        path={ROUTE_CONSTANTS.RESET_PASSWORD}
                        exact={true}
                        render={(props) => (
                            <AuthenticationLayout>
                                <ResetPassword
                                    {...props}
                                />
                            </AuthenticationLayout>
                        )}
                    />
                }
                {/* authenticated users route */}
                {
                    !isEmptyList(prodctBasedRoutes) && prodctBasedRoutes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => (
                                    <Suspense fallback={<Loader />}>
                                        <route.layout
                                            isAuthenticated={isAuthenticated}
                                            isSubscribedUser={isSubscribedUser || isSubscribedAdmin || isSuperAdmin}
                                            role={userRole}
                                            userDetails={userProfileDetail}
                                            accountDetail={userAccountDetail}
                                            isInvite={isInvite}
                                            data={data}
                                            product={route?.product}
                                            {...props}>
                                            <route.component
                                                accountDetail={userAccountDetail}
                                                isAuthenticated={isAuthenticated}
                                                userDetails={userProfileDetail}
                                                isSubscribedUser={isSubscribedUser || isSubscribedAdmin || isSuperAdmin}
                                                role={userRole}
                                                product={route?.product}
                                                data={data} {...props}
                                            />
                                        </route.layout>
                                    </Suspense>
                                )}
                            />
                        );
                    })
                }
                {
                    roleBasedRoutes && roleBasedRoutes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => (
                                    <Suspense fallback={<Loader />}>
                                        <route.layout
                                            isAuthenticated={isAuthenticated}
                                            isSubscribedUser={isSubscribedUser || isSubscribedAdmin || isSuperAdmin}
                                            role={userRole}
                                            userDetails={userProfileDetail}
                                            accountDetail={userAccountDetail}
                                            isInvite={isInvite}
                                            data={data}
                                            product={route?.product}
                                            {...props}>
                                            <route.component
                                                accountDetail={userAccountDetail}
                                                isAuthenticated={isAuthenticated}
                                                userDetails={userProfileDetail}
                                                isSubscribedUser={isSubscribedUser || isSubscribedAdmin || isSuperAdmin}
                                                role={userRole}
                                                product={route?.product}
                                                data={data} {...props}
                                            />
                                        </route.layout>
                                    </Suspense>
                                )}
                            />
                        );
                    })
                }

                {/* common routes to all users*/}
                {
                    COMMON_ROUTES.map((route, index) => {
                        if(
                            (isSuperAdmin || isSubscribedAdmin || isSubscribedUser)
                            && (route.path === history.location.pathname)
                        ){
                            if(route.path === ROUTE_CONSTANTS.MODEL_PLAYGROUND){
                                return (
                                    <Redirect
                                        from={ROUTE_CONSTANTS.MODEL_PLAYGROUND}
                                        to={ROUTE_CONSTANTS.DEV_STUDIO}
                                    />
                                )
                            }
                            
                        }
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => (
                                    <Suspense fallback={<Loader />}>
                                        <route.layout
                                            isAuthenticated={isAuthenticated}
                                            isSubscribedUser={isSubscribedUser || isSubscribedAdmin || isSuperAdmin}
                                            role={userRole}
                                            userDetails={userProfileDetail}
                                            accountDetail={userAccountDetail}
                                            data={data}
                                            product={route?.product}
                                            {...props}
                                        >
                                            <route.component
                                                isAuthenticated={isAuthenticated}
                                                isSubscribedUser={isSubscribedUser || isSubscribedAdmin || isSuperAdmin}
                                                role={userRole}
                                                userDetails={userProfileDetail}
                                                accountDetail={userAccountDetail}
                                                data={data}
                                                product={route?.product}
                                                {...props}
                                            />
                                        </route.layout>
                                    </Suspense>
                                )}
                            />
                        );
                    })
                }
                {
                    REDIRECT_ROUTES.map((route,index) => {
                        return (
                            <Redirect
                                key = {index}
                                from={route.old_path}
                                to={route.new_path}
                            />
                        )
                    })
                }
                {
                    REDIRECT_USER_ROUTES.map((route,index) => {
                        if(!isEmpty(route?.check)){
                            if(history.location.pathname.match(route?.check)){
                                return (
                                    <Redirect
                                        key={index}
                                        from={history.location.pathname}
                                        to={route.to_path}
                                    />
                                )
                            }
                        }
                        return (
                            (history.location.pathname === route.check_path) &&
                                <Redirect
                                    key={index}
                                    from={route.check_path}
                                    to={route.to_path}
                                />
                        )
                    })
                }
                {
                    BROKEN_ROUTES.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => (
                                    <Suspense fallback={<Loader />}>
                                        <route.layout
                                            isAuthenticated={isAuthenticated}
                                            isSubscribedUser={isSubscribedUser || isSubscribedAdmin || isSuperAdmin}
                                            role={userRole}
                                            userDetails={userProfileDetail}
                                            accountDetail={userAccountDetail}
                                            data={data}
                                            {...props}
                                        >
                                            <PageNotFound {...props} />
                                        </route.layout>
                                    </Suspense>
                                )}
                            />
                        );
                    })
                }
                {/* <Redirect
                    from={history.location.pathname}
                    to={ROUTE_CONSTANTS.HOME_PAGE}
                /> */}
            </Switch>
        </>
    )
}
export default renderRouteBasedComponent;
