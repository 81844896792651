import { useState } from "react";
import cx from 'classnames';
import classes from './TryForFree.module.css';

import Button from "../../components/ui_elements/button/ButtonNew";

import globalClasses from '../../App.module.css'
import { isValidNumberString } from "../../utils/UtilityFunctions";
import { EMPTY_STRING } from "../../utils/Constants";
import Loader from "../../components/loader/Loader";
import { ROUTE_CONSTANTS } from "../../routes/RouteConstants";
import { BUTTON_TEXTS, FORM_PLACEHOLDER_TEXTS } from "../../strings/Strings";
import { authenticateUser, getCognitoUser, resendConfirmationCode } from "./Cognito.utils";
import { HiOutlineChevronLeft } from "react-icons/hi";
import VerificationCodeField from "../../components/ui_elements/form_fields/verify_code_field/VerificationCodeField";
import { BUTTON_TYPES } from "../../utils/ButtonConstants";


function VerifyEmail(props) {

    const { email, password, route, noVerification, getVerificationCode } = props
    const [verificationCode, setVerificationCode] = useState(EMPTY_STRING)
    const [isVerificationCodeError, setVerificationCodeError] = useState(false)

    const [isVerificationEmailError, setVerifyingEmailError] = useState(false)
    const [isVerifyingEmail, setVerifyingEmail] = useState(false)
    const [isVerificationEmailErrorMsg, setVerifyingEmailErrorMsg] = useState(false)

    let [enableResend, setEnableResend] = useState(true)


    const onChangeVerificationCode = (verificationCode) => {
        setVerificationCode(verificationCode)
        setVerificationCodeError(false)
        // getVerificationCode(verificationCode)
    }
    const onVerifyMailBtnClickHandler =  () => {

        let isVerificationError = isValidNumberString(verificationCode, true).error

        if (!isVerificationError) {
            setVerifyingEmail(true)
            setVerifyingEmailError(false)
            setVerifyingEmailErrorMsg(EMPTY_STRING)

            if (route.location.pathname === ROUTE_CONSTANTS.LOGIN || route.location.pathname === ROUTE_CONSTANTS.TRY_FOR_FREE) {
                // confirm user after registration
                getCognitoUser(email).confirmRegistration(verificationCode, true, (err, res) => {
                    if (err) {
                        console.error(err)
                        setVerifyingEmailError(true)
                        setVerifyingEmailErrorMsg(err.message)
                        setVerifyingEmail(false)
                        return
                    }
                    if (res.toUpperCase() === "SUCCESS") {
                        // auto login process after email verification
                         authenticateUser(email, password, route, null, null, null)
                    }
                })
            }

        } else {
            setVerificationCodeError(isVerificationError)
        }

    }

    const gotoLogin = () => {
        window.location.replace(ROUTE_CONSTANTS.LOGIN)
    }

    const onResendCodeFailureHandler = (err) => {
        setVerifyingEmailError(true)
        setVerifyingEmailErrorMsg(err.message)
    }

    const onResendCodeHandler = () => {
        resendConfirmationCode(email, (err) => onResendCodeFailureHandler(err))
        setEnableResend(false)
        setTimeout(
            () => { setEnableResend(true) },
            60000
        )
    }

    return (
        <>
            <div className="mb-3">
                <span className={cx(['align-self-start', globalClasses.Font12Grey, globalClasses.PointerCursor])} onClick={gotoLogin}>
                    <HiOutlineChevronLeft size={12} style={{ margin: '0px 3px 3px 0px' }} />Cancel
                </span>
            </div>

            {isVerifyingEmail && <Loader containerClass={classes.Loading} />}

            <div className='col-12'>
                <h3 className={cx([globalClasses.FontGoogleSans, globalClasses.FontBold, "text-start mb-4"])}>
                    Email Verification
                </h3>
                <VerificationCodeField
                    containerClass='col-12'
                    value={verificationCode}
                    id={'verificationCode'}
                    placeHolder={FORM_PLACEHOLDER_TEXTS.VERFICATION_CODE}
                    onChangeHandler={onChangeVerificationCode}
                    validate={isValidNumberString}
                    isDisabled={isVerifyingEmail}
                    isReadOnly={isVerifyingEmail}
                    isError={isVerificationCodeError}
                    isFieldError={isVerificationCodeError}
                    isMandatory={true}
                    isNumber={true}
                    resendVerificationCode={onResendCodeHandler}
                />
            </div>

            {isVerificationEmailError && <div className={cx(['align-self-start mt-0', globalClasses.Error])}> {isVerificationEmailErrorMsg}</div>}

            {
                !noVerification &&
                <div className="mt-3 text-end">
                    <Button
                        label={BUTTON_TEXTS.OK}
                        actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                        borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                        className={cx(['py-2 col-12'])}
                        onClickHandler={() => onVerifyMailBtnClickHandler()}
                    />
                </div>
            }
        </>

    )
}
export default VerifyEmail;
