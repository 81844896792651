
import cx from 'classnames';
import classes from './Footer.module.css';

import { FaFacebookF, FaInstagram, FaXTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa6";

import {FOOTER, PRODUCT_HUNT} from '../../utils/Constants';
import VIVASAI_LOGO from '../../assets/images/logos/vivas-ai-logo-v01.1.png'

function Footer(props) {
    return (
        <>
            <hr className="mt-0" />
            <div className={cx(["d-flex text-center text-md-start justify-content-md-between flex-wrap py-4", classes.FooterContent])}>
                <div className={cx(['col-12 col-md-4', classes.FooterTitleText])}>
                    <img width='100%' height='100%' className={classes.FooterLogo} src={VIVASAI_LOGO} alt={"Vivas.AI"} title={"Vivas.AI"} />
                </div>
                <div className="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-end mt-4 mt-md-0">
                    <div className="d-flex">
                        <div>
                            <a href={FOOTER.PRODUCT_HUNT} className={classes.AdditionalFooterIcon} target="_blank" rel="noopener noreferrer">
                                <img 
                                    src={PRODUCT_HUNT.BADGE_IMAGE}
                                    alt={PRODUCT_HUNT.BADGE_IMAGE_ALT_TEXT} 
                                />
                            </a>
                        </div>
                        <div>
                            <a href={FOOTER.FACEBOOK} className={cx(classes.FooterIcon,classes.FooterIconFaceBook)} target="_blank" rel="noopener noreferrer"  >
                                <FaFacebookF size={14} />
                            </a>
                        </div>
                        <div>
                            <a href={FOOTER.TWITTER} className={cx(classes.FooterIcon,classes.FooterIconXTwitter)} target="_blank" rel="noopener noreferrer">
                                <FaXTwitter size={14} />
                            </a>
                        </div>
                        <div>
                            <a href={FOOTER.INSTAGRAM} className={cx(classes.FooterIcon,classes.FooterIconInstagram)} target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={14} />
                            </a>
                        </div>
                        <div>
                            <a href={FOOTER.LINKED_IN} className={cx(classes.FooterIcon,classes.FooterIconLinkedIn)} target="_blank" rel="noopener noreferrer">
                                <FaLinkedinIn size={14} />
                            </a>
                        </div>
                        <div>
                            <a href={FOOTER.YOUTUBE} className={cx(classes.FooterIcon,classes.FooterIconYouTube)} target="_blank" rel="noopener noreferrer">
                                <FaYoutube size={14} />
                            </a>
                        </div>
                    </div>
                    <p className={classes.FooterCopyRightText}>Copyright © {process.env.REACT_APP_COPYRIGHT_YEAR} Workhall. All rights reserved.</p>
                </div>
            </div>
        </>
    )
}
export default Footer;