import { Component } from 'react';
import cx from 'classnames';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoMdArrowDropup } from 'react-icons/io';
import classes from '../UserManagement.module.css';
import DOWNLOAD_ICON from '../../../assets/images/download-orange.png';
import SUCCESS_IMG from '../../../assets/images/user-manage/approve-icon.png';
import FAILURE_IMG from '../../../assets/images/user-manage/cancel-icon.png';
import WARNING_IMG from '../../../assets/images/user-manage/warning.png';
import { getWesternNumber, isEmpty, isEmptyList, downloadFile, getDateFromInput } from '../../../utils/UtilityFunctions';
import Loader from '../../../components/loader/Loader';
import ErrorPage from '../../../components/error_page/ErrorPage';
import NoData from '../../../components/no_data/NoData';
import { USER_MNGMENT_STRINGS } from '../../../strings/UserManagement';
import { getTransactionData, handleTransactionsAPIError, INITIAL_TRANSACTION_PANE_STATE, TAB_IDS } from '../UserManagement.utils';
import { GET_TRANSACTIONS, FILE_DOWNLOAD_FROM_S3 } from '../../../urls/Urls';
import axios from '../../../axios/Axios'
import SortingTable from '../../../components/sorting_table/SortingTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCognitoUserAccessToken } from '../../sign_up/Cognito.utils';
import { SORT_TABLE_VALUES } from '../../../utils/Constants';
import { SEPARATORS } from '../../../strings/Strings';

class TransactionsPane extends Component {

    state = {
        ...INITIAL_TRANSACTION_PANE_STATE
    }

    componentDidMount() {
        let { isActiveTab } = this.props;
        if (isActiveTab) {
            this.getTransactionData()
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.isActiveTab !== this.props.isActiveTab) {
            let { isActiveTab } = this.props;
            if (isActiveTab) {
                this.getTransactionData();
            }
        }
    }


    getTransactionData = () => {

        axios.get(GET_TRANSACTIONS + this.props.accountID, {
            headers:{
                Authorization: "Bearer "+getCognitoUserAccessToken()
            }
        }
        )
            .then((response) => {
                this.setState({
                    ...getTransactionData(response.data)
                })
            }).catch((err) => {
                this.setState({
                    ...handleTransactionsAPIError(err)
                })
            })
    }

    downloadAttachment = (item) => {
        console.log("url: ", item)
        let downloadUrl = FILE_DOWNLOAD_FROM_S3
        let downloadReqBody = {
            objectID: item.receiptUrl,
            bucketType: 'Private'
        }

        axios.post(
            downloadUrl,
            downloadReqBody,
            {headers:{"Authorization" : "Bearer "+getCognitoUserAccessToken()}}
        ).then((res) => {
            console.log('response: ', res)
            let responseURL = res.data.URL;
            console.log('responseURL: ', responseURL)
            downloadFile(responseURL)
        }).catch(err => {
            console.log('error: ', err)
        })
    }
    render() {
        
        let { transactionsData, isTransactionsAPIError, isTransactionsAPILoading } = this.state
        let { tableName } = this.props;

        let transactionsContainer = null
        if (isTransactionsAPILoading) {
            transactionsContainer = <Loader />
        }
        else {
            
            if (isTransactionsAPIError) {
                transactionsContainer = <ErrorPage />
            }
            else if (isEmptyList(transactionsData)) {
                transactionsContainer = <NoData mainText={'No Transactions Available'} subText={''} />
            } else {

                transactionsContainer = (
                    <SortingTable
                        enableExports={true}
                        exportTableName={[tableName,"Transactions"].join(SEPARATORS.UNDERSCORE)}
                        exportKeysList={
                            [
                                {
                                    key : "transactionId",
                                    label : "Transaction ID"
                                },
                                {
                                    key : "AccountUUID",
                                    label : "Account ID",
                                    getValue: (item) => String(item.AccountUUID),
                                    hide: this.props.isNotAccountBasedTransaction
                                },
                                {
                                    key : "paymentId",
                                    label : "Payment ID"
                                },
                                {
                                    key : "transactionType",
                                    label : "Transaction Type"
                                },
                                {
                                    key: 'credit',
                                    label: 'Credits',
                                    hide : true,
                                    getValue : (item) => {
                                        let creditsStatus = "not added - "
                                        if(item.status === 'success'){
                                            creditsStatus = "added - "
                                        } else if(item.status === "requested"){
                                            creditsStatus = "pending - "
                                        }
                                        return isEmpty(Number(item.credit))? 'NA' :
                                            creditsStatus + item.credit
                                    }
                                }, {
                                    key: 'amount',
                                    label: 'Amount (USD)',
                                    getValue : (item) => {
                                        return Number(item.amount).toFixed(2)
                                    }
                                },
                                {
                                    key : "requestBy",
                                    label : "Actioned By"
                                },
                                {
                                    key : "status",
                                    label : "Status"
                                },
                                {
                                    key: 'createdOn',
                                    label: 'Actioned On',
                                    getValue : (item) => getDateFromInput(item.createdOn,true)
                                }
                            ]
                        }
                        enableSearch = {true}
                        searchKeysList = {
                            [
                                {
                                    key : "transactionId",
                                    label : "Transaction ID",
                                    value : "Transaction ID",
                                    placeholder : "Search by Transaction ID"
                                },
                                {
                                    key : "AccountUUID",
                                    label : "Account ID",
                                    value : "Account ID",
                                    placeholder : "Search by Account ID",
                                    hide: this.props.isNotAccountBasedTransaction
                                },
                                {
                                    key : "paymentId",
                                    label : "Payment ID",
                                    value : "Payment ID",
                                    placeholder : "Search by Payment ID"
                                },
                                {
                                    key : "transactionType",
                                    label : "Transaction Type",
                                    value : "Transaction Type",
                                    placeholder : "Search by Transaction Type"
                                },
                                {
                                    key : "requestBy",
                                    label : "Actioned By",
                                    value : "Actioned By",
                                    placeholder : "Search by Actioned User"
                                },
                                {
                                    key : "status",
                                    label : "Status",
                                    value : "Status",
                                    placeholder : "Search by Status"
                                }
                            ]
                        }
                        headingList={[
                            {
                                key: 'transactionId',
                                label: 'Transaction ID',
                                isDate: false
                            }, {
                                key: 'AccountUUID',
                                label: 'Account ID',
                                isDate: false,
                                hide: this.props.isNotAccountBasedTransaction
                            }, {
                                key: 'paymentId',
                                label: 'Payment ID',
                                isDate: false
                            }, {
                                key: 'transactionType',
                                label: 'Transaction Type',
                                isDate: false
                            }, {
                                key: 'credit',
                                label: 'Credits',
                                isDate: false,
                                hide : true,
                                renderItem : (item) => {
                                    let creditsStatusIcon = (
                                        <RiErrorWarningFill style={{margin:"0px 5px 3px 0px"}} color={'var(--error-text)'}/>
                                    );
                                    if(item.status === 'success'){
                                        creditsStatusIcon = (
                                            <IoMdArrowDropup style={{margin:"0px 3px 3px 0px"}} size={20} color={'var(--success-text)'}/>
                                        )
                                    } else if(item.status === "requested"){
                                        creditsStatusIcon = (
                                            <RiErrorWarningFill style={{margin:"0px 3px 3px 0px"}} size={20} color={'var(--warning-text)'}/>
                                        )
                                    }
                                    return isEmpty(Number(item.credit))? 
                                    <span className='d-flex justify-content-center'>{'NA'}</span> :
                                    <span className='d-inline-flex align-items-center justify-content-center'>
                                        {creditsStatusIcon}
                                        {getWesternNumber(item.credit)}
                                    </span>
                                }
                            }, {
                                key: 'amount',
                                label: 'Amount (USD)',
                                isDate: false,
                                renderItem : (item) => {
                                    return Number(item.amount).toFixed(2)
                                }
                            }, {
                                key: 'status',
                                label: 'Status',
                                isDate: false,
                                renderItem : (item) => {
                                    return(
                                        (item.status && (item.status).toLowerCase() == 'success') ?
                                            <div className='d-flex align-items-center'>
                                                <img src={SUCCESS_IMG} title="Success" alt="success image" width={"20px"} height="auto" />
                                                <span className='mx-2'>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</span>
                                            </div>
                                            :
                                            (item.status && (item.status).toLowerCase() == 'requested')?
                                            <div className='d-flex align-items-center'>
                                                <img src={WARNING_IMG} title="Requested" alt="requested image" width={"20px"} height="auto" />
                                                <span className='mx-2'>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</span>
                                            </div>
                                            :
                                            <div className='d-flex align-items-center'>
                                                <img src={FAILURE_IMG} title="Failure" alt="failure image" width={"20px"} height="auto" />
                                                <span className='mx-2'>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</span>
                                            </div>
                                    )
                                }
                            }, {
                                key: 'requestBy',
                                label: 'Actioned By',
                                isDate: false
                            }, {
                                key: 'createdOn',
                                label: 'Actioned On',
                                isDate: true,
                                onlyDate: true
                            }, {
                                key: 'attachmentUrl',
                                label: 'Receipt',
                                isDate: false,
                                unsortable : true,
                                renderItem: (item) => {
                                    return(
                                        item.receiptUrl && <div className="d-flex text-center">

                                        <span onClick={() => this.downloadAttachment(item)}>
                                            <img
                                                className={cx(['mx-2', classes.ActionIcon])}
                                                src={DOWNLOAD_ICON} alt={USER_MNGMENT_STRINGS.TOOLTIP_DOWNLOAD}
                                                title={USER_MNGMENT_STRINGS.TOOLTIP_DOWNLOAD}
                                            />
                                        </span>
                                        </div>
                                    )
                                }
                            }]
                        }
                        dataList={transactionsData}
                        itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.TRANSACTION}
                        noteText = {SORT_TABLE_VALUES.TABLE_NOTES.DATE_FORMAT1}
                    />
                )
            }
        }
        return (
            <>
            {transactionsContainer}
            </>
        )
    }
}


export default TransactionsPane;