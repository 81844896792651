import axios from '../../src/axios/Axios';
import { getCognitoUserAccessToken } from "../../src/containers/sign_up/Cognito.utils";
import { POST_AUDIT_LOG } from "../../src/urls/Urls.js"
import { isEmpty, getTimeDiff } from './UtilityFunctions';
import { AUDIT_LOG_EXCLUDED_EVENTS } from './Constants';

export const isExcludedEvent = (eventType) => {
    let isExcluded = false;

    if(Object.values(AUDIT_LOG_EXCLUDED_EVENTS).includes(eventType)) { 
        isExcluded = true
    }
    else
    {
        isExcluded = false
    }
    return isExcluded
}
export const AuditLog = async function (eventDetails, eventAttributes, accountDetails) {

    console.log('eventDetails: ', eventDetails)
    console.log('eventAttributes: ', eventAttributes)

    //calculate the duration
    let startDateTime = eventDetails?.eventStartDateTime || null
    let endDateTime = new Date()
    let timeTaken = !isEmpty(startDateTime) ? getTimeDiff(startDateTime, endDateTime) : null
    eventDetails = { ...eventDetails, eventEndDateTime:endDateTime, 'eventDuration': timeTaken }

    //assign additional properties to event detail and event attribute objects
    let eventSource = {}
    let attributes = {}
    if (typeof window !== "undefined") {
        if (window) {
            eventSource = {
                eventSource: window?.location?.host
            }
            attributes = {
                browser: window?.navigator?.userAgent,
                host: window?.location?.host,
                hostname: window?.location?.hostname,
                origin: window?.location?.origin,
                pathname: window?.location?.pathname,
            }
        }
    }

    eventDetails = { ...eventDetails, ...eventSource }
    eventAttributes = { ...eventAttributes, ...attributes }

    //assign locatoion to event attributes object
    let location = localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : {}
    eventAttributes.location = location

    console.log('eventAttributes: ', eventAttributes)

    //assign event attributes to event details object
    eventDetails.eventAttributes = { ...eventAttributes }
    console.log('eventDetails: ', eventDetails)

    //create data object
    let dataObject = {
        accountID: accountDetails?.accountUUID || null,
        eventName: eventDetails?.eventType,
        eventDetails: eventDetails,
        type: 'email'
    }

    console.log('dataObject: ', dataObject)
    //api call
    // let isExcluded = isExcludedEvent(eventDetails?.eventType)
    // console.log('excluded: ', isExcluded)
    // console.log(isExcluded)
    axios.post(POST_AUDIT_LOG, dataObject,
        { headers: { "Authorization": isExcludedEvent(eventDetails?.eventType) ? eventDetails?.eventType : "Bearer " + getCognitoUserAccessToken() } }
    )
        .then(res => {
            console.log('Audit log success:', res)
        })
        .catch(err => {
            console.log('Error while submitting the audit log...')
            console.log('Error :', err)
        })
};