import { SEPARATORS } from "../strings/Strings";
import globalClasses from '../App.module.css';
import ProgressBar from "../components/ui_elements/progress_bar/ProgressBar";
import Sentiment from "../components/ui_elements/sentiment_type/Sentiment";
import { getFileContent, getLabelText, getReferredData, getStructuredName, isEmpty, isEmptyList } from "./UtilityFunctions";
import AudioPlayer from "../components/ui_elements/audio_player/AudioPlayer";
import ReadMoreText from "../components/ui_elements/read_more_text/ReadMoreText";
import { EMPTY_STRING } from "./Constants";

export const getLLMData = (llmData,typeLabel="Credit(s)") => {
    if(!isEmpty(llmData) && isNaN(llmData)){
        let llmReturnData = llmData
        let llmDatasJSON = JSON.parse(llmReturnData);
        let providerTableData = []
        Object.keys(llmDatasJSON).map(llmDataItem => {
            if(llmDataItem !== "average_credit" && llmDataItem !== "average_accuracy"){
                // console.log("UNIT_PRICE",llmDataItem.split("_",2))
                let llmUnitPriceData = llmDataItem.split("_")
                let provider = llmUnitPriceData[0]
                let llm = llmUnitPriceData[1]
                if(llmUnitPriceData.length === 3){
                    provider = [llmUnitPriceData[0],llmUnitPriceData[1]].join(SEPARATORS.UNDERSCORE)
                    llm = llmUnitPriceData[2]
                }
                if(!isEmptyList(providerTableData) && providerTableData.findIndex(providerItem => providerItem.name===provider) >= 0){
                    let providerIndex = providerTableData.findIndex(providerItem => providerItem.name===provider)
                    providerTableData[providerIndex]['llm'].push({
                        name : llm,
                        llmData : llmDatasJSON[llmDataItem]
                    });
                    providerTableData[providerIndex]['llmCount'] += 1;
                } else {
                    providerTableData.push({
                        name : provider,
                        llm : [{
                            name : llm,
                            llmData : llmDatasJSON[llmDataItem]
                        }],
                        llmCount : 1
                    })
                }
            }
        })
        return (
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <th>Provider</th>
                            <th>LLM</th>
                            <th>Input (USD)</th>
                            <th>Output (USD)</th>
                        </tr>
                        {
                            providerTableData.map((providerItem,pIndex) => {
                                return providerItem?.llm.map((llmItem,lIndex) => {
                                    return (
                                        <tr>
                                            {
                                                !lIndex &&
                                                <td rowSpan={providerItem.llmCount} style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
                                                    <b>{getStructuredName(providerItem.name)}</b>
                                                </td>
                                            }
                                            <td>
                                                {getStructuredName(llmItem.name)}
                                            </td>
                                            <td>
                                                {llmItem.llmData?.input || 0}
                                            </td>
                                            <td>
                                                {llmItem.llmData?.output || 0}
                                            </td>
                                        </tr>
                                    )
                                })
                            })
                        }
                    </tbody>
                </table>
        )
    } else {
        return llmData;
    }
}

// to display date and time 
export const getTableTimeData = (time) => {
    let dateTimeString = time
    if(dateTimeString.indexOf(SEPARATORS.COMMA) !== -1){
        let [dateString,timeString] = dateTimeString.split(SEPARATORS.COMMA)
        return(
            <div className="d-flex flex-column">
                <span>{dateString}</span>
                <span className={globalClasses.Font14BlueGrey}>{timeString}</span>
            </div>
        )
    }
    return dateTimeString
}

export const getURLContainedStringToLinkElements = (inputString) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return (
        <span>
            {
                inputString.split(urlRegex).map(part => {
                    if (part.match(urlRegex)) {
                        return (
                            <a href={part} target="_blank" rel={"noopener noreferrer"}>{part}</a>
                        )
                    } else {
                        return (
                            <span>{part}</span>
                        )
                    }
                })
            }
        </span>
    )
}

// get format UI
export const getFormattedUI = (value,type,meta,data) => {
    if(!isEmpty(value) || (!isNaN(value) && Number(value)===0) || type==="referrer" ){
        if(type === 'longtext'){
            return (
                <ReadMoreText
                    query={value}
                    maxCharacter={meta?.length || 500}
                />
            )
        }
        if(type === 'beautify'){
            return (
                <span style={{whiteSpace:"pre-wrap"}}>
                    {value}
                </span>
            )
        }
        if(type === 'audio'){
            return (
                <AudioPlayer
                    audioSrc={value}
                />
            )
        }
        if(type === "score"){
            let scoreValue = isNaN(value)? value : meta?.multiply_with ? (Number(value) * meta?.multiply_with).toFixed(2) : (Number(value)).toFixed(2)
            return (
                <ProgressBar
                    value = {scoreValue}
                />
            )
        }
        if(type === "sentiment"){
            return (
                <Sentiment
                    type={getLabelText(value)}
                />
            )
        }
        if(type === "label"){
            let separator = meta?.separator || SEPARATORS.UNDERSCORE
            return getLabelText(value,separator);
        }
        if(type === "image"){
            if(!value.match(/(https?:\/\/[^\s]+)/g)){
                value = getFileContent(value) + value
            }
            return <img src={value} alt={meta?.label || "formatimage"} 
                style= {{ objectFit : "contain" }}
                width={meta?.width} height={meta?.height}/>
        }
        if(type === "referrer"){
            let returnData = getReferredData(data,meta.ref)
            if(isEmpty(returnData)){
                returnData = isEmpty(value) ? SEPARATORS.HYPHEN : value
            }
            return returnData
        }
        if(type === "bulletlist"){
            let actualList = Object.assign([],value);
            if(actualList.length){
                let bulletList = [];
                let replaceMeta = meta?.replace_meta
                actualList.map((listItem,index) => {
                    let pushText = EMPTY_STRING;
                    if(replaceMeta){
                        pushText = replaceMeta?.replace_text;
                        replaceMeta?.replacers.map((replaceItem,rIndex) => {
                            let replaceValue = getReferredData(listItem,replaceItem?.replace_ref)
                            pushText = pushText.replace(replaceItem?.text,replaceValue)
                            return rIndex;
                        })
                    } else {
                        pushText = getReferredData(listItem,meta?.value_ref)
                    }
                    if(!isEmpty(pushText)){
                        pushText = "• " + pushText.trim();
                        if(bulletList.indexOf(pushText) < 0){
                            bulletList.push(pushText)
                        }
                    }
                    return index;
                })
                if(!isEmptyList(bulletList)){
                    return (
                        <span style={{whiteSpace:"pre"}}>
                            {bulletList.join("\n")}
                        </span>
                    )
                }
            }
            return SEPARATORS.HYPHEN
        }
        if(type === "table"){
            let data = (
                <table className="table table-bordered">
                    <tbody>
                    {
                        Object.keys(value).map((item,index) => {
                        if((item === "current_pay" || item === "ytd_pay") && 
                            (typeof value[item] === "object") && (value[item].hasOwnProperty('amount') && value[item].hasOwnProperty('currency')))
                            {
                                return(
                                    <tr key={index}>
                                        <td style={{width:"200px"}}>{getLabelText(item)}</td>
                                        <td>{ isEmpty(value[item]['amount']) ? '-' : value[item]['amount'] + ' ' + value[item]['currency']}</td> 
                                    </tr>
                                )
                            }    
                            else
                            {
                                return(
                                    <tr key={index}>
                                        <td style={{width:"200px"}}>{getLabelText(item)}</td>
                                        <td style={{whiteSpace:"pre-wrap"}}> { Array.isArray(value[item]) && !isEmptyList(value[item])? ((value[item]).map(item => "• "+item)).join('\n ') : isEmpty(value[item]) ? '-' : (isNaN(value[item]) ? value[item].trim() : value[item])}</td> 
                                    </tr>
                                )
                            }
                        })
                    }
                    </tbody>
                </table>
            )
            return data;
        }
        if(type==="stringlist"){
            if(Array.isArray(value)){
                let stringList = Object.assign([],value)
                if(stringList.length === 0){
                    return SEPARATORS.HYPHEN
                }
                if(stringList.length === 1){
                    return stringList;
                }
                return (
                    <ul>
                        {
                            stringList.map((stringList) => {
                                return (<li>{stringList}</li>)
                            })
                        }
                    </ul>
                )
            } else {
                return value
            }
        }
        if(type==="list"){
            return Array.isArray(value)? value.join(", ") : value;
        }
        return value;
    }

    return SEPARATORS.HYPHEN
    
}