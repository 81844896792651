import ALL_ICON from '../assets/images/icon.png';
import { IMAGES } from './ImageConstants';

export const PAGE_TITLE = {
    DEFAULT : {
        TITLE : "Vivas.AI - Bring the Power of Gen AI into your workflow",
        DESCRIPTION : "Elevate your enterprise with Vivas.AI using Gen AI for document processing, engaging data conversations, and a suite of AI models to enhance workflow and decision-making, all tailored to your needs."
    },
    PRODUCTS : {
        LEAF_IDP : {
            TITLE : "Leaf IDP - Intelligent Document Processing",
            DESCRIPTION : "Transform documents into actionable decisions with Leaf IDP's smarter AI technology. Simplify your workflow with generative AI."
        },
        LEAF_CONVERSE : {
            TITLE : "Leaf Converse - Data Insights Unleashed",
            DESCRIPTION : "Unlock the full potential of your data with Leaf Converse. Discover powerful data insights with generative AI today."
        },
        LEAF_ESSENTIALS : {
            TITLE : "Leaf Essentials - Custom AI Model Creation",
            DESCRIPTION : "Effortlessly create custom AI models with Leaf Essentials. Discover the simplicity of generative AI today."
        }
    },
    SERVICE : {
        LEAF_CONCIERGE : {
            TITLE : "Leaf Concierge - Vivas.AI Premium AI Services",
            DESCRIPTION : "Vivas.AI services, blend custom solutions with advanced technology for your unique business needs, ensuring cost-effectiveness and swift market entry."
        }
    },
    ABOUTUS : {
        TITLE : "About Vivas.AI - Our Vision and Values",
        DESCRIPTION : "Discover Vivas.AI's vision of making AI simple and accessible for all. Learn more about our mission and values today."
    },
    PARTNER : {
        TITLE : "Vivas.AI Partners - Business Collaboration",
        DESCRIPTION : "Unlock new business possibilities worldwide with Vivas.AI partnerships. Explore collaboration opportunities and bring generative AI into your workflow."
    },
    CONTACTUS : {
        TITLE : "Contact Vivas.AI - Get in Touch",
        DESCRIPTION : "Reach out to Vivas.AI for any inquiries. We're here to help you integrate generative AI into your applications."
    },
    CAREERS : {
        TITLE : "Careers at Vivas.AI - Join Our Team",
        DESCRIPTION : "Explore exciting career opportunities at Vivas.AI. Join our team and help make AI simple and accessible for all."
    },
    PRICING : {
        TITLE : "Vivas.AI Pricing - Leaf IDP, Leaf Converse and Leaf Essentials",
        DESCRIPTION : "Discover our flexible pricing options designed to meet your needs and budget. Explore Vivas.AI plans today!"
    },
    WHITEPAPERS : {
        TITLE : "Vivas.AI Whitepapers - Stay Informed",
        INDIVIDUAL_TITLE : "Whitepapers",
        DESCRIPTION : "Discover and stay ahead of the curve with Vivas.AI whitepapers. Access the latest insights and research on generative AI."
    },
    BLOG : {
        TITLE : "Vivas.AI Blog - Tech Tips and Insights",
        INDIVIDUAL_TITLE : "Blog",
        DESCRIPTION : "Stay updated with the latest technologies, tips, and insights on the Vivas.AI blog. Learn how generative AI can transform your workflow."
    },
    USE_CASE : {
        TITLE : "Vivas.AI Use Cases - Real-World Applications",
        INDIVIDUAL_TITLE : "Use Case",
        DESCRIPTION : "Explore real-world use cases of Vivas.AI models and see how generative AI can benefit your business."
    },
    INDUSTRY : {
        TITLE : "Vivas.AI Industries - Sector Applications",
        INDIVIDUAL_TITLE : "Industry",
        DESCRIPTION : "Discover how Vivas.AI models are utilized across various industries. Explore sector-specific applications of generative AI."
    },
    PRIVACY : {
        TITLE : "Vivas.AI Privacy Policy",
        DESCRIPTION : "Learn how Vivas.AI protects your data and upholds your privacy. Read our privacy policy."
    },
    TERMS : {
        TITLE : "Vivas.AI Terms and Conditions",
        DESCRIPTION : "Discover the guidelines and agreements that govern Vivas.AI services. Read our terms and conditions."
    },
    REQUEST_DEMO : {
        TITLE : "Get Vivas.AI Demo",
        DESCRIPTION : "Experience our solutions firsthand with a personalized demonstration tailored to your needs."
    },
    ANNOUNCEMENTS : {
        TITLE : "Announcements",
        DESCRIPTION : ""
    },
    MANAGE : {
        TITLE : "Manage Your Vivas.AI Account",
        DESCRIPTION : "Take control of your Vivas.AI account and optimize your experience with our comprehensive management tools."
    },
    LOGIN : {
        TITLE : "Vivas.AI Secure Login",
        DESCRIPTION : "Securely access your Vivas.AI account and explore endless possibilities with just a few clicks."
    },
    REGISTER : {
        TITLE : "Sign Up for Vivas.AI - Free Trial",
        DESCRIPTION : "Create your Vivas.AI account today and enjoy exclusive benefits with our free trial. Join our community now!"
    },
    PROFILE : {
        TITLE : "Manage Your Vivas.AI Profile",
        DESCRIPTION : "Personalize your Vivas.AI experience and easily manage your preferences from your profile dashboard."
    },
    RESET_PASSWORD : {
        TITLE : "Reset Your Vivas.AI Password",
        DESCRIPTION : "Quickly and securely regain access to your Vivas.AI account by resetting your password."
    }
}

export const VALIDATION_REGEX = {
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    MOBILE: /^[\s()+-]*([0-9][\s()+-]*){6,20}$/,
    NUMBER: /^[0-9]*$/,
    NUMBER_PATTERN: /^[0-9]*[.]?[0-9]*$/,
    MAIL_PATTERN: /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    // MAIL_PATTERN: /^[a-z0-9]+[\.]?[a-z0-9]+@[a-z]{3,10}\.[a-z]{2,3}$/,
    // WORKMAIL_PATTERN: /^[a-z0-9]+(?:\.[a-z0-9]+)*@(?!gmail)(?!yahoo)(?!hotmail)(?!mail)(?!yandex)(?!mail)(?!outlook)[a-zA-Z]{3,7}\.[c]{1}[o]{1}[m]{1}$/,
    // WORKMAIL_PATTERN: /^[a-z0-9]+[\.]?[a-z0-9]+@[a-z]{3,10}\.[a-z]{2,3}$/,
    WORKMAIL_PATTERN: new RegExp(process.env.REACT_APP_WORK_EMAIL_REGEX),
    // WORKMAIL_PATTERN: /^[a-zA-Z]+/,
    NAME_PATTERN: /^[a-zA-Z]+(([a-zA-Z\.\s])?[a-zA-Z]*)*$/,
    ORG_NAME: /^[a-zA-Z0-9]+(([a-zA-Z0-9\.\-\& ])?[a-zA-Z0-9]*)*$/,
    MODEL_NAME: /^[a-zA-Z0-9]+(([a-zA-Z0-9\.\-\&])?[\sa-zA-Z0-9]+)*$/,
    ZIP_CODE: /^[\w\d]{2,}(?:[-\s][\w\d]{1,})?(?:[-\s][\w\d]{1,})?$/,
    SECRETS : /^[A-Z_]+$/,
    WEBSITE : /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
}

export const DEFAULT_COUNTRY_CODE = 'US'

export const ENABLE_RENEW_BEFORE = 3
export const EXPIRY_DAY = -1

export const KEY_CODES = {
    ENTER_KEY: 13
}

export const CONFIGURATOR_CHECK_LINKS_INPUTLIST = ["provider","llm",'tool']

export const SORT_TABLE_VALUES = {
    ALIGNMENTS : {
        LEFT : 1,
        RIGHT : 2,
        CENTER : 3
    },
    ITEM_COUNTS : {
        TRANSACTION : 15,
        SUPPORT_TICKET : 15,
        PENDING_INVITEES : 15,
        ACCOUNTS : 15,
        MODEL_MANAGE : 15,
        USERS : 15,
        API_KEYS : 15,
        CONFIGURATORS : 15,
        TASKS : 10,
        DETAILED_REPORT : 15,
        TASK_FILES : 5,
        LICENSES : 5
    },
    TABLE_NOTES : {
        DATE_FORMAT1 : "All dates are in MM/DD/YYYY format.",
        DATE_FORMAT2 : "All dates are in MM/DD/YYYY hh:mm:ss aa (UTC) format.",
        PRICING_ESTIMATOR : "Above result is for one month (30 days).",
        LICENSES : "All dates are in MM/DD/YYYY format.",
        API_KEYS : "Get Token button will generate token for the Client ID and Client Secret, you can also copy them by clicking the copy button."
    }
}

export const SELECT_OPTIONS_LIST = {
    HOW_DID_YOU_KNOW: ['Event', 'LinkedIn', 'Facebook', 'Instagram', 'Google Search', 'Peer Referral', 'Third-Party Review', 'Other'],
    TOP_UP_UNITS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15],
    SUBSCRIPTION_TOP_UP_UNITS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 50],
    AVAILABLE_CATEGORIES: ['General', 'API & Connectors', 'Model Specific', 'Pricing & Billing', 'Login & Register', 'Subscription/ Top-up/ Add-ons/ Professional Service'],
    MODEL_FEEDBACK_TOPICS: ['Performance', 'Limitations', 'API Information', 'Use Case', 'Pricing', 'Others'],
    PARTNER_TYPE:['OEM Partnership','Low Code/No Code Platform Partnership','Services Partnership','Reseller Partnership'],
}

export const ENTITY_ACTIONS = {
    ADD : 1,
    UPDATE : 2,
    DELETE : 3,
    REORDER : 4
}

export const LICENSE_ACTIONS = {
    ACTIVATE : "ACTIVATE",
    UPDATE : "UPDATE",
    DEACTIVATE : "DEACTIVATE"
}

export const LICENSE_STATUS = {
    ACTIVE : "ACTIVE",
    DELETED : "DELETED",
    DEACTIVE : "DEACTIVE"
}

export const RADIO_OPTIONS_LIST = {
    TP_DLP_TDI: ['redact', 'character_mask', 'string_replacement', 'infotype_replacement']
}

export const EMOTIONS_FEEDBACK_OPTIONS = {
    FIVE_GROUP : [
        {
            face : IMAGES.FEEDBACK_EMOTIONS.VERY_UNSATISFIED_FACE,
            text : "Disappointed",
            value : 1,
            size : 42
        },
        {
            face : IMAGES.FEEDBACK_EMOTIONS.UNSATISFIED_FACE,
            text : "Not Good",
            value : 2,
            size : 42
        },
        {
            face : IMAGES.FEEDBACK_EMOTIONS.NEUTRAL_FACE,
            text : "Average",
            value : 3,
            size : 42
        },
        {
            face : IMAGES.FEEDBACK_EMOTIONS.SATISFIED_FACE,
            text : "Good",
            value : 4,
            size : 42
        },
        {
            face : IMAGES.FEEDBACK_EMOTIONS.VERY_SATISFIED_FACE,
            text : "Awesome",
            value : 5,
            size : 42
        }
    ]
}

export const SUBSCRIPTION_TYPE_IDS = {
    Trial: 1,
    Bronze: 2,
    Bronze3: 14,
    Bronze6: 15,
    Bronze12: 16,
    Silver: 4,
    Silver3: 17,
    Silver6: 18,
    Silver12: 19,
    Gold: 3,
    Gold3: 20,
    Gold6: 21,
    Gold12: 22,
}

export const SELF_HOSTING_SUBSCRIPTION_IDS = [
    3, 8, 9
]

export const SUBSCRIPTION_PRODUCTS = {
    LEAF_IDP: {
        ID : "LEAF_IDP",
        NAME : "Leaf IDP",
        UNITS : "Page"
    },
    LEAF_CONVERSE: {
        ID : "LEAF_CONVERSE",
        NAME : "Leaf Converse",
        UNITS : "USD"
    },
    LEAF_ESSENTIALS: {
        ID : "LEAF_ESSENTIALS",
        NAME : "Leaf Essentials",
        UNITS : "USD"
    }
}

export const SUBSCRIPTION_TENURES = {
    FREE: {
        ID: 'TRIAL',
        VALUE: 0,
        LABEL: '14 Days',
        ENABLE_RENEW_BEFORE: 1,
        DISCOUNT: 0
    },
    ONE_MONTH: {
        ID: 'Tenure-1',
        VALUE: 1,
        LABEL: '1 month',
        ENABLE_RENEW_BEFORE: 7,
        DISCOUNT: 0
    },
    THREE_MONTH: {
        ID: 'Tenure-3',
        VALUE: 3,
        LABEL: '3 months',
        ENABLE_RENEW_BEFORE: 21,
        DISCOUNT: 5
    },
    SIX_MONTH: {
        ID: 'Tenure-6',
        VALUE: 6,
        LABEL: '6 months',
        ENABLE_RENEW_BEFORE: 42,
        DISCOUNT: 12
    },
    TWELVE_MONTH: {
        ID: 'Tenure-12',
        VALUE: 12,
        LABEL: '12 months',
        ENABLE_RENEW_BEFORE: 84,
        DISCOUNT: 20
    }
}

export const SUBSCPTION_PRODUCT_TYPE = {
    LEAF_IDP: [1, 2, 3],
    LEAF_CONVERSE: [4,5,8],
    LEAF_ESSENTIALS: [6, 7,9]
}

export const SUBSCPTION_TENURE_TYPE = {
    ONE: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    THREE: [14, 17, 20],
    SIX: [15, 18, 21],
    TWELEVE: [16, 19, 22]
}

// subscription plans
export const SUBSCRIPTION_TYPE = {
    LEAF_IDP : {
        LITE : [1],
        STANDARD : [2],
        ENTERPRISE : [3],
        LITE_DETAILS : {
            LABEL : "Lite",
            IS_FREE_PLAN : true
        },
        STANDARD_DETAILS : {
            LABEL : "Standard",
            IS_FREE_PLAN : false
        },
        ENTERPRISE_DETAILS : {
            LABEL : "Enterprise",
            IS_FREE_PLAN : false
        }
    },
    LEAF_CONVERSE : {
        TRIAL : [4],
        CUSTOM : [5, 8],
        TRIAL_DETAILS : {
            LABEL : "Lite",
            IS_FREE_PLAN : true
        },
        CUSTOM_DETAILS : {
            LABEL : "Custom",
            IS_FREE_PLAN : false
        }
    },
    LEAF_ESSENTIALS : {
        TRIAL : [6],
        CUSTOM : [7, 9],
        TRIAL_DETAILS : {
            LABEL : "Lite",
            IS_FREE_PLAN : true
        },
        CUSTOM_DETAILS : {
            LABEL : "Custom",
            IS_FREE_PLAN : false
        }
    },
    CUSTOM: [3, 20, 21, 22],
    BASIC: [4, 17, 18, 19],
    TRIAL: [1],
    BRONZE: [2, 14, 15, 16],
    TRIAL_DETAILS: {
        LABEL: 'Lite',
        STYLE: {
            color: '#202124',
            backgroundColor: '#f6f6f6',
            fontFamily: 'Roboto-Medium',
            border: '1px solid #d2d2d2',
            fontSize: '14px',
            marginLeft: '10px'
        }
    },
    BRONZE_DETAILS: {
        LABEL: 'Standard',
        STYLE: {
            color: '#202124',
            backgroundColor: '#D7FFB9',
            fontFamily: 'Roboto-Medium',
            border: '1px solid #23C15D',
            fontSize: '14px',
            marginLeft: '10px'
        }
    },
    CUSTOM_DETAILS: {
        LABEL: 'Enterprise',
        STYLE: {
            color: '#202124',
            backgroundColor: '#FBFF7D',
            fontFamily: 'Roboto-Medium',
            border: '1px solid #BFC25D',
            fontSize: '14px',
            marginLeft: '10px'
        }
    },
    BASIC_DETAILS: {
        LABEL: 'None',
        STYLE: {
            color: '#202124',
            backgroundColor: '#D0F7FC',
            fontFamily: 'Roboto-Medium',
            border: '1px solid #57AEB9',
            fontSize: '14px',
            marginLeft: '10px'
        }
    },
}

export const INPUT_TYPES = {
    TEXT_AREA: 1,
    TEXT: 2,
    NUMBER: 3,
    TOPUP: 4
}

export const PRODUCT_VIDEO = "https://www.youtube.com/watch?v=YppwgvVWoZ0"
export const AUGMENT_CREATION_VIDEO = "https://www.youtube.com/watch?v=vc30SdfyIVw"
export const VURAM_SITE = "https://workhall.com/"
export const INFO_MAIL = 'ask@vivas.ai' // support mail
export const SUPPORT_MAIL = 'support@vivas.ai'
export const INFO_PHONE = '+ 91 44 4910 2200'
export const INFO_ADDRESS = '185, 3rd Main Road,\nNatesa Nagar, Virugambakkam,\nChennai - 600 092,\nTamil Nadu, India.'
export const JOB_MAIL = 'jobs@vivas.ai' // job mail

export const MIN_TOPUP_AMOUNT = 100.00;
export const TOPUP_UNIT_AMOUNT = 1;
export const MIN_TOPUP_UNIT = 0;
export const MIN_TOPUP_UNIT_MANAGE = 0;
export const CREDIT_PER_USD = 1
export const MAX_CREDITS_REQUIRED = 1
export const MAX_ADDITION_MANAGE_TOPUP = 4900
export const MAX_ADDITION_SUBSCRIBE_TOPUP = 900
export const TIMEOUT_ESTIMATE = 10000
export const STRIPE_THRESHOLD_AMOUNT = 5000

export const SUPPORT_STATUS_IDS = {
    "0": "Closed",
    "1": "Raised"
}

export const THEME = {
    DARK: 1,
    LIGHT: 0,
    LIGHT_BLUE_SHADES : 2
}

export const LEAF_PRODUCTS = {
    LEAF_IDP : "Leaf IDP",
    LEAF_ESSENTIALS : "Leaf Essentials",
    LEAF_CONVERSE : "Leaf Converse",
    LEAF_ANALYZER : "Leaf Analyzer",
    LEAF_RPA : "Leaf RPA",
    SEED_ESSENTIALS : "Seed Essentials",
    LEAF_COPILOT_ENGINE : "Leaf Concierge"
}

export const TOKEN_IDS = {
    NOUN: 'Noun',
    ADP: 'Adposition',
    VERB: 'Verb',
    DET: 'Delimiter',
    ADJ: 'Adjective',
    ADV: 'Adverb',
    PUNCT: 'Punctuation',
    NUM: 'Number',
    PRON: 'Pronoun',
    PRT: 'Particle',
    CONJ: 'Conjunction',
    ALL: 'All'
}

export const PIE_CHART_COLOR_LIST = ['#735DF8', '#DD7EFF', '#F7D7D8', '#F3C1C1', '#EA6D93']

export const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
export const FULL_MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const STRING_VALUE = {
    TRUE: "True",
    FALSE: "False"
}

export const CONST_VALUE = {
    TRUE: 1,
    FALSE: 0
}
export const CATEGORY = {
    PLUG_AND_PLAY: "Plug & Play",
    FINE_TUNED: "Fine-Tuned",
    CUSTOM_TRAINED: "Custom Trained",
    FINE_TUNEABLE: "Fine-Tunable"
}

export const SUBSCRIBE_REQUEST_TYPE = {
    SUBSCRIBE: 'Subscribe Plan',
    RENEW: 'Renew',
    CHANGE: 'Plan Change'
}

export const USER_MANAGEMENT = {
    MIN_CREDITS_BALANCE: 5000,
    USER_STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
        UNSUBSCRIBED:3
    },
    TRANSACTION_TYPE: {
        NAMED_USER: "NamedUser",
        ADD_CREDITS: "Recharge",
        RENEW_ACCOUNT: "Renew",
        CHANGE_PLAN: "Change",
        SUBSCRIPTION: "Subscription",
    }
}

export const REQUEST_TYPE = {
    SUBSCRIBE: 'Subscribe Plan',
    TOPUP: 'Top Up',
    RENEW: 'Renew',
    CHANGE: 'Plan Change'
}

export const USER_ROLE = {
    SUBSCRIBED: {
        ID: 2,
        LABEL: "subscribed"
    },
    SUBSCRIBED_ADMIN: {
        ID: 3,
        LABEL: "subscribed_admin"
    },
    SUPER_ADMIN: {
        ID: 4,
        LABEL: "superadmin"
    },
    REGISTERED: {
        ID: 1,
        LABEL: "registered"
    },
    ANONYMOUS: {
        ID: 0,
        LABEL: "anonymous"
    }
}

export const EMPTY_STRING = ""
export const EMPTY_LIST = []

export const BASE64_PNG = "data:image/png;charset=utf-8;base64,";

export const BASE64_CONTENTS = {
    PNG: "data:image/png;charset=utf-8;base64,",
    JPEG: "data:image/jpeg;charset=utf-8;base64,",
    WEBP: "data:image/webp;charset=utf-8;base64,",
    PDF: "data:application/pdf;charset=utf-8;base64,",
    BMP: "data:image/bmp;charset=utf-8;base64,",
    OGG: "data:audio/ogg;base64,",
    MP3: "data:audio/mpeg;base64,",
    WAV: "data:audio/wav;base64,"
}

export const BUTTON_ICON_POSITION = {
    LEFT: 0,
    RIGHT: 1
}

export const HOME = {
    START_PAGE: 1,
    PAGINATION_COUNT: 12,
    GROUP: "&group=",
    ROLE: "role=",
    PAGE: "&page=",
    TASK: "&task=",
    DOMAIN: "&dv=",
    GENAI: "&gv=",
    MODEL_VIEW : "&view=",
    CATEGORY: "&cat=",
    SEARCH_KEY: "&k=",
    TRYABLE: "&tryit=",
    REGION: "&region=",
    MODEL_TYPE : "&type=",
    BASE_MODEL : "&base=",
    ALL_ICON: ALL_ICON
}

export const PLAYGROUD_GROUP_TYPES = {
    DOMAIN : "domain",
    TASK : "task",
    ALL : "all",
    FAVORITES : "favorites",
    CONVENTIONAL : "conventional",
    COMING_SOON : "comingsoon",
    LEAF_FLOW : LEAF_PRODUCTS.LEAF_ESSENTIALS,
    LEAF_IDP : LEAF_PRODUCTS.LEAF_IDP,
    LEAF_XDOCCONVERSE : LEAF_PRODUCTS.LEAF_CONVERSE,
    LEAF_SCRAPER : LEAF_PRODUCTS.LEAF_RPA,
    LEAF_PII_SAFETY : LEAF_PRODUCTS.LEAF_ANALYZER,
    MISCELLANEOUS : LEAF_PRODUCTS.SEED_ESSENTIALS,
    LEGACY_MODELS : "legacy"
}

export const TEXTAREA_LENGTH = {
    MINIMUM: 3,
    MAXIMUM: 500,
    MODEL_FEEDBACK_MINIMUM: 3,
    MODEL_FEEDBACK_MAXIMUM: 300,
    MODEL_FEEDBACK_ROWS : 3,
    SECRET_NAME : {
        MINIMUM : 1,
        MAXIMUM : 100
    },
    PUBLISH_SKILL_NAME : {
        MINIMUM : 5,
        MAXIMUM : 100
    },
    PUBLISH_SKILL_DESCRIPTION : {
        ROWS:1,
        MINIMUM : 50,
        MAXIMUM : 1000
    },
    PUBLISH_SKILL_SAMPLE_DESCRIPTION : {
        ROWS:3,
        MINIMUM : 20,
        MAXIMUM : 1000
    },
    TRIAL_USECASE:{
        ROWS:4,
        MINIMUM : 50,
        MAXIMUM : 500
    }
}

export const FILE_SIZE = {
    MAX_RESUME_SIZE: 1024 * 1024 * 5,
}

export const IMAGE_TYPES = {
    PROFILE_PIC:['.jpg', '.jpeg', '.png']
}

export const PROGRESS_STATUS = {
    MAX: 85,
    MIN: 65
}

export const MODEL_DETAILS = {
    RELATED_MODELS: {
        START_PAGE: 1,
        PAGINATION_COUNT: 5,
        PAGE: "?page=",
    }
}

export const PRODUCT_HUNT = {
    PAGE_URL : "https://www.producthunt.com/posts/vivas-ai-leaf-idp?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-vivas&#0045;ai&#0045;leaf&#0045;idp",
    BADGE_IMAGE : "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=452335&theme=dark",
    BADGE_IMAGE_ALT_TEXT : "Vivas&#0046;AI&#0032;&#0045;&#0032;Leaf&#0032;IDP - Effortlessly&#0032;process&#0032;documents&#0032;with&#0032;precision&#0032;and&#0032;ease&#0046; | Product Hunt",
    BLOG_IFRAME : <iframe style={{borderRadius: "6px", backgroundColor: "var(--color-white)", border: "none"}} src="https://cards.producthunt.com/cards/posts/452335?v=1" width="70%" height="300" ></iframe>
}

export const FOOTER = {
    LINKED_IN: "https://www.linkedin.com/company/vivas-ai/",
    YOUTUBE: "https://www.youtube.com/@vivasai7984",
    INSTAGRAM: "https://www.instagram.com/vivas_ai/",
    TWITTER: "https://twitter.com/Vivas_AI",
    FACEBOOK: "https://www.facebook.com/vivas.ai.ml/",
    PRODUCT_HUNT : PRODUCT_HUNT.PAGE_URL
}

export const BULK_MODEL_CONTENTS = {
    SUMMARY : "S",
    LEFT_CONTENT : "L",
    FULL_PAGE : "F"
}

export const MODEL_CODES = {
    WEB_SCRAPER : "TP_LLM_WS",
    DOCUMENT_ENTITY_EXTRACTOR : "TP_LLM_CDE",
    ID_EXTRACTOR : "TP_LLM_CIDE",
    TABLE_EXTRACTOR : 'TP_DOC_TE',
    SIGNATURE_LOCALIZATION : 'CT_CV_SL',
    TOXICITY_DETECTOR: 'TP_SA_TD',
    EMOTION_DETECTOR: 'PT_SA_ED',
    EXPENSE_EXTRACTOR: "TP_DOC_EE",
    MORTGAGE_EXTRACTOR: "TP_DOC_ME",
    COMPARE_FACES: 'TP_CV_CF',
    OBJECT_DETECTOR: 'TP_CV_OD',
    TEXT_DEIDENTIFIER: 'TP_DLP_TDI',
    SENTENCE_EXTRACTOR: 'PT_TE_SE',
    MACHINE_TRANSLATION: 'TP_L_A2A',
    ZERO_SHOT_CLASSIFIER: 'PT_TC_ZSC',
    LOGO_DETECTOR:'TP_CV_LD',
    PLACES_INFO:"TP_MISC_PI",
    FACE_ANALYZER:"TP_CV_FA",
    PROTECTIVE_EQUIPMENT_DETECTOR:"TP_CV_PED",
    BARCODE_DETECTION:"PT_CV_BD",
    IMAGE_IDENTIFIER:"TP_DLP_IID",
    SHORT_SPEECH_TRANSCRIBER:"TP_SR_SST",
    OPINION_MINING : "TP_SA_OM",
    DOCUMENT_OCR:"TP_ASYNCDOC_OCR",
    MORTGAGE_1098:"TP_ASYNCDOC_1098E",
    INT_EXTRACT_1099:"TP_ASYNCDOC_1099INTE",
    SPEECH_ANALYZER:"TP_SR_SA",
    MEDICAL_TEXT_ANALYZER: 'TP_L_MTA',
    DOCUMENT_REDLINING:"CT_DOC_DR",
    PII_REDACTOR : "TP_LLM_PII",
    GENERIC_EMOTION_DETECTOR : "TP_LLM_GED"
}

export const ERROR_CODES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    CONFLICT: 409,
    SERVER_ERROR: 500,
    PRECONDITION_FAILED: 412,
    UNPROCESSABLE_ENTITY: 422,
    SERVICE_UNAVAILABLE : 503
}

export const TASK_STATUS = {
    COMPLETED: 'Completed',
    FAILED: 'Failed'
}

export const OPINION_TYPES = {
    PEOPLE : 1,
    COMPANY : 2
}

export const EVENT = {
    AUTHENTICATION_CATEGORY: {
        CATEGORY_NAME: "Authentication",
        LOGIN: {
            "eventVersion": "1.0",
            "eventType": "Login",
            "eventName": "Login"
        },
        LOGIN_REFRESH : {
            "eventVersion": "1.0",
            "eventType": "Login",
            "eventName" :'Login refresh'
        },
        LOGOUT: {
            "eventVersion": "1.0",
            "eventType": "Logout",
            "eventName": "Logout"
        }
    },
    USER_MANAGEMENT_CATEGORY: {
        CATEGORY_NAME: "User management",
        REGISTER: {
            "eventVersion": "1.0",
            "eventType": "Registration",
            "eventName": "Create account"
        },
        FORGOT_PASSWORD: {
            "eventVersion": "1.0",
            "eventType": "Forgot password",
            "eventName": "Forgot password"
        },
        MY_PROFILE_EDIT: {
            "eventVersion": "1.0",
            "eventType": "Profile update",
            "eventName": "Profile update"
        },
        MY_PROFILE_ENABLE_MFA: {
            "eventVersion": "1.0",
            "eventType": "Enable MFA",
            "eventName": "Enable MFA"
        },
        MY_PROFILE_DISABLE_MFA: {
            "eventVersion": "1.0",
            "eventType": "Disable MFA",
            "eventName": "Disable MFA"
        }
    },
    REQUEST_CATEGORY: {
        CATEGORY_NAME: "Request",
        REQUEST_DEMO: {
            "eventVersion": "1.0",
            "eventType": "Request demo",
            "eventName": "Request demo"
        },
        REQUEST_PARTNER: {
            "eventVersion": "1.0",
            "eventType": "Request Partner",
            "eventName": "Request Partner"
        },
    },
    MODEL_DETAIL_CATEGORY: {
        CATEGORY_NAME: "Model details",
        ADD_RATING: {
            "eventVersion": "1.0",
            "eventType": "Add rating",
            "eventName": "Add rating"
        },
        UNLOCK_LARGE_VOLUME_DISCOUNT: {
            "eventVersion": "1.0",
            "eventType": "Unlock large volume discount",
            "eventName": "Unlock large volume discount request"
        }
    },
    PAYMENT_TRANSACTION_CATEGORY: {
        CATEGORY_NAME: "Payment transaction",
        SUBSCRIBE_PLAN: {
            "eventVersion": "1.0",
            "eventType": "Subscription",
            "eventName": "Subscription creation"
        },
        CHANGE_PLAN: {
            "eventVersion": "1.0",
            "eventType": "Change plan",
            "eventName": "Change plan entry creation"
        },
        RENEW_PLAN: {
            "eventVersion": "1.0",
            "eventType": "Renew plan",
            "eventName": "Renew plan entry creation"
        },
        ADD_CREDITS: {
            "eventVersion": "1.0",
            "eventType": "Credits Topup",
            "eventName": "Credits topup entry creation"
        }
    },
    MANAGE_CATEGORY: {
        CATEGORY_NAME: "Manage",
        ADD_CONFIGURATION: {
            "eventVersion": "1.2",
            "eventType": "Add Configuration",
            "eventName": "Own Configurations"
        },
        UPDATE_CONFIGURATION: {
            "eventVersion": "1.2",
            "eventType": "Update Configuration",
            "eventName": "Own Configurations"
        },
        DELETE_CONFIGURATION: {
            "eventVersion": "1.2",
            "eventType": "Remove Configuration",
            "eventName": "Own Configurations"
        },
        VALIDATE_CONFIGURATION: {
            "eventVersion": "1.2",
            "eventType": "Validate Configuration",
            "eventName": "Own Configurations"
        },
        GENERATE_API_KEY: {
            "eventVersion": "1.0",
            "eventType": "Generate API Key",
            "eventName": "Generate API Key"
        },
        EDIT_ACCOUNT: {
            "eventVersion": "1.0",
            "eventType": "Edit API Key",
            "eventName": "Edit API Key"
        },
        DELETE_ACCOUNT: {
            "eventVersion": "1.0",
            "eventType": "Delete API Key",
            "eventName": "Delete API Key"
        },
        ADD_ON_REQUEST: {
            "eventVersion": "1.0",
            "eventType": "Professional service Add-on",
            "eventName": "Professional service Add-on"
        },
        ADD_USER: {
            "eventVersion": "1.0",
            "eventType": "Add user",
            "eventName": "Add user"
        },
        EDIT_USER: {
            "eventVersion": "1.0",
            "eventType": "Edit user",
            "eventName": "Edit user"
        },
        DELETE_USER: {
            "eventVersion": "1.0",
            "eventType": "Delete user",
            "eventName": "Delete user"
        },
        MANAGE_MODEL: {
            "eventVersion": "1.0",
            "eventType": "Enable/Disable model",
            "eventName": "Enable/Disable model"
        },
        MANAGE_MODEL_BY_PROVIDER: {
            "eventVersion": "1.0",
            "eventType": "Enable/Disable model by provider",
            "eventName": "Enable/Disable model by provider"
        },
        UNSUBSCRIPTION: {
            "eventVersion": "1.0",
            "eventType": "Unsubscription",
            "eventName": "Unsubscription"
        }
    }
}

export const AUDIT_LOG_EXCLUDED_EVENTS = {
    RESET_PASSWORD : "Forgot password",
    REGISTERATION : "Registration"
}
