import classes from './ProgressBar.module.css';
import cx from 'classnames';
import { PROGRESS_STATUS } from '../../../utils/Constants';

function ProgressBar(props) {
    let {value, progressStyle, progressBarStyle, valueStyle,containerStyle}=props;
    let statusStyle;
    statusStyle = classes.ProgressBar;
    if(isNaN(value)){
        return value
    }
    let magValue = Math.abs(value)
    // if(coloredBar){
        statusStyle = magValue>PROGRESS_STATUS.MAX ? 
                        classes.ProgressBarPositive 
                        : magValue<PROGRESS_STATUS.MIN? 
                            classes.ProgressBarNegative
                            : classes.ProgressBarNeutral
    // }
    let progressStyles = cx(["progress",classes.Progress, progressStyle])
    let progressBarStyles = cx(["progress-bar",statusStyle, progressBarStyle])
    let valueStyles = cx([valueStyle,classes.ValueText])
    let containerStyles = cx([containerStyle,classes.Container])
    return (
        <div className={containerStyles}>
            <div className={progressStyles}>
                <div 
                    className={progressBarStyles}
                    role="progressbar"
                    style={{width: `${magValue}%`}}>
                </div>
            </div>
            <span className={valueStyles}>{value+'%'}</span>
        </div>
    )
}

export default ProgressBar;