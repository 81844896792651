// modules
import cx from "classnames";

// classes
import classes from "./Button.module.css";
import { getActionClass, getBorderClass, getRadiusClass } from "./Button.utils";

function ButtonGroup (props) {

    // initialization
    let { containerClass, labelList} = props;
    
    let buttonContainerClass = cx("btn-group",containerClass);

    return (
        <div className={buttonContainerClass} role="group">
            {
                labelList.map((labelItem) => {
                    // class initialization
                    let actionClass = getActionClass(labelItem?.actionType);
                    let radiusClass = getRadiusClass(labelItem?.radiusType);
                    let borderClass = getBorderClass(labelItem?.borderType);
                    
                    let buttonClass = cx("col-12 p-2",labelItem?.hide && "d-none",classes.ButtonNew, actionClass, radiusClass, borderClass, labelItem?.className);
                    
                    return (
                        <button key={labelItem?.id} id={labelItem?.id} type="button" class={buttonClass} onClick={labelItem.onClickHandler}>
                            {labelItem?.icon}{labelItem?.label}{labelItem?.backIcon}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default ButtonGroup;