import { ROUTE_CONSTANTS } from "../../routes/RouteConstants";
import { LEAF_PRODUCTS } from "../../utils/Constants";
import { IMAGES } from "../../utils/ImageConstants";

export const DROP_NAV_LINKS = {
    RESOURCES : {
        LABEL : "Resources",
        ITEMS : [
            {
                label : "Industries",
                routePath : ROUTE_CONSTANTS.INDUSTRY,
                id: "Resources-Industries",
                icon : IMAGES.MENU_ICONS.INDUSTRY_ICON,
                description : "Explore the utility of our models in various industries."
            },
            {
                label : "Use Cases",
                routePath : ROUTE_CONSTANTS.USECASE,
                id: "Resources-Usecases",
                icon : IMAGES.MENU_ICONS.USECASE_ICON,
                description : "Explore real-world use cases of our models."
            },
            {
                label : "Blogs",
                routePath : ROUTE_CONSTANTS.BLOG,
                id: "Resources-Blogs",
                icon : IMAGES.MENU_ICONS.BLOG_ICON,
                description : "Stay updated with technologies, tips and inights."
            },
            {
                label : "Whitepapers",
                routePath : ROUTE_CONSTANTS.WHITE_PAPER,
                id: "Resources-Whitepaper",
                icon : IMAGES.MENU_ICONS.WHITEPAPER_ICON,
                description : "Discover and stay ahead of the curve!"
            }
        ]
    },
    ABOUT : {
        LABEL : "About",
        ITEMS : [
            {
                label : "Who are we?",
                routePath : ROUTE_CONSTANTS.ABOUT_VIVASAI,
                id: "About-Who-Are-We",
                icon : IMAGES.MENU_ICONS.ABOUTUS_ICON,
                description : "Discover our company's vision and values."
            },
            {
                label : "Partners",
                routePath : ROUTE_CONSTANTS.PARTNER,
                id: "About-Partner",
                icon : IMAGES.MENU_ICONS.PARTNER_ICON,
                description : "Unlock new possibilities for businesses worldwide."
            },
            {
                label : "Careers",
                routePath : ROUTE_CONSTANTS.CAREERS,
                id: "About-Careers",
                icon : IMAGES.MENU_ICONS.CAREER_ICON,
                description : "Join us and explore exciting opportunities."
            },
            {
                label : "Contact Us",
                routePath : ROUTE_CONSTANTS.CONTACT_US,
                id: "About-Contacts",
                icon : IMAGES.MENU_ICONS.CONTACTUS_ICON,
                description : "Reach out to us for inquiries."
            }
        ]
    },
    PRODUCT : {
        LABEL : "Products",
        ITEMS : [
            {
                label : LEAF_PRODUCTS.LEAF_IDP,
                routePath : ROUTE_CONSTANTS.PRODUCT_LEAF_IDP,
                id: "Product-LeafIDP",
                icon : IMAGES.MENU_ICONS.LEAFIDP_ICON,
                description : "Transform documents into decisions with AI that works smarter, not harder."
            },
            {
                label : LEAF_PRODUCTS.LEAF_CONVERSE,
                routePath : ROUTE_CONSTANTS.PRODUCT_DOCQSPHERE,
                id: "Product-DocQSphere",
                icon : IMAGES.MENU_ICONS.DOCQSPHERE_ICON,
                description : "Unlock the true potential of your data."
            },
            {
                label : LEAF_PRODUCTS.LEAF_ESSENTIALS,
                routePath : ROUTE_CONSTANTS.PRODUCT_GPT_FLOW,
                id: "Product-GPT-FLow",
                icon : IMAGES.MENU_ICONS.GPTFLOW_ICON,
                description : "Discover AI models with effortless custom AI model creation."
            }
        ]
    },
    SERVICES : {
        LABEL : "Services",
        ITEMS : [
            {
                label : LEAF_PRODUCTS.LEAF_COPILOT_ENGINE,
                routePath : ROUTE_CONSTANTS.PRODUCT_DEV_COPILOT,
                id: "Service-DevCopilotEngine",
                icon : IMAGES.MENU_ICONS.DEVCOPILOT_ICON,
                description : "Custom GenAI services with cost-effectiveness, rapid market entry, and 24/7 support."
            }
        ]
    }
}
