import { Component } from "react";
import { withRouter } from "react-router-dom";


import cx from 'classnames';
import globalClasses from '../../App.module.css'
import classes from './FeedbackScreen.module.css';
import FeedbackImage from '../../assets/images/feedback-image.svg';
import Button from '../ui_elements/button/ButtonNew';
import axios from "axios";
import { INITIAL_STATE, setDefaultState } from "./FeedbackScreen.utils";
import { BUTTON_TEXTS, EMPTY_STRING, FEEDBACK_STRING, TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../strings/Strings";
import { POST_SUBMIT_FEEDBACK } from "../../urls/Urls";
import { HOME, USER_ROLE } from "../../utils/Constants";
import { isEmpty, isEmptyList, isValidMessageString, isValidWorkEmailString } from "../../utils/UtilityFunctions";
import SuccessScreen from "../success_screen/SuccessScreen";

import LoaderGIF from '../../assets/images/loader/loader-gif.gif';
import TextAreaField from "../ui_elements/form_fields/teaxt_area_input/TextAreaField";
import TextField from "../ui_elements/form_fields/text_input/TextField";
import SelectField from "../ui_elements/form_fields/select_input/SelectField";
import Loader from "../loader/Loader";
import { ROUTE_CONSTANTS } from "../../routes/RouteConstants";
import { toast } from 'react-toastify';
import { BUTTON_TYPES } from "../../utils/ButtonConstants";
class FeedbackScreen extends Component {
    errorCount = 0;
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        }
    }
    setUser = (user) => {
        this.setState({
            user
        })
    }
    setOptionType = (optionType) => {
        this.setState({
            optionType: optionType,
            modelName: optionType
        })
    }
    onChangeTextContent = (feedback) => {
        this.setState({
            textContent: feedback,
            isErrorTextContent: false
        })
    }
    onChangeUserText = (email) => {
        this.setState({
            user: email,
            isErrorUser: false
        })
    }
    onChangeSpecificModelHandler = (modelName) => {
        // let modelName = event.target.value;
        this.setState({
            modelName
        })
    }
    onChangeOptionHandler = (value) => {
        let type = value;
        let isModelNamesOptionVisible = false;
        // let modelName = FEEDBACK_STRING.FEED_TYPE[1];
        let modelName = EMPTY_STRING
        if (type === FEEDBACK_STRING.FEED_TYPE[4]) {
            isModelNamesOptionVisible = true;
            modelName = this.state.optionList[0]
        }
        this.setState({
            optionType: value,
            isModelNamesOptionVisible,
            modelName
        })
    }
    getModelNames = (url) => {
        axios.get(url)
            .then(res => {
                let modelNames = [];
                // res.data.modelNames.map(item => {
                //     modelName.push(item.Model_Name)
                // })
                modelNames = [...new Set(res.data.modelNames.map(item => item.Model_Name))]
                this.setState({
                    optionList: modelNames,
                    modelName: (this.props.history.location.pathname === ROUTE_CONSTANTS.MODEL_PLAYGROUND || this.props.history.location.pathname === ROUTE_CONSTANTS.DEV_STUDIO) ? modelNames[0]:EMPTY_STRING
                })
            })
            .catch(err => console.log(err.message))
    }

    getRouteBasedOptionType = () => {
        let pathname = this.props.history.location.pathname
        let optionType = FEEDBACK_STRING.FEED_TYPE[1]
        switch (pathname) {
            case ROUTE_CONSTANTS.MODEL_PLAYGROUND:
            case ROUTE_CONSTANTS.DEV_STUDIO:
                optionType = FEEDBACK_STRING.FEED_TYPE[4];
                this.setState({
                    isModelNamesOptionVisible:true,
                    modelName:this.state.optionList[0]
        
                })
                break;
            case ROUTE_CONSTANTS.PRICING:
                optionType = FEEDBACK_STRING.FEED_TYPE[5];
                break;
            case ROUTE_CONSTANTS.HOME_PAGE:
                optionType = FEEDBACK_STRING.FEED_TYPE[1];
                break;
            case ROUTE_CONSTANTS.SUBCRIBE:
                optionType = FEEDBACK_STRING.FEED_TYPE[5];
                break;
            case ROUTE_CONSTANTS.USER_MANAGEMENT:
                optionType = FEEDBACK_STRING.FEED_TYPE[3];
                break;
            default:
                break;
        }
        this.setState({
            optionType: optionType
        })

    }
    componentDidMount() {
        let { accountDetail, userRole, isAuthenticated,userDetails } = this.props;
        this.getModelNames('/api/models/' + (isAuthenticated ? "?" + HOME.ROLE + 1 : ""));

        console.log("UserDetails feed",userDetails)

        this.getRouteBasedOptionType()
        if (isAuthenticated) {
            this.setUser(userDetails.email);
            this.setState({
                accountName: isEmpty(userRole) ? "null" : isEmpty(accountDetail.AccountName) ? "null" : accountDetail.AccountName,
                userRole: isEmpty(userRole) ? USER_ROLE.REGISTERED.LABEL : userRole
            })
        }
    }
    setToDefault = () => {
        this.errorCount = 0;
        this.setState(
            setDefaultState()
        )
    }

    onFeedbackSubmitHandler = () => {

        let feedback = this.state.textContent;
        let user = this.state.user;
        let accountName = this.state.accountName;
        let userRole = this.state.userRole;

        let isValidEmail = isValidWorkEmailString(user, false).error
        let isValidTextContent = isValidMessageString(feedback, 500, 3, true).error;

        if (user === EMPTY_STRING) {
            user = 'anonymous'
            accountName = 'null';
            userRole = USER_ROLE.ANONYMOUS.LABEL;
        }
        console.log('Feedback Data..');
        console.log(this.state.optionType);

        if (!isValidEmail && !isValidTextContent) {
            this.setState({
                isFeedbackSubmitting: true
            }, () => {
                let feedback = {
                    feedbackType: this.state.optionType,
                    modelName: this.state.modelName? this.state.modelName:this.state.optionList[0],
                    comments: this.state.textContent,
                    user,
                    accountName,
                    userRole
                }
                axios.post(POST_SUBMIT_FEEDBACK, feedback)
                    .then(res => {
                        this.setState({
                            // isFeedbackSubmitSuccessFull: true, //TODO:REMOVE
                            isFeedbackSubmitting: false
                        })

                        //toaster
                        toast(TOASTER_MESSAGE.FEEDBACK.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });

                        this.onClickDoneHandler()
                    })
                    .catch(err => {
                        // alert(err.message)
                        console.log('Error while submitting your feedback')
                        this.setState({
                            isFeedbackSubmitting: false
                        })

                        //toaster
                        toast(TOASTER_MESSAGE.FEEDBACK.ERROR,
                            {
                                type: TOASTER_TYPE.ERROR,
                                theme: TOASTER_THEME.LIGHT
                            });
                    })
            })
        } else {
            this.setState({
                isSubmitError: true,
                isErrorTextContent: isValidTextContent,
                isErrorUser: isValidEmail
            })
        }
    }
    onClickDoneHandler = () => {
        this.setToDefault();
        // set scroll bar after successfull submission
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'auto'
        }
        this.props.closeFeedbackScreen();
    }
    render() {
        console.log('feedback', this.props, this.props.type)
        let { textContent, optionList, optionType, modelName, user,
            isErrorUser, isErrorTextContent,
            isModelNamesOptionVisible, isFeedbackSubmitSuccessFull, isSubmitError,
            isFeedbackSubmitting } = this.state;

        let modelOptionsComponent = null, userComponent = null, buttonGroupComponent = null;
        let selectableOptionComponent = null;

        if (isFeedbackSubmitting) {
            buttonGroupComponent = (
                // <span className='d-inline-flex align-items-center'>
                //     <img className={classes.LoaderSmallSize} src={LoaderGIF} alt={'Submit'} />
                //     <span className={globalClasses.Font16LoadText}>{FEEDBACK_STRING.SUBMITTING}</span>
                // </span>
                <Loader
                    containerClass={globalClasses.FullPageLoader}
                />
            )
        } else {
            buttonGroupComponent = (
                <div className={cx(['row justify-content-around justify-content-md-end', classes.ButtonGroup])}>
                    <Button
                        containerClass={'col-5 col-md-4'}
                        label={BUTTON_TEXTS.CANCEL}
                        onClickHandler={this.onClickDoneHandler}
                        actionType={BUTTON_TYPES.ACTIONS.SECONDARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                    />
                    <Button
                        containerClass={cx(['col-5 col-md-4'])}
                        label={BUTTON_TEXTS.SUBMIT}
                        onClickHandler={() => this.onFeedbackSubmitHandler()}
                        actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                        borderType={BUTTON_TYPES.BORDER.BORDERED}
                    />
                </div>
            )
        }
        userComponent = (
            <TextField
                containerClass='col-12'
                value={user}
                id={'email'}
                placeHolder={FEEDBACK_STRING.EMAIL_PLACEHOLDER}
                onChangeHandler={this.onChangeUserText}
                validate={isValidWorkEmailString}
                isDisabled={isFeedbackSubmitting}
                isReadOnly={isFeedbackSubmitting}
                isError={isSubmitError}
                isFieldError={isErrorUser}
                isMandatory={false}
                isNumber={false}
            />
        )
        if (isModelNamesOptionVisible && !isEmptyList(optionList)) {
            modelOptionsComponent = (
                <SelectField
                    // containerClass={cx(['col-12', classes.Select])}
                    containerClass='col-12'
                    value={modelName}
                    onChangeHandler={this.onChangeSpecificModelHandler}
                    isDisabled={isFeedbackSubmitting}
                    isReadOnly={isFeedbackSubmitting}
                    // label={'Selected Plan'}
                    id={'feedbackModelCategory'}
                    optionsList={optionList}
                />
            )
        }
        if (!this.props.type) {
            console.log("feedback 1")
            selectableOptionComponent = (
                <>
                    <SelectField
                        // containerClass={cx(['col-12', classes.Select])}
                        containerClass='col-12'
                        value={optionType}
                        onChangeHandler={this.onChangeOptionHandler}
                        isDisabled={isFeedbackSubmitting}
                        isReadOnly={isFeedbackSubmitting}
                        // label={'Selected Plan'}
                        id={'feedbackCategory'}
                        optionsList={[FEEDBACK_STRING.FEED_TYPE[1], FEEDBACK_STRING.FEED_TYPE[2],
                        FEEDBACK_STRING.FEED_TYPE[3], FEEDBACK_STRING.FEED_TYPE[4],
                        FEEDBACK_STRING.FEED_TYPE[5], FEEDBACK_STRING.FEED_TYPE[6],
                        FEEDBACK_STRING.FEED_TYPE[7]]}
                    />
                    {modelOptionsComponent}
                </>
            )
        } else {
            console.log("feedback 2")

            if (optionType !== this.props.type)
                this.setOptionType(this.props.type);
        }
        if (isFeedbackSubmitSuccessFull) { //TODO:REMOVE
            return (
                <SuccessScreen
                    className={"px-3 px-md-4 px-lg-5"}
                    mainText={FEEDBACK_STRING.THANK_YOU}
                    subText={this.props.successMessage ?
                        this.props.successMessage
                        : FEEDBACK_STRING.SUCCESS_SUBMISSION}
                    buttonText={BUTTON_TEXTS.DONE}
                    onClickHandler={this.onClickDoneHandler}
                />
            )
        }
        return (
            <div className={cx(['flex-column flex-lg-row', classes.Container])}>
                <img width='100%' height='100%' src={this.props.imageSrc ? this.props.imageSrc : FeedbackImage} className={classes.FeedbackImage} alt="Feedback Icon" />
                <div className={cx(['mt-3 mt-lg-0', classes.ContainerContent])}>
                    <span className={cx([globalClasses.Font36BoldDarkerGrey, classes.Heading])}>
                        {this.props.mainText ? this.props.mainText : FEEDBACK_STRING.HEADING}
                    </span>
                    <span className={cx([globalClasses.Font15DarkerGrey, classes.SubText])}>
                        {this.props.subText ? this.props.subText : FEEDBACK_STRING.SUBTEXT}
                    </span>
                    {userComponent}
                    {selectableOptionComponent}
                    <TextAreaField
                        containerClass={cx(['col-12', classes.TextArea])}
                        value={textContent}
                        // label={'Cover letter'}
                        id={'feedback'}
                        placeHolder={this.props.textAreaPlaceholder ?
                            this.props.textAreaPlaceholder
                            : FEEDBACK_STRING.PLACEHOLDER_TEXT}
                        onChangeHandler={this.onChangeTextContent}
                        validate={isValidMessageString}
                        isDisabled={isFeedbackSubmitting}
                        isReadOnly={isFeedbackSubmitting}
                        isError={isSubmitError}
                        isFieldError={isErrorTextContent}
                        isMandatory={true}
                        rowLength={3}
                        maxCharacter={500}
                        minCharacter={3}
                    />
                    {buttonGroupComponent}
                </div>
            </div>
        )
    }
}

export default withRouter(FeedbackScreen);