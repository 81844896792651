import axios from 'axios';
import queryString from 'query-string';

import {
    CONFIG_CONSTANTS,
    REQUEST_RESPONSE_OBJECT_KEYS,
    REQUEST_TYPE
} from './Constants';

const instance = axios.create({
    timeout: CONFIG_CONSTANTS.TIMEOUT
});
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.interceptors.request.use(
    request => {
        if (request.method === REQUEST_TYPE.GET) {
            if (request.hasOwnProperty(REQUEST_RESPONSE_OBJECT_KEYS.PARAMS)) {
                if (!request.params.hasOwnProperty(REQUEST_RESPONSE_OBJECT_KEYS.FORMAT)) {
                    request.params[REQUEST_RESPONSE_OBJECT_KEYS.FORMAT] = CONFIG_CONSTANTS.APPLICATION_TYPE.JSON; //add format type as json if there is no format-type
                }
            } else {
                let queryParamsAndURL = queryString.parseUrl(request.url);
                if (queryParamsAndURL.query.format !== CONFIG_CONSTANTS.APPLICATION_TYPE.JSON)
                    request.params = {
                        ...request.params,
                        format: CONFIG_CONSTANTS.APPLICATION_TYPE.JSON
                    };
            }
        }
        return request;
    },
    error => {
        throw error;
    }
);
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        throw error;
    }
);
export default instance;