import { TEXTAREA_LENGTH } from "../utils/Constants"

export const BEST_EXPERIENCE = {
    BEST_EXPERIENCE_TEXT: "We kindly recommend you to use desktop view for better experience."
}

export const NODATA_FOUND = {
    NORESULT: "No Result found",
    NORESULTTEXT: "We couldn't find what you are looking for.",
    NODATA: "No Data found",
}

export const NOPAGE_FOUND = {
    NOPAGE: "Page Not Found",
    NOPAGETEXT: "We're sorry, the page you requested could not be found."
}

export const COMING_SOON = {
    TITLE: "Coming Soon",
    SUBTEXT: "We're sorry, the page you requested is under construction."
}

export const SOMETHING_WRONG = {
    SOMETHINGWENTWRONG: "Oops! Something went wrong",
    SOMETHINGWENTWRONGTEXT: "You may also refresh the page or try again later."
}

export const CONNECTION_ISSUE = {
    NETWORKCONNECTIVITYISSUES: "Network Connectivity Issues",
    NETWORKCONNECTIVITYISSUESTEXT: "Poor Internet connection detected"
}

export const CONTACT_STRINGS = {
    HEADING: "We'd love to help you",
    SUBTEXT: "We are here to help you. Your question may help other users also.",
    PLACEHOLDER_TEXT: "Ask your questions",
    THANK_YOU: 'Thank you!',
    SUCCESS_SUBMISSION: 'Your query has been placed, we will reach out to you soon.',
    EMAIL_PLACEHOLDER: 'Email ID',
    EMPTY_FIELD: 'This field should not be empty',
}

export const FEEDBACK_STRING = {
    HEADING: "We'd love to hear your feedback",
    SUBTEXT: "Your feedback is extremely valuable to us. This allows us to continue to improve our product.",
    PLACEHOLDER_TEXT: "Share your feedback / comments with us.",
    THANK_YOU: 'Thank you!',
    SUCCESS_SUBMISSION: 'Your feedback helps us to improve our services and enhance your experience on our platform.',
    EMAIL_PLACEHOLDER: "Email ID",
    SUBMITTING: "Submitting...",
    FEED_TYPE: {
        1: 'General Feedback',
        2: 'API & Connectors',
        3: 'Account & User Management',
        4: 'Model Specific Feedback',
        5: 'Pricing & Billing',
        6: 'Login & Register',
        7: 'Subscription/ Top-up/ Add-ons/ Professional Service'
    }
}

export const RATING_STRINGS = {
    HEADING : "Rate the model",
    SUBTEXT : "Your rating will help us continue to improve our models and services.",
    PLACEHOLDER_TEXT : "Share us about your own experience of this model...",
    THANK_YOU: 'Thank you!',
    SUCCESS_SUBMISSION: 'We really appreciate you taking the time to share your rating with us.'
}

export const MODEL_FEEDBACK_STRINGS = {
    HEADING : "Help us make it better for you",
    SUBTEXT : "Your valuable feedback will help us provide better models.",
    EXPERIENCE_LABEL : "1. What do you feel about model's response?",
    PLACEHOLDER_TOPIC : "-- Select --",
    POSITIVE_SUGGESTIONS_TOPIC_LABEL : "2. Which aspect of the model do you think it excels at?",
    NEGATIVE_SUGGESTIONS_TOPIC_LABEL : "2. How can we improve ourselves to serve you better?",
    POSITIVE_OWN_SUGGESTIONS_TOPIC_LABEL : "Type the area needs improvement here...",
    NEGATIVE_OWN_SUGGESTIONS_TOPIC_LABEL : "Type the reason for the issue here...",
    POSITIVE_PLACEHOLDER_SUGGESTION : "Give us more information on what need to be improved...",
    NEGATIVE_PLACEHOLDER_SUGGESTION : "Give us more information on where you faced problem...",
    ASK_FOR_SAVING_DATA : "Can we save the data for the current request?",
    THANK_YOU: 'Thank you!',
    SUCCESS_SUBMISSION: 'We really appreciate you taking the time to share your experience with us.'
}

export const SUBSCRIPTION_REQUIRED = {
    TITLE: "Subscribe to Vivas.AI today",
    SUB_TEXT: "Buy subscription and get more access to this model.",
    SUB_TEXT_API: "Buy subscription and get access to API information of models.",
    SUB_TEXT_USER: "Buy subscription at any time using your Vivas.AI ID, ",
    GO_TO_HOME: "Go to Home",
    SUBSCRIBE: "Subscribe",
    GO_TO_SHOWCASE: "Go to Playground"
}

export const REGISTRATION_REQUIRED = {
    TITLE : "Register / Login to Vivas.AI",
    SUB_TEXT : "You are required to register or login to use models."
}

export const BUTTON_TEXTS = {
    NOTIFY : "Notify",
    CORE_MODELS : "Core Models",
    AUGMENTED_MODELS : "Augmented Models",
    ENTERPRISE_MODELS : "Enterprise Models",
    EDIT_AND_DOWNLOAD : "Edit & Download",
    BUILD_NEW_MODEL : "+ Build Your Own Model",
    SAVE_AS_DRAFT : "Save as Draft",
    DEPLOY_MODEL : "Deploy Model",
    VIEW_ALL : "View All",
    PUBLISH_MODEL : "Publish Model",
    UPDATE_MODEL : "Update Model",
    EDIT_MODEL : "Edit Model",
    CANCEL_EDIT_MODEL : "Cancel Edit",
    DELETE_MODEL : "Delete Model",
    GOTO_PUBLISH_MODEL : "Go to Model",
    UPDATE_SAMPLE : "Update Sample",
    ADD_SAMPLE : "Add Sample",
    NEXT : "Next",
    PREVIOUS : "Previous",
    PAYMENT : "Proceed to Payment",
    REQUEST_PAYMENT : "Request for Payment",
    CHECKOUT : "Checkout",
    CLEAR: "Clear",
    CLEAR_FILTERS: "Clear Filter",
    SUBMIT: "Submit",
    CONFIRM_PASS: "Confirm",
    DONE: 'Done',
    CHOOSE_PLAN: 'Choose Plan',
    CONTACT_SALES:'Contact Sales Team',
    CONFIRM: 'Yes',
    BACK_TO_HOME: 'Go Back to Home',
    CONTACT_TEAM: 'Contact our sales team',
    SIGN_IN: "Login",
    SIGN_UP: "Register",
    CANCEL: "Cancel",
    LEARN_MORE: "Learn More",
    TRY_FOR_FREE: "Try for Free",
    BUY_NOW: "Buy Now",
    TRY_FOR_FREE_TEXT: "Try for Free",
    REQ_A_DEMO: "Request a Demo",
    CONTACT_US: "Contact Us",
    SHOW_MORE: "Show More",
    EXPLORE: "Explore More",
    REGISTER_NOW: "Register Now",
    SUBSCRIBE_NOW: "Subscribe Now",
    JOIN_FOR_FREE: "Join for Free",
    SUBSCRIBE: 'Subscribe',
    GO_TO_SHOWCASE: 'Go to Playground',
    GO_TO_DEVSTUDIO: 'Go to Dev Studio',
    GO_TO_LEAFIDP_STUDIO: 'Go to Leaf IDP Studio',
    DELETE: 'Delete',
    REMOVE: 'Remove',
    DOWNLOAD: 'Download',
    VIEW_DETAILED_REPORT: "View Detailed Report",
    HIDE_DETAILED_REPORT: "Hide Detailed Report",
    ESTIMATE: 'Estimate',
    OK: 'OK',
    EXPORT_PDF: 'Export as PDF',
    EXPORT_CSV: 'Export as CSV',
    FIND_ANSWER: 'Find An Answer',
    PLAN_CHANGE_RENEW: 'Change or Renew',
    PLAN_CHANGE: 'Change Plan',
    VIEW_PLAN: 'View Plans',
    UPDATE: 'Submit',
    TRY_IT: 'Try It',
    ESTIMATE_IT: 'Estimate It',
    UPLOAD : "Upload",
    RESET : "Reset",
    SEND_CODE : "Send Verification Code",
    BACK_TO_MODELS : "Back to Models",
    JOIN_NOW : "Join Now",
    VIEW_MORE : "View More",
    GO_TO_MANAGE : "Go to Manage Section",
    ADD_KEY : "Add Key",
    UPDATE_KEY : "Update",
    TRY_LEAF_IDP : "Try Leaf IDP for Free",
    TRY_LEAF_CONVERSE : "Try Leaf Converse",
    TRY_LEAF_ESSENTIAL : "Try Leaf Essentials"
}

export const FORM_LABEL_TEXTS = {
    NAME: "Name",
    FIRST_NAME: 'First Name',
    LAST_NAME: "Last Name",
    JOB_TITLE: 'Job Title',
    COMPANY: "Company Name",
    EMAIL: 'Email ID',
    PHONE: 'Phone',
    PASSWORD:"Password",
    VALID_PASSWORD_TEXT:"8+ characters including a small letter, a capital letter, a digit and a special character ( @ $ ! % * ? & )",
    NEW_PASSWORD:"New Password",
    HOW_DID_YOU_KNOW: 'How did you come to know about Vivas.AI?',
    MESSAGE: 'Tell us about your project',
    SUBMISSION: 'By proceeding, you agree to our Terms of Service and Privacy Policy',
    ADD_MESSAGE: 'Type your project details here',
    SELECT_AVAILABILITY: "Select availability",
    VERFICATION_CODE:"Verification Code",
    CONFIRM_PASSWORD: "Confirm Password",
    WEBSITE: "Company Website",
    DESIRED_PARTNER: "Desired Partner Type",
    ADD_MORE_DETAILS:"Add more details"

}

export const FORM_PLACEHOLDER_TEXTS = {
    NAME: "Type name here...",
    FIRST_NAME: 'Type first name here...',
    LAST_NAME: "Type last name here...",
    JOB_TITLE: 'Type job title here...',
    COMPANY: "Type company name here...",
    EMAIL: 'Type email id here...',
    PASSWORD:"Type password here...",
    LOGIN_EMAIL: "Enter registered email id here...",
    LOGIN_PASSWORD: "Enter password here...",
    NEW_PASSWORD:"Enter new password here..",
    VERFICATION_CODE:"Enter 6-digit verification code here...",
    CONFIRM_PASSWORD: "Re-enter new password here...",
    WEBSITE: "Type company website here...",
    DESIRED_PARTNER: "Select your partner type here...",
    ADD_MORE_DETAILS:"If you have more specific details, please add it here..."
}

export const ERROR_TEXTS = {
    EXCEEDED_CHAR_IN_TEXTAREA: `You have reached your maximum limit (${TEXTAREA_LENGTH.MAXIMUM}) of characters allowed.`,
    MINIMUM_CHAR_IN_TEXTAREA: `This field should have atleast ${TEXTAREA_LENGTH.MINIMUM} characters.`,
    EMPTY_FIELD: "Please fill your valuable feedback",
    INVALID_EMAIL: "Invalid email id",
    SERVICE_REQUEST_EMPTY_FIELD: "This field should not be empty.",
    NOT_REGISTERED : "This email id is not registered in Vivas.AI",
    NOT_SUBSCRIBED : "This email id is not subscribed to Vivas.AI",
    USER_ALREADY_ADDED : "This email address already added to Vivas.AI account",
    NEED_VERIFICATION : 'This email id has to be verified',
    ACCOUNT_ALREADY_ADDED : 'This account name is already added'
}

export const EMPTY_STRING = "";
export const SEPARATORS = {
    COMMA: ',',
    SPACE: ' ',
    BACKSLASH : '/',
    SEMICOLON : ';',
    UNDERSCORE : '_',
    HYPHEN : '-',
    DOUBLE_PIPE : '||',
    PIPE : '|',
    DOT : ".",
    NEXT_LINE : "\n",
    COLON : ':',
    JSON_SEPARATION : '<code-json-string-separation>'
}
export const FILE_TYPES = {
    PDF : '.pdf',
    JPEG : '.jpeg',
    JPG : '.jpg',
    PNG : '.png',
    WEBP : '.webp',
    BMP : '.bmp',
    ICO : ".ico",
    X_ICON : ".x-icon",
    TIFF : ".tiff",
    GIF : ".gif",
    ZIP : ".zip",
    X_ZIP : ".x-zip-compressed",
    TEXT : ".txt",
    PLAIN : ".plain",
    WAV : ".wav",
    MP3 : ".mp3",
    MP4 : ".mp4",
    WEBM : ".webm",
    AVI : ".avi",
    MPEG : ".mpeg",
    JSON : ".json",
    DOCX_MIME : ".vnd.openxmlformats-officedocument.wordprocessingml.document",
    DOCX : ".docx",
    PPTX_MIME : ".vnd.openxmlformats-officedocument.presentationml.presentation",
    PPTX : ".pptx",
    XLSX_MIME : ".vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    XLSX : ".xlsx",
    CSV : ".csv",
    XLS_MIME : ".vnd.ms-excel",
    XLS : ".xls"
}
export const PAYMENT_STATUS = {
    PAYMENT_SUCCESS: 'Success',
    PAYMENT_FAILED: 'Failed'
}

export const HTTP_METHODS = {
    GET : "get",
    PUT : "put",
    POST : "post"
}

export const STATUS = {
    ACTIVE : "Active",
    INACTIVE : "Inactive"
}

export const FAVORITE = {
    QUERYSTRING_KEY : "favorites"
}

export const TOASTER_TYPE = {
    INFO: 'info', 
    SUCCESS: 'success', 
    WARNING: 'warning', 
    ERROR: 'error', 
    DEFAULT: 'default'
}

export const TOASTER_THEME = {
    LIGHT:'light', 
    DARK: 'dark', 
    COLORED: 'colored',
    DEFAULT: 'Default'
}

export const TOASTER_MESSAGE = {
    LOGIN: {
        SUCCESS : "You have been signed in successfully.",
        ERROR : "We couldn't sign you in. Please try again."
    },
    LOGOUT: {
        SUCCESS : "You've been logged out successfully.",
        ERROR : "Error on your logout. Please try again."
    },
    FORGOT_PASSWORD: {
        SUCCESS : "Your password has been reset successfully.",
        ERROR : "We couldn't reset your password. Please try again."
    },
    REGISTERATION: {
        SUCCESS : "You have successfully registered.",
        ERROR : "Sorry, we couldn't register you. Please try again."
    },
    UPDATE_PROFILE: {
        SUCCESS : "Profile update completed successfully.",
        ERROR : "Failed to update the profile. Please try again."
    },
    ENABLE_MFA: {
        SUCCESS : "Multi-Factor Authentication has been enabled successfully.",
        ERROR : "Failed to enable MFA. Please try again."
    },
    DISABLE_MFA: {
        SUCCESS : "Multi-Factor Authentication has been disabled successfully.",
        ERROR : "Failed to disable MFA. Please try again."
    },
    REQUEST_DEMO: {
        SUCCESS : "Demo request recorded successfully.",
        ERROR : "Failed to record demo request. Please try again."
    },
    REQUEST_PARTNER: {
        SUCCESS : "Partner request recorded successfully.",
        ERROR : "Failed to record partner request. Please try again."
    },
    CONTACT_US: {
        SUCCESS : "Thanks for reaching out! We'll get back to you soon.",
        ERROR : "Failed to record your request. Please try again."
    },
    ADD_RATING: {
        SUCCESS : "Rating recorded successfully.",
        ERROR : "Failed to record your rating. Please try again."
    },
    DISCOUNT_REQUEST: {
        SUCCESS : "Your discount request recorded successfully.",
        ERROR : "Failed to record the discount request. Please try again."
    },
    FEEDBACK: {
        SUCCESS : "Feedback recorded successfully.",
        ERROR : "Failed to record the feedback. Please try again."
    },
    CAREER: {
        SUCCESS : "Job application recorded successfully.",
        ERROR : "Failed to record the job application. Please try again."
    },
    SUPPORT: {
        SUCCESS : "Support request recorded successfully.",
        ERROR : "Failed to record the support request. Please try again."
    },
    CREATE_ACC_USER: {
        SUCCESS : "Successfully added the user.",
        ERROR : "Failed to add the user. Please try again."
    },
    EDIT_ACC_USER: {
        SUCCESS : "Successfully updated the user details.",
        ERROR : "Failed to update the user details. Please try again."
    },
    DELETE_ACC_USER: {
        SUCCESS : "Successfully removed the user.",
        ERROR : "Failed to remove the user. Please try again."
    },
    GENERATE_API_KEY: {
        SUCCESS : "API key generated successfully.",
        ERROR : "Failed to generate the API key. Please try again."
    },
    EDIT_API_KEY: {
        SUCCESS : "API key updated successfully.",
        ERROR : "Failed to update the API key. Please try again."
    },
    DELETE_API_KEY: {
        SUCCESS : "The API key deleted successfully.",
        ERROR : "Failed to delete the API key. Please try again."
    },
    UNSUBSCRIPTION:{
        SUCCESS : "The request to unsubscribe from the account has been recorded successfully.",
        ERROR : "Failed to record your request to unsubscribe from the account. Please try again."
    },
    EDIT_ACCOUNT_ADDRESS:{
        SUCCESS : "Billing address updated successfully.",
        ERROR : "Failed to update the billing address. Please try again."
    },
    ADD_TO_FAVORITE:{
        SUCCESS_TEXT1 : "The model ",
        SUCCESS_TEXT2 : " added to favorites successfully.",
        SUCCESS : "The model added to favorites successfully.",
        ERROR_TEXT1 : "Failed to add the model ",
        ERROR_TEXT2 : " to favorites. Please try again.",
        ERROR : "Failed to add the model to favorites. Please try again.",
    },
    REMOVE_FROM_FAVORITE:{
        SUCCESS_TEXT1 : "The model ",
        SUCCESS_TEXT2 : " removed from favorites successfully.",
        SUCCESS : "The model removed from favorites successfully.",
        ERROR_TEXT1 : "Failed to remove the model ",
        ERROR_TEXT2 : " from favorites. Please try again.",
        ERROR : "Failed to remove the model from favorites. Please try again."
    },
    ACTIVATE_MODEL:{
        SUCCESS_TEXT1 : "The model ",
        SUCCESS_TEXT2 : " enabled successfully.",
        SUCCESS : "The model enabled successfully.",
        ERROR_TEXT1 : "Failed to enable the model ",
        ERROR_TEXT2 : " . Please try again.",
        ERROR : "Failed to enable the model. Please try again."
    },
    IN_ACTIVATE_MODEL:{
        SUCCESS_TEXT1 : "The model ",
        SUCCESS_TEXT2 : " disabled successfully.",
        SUCCESS : "The model disabled successfully.",
        ERROR_TEXT1 : "Failed to disable the model ",
        ERROR_TEXT2 : " . Please try again.",
        ERROR : "Failed to disable the model. Please try again."
    },
    ACTIVATE_MODEL_PROVIDER:{
        SUCCESS_TEXT1 : "The model provider ",
        SUCCESS_TEXT2 : " enabled successfully.",
        SUCCESS : "The model provider enabled successfully.",
        ERROR_TEXT1 : "Failed to enable the model provider ",
        ERROR_TEXT2 : " . Please try again.",
        ERROR : "Failed to enable the model provider. Please try again."
    },
    IN_ACTIVATE_MODEL_PROVIDER:{
        SUCCESS_TEXT1 : "The model provider ",
        SUCCESS_TEXT2 : " disabled successfully.",
        SUCCESS : "The model provider disabled successfully.",
        ERROR_TEXT1 : "Failed to disable the model provider ",
        ERROR_TEXT2 : " . Please try again.",
        ERROR : "Failed to disable the model provider. Please try again."
    },
    CREATE_ACCOUNT: {
        SUCCESS_TEXT1 : "Successfully added the account ",
        SUCCESS_TEXT2 : ".",
        SUCCESS : "Successfully added the account.",
        ERROR_TEXT1 : "Failed to add the account ",
        ERROR_TEXT2 : ". Please try again.",
        ERROR : "Failed to add the account. Please try again."
    },
    UPDATE_ACCOUNT: {
        SUCCESS_TEXT1 : "Account ",
        SUCCESS_TEXT2 : " updated successfully.",
        SUCCESS : "Account updated successfully.",
        ERROR_TEXT1 : "Failed to update the account ",
        ERROR_TEXT2 : " . Please try again.",
        ERROR : "Failed to update the account. Please try again."
    }
}