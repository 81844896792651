// modules
import { Component } from "react";
import cx from 'classnames';
import axios from "axios";

//utils
import { BUTTON_ICON_POSITION, ERROR_CODES, EXPIRY_DAY, SORT_TABLE_VALUES, USER_MANAGEMENT, USER_ROLE, 
    EVENT, 
    THEME} from "../../../utils/Constants";
import { isEmptyList, isValidCompanyNameString, isValidJobTitleString, isValidNameString, isValidTextString, 
    isValidWorkEmailString, isEmptyObject } from "../../../utils/UtilityFunctions";
import { ACTIONS, USERS_PANE_INITIAL_STATE, getInitialUserCreation, USERS, handleSuccessVerfication, handleErrorVerification } from "../UserManagement.utils";
import { USER_MNGMENT_STRINGS } from "../../../strings/UserManagement";
import { BUTTON_TEXTS, EMPTY_STRING, SEPARATORS, TOASTER_TYPE, TOASTER_THEME, TOASTER_MESSAGE } from "../../../strings/Strings";
import {
    CREATE_UPDATE_NAMED_USERS, DELETE_NAMED_USER, GET_USER_PROFILE, SERVICE_ACCOUNT_RENEW_SECRET, TRY_FOR_FREE_CREATE_USERS,
} from "../../../urls/Urls";
import { AuditLog } from "../../../utils/AuditUtils";
import { getCognitoUserAccessToken } from "../../sign_up/Cognito.utils";

// classes
import classes from '../UserManagement.module.css';
import globalClasses from '../../../App.module.css'

// component
import ConfirmPopup from "../../../components/confirm_popup/ConfirmPopup";
import Button from "../../../components/ui_elements/button/ButtonNew";
import NoData from "../../../components/no_data/NoData";
import Loader from "../../../components/loader/Loader";

// subcomponents
import AccountCreationPopup from "../AccountCreatePopup";
import UserDetailsViewPopup from "../UserDetailsViewPopup";
import TextField from "../../../components/ui_elements/form_fields/text_input/TextField";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField";
import SortingTable from "../../../components/sorting_table/SortingTable";

// images
import ADD_ICON from "../../../assets/images/user-manage/add-icon.png"
import EDIT_ICON from '../../../assets/images/user-manage/edit-icon.png'
import TRASH_ICON from '../../../assets/images/user-manage/trash-icon.png'
import ADMIN_ICON from '../../../assets/images/user-manage/admin.png';
import USER_ICON from '../../../assets/images/user-manage/user-new.png';
import LoaderGIF from '../../../assets/images/loader/loader-gif.gif';
import { toast } from 'react-toastify';
import { BUTTON_TYPES } from "../../../utils/ButtonConstants";
import { IMAGES } from "../../../utils/ImageConstants";
import { MdGroupAdd } from "react-icons/md";
class UsersPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...USERS_PANE_INITIAL_STATE
        }
    }

    // set loader to the pane and refresh the data
    updatePane = (key, data) => {
        if (document.body.style.overflow !== 'auto') {
            document.body.style.overflow = 'auto'
        }
        this.props.refreshData();
    }

    // set error data for the component
    setError = (errorCode) => {
        // this.props.setErrorData();
        if (errorCode === ERROR_CODES.NOT_FOUND) {
            this.setState({
                isUsersPaneLoading: false,
                isSubmitting: false,
                isSubmitError: true,
                userError: true,
                newUserEmailErrorText: 'This email address is not registered in Vivas.AI'
            })
        } else if (errorCode === ERROR_CODES.CONFLICT) {
            this.setState({
                isUsersPaneLoading: false,
                isSubmitting: false,
                isSubmitError: true,
                userError: true,
                newUserEmailErrorText: 'This email address already added to Vivas.AI account'
            })
        } else {
            if (document.body.style.overflow !== 'auto') {
                document.body.style.overflow = 'auto'
            }
            this.setState({
                ...USERS_PANE_INITIAL_STATE
            })
        }
    }

    // to cancel the edit
    cancelEdit = () => {
        if (this.state.accountEditable) {
            this.setState({
                actionableData: null,
                accountEditable: false,
            })
        }
    }

    // validate before submit
    onSubmitData = (action, detail) => {
        let { adminCount, activeUsersCount } = this.props;
        let { actualActionableData } = this.state;
        console.log("action", this.props)
        if (action === ACTIONS.DELETE) {
            if (detail.userType === USERS.ADMIN
                && Number(adminCount) === 1) {
                return false
            }

            else if ((Number(detail.isActive) === USER_MANAGEMENT.USER_STATUS.ACTIVE) && (Number(activeUsersCount) === 1)) {
                return false
            }
        }
        // if (action === ACTIONS.INSERT) {
        //     if (adminCount == 2 && detail === USERS.ADMIN) {
        //         return false
        //     }
        // }
        if (action === ACTIONS.UPDATE) {
            // if (adminCount == 2 && detail.userType === USERS.ADMIN
            //     && detail.userType !== actualActionableData.userType) {
            //     return false
            // }
            if (Number(adminCount) === 1 && detail.userType === USERS.USER
                && detail.userType !== actualActionableData.userType) {
                return false
            }
        }
        return true
    }

    // on click add user
    onCreateUserClicked = () => {
        this.cancelEdit()
        this.setState({
            showAddUserPopup: true
        }, () => {
            this.props.clearSearch()
        })
    }

    // create new user 
    inviteNewUser = (inviteData, data) => {
        let url = TRY_FOR_FREE_CREATE_USERS
        axios.post(url, inviteData,
            {
                headers:{
                    Authorization: "Bearer "+getCognitoUserAccessToken()
                }
            })
            .then(res => {
                setTimeout(() => this.createNewUser(data), 2000)
            })
            .catch(err => {
                this.setError(err.response.status)
            })
    }
    createNewUser = (data) => {
        //#region - audit log
        let startDateTime = new Date()
                
        // audit log - common properties
        let eventDetails = EVENT.MANAGE_CATEGORY.ADD_USER
        eventDetails = {
            ...eventDetails,
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }

        let additionalDetails = {} //actionable data
        if(data){
            additionalDetails = {
                userRole: data?.user_role,
                email: data?.communication_email
            }
        }
        //#endregion
        let url = CREATE_UPDATE_NAMED_USERS
        axios.post(url, data,
            {
                headers: {
                    Authorization: "Bearer " + getCognitoUserAccessToken()
                }
            })
            .then(res => {
                //audit log - success scenario
                eventDetails = {
                    ...eventDetails,
                    eventStatusCode: res?.status || '200',
                    eventStatus: 'Success',
                    eventMessage: res?.data?.message || res?.statusText || 'Create an new user success'
                }

                //toaster
                toast(TOASTER_MESSAGE.CREATE_ACC_USER.SUCCESS,
                    {
                        type: TOASTER_TYPE.SUCCESS,
                        theme: TOASTER_THEME.LIGHT
                    });

                this.setState({
                    isSubmitting: false,
                    isSubmitError: false
                }, () => {
                    this.updatePane(ACTIONS.INSERT, res.data)
                    this.onCloseAddAccountPopup();
                })
            })
            .catch(err => {
                //audit log - error scenario
                if (err) {
                    eventDetails = {
                        ...eventDetails,
                        eventStatus: 'Failure',
                        eventStatusCode: err?.response?.status || null,
                        eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Create an new user error',
                    }
                }

                //toaster
                toast(TOASTER_MESSAGE.CREATE_ACC_USER.ERROR,
                    {
                        type: TOASTER_TYPE.ERROR,
                        theme: TOASTER_THEME.LIGHT
                    });

                this.setError(err.response.status)
            }).finally(() => {
                //audit log
                this.insertToAuditLog(eventDetails, additionalDetails)
            })
    }

    // called after the successfull validation or payment
    onCreateUserDetails = (inviteData, data) => {
        console.log("data", data)
        let { isRegisteredUser } = this.state;
        this.setState({
            isSubmitting: true
        }, () => {
            if (isRegisteredUser) {
                // data.amount = data.amount.replace('USD ','')
                this.createNewUser(data)
            } else {
                this.inviteNewUser(inviteData, data)
            }
        })
    }

    // on submit the new user or edited user details
    onClickUserCreation = () => {
        let { accountEditable, newUserEmail, newUserCode, newUserRole, newUserTierLevel,
            userError, firstName, lastName, organisationName, jobTitle } = this.state;
        if (accountEditable) {
            this.onClickEditDone();
        } else {
            let isNewUserEmailError = this.validateUserEmail(newUserEmail, true).error;
            if (!isNewUserEmailError) {

                // let isNewUserNameError = this.validateUserName(newUserName, true).error;
                let isFirstNameError = isValidNameString(firstName, true).error;
                let isLstNameError = isValidNameString(lastName, true).error;
                let isJobTitleError = isValidJobTitleString(jobTitle, true).error;
                let isOrganisationNameError = isValidCompanyNameString(organisationName, true).error;
                if (!isFirstNameError && !isLstNameError
                    && !isJobTitleError && !isOrganisationNameError
                    && !userError) {
                    let inviteData = {
                        first_name: firstName, last_name: lastName, job_title: jobTitle,
                        organisation_name: organisationName,
                        communication_email: newUserEmail,
                        accept_market_email: String(true)
                    }
                    let data = {
                        // display_name: newUserName,
                        display_name: firstName + " " + lastName,
                        communication_email: newUserEmail,
                        user_type: Number(newUserCode),
                        user_role: newUserRole,
                        user_tier_level: 6,//Number(newUserTierLevel),
                        user: this.props.username,
                        account_id: String(this.props.accountID),
                    }
                    this.onCreateUserDetails(inviteData, data)
                } else {
                    this.setState({
                        isFirstNameError,
                        isLstNameError, isJobTitleError,
                        isOrganisationNameError,
                        isSubmitError: true
                    })
                }
            } else {
                this.setState({
                    isNewUserEmailError,
                    isSubmitError: true
                })
            }
        }
    }

    // on close/cancel the add/edit user popup
    onCloseAddAccountPopup = () => {
        let { isSubmitting } = this.state;
        if (!isSubmitting) {
            if (document.body.style.overflow === 'hidden') {
                document.body.style.overflow = 'auto'
            }
            this.setState({
                ...getInitialUserCreation()
            })
        }
    }

    // on click edit icon
    onClickEditAccount = (data) => {
        let actionableData = Object.assign({}, data)
        let actualActionableData = Object.assign({}, data)
        this.setState({
            accountEditable: true,
            actionableData,
            showAddUserPopup: true,
            actualActionableData
        }, () => {
            this.props.clearSearch()
        })
    }


    // on click submit after editing the user details
    onClickEditDone = () => {

        let { actionableData } = this.state

        let url = CREATE_UPDATE_NAMED_USERS
        let data = {
            display_name: actionableData.userName,
            communication_email: actionableData.email,
            user_type: actionableData.userCode,
            user_role: actionableData.userRole,
            user_tier_level: 6,//Number(actionableData.tier),
            user: this.props.username,
            account_id: String(this.props.accountID),
            client_id: actionableData.clientID,
        }

        let userTypeError = this.validateUserType(actionableData.userType, true).error;

        //#region - audit log
        let startDateTime = new Date()
                
        // audit log - common properties
        let eventDetails = EVENT.MANAGE_CATEGORY.EDIT_USER
        eventDetails = {
            ...eventDetails,
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }

        //user to delete
        let additionalDetails = {
            accountId: data?.account_id,
            clientId: data?.client_id,
            email: data?.communication_email
        }
        //#endregion

        if (!userTypeError) {
            this.setState({
                isSubmitting: true
            }, () => {
                axios.post(url, data,
                    {
                        headers: {
                            Authorization: "Bearer " + getCognitoUserAccessToken()
                        }
                    })
                    .then(res => {
                        //audit log - success scenario
                        eventDetails = {
                            ...eventDetails,
                            eventStatusCode: res?.status || '200',
                            eventStatus: 'Success',
                            eventMessage: res?.data?.message || res?.statusText || 'Edit user success'
                        }

                        //toaster
                        toast(TOASTER_MESSAGE.EDIT_ACC_USER.SUCCESS,
                            {
                                type: TOASTER_TYPE.SUCCESS,
                                theme: TOASTER_THEME.LIGHT
                            });
                        this.updatePane(ACTIONS.UPDATE, actionableData);
                    })
                    .then(() => {
                        this.setState({
                            isUsersPaneLoading: false,
                            showAddUserPopup: false,
                            isSubmitting: false,
                            isSubmitError: false
                        }, () => {
                            this.cancelEdit();
                            this.onCloseAddAccountPopup()
                        })
                    })
                    .catch(err => {
                        this.setError(err.response.status)
                        //audit log - error scenario
                        if(err){
                            eventDetails = {...eventDetails,
                                eventStatus: 'Failure',
                                eventStatusCode: err?.response?.status || null,
                                eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Edit user details error',
                            }
                        }

                        //toaster
                        toast(TOASTER_MESSAGE.EDIT_ACC_USER.ERROR,
                            {
                                type: TOASTER_TYPE.ERROR,
                                theme: TOASTER_THEME.LIGHT
                            });
                    }).finally(() => {
                        //audit log
                        this.insertToAuditLog(eventDetails, additionalDetails)
                    })
            })
        } else {
            this.setState({
                userTypeError,
                isSubmitError: true
            })
        }
    }

    validateUserType = (userType, isMandatory) => {
        let { adminCount } = this.props;
        let { actionableData, accountEditable } = this.state;
        let error = false, text = EMPTY_STRING
        if (accountEditable) {
            if (this.onSubmitData(ACTIONS.UPDATE, actionableData)) {
                error = false;
                text = EMPTY_STRING;
            } else {
                if (adminCount === 2 && userType === USERS.ADMIN) {
                    error = true;
                    text = 'Only two admins are allowed';
                } else {
                    error = true;
                    text = 'Atleast one admin must exist';
                }
            }
        }
        return { error, text }
    }

    // on change usertype in edit mode
    onChangeEditableUserType = (userType) => {
        let { actionableData } = this.state;
        actionableData['userType'] = userType;
        actionableData['userCode'] = (userType === USERS.ADMIN) ? USERS.ADMIN_USER.TYPE : USERS.NORMAL_USER.TYPE;
        actionableData['userRole'] = (userType === USERS.ADMIN )? USERS.ADMIN_USER.ROLE : USERS.NORMAL_USER.ROLE;
        this.setState({
            actionableData,
            userTypeError: false
        })
    }

    // on change tier in edit mode
    onChangeEditableTierLevel = (tierLevel) => {
        let { actionableData } = this.state;
        actionableData['tier'] = tierLevel
        this.setState({
            actionableData
        })
    }

    onClickUserDetailView = (detail) => {
        this.setState({
            showUserDetailPopup: true,
            actionableData: detail
        })
    }

    onCloseUserDetailView = () => {
        this.setState({
            showUserDetailPopup: false,
            actionableData: null
        })
    }

    // on click renew
    onClickRefreshSecret = (detail) => {
        let url = SERVICE_ACCOUNT_RENEW_SECRET
        let data = {
            client_id: detail.clientID,
            user: this.props.username
        }
        // this.setState({
        //     isUsersPaneLoading: true
        // }, () => {
        axios.post(url, data,
            {
                headers: {
                    Authorization: "Bearer " + getCognitoUserAccessToken()
                }
            })
            .then(res => {
                this.updatePane(ACTIONS.RENEW, res.data)
            })
            .then(() => {
                this.setState({
                    isUsersPaneLoading: false
                })
            })
            .catch(err => {
                this.setError(err.response.status)
            })
        // })
    }

    // on click delete icon
    onClickDeleteAccount = (detail) => {
        this.cancelEdit()
        this.props.clearSearch()
        if (this.onSubmitData(ACTIONS.DELETE, detail)) {
            this.setState({
                showDeletePopup: true,
                actionableData: detail,
                errorDelete: false
            })
        } else {
            this.setState({
                showDeletePopup: true,
                actionableData: null,
                errorDelete: true
            })
        }
    }

    // on click delete button in popup
    onConfirmDelete = () => {
        let { actionableData } = this.state
        let url = DELETE_NAMED_USER
        let data = {
            communication_email: actionableData.email,
            client_id: actionableData.clientID,
            user: this.props.username
        }

        //#region - audit log
        let startDateTime = new Date()

        // audit log - common properties
        let eventDetails = EVENT.MANAGE_CATEGORY.DELETE_USER
        eventDetails = {
            ...eventDetails,
            eventCategory: EVENT.MANAGE_CATEGORY.CATEGORY_NAME,
            eventStartDateTime: startDateTime
        }
        //user to delete
        let additionalDetails = {
            email: data?.communication_email,
            clientId: data?.client_id,
        }
        //#endregion

        this.setState({
            isDeleting: true,
            // isUsersPaneLoading: true
        }, () => {
            axios.post(url, data,
                {
                    headers: {
                        Authorization: "Bearer " + getCognitoUserAccessToken()
                    }
                })
                .then(res => {
                    //audit log - success scenario
                    eventDetails = {
                        ...eventDetails,
                        eventStatusCode: res?.status || '200',
                        eventStatus: 'Success',
                        eventMessage: res?.data?.message || res?.statusText || 'Remove user success'
                    }
                     //toaster
                     toast(TOASTER_MESSAGE.DELETE_ACC_USER.SUCCESS,
                        {
                            type: TOASTER_TYPE.SUCCESS,
                            theme: TOASTER_THEME.LIGHT
                        });
                    this.updatePane(ACTIONS.DELETE, actionableData)
                    this.onCloseDeletePopup();
                })
                .catch(err => {
                    this.setError(err.response.status)

                    //audit log - error scenario
                    if (err) {
                        eventDetails = {
                            ...eventDetails,
                            eventStatus: 'Failure',
                            eventStatusCode: err?.response?.status || null,
                            eventMessage: err?.response?.data?.message || err?.message || err?.response?.statusText || 'Remove user error',
                        }
                    }

                    //toaster
                    toast(TOASTER_MESSAGE.DELETE_ACC_USER.ERROR,
                        {
                            type: TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });

                })
                .finally(() => {
                    //audit log
                    this.insertToAuditLog(eventDetails, additionalDetails)
                })
        })
    }

    // on cancel/close delete popup
    onCloseDeletePopup = () => {
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'auto'
        }
        this.setState({
            showDeletePopup: false,
            isDeleting: false,
            isUsersPaneLoading: false,
        })
    }

    // on change name in add user popup
    onChangeNewUserName = (username) => {
        this.setState({
            newUserName: username,
            isNewUserNameError: false
        })
    }

    validateUserName = (userName, isMandatory) => {
        let { usersNameList } = this.props;
        let validateResult = isValidTextString(userName, isMandatory);
        let error = validateResult.error;
        let text = validateResult.text;
        if (!error) {
            let userNameIndex = usersNameList.findIndex(item => item.toLowerCase() === userName.toLowerCase())
            if (userNameIndex > -1) {
                error = true;
                text = 'This user name is already added'
            }
        }
        return { error, text }
    }

    // on change mail in add user popup
    onChangeNewUserMail = (usermail) => {
        this.setState({
            newUserEmail: usermail,
            isNewUserEmailError: false
        })
    }
    validateUserEmail = (email, isMandatory) => {
        let { isEmailVerified } = this.state;
        let validateResult = isValidWorkEmailString(email, isMandatory);
        let error = validateResult.error;
        let text = validateResult.text;
        if (!error) {
            if (!isEmailVerified) {
                error = true;
                text = 'This email id has to be verified'
            }
        }
        return { error, text }
    }

    // on change usertype in add/edit user popup
    onChangeNewUserType = (userType) => {
        let { accountEditable } = this.state;
        if (accountEditable) {
            this.onChangeEditableUserType(userType)
        } else {
            let newUserCode, newUserRole, newUserType;
            if (userType === USERS.ADMIN) {
                newUserCode = USERS.ADMIN_USER.TYPE;
                newUserRole = USERS.ADMIN_USER.ROLE;
                newUserType = USERS.ADMIN;
            } else {
                newUserCode = USERS.NORMAL_USER.TYPE;
                newUserRole = USERS.NORMAL_USER.ROLE;
                newUserType = USERS.USER;
            }
            this.setState({
                newUserCode, newUserRole, newUserType
            }, () => {
                if (this.onSubmitData(ACTIONS.INSERT, userType)) {
                    this.setState({
                        userTypeError: false,
                        userTypeErrorText: EMPTY_STRING
                    })
                } else {
                    this.setState({
                        userTypeError: true,
                        userTypeErrorText: 'Only two admins are allowed'
                    })
                }
            })
        }
    }

    // on change tierlevel in add/edit user popup
    onChangeNewUserTierLevel = (tierLevel) => {
        let { accountEditable } = this.state;
        if (accountEditable) {
            this.onChangeEditableTierLevel(tierLevel)
        } else {
            this.setState({
                newUserTierLevel: tierLevel
            })
        }
    }

    // on click change email
    onClickChangeEmail = () => {
        this.setState({
            ...getInitialUserCreation(),
            showAddUserPopup: true
        })
    }

    // on click verify email
    onClickVerify = () => {
        let { newUserEmail } = this.state;
        let isNewUserEmailError = isValidWorkEmailString(newUserEmail, true).error;
        if (!isNewUserEmailError) {
            this.setState({
                isVerifying: true,
                isNewUserEmailError
            }, () => {
                axios.post(GET_USER_PROFILE, { user_id: newUserEmail },
                    {
                        headers:{
                            Authorization: "Bearer "+getCognitoUserAccessToken()
                        }
                    })
                    .then((res) => {
                        this.setState({
                            ...handleSuccessVerfication(res),
                            isSubmitError: false
                        })
                    }).catch(err => {
                        if (err.response.status === ERROR_CODES.NOT_FOUND) {
                            this.setState({
                                isEmailVerified: true,
                                isRegisteredUser: false,
                                isNewUserEmailError: false,                        // create user using credits available in the account

                            })
                        } else {
                            this.setState({
                                ...handleErrorVerification()
                            })
                        }
                    })
            })
        } else {
            this.setState({
                isNewUserEmailError,
                isSubmitError: true
            })
        }
    }

    onChangeOrganisationName = (orgname) => {
        this.setState({
            organisationName: orgname,
            isOrganisationNameError: false
        })
    }

    onChangeFirstName = (name) => {
        this.setState({
            firstName: name,
            isFirstNameError: false
        })
    }

    onChangeLastName = (name) => {
        this.setState({
            lastName: name,
            isLstNameError: false
        })
    }

    onChangeJobTitle = (jobtitle) => {
        this.setState({
            jobTitle: jobtitle,
            isJobTitleError: false
        })
    }

    //audit log
    insertToAuditLog = (eventDetails, additionalDetails = {}) => {
        console.log('Props: ', this.props)
        let { userDetails, accountUUID } = this.props
        let accountDetails = {}                  
        let eventAttributes = {}
        let user = {}

        if (accountUUID) {
            accountDetails = {accountUUID: accountUUID}
        }

        if (!isEmptyObject(userDetails)) {
            user = {
                awsId: userDetails?.awsId,
                email: userDetails?.email,
            }
        }

        eventAttributes = {...user, additionalDetails}
        AuditLog(eventDetails, eventAttributes, accountDetails)
    }

    render() {
        let { users, userRole, adminCount, activeUsersCount, accountIsActive, userDetails, accountUUID } = this.props;
        let { showAddUserPopup, showDeletePopup, newUserEmail, isNewUserEmailError, newUserEmailErrorText, actionableData,
            newUserTierLevel, newUserType, accountEditable, isSubmitting, isDeleting, isUsersPaneLoading,
            showUserDetailPopup, errorDelete, userTypeError, isEmailVerified,
            isVerifying, firstName, lastName, organisationName, jobTitle, isRegisteredUser,
            isOrganisationNameError, isFirstNameError, isJobTitleError, isLstNameError,
            isSubmitError, userError } = this.state;

        let addUserPopupComponent = null, deleteAccountPopupComponent = null, viewDetailPopup = null;
        let usersComponent = null;
        let loaderComponent = null;
        let buttonAndFilterComponent = null;
        let filterComponent = null;
        let addUserButton = null;
        let verifyButtonComponent = null;

        if (userRole !== USER_ROLE.SUBSCRIBED.LABEL
            // && !SUBSCRIPTION_TYPE.TRIAL.includes(subscriptionType)
            && accountIsActive === USER_MANAGEMENT.USER_STATUS.ACTIVE) {
            addUserButton = <Button
                label={"Add User"}
                containerClass={"ms-auto col-12 col-sm-6 col-md-4 col-lg-2"}
                icon={<MdGroupAdd className={"me-2"} size={20} />}
                onClickHandler={this.onCreateUserClicked}
                actionType={BUTTON_TYPES.ACTIONS.PRIMARY}
                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                borderType={BUTTON_TYPES.BORDER.NO_BORDER}
            />
        }

        if (isUsersPaneLoading) {
            loaderComponent = (
                <Loader
                    containerClass={classes.Loader}
                />
            )
        }
        if (showAddUserPopup) {
            if (!isEmailVerified && !accountEditable) {
                if (!isVerifying) {
                    verifyButtonComponent = null;
                } else {
                    verifyButtonComponent = (
                        <span className='d-flex align-items-center justify-content-end ms-auto mb-2'>
                            <img className={globalClasses.LoaderExtraSmallSize} src={LoaderGIF} alt={'Verify'} />
                            <span className={globalClasses.Font16LoadText}>{'Verifying'}</span>
                        </span>
                    )
                }
            } else if (isEmailVerified && !accountEditable) {
                verifyButtonComponent = (
                    <Button
                        label={'Change Email ID'}
                        containerClass={"ms-auto"}
                        className={"py-1 mb-3"}
                        onClickHandler={this.onClickChangeEmail}
                        actionType={BUTTON_TYPES.ACTIONS.SECONDARY}
                        radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                        borderType={BUTTON_TYPES.BORDER.NO_BORDER}
                        disabled={isSubmitting}
                    />
                )
            }

            let title = '';
            if (accountEditable) {
                title = USER_MNGMENT_STRINGS.USER_PANE.EDIT_USER

            } else {
                title = USER_MNGMENT_STRINGS.USER_PANE.ADD_NEW_USER
            }
            addUserPopupComponent = (
                <AccountCreationPopup
                    title={title}
                    onCloseHandler={this.onCloseAddAccountPopup}
                    onSubmitHandler={this.onClickUserCreation}
                    isSubmitting={isSubmitting}
                    submittingText={USER_MNGMENT_STRINGS.SUBMITTING}
                    submitButtonText={BUTTON_TEXTS.SUBMIT}
                    disableSubmitButton={!isEmailVerified && !accountEditable}
                >
                    <div className="d-flex flex-wrap">
                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="usermail">
                            {USER_MNGMENT_STRINGS.USER_PANE.USER_MAIL_LABEL}
                        </label>
                        <div className="col-12 col-lg-8">
                            <TextField
                                containerClass='col-12'
                                value={accountEditable ? actionableData.email : newUserEmail}
                                id={'usermail'}
                                placeHolder={'Email ID'}
                                onChangeHandler={this.onChangeNewUserMail}
                                onBlurHandler={this.onClickVerify}
                                validate={this.validateUserEmail}
                                isDisabled={isSubmitting || accountEditable || isEmailVerified}
                                isReadOnly={isSubmitting || accountEditable || isEmailVerified}
                                isError={isSubmitError}
                                isFieldError={isNewUserEmailError}
                                isMandatory={true}
                                isNumber={false}
                                enableVerification={true}
                                isVerified={isEmailVerified}
                                errorField={userError}
                                errorFieldText={newUserEmailErrorText}
                            />
                        </div>
                        {verifyButtonComponent}
                    </div>
                    {
                        (isEmailVerified || accountEditable) &&
                        <>
                            {
                                !accountEditable &&
                                <>
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="firstname">
                                            {"First Name"}
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={firstName}
                                                id={'firstname'}
                                                placeHolder={'First name'}
                                                onChangeHandler={this.onChangeFirstName}
                                                validate={isValidNameString}
                                                isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isError={isSubmitError && isEmailVerified}
                                                isFieldError={isFirstNameError}
                                                isMandatory={true}
                                                isNumber={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="lastname">
                                            {"Last Name"}
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={lastName}
                                                id={'lastname'}
                                                placeHolder={'Last name'}
                                                onChangeHandler={this.onChangeLastName}
                                                validate={isValidNameString}
                                                isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isError={isSubmitError && isEmailVerified}
                                                isFieldError={isLstNameError}
                                                isMandatory={true}
                                                isNumber={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="jobtitle">
                                            {"Job Title"}
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={jobTitle}
                                                id={'jobtitle'}
                                                placeHolder={'Job title'}
                                                onChangeHandler={this.onChangeJobTitle}
                                                validate={isValidJobTitleString}
                                                isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isError={isSubmitError && isEmailVerified}
                                                isFieldError={isJobTitleError}
                                                isMandatory={true}
                                                isNumber={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="organisationname">
                                            {"Organisation Name"}
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={organisationName}
                                                id={'organisationname'}
                                                placeHolder={'Organisation name'}
                                                onChangeHandler={this.onChangeOrganisationName}
                                                validate={isValidCompanyNameString}
                                                isDisabled={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isReadOnly={isSubmitting || isRegisteredUser || !isEmailVerified}
                                                isError={isSubmitError && isEmailVerified}
                                                isFieldError={isOrganisationNameError}
                                                isMandatory={true}
                                                isNumber={false}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="d-flex flex-wrap">
                                <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="usetype">
                                    {USER_MNGMENT_STRINGS.USER_PANE.USER_TYPE_LABEL}
                                </label>
                                <div className="col-12 col-lg-8">
                                    <SelectField
                                        containerClass={'col-12'}
                                        value={accountEditable ? actionableData.userType : newUserType}
                                        onChangeHandler={this.onChangeNewUserType}
                                        validate={this.validateUserType}
                                        isError={isSubmitError}
                                        isFieldError={userTypeError}
                                        isDisabled={isSubmitting}
                                        isReadOnly={isSubmitting}
                                        id={"usertype"}
                                        isSearchable={false}
                                        optionsList={[
                                            {
                                                value : USERS.ADMIN,
                                                label : USERS.ADMIN,
                                                icon : ADMIN_ICON
                                            },
                                            {
                                                value : USERS.USER,
                                                label : USERS.USER,
                                                icon : USER_ICON
                                            }
                                        ]}
                                        isMandatory={true}
                                    />
                                </div>
                            </div>
                            {/* <div className="d-flex flex-wrap">
                                <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="tierlevel">
                                    {USER_MNGMENT_STRINGS.TIER_LEVEL_LABEL}
                                </label>
                                <div className="col-12 col-lg-8">
                                    <SelectField
                                        containerClass={'col-12'}
                                        value={accountEditable ? actionableData.tier : newUserTierLevel}
                                        onChangeHandler={this.onChangeNewUserTierLevel}
                                        isDisabled={isSubmitting}
                                        isReadOnly={isSubmitting}
                                        isSearchable={false}
                                        id={"tierlevel"}
                                        menuPlacement="bottom"
                                        optionsList={['1', '2', '3', '4', '5', '6']}
                                        isMandatory={true}
                                        menuRootElement={document.body}
                                    />
                                </div>
                            </div> */}
                        </>
                    }
                </AccountCreationPopup>
            )
        }

        if (isEmptyList(users)) {
            usersComponent = (
                <NoData
                    mainText={USER_MNGMENT_STRINGS.USER_PANE.NO_USERS}
                    subText={USER_MNGMENT_STRINGS.USER_PANE.NO_USERS_SUB_TEXT}
                />
            )
        } else {
            if (showUserDetailPopup) {
                viewDetailPopup = (
                    <UserDetailsViewPopup
                        userRole={this.props.userRole}
                        userDetail={actionableData}
                        onCloseHandler={this.onCloseUserDetailView}
                        onRenewClientSecret={() => this.onClickRefreshSecret(actionableData)}
                        isLoading={isUsersPaneLoading}
                    />
                )
            }
            if (showDeletePopup) {
                deleteAccountPopupComponent = (
                    <ConfirmPopup
                        popupClass={globalClasses.PopupBack}
                        isSubmitting={isDeleting}
                        submittingText={USER_MNGMENT_STRINGS.DELETING}
                        modalClass={'col-11 col-md-8 col-lg-6 ps-3 ps-md-4'}
                        title={errorDelete ? "Can't remove" : USER_MNGMENT_STRINGS.USER_PANE.DELETE_POPUP_TITLE}
                        subtext={errorDelete ? ((Number(activeUsersCount) === 1 && adminCount > 1) ? "Atleast an active user must exist." : "Atleast an admin must exist.")
                            : USER_MNGMENT_STRINGS.USER_PANE.DELETE_POPUP_SUBTEXT}
                        primaryButtonText={errorDelete ? 'OK' : BUTTON_TEXTS.REMOVE}
                        hideSecondaryButton={errorDelete}
                        secondaryButtonText={BUTTON_TEXTS.CANCEL}
                        onClickPrimaryButton={errorDelete ? this.onCloseDeletePopup : this.onConfirmDelete}
                        onClickSecondaryButton={this.onCloseDeletePopup}
                        isPopupFixed={true}
                        enableScroll={false}
                        onCloseModalCard={this.onCloseDeletePopup}
                        theme={THEME.LIGHT_BLUE_SHADES}
                    />
                )
            }

            usersComponent = (
                <>
                    {deleteAccountPopupComponent}
                    {viewDetailPopup}
                    <SortingTable
                        enableExports={true}
                        exportTableName={[accountUUID,"Users"].join(SEPARATORS.UNDERSCORE)}
                        exportKeysList={
                            [
                                {
                                    key : "userName",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.USERNAME
                                },
                                {
                                    key : "email",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.EMAIL
                                },
                                {
                                    key : "userType",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.USERTYPE
                                }
                                // ,
                                // {
                                //     key : "tier",
                                //     label : USER_MNGMENT_STRINGS.USER_PANE.TIER_LEVEL_LABEL
                                // }
                            ]
                        }
                        enableSearch={true}
                        searchKeysList={
                            [
                                {
                                    key : "userName",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.USERNAME,
                                    value : USER_MNGMENT_STRINGS.USER_PANE.USERNAME,
                                    placeholder : "Search by "+USER_MNGMENT_STRINGS.USER_PANE.USERNAME
                                },
                                {
                                    key : "email",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.EMAIL,
                                    value : USER_MNGMENT_STRINGS.USER_PANE.EMAIL,
                                    placeholder : "Search by "+USER_MNGMENT_STRINGS.USER_PANE.EMAIL
                                },
                                {
                                    key : "userType",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.USERTYPE,
                                    value : USER_MNGMENT_STRINGS.USER_PANE.USERTYPE,
                                    placeholder : "Search by "+USER_MNGMENT_STRINGS.USER_PANE.USERTYPE
                                }
                                // ,
                                // {
                                //     key : "tier",
                                //     label : USER_MNGMENT_STRINGS.USER_PANE.TIER_LEVEL_LABEL,
                                //     value : USER_MNGMENT_STRINGS.USER_PANE.TIER_LEVEL_LABEL,
                                //     placeholder : "Search by "+USER_MNGMENT_STRINGS.USER_PANE.TIER_LEVEL_LABEL,
                                //     isNumber : true
                                // }
                            ]
                        }
                        headingList={
                            [
                                {
                                    key : 'userName',
                                    label : USER_MNGMENT_STRINGS.USER_PANE.USERNAME,
                                    isDate : false,
                                    renderItem : (item) => {
                                        return(
                                            <span
                                                style={{ cursor: 'pointer', color: '#2159C9', width: 'fit-content' }}
                                                onClick={() => this.onClickUserDetailView(item)}
                                                className="mb-0">
                                                {item.userName}
                                            </span>
                                        )
                                    }
                                },
                                {
                                    key : "email",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.EMAIL,
                                    isDate : false
                                },
                                {
                                    key : "userType",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.USERTYPE,
                                    isDate : false,
                                    renderItem : (item) => {
                                        return(
                                            <div className="d-flex align-items-center">
                                                {
                                                    item.userType === USERS.ADMIN ?
                                                        <img
                                                            className={cx(['mx-2', classes.Icon])}
                                                            src={ADMIN_ICON} alt={USERS.ADMIN}
                                                            title={USERS.ADMIN}
                                                            style={{ cursor: 'default' }}
                                                        /> : <img
                                                            className={cx(['mx-2', classes.Icon])}
                                                            src={USER_ICON} alt={USERS.USER}
                                                            title={USERS.USER}
                                                            style={{ cursor: 'default' }}
                                                        />
                                                }
                                                <span>{item.userType}</span>
                                            </div>
                                        )
                                    }
                                },
                                // {
                                //     key : "tier",
                                //     label : USER_MNGMENT_STRINGS.USER_PANE.TIER_LEVEL_LABEL,
                                //     isDate : false
                                // },
                                {
                                    key : "actions",
                                    label : USER_MNGMENT_STRINGS.USER_PANE.ACTIONS,
                                    isDate : false,
                                    unsortable : true,
                                    hide: userRole === USER_ROLE.SUBSCRIBED.LABEL,
                                    renderItem : (item) => {
                                        return (
                                            <div className="d-flex align-items-center">
                                                {
                                                    (accountIsActive === USER_MANAGEMENT.USER_STATUS.ACTIVE) &&
                                                    <img
                                                        className={cx(['mx-2', classes.ActionIconBlue])}
                                                        src={IMAGES.ACTIONS.EDIT_ICON_BLUE} alt={'Edit'}
                                                        title={USER_MNGMENT_STRINGS.USER_PANE.TOOLTIP_EDIT_USER}
                                                        onClick={() => this.onClickEditAccount(item)}
                                                    />
                                                }
                                                <img
                                                    className={cx(['mx-2', classes.ActionIconBlue, classes.RefreshIcon])}
                                                    src={IMAGES.ACTIONS.DELETE_ICON_BLUE} alt={'Remove'}
                                                    title={USER_MNGMENT_STRINGS.USER_PANE.TOOLTIP_DELETE_USER}
                                                    onClick={() => this.onClickDeleteAccount(item)}
                                                />
                                            </div>
                                        )
                                    }
                        
                                }
                            ]
                        }
                        containerClass={"mt-3"}
                        dataList={users}
                        itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.USERS}
                    />
                </>
            )
        }

        buttonAndFilterComponent = (
            <div className="d-flex flex-wrap col-12">
                {filterComponent}
                {addUserButton}
            </div>
        )
        return (
            <>
                {loaderComponent}
                {buttonAndFilterComponent}
                {addUserPopupComponent}
                {usersComponent}
            </>
        )
    }
}

export default UsersPane;