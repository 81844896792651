import { isEmptyObject } from "../../../utils/UtilityFunctions";

function Badge(props) {
    let {label, badgeColor, fontColor, customStyle}=props;
    let badgeStyle = {
        backgroundColor: badgeColor || '#d2d2d2',
        color: fontColor || '#000000',
        fontSize: '12px',
        fontWeight: '500',
        padding: '3px 15px',
        borderRadius: '50px',
    }
    if(!isEmptyObject(customStyle)){
        badgeStyle = {
            ...customStyle,
            padding: '3px 15px',
            borderRadius: '50px',
        }
    }
    return(
        <span style={badgeStyle}>{label}</span>
    )
}

export default Badge;