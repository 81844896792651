import cx from 'classnames';
import globalClasses from "../../../App.module.css"
import { useState } from "react";
import { isEmpty } from '../../../utils/UtilityFunctions';

function ReadMoreText(props) {
    let {query, maxCharacter}=props;

    let [showMore, setShowMore] = useState(false);

    if(isEmpty(maxCharacter)){
        maxCharacter = 40
    }

    if ((query).length < maxCharacter) {
        return query
    }

    else {
        let firsthalf = query.substr(0, maxCharacter);
        let secondhalf = query.substr(maxCharacter, (query).length);
        const onClickViewHandler = (query) => {
            setShowMore(!showMore)
        }
        return <span style={{whiteSpace:"pre-wrap"}}>{firsthalf + ((showMore) ? secondhalf : "")}<label className={cx(["text-primary", globalClasses.PointerCursor])} onClick={() => onClickViewHandler(query)}>{(showMore) ? " Read less" : "...Read more"}</label></span>
    }
}

export default ReadMoreText;