
import React, { Component } from 'react';
import Main from './Main'
import { UserContext } from './UserContext'
import axios from '../axios/Axios';
import { FILE_DOWNLOAD_FROM_S3, GET_USER_PROFILE } from '../urls/Urls'
import { isEmpty, isEmptyList, isEmptyObject, getUTCTime } from '../utils/UtilityFunctions';
import Loader from '../components/loader/Loader';
import UserPool from '../containers/sign_up/UserPool';
import { getCognitoUserAccessToken } from '../containers/sign_up/Cognito.utils';
import { EVENT } from "../../src/utils/Constants"
import { AuditLog } from  "../../src/utils/AuditUtils"
class UserContextProvider extends Component {

    state = {
        user: {},
        isLoading: true,
        isUserFetched: false
    };

    getUserAccountDetails = (user) => {

        let accessToken = getCognitoUserAccessToken()
        if (!isEmpty(accessToken)) {
            
            //audit log
            let startDateTime = new Date()
            
            //audit log - common properties
            let eventDetails = EVENT.AUTHENTICATION_CATEGORY.LOGIN
            eventDetails = {...eventDetails, 
                eventCategory: EVENT.AUTHENTICATION_CATEGORY.CATEGORY_NAME,
                eventStartDateTime: startDateTime
            }

            axios.get(GET_USER_PROFILE,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken
                    }
                })
                .then((res) => {
                    let userData = res.data
                    this.setState({
                        user: { ...userData }
                    },() => {
                        if(res?.data && res.data?.user && res.data.user?.picture){
                            if(!isEmpty(res.data.user.picture)){
                                let downloadUrl = FILE_DOWNLOAD_FROM_S3
                                let downloadReqBody = {
                                    bucketType: "Private",
                                    objectID: res.data.user.picture
                                }
                                axios.post(
                                    downloadUrl,
                                    downloadReqBody,
                                    {headers:{"Authorization" : "Bearer "+getCognitoUserAccessToken()}}
                                ).then((downloadRes) => {
                                    userData.user["picture"] = downloadRes.data.URL;
                                    this.setState({
                                        user: { ...userData }
                                    })
                                }).catch(err => {
                                    this.setState({
                                        user: { ...userData }
                                    })
                                })
                            }
                        }  
                    })
                    
                    //audit log - success scenario
                    if (res) {
                        eventDetails = { ...eventDetails,
                            eventStatusCode: res?.status || '200',
                            eventStatus: 'Success',
                            eventMessage: res?.data?.message || res?.statusText || 'Login refresh to get userprofile on successful authentication'
                        }    
                    }
                    
                }).catch(error => {
                    let user = UserPool.getCurrentUser()
                    //audit log - error scenario
                    eventDetails = {...eventDetails,
                        eventStatus: 'Failure'
                    }

                    if (error.response) {
                        eventDetails = {...eventDetails,
                            eventStatusCode: error?.response?.status || null,
                            eventMessage: error?.response?.data?.message || error?.message || error?.response?.statusText || 'Login refresh to get userprofile error',
                        }
                    }
                    
                    if (user) {
                        user.signOut()
                        window.location.reload()
                    }
                }).finally(() => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        //audit log
                        const { user } = this.state
                        let accountDetails = {}
                        let userDetails = {}                        
                        let eventAttributes = {}
                        
                        if (!isEmptyObject(user) && isEmpty(window.history.state)) {
                            accountDetails = (!isEmptyList(user.accountDetail)) ? user.accountDetail[0] : {}
                            userDetails = (!isEmptyObject(user.user)) ? user.user : {}
                        
                            if (userDetails) {
                                eventAttributes = {
                                    awsId: userDetails?.awsId,
                                    email: userDetails?.email,
                                }
                            }
                        }
                        AuditLog(eventDetails, eventAttributes, accountDetails)
                    })
                })
        } else {
            let user = UserPool.getCurrentUser()
            if (user) {
                user.signOut()
                window.location.reload()
            }
        }
    }

    getUserDetails = async () => {
        let currentUser = UserPool.getCurrentUser()

        if (!isEmpty(currentUser)) {

            this.getUserAccountDetails(currentUser.username)

        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    componentDidMount() {
        let { user } = this.state
        console.log("user context did mount", user)

        this.getUserDetails()
    }


    componentDidUpdate(prevProps, prevState) {
        console.log("user context did update")

        if (prevProps.user !== this.props.user) {
            this.getUserDetails()
        }
    }


    render() {
        let { isLoading, user } = this.state
        let mainComponentContainer = <Loader />
        if (!isLoading) {
            mainComponentContainer =
                <UserContext.Provider value={user}>
                    <Main />
                </UserContext.Provider>
        }
        return (
            <>
                {mainComponentContainer}
            </>


        );
    }
}
export default UserContextProvider;