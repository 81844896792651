import LoaderGIF from '../../assets/images/loader/loader-gif.gif';
import cx from 'classnames';
import classes from './Loader.module.css';

function Loader(props){
    let {loadingText, loadingTextStyle, loaderStyle, containerClass}=props;
    let containerStyle = cx([containerClass,classes.ContainerStyle])
    let loaderImageStyle = loaderStyle? loaderStyle : classes.LoaderImageStyle
    return (
        <div className={containerStyle}>
            <img className={loaderImageStyle} src={LoaderGIF} alt={'Loading'}/>
            <span className={loadingTextStyle}>{loadingText}</span>
        </div>
    )
}

export default Loader;