import globalClasses from '../../App.module.css';

import { SOMETHING_WRONG } from "../../strings/Strings";
import ErrorImage from '../../assets/images/error.png';

import classes from './ErrorPage.module.css';
import cx from 'classnames';

function ErrorPage() {
    return (
        <div
            className={cx(["d-flex flex-column align-items-center", classes.Container])}

        >
            <img className={classes.Image} src={ErrorImage} alt={'ErrorImage.png'} />
            <span className={cx(globalClasses.Font36BoldGrey44, classes.HeadingText)}>
                {SOMETHING_WRONG.SOMETHINGWENTWRONG}
            </span>
            <span className={cx([globalClasses.Font18Grey44, classes.SubText])}>
                {SOMETHING_WRONG.SOMETHINGWENTWRONGTEXT}
            </span>
            {/* <Button
                label = {"Refresh Page"}
                onClickHandler = {reloadpage}
            /> */}
        </div>
    )
}
export default ErrorPage;