import { createRef } from "react";
import cx from 'classnames';

import Card from "../ui_elements/card/Card";
import CustomCarousel from "../ui_elements/custom_carousel/CustomCarousel";

import classes from './MarketingLayout.module.css';
import globalClasses from '../../App.module.css';

import MARKETING_IMG3 from '../../assets/images/home_page/tasks3.png'
import MARKETING_IMG1 from '../../assets/images/home_page/tasks1.png'
import MARKETING_IMG4 from '../../assets/images/home_page/tasks4.png'
import MARKETING_IMG2 from '../../assets/images/home_page/tasks2.png'

function MarketingLayout() {
    const marketingCarousel = createRef()
    let cardClass = cx(["d-flex flex-column border-0",classes.MarketingImageCard])
    return (
        <div className={classes.MarketingLayout}>
            <h6 className={cx([globalClasses.FontGoogleSans,globalClasses.Font46White,classes.MarketingHeading])}>
                Don't code it, just plug it
            </h6>
            <p className={cx([globalClasses.Font15White,classes.MarketingText])}>
                Discover and integrate powerful plug-and-play AI models to empower your business.
            </p>

            {/* marketing Carousel */}
            <CustomCarousel
                className={"mt-3"}
                reference={marketingCarousel}
                itemsToShow={1}
                itemsToScroll={1}
                enableArrows={false}
                enablePagination={true}
                enableAutoPlay={true}
                transitionDuration={2000}
                focusOnSelect={false}
                itemsCount={3}
                pageDotClass={classes.PageDot}
                pageDotActiveClass={classes.PageDotActive}
            >
                <div>
                    <Card className={cardClass}>
                        <img className={classes.MerketingImage} src={MARKETING_IMG1} alt="textanalytics" title="Text Analytics" />
                        <span className={globalClasses.FontRobotoMedium}>Text Analytics</span>
                    </Card>
                </div>
                <div>
                    <Card className={cardClass}>
                        <img className={classes.MerketingImage} src={MARKETING_IMG2} alt="docai" title="Doc.AI" />
                        <span className={globalClasses.FontRobotoMedium}>Doc.AI</span>
                    </Card>
                </div>
                <div>
                    <Card className={cardClass}>
                        <img className={classes.MerketingImage} src={MARKETING_IMG4} alt="voice" title="Voice" />
                        <span className={globalClasses.FontRobotoMedium}>Voice</span>
                    </Card>
                </div>
                <div>
                    <Card className={cardClass}>
                        <img className={classes.MerketingImage} src={MARKETING_IMG3} alt="vision" title="Vision" />
                        <span className={globalClasses.FontRobotoMedium}>Vision</span>
                    </Card>
                </div>
            </CustomCarousel>

            <p className={cx([globalClasses.Font12White, classes.MarketingCopyrightText, "mt-5 mb-0"])}>Copyright © {process.env.REACT_APP_COPYRIGHT_YEAR} Workhall. All rights reserved.</p>
        </div>

    )
}

export default MarketingLayout;