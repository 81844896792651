import { FaFacebookF, FaInstagram, FaXTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa6";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { ROUTE_CONSTANTS } from "../../routes/RouteConstants"
import { FOOTER, INFO_ADDRESS, INFO_MAIL, INFO_PHONE, PRODUCT_HUNT } from "../../utils/Constants"

import cx from 'classnames';
import classes from './HomeFooter.module.css';
import globalClasses from '../../App.module.css';

import VIVASAI_LOGO from '../../assets/images/logos/vivas-ai-logo-v01.1.png'

function HomeFooter(props) {
    let { showEula } = props;

    const onMouseOver = event => {
        const el = event.target;
        let black = "var(--color-primary)";
        el.style.color = black;
    };

    const onMouseOut = event => {
        const el = event.target;
        let black = "var(--color-darker-grey)";
        el.style.color = black;
    };

    return (
        <>
            <div className={cx(["mt-0 mb-4",globalClasses.HorizontalLineNormal])}></div>
            <div className={classes.Footer}>
                <div className={cx(['flex-column flex-md-row pb-5', classes.FooterContent])}>
                    <div className="col-12 col-md-6 ms-2 mt-5 mt-md-0">
                        <div className={classes.FooterTitleText}><b>Contact</b></div>
                        <div className={cx(['col-9', classes.FooterText, classes.FooterAddress])}>
                            {INFO_ADDRESS}
                        </div>
                        <div className="flex-column">
                            <div className={cx(globalClasses.IconMargins,classes.FooterText)}><FaEnvelope size={16} />  <a className={globalClasses.FontPrimary} href={"mailto:" + INFO_MAIL}>{INFO_MAIL}</a></div>
                            <div className={cx(globalClasses.IconMargins,classes.FooterText)}><FaPhoneAlt size={16} />  {INFO_PHONE}</div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="row mx-0">
                            <div className="col-12 col-md-4 mt-5 mt-md-0">
                                <div className={classes.FooterTitleText}><b>Resources</b></div>
                                <div className={classes.FooterText}>
                                    <a className={classes.FooterText} href={ROUTE_CONSTANTS.INDUSTRY} target={"_blank"} rel="noopener noreferrer"
                                        onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)}>
                                        Industries
                                    </a></div>
                                <div className={classes.FooterText}>
                                    <a className={classes.FooterText} href={ROUTE_CONSTANTS.USECASE} target={"_blank"} rel="noopener noreferrer"
                                        onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)}>
                                        Use Cases
                                    </a></div>
                                <div className={classes.FooterText}>
                                    <a className={classes.FooterText} href={ROUTE_CONSTANTS.BLOG} target={"_blank"} rel="noopener noreferrer"
                                        onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)}>
                                        Blog
                                    </a></div>
                                <div className={classes.FooterText}><a href={ROUTE_CONSTANTS.WHITE_PAPER} target={"_blank"} rel="noopener noreferrer"
                                    className={classes.FooterText} onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)}>
                                    Whitepaper
                                </a></div>
                                <div className={classes.FooterText}><a href={process.env.REACT_APP_API_STATUS_PAGE} target="_blank" rel="noopener noreferrer"
                                    className={classes.FooterText} onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)}>
                                    API Status
                                </a></div>
                                {/* 
                                    <div className={classes.FooterText}>
                                        <a className={classes.FooterText} href={ROUTE_CONSTANTS.PLAYBOOK} 
                                            onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)}>
                                            Playbook
                                        </a>
                                    </div>
                                    <div className={classes.FooterText}>
                                        <a className={classes.FooterText} href={ROUTE_CONSTANTS.CASE_STUDY} 
                                        onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)}>
                                        Case Study
                                        </a>
                                    </div> 
                                */}
                            </div>
                            <div className="col-12 col-md-4 mt-3 mt-md-0">
                                <div className={classes.FooterTitleText}><b>Company</b></div>
                                <div className={classes.FooterText}><a className={classes.FooterText} href={ROUTE_CONSTANTS.ABOUT_VIVASAI} target={"_blank"}
                                    onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)} rel="noopener noreferrer">
                                    About Vivas.AI
                                </a></div>
                                <div className={classes.FooterText}>
                                    <a className={classes.FooterText} href={ROUTE_CONSTANTS.PARTNER} target={"_blank"}
                                        onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)} rel="noopener noreferrer">
                                        Partners
                                    </a></div>
                                <div className={classes.FooterText}><a className={classes.FooterText} href={ROUTE_CONSTANTS.CAREERS} target={"_blank"}
                                    onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)} rel="noopener noreferrer">
                                    We're Hiring!
                                </a></div>
                                <div className={classes.FooterText}><a className={classes.FooterText} href={ROUTE_CONSTANTS.CONTACT_US} target={"_blank"}
                                    onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)} rel="noopener noreferrer">
                                    Contact Us
                                </a></div>
                            </div>
                            <div className="col-12 col-md-4 mt-3 mt-md-0">
                                <div className={classes.FooterTitleText}><b>Legal</b></div>
                                <div className={classes.FooterText}><a className={classes.FooterText} href={ROUTE_CONSTANTS.PRIVACY_POLICY} target={"_blank"}
                                    onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)} rel="noopener noreferrer">
                                    Privacy Policy
                                </a></div>
                                <div className={classes.FooterText}><a className={classes.FooterText} href={ROUTE_CONSTANTS.TERMS} target={"_blank"}
                                    onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)} rel="noopener noreferrer">
                                    Terms
                                </a></div>
                                {
                                    showEula &&
                                    <div className={classes.FooterText}><a className={classes.FooterText} href={ROUTE_CONSTANTS.EULA} target={"_blank"}
                                        onMouseEnter={event => onMouseOver(event)} onMouseOut={event => onMouseOut(event)} rel="noopener noreferrer">
                                        EULA
                                    </a></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={cx(['d-block mt-5', classes.FooterBetaContent])}>
                    As a beta user, your
                    <span className={cx([globalClasses.PointerCursor])} onClick={openFeedback}> feedback </span>
                    is valuable. Vivas.AI beta version could be unstable at times and subject to change.
                </div> */}
                <hr className="mt-0" />
                <div className={cx(["d-flex text-center text-md-start justify-content-md-between flex-wrap py-4", classes.FooterContent])}>
                    <div className={cx(['col-12 col-md-4', classes.FooterTitleText])}>
                        <img width='100%' height='100%' className={classes.FooterLogo} src={VIVASAI_LOGO} alt={"Vivas.AI"} title={"Vivas.AI"} />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-end mt-4 mt-md-0">
                        <div className="d-flex">
                            <div>
                                <a href={FOOTER.PRODUCT_HUNT} className={classes.AdditionalFooterIcon} target="_blank" rel="noopener noreferrer"  >
                                    <img 
                                        src={PRODUCT_HUNT.BADGE_IMAGE}
                                        alt={PRODUCT_HUNT.BADGE_IMAGE_ALT_TEXT} 
                                    />
                                </a>
                            </div>
                            <div>
                                <a href={FOOTER.FACEBOOK} className={cx(classes.FooterIcon,classes.FooterIconFaceBook)} target="_blank" rel="noopener noreferrer"  >
                                    <FaFacebookF size={14} />
                                </a>
                            </div>
                            <div>
                                <a href={FOOTER.TWITTER} className={cx(classes.FooterIcon,classes.FooterIconXTwitter)} target="_blank" rel="noopener noreferrer">
                                    <FaXTwitter size={14} />
                                </a>
                            </div>
                            <div>
                                <a href={FOOTER.INSTAGRAM} className={cx(classes.FooterIcon,classes.FooterIconInstagram)} target="_blank" rel="noopener noreferrer">
                                    <FaInstagram size={14} />
                                </a>
                            </div>
                            <div>
                                <a href={FOOTER.LINKED_IN} className={cx(classes.FooterIcon,classes.FooterIconLinkedIn)} target="_blank" rel="noopener noreferrer">
                                    <FaLinkedinIn size={14} />
                                </a>
                            </div>
                            <div>
                                <a href={FOOTER.YOUTUBE} className={cx(classes.FooterIcon,classes.FooterIconYouTube)} target="_blank" rel="noopener noreferrer">
                                    <FaYoutube size={14} />
                                </a>
                            </div>
                        </div>
                        <p className={classes.FooterCopyRightText}>Copyright © {process.env.REACT_APP_COPYRIGHT_YEAR} Workhall. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeFooter