/*
    Created On : 21 March, 2022
    Created By : Hariprakash Karthikeyan
    Last Updated On : 12 May, 2023
    Last Updated By : Hariprakash Karthikeyan
*/

// modules
import { Component } from "react";
import cx from 'classnames'
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";

// classes
import classes from './SortingTable.module.css';
import globalClasses from '../../App.module.css';

// subcomponents
import SortableHead from "../sortable_head/SortableHead";

// utils
import { KEY_CODES, SORT_TABLE_VALUES } from "../../utils/Constants";
import { EMPTY_STRING, SEPARATORS } from "../../strings/Strings";
import { getReferredData, getTimeFromDateString, isEmpty, isEmptyList, isEmptyObject, isValidNumber } from "../../utils/UtilityFunctions";
import { SORT_TABLE_PAGINATION_STATES, SORT_TABLE_STATES, formAndDownloadCSVData } from "./SortingTable.utils";
import SearchInput from "../search_input/SearchInput";
import NoData from "../no_data/NoData";
import { IMAGES } from "../../utils/ImageConstants";
import InfoToolTip from "../ui_elements/info_tool_tip/InfoToolTip";

class SortingTable extends Component {

    // initial state of the sorting table
    state = {
        ...SORT_TABLE_STATES
    }

    // to filter the data from the actual data
    getFilteredData = (actualList, index) => {
        let { itemsToShow } = this.props;
        let count = isEmpty(itemsToShow) ? actualList.length : itemsToShow;
        let filterDataList = Object.assign([], actualList);
        return filterDataList.slice(index * count, (index * count) + parseInt(count));
    }

    // to get the initial data for thetable
    getSortTableData = () => {
        let { headingList, dataList, itemsToShow, indexed } = this.props;
        let { searchValue, currentSearchKeyObject } = this.state;
        // setting the data
        let tableDataList = Object.assign([], dataList);
        let tableHeadList = Object.assign([], headingList);

        // adding serial no for the table if indexed is true
        if(indexed && tableDataList.length>1){
            tableDataList.forEach((element,index) => {
                element['sno']=String(index+1).padStart(2,0)
            });
            tableHeadList.unshift({
                key : "sno",
                label : "S.No.",
                isDate : false
            })
        }
        
        if(!isEmpty(searchValue) && !isEmptyObject(currentSearchKeyObject)){
            tableDataList = tableDataList.filter((item) => {
                if(isEmpty(item)){
                    return false;
                } else if(currentSearchKeyObject?.isNumber){
                    return item[currentSearchKeyObject?.key]===Number(searchValue)
                } else if(currentSearchKeyObject.hasOwnProperty("getItem")){
                    let itemActualValue = currentSearchKeyObject.getItem(item);
                    return itemActualValue.toLowerCase().includes(searchValue.toLowerCase())
                } else{
                    return item[currentSearchKeyObject?.key].toLowerCase().includes(searchValue.toLowerCase())
                }
            })
        }

        let totalPage = Math.ceil(tableDataList.length / itemsToShow);
        let tableFilteredSortedDataLists = this.getFilteredSortedDataLists(tableDataList, 0, EMPTY_STRING)
        let lastPaginationIndex = (totalPage >= 4) ? 3 : totalPage-1

        this.setState({
            ...SORT_TABLE_PAGINATION_STATES,
            tableDataList,
            tableHeadList,
            ...tableFilteredSortedDataLists,
            totalPage,
            lastPaginationIndex
        })
    }

    componentDidMount = () => {
        // getting initial table data
        let { searchInputValue, searchInputKey, searchKeysList, enableSearch } = this.props
        if(enableSearch && !isEmptyList(searchKeysList)){
            let searchValue = isEmpty(searchInputValue)? EMPTY_STRING : searchInputValue
            let currentSearchKeyObject = searchKeysList[0]
            if(!isEmpty(searchInputKey)){
                currentSearchKeyObject = searchKeysList[searchKeysList.findIndex(searchkey => (searchkey?.key === searchInputKey))]
            }
            this.setState({
                searchValue,
                currentSearchKeyObject
            },this.getSortTableData)
        } else {
            this.getSortTableData();
        }
        
    }

    componentDidUpdate = (prevProps, prevState) => {
        let { headingList } = this.props;
        
        // if data list changes then setting to initial states
        // else if headlist changes render the table again
        if(JSON.stringify(prevProps.dataList) !== JSON.stringify(this.props.dataList)){
            this.getSortTableData();
        } else if (prevProps.headingList !== this.props.headingList) {
            let tableHeadList = Object.assign([], headingList);
            this.setState({
                tableHeadList
            })
        }
    }

    // to get the sorted table data for the filtered table
    getFilteredSortedDataLists = (actualList, pageIndex, currentSortKey) => {
        let tableSortableDataList = Object.assign([], actualList);
        let tableFilteredDataList = this.getFilteredData(tableSortableDataList, pageIndex);
        return {
            tableFilteredDataList,
            tableSortableDataList,
            currentSortKey
        }
    }

    // to sort the table data
    sortTableData = (key, order, item) => {
        let { tableDataList, pageIndex } = this.state;
        let tableSortableDataList = Object.assign([], tableDataList);
        tableSortableDataList.sort((item1, item2) => {
            console.log('Item', item1[key], item2[key]);
            let sortData1 = isNaN(item1[key]) ? item1[key] : Number(item1[key])
            let sortData2 = isNaN(item2[key]) ? item2[key] : Number(item2[key])
            if (item.isDate) {
                sortData1 = getTimeFromDateString(sortData1, item.onlyDate);
                sortData2 = getTimeFromDateString(sortData2, item.onlyDate);
            }
            if(item.isObject) {
                sortData1 = getReferredData(sortData1, item.valueRef);
                sortData2 = getReferredData(sortData2, item.valueRef);
            }
            if (sortData1 > sortData2) {
                return order ? 1 : -1
            }
            if (sortData1 < sortData2) {
                return order ? -1 : 1
            }
            return 0;
        })
        return {
            ...this.getFilteredSortedDataLists(tableSortableDataList, pageIndex, key)
        }
    }

    // to handle the sorting for the key
    onSortHandler = (key, order, item) => {
        this.setState({
            currentSortKey: key,
            ...this.sortTableData(key, order, item)
        })
    }

    // handle click event on next button
    onClickNextPage = () => {
        let { pageIndex, tableSortableDataList, currentSortKey, lastPaginationIndex } = this.state;
        pageIndex = pageIndex + 1;
        if((pageIndex > 3) && (pageIndex > lastPaginationIndex)){
            lastPaginationIndex = pageIndex
        }
        this.setState({
            lastPaginationIndex,
            pageIndex,
            inputPage : pageIndex+1,
            ...this.getFilteredSortedDataLists(tableSortableDataList, pageIndex, currentSortKey)
        })
    }

    // handle click event on previous button
    onClickPreviousPage = () => {
        let { pageIndex, tableSortableDataList, currentSortKey, lastPaginationIndex } = this.state;
        pageIndex = pageIndex - 1;
        if((pageIndex < lastPaginationIndex-3)){
            lastPaginationIndex = pageIndex + 3
        }
        this.setState({
            lastPaginationIndex,
            pageIndex,
            inputPage : pageIndex+1,
            ...this.getFilteredSortedDataLists(tableSortableDataList, pageIndex, currentSortKey)
        })
    }

    // handle click and change event on particular page
    onClickGotoPage = (index) => {
        let { tableSortableDataList, currentSortKey, lastPaginationIndex, totalPage } = this.state;
        let pageIndex = index;
        if((pageIndex === 0) || (pageIndex === (totalPage - 1))){
            lastPaginationIndex = pageIndex? totalPage - 1 : 3
        }
        if(pageIndex > lastPaginationIndex || pageIndex < lastPaginationIndex-3){
            lastPaginationIndex = pageIndex
        }
        this.setState({
            lastPaginationIndex,
            pageIndex,
            inputPage : pageIndex+1,
            ...this.getFilteredSortedDataLists(tableSortableDataList, pageIndex, currentSortKey)
        })
    }

    // handle change event of the page number input box
    onChangeInputPage = (e) => {
        let { totalPage } = this.state
        let value = e.target.value;
        let validNumber = isValidNumber(value)
        if(validNumber && value>totalPage){
            value = totalPage
        }
        
        if(validNumber || isEmpty(value)){
            
            this.setState({
                inputPage : value !== "0" ? value : EMPTY_STRING
            })
        }
    }

    // handle the blur event for the page number input box 
    onBlurInputPage = () => {
        let { inputPage, pageIndex } = this.state
        let currentPageIndex = isEmpty(inputPage)? pageIndex : Number(inputPage)-1
        this.onClickGotoPage(currentPageIndex)
    }

    // handle the key press event for the page number input box 
    onKeyDownInputPage = (e) => {
        if (e.keyCode === KEY_CODES.ENTER_KEY) {
            this.onBlurInputPage()
        }
    }

    // handle on change event of the search input
    onChangeSearchInputHandler = ({target}) => {
        this.setState({
            searchValue : isEmpty((target.value).trim()) ? EMPTY_STRING : target.value.replace(/\s\s/g,SEPARATORS.SPACE)
        },this.getSortTableData)
    }

    // handle clear event of search field
    onClearSearchInputHandler = () => {
        let {searchValue} = this.state;
        if(!isEmpty(searchValue)){
            this.setState({
                searchValue : EMPTY_STRING
            },this.getSortTableData)
        }
    }

    // handle on change event for the search field dropdown
    onChangeSearchFieldKey = (value) => {
        this.setState({
            currentSearchKeyObject : value
        },this.onClearSearchInputHandler)
    }

    // handle the click event for the export field
    onClickExport = () => {
        let { exportKeysList, exportTableName, addExportRows } = this.props;
        let { tableDataList } = this.state;
        formAndDownloadCSVData(exportKeysList,tableDataList,exportTableName, addExportRows)
    }

    render() {
        let { itemsToShow, noteText, containerClass, additionalRows, isHalfScreen, tableHeadClass, enableSearch, searchKeysList, enableExports,
            hoverClass, sortedClass, pageButtonClass, pageButtonActiveClass } = this.props;
        let { currentSortKey, tableDataList, tableHeadList, tableFilteredDataList, pageIndex, totalPage, lastPaginationIndex, inputPage,
            searchValue, currentSearchKeyObject } = this.state;

        let tableTopComponent = null;
        let tableComponent = null;
        let searchComponent = null;
        let additionalOptionsComponent = null;
        
        let paginationButtonClass = cx(["btn btn-outline-primary", classes.PageButtonSize, pageButtonClass? pageButtonClass : classes.PageButton])
        let noteTextClass = cx(["px-2 px-md-3",classes.NoteText])

        // export button component
        if(enableExports){
            let exportComponent = (
                <InfoToolTip text={"Export"} textPlacement={"bottom"}>
                    <button className={classes.ExportIcon} onClick={this.onClickExport}>
                        <img src={IMAGES.ACTIONS.EXPORT_ICON} alt={"export"} width={"24px"} height={"24px"}/>
                    </button>
                </InfoToolTip>
            )
            additionalOptionsComponent = (
                <div className={cx(["col-12 mt-3 mt-md-0 d-flex justify-content-end",enableSearch&&"col-md-5 col-lg-7"])}>
                    {exportComponent}
                </div>
            )
        }

        // search input field
        if(enableSearch && !isEmptyObject(currentSearchKeyObject)){
            searchComponent = (
                <div className="col-12 col-md-7 col-lg-5">
                    <SearchInput
                        searchDropList={searchKeysList}
                        onChangeSearchDrop = {this.onChangeSearchFieldKey}
                        currentSearch = {currentSearchKeyObject}
                        searchValue={searchValue}
                        onChangeValue={this.onChangeSearchInputHandler}
                        onClearSearch={this.onClearSearchInputHandler}
                        searchPlaceHolder={currentSearchKeyObject?.placeholder}
                        disableAutoSearch={true}
                    />
                </div>
            )
        }

        if(searchComponent || additionalOptionsComponent){
            tableTopComponent = (
                <div className="row px-2 px-md-3 mt-2 py-2">
                    {searchComponent}
                    {additionalOptionsComponent}
                </div>
            )
        }

        // forming table header component
        if(!isEmptyList(tableDataList)){
            let headingComponent = null;
            let dataComponent = null;
            headingComponent = tableHeadList.map((item, key) => {
                if (!item.hide) {
                    let textClass = EMPTY_STRING;
                    if(item?.alignment === SORT_TABLE_VALUES.ALIGNMENTS.LEFT){
                        textClass = "text-start";
                    }
                    if(item?.alignment === SORT_TABLE_VALUES.ALIGNMENTS.RIGHT){
                        textClass = "text-end";
                    }
                    if(item?.alignment === SORT_TABLE_VALUES.ALIGNMENTS.CENTER){
                        textClass = "text-center";
                    }
                    return (
                        item.unsortable ?
                            <th className={cx([classes.TableHead])}>{item.label}</th>
                            :
                            <SortableHead
                                data={item.label}
                                isSortable={tableHeadList.length}
                                dataStyle={cx([textClass,classes.TableHead,classes.TableHoverHead,globalClasses.PointerCursor,hoverClass])}
                                dataSortedStyle={cx(classes.TableDataRowSorted,sortedClass)}
                                sortKey={item.key}
                                currentSortKey={currentSortKey}
                                iconStyle={classes.TableHeadRowSortable}
                                onSortHandler={(data, order) => this.onSortHandler(data, order, item)}
                            />
                    )
                }
                return null;
            })

            // forming table body component
            dataComponent = tableFilteredDataList.map((item, dKey) => {
                let subItemComponent = null;
                return (
                    <>
                        <tr key={dKey} className={classes.TableDataRow}>
                            {
                                tableHeadList.map((headItem, hKey) => {
                                    let headItemValue = item[headItem.key]
                                    console.log("headeiten", headItem)
                                    if (!isEmpty(headItem.renderItem)) {
                                        headItemValue = headItem.renderItem(item)
                                    }
                                    if(headItem?.showSubItem){
                                        let showSubItem = headItem.showSubItem(item)
                                        if (showSubItem && !isEmpty(headItem?.renderSubItem)) {
                                            let subItem = headItem.renderSubItem(item)
                                            if(!isEmpty(subItem)){
                                                subItemComponent = <tr><td colSpan={tableHeadList.length}>{headItem.renderSubItem(item)}</td></tr>
                                            }
                                        }
                                    }
                                    if (!headItem.hide) {
                                        let textClass = EMPTY_STRING;
                                        if(headItem?.alignment === SORT_TABLE_VALUES.ALIGNMENTS.LEFT){
                                            textClass = "text-start";
                                        }
                                        if(headItem?.alignment === SORT_TABLE_VALUES.ALIGNMENTS.RIGHT){
                                            textClass = "text-end";
                                        }
                                        if(headItem?.alignment === SORT_TABLE_VALUES.ALIGNMENTS.CENTER){
                                            textClass = "text-center";
                                        }

                                        return (
                                            <td key={hKey} style={{whiteSpace:"pre-wrap"}} className={cx([textClass,headItem.class])}>
                                                {isEmpty(headItemValue) ? "-" : headItemValue}
                                            </td>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </tr>
                        {subItemComponent}
                    </>

                )
                
            })

            tableComponent = (
                <div className={cx(["table-responsive my-2",classes.ResponsiveTableContainer])}>
                    <table className="table mb-0">
                        <thead className={classes.THead}>
                            <tr className={cx([tableHeadClass,classes.TableHeadRow])}>
                                {headingComponent}
                            </tr>
                        </thead>
                        <tbody className={classes.TBody}>
                            {dataComponent}
                            {additionalRows}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            tableComponent = (
                <NoData mainText={"No Records Found"}/>
            )
        }

        // returnig table
        return (
            <div className={cx(["col-12 card",classes.Container,containerClass])}>
                {tableTopComponent}
                {tableComponent}
                <div className="col-12 px-0">
                    {
                        !isEmpty(noteText) && 
                        <div className="col-12 mb-2">
                            <span className={noteTextClass}><label>Note:</label> {noteText}</span>
                        </div>
                    }
                    {
                        !isEmptyList(totalPage > 1) &&  
                        <div className="col-12 py-3 d-flex flex-wrap align-items-center justify-content-end">
                            <div className={cx([isHalfScreen? "col-12 col-sm-6 mb-3" : "col-12 mb-3 mb-lg-0 col-sm-6 col-md-6 col-lg-4","px-2 px-md-3 text-center text-sm-start"])}>
                                <span className={classes.PageText}>
                                    Page {
                                        <input type={"text"} value={inputPage} onChange={this.onChangeInputPage} onBlur={this.onBlurInputPage} onKeyDown={this.onKeyDownInputPage}/>
                                    } of {totalPage}
                                </span>
                            </div>
                            <div className={cx([isHalfScreen? "col-12 col-sm-6 mb-3" : "col-12 mb-3 mb-lg-0 col-sm-6 col-md-6 col-lg-4" ,"px-2 px-md-3 text-center text-sm-end"])}>
                                <span className={classes.PageText}>{pageIndex * itemsToShow + 1} to {pageIndex * itemsToShow + tableFilteredDataList.length} of {tableDataList.length} items</span>
                            </div>
                            <div className={cx([isHalfScreen? "col-12 col-md-8" : "col-12 col-md-6 col-lg-4", "btn-group px-2 px-md-3"])} role="group" aria-label="pagination">
                                <button type="button" disabled={pageIndex ? false : true} className={paginationButtonClass} onClick={() => this.onClickGotoPage(0)}>
                                    <HiOutlineChevronDoubleLeft
                                        style={{
                                            margin: '0px 0px 2px 0px'
                                        }}
                                    />
                                </button>
                                <button type="button" disabled={pageIndex ? false : true} className={paginationButtonClass} onClick={this.onClickPreviousPage}>
                                    <HiOutlineChevronLeft
                                        style={{
                                            margin: '0px 0px 2px 0px'
                                        }}
                                    />
                                </button>
                                <button type="button" className={cx([paginationButtonClass,pageIndex===(totalPage>4?lastPaginationIndex-3:0)?cx(pageButtonActiveClass? pageButtonActiveClass : classes.ActivePage):""])} onClick={() => this.onClickGotoPage(totalPage>4?lastPaginationIndex-3:0)}>
                                    <span>{totalPage>4?lastPaginationIndex-2:1}</span>
                                </button>
                                <button type="button" className={cx([paginationButtonClass,pageIndex===(totalPage>4?lastPaginationIndex-2:1)?cx(pageButtonActiveClass? pageButtonActiveClass : classes.ActivePage):""])} onClick={() => this.onClickGotoPage(totalPage>4?lastPaginationIndex-2:1)}>
                                    <span>{totalPage>4?lastPaginationIndex-1:2}</span>
                                </button>
                                {
                                    totalPage >= 3 &&
                                    <button type="button" className={cx([paginationButtonClass,pageIndex===(totalPage>4?lastPaginationIndex-1:2)?cx(pageButtonActiveClass? pageButtonActiveClass : classes.ActivePage):""])} onClick={() => this.onClickGotoPage(totalPage>4?lastPaginationIndex-1:2)}>
                                        <span>{totalPage>4?lastPaginationIndex:3}</span>
                                    </button>
                                }
                                {
                                    totalPage >= 4 &&
                                    <button type="button" className={cx([paginationButtonClass,pageIndex===(totalPage>4?lastPaginationIndex:3)?cx(pageButtonActiveClass? pageButtonActiveClass : classes.ActivePage):""])} onClick={() => this.onClickGotoPage(totalPage>4?lastPaginationIndex:3)}>
                                        <span>{totalPage>4?lastPaginationIndex+1:4}</span>
                                    </button>
                                }
                                <button type="button" disabled={totalPage > pageIndex + 1 ? false : true} className={paginationButtonClass} onClick={this.onClickNextPage}>
                                    <HiOutlineChevronRight
                                        style={{
                                            margin: '0px 0px 2px 0px'
                                        }}
                                    />
                                </button>
                                <button type="button" disabled={totalPage > pageIndex + 1 ? false : true} className={paginationButtonClass} onClick={() => this.onClickGotoPage(totalPage-1)}>
                                    <HiOutlineChevronDoubleRight
                                        style={{
                                            margin: '0px 0px 2px 0px'
                                        }}
                                    />
                                </button>
                            </div>
                        </div>
                    }
                </div>
                
            </div>
        )
    }
}

export default SortingTable;