import cx from 'classnames';
import classes from './Card.module.css';

import { isEmpty } from '../../../utils/UtilityFunctions';

function Card(props) {
    let {className,onClickhandler}=props;
    let cardStyle = cx([classes.CardContainer, className]);
    const onClickCard = () => {
        if(!isEmpty(onClickhandler)){
            onClickhandler();
        }
    }
    return(
        <div className={cardStyle} onClick={onClickCard}>
            {props.children}
        </div>
    )
}

export default Card;