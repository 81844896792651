import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import cx from 'classnames';
import globalClasses from "../../../App.module.css";
import { useState } from "react";
import { isEmpty } from "../../../utils/UtilityFunctions";

/*
    text [mandatory] - Text to display in the tooltip 
    showDelay [default:200] - time to display text after the particular ms
    hideDelay [default:400] - time to hide text after the particular ms
    textPlacement [default:"right"] - position where to display tooltip 
                                    ["right","left","top","bottom"]
*/

function InfoToolTip (props) {
    let { text, showDelay, hideDelay, textPlacement, customTooltipClass } = props;

    const [showToolTip,setShowToolTip] = useState(false)

    const getStyle = (e) => {
        if(e.children && !isEmpty(customTooltipClass)){
            e.children[1].className = customTooltipClass;
        }
    };

    const renderTooltip = (tooltipProps) => {
        return (<Tooltip id="span-tooltip" {...tooltipProps}>
          {text}
        </Tooltip>)
    }
    let uiComponent = null;
    if(props.children){
        uiComponent = props.children;
    } else {
        uiComponent = <span className={cx(['px-0 ms-1',globalClasses.PointerCursor])}><MdInfoOutline style={{marginBottom:'2px'}}/></span>
    }

    return (
        <OverlayTrigger
            placement={textPlacement || "right"}
            delay={{ show: showDelay || 200, hide: hideDelay || 400 }}
            overlay={renderTooltip}
            show={showToolTip}
            onEntering={getStyle}
        >
            <span className="px-0" onMouseEnter={() => setShowToolTip(true)} 
                onClick={() => setShowToolTip(false)} 
                onMouseLeave={() => setShowToolTip(false)}>
                {uiComponent}
            </span>
        </OverlayTrigger>
    )
}

export default InfoToolTip;