import FEEDBACK_ICON from '../assets/images/feedback-icon.png';
import EDIT_ICON from '../assets/images/user-manage/edit-icon.png';
import EDIT_ICON_BLUE from '../assets/images/user-manage/edit-icon-blue.png';
import DELETE_ICON_BLUE from '../assets/images/user-manage/trash-icon-blue.png';
import REFRESH_ICON_BLUE from '../assets/images/user-manage/refresh-icon-blue.png';
import DEACTIVATE_ICON_BLUE from '../assets/images/user-manage/deactive-blue.png';
import DONE_ICON from '../assets/images/user-manage/tick-icon.png';
import CANCEL_ICON from '../assets/images/user-manage/close-icon.png';
import EXPORT_ICON from '../assets/images/export-black.png';
import EXPORT_ICON_ORANGE from '../assets/images/export.png';
import NO_DATA from '../assets/images/nodata/no-results.png';
import NO_ACCESS from '../assets/images/nodata/access.png';
import NO_ENOUGH_CREDITS from '../assets/images/nodata/credits.png';
import NO_FACE from '../assets/images/nodata/no-face.png';
import NO_OBJECT from '../assets/images/nodata/no-object.png';
import NO_LOGO from '../assets/images/nodata/no-logo.png';
import NO_BARCODE from '../assets/images/nodata/no-barcode.png';
import NO_IMAGEIDENTIFIER from '../assets/images/nodata/no-personal-data.png';
import NO_WEBLINK from '../assets/images/nodata/no-weblink.png';
import NO_LANDMARK from '../assets/images/nodata/no-landmark.png';
import NO_PLACE from '../assets/images/nodata/no-place.png';
import NO_EQUIPMENT from '../assets/images/nodata/no-equipment.png';
import NO_TEXT from '../assets/images/nodata/no-text.png';
import NO_FAVORITES from '../assets/images/nodata/add-to-favorites.png';
import ACCOUNT_UNSUBSCRIBED from '../assets/images/nodata/account-unsubscribed.png';
import ACCOUNT_EXPIRED from '../assets/images/nodata/account-expired.png';
import USER_EXPIRED from '../assets/images/nodata/user-validity.png';
import ERROR from '../assets/images/error.png';
import CLOUD_UPLOAD_ICON from '../assets/images/cloud-upload.png';
import CLOUD_UPLOAD_ICON_BLUE from '../assets/images/cloud-upload-blue.png';
import IMAGE_PLACEHOLDER from '../assets/images/image-placeholder.png';
import PDF_ICON from '../assets/images/files/pdf.webp';
import TIFF_ICON from '../assets/images/files/tiff.webp';
import ZIP_ICON from '../assets/images/files/zip.webp';
import TXT_ICON from '../assets/images/files/text.webp';
import AUDIO_ICON from '../assets/images/files/audio.webp';
import JSON_ICON from '../assets/images/files/json.webp';
import VIDEO_ICON from '../assets/images/files/video.webp';
import DOCX_ICON from '../assets/images/files/docx.webp';
import PPTX_ICON from '../assets/images/files/pptx.webp';
import XLSX_ICON from '../assets/images/files/xlsx.webp';
import CSVF_ICON from '../assets/images/files/csv.webp';
import XLS_ICON from '../assets/images/files/xls.webp';
import CSV_ICON from '../assets/images/csv.png';

import GPT_FLOW_IMAGE from '../assets/images/products/GPT_Flow.jpg'
import DOCQSPHERE_IMAGE from '../assets/images/products/DocQSphere.webp'
import DEV_COPILOT_IMAGE from '../assets/images/products/Dev_Copilot_Engine.jpg'
import LEAF_IDP_IMAGE from '../assets/images/products/Leaf_IDP.jpg'
import PRODUCT_STEPS_IMAGE from '../assets/images/products/Steps.jpg'
import DRAG_AND_DROP_FEATURE from '../assets/images/products/feature-icons/drag-and-drop.webp'
import COST_FEATURE from '../assets/images/products/feature-icons/cost.webp'
import DATA_FEATURE from '../assets/images/products/feature-icons/data.webp'
import ONPREM_CLOUD_FEATURE from '../assets/images/products/feature-icons/onprem-and-cloud.webp'
import ADAPTABLE_FEATURE from '../assets/images/products/feature-icons/adaptable.png'
import DATA_DRIVEN_FEATURE from '../assets/images/products/feature-icons/data-driven.png'
import EFFICIENCY_FEATURE from '../assets/images/products/feature-icons/efficiency.png'
import INSIGHTS_FEATURE from '../assets/images/products/feature-icons/insights.png'
import INTELLIGENCE_FEATURE from '../assets/images/products/feature-icons/intelligence.png'
import NO_CODING_FEATURE from '../assets/images/products/feature-icons/no-coding.png'
import SOLUTIONS_AND_IDEAS_FEATURE from '../assets/images/products/feature-icons/solution-and-idea.png'
import TAILORED_BUSINESS_FEATURE from '../assets/images/products/feature-icons/tailored-business.png'
import SPEED_UP_FEATURE from '../assets/images/products/feature-icons/speed-up.png'
import DATA_PRIVACY from '../assets/images/products/feature-icons/data_privacy.webp'
import MODEL_DEPLOYMENT from '../assets/images/products/feature-icons/model_deployment.webp'
import UNMATCHED_AFFORDABLITY from '../assets/images/products/feature-icons/unmatched_affordablity.webp'
import AIML_MODELS from '../assets/images/products/feature-icons/models.webp'
import CONFIDENCE_INCREASE from '../assets/images/products/feature-icons/increase-confidence.webp'
import BOUNDING_BOX from '../assets/images/products/feature-icons/bounding-box.webp'
import TRAINING from '../assets/images/products/feature-icons/training.webp'

import IDP_STEP1 from '../assets/images/products/leaf-idp-steps/1.png'
import IDP_STEP2 from '../assets/images/products/leaf-idp-steps/2.png'
import IDP_STEP3 from '../assets/images/products/leaf-idp-steps/3.png'


import DOWNLOAD_ICON_GREY from '../assets/images/download.png';
import DOWNLOAD_ICON_BLUE from '../assets/images/download-blue.png';
import COPY_ICON_GREY from '../assets/images/copy.png';
import COPY_ICON_BLUE from '../assets/images/copy-blue.png';
import PREVIEW_ICON_ORANGE from '../assets/images/preview.png';
import PREVIEW_ICON_BLUE from '../assets/images/preview-icon-blue.png';

import SATISFIED_FACE from '../assets/images/feedback-emotions/satisfied.webp';
import NEUTRAL_FACE from '../assets/images/feedback-emotions/neutral.webp';
import VERY_SATISFIED_FACE from '../assets/images/feedback-emotions/very-satisfied.webp';
import UNSATISFIED_FACE from '../assets/images/feedback-emotions/unsatisfied.webp';
import VERY_UNSATISFIED_FACE from '../assets/images/feedback-emotions/very-unsatisfied.webp';

import ROBOT_GETTING_MODEL_FEEDBACK from '../assets/images/feedback/robot-wave-feedback.webp'
import MODEL_FEEDBACK_IMAGE from '../assets/images/feedback/model-fedback.webp'
import DISCOUNT_ICON from '../assets/images/discount/discount.webp'

import MODEL_GROUP_ICON from '../assets/images/group-panel/model-group-icon.webp';
import DOMAIN_GROUP_ICON from '../assets/images/group-panel/domain-group-icon.webp';
import FAV_MODELS_ICON from '../assets/images/group-panel/favorite-models-icon.webp';
import CONVENTIONAL_MODELS_ICON from '../assets/images/group-panel/conventional.webp';
import GENAI_MODELS_ICON from '../assets/images/group-panel/gen-ai.webp';
import ALL_MODELS_ICON from '../assets/images/group-panel/all-models-icon.webp';
import COMING_SOON_MODELS_ICON from '../assets/images/group-panel/coming-soon-icon.webp';
import LEGACY_MODELS_ICON from '../assets/images/group-panel/legacy.webp';
import LEAF_FLOW_ICON from '../assets/images/group-panel/leaf-flow.webp';
import LEAF_IDP_ICON from '../assets/images/group-panel/leaf-idp.webp';
import LEAF_XDOCCONVERSE_ICON from '../assets/images/group-panel/leaf-xdocconverse.webp';
import LEAF_SCRAPER_ICON from '../assets/images/group-panel/leaf-scraper.webp';
import LEAF_PII_SAFETY_ICON from '../assets/images/group-panel/leaf-pii-safety.webp';
import LEAF_HEALTH_ICON from '../assets/images/group-panel/leaf-health.webp';
import MISCELLANEOUS_ICON from '../assets/images/group-panel/miscellaneous.webp';

import PROFILE_ICON_DB from '../assets/images/profile-drop-icons/profile.png';
import FAVORITE_ICON_DB from '../assets/images/profile-drop-icons/favorite.png';
import LOGOUT_ICON_DB from '../assets/images/profile-drop-icons/logout.png';
import MANAGE_ICON_DB from '../assets/images/profile-drop-icons/manage.webp';
import DOCUMENTATION_ICON_DB from '../assets/images/profile-drop-icons/documentation.webp';

import COMPANY_FINMAG from "../assets/images/companies-logo/finance-magazine.webp";

import OPENAI_ICON from "../assets/images/providers/openai.png";
import COHERE_ICON from "../assets/images/providers/cohere.png";
import AZURE_OPENAI_ICON from "../assets/images/providers/azure-openai.png";
import AWS_BEDROCK_ICON from "../assets/images/providers/aws_bedrock.webp";

import AWS_TRANSCRIBER from "../assets/images/providers/aws_transcriber.webp";
import AZURE_TRANSCRIBER from "../assets/images/providers/azure_transcriber.webp";
import GOOGLE_TRANSCRIBER from "../assets/images/providers/google_transcriber.webp";
import AWS_OCR from "../assets/images/providers/aws_ocr.webp";
import AZURE_OCR from "../assets/images/providers/azure_ocr.webp";

import CANCEL_MARK_ICON from "../assets/images/closemark.png";
import TICK_MARK_ICON from "../assets/images/approve-ico.png";

import HEALTHCARE from "../assets/images/home_page/Industries/healthcare-industry.png";
import HEALTHCARE_CARDPIC from "../assets/images/home_page/Industries/healthcare-industry-cardback.png";
import MANUFACTURING from "../assets/images/home_page/Industries/manufacture-industry.png";
import MANUFACTURING_CARDPIC from "../assets/images/home_page/Industries/manufacture-industry-cardback.png";
import HUMANRESOURCE from "../assets/images/home_page/Industries/humanresource-industry.png";
import HUMANRESOURCE_CARDPIC from "../assets/images/home_page/Industries/humanresource-industry-cardback.png";

import BLOG_ICON from "../assets/images/icons/menu_icons/blog.png";
import WHITEPAPER_ICON from "../assets/images/icons/menu_icons/whitepaper.png";
import INDUSTRY_ICON from "../assets/images/icons/menu_icons/industry.png";
import USECASE_ICON from "../assets/images/icons/menu_icons/use-case.png";
import GPTFLOW_ICON from "../assets/images/icons/menu_icons/gpt-flow.webp";
import LEAFIDP_ICON from "../assets/images/icons/menu_icons/leaf-idp.webp";
import LEAFRPA_ICON from "../assets/images/icons/menu_icons/leaf-rpa.webp";
import LEAFANALYZER_ICON from "../assets/images/icons/menu_icons/leaf-analyser.webp";
import DOCQSPHERE_ICON from "../assets/images/icons/menu_icons/docqsphere.webp";
import DEVCOPILOT_ICON from "../assets/images/icons/menu_icons/dev-copilot-engine.webp";
import CONTACTUS_ICON from "../assets/images/icons/menu_icons/contact-us.png";
import ABOUTUS_ICON from "../assets/images/icons/menu_icons/about-us.png";
import CAREER_ICON from "../assets/images/icons/menu_icons/career.png";
import PARTNER_ICON from "../assets/images/icons/menu_icons/partner.png";
import LEAFIDP_ID_ICON from "../assets/images/icons/menu_icons/leaf-id.webp";
import LEAFIDP_HEALTH_ICON from "../assets/images/icons/menu_icons/leaf-health.webp";
import LEAFIDP_FINANCE_ICON from "../assets/images/icons/menu_icons/leaf-finance.webp";
import LEAFIDP_CONTRACT_ICON from "../assets/images/icons/menu_icons/leaf-contract.webp";

import PAGE_NOT_FOUND from "../assets/images/pages/page-not-found.webp"
import CONTACT_US_BACK from "../assets/images/pages/contact-us-back.webp"
import GET_IN_TOUCH_WITH_US from "../assets/images/pages/get-in-touch-with-us.webp"
import BUILD_MY_MODEL from "../assets/images/pages/build-my-own-model.webp"

import LOADER_GIF from "../assets/images/loader/loader-gif.gif"

import NUMBER_ONE from "../assets/images/home_page/one.png";
import NUMBER_TWO from "../assets/images/home_page/two.png";
import NUMBER_THREE from "../assets/images/home_page/three.png";
import NUMBER_FOUR from "../assets/images/home_page/four.png";
import NUMBER_FIVE from "../assets/images/home_page/five.png";
import NUMBER_SIX from "../assets/images/home_page/six.png";

import CLOUD_SAAS_DEPLOY from "../assets/images/home_page/cloud-saas.gif";
import SELFHOST_DEPLOY from "../assets/images/home_page/selfhosting.gif";

import BRING_YOUR_OWN_LLM from "../assets/images/home_page/why_vivasai/bring_your_own_llm.gif";
import CITIZER_DEVELOPER from "../assets/images/home_page/why_vivasai/citizen_developer_ready.gif";
import FLEXIBLE_DEPLOYMENT from "../assets/images/home_page/why_vivasai/flexible_deployment.gif";
import PLUG_AND_PLAY_AI_MODEL from "../assets/images/home_page/why_vivasai/plug_and_play_ai_model.gif";
import UNMATCHED_AFFORDABILITY from "../assets/images/home_page/why_vivasai/unmatched_affordability.gif";

import BYOLLM_ANNOUNCE from "../assets/images/announcements/exciting-news-introducing-bring-your-own-llm-feature-to-elevate-your-ai-ml-experience.webp"
import SELFHOSTING_ANNOUNCE from "../assets/images/announcements/embrace-full-data-autonomy-with-vivasai-new-self-hosting-options.webp"
import G2RANKING_ANNOUNCE from "../assets/images/announcements/g2-ranks-vivasai-as-a-high-performer-in-machine-learning-software.webp"
import PRODUCTLAUNCH_ANNOUNCE from "../assets/images/announcements/join-the-buzz-around-vivasai-on-product-hunt-and-explore-our-ai-revolution.webp"

import LEAFIDP_UPLOAD_TBN from "../assets/images/products/leaf-idp-thumbnails/upload-thumbnail.webp"
import LEAFIDP_DISCOVER_TBN from "../assets/images/products/leaf-idp-thumbnails/discover-thumbnail.webp"
import LEAFIDP_BUILD_TBN from "../assets/images/products/leaf-idp-thumbnails/build-thumbnail.webp"
import LEAFIDP_VALIDATE_TBN from "../assets/images/products/leaf-idp-thumbnails/validate-thumbnail.webp"
import LEAFIDP_DEPLOY_TBN from "../assets/images/products/leaf-idp-thumbnails/deploy-thumbnail.webp"

import Services from "../assets/images/services/services.webp";
import Services_We_Provide from "../assets/images/services/services_weprovide.webp";

import OEM_Partnership from "../assets/images/partner/OEM_Partnership.webp";
import Services_Partnership from "../assets/images/partner/Services_Partnership.webp"
import Reseller_Partnership from "../assets/images/partner/Reseller_Partnership.webp"
import No_Code_Partnership from "../assets/images/partner/No_Code_Partnership.webp"

import FINE_TUNE_SERVICE from "../assets/images/services/key-services/fine-tune.webp";
import HOSTING_SERVICE from "../assets/images/services/key-services/hosting.webp";
import AUGMENT_MODEL_CREATE_SERVICE from "../assets/images/services/key-services/augment-model-create.webp";
import MODE_CREATION_AI_SERVICE from "../assets/images/services/key-services/ai-model-creation.webp";
import COPILOT_SERVICE from "../assets/images/services/key-services/copilot.webp";
import WORKFLOW_SERVICE from "../assets/images/services/key-services/workflow.webp";

import STUDIO_LEAFIDP from "../assets/images/studio-card/LeafIDP.webp";
import STUDIO_LEAFXDOCCONVERSE from "../assets/images/studio-card/LeafXDocConverse.webp";
import STUDIO_LEAFESSENTIALS from "../assets/images/studio-card/LeafEssentials.webp";

import BROWSE_LEAFIDP_MODEL from "../assets/images/products/leaf-idp-studio-features/browse-model.webp";
import EXECUTE_LEAFIDP_MODEL from "../assets/images/products/leaf-idp-studio-features/execute-model.webp";
import BUILD_LEAFIDP_MODEL from "../assets/images/products/leaf-idp-studio-features/build-model.webp";


export const IMAGES = {
    IMAGE_PLACEHOLDER,
    FEEDBACK_ICON,
    LOADERS : {
        LOADER_GIF
    },
    PARTNERSHIP : {OEM_Partnership,Services_Partnership,Reseller_Partnership,No_Code_Partnership},
    SERVICES : {
        Services,Services_We_Provide,
        KEY_SERVICES : { FINE_TUNE_SERVICE, HOSTING_SERVICE, AUGMENT_MODEL_CREATE_SERVICE, MODE_CREATION_AI_SERVICE, COPILOT_SERVICE, WORKFLOW_SERVICE}
    },
    PAGES : {
        PAGE_NOT_FOUND, CONTACT_US_BACK, BUILD_MY_MODEL,GET_IN_TOUCH_WITH_US
    },
    POINTS_IMAGE : {
        NUMBER_ONE, NUMBER_TWO, NUMBER_THREE,
        NUMBER_FOUR, NUMBER_FIVE, NUMBER_SIX
    },
    ANNOUNCEMENTS : {
        BANNER : {BYOLLM_ANNOUNCE,SELFHOSTING_ANNOUNCE,G2RANKING_ANNOUNCE,PRODUCTLAUNCH_ANNOUNCE}
    },
    MENU_ICONS : {
        BLOG_ICON, WHITEPAPER_ICON, INDUSTRY_ICON, USECASE_ICON,
        GPTFLOW_ICON, DOCQSPHERE_ICON, DEVCOPILOT_ICON,
        CONTACTUS_ICON, ABOUTUS_ICON, CAREER_ICON, LEAFIDP_ICON,
        LEAFRPA_ICON, LEAFANALYZER_ICON, PARTNER_ICON
    },
    PRODUCTS:{
        GPT_FLOW_IMAGE,DEV_COPILOT_IMAGE,DOCQSPHERE_IMAGE,PRODUCT_STEPS_IMAGE,IDP_STEP1,IDP_STEP2,IDP_STEP3,LEAF_IDP_IMAGE,LEAFIDP_ID_ICON,LEAFIDP_HEALTH_ICON,LEAFIDP_FINANCE_ICON,LEAFIDP_CONTRACT_ICON,
        FATURES_ICON:{DRAG_AND_DROP_FEATURE, ONPREM_CLOUD_FEATURE, DATA_FEATURE, COST_FEATURE, ADAPTABLE_FEATURE, SOLUTIONS_AND_IDEAS_FEATURE,
            TAILORED_BUSINESS_FEATURE, DATA_DRIVEN_FEATURE, NO_CODING_FEATURE, EFFICIENCY_FEATURE, INSIGHTS_FEATURE, INTELLIGENCE_FEATURE,
            SPEED_UP_FEATURE,DATA_PRIVACY,MODEL_DEPLOYMENT,UNMATCHED_AFFORDABLITY, AIML_MODELS, CONFIDENCE_INCREASE, BOUNDING_BOX, TRAINING},
        THUMBNAILS :{ LEAFIDP_DEPLOY_TBN, LEAFIDP_BUILD_TBN, LEAFIDP_VALIDATE_TBN, LEAFIDP_DISCOVER_TBN,LEAFIDP_UPLOAD_TBN },
        STUDIO_FEATURES : {
            LEAFIDP : {
                BROWSE_LEAFIDP_MODEL, EXECUTE_LEAFIDP_MODEL, BUILD_LEAFIDP_MODEL
            }
        }
    },
    STUDIO_CARD:{
        STUDIO_LEAFIDP,STUDIO_LEAFXDOCCONVERSE,STUDIO_LEAFESSENTIALS
    },
    COMPANY_LOGOS : {
        COMPANY_FINMAG
    },
    PROVIDERS : {
        OPENAI_ICON,AWS_BEDROCK_ICON, COHERE_ICON, AZURE_OPENAI_ICON,AWS_TRANSCRIBER,AZURE_TRANSCRIBER,GOOGLE_TRANSCRIBER,
        AWS_OCR, AZURE_OCR
    },
    PROFILE_DROP : {
        PROFILE_ICON_DB, FAVORITE_ICON_DB, LOGOUT_ICON_DB, MANAGE_ICON_DB, DOCUMENTATION_ICON_DB
    },
    ASSETS : {
        ROBOT_GETTING_MODEL_FEEDBACK,
        MODEL_FEEDBACK_IMAGE, DISCOUNT_ICON,
        CLOUD_SAAS_DEPLOY, SELFHOST_DEPLOY,
        BRING_YOUR_OWN_LLM,CITIZER_DEVELOPER,FLEXIBLE_DEPLOYMENT,PLUG_AND_PLAY_AI_MODEL,UNMATCHED_AFFORDABILITY
    },
    INDUSTRIES : {
        HEALTHCARE,HEALTHCARE_CARDPIC,MANUFACTURING,MANUFACTURING_CARDPIC,
        HUMANRESOURCE,HUMANRESOURCE_CARDPIC
    },
    PANEL_ICONS : {
        ALL_MODELS_ICON, FAV_MODELS_ICON, CONVENTIONAL_MODELS_ICON,
        MODEL_GROUP_ICON, DOMAIN_GROUP_ICON, COMING_SOON_MODELS_ICON,
        GENAI_MODELS_ICON, LEGACY_MODELS_ICON, LEAF_FLOW_ICON,
        LEAF_HEALTH_ICON, LEAF_IDP_ICON, LEAF_PII_SAFETY_ICON,
        LEAF_SCRAPER_ICON, LEAF_XDOCCONVERSE_ICON, MISCELLANEOUS_ICON
    },
    FILES : {
        PDF_ICON,TIFF_ICON,ZIP_ICON,TXT_ICON,
        AUDIO_ICON,JSON_ICON,VIDEO_ICON,DOCX_ICON,
        PPTX_ICON,XLSX_ICON, CSVF_ICON, XLS_ICON
    },
    ACTIONS : {
        EDIT_ICON,DONE_ICON,CANCEL_ICON,EDIT_ICON_BLUE,
        CLOUD_UPLOAD_ICON,DOWNLOAD_ICON_GREY,CSV_ICON,
        COPY_ICON_GREY,PREVIEW_ICON_ORANGE, REFRESH_ICON_BLUE,
        EXPORT_ICON,EXPORT_ICON_ORANGE, DELETE_ICON_BLUE,
        CLOUD_UPLOAD_ICON_BLUE, CANCEL_MARK_ICON, PREVIEW_ICON_BLUE,
        TICK_MARK_ICON, DOWNLOAD_ICON_BLUE, COPY_ICON_BLUE,
        DEACTIVATE_ICON_BLUE
    },
    MODEL_RESPONSE:{
        NO_DATA,NO_ACCESS,NO_ENOUGH_CREDITS,NO_FACE,NO_TEXT,
        NO_LANDMARK,NO_LOGO,NO_OBJECT,NO_PLACE,NO_WEBLINK, NO_FAVORITES,
        ACCOUNT_EXPIRED,USER_EXPIRED,ERROR,NO_EQUIPMENT,NO_BARCODE,NO_IMAGEIDENTIFIER,
        ACCOUNT_UNSUBSCRIBED
    },
    FEEDBACK_EMOTIONS:{
        SATISFIED_FACE,VERY_SATISFIED_FACE,UNSATISFIED_FACE,VERY_UNSATISFIED_FACE,NEUTRAL_FACE
    }
}