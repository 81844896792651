import Helmet from "react-helmet"
import { isEmpty } from "../../utils/UtilityFunctions";
import { SEPARATORS } from "../../strings/Strings";

function ComponentHelmet (props) {
    const { path, title, description, image } = props;
    const titleText = title.filter((text) => !isEmpty(text)).join(SEPARATORS.SPACE+SEPARATORS.PIPE+SEPARATORS.SPACE)
    return (
        <Helmet defer={false}>
            <title>{titleText}</title>
            <meta name="title" content={titleText} />
            <meta name="description" content={description} />
            {/* <link rel="canonical" href={path} /> */}
            <meta property="og:title" content={titleText} />
            <meta property="og:description"
                content={description} />
            <meta property="og:image" content={image || (process.env.REACT_APP_S3_PUBLIC_BUCKET_BASE_URL+"/assets/vivasai-logo-light") }/>
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property="og:url" content={path}/>
            <meta name="twitter:title" content={titleText} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image || (process.env.REACT_APP_S3_PUBLIC_BUCKET_BASE_URL+"+/assets/vivasai-logo-light") } />
            <meta name="twitter:url" content={path}/>
        </Helmet>
    )
}

export default ComponentHelmet;