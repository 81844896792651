import { Component } from "react";
import { GET_LICENSEE_DETAILS, GET_LICENSEE_KEY } from "../../../urls/Urls";
import { LEAF_PRODUCTS, LICENSE_ACTIONS, LICENSE_STATUS, SORT_TABLE_VALUES, SUBSCRIPTION_PRODUCTS, TEXTAREA_LENGTH, USER_ROLE } from "../../../utils/Constants";
import axios from "axios";
import { getCognitoUserAccessToken } from "../../sign_up/Cognito.utils";
import { INITIAL_SELF_HOSTING_STATE, getLicenseeData, getErrorLicenseeData, SELF_HOSTING_FORM_STATES } from "../UserManagement.utils";
import { copyToClipboard, downloadCsvFile, downloadJsonFile, getDownloadFileNameWithTime, getPlanDetails, isEmpty, isEmptyList, isEmptyObject, isValidNumber, isValidSecret, isValidTextString } from "../../../utils/UtilityFunctions";
import ErrorPage from "../../../components/error_page/ErrorPage";
import Loader from "../../../components/loader/Loader";
import cx from "classnames";
import classes from "../UserManagement.module.css";
import globalClasses from '../../../App.module.css';
import { BsFillCircleFill } from "react-icons/bs";
import { MdInfoOutline, MdKey, MdOutlineKeyboardArrowRight } from "react-icons/md";
import CopyButton from "../../../components/ui_elements/copy_button/CopyButton";
import { BUTTON_TEXTS, EMPTY_STRING, SEPARATORS, TOASTER_THEME, TOASTER_TYPE } from "../../../strings/Strings";
import { IMAGES } from "../../../utils/ImageConstants";
import SelfHostingInfo from "../../self_hosting_info/SelfHostingsInfo";
import SortingTable from "../../../components/sorting_table/SortingTable";
import { getTableTimeData } from "../../../utils/UIUtilityFunctions";
import NoData from "../../../components/no_data/NoData";
import Card from "../../../components/ui_elements/card/Card";
import TextField from "../../../components/ui_elements/form_fields/text_input/TextField";
import AccountCreationPopup from "../AccountCreatePopup";
import TextAreaField from "../../../components/ui_elements/form_fields/teaxt_area_input/TextAreaField";
import { USER_MNGMENT_STRINGS } from "../../../strings/UserManagement";
import ConfirmPopup from "../../../components/confirm_popup/ConfirmPopup";
import { toast } from "react-toastify";
import Button from "../../../components/ui_elements/button/ButtonNew";
import { BUTTON_TYPES } from "../../../utils/ButtonConstants";
import ButtonGroup from "../../../components/ui_elements/button/ButtonGroup";
import InfoToolTip from "../../../components/ui_elements/info_tool_tip/InfoToolTip";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField";

const SELF_HOSTING_LICENESEE = {
    AUTHENTICATE_LICENSE : {
        key : "authenticate-license",
        label : "Licensee Details"
    },
    PRODUCT_LICENSE : {
        key : "product-license",
        label : "Your License"
    }
}

const LICENSE_INFO = {
    LICENSE_DETAILS : {
        label : "Licensee Details",
        key : "licensee-details"
    },
    DOCUMENTATION : {
        label : "Documentation",
        key : "documentation"
    }
}

class SelfHostingPane extends Component{

    state = { ...INITIAL_SELF_HOSTING_STATE, selectedLicenseMenu : SELF_HOSTING_LICENESEE.AUTHENTICATE_LICENSE, selectedInfo : LICENSE_INFO.LICENSE_DETAILS.key }

    getLicenseeDetails = () => {
        let { userRole, accountUUID } = this.props;
        
        if(document.body.style.overflow !== "auto"){
            document.body.style.overflow = "auto"
        }

        this.setState({
            isSelfHostLoading : true
        }, () => {
            let url = GET_LICENSEE_DETAILS + "?account_id="+accountUUID
            // if(userRole === USER_ROLE.SUPER_ADMIN.LABEL){
            //     url = url + "?account_id="+accountUUID
            // }
            axios.get(url, {
                headers:{
                    Authorization: "Bearer "+getCognitoUserAccessToken()
                }}
            )
                .then((response) => {
                    this.setState({
                        ...getLicenseeData(response.data)
                    })
                }).catch((err) => {
                    this.setState({
                        ...getErrorLicenseeData()
                    })
                })
        })
    }
    
    componentDidMount() {
        let { isActiveTab } = this.props;
        if (isActiveTab) {
            this.getLicenseeDetails()
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.isActiveTab !== this.props.isActiveTab) {
            let { isActiveTab } = this.props;
            if (isActiveTab) {
                this.getLicenseeDetails();
            }
        }
    }

    generateLicenseeKey = (licenseeID,copy) => {
        const { accountUUID } = this.props;
        let url = GET_LICENSEE_KEY
        let requestBody = {
            "licensee_id" : licenseeID,
            "account_id" : accountUUID
        }
        axios.post(url, requestBody, {
            headers:{
                Authorization: "Bearer "+getCognitoUserAccessToken()
            }}
        )
            .then((response) => {
                let licenseeKey = response.data?.licenseeKey;
                if(copy){
                    copyToClipboard(licenseeKey)
                } else {
                    let fileName = getDownloadFileNameWithTime(accountUUID)
                    let csvData = [
                        ["Account ID","Licensee ID","License Key"],
                        [accountUUID,licenseeID,licenseeKey]
                    ].map(ele => ele.join(','))
                        .join('\n')
                    downloadCsvFile([fileName,"("+licenseeID+")"].join(SEPARATORS.SPACE), csvData);
                }
            }).catch((err) => {
                console.log(err?.message)
            })
            .finally(() => {
                this.setState({
                    gettingLicenseeKey : false
                })
            })
    }

    onClickCopyIcon = (licenseeID) => {
        this.setState({
            gettingLicenseeKey : true
        },() => {
            this.generateLicenseeKey(licenseeID,true)
        })
    }

    onClickDownloadIcon = (licenseeID) => {
        this.setState({
            gettingLicenseeKey : true
        },() => {
            this.generateLicenseeKey(licenseeID,false)
        })
    }

    onChangeLicenseeMenu = (menuInfo) => {
        this.setState({
            selectedLicenseMenu : menuInfo
        })
    }

    onChangeLicenseeMenuProduct = (productId) => {
        this.setState({
            selectedProductLicense : productId
        }, () => {
            this.onChangeLicenseeMenu(SELF_HOSTING_LICENESEE.AUTHENTICATE_LICENSE)
        })
    }

    onCloseSuccessPopup = () => {
        const { refreshData } = this.props;
        if(document.body.overflow !== "auto"){
            document.body.overflow = "auto"
        }
        this.setState({
            ...SELF_HOSTING_FORM_STATES,
            showFormComponent : false,
            showConfirmationComponent : false,
            showSuccessPopup : false,
            apiResponseData : {}
        }, refreshData)
    }

    onCloseConfirmationPopup = () => {
        if(document.body.overflow !== "auto"){
            document.body.overflow = "auto"
        }
        this.setState({
            ...SELF_HOSTING_FORM_STATES,
            showFormComponent : false,
            showConfirmationComponent : false,
            showSuccessPopup : false,
            apiResponseData : {}
        })
    }

    onClickDownloadSuccessPopup = (tokenType) => {
        const { apiResponseData } = this.state;
        const { accountUUID, refreshData } = this.props;
        if(document.body.overflow !== "auto"){
            document.body.overflow = "auto"
        }

        let fileName = getDownloadFileNameWithTime(accountUUID)
        let csvData = [
            ["Account ID","License ID",tokenType],
            [accountUUID,apiResponseData?.licenseeID,apiResponseData?.token]
        ].map(ele => ele.join(','))
            .join('\n')
        downloadCsvFile([fileName,"("+apiResponseData?.licenseeID+")"].join(SEPARATORS.SPACE), csvData);

        this.setState({
            ...SELF_HOSTING_FORM_STATES,
            showFormComponent : false,
            showConfirmationComponent : false,
            showSuccessPopup : false,
            apiResponseData : {}
        }, refreshData)
    }

    onCloseFormPopup = () => {
        if(document.body.overflow !== "auto"){
            document.body.overflow = "auto"
        }
        this.setState({
            ...SELF_HOSTING_FORM_STATES,
            showFormComponent : false,
            showConfirmationComponent : false,
            showSuccessPopup : false
        })
    }

    onConfirmDelete = () => {
        const { formSubmissionData } = this.state;
        const { refreshData } = this.props;
        this.setState({
            isSubmitting : true,
        }, () => {
            let url = GET_LICENSEE_DETAILS + "?account_id="+formSubmissionData?.accountID + "&licensee_id=" + formSubmissionData?.licenseeID;
            let headers = {
                Authorization : "Bearer " + getCognitoUserAccessToken()
            }
            axios.delete(url,{headers})
                .then((response) => {
                    toast("The license has been removed from your account.",
                        {
                            type: TOASTER_TYPE.SUCCESS,
                            theme: TOASTER_THEME.LIGHT
                        });
                        this.onCloseSuccessPopup()
                })
                .catch((err) => {
                    let errMessage = err?.response?.data?.message || err?.message || err?.response?.statusText || 'Error in removing the licensee.'
                    toast(errMessage,
                        {
                            type: TOASTER_TYPE.ERROR,
                            theme: TOASTER_THEME.LIGHT
                        });
                })
        })
        
    }

    onSubmitFormHandler = (currentSubscription) => {
        const { formSubmissionData } = this.state;
        
        let formAction = formSubmissionData?.action;
        let formSubmissionsError = {};
        let successMessage;
        if(formAction === LICENSE_ACTIONS.ACTIVATE){
            successMessage = "Your license has been successfully created, and the balance has been deducted from your account."
            formSubmissionsError["aliasName"] = isValidSecret(formSubmissionData?.aliasName, TEXTAREA_LENGTH.SECRET_NAME.MINIMUM, TEXTAREA_LENGTH.SECRET_NAME.MAXIMUM, true).error
            formSubmissionsError["addBalance"] = this.isValidBalance(formSubmissionData?.addBalance, currentSubscription?.balance, true).error
            formSubmissionsError["activationToken"] = isValidTextString(formSubmissionData?.activationToken, true).error
        }
        if(formAction === LICENSE_ACTIONS.UPDATE){
            successMessage = "Your license has been successfully updated, and the balance has been deducted from your account."
            formSubmissionsError["addBalance"] = this.isValidBalance(formSubmissionData?.addBalance, currentSubscription?.balance, true).error
            formSubmissionsError["updateToken"] = isValidTextString(formSubmissionData?.updateToken, true).error
        }
        if(formAction === LICENSE_ACTIONS.DEACTIVATE){
            successMessage = "The license has been deactivated, and the balance has been refunded to your account."
            formSubmissionsError["deactivationToken"] = isValidTextString(formSubmissionData?.deactivationToken, true).error
        }
        Object.keys(formSubmissionsError).forEach((key) => {
            if(!formSubmissionsError[key]){
                delete formSubmissionsError[key]
            }
        })
        if(isEmptyObject(formSubmissionsError)){
            this.setState({
                isSubmitting : true,
                isSubmitError : false
            }, () => {
                let url = GET_LICENSEE_DETAILS;
                let headers = {
                    Authorization : "Bearer " + getCognitoUserAccessToken()
                }
                let requestBody = Object.assign({},formSubmissionData);
                if(requestBody?.addBalance){
                    requestBody["addBalance"] = Number(requestBody?.addBalance)
                }
                axios.post(url,requestBody,{headers})
                    .then((response) => {
                        let showSuccessPopup = formAction !== LICENSE_ACTIONS.DEACTIVATE;
                        let apiResponseData = {};
                        if(showSuccessPopup){
                            apiResponseData = {
                                licenseeID : response?.data?.licensee_id,
                                token : response?.data?.token,
                            }
                        }
                        this.setState({
                            apiResponseData,
                            isSubmitting : false,
                            showSuccessPopup,
                            showFormComponent : false
                        }, () => {
                            toast(successMessage,
                                {
                                    type: TOASTER_TYPE.SUCCESS,
                                    theme: TOASTER_THEME.LIGHT
                                });
                        })
                    })
                    .catch((err) => {
                        let errMessage = err?.response?.data?.message || err?.message || err?.response?.statusText || 'Something went wrong, please try again after some time.'
                        toast(errMessage,
                            {
                                type: TOASTER_TYPE.ERROR,
                                theme: TOASTER_THEME.LIGHT
                            });
                        this.setState({
                            apiResponseData : {},
                            isSubmitting : false
                        })
                    })
                    .finally(() => {
                        if(formAction === LICENSE_ACTIONS.DEACTIVATE){
                            this.onCloseSuccessPopup()
                        }
                    })
            })
        } else {
            this.setState({
                formSubmissionsError,
                isSubmitError : true
            })
        }
    }

    onClickDeleteLicenseHandler = (licenseeID, aliasName) => {
        const { accountUUID } = this.props;
        let formSubmissionData = {};

        formSubmissionData["accountID"] = accountUUID;
        formSubmissionData["licenseeID"] = licenseeID;
        formSubmissionData["aliasName"] = aliasName;

        this.setState({
            formSubmissionData,
            formSubmissionsError : {},
            isSubmitting : false,
            isSubmitError : false,
            showConfirmationComponent : true
        })

    }

    onClickDeactivateLicenseHandler = (currentSubscription, licenseeID) => {
        const { accountUUID } = this.props;
        let formSubmissionData = {};

        formSubmissionData["accountID"] = accountUUID;
        formSubmissionData["action"] = LICENSE_ACTIONS.DEACTIVATE;
        formSubmissionData["productName"] = currentSubscription?.productName
        formSubmissionData["subscriptionID"] = currentSubscription?.subscription_id;
        formSubmissionData["licenseeID"] = licenseeID;
        formSubmissionData["deactivationToken"] = EMPTY_STRING;

        this.setState({
            formSubmissionData,
            formSubmissionsError : {},
            isSubmitting : false,
            isSubmitError : false,
            showFormComponent : true
        })

    }

    onClickUpdateLicenseHandler = (currentSubscription, licenseeID) => {
        const { accountUUID } = this.props;
        let formSubmissionData = {};

        formSubmissionData["accountID"] = accountUUID;
        formSubmissionData["action"] = LICENSE_ACTIONS.UPDATE;
        formSubmissionData["productName"] = currentSubscription?.productName
        formSubmissionData["subscriptionID"] = currentSubscription?.subscription_id;
        formSubmissionData["licenseeID"] = licenseeID;
        formSubmissionData["addBalance"] = EMPTY_STRING;
        formSubmissionData["updateToken"] = EMPTY_STRING;

        this.setState({
            formSubmissionData,
            formSubmissionsError : {},
            isSubmitting : false,
            isSubmitError : false,
            showFormComponent : true
        })

    }

    onClickActivateLicenseHandler = (currentSubscription) => {
        const { accountUUID } = this.props;
        let formSubmissionData = {};

        formSubmissionData["accountID"] = accountUUID;
        formSubmissionData["aliasName"] = EMPTY_STRING;
        formSubmissionData["action"] = LICENSE_ACTIONS.ACTIVATE;
        formSubmissionData["productName"] = currentSubscription?.productName
        formSubmissionData["subscriptionID"] = currentSubscription?.subscription_id;
        formSubmissionData["addBalance"] = EMPTY_STRING;
        formSubmissionData["activationToken"] = EMPTY_STRING;

        this.setState({
            formSubmissionData,
            formSubmissionsError : {},
            isSubmitting : false,
            isSubmitError : false,
            showFormComponent : true
        })

    }

    isValidBalance = (value, max, isMandatory) => {
        let validation = isValidNumber(value, isMandatory)
        let error = validation.error;
        let text = validation.text;
        if (!error) {
            if (isEmpty(value) && isMandatory) {
                error = true;
                text = "This field should not be empty."
            } else if (Number(value) < 1) {
                error = true;
                text = "Balance cannot be zero or lesser than zero."
            } else if (Number(value) > max){
                error = true;
                text = `Balance cannot exceed your account's available balance (${max} Pages).`
            } else if (value.includes(SEPARATORS.DOT)){
                error = true;
                text = `Balance cannot be in decimal points.`
            }
        }
        return { error, text }
    }

    onChangeAliasName = (aliasName) => {
        const { formSubmissionData, formSubmissionsError } = this.state;
        let newformSubmissionData = Object.assign({},formSubmissionData)
        let newformSubmissionsError = Object.assign({},formSubmissionsError)
        newformSubmissionData["aliasName"] = aliasName;
        newformSubmissionsError["aliasName"] = false;
        this.setState({
            formSubmissionData : newformSubmissionData, formSubmissionsError : newformSubmissionsError
        })
    }

    onChangeAddBalance = (addBalance) => {
        const { formSubmissionData, formSubmissionsError } = this.state;
        let newformSubmissionData = Object.assign({},formSubmissionData)
        let newformSubmissionsError = Object.assign({},formSubmissionsError)
        newformSubmissionData["addBalance"] = addBalance;
        newformSubmissionsError["addBalance"] = false;
        this.setState({
            formSubmissionData : newformSubmissionData, formSubmissionsError : newformSubmissionsError
        })
    }

    onChangeToken = (token) => {
        const { formSubmissionData, formSubmissionsError } = this.state;
        let newformSubmissionData = Object.assign({},formSubmissionData)
        let newformSubmissionsError = Object.assign({},formSubmissionsError)
        if(newformSubmissionData?.action === LICENSE_ACTIONS.ACTIVATE){
            newformSubmissionData["activationToken"] = token;
            newformSubmissionsError["activationToken"] = false;
        }
        if(newformSubmissionData?.action === LICENSE_ACTIONS.UPDATE){
            newformSubmissionData["updateToken"] = token;
            newformSubmissionsError["updateToken"] = false;
        }
        if(newformSubmissionData?.action === LICENSE_ACTIONS.DEACTIVATE){
            newformSubmissionData["deactivationToken"] = token;
            newformSubmissionsError["deactivationToken"] = false;
        }
        this.setState({
            formSubmissionData : newformSubmissionData, formSubmissionsError : newformSubmissionsError
        })
    }

    setSelectedInfo = (infoKey) => {
        this.setState({
            selectedInfo : infoKey
        })
    }

    render(){
        const { isErrorLicenseeData, isSelfHostLoading, licenseeData, gettingLicenseeKey, isSubmitting, isSubmitError, selectedLicenseMenu, selectedProductLicense, showFormComponent, 
            formSubmissionData, formSubmissionsError, apiResponseData, showConfirmationComponent, showSuccessPopup, selectedInfo } = this.state;
        const { subscriptions, accountUUID, userRole } = this.props;
        let selfHostingDataComponent = null;

        let liceneeDetailsContainerClass = cx("col-12 mb-4",classes.LicenseeDetailsContainer)
        let liceneeDetailsInfoContainerClass = cx("mt-3 row flex-wrap")
        let licenseeDataContainerClass = cx("col-12",classes.LicenseeDataContainer)
        let licenseeKeyContainerClass = cx("col-12",classes.LicenseeKeyContainer)
        let progressClass = cx(["progress",classes.Progress])
        let progressBarClass = cx(["progress-bar",classes.ProgressBar])
        let progressValueClass = cx([classes.ValueText])
        let progressContainerClass = cx([classes.Container])

        if (isErrorLicenseeData) {
            selfHostingDataComponent = <ErrorPage />
        } else {
            if(isSelfHostLoading){
                selfHostingDataComponent = <Loader />
            } else {
                let licenseeDetailsComponent = null;
                let formComponent = null;
                let successComponent = null;
                let licenseInfoComponent = null;
                let productButtonGroupComponent = null;

                licenseInfoComponent = (
                    <div className="row flex-wrap mb-4">
                        <Button
                            id={LICENSE_INFO.LICENSE_DETAILS.key}
                            containerClass={"col-4 col-md-3 col-xl-2"}
                            className={cx("col-12")}
                            label={LICENSE_INFO.LICENSE_DETAILS.label}
                            onClickHandler={() => this.setSelectedInfo(LICENSE_INFO.LICENSE_DETAILS.key)}
                            actionType={selectedInfo === LICENSE_INFO.LICENSE_DETAILS.key ? BUTTON_TYPES.ACTIONS.PRIMARY : BUTTON_TYPES.ACTIONS.SECONDARY}
                            radiusType={BUTTON_TYPES.RADIUS.PILL}
                            borderType={BUTTON_TYPES.BORDER.BORDERED}
                        />
                        <Button
                            id={LICENSE_INFO.DOCUMENTATION.key}
                            containerClass={"col-4 col-md-3 col-xl-2"}
                            className={cx("col-12")}
                            label={LICENSE_INFO.DOCUMENTATION.label}
                            onClickHandler={() => this.setSelectedInfo(LICENSE_INFO.DOCUMENTATION.key)}
                            actionType={selectedInfo === LICENSE_INFO.DOCUMENTATION.key ? BUTTON_TYPES.ACTIONS.PRIMARY : BUTTON_TYPES.ACTIONS.SECONDARY}
                            radiusType={BUTTON_TYPES.RADIUS.PILL}
                            borderType={BUTTON_TYPES.BORDER.BORDERED}
                        />
                        {/* <SelectField
                            containerClass={"ms-auto col-4 col-md-3 col-xl-2"}
                            selectClassName={classes.ProductDropDown}
                            value={selectedProductLicense}
                            onChangeHandler={this.onChangeLicenseeMenuProduct}
                            id={"Product-DropDown"}
                            isSearchable={false}
                            optionsList={[
                                {
                                    value : SUBSCRIPTION_PRODUCTS.LEAF_IDP.ID.toLowerCase(),
                                    label : SUBSCRIPTION_PRODUCTS.LEAF_IDP.NAME,
                                    hide : isEmpty(licenseeData?.authentication) || licenseeData?.authentication.findIndex((lData => lData?.productID === SUBSCRIPTION_PRODUCTS.LEAF_IDP.ID.toLowerCase()))
                                },
                                {
                                    value : SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.ID.toLowerCase(),
                                    label : SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.NAME,
                                    hide : isEmpty(licenseeData?.authentication) || licenseeData?.authentication.findIndex((lData => lData?.productID === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.ID.toLowerCase()))
                                },
                                {
                                    value : SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.ID.toLowerCase(),
                                    label : SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.NAME,
                                    hide : isEmpty(licenseeData?.authentication) || licenseeData?.authentication.findIndex((lData => lData?.productID === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.ID.toLowerCase()))
                                }
                            ]}
                        /> */}
                    </div>
                )

                productButtonGroupComponent = (
                    <ButtonGroup
                        labelList = {
                            [
                                {
                                    label : SUBSCRIPTION_PRODUCTS.LEAF_IDP.NAME,
                                    actionType : (selectedProductLicense === SUBSCRIPTION_PRODUCTS.LEAF_IDP.ID.toLowerCase()) ? BUTTON_TYPES.ACTIONS.PRIMARY : BUTTON_TYPES.ACTIONS.SECONDARY,
                                    borderType : BUTTON_TYPES.BORDER.BORDERED
                                },
                                {
                                    label : SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.NAME,
                                    actionType : (selectedProductLicense === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.ID.toLowerCase()) ? BUTTON_TYPES.ACTIONS.PRIMARY : BUTTON_TYPES.ACTIONS.SECONDARY,
                                    borderType : BUTTON_TYPES.BORDER.BORDERED
                                },
                                {
                                    label : SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.NAME,
                                    actionType : (selectedProductLicense === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.ID.toLowerCase()) ? BUTTON_TYPES.ACTIONS.PRIMARY : BUTTON_TYPES.ACTIONS.SECONDARY,
                                    borderType : BUTTON_TYPES.BORDER.BORDERED
                                }
                            ]
                        }
                    />
                )

                const currentAuthenticateLicenseData = !isEmptyList(licenseeData?.authentication) ? licenseeData?.authentication.filter(lData => lData?.productID === selectedProductLicense) : [];
                let planDetails = {}
                let planTenureDays = 1;
                let currentProductSubscriptionData = []
                
                if(selectedProductLicense === SUBSCRIPTION_PRODUCTS.LEAF_IDP.ID.toLowerCase()){
                    currentProductSubscriptionData = subscriptions.filter(sub => sub?.productName===SUBSCRIPTION_PRODUCTS.LEAF_IDP.NAME)
                    if(!isEmptyList(currentProductSubscriptionData)){
                        planDetails = getPlanDetails(currentProductSubscriptionData[0]?.subscription_id)
                        planTenureDays = planDetails?.planTenure?.VALUE * 30
                    }
                }
                if(selectedProductLicense === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.ID.toLowerCase()){
                    currentProductSubscriptionData = subscriptions.filter(sub => sub?.productName===SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.NAME)
                    if(!isEmptyList(currentProductSubscriptionData)){
                        planDetails = getPlanDetails(currentProductSubscriptionData[0]?.subscription_id)
                        planTenureDays = planDetails?.planTenure?.VALUE * 30
                    }
                }
                if(selectedProductLicense === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.ID.toLowerCase()){
                    currentProductSubscriptionData = subscriptions.filter(sub => sub?.productName===SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.NAME)
                    if(!isEmptyList(currentProductSubscriptionData)){
                        planDetails = getPlanDetails(currentProductSubscriptionData[0]?.subscription_id)
                        planTenureDays = planDetails?.planTenure?.VALUE * 30
                    }
                }
                if(!isEmptyObject(formSubmissionData)){
                    let title = EMPTY_STRING;
                    let formButton = "Submit"
                    let successTokenLabel = "Token"
                    let tooltipText = EMPTY_STRING
                    let idToolTipText = EMPTY_STRING
                    if(formSubmissionData?.action === LICENSE_ACTIONS.ACTIVATE){
                        title = "Create License"
                        formButton = "Create"
                        successTokenLabel = "Activation Token"
                        tooltipText = "The number of pages you'd like to add to the license you're creating."
                        idToolTipText = "The 'activation_id' generated by calling the '/generate-id' container endpoint."
                    } else if(formSubmissionData?.action === LICENSE_ACTIONS.UPDATE){
                        title = "Update License"
                        formButton = "Update"
                        successTokenLabel = "Update Token"
                        tooltipText = "The number of pages you'd like to add to the license you're updating."
                        idToolTipText = "The 'update_id' generated by calling the '/generate-id' container endpoint."
                    } else if(formSubmissionData?.action === LICENSE_ACTIONS.DEACTIVATE){
                        title = "Deactivate & Delete License"
                        formButton = "Deactivate"
                        idToolTipText="The 'deactivation_id' generated by calling the '/generate-id' container endpoint."
                    }
                    if(showSuccessPopup) {
                        formComponent = (
                            <AccountCreationPopup
                                title={title}
                                onCloseHandler={this.onCloseSuccessPopup}
                                onSubmitHandler={() => this.onClickDownloadSuccessPopup(successTokenLabel)}
                                isSubmitting={isSubmitting}
                                submitButtonText={BUTTON_TEXTS.DOWNLOAD}
                                noteText={"Please download or copy the token as it will no longer be accessible."}
                            >
                                <div className="d-flex flex-wrap align-items-start mb-3">
                                    <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="licenseeID">
                                        {"Licensee ID"}
                                    </label>
                                    <div className="col-12 col-lg-8">
                                        <span>
                                            {apiResponseData?.licenseeID}
                                            {/* {apiResponseData?.licenseeID.substring(0, 6) + (apiResponseData?.licenseeID.substring(6, 14).replace(/\S/g, '*'))} */}
                                        </span>
                                        <CopyButton
                                            className={cx(['ms-2', classes.Icon])}
                                            imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                                            copyText={"Copy Licensee ID"}
                                            copiedText={'Copied'}
                                            onClickCopy={() => copyToClipboard(apiResponseData?.licenseeID)}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-start mb-3">
                                    <label className={cx(['col-12 col-lg-4 mb-2 mb-lg-0'])} htmlFor="token">
                                        {successTokenLabel}
                                    </label>
                                    <div className="col-12 col-lg-8">
                                        <span className={classes.PopupBearerContent}>
                                            {apiResponseData?.token.substring(0, 200) + "..."}
                                            {/* {apiResponseData?.token.substring(0, 6) + (apiResponseData?.token.substring(6, 14).replace(/\S/g, '*'))} */}
                                        </span>
                                        <CopyButton
                                            className={cx(['ms-2', classes.Icon])}
                                            imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                                            copyText={"Copy Token"}
                                            copiedText={'Copied'}
                                            onClickCopy={() => copyToClipboard(apiResponseData?.token)}
                                        />
                                    </div>
                                </div>
                            </AccountCreationPopup>
                        )
                    } else if(showFormComponent){
                        formComponent = (
                            <AccountCreationPopup
                                title={title}
                                onCloseHandler={this.onCloseFormPopup}
                                onSubmitHandler={() => this.onSubmitFormHandler(currentProductSubscriptionData[0])}
                                isSubmitting={isSubmitting}
                                submitButtonText={formButton}
                            >
                                <div className="d-flex flex-wrap">
                                    <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="productName">
                                        {"Product Name"}
                                    </label>
                                    <div className="col-12 col-lg-8">
                                        <TextField
                                            containerClass='col-12'
                                            value={formSubmissionData?.productName || EMPTY_STRING}
                                            id={'productName'}
                                            isDisabled={true}
                                            isReadOnly={true}
                                        />
                                    </div>
                                </div>
                                {
                                    (formSubmissionData?.action !== LICENSE_ACTIONS.ACTIVATE) &&
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="licenseeID">
                                            {"License ID"}
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={formSubmissionData?.licenseeID || EMPTY_STRING}
                                                id={'licenseeID'}
                                                isDisabled={true}
                                                isReadOnly={true}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    (formSubmissionData?.action === LICENSE_ACTIONS.ACTIVATE) &&
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="aliasName">
                                            {"Alias Name"}
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={formSubmissionData?.aliasName || EMPTY_STRING}
                                                id={'aliasName'}
                                                placeHolder={'Alias Name (example : FINANCE_SERVER, BILLING_SERVER, INVOICE_SERVER )'}
                                                onChangeHandler={this.onChangeAliasName}
                                                validate={(value,isMandatory) => isValidSecret(value, TEXTAREA_LENGTH.SECRET_NAME.MINIMUM, TEXTAREA_LENGTH.SECRET_NAME.MAXIMUM, isMandatory)}
                                                isDisabled={isSubmitting}
                                                isReadOnly={isSubmitting}
                                                isError={isSubmitError}
                                                isFieldError={formSubmissionsError?.aliasName || false}
                                                isMandatory={true}
                                                isNumber={false}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    (formSubmissionData?.action !== LICENSE_ACTIONS.DEACTIVATE) &&
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="balance">
                                            {"Balance Addition"}<MdInfoOutline title={tooltipText} className="ms-1"/>
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={formSubmissionData?.addBalance || EMPTY_STRING}
                                                id={'balance'}
                                                placeHolder={'Add balance for license'}
                                                onChangeHandler={this.onChangeAddBalance}
                                                validate={(value,isMandatory) => this.isValidBalance(value, currentProductSubscriptionData?.[0]?.balance, isMandatory)}
                                                isDisabled={isSubmitting}
                                                isReadOnly={isSubmitting}
                                                isError={isSubmitError}
                                                isFieldError={formSubmissionsError?.addBalance || false}
                                                isMandatory={true}
                                                isNumber={true}
                                                autoComplete={false}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    (formSubmissionData?.action === LICENSE_ACTIONS.ACTIVATE) &&
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="activationToken">
                                            {"Activation ID"}<MdInfoOutline title={idToolTipText} className="ms-1"/>
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={formSubmissionData?.activationToken || EMPTY_STRING}
                                                id={'token'}
                                                placeHolder={'Activation ID'}
                                                onChangeHandler={this.onChangeToken}
                                                validate={isValidTextString}
                                                isDisabled={isSubmitting}
                                                isReadOnly={isSubmitting}
                                                isError={isSubmitError}
                                                isFieldError={formSubmissionsError?.activationToken || false}
                                                isMandatory={true}
                                                isNumber={false}
                                                autoComplete={false}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    (formSubmissionData?.action === LICENSE_ACTIONS.UPDATE) &&
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="updateToken">
                                            {"Update ID"}<MdInfoOutline title={idToolTipText} className="ms-1"/>
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={formSubmissionData?.updateToken || EMPTY_STRING}
                                                id={'updateToken'}
                                                placeHolder={'Update ID'}
                                                onChangeHandler={this.onChangeToken}
                                                validate={isValidTextString}
                                                isDisabled={isSubmitting}
                                                isReadOnly={isSubmitting}
                                                isError={isSubmitError}
                                                isFieldError={formSubmissionsError?.updateToken || false}
                                                isMandatory={true}
                                                isNumber={false}
                                                autoComplete={false}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    (formSubmissionData?.action === LICENSE_ACTIONS.DEACTIVATE) &&
                                    <div className="d-flex flex-wrap">
                                        <label className={cx(['col-12 col-lg-4 mt-2 mb-2 mb-lg-0'])} htmlFor="deactivationToken">
                                            {"Deactivation ID"}<MdInfoOutline title={idToolTipText} className="ms-1"/>
                                        </label>
                                        <div className="col-12 col-lg-8">
                                            <TextField
                                                containerClass='col-12'
                                                value={formSubmissionData?.deactivationToken || EMPTY_STRING}
                                                id={'deactivationToken'}
                                                placeHolder={'Deactivation ID'}
                                                onChangeHandler={this.onChangeToken}
                                                validate={isValidTextString}
                                                isDisabled={isSubmitting}
                                                isReadOnly={isSubmitting}
                                                isError={isSubmitError}
                                                isFieldError={formSubmissionsError?.deactivationToken || false}
                                                isMandatory={true}
                                                isNumber={false}
                                                autoComplete={false}
                                            />
                                        </div>
                                    </div>
                                }
                            </AccountCreationPopup>
                        )   
                    } else if (showConfirmationComponent){
                        formComponent = (
                            <ConfirmPopup
                                popupClass={globalClasses.PopupBack}
                                isSubmitting={isSubmitting}
                                submittingText={USER_MNGMENT_STRINGS.DELETING}
                                modalClass={'col-11 col-md-8 col-lg-6 ps-3 ps-md-4'}
                                title={"Delete License"}
                                subtext={`Are you sure you want to delete your license? Any unused pages associated with '${formSubmissionData?.aliasName}' license will be lost. If you prefer, you can deactivate the license to have the balance refunded.`}
                                primaryButtonText={BUTTON_TEXTS.DELETE}
                                secondaryButtonText={BUTTON_TEXTS.CANCEL}
                                onClickPrimaryButton={this.onConfirmDelete}
                                onClickSecondaryButton={this.onCloseConfirmationPopup}
                                isPopupFixed={true}
                                enableScroll={false}
                                onCloseModalCard={this.onCloseConfirmationPopup}
                            />
                        )
                    }
                }
                if(selectedLicenseMenu.key === SELF_HOSTING_LICENESEE.AUTHENTICATE_LICENSE.key){
                    licenseeDetailsComponent = (
                        <>
                            <div className="col-12">
                                <div className={licenseeDataContainerClass}>
                                    {
                                        currentAuthenticateLicenseData[0]?.remainingDays>=0 &&
                                        <div className={progressContainerClass}>
                                            <div className={progressClass}>
                                                <div 
                                                    className={progressBarClass}
                                                    role="progressbar"
                                                    style={{width: `${Math.round((currentAuthenticateLicenseData[0]?.remainingDays/planTenureDays)*100)}%`}}>
                                                </div>
                                            </div>
                                            <span className={progressValueClass}>{currentAuthenticateLicenseData[0]?.remainingDays} days left</span>
                                        </div>
                                    }
                                    {/* <progress className="col-12" id="remaining-days" value={licenseeData?.remainingDays} max={planTenureDays}> {licenseeData?.remainingDays} days left </progress> */}
                                    <div className="row flex-wrap mt-2">
                                        <label className="col-4 mt-3">Licensee ID</label>
                                        <span className="col-8 mt-3">
                                            {currentAuthenticateLicenseData[0]?.licenseeID}
                                            <span>
                                                <CopyButton
                                                    className={cx(['ms-2', classes.LicenseeActionIcon])}
                                                    imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                                                    copyText={"Copy License ID"}
                                                    copiedText={'Copied License ID'}
                                                    onClickCopy={() => copyToClipboard(currentAuthenticateLicenseData[0]?.licenseeID)}
                                                />
                                            </span>
                                        </span>
                                        <label className="col-4 mt-3">Account ID</label>
                                        <span className="col-8 mt-3">
                                            {accountUUID}
                                            <span>
                                                <CopyButton
                                                    className={cx(['ms-2', classes.LicenseeActionIcon])}
                                                    imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                                                    copyText={"Copy Account ID"}
                                                    copiedText={'Copied Account ID'}
                                                    onClickCopy={() => copyToClipboard(accountUUID)}
                                                />
                                            </span>
                                        </span>
                                        <label className="col-4 mt-3">Expiry Date</label>
                                        <span className="col-8 mt-3">{currentAuthenticateLicenseData[0]?.expiryDate}</span>
                                        <label className="col-4 mt-3">Status</label>
                                        <span className="col-8 mt-3">
                                            {
                                                (currentAuthenticateLicenseData[0]?.remainingDays < 0 )?
                                                <><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-expired)" />&nbsp;Expired</>
                                                :
                                                <><BsFillCircleFill size={9} style={{ margin: '0px 5px 3px 0px' }} color="var(--color-active)" />&nbsp;Active</>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                                <div className={licenseeKeyContainerClass}>
                                    <h4>License Key</h4>
                                    <div className="input-group my-3">
                                        <span className="input-group-text" id="license-key-icon">
                                            <MdKey />
                                        </span>
                                        <input id="license-key" className="form-control" value={"***************************************"} disabled={true}/>
                                    </div>
                                    <div>
                                        <span className={globalClasses.Font14Grey}>{"*The license key is passed to the container as an environment variable during its runtime for the purpose of license activation."}</span>
                                    </div>
                                    {
                                        (currentAuthenticateLicenseData[0]?.remainingDays >= 0 ) &&
                                        <div className="d-flex justify-content-end">
                                            <CopyButton
                                                className={cx(['ms-2', classes.LicenseeActionIcon, gettingLicenseeKey && classes.LicenseeActionDisabledIcon])}
                                                imageSrc={IMAGES.ACTIONS.COPY_ICON_BLUE}
                                                copyText={"Copy License Key"}
                                                copiedText={'Copied License Key'}
                                                onClickCopy={() => this.onClickCopyIcon(currentAuthenticateLicenseData[0]?.licenseeID)}
                                            />
                                            <span title={"Download License Key"} onClick={() => this.onClickDownloadIcon(currentAuthenticateLicenseData[0]?.licenseeID)}>
                                                <img
                                                    className={cx(['ms-2', classes.LicenseeActionIcon, gettingLicenseeKey && classes.LicenseeActionDisabledIcon])}
                                                    src={IMAGES.ACTIONS.DOWNLOAD_ICON_BLUE}
                                                    alt={'Download'}
                                                />
                                            </span>
                                        </div>
                                    }
                                    
                                </div>
                            </div> */}
                        </>
                    )
                }
                if(selectedLicenseMenu.key === SELF_HOSTING_LICENESEE.PRODUCT_LICENSE.key){
                    const currentProductLicenseData = !isEmptyList(licenseeData?.productLicenses) ? licenseeData?.productLicenses.filter(lData => lData?.productID === selectedProductLicense) : [];
                    if(!isEmptyList(currentProductLicenseData)){
                        licenseeDetailsComponent = (
                            <>
                                {
                                    (currentAuthenticateLicenseData[0]?.remainingDays>=0 && userRole !== USER_ROLE.SUBSCRIBED.LABEL) &&
                                    <div className={"col-12 mb-3"}>
                                        <Button
                                            id={"Create-License-Button"} 
                                            label={"Create New License"} 
                                            containerClass={"ms-auto col-12 col-sm-6 col-md-4 col-lg-2"}
                                            actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                                            radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE} 
                                            borderType={BUTTON_TYPES.BORDER.BORDERED} 
                                            onClickHandler={() => this.onClickActivateLicenseHandler(currentProductSubscriptionData[0])}
                                        />
                                    </div>
                                }
                                <SortingTable
                                    enableExports={true}
                                    exportTableName={[accountUUID,"Licenses"].join(SEPARATORS.UNDERSCORE)}
                                    exportKeysList={
                                        [
                                            {
                                                key : "licenseeID",
                                                label : "License ID"
                                            },
                                            {
                                                key : "aliasName",
                                                label : "Alias Name"
                                            },
                                            {
                                                key : "balance",
                                                label : "Balance"
                                            },
                                            {
                                                key : "licenseStatus",
                                                label : "Status"
                                            },
                                            {
                                                key : "expiryDate",
                                                label : "Valid Up To"
                                            }
                                        ]
                                    }
                                    enableSearch={true}
                                    searchKeysList={
                                        [
                                            {
                                                key : "licenseeID",
                                                label : "License ID",
                                                value : "License ID",
                                                placeholder : "Search by "+"License ID"
                                            },
                                            {
                                                key : "aliasName",
                                                label : "Alias Name",
                                                value : "Alias Name",
                                                placeholder : "Search by "+"Alias Name"
                                            }
                                        ]
                                    }
                                    headingList={
                                        [
                                            {
                                                key : "licenseeID",
                                                label : "License ID",
                                                isDate : false
                                            },
                                            {
                                                key : "aliasName",
                                                label : "Alias Name",
                                                isDate : false
                                            },
                                            {
                                                key : 'balance',
                                                label : "Last Updated",
                                                isDate : false,
                                                renderItem : (item) => {
                                                    let value = item.balance
                                                    if(item?.productID === SUBSCRIPTION_PRODUCTS.LEAF_IDP.ID.toLowerCase()){
                                                        value = item.balance + SEPARATORS.SPACE + SUBSCRIPTION_PRODUCTS.LEAF_IDP.UNITS + "(s)"
                                                    } else if(item?.productID === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.ID.toLowerCase()){
                                                        value = Number(item.balance).toFixed(5).toString() + SEPARATORS.SPACE + SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.UNITS
                                                    } else if(item?.productID === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.ID.toLowerCase()){
                                                        value = Number(item.balance).toFixed(5).toString() + SEPARATORS.SPACE + SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.UNITS
                                                    }
                                                    return(
                                                        <span>
                                                            {value}
                                                        </span>
                                                    )
                                                }
                                            },
                                            {
                                                key : "licenseStatus",
                                                label : "Status",
                                                isDate : false,
                                                renderItem : (item) => {
                                                    let value = item?.licenseStatus;
                                                    let className = classes.ActiveLicense;
                                                    if(value === LICENSE_STATUS.DELETED){
                                                        className = classes.DeletedLicense;
                                                    }
                                                    return(
                                                        <span className={className}>
                                                            {value}
                                                        </span>
                                                    )
                                                }
                                            },
                                            {
                                                key : "expiryDate",
                                                label : "Valid Up To",
                                                isDate : false
                                            },
                                            {
                                                key : "remainingDays",
                                                label : "Remaining Days",
                                                isDate : false,
                                                hide : true
                                            },
                                            {
                                                key : "actions",
                                                label : "Actions",
                                                isDate : false,
                                                unsortable : true,
                                                hide: userRole === USER_ROLE.SUBSCRIBED.LABEL,
                                                renderItem : (item) => {
                                                    if(item?.licenseStatus === LICENSE_STATUS.DELETED){
                                                        return SEPARATORS.HYPHEN;
                                                    }
                                                    return (
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                (currentAuthenticateLicenseData[0]?.remainingDays >= 0) &&
                                                                <img
                                                                    className={cx(['mx-2', classes.ActionIconBlue])}
                                                                    src={IMAGES.ACTIONS.EDIT_ICON_BLUE} alt={'Edit'}
                                                                    title={"Update License"}
                                                                    onClick={() => this.onClickUpdateLicenseHandler(currentProductSubscriptionData[0], item?.licenseeID)}
                                                                />
                                                            }
                                                            {
                                                                (currentAuthenticateLicenseData[0]?.remainingDays >= 0) &&
                                                                <img
                                                                    className={cx(['mx-2', classes.ActionIconBlue])}
                                                                    src={IMAGES.ACTIONS.DEACTIVATE_ICON_BLUE} alt={'Edit'}
                                                                    title={"Deactivate & Delete License"}
                                                                    onClick={() => this.onClickDeactivateLicenseHandler(currentProductSubscriptionData[0], item?.licenseeID)}
                                                                />
                                                            }
                                                            <img
                                                                className={cx(['mx-2', classes.ActionIconBlue, classes.RefreshIcon])}
                                                                src={IMAGES.ACTIONS.DELETE_ICON_BLUE} alt={'Delete'}
                                                                title={"Delete License"}
                                                                onClick={() => this.onClickDeleteLicenseHandler(item?.licenseeID, item?.aliasName)}
                                                            />
                                                        </div>
                                                    )
                                                }
                                    
                                            }
                                        ]
                                    }
                                    containerClass={"mb-3"}
                                    dataList={currentProductLicenseData}
                                    itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.LICENSES}
                                />
                            </>
                        )
                    } else {
                        licenseeDetailsComponent = (
                            <Card className={"px-3 px-md-5"}>
                                <NoData
                                    mainText = {EMPTY_STRING}
                                    noImage={true}
                                    subText = {"Your account currently does not have a license for the Leaf IDP product. Click the 'Create New License' button below to continue."}
                                    buttonLabel = {(currentAuthenticateLicenseData[0]?.remainingDays>=0 && userRole !== USER_ROLE.SUBSCRIBED.LABEL)? "Create New License" : EMPTY_STRING}
                                    onClickHandler = {() => this.onClickActivateLicenseHandler(currentProductSubscriptionData[0])}
                                />
                            </Card>
                        )
                    }
                }
                selfHostingDataComponent = (
                    <div className={liceneeDetailsContainerClass}>
                        {successComponent}
                        {formComponent}
                        {licenseInfoComponent}
                        {/* {productButtonGroupComponent} */}
                        {/* <h3>License Details</h3> */}
                        {
                            (selectedInfo === LICENSE_INFO.LICENSE_DETAILS.key) &&
                            <div className={liceneeDetailsInfoContainerClass}>
                                <div className="col-3 col-md-3">
                                    <div className={cx("col-12",classes.MenuContainer)}>
                                        <label className={cx("col-12",classes.MenuItem ,(selectedLicenseMenu.key===SELF_HOSTING_LICENESEE.AUTHENTICATE_LICENSE.key) ? classes.MenuActiveItem : EMPTY_STRING)} 
                                            onClick={() => this.onChangeLicenseeMenu(SELF_HOSTING_LICENESEE.AUTHENTICATE_LICENSE)}>
                                            <span>{SELF_HOSTING_LICENESEE.AUTHENTICATE_LICENSE.label}</span><MdOutlineKeyboardArrowRight/>
                                        </label>
                                        <label className={cx("col-12",classes.MenuItem ,(selectedLicenseMenu.key===SELF_HOSTING_LICENESEE.PRODUCT_LICENSE.key) ? classes.MenuActiveItem : EMPTY_STRING)} 
                                            onClick={() => this.onChangeLicenseeMenu(SELF_HOSTING_LICENESEE.PRODUCT_LICENSE)}>
                                            <span>{SELF_HOSTING_LICENESEE.PRODUCT_LICENSE.label}</span><MdOutlineKeyboardArrowRight/>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className={"row flex-wrap"}>
                                        {licenseeDetailsComponent}
                                    </div>
                                </div>
                            </div>
                        }
                        {/* <h3>Documentations</h3> */}
                        {
                            (selectedInfo === LICENSE_INFO.DOCUMENTATION.key) &&
                            <div className={liceneeDetailsInfoContainerClass}>
                                <SelfHostingInfo accountUUID={accountUUID} licenseeID={currentAuthenticateLicenseData?.[0]?.licenseeID}/>
                            </div>
                        }
                    </div>
                )
            }
        }
        return(
            <>
                {selfHostingDataComponent}
            </>
        )
    }
}

export default SelfHostingPane;