import { useEffect, useState } from 'react';
import globalClasses from '../../../../App.module.css';
import { isEmpty, isValidNumber } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'
import { EMPTY_STRING } from '../../../../strings/Strings';
import { KEY_CODES } from '../../../../utils/Constants';
import InfoToolTip from '../../info_tool_tip/InfoToolTip';

function TextField(props) {
    let {type, autoComplete,containerClass, value, placeHolder, onChangeHandler, isDisabled, isFieldError,
        isReadOnly, label, id, isError, validate, isMandatory, isNumber, maxLength, infoText, customInputClass,
        enableVerification, isVerified, errorField, errorFieldText, onBlurHandler, matchText, title, hideErrorTextOnEmpty } = props;
    let labelComponent = null
    let [isTextError, setIsTextError] = useState(false);
    let [errorText, setErrorText] = useState(false);
    let [isFieldMandatory,setIsFieldMandatory] = useState(isMandatory || false);

    useEffect(() => {
        if(isFieldMandatory!==isMandatory){
            console.log("MANDATORY_CHANGED",isFieldMandatory,isMandatory)
            setIsFieldMandatory(isMandatory)
            if(!isEmpty(validate)){
                let validateResult = isEmpty(matchText) ? validate(value, isMandatory) : validate(matchText, value)
                setIsTextError(validateResult.error)
                setErrorText(validateResult.text)
            }
        }
    },[isMandatory])

    console.log('Submit error', isError);
    
    const validateTextField = (value) => {
        if(!isEmpty(validate)){
            let validateResult = isEmpty(matchText) ? validate(value, isFieldMandatory) : validate(matchText, value)
            console.log(id, validateResult)
            setIsTextError(validateResult.error)
            setErrorText(validateResult.text)
        }
    }

    const onChangeText = (input) => {
        let trimmedValue = input.replace(/^\s+/g, '');
        trimmedValue = trimmedValue.replace(/\s{2}/g, ' ');
        if (!isNumber || isValidNumber(trimmedValue)) {
            onChangeHandler(trimmedValue)
            if (isError) {
                validateTextField(trimmedValue)
            }
        }
    }
    const onBlurText = (input) => {
        if (!isEmpty(onBlurHandler)) {
            onBlurHandler()
        }
    }
    const onKeyDownText = (event) => {
        if (event.keyCode === KEY_CODES.ENTER_KEY) {
            onBlurText()
        }
    }

    if (isFieldError && !isTextError) {
        validateTextField(value)
    }
    if (enableVerification && isVerified) {
        if (isTextError && !errorField) {
            validateTextField(value)
        }
    }
    if (errorField) {
        if (!isTextError) {
            setIsTextError(errorField);
            setErrorText(errorFieldText);
        }
    }
    if (!isError && isTextError) {
        setIsTextError(false);
        setErrorText(EMPTY_STRING);
    }

    if (!isEmpty(label)) {
        labelComponent = (
            <label
                className={cx([globalClasses.FormLabel, isFieldMandatory ? globalClasses.FormRequiredInput : ''])}
                htmlFor={id}>
                {label}
                { !isEmpty(infoText) && <InfoToolTip text={infoText}/> }
            </label>
        )
    }
    let formFieldContainerClass = isEmpty(containerClass) ? "form-group col-12 col-md-6 px-1 px-md-3 my-3"
        : containerClass;
    let formInputClass = isEmpty(customInputClass) ? globalClasses.FormInput : customInputClass
    return (
        <div className={formFieldContainerClass}>
            {labelComponent}
            <input
                type={type}
                className={cx(["form-control",formInputClass,
                    isTextError ? globalClasses.ErrorFormInput : ''])}
                id={id}
                value={value}
                placeholder={placeHolder}
                disabled={isDisabled}
                readOnly={isReadOnly}
                onChange={(event) => onChangeText(event.target.value)}
                onBlur={(event) => onBlurText(event.target.value)}
                onKeyDown={(event) => onKeyDownText(event)}
                maxLength={maxLength}
                autoComplete={autoComplete}
                title={title || ""}
            />
            {
                (!hideErrorTextOnEmpty || !isEmpty(errorText)) &&
                <span className={globalClasses.ErrorText}>
                    {errorText}
                </span>
            }
        </div>
    )
}

export default TextField;