import { useEffect, useState } from 'react';
import globalClasses from '../../../../App.module.css';
import { isEmpty, isValidNumber } from '../../../../utils/UtilityFunctions';
import cx from 'classnames'
import { EMPTY_STRING } from '../../../../strings/Strings';
import { KEY_CODES } from '../../../../utils/Constants';
import classes from "./PasswordField.module.css";
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import InfoToolTip from '../../info_tool_tip/InfoToolTip';

function PasswordField(props) {
    let {containerClass, value, placeHolder, onChangeHandler, isDisabled, isFieldError,
        isReadOnly, label, id, isError, validate, isMandatory, isNumber, maxLength,
        enableVerification, isVerified, errorField, errorFieldText, onBlurHandler, matchText,
        infoText,onKeyUpHandler } = props;
    let labelComponent = null
    let inputComponent = null

    let [isTextError, setIsTextError] = useState(false);
    let [errorText, setErrorText] = useState(false);
    let [showText, setShowText] = useState(false);

    useEffect(() => {
        if(!isFieldError && isTextError){
            validateTextField(value)
        }
    })

    const validateTextField = (value) => {
        let validateResult = (matchText===undefined) ? validate(value, isMandatory) : validate(matchText, value)
        console.log(id, validateResult)
        setIsTextError(validateResult.error)
        setErrorText(validateResult.text)
    }

    const onChangeText = (input) => {
        let trimmedValue = input.replace(/^\s+/g, '');
        trimmedValue = trimmedValue.replace(/\s{2}/g, ' ');
        if (!isNumber || isValidNumber(trimmedValue)) {
            onChangeHandler(trimmedValue)
            if (isError) {
                validateTextField(trimmedValue)
            }
        }
    }

    const onBlurText = (input) => {
        if (!isEmpty(onBlurHandler)) {
            onBlurHandler()
        }
    }

    const onKeyDownText = (event) => {
        if (event.keyCode === KEY_CODES.ENTER_KEY) {
            onBlurText()
        }
    }

    if (isFieldError && !isTextError) {
        validateTextField(value)
    }
    if (enableVerification && isVerified) {
        if (isTextError && !errorField) {
            validateTextField(value)
        }
    }
    if (errorField) {
        if (!isTextError) {
            setIsTextError(errorField);
            setErrorText(errorFieldText);
        }
    }
    if (!isError && isTextError) {
        setIsTextError(false);
        setErrorText(EMPTY_STRING);
    }

    if (!isEmpty(label)) {
        labelComponent = (
            <label
                className={cx([globalClasses.FormLabel, isMandatory ? globalClasses.FormRequiredInput : ''])}
                htmlFor={id}>
                {label}
                { !isEmpty(infoText) && <InfoToolTip text={infoText}/> }
            </label>
        )
    }

    inputComponent = (
        <div className="input-group">
            <input
                type={showText? "text" : "password"}
                className={cx(["form-control", globalClasses.FormInput,
                    isTextError ? globalClasses.ErrorFormInput : ''])}
                id={id}
                value={value}
                placeholder={placeHolder}
                disabled={isDisabled}
                readOnly={isReadOnly}
                onChange={(event) => onChangeText(event.target.value)}
                onBlur={(event) => onBlurText(event.target.value)}
                onKeyDown={(event) => onKeyDownText(event)}
                maxLength={maxLength}
                autoComplete="off"
                onKeyUp={(event) => onKeyUpHandler && onKeyUpHandler(event)}
            />
            <div 
                className={cx(["input-group-append",globalClasses.PointerCursor])} 
                onMouseDown={()=>setShowText(true)}
                onTouchStart={()=>setShowText(true)}

                onMouseUp={()=>setShowText(false)}
                onTouchEnd={()=>setShowText(false)}

                onMouseLeave={()=>setShowText(false)}
                onTouchMove={()=>setShowText(false)}
            >
                <span className={cx(["input-group-text",classes.PasswordEyeIcon])}>
                    {showText? <VscEyeClosed size={18} /> : <VscEye size={18} />}
                </span>
            </div>
        </div>
    )

    let formFieldContainerClass = isEmpty(containerClass) ? "form-group col-12 col-md-6 px-1 px-md-3 my-3"
        : containerClass;
    return (
        <div className={formFieldContainerClass}>
            {labelComponent}
            {inputComponent}
            <span className={globalClasses.ErrorText}>
                {errorText}
            </span>
        </div>
    )
}

export default PasswordField;