export const QUERY_STRINGS = {
    SUPPORT_TICKETS_FOR_ACCCOUNTS: '?account_search=',
    SUPPORT_TICKET_ROLE: '?role='
}

export const USER_MNGMENT_STRINGS = {
    CLIENT_ID : "Client ID",
    CLIENT_SECRET : "Client Secret",
    ACCESS_TOKEN : "Access Token",
    CHANGE_PLAN_NOTE : "Note: Users' access might change according to the new plan.",
    PLAN_RENEW_CHANGE_TITLE : "Do you want to change or renew your plan?",
    PLAN_CHANGE_TITLE : "Do you want to change your plan?",
    PLAN_RENEW_CHANGE_MODAL_TITLE : "Change or Renew Plan",
    PLAN_CHANGE_MODAL_TITLE : "Change Plan",
    ACCOUNT_SECTION: {
        TAB_1: "Accounts",
        TAB_2: "Support",
        TAB_3: "Pending Invitees",
        TAB_4: "Reports",
        TAB_5: "Manage Models",
        TAB_6: "Transactions",
    },

    VIEW_ALL: "View all accounts",
    TAB_1: "Users",
    TAB_2: "API Keys",
    TAB_3: "Reports",
    TAB_4: "Support",
    TAB_5: "Subscription",
    TAB_6: "Transactions",
    TAB_7: "Manage Models",
    TAB_8: "Configurator",
    TAB_9: "Self-hosting",
    DELETE_POPUP_TITLE: "Delete Account",
    DELETE_POPUP_SUBTEXT: "Are you sure, you want to delete this account?",
    ADD_NEW_SERVICE_ACCOUNT: "Create New API Key",
    SERVICE_ACCOUNT_NAME_LABEL: "API Key Name",
    TIER_LEVEL_LABEL: "Tier Level ",
    ECHO_TEST_LABEL: 'Echo Test',
    TOOLTIP_COPY_ID: 'Copy Client ID',
    TOOLTIP_COPY_TOKEN: 'Copy Token',
    TOOLTIP_COPY_SECRET: 'Copy Secret Key',
    TOOLTIP_EDIT_ACCOUNT: 'Edit Account',
    TOOLTIP_DOWNLOAD: "Download",
    TOOLTIP_REFRESH_SECRET: 'Renew Client Secret',
    TOOLTIP_DELETE_ACCOUNT: 'Delete Account',
    TOOLTIP_EDIT_DONE: 'Done Changes',
    SUBMITTING: 'Submitting',
    DELETING: 'Deleting',
    ACCOUNTS : {
        SUPPORT : {
            EDIT_COMMENT : "Edit Comment",
            CANCEL_COMMENT : "Cancel",
            SUBMIT_COMMENT : "Update"
        }
    },
    USER_PANE: {
        USERNAME: "Display Name",
        EMAIL: "Email ID",
        USERTYPE: "User Type",
        STATUS: "Status",
        ECHOTEST: "Echo Test",
        NO_USERS: "No Users found",
        NO_USERS_SUB_TEXT: "No users exists in this account",
        ACTIONS: 'Actions',
        TOOLTIP_EDIT_USER: 'Edit User',
        TOOLTIP_DELETE_USER: 'Remove User',
        TOOLTIP_RENEW_USER: "Renew User",
        TOOLTIP_REFRESH: 'Refresh',
        ADD_NEW_USER: 'Create New User',
        EDIT_USER: 'Edit User',
        USER_NAME_LABEL: "Display Name ",
        TIER_LEVEL_LABEL: "Tier Level ",
        USER_MAIL_LABEL: 'Email ID ',
        USER_TYPE_LABEL: 'User Type ',
        ECHO_TEST_LABEL: 'Echo Test',
        DELETE_POPUP_TITLE: "Remove User",
        DELETE_POPUP_SUBTEXT: "Are you sure, you want to remove this user?",
    },
    CONFIGURATORS_PANE : {
        TOOLTIP_EDIT: 'Edit',
        TOOLTIP_DELETE: 'Remove',
        ACTIONS : "Actions"
    }
}


export const REPORT_STRINGS = {
    ALL_VALUE: 'All',
    TOP_VALUE: 'Top',
    BOTTOM_VALUE: 'Bottom',
    USERS: {
        TOTAL_USERS: 'Total Users',
        LABEL: 'Users'
    },
    SERVICE_ACCOUNTS: {
        TOTAL_SERVICE_ACCOUNTS: 'Total API Keys',
        LABEL: 'API Keys'
    },
    CREDITS: {
        TOTAL_CREDITS: 'Total Credits (USD)',
        LABEL: 'Credits (USD)'
    },
    TRANSACTIONS: {
        TOTAL_TRANSACTIONS: 'Total Transactions',
        LABEL: 'Transactions'
    },
    TIER_CREDIT_TRANSACTION_CHART: {
        CREDIT_TITLE: 'Net credits consumed by tiers',
        TRANSACTION_TITLE: 'Net transactions consumed by tiers',
        LEGEND_TITLE: 'Model Tier',
        VALUE_UNIT_CREDIT: 'credit',
        VALUE_UNIT_TRANSACTON: 'transaction'
    },
    USER_CREDIT_TRANSACTION_CHART: {
        TITLE: 'Net credits and transactions consumed by users / service accounts',
        USER_SELECT_TITLE: 'User',
        XAXIS_LABEL: 'Users / API Keys',
        YAXIS_LABEL: 'Total credits / Transactions',
        NO_DATA_MAIN_TEXT: 'No data found',
        NO_DATA_SUB_TEXT: 'No transactions is involved by the requested user.',
    },
    MODEL_GROUP_CREDIT_TRANSACTION_CHART: {
        TITLE: 'Net credits and transactions consumed by model groups',
        MODEL_GROUP_SELECT_TITLE: 'Model Group',
        XAXIS_LABEL: 'Model groups',
        YAXIS_LABEL: 'Total credits / Transactions',
        NO_DATA_MAIN_TEXT: 'No data found',
        NO_DATA_SUB_TEXT: 'No transactions involved for the requested model group.',
    },
    MODEL_CREDIT_TRANSACTION_CHART: {
        TITLE: 'Net credits and transactions consumed by models',
        LIMIT_SELECT_TITLE: 'Model Limit',
        MODEL_SELECT_TITLE: 'Model Name',
        XAXIS_LABEL: 'Models',
        YAXIS_LABEL: 'Total credits / Transactions',
        NO_DATA_MAIN_TEXT: 'No data found',
        NO_DATA_SUB_TEXT: 'No transactions involved for the requested model.',
    },
    TREND_CREDIT_TRANSACTION_CHART: {
        TITLE: 'Net credits and transactions consumed by trend',
        USER_SELECT_TITLE: 'User',
        MODEL_GROUP_SELECT_TITLE: 'Model Group',
        MODEL_SELECT_TITLE: 'Model',
        TIER_SELECT_TITLE: 'Model Tier',
        XAXIS_LABEL: 'Date',
        YAXIS_LABEL: 'Total credits / Transactions',
        NO_DATA_MAIN_TEXT: 'No data found',
        NO_DATA_SUB_TEXT: 'No transactions involved for the requested detail.',
    },
    IDP_PRODUCTS : [
        {
        ID: 1,
        PRODUCT : "Leaf IDP"
        },
        {
           ID: 2,
           PRODUCT : "Leaf Converse"
        },
        {
           ID: 3,
           PRODUCT : "Leaf Essentials"
        }
     ],

}

