import classes from './Sentiment.module.css';
import cx from 'classnames';

import POSITIVE from '../../../assets/images/thumbs-up.png';
import NEGATIVE from '../../../assets/images/thumb-down.png';
import NEUTRAL from '../../../assets/images/thumb-neutral.png';
import POSITIVE_STRONG from '../../../assets/images/positive-strong.png';
import NEGATIVE_STRONG from '../../../assets/images/negative-strong.png';
import { EMPTY_STRING } from '../../../utils/Constants';

function Sentiment(props) {
    let {type, className, iconStyle}=props;
    let sentimentSrc = NEUTRAL
    let textStatus = classes.TextStatusNeutral;
    let showIcon = true;
    type = type.trim();
    switch(type.toLowerCase()){
        case 'spam' : showIcon = false
        case 'negative' :
        case 'poor' : sentimentSrc = NEGATIVE;
        textStatus = classes.TextStatusBad;
        break;
        case 'strongly negative' : sentimentSrc = NEGATIVE_STRONG;
        case 'very negative' : sentimentSrc = NEGATIVE_STRONG;
        textStatus = classes.TextStatusBad;
        break;
        case 'ham' : showIcon = false
        case 'positive' :
        case 'good' : sentimentSrc = POSITIVE
        textStatus = classes.TextStatusGood;
        break;
        case 'strongly positive' : sentimentSrc = POSITIVE_STRONG;
        case 'very positive' : sentimentSrc = POSITIVE_STRONG;
        textStatus = classes.TextStatusGood;
        break;
        case 'mixed' :
        case 'neutral' : sentimentSrc = NEUTRAL;
        textStatus = classes.TextStatusNeutral;
        break;
        default : sentimentSrc = EMPTY_STRING
        textStatus = EMPTY_STRING
        showIcon = false
    }
    let textStyle=cx([className, textStatus])
    let iconStyles = cx([iconStyle, classes.IconImage])
    return(
        <span className='d-inline-flex align-items-center'>
            {showIcon && <img src={sentimentSrc} className={iconStyles} alt='sentiment icon'/>}
            <span className={textStyle}>{type}</span>
        </span>
    )
}

export default Sentiment;