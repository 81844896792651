import { isValidConfirmPassword, isValidPassword } from "../../../../utils/UtilityFunctions";
import PasswordField from "./PasswordField";

function ConfirmPasswordField(props) {
    let {containerClass, password, confirmPassword, isDisabled, isReadOnly,
        isSubmitError, passwordPlaceholder, confirmPasswordPlaceholder,
        onChangePassword, onChangeConfirmPassword, isErrorPassword, isErrorConfirmPasword,
        passwordLabel, confirmLabel, passwordInfo } = props;

    return (
        <div className={containerClass}>
            <PasswordField
                containerClass='col-12'
                value={password}
                type="password"
                // label={FORM_LABEL_TEXTS.PASSWORD}
                infoText={passwordInfo}
                label={passwordLabel}
                id={'password'}
                placeHolder={passwordPlaceholder}
                onChangeHandler={onChangePassword}
                validate={isValidPassword}
                isDisabled={isDisabled}
                isReadOnly={isReadOnly}
                isError={isSubmitError}
                isFieldError={isErrorPassword}
                isMandatory={true}
                isNumber={false}
            />
            <PasswordField
                containerClass='col-12'
                value={confirmPassword}
                type="password"
                matchText={password}
                // label={FORM_LABEL_TEXTS.PASSWORD}
                label={confirmLabel}
                id={'password'}
                placeHolder={confirmPasswordPlaceholder}
                onChangeHandler={onChangeConfirmPassword}
                validate={isValidConfirmPassword}
                isDisabled={isDisabled}
                isReadOnly={isReadOnly}
                isError={isSubmitError}
                isFieldError={isErrorConfirmPasword}
                isMandatory={true}
                isNumber={false}
            />
        </div>
    )
}

export default ConfirmPasswordField;