import classes from './ToggleSwitch.module.css';
import globalClasses from '../../../App.module.css'
import cx from 'classnames';
import { isEmpty } from '../../../utils/UtilityFunctions';

function ToggleSwitch(props) {
    let { label, isToggleOn, labelStyle, toggleSwitchStyle, checkinputClass, onClickHandler,
        leftLable } = props;

    let labelClass = cx([labelStyle]);
    let leftLabelClass = null;
    let rightLabelClass = null;
    let toggleSwitchClass = cx([classes.Slider, classes.Round, toggleSwitchStyle])

    let checkInputClass = cx([classes.CheckInput, checkinputClass])

    if (!isEmpty(leftLable)) {
        if (!isToggleOn) {
            leftLabelClass = globalClasses.Font16Orange;
        } else {
            rightLabelClass = globalClasses.Font16Orange;
        }
    }

    const onClickToggle = (event) => {
        let value = event.target.checked
        onClickHandler(value)
    }

    return (
        <div className='d-flex align-items-center'>
            {!isEmpty(leftLable) && <label className={cx(["me-2",labelClass,leftLabelClass])}>{leftLable}</label>}
            <div className={globalClasses.FixedWidth42}>
                <label className={classes.Switch}>
                    <input className={checkInputClass} checked={isToggleOn} type="checkbox" onChange={onClickToggle} />
                    <span className={toggleSwitchClass} ></span>
                </label>
            </div>
            {!isEmpty(label) && <label className={cx(["ms-2",labelClass,rightLabelClass])}>{label}</label>}
        </div>
    )
}

export default ToggleSwitch;