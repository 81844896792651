import { EMPTY_STRING } from "../../../../strings/Strings";
import { isEmpty, isEmptyList } from "../../../../utils/UtilityFunctions";

export const REQUEST_DATA = {
    "provider_id": "",
    "model_id": "",
    "model_code": "",
    "is_count_required":"",
    "is_active":"",
    "user": ""
}

export const INITIAL_STATE = {
    providerList : [],
    providerModelList : [],
    isLoading : false,
    isErrorData : false,
    selectedProvider : {},
    isProviderSelected : false,
    tempReqData : {},
    showPopupId : 0
}

export const setProvierInfo = ({data}) => {
    let dataList = Object.assign([],data);
    let providerList = [];
    if(!isEmptyList(dataList)){
        dataList.map((item,index) => {
            let providerInfo = {};
            let availabelCount = Number(item.Active) + Number( item.InActive)

            providerInfo["id"] = item.Id;
            providerInfo["name"] = item.Name;
            providerInfo["description"] = item.Description;
            providerInfo["imageSrc"] = item.ImageURL;
            providerInfo["activeCount"] = item.Active;
            providerInfo["inActiveCount"] = item.InActive;
            providerInfo["availableCount"] = availabelCount
            providerInfo["isActive"] = (Number(item.InActive) === 0) || (Number(item.Active) !== 0)
            providerInfo["availableFromParent"] = isEmpty(item.IsActiveFromParent) ? availabelCount : Number(item.IsActiveFromParent)

            providerList.push(providerInfo)

            return null;
        })
    }
    return({
        isLoading : false,
        isErrorData : false,
        providerList
    })
}

export const updateProviderDetails = (list,provider,providerSelected, modelList) => {
    console.log(modelList)
    let selectedProvider = {}
    let providerList = Object.assign([],list);
    let providerModelList = Object.assign([],modelList)
    let providerIndex = providerList.findIndex((item) => item.id === provider.id);
    let activestatus = !provider.isActive
    let actualAvailable = provider.availableFromParent
    let diabledByParent = provider.availableCount - provider.availableFromParent
    if (providerIndex > -1) {
        providerList[providerIndex].isActive = activestatus;
        providerList[providerIndex].activeCount = activestatus? actualAvailable : 0;
        providerList[providerIndex].inActiveCount = activestatus? diabledByParent : provider.availableCount
    }
    if(providerSelected){
        selectedProvider = Object.assign({},providerList[providerIndex])
        providerModelList.filter(item => item.isActive = activestatus)
        console.log(providerModelList)
    }
    return({
        selectedProvider,
        providerModelList,
        providerList,
        isLoading : false,
        isErrorData : false,
        tempReqData : {}
    })
}

export const updateProviderModelDetails = (list,model,provider,modelList) => {
    let selectedProvider = {}
    let providerList = Object.assign([],list);
    let providerModelList = Object.assign([],modelList)
    let providerModelIndex = providerModelList.findIndex((item) => item.modelCode === model.modelCode)
    let providerIndex = providerList.findIndex((item) => item.id === provider.id)

    console.log(providerList,providerIndex,providerModelList,providerModelIndex)

    let activestatus = !model.isActive
    let activemodels = Number(providerList[providerIndex].activeCount)
    let newActiveCount = activestatus? activemodels+1 : activemodels-1
    let availablemodels = Number(providerList[providerIndex].availableCount)
    let newInActiveCount = availablemodels - newActiveCount

    console.log(activestatus,activemodels,newActiveCount,availablemodels,newInActiveCount)

    if(providerModelIndex > -1){
        providerModelList[providerModelIndex].isActive = activestatus
        if(providerIndex > -1){
            providerList[providerIndex].activeCount = newActiveCount
            providerList[providerIndex].inActiveCount = newInActiveCount
            providerList[providerIndex].isActive = newActiveCount > 0 ? true : false
            selectedProvider = Object.assign({},providerList[providerIndex])
        }
    }
    return({
        selectedProvider,
        providerModelList,
        providerList,
        isLoading : false,
        isErrorData : false,
        tempReqData : {}
    })
}

export const setProviderModelsInfo = ({data}) => {
    console.log(data)
    let dataList = Object.assign([],data);
    console.log(dataList)
    let providerModelList = [];
    if(!isEmptyList(dataList)){
        dataList.map((item,index) => {
            console.log(item)
            let providerModelInfo = {};
            let modelData = isEmpty(item.Model)? [{}] : JSON.parse(item.Model);
            providerModelInfo['id'] = item.ModelId
            providerModelInfo['modelCode'] = isEmpty(modelData[0].Model_Code) ? "-" : modelData[0].Model_Code;
            providerModelInfo['modelDomain'] = isEmpty(modelData[0].Model_Domain) ? "-" : modelData[0].Model_Domain;
            providerModelInfo['modelGroup'] = isEmpty(modelData[0].Model_Task) ? "-" : modelData[0].Model_Task;
            providerModelInfo['category'] = isEmpty(modelData[0].Model_Category) ? "-" : modelData[0].Model_Category;
            providerModelInfo['modelName'] = isEmpty(modelData[0].Model_Name) ? "-" : modelData[0].Model_Name;
            providerModelInfo['credits'] = isEmpty(modelData[0].Credits) ? "-" : modelData[0].Credits;
            providerModelInfo['tier'] = isEmpty(modelData[0].Tier) ? "-" : modelData[0].Tier;
            providerModelInfo['isActive'] = Number(item.IsActive) !== 0;
            providerModelInfo['mappingId'] = isEmpty(item.MappingId) ? "-" : item.MappingId
            providerModelInfo['isActiveForAllAccounts'] = isEmpty(item.IsActiveFromParent)? 1 : Number(item.IsActiveFromParent)
            providerModelList.push(providerModelInfo)
            return null;
        })
    }

    return({
        isLoading : false,
        isErrorData : false,
        providerModelList,
        isProviderSelected : true,
        tempReqData : {}
    })
}

export const setError = () => {
    return({
        isLoading : false,
        isErrorData : false,
        tempReqData : {}
    })
}
