import React from 'react';
import {Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, ArcElement, Tooltip, PointElement, LinearScale, Title } from 'chart.js';
import { isEmpty } from '../../utils/UtilityFunctions';

ChartJS.register(CategoryScale, ArcElement, PointElement, LinearScale, Title, Tooltip);

function PieChart(props) {

    let {title, labels, datasets, legendTitle, valueUnit} = props;

    console.log('PieChart',labels,datasets);

    let chartData ={
      labels,
      datasets
    }


    let chartOptions = {
        responsive: true,
        plugins: {
          title: {
            display: !isEmpty(title),
            text: title,
            color: "#221e5b",
            font: {
              size: '18px'
            },
            padding: {top: 0, left: 0, right: 0, bottom: 20}
          },
          legend: {
              display : true,
              position: 'right',
              maxHeight: 12,
              labels : {
                usePointStyle : true,
                boxHeight : 8,
                boxWidth : 8
              },
              title : {
                display : !isEmpty(legendTitle),
                text : legendTitle,
                color: "#202124",
                font: {
                    size: 12,
                    weight : 'bold'
                }
              }
          },
          tooltip : {
            displayColors : false,
            callbacks:{
              label : (tooltipitems) => {
                let label = tooltipitems.label
                let value = tooltipitems.formattedValue
                return (label + ': ' + value + ' ' + valueUnit + (value === '1'? '' : 's'))
              }
            }
          }
        }
      }
    
    
    return(
        <>
          <Pie 
            data={chartData} 
            options={chartOptions}
          />
        </>
    )
}

export default PieChart;